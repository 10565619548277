import styles from './ComparisonModalTable.module.scss';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { MutableRefObject, PropsWithoutRef } from 'react';
import { ProductComparisonDetail } from '../../../../models/product-comparison-detail';
import { ReactComponent as Close } from '../../../../icons/close.svg';
import { productComparisonFields } from '../productComparisonFields';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../../store';
import _ from 'lodash';
import Button from '../../Button/Button';
import { getLinkUrl } from '../../../../utils/product.util';

interface ComparisonModalTableProps {
  products: ProductComparisonDetail[];
  onProductDelete: (product: ProductComparisonDetail) => void;
  className?: string;
  tableRef: MutableRefObject<HTMLDivElement | null>;
}

/**
 * comparison modal table
 * @param products products
 * @param onProductDelete product delete handler
 * @param className classname
 */
function ComparisonModalTable({
  products,
  onProductDelete,
  className,
  tableRef,
}: PropsWithoutRef<ComparisonModalTableProps>) {
  const {
    welcomeInfo: { language, country, industrySector },
  } = useSelector((state: RootStore) => state.welcomeInfo);
  const columnWidth = `${100 / (products.length + 1)}%`;

  const getProductDocumentId = (product: ProductComparisonDetail) => {
    const productDocument =
      _.find(
        product.productDocuments,
        ({ countryId, languageId }) =>
          countryId === country?.id || languageId === language?.id,
      ) || product.productDocuments[0];
    return productDocument ? productDocument.id : undefined;
  };

  return (
    <div ref={tableRef} className={cn(styles.container, className)}>
      <div className={styles.tableHeader}>
        <div className={styles.tableRow}>
          <div
            style={{ width: columnWidth }}
            className={cn(styles.tableLabelColumn, styles.tableColumn)}
          >
            <FormattedMessage id={'product-name-label'} />
          </div>

          {products.map((item) => {
            return (
              <div
                key={item.id}
                style={{ width: columnWidth }}
                className={styles.tableColumn}
              >
                <Link
                  className={styles.link}
                  to={`/product/${item.friendlyUrl}`}
                >
                  {item.name}
                </Link>

                <Close
                  onClick={() => onProductDelete(item)}
                  className={styles.close}
                />
              </div>
            );
          })}
        </div>
      </div>

      <div className={styles.tableBody}>
        {productComparisonFields.map(
          (productComparisonField) =>
            products.some((prod) =>
              productComparisonField.isVisibleFunction(
                prod,
                industrySector?.friendlyUrl,
              ),
            ) &&
            productComparisonField.formattedMessageId !==
              'product-type-label2' &&
            productComparisonField.formattedMessageId !==
              'applicable-these-sectors-label' &&
            productComparisonField.formattedMessageId !==
              'product-comparision-master-brand-label' &&
            productComparisonField.formattedMessageId !==
              'product-comparision-owner-label' && (
              <div
                className={styles.tableRow}
                key={productComparisonField.formattedMessageId}
              >
                <div
                  style={{ width: columnWidth }}
                  className={cn(styles.tableLabelColumn, styles.tableColumn)}
                >
                  <FormattedMessage
                    id={productComparisonField.formattedMessageId}
                  />
                </div>

                {productComparisonField.formattedMessageId === 'oem-label'
                  ? products.map((item) => {
                      return (
                        <div
                          key={item.id}
                          style={{ width: columnWidth }}
                          className={cn(styles.tableColumn, styles.oemColumn)}
                        >
                          <FormattedMessage
                            id={'oem-approvals-counts-label'}
                            values={{
                              count: item.oemProductApprovalCount || 0,
                            }}
                          />
                        </div>
                      );
                    })
                  : products.map((item) => {
                      return (
                        <div
                          key={item.id}
                          style={{ width: columnWidth }}
                          className={styles.tableColumn}
                        >
                          {productComparisonField.value(item)}
                        </div>
                      );
                    })}
              </div>
            ),
        )}
        {products.some(getProductDocumentId) && (
          <div className={styles.tableRow}>
            <div
              style={{ width: columnWidth }}
              className={cn(styles.tableLabelColumn, styles.tableColumn)}
            >
              <FormattedMessage id={'quick-links-label'} />
            </div>
            {products.map((product) => {
              const productDocument = getProductDocumentId(product);
              if (!productDocument) return 'N/A';
              return (
                <div
                  key={product.id}
                  style={{ width: columnWidth }}
                  className={styles.tableColumn}
                >
                  <Button
                    onClick={() =>
                      window.open(
                        getLinkUrl(
                          'PDS',
                          country,
                          product.name,
                          industrySector,
                          language,
                        ),
                      )
                    }
                    color={'green-outline'}
                    className={styles.downloadButton}
                  >
                    <FormattedMessage id={'product-data-sheet-label'} />
                  </Button>
                  <Button
                    onClick={() =>
                      window.open(
                        getLinkUrl(
                          'MSDS',
                          country,
                          product.name,
                          industrySector,
                          language,
                        ),
                      )
                    }
                    color={'green-outline'}
                    className={styles.downloadButton}
                  >
                    <FormattedMessage id={'safety-data-sheet-label'} />
                  </Button>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

export default ComparisonModalTable;
