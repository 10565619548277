import styles from './PortAvailableTable.module.scss';
import { PropsWithoutRef } from 'react';
import { ReactComponent as Barge } from '../../../icons/barge.svg';
import { ReactComponent as Truck } from '../../../icons/truck.svg';
import { ReactComponent as Ibc } from '../../../icons/ibc.svg';
import { ReactComponent as Drum } from '../../../icons/drum.svg';
import { ReactComponent as Pail } from '../../../icons/pail.svg';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { AvailablePort } from '../../../models/search-result-product';
import { FormattedMessage } from 'react-intl';

interface PortAvailableTableProps {
  ports: AvailablePort[];
  className?: string;
}

/**
 * port available table
 * @param ports ports to display
 * @param className classname
 */
function PortAvailableTable({
  ports,
  className,
}: PropsWithoutRef<PortAvailableTableProps>) {
  return (
    <table className={cn(styles.container, className)}>
      <colgroup>
        <col />
        <col />
        <col />
        <col />
        <col />
        <col />
        <col />
        <col />
      </colgroup>

      <thead>
        <tr>
          <th rowSpan={2}>
            <FormattedMessage id="port-availability-port-label" />
          </th>

          <th rowSpan={2}>
            <FormattedMessage id="port-availability-notice-label" />
          </th>

          <th colSpan={3}>
            <FormattedMessage id="port-availability-bulk-label" />
          </th>

          <th colSpan={2}>
            <FormattedMessage id="port-availability-pack-label" />
          </th>
        </tr>

        <tr>
          <th>
            <FormattedMessage id="port-availability-barge-label" />
          </th>

          <th>
            <FormattedMessage id="port-availability-truck-label" />
          </th>

          <th>
            <FormattedMessage id="port-availability-ibc-label" />
          </th>

          <th>
            <FormattedMessage id="port-availability-drum-label" />
          </th>

          <th>
            <FormattedMessage id="port-availability-pail-label" />
          </th>
        </tr>
      </thead>

      <tbody>
        {ports.map((item) => {
          return (
            <tr key={item.id}>
              <td>
                <Link to={`/port/${item.friendlyUrl}`}>{item.name}</Link>
              </td>

              <td>{item.workingDaysNotice}</td>

              <td>{item.offerBulkBadge && <Barge />}</td>

              <td>{item.offerBulkTruck && <Truck />}</td>

              <td>{item.offerBulkIbc && <Ibc />}</td>

              <td>{item.offerPackDrum && <Drum />}</td>

              <td className={styles.center}>
                {item.offerPackPail && <Pail />}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default PortAvailableTable;
