import { memo, PropsWithoutRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { ProductValidationColumn } from '../../../models/table-column';
import styles from './ProductValidationTable.module.scss';

interface ProductValidationCardFieldProps {
  product: any;
  setDataAtIndex: (x: any) => void;
  column: ProductValidationColumn;
  rowIndex: number;
}

function ProductValidationCardField({
  product,
  setDataAtIndex,
  column,
  rowIndex,
}: PropsWithoutRef<ProductValidationCardFieldProps>) {
  const [showInput, setShowInput] = useState(false);

  const getColumnValue = () => {
    if (column.renderer) {
      return column.renderer(column, product, setDataAtIndex, rowIndex);
    }

    if (['$NULL_STRING$', ''].includes(String(column.key(product)))) {
      if (column.hidden) return null;
      if (column.showNA) return 'N/A';
      else if (showInput) {
        return (
          <input
            className={styles.input}
            onBlur={(e) => {
              if (e.target.value) {
                setDataAtIndex({
                  ...product,
                  [column.objectKey]: e.target.value,
                });
              } else {
                setShowInput(false);
              }
            }}
          />
        );
      } else {
        return (
          <span className={styles.errorText} onClick={() => setShowInput(true)}>
            <FormattedMessage id={'cant-empty-label'} />
          </span>
        );
      }
    }
    return column.key(product);
  };

  return (
    <>
      <div className={styles.tableColumn} key={column.formattedMessageId}>
        {getColumnValue()}
      </div>
    </>
  );
}

export default memo(ProductValidationCardField);
