import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ProductDetailsState {
  productId?: number;
  showDeactivateDialog?: boolean;
  sectorIds?: number[];
  showactivateDialog?: boolean;
}

export const ProductDetailsSlice = createSlice({
  name: 'product-details',
  initialState: {
    productId: undefined,
    showDeactivateDialog: false,
    showactivateDialog: false,
    sectorIds: [],
  } as ProductDetailsState,
  reducers: {
    // set product id
    setProductId: (
      state: ProductDetailsState,
      action: PayloadAction<number | undefined>,
    ): ProductDetailsState => {
      return {
        ...state,
        productId: action.payload,
      };
    },
    // toggle diactive dialog
    toggleDiactiveDialog: (
      state: ProductDetailsState,
      action: PayloadAction<boolean>,
    ): ProductDetailsState => {
      return {
        ...state,
        showDeactivateDialog: action.payload,
      };
    },
    toggleactiveDialog: (
      state: ProductDetailsState,
      action: PayloadAction<boolean>,
    ): ProductDetailsState => {
      return {
        ...state,
        showactivateDialog: action.payload,
      };
    },
    // set product sector ids
    setSectorIds: (
      state: ProductDetailsState,
      action: PayloadAction<number[]>,
    ): ProductDetailsState => {
      return {
        ...state,
        sectorIds: action.payload,
      };
    },
  },
});

export const {
  setProductId,
  toggleDiactiveDialog,
  toggleactiveDialog,
  setSectorIds,
} = ProductDetailsSlice.actions;

export default ProductDetailsSlice.reducer;
