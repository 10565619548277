import { PropsWithChildren } from 'react';

import styles from './Tab.module.scss';
import classNames from 'classnames';

type TabType = {
  currentTab: string;
  tabNames: string[];
  onClickTab: (tab: string) => void;
};

const Tab = ({
  currentTab,
  tabNames,
  onClickTab,
}: PropsWithChildren<TabType>) => {
  return (
    <div className={styles.tabs}>
      {tabNames.map((tab, index) => (
        <div
          className={classNames(
            styles.tab,
            tab === currentTab ? styles.active : null,
          )}
          key={index}
          onClick={() => onClickTab(tab)}
        >
          {tab}
        </div>
      ))}
    </div>
  );
};

export default Tab;
