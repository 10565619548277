import config from '../configs/config';
import { Lookups } from '../models/lookups';
import { WelcomeInfo } from '../models/welcome-info';
import {
  filterCountryByCulture,
  filterLanguageByCulture,
  filterSectorByFriendlyURL,
} from './lookup.util';

/**
 * get sector and culture from welcome info
 * @param welcomeInfo welcome info
 */
export function getSectorAndCulture(welcomeInfo?: WelcomeInfo): {
  sector: string;
  culture: string;
} {
  const languageCode = welcomeInfo?.language?.code || 'en';
  const countryCode = welcomeInfo?.country?.code || 'GB';

  const sector = welcomeInfo?.industrySector?.friendlyUrl || '';
  const culture = `${languageCode}-${countryCode}`;

  return {
    sector,
    culture,
  };
}

/**
 * validate sector and culture.
 * @param culture culture
 * @param sector secor
 * @param lookups lookups
 */
export function validateSectorAndCulture(
  sector: string,
  culture: string,
  lookups: Lookups,
): boolean {
  if (culture.split('-').length !== 2) {
    return false;
  }

  if (
    !filterSectorByFriendlyURL(lookups.sectors, sector) &&
    sector !== config.ALL_SECTORS
  ) {
    return false;
  }

  if (!filterCountryByCulture(lookups.countries, culture)) {
    return false;
  }

  if (!filterLanguageByCulture(lookups.languages, culture)) {
    return false;
  }

  return true;
}
