import _ from 'lodash';

import InputRowWithButton from '../InputRowWithButton/InputRowWithButton';

import { ContactDetailsProps } from '../../../models/admin-form-types';

const ContactDetails = ({
  fields,
  formValue,
  handleValueChange,
}: ContactDetailsProps) => (
  <>
    {fields.map((field: any, index: number) => (
      <InputRowWithButton
        key={`${field.key}-${index}`}
        fieldKey={field.key}
        labelId={field.labelId}
        list={formValue[field.key]}
        required={false}
        tooltip={field.tooltip}
        onChange={(index, value) => {
          const tempValues = _.cloneDeep(formValue[field.key]);
          tempValues[index] = value;
          handleValueChange(field.key, tempValues);
        }}
        onAddBtnClick={(newValue) => {
          const tempValues = _.cloneDeep(formValue[field.key]);
          tempValues.push(newValue);
          handleValueChange(field.key, tempValues);
        }}
        onDeleteBtnClick={(index: number) => {
          const tempValues = _.cloneDeep(formValue[field.key]);
          tempValues.splice(index, 1);
          handleValueChange(field.key, tempValues);
        }}
      />
    ))}
  </>
);

export default ContactDetails;
