import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import GenericManageSkeleton from '../../../components/Admin/GenericManageSkeleton/GenericManageSkeleton';
import Header from '../../../components/Admin/Header/Header';
import ManageStockpointForm from '../../../components/Admin/ManageStockpointContent/ManageStockpointForm/ManageStockpointForm';
import SelectStockpointForm from '../../../components/Admin/ManageStockpointContent/SelectStockpointForm/SelectStockpointForm';

import {
  StepperItemType,
  StockpointFormType,
  StockpointProductType,
  StockpointType,
} from '../../../models/admin-form-types';
import { PreviousLink } from '../../../models/previous-link';
import { SearchResultProduct } from '../../../models/search-result-product';
import { getSearchResults } from '../../../services/ProductService';
import { CountryItem } from '../../../models/lookups';

import { hideLoading, showLoading } from '../../../features/loadingSlice';
import { addToast } from '../../../features/toastSlice';
import { showGenericModal } from '../../../features/genericModalSlice';

import {
  getStockpoint,
  getStockpoints,
  updateStockpoint,
} from '../../../services/StockpointService';

import { RootStore } from '../../../store';
import withAdmin from '../../../hoc/withAdmin';
import config from '../../../configs/config';

const breadcrumbLinks: PreviousLink[] = [
  {
    name: 'Home',
    to: '/home',
  },
  {
    name: 'Manage Stockpoint',
  },
];

const initialState: StockpointFormType = {
  stockpoint: {} as StockpointType,
  country: {} as CountryItem,
  stockpointProducts: [],
  ports: [],
};

const ManageStockpoint = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [query] = useSearchParams();
  const { countries } = useSelector((state: RootStore) => state.lookups);
  const [step, setStep] = useState(0);
  const [formValue, setFormValue] = useState(initialState);
  const [showMessage, setShowMessage] = useState(false);
  const [stockpoints, setStockpoints] = useState([] as StockpointType[]);
  const stockPointId = query.get('stockPointId');

  useEffect(() => {
    dispatch(showLoading());
    getStockpoints()
      .then((data) => {
        setStockpoints(data);

        if (stockPointId) {
          const stockPoint = _.find(
            data,
            (item) => String(item.id) === stockPointId,
          );
          if (stockPoint && step === 0) {
            setFormValue({
              ...formValue,
              stockpoint: stockPoint,
            });
            setStep(1);
          }
        }
      })
      .finally(() => dispatch(hideLoading()));
  }, []);

  /* istanbul ignore next */
  const associateProductIdWithProduct = (
    productsData: SearchResultProduct[],
    stockpointProducts: any,
  ) => {
    const updatedProducts: any = _.cloneDeep(stockpointProducts);
    for (let i = 0; i < updatedProducts.length; i++) {
      const product: any = productsData.find(
        (p: any) => p.id === (updatedProducts[i] as any).productId,
      );
      updatedProducts[i].name = updatedProducts[i].product.name;
      updatedProducts[i].notFromProduct = !product?.name;
    }

    return updatedProducts;
  };

  /* istanbul ignore next */
  const fetchStockpointDetails = (productsData: SearchResultProduct[]) => {
    const stockpointId = formValue.stockpoint.id;
    return new Promise((resolve, reject) => {
      if (stockpointId) {
        getStockpoint(stockpointId)
          .then((res) => {
            const stockpointProducts = associateProductIdWithProduct(
              productsData,
              res.stockPointProducts || [],
            );
            let country = countries.find((c) => c.id === res.countryId);
            if (!country) {
              country = {} as CountryItem;
            }

            setFormValue({
              ...formValue,
              country,
              stockpointProducts,
              ports: res.ports || [],
            });
            resolve(true);
          })
          .catch(() => reject());
      } else {
        resolve(true);
      }
    });
  };

  useEffect(() => {
    // Make only API call to fetch stockpoint details when we are on the actual page
    if (formValue.stockpoint.id && step === 1) {
      dispatch(showLoading());
      const headers = {
        sector: config.MARINE_SECTOR,
        culture: `en-${
          countries.find((x) => x.id === formValue.stockpoint.countryId)?.code
        }`,
      };
      getSearchResults(
        {
          onlyProducts: true,
        },
        undefined,
        headers,
      )
        .then(async (data) => {
          await fetchStockpointDetails(data.products);
        })
        .finally(() => {
          dispatch(hideLoading());
        });
    }
  }, [step, formValue.stockpoint.id]);

  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });

  /* istanbul ignore next */
  const handleValueChange = (
    fieldKey: string,
    value: StockpointProductType[],
  ) => {
    setShowMessage(false);
    const formVal = _.set(formValue, fieldKey, value);
    setFormValue((state) => ({
      ...state,
      formVal,
    }));
  };

  /* istanbul ignore next */
  const stepperItems: StepperItemType = [
    {
      label: getTranslatedMessage('select-stockpoint-label'),
      componentToRender: (
        <SelectStockpointForm
          stockpoints={stockpoints}
          formValue={formValue}
          handleValueChange={(value: StockpointType) => {
            setFormValue({
              ...formValue,
              stockpoint: value,
            });
          }}
        />
      ),
    },
    {
      label: getTranslatedMessage('modify-products-stockpoint-label'),
      componentToRender: (
        <ManageStockpointForm
          stockpoints={stockpoints}
          formValue={formValue}
          handleValueChange={handleValueChange}
          showMessage={showMessage}
          reset={() => {
            setStep(0);
            setShowMessage(false);
          }}
        />
      ),
    },
  ];

  const saveDisabled = () => {
    if (step === 0) {
      return !formValue.stockpoint?.name;
    }
    if (step === 1) {
      return !formValue.stockpoint?.name;
    }

    return false;
  };

  const handleSaveBtnClick = () => {
    if (step === 1) {
      const payload = {
        ...formValue.stockpoint,
        countryId: formValue.country.id,
        stockPointProducts: formValue.stockpointProducts.map((x: any) => ({
          offerBulkBadge: x.offerBulkBadge,
          offerBulkIbc: x.offerBulkIbc,
          offerBulkTruck: x.offerBulkTruck,
          offerPackDrum: x.offerPackDrum,
          offerPackPail: x.offerPackPail,
          productId: x.productId,
        })),
        portIds: formValue.ports.map((x: any) => x.id),
      };
      dispatch(showLoading());
      updateStockpoint(payload)
        .then((data) => {
          const list = [...stockpoints];
          list.forEach((x) => {
            if (x.id === data.id) {
              x = data;
            }
          });
          setStockpoints([...list]);
          dispatch(
            showGenericModal({
              titleId: 'stockpoint-update-success-label',
              messageId: 'stockpoint-update-success-message',
            }),
          );
          setShowMessage(true);
        })
        .catch((message) => {
          dispatch(
            addToast({
              type: 'error',
              title: intl.formatMessage({ id: 'stockpoint-update-failure' }),
              message: message || '',
            }),
          );
        })
        .finally(() => dispatch(hideLoading()));
      return;
    }

    setStep(step + 1);
  };

  return (
    <>
      <Header
        titleId="header-menu-manage-ports-manage-stockpoints"
        breadcrumbLinks={breadcrumbLinks}
      />
      <GenericManageSkeleton
        step={step}
        stepperItems={stepperItems}
        saveBtnDisabled={saveDisabled()}
        showUnsavedChangesModal={!_.isEqual(initialState, formValue)}
        onBackBtnClick={() => setStep(step - 1)}
        onSaveBtnClick={handleSaveBtnClick}
      >
        {stepperItems[step].componentToRender}
      </GenericManageSkeleton>
    </>
  );
};

export default withAdmin(ManageStockpoint);
