import { PropsWithChildren } from 'react';
import styles from './UserActionButton.module.scss';

interface UserActionButtonProps {
  onActionClick?: () => void;
}

/**
 * user action button for user profile in main header
 * @param children children
 */
function UserActionButton({
  onActionClick,
  children,
}: PropsWithChildren<UserActionButtonProps>) {
  return (
    <div className={styles.container} onClick={onActionClick}>
      {children}
    </div>
  );
}

export default UserActionButton;
