import { PropsWithoutRef } from 'react';
import cn from 'classnames';

import styles from './FilterContainer.module.scss';

interface FilterContainerProps {
  title: string;
  className?: string;
  children?: any;
}

/**
 * filter container
 * @param title title
 * @param className classname
 * @param children children
 */
function FilterContainer({
  title,
  className,
  children,
}: PropsWithoutRef<FilterContainerProps>) {
  return (
    <div className={className}>
      <div className={cn(styles.titleContainer)}>
        <div className={styles.title}>{title}</div>
      </div>

      <div className={styles.optionsContainer}>{children}</div>
    </div>
  );
}

export default FilterContainer;
