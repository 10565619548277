import { PropsWithChildren } from 'react';
import classNames from 'classnames';

import styles from './FormWrapper.module.scss';

type FormWrapperType = {
  title?: string;
  infoMessage?: string;
  message?: string;
  className?: string;
  secondaryTitle?: React.ReactNode;
};

const FormWrapper = ({
  title,
  infoMessage,
  message,
  secondaryTitle,
  children,
  className = '',
}: PropsWithChildren<FormWrapperType>) => {
  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.header}>
        {title && <h4>{title}</h4>}
        {/* Safe to use dangerouslySetInnerHTML here since we are reading from config file */}
        {infoMessage && (
          <span
            className={styles.infoMessage}
            dangerouslySetInnerHTML={{
              __html: infoMessage,
            }}
          ></span>
        )}
        {message && (
          <span className={classNames(styles.infoMessage, styles.message)}>
            {message}
          </span>
        )}
        {secondaryTitle && (
          <div className={styles.secondaryTitle}>{secondaryTitle}</div>
        )}
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default FormWrapper;
