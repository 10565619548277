import { useEffect, useState } from 'react';
import styles from './FilterByCountryPage.module.scss';
import ContainerForDrawer from '../../../../components/common/ContainerForDrawer/ContainerForDrawer';
import NavigationHeader from '../../../../components/common/NavigationHeader/NavigationHeader';
import { PreviousLink } from '../../../../models/previous-link';
import { FormattedMessage, useIntl } from 'react-intl';
import QuickStatsForm from '../components/QuickStatsForm/QuickStatsForm';
import { useDispatch } from 'react-redux';
import { CountryItem } from '../../../../models/lookups';
import classNames from 'classnames';
import { ReactComponent as XlsxFileIcon } from '../../../../icons/xlsx-file.svg';
import {
  getPortProductByCountry,
  getReportCountries,
} from '../../../../services/StatsService';
import LoadingSpinner from '../../../../components/common/LoadingSpinner/LoadingSpinner';
import FormRow from '../../../../components/Admin/FormRow/FormRow';
import MultiProductSelect from '../../Products/AddCountryLevelProductPage/components/MultiProductSelect/MultiProductSelect';
import { addToast } from '../../../../features/toastSlice';

/**
 * Filter By Country page component
 */
function FilterByCountryPage() {
  const dispatch = useDispatch();
  const intl = useIntl();

  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });
  const initialState = {
    country: [],
  };

  const [formValue, setFormValue] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [countryItems, setCountryItems] = useState<any[]>([]);

  const breadcrumbLinks: PreviousLink[] = [
    {
      name: 'Report',
      to: '/admin/reports',
    },
    {
      name: 'PORT PRODUCT BY COUNTRY',
    },
  ];

  const handleValueChange = (
    fieldKey: string,
    value: string | boolean | number[],
  ) => {
    setFormValue({
      ...formValue,
      [fieldKey]: value,
    });
  };

  const isInitialState = !formValue.country;

  useEffect(() => {
    setLoading(true);
    getReportCountries()
      .then((data) => {
        setCountryItems([
          {
            name: getTranslatedMessage('reports-page-select-all'),
            value: -1,
          },
          ...data.map((country: CountryItem) => ({
            name: country.name,
            value: country.id,
          })),
        ] as any);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const onClickExport = () => {
    setLoading(true);
    getPortProductByCountry(formValue.country)
      .catch((err) => {
        dispatch(
          addToast({
            type: 'error',
            title: intl.formatMessage({
              id: 'report-port-page-by-country-failure',
            }),
            message: err?.message || '',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div className={classNames(loading ? styles.loadingOverlayDim : null)}>
        <NavigationHeader breadcrumbLinks={breadcrumbLinks} />
        <ContainerForDrawer>
          <div className={classNames(styles.container)}>
            <div className={styles.title}>
              <FormattedMessage id={'filter-by-country-page-title'} />
            </div>
            <div className={styles.contentContainer}>
              <div className={styles.reportDetailContainer}>
                <div>
                  <QuickStatsForm
                    title={getTranslatedMessage(
                      'filter-by-country-page-form-title',
                    )}
                  >
                    <div className={styles.formFieldsContainer}>
                      <FormRow
                        labelId="port-product-select-country"
                        required={false}
                        className={styles.multipleSelect}
                        tooltip={getTranslatedMessage(
                          'port-product-page-tooltip',
                        )}
                      >
                        <MultiProductSelect
                          value={formValue.country}
                          onChange={(value: number[]) => {
                            if (value.includes(-1)) {
                              handleValueChange('country', []);
                            } else {
                              handleValueChange('country', value);
                            }
                          }}
                          placeholder={getTranslatedMessage(
                            'reports-page-select-all',
                          )}
                          options={countryItems}
                          className={styles.multipleSelect}
                          inputClassName={styles.multipleInput}
                          selectClassName={styles.selectOptions}
                          selectedItemName={getTranslatedMessage(
                            'reports-field-country-select',
                          )}
                          selectedItemNameSingular={getTranslatedMessage(
                            'reports-field-country-select-singular',
                          )}
                        />
                      </FormRow>
                    </div>

                    <div className={styles.filterContainer}>
                      <div
                        className={classNames(
                          styles.export,
                          isInitialState || loading ? styles.disabled : '',
                        )}
                        onClick={onClickExport}
                      >
                        <XlsxFileIcon />
                        <a className={styles.filterButton}>
                          {getTranslatedMessage(
                            'oem-table-field-export-to-excel',
                          )}
                        </a>
                      </div>
                    </div>
                  </QuickStatsForm>
                </div>
              </div>
            </div>
          </div>
        </ContainerForDrawer>
      </div>
      {loading && (
        <div className={styles.loadingOverlay}>
          <LoadingSpinner />
        </div>
      )}
    </>
  );
}

export default FilterByCountryPage;
