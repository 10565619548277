import {
  PropsWithoutRef,
  ReactElement,
  useLayoutEffect,
  useState,
} from 'react';
import { ReactComponent as CheckCircleGreen } from '../../../../icons/check-circle-green.svg';
import { ReactComponent as Close } from '../../../../icons/close.svg';
import { ToastItem } from '../../../../models/toast-item';
import styles from './ToastMessage.module.scss';

interface ToastMessageProps {
  toast: ToastItem;
  onClose: () => void;
}

/**
 * toast message
 * @param toast toast message item
 * @param onClose close handler
 */
function ToastMessage({ toast, onClose }: PropsWithoutRef<ToastMessageProps>) {
  const [count, setCount] = useState(5);

  const timer = setTimeout(() => {
    setCount(count - 1);

    if (count <= 0) {
      onClose();
    }
  }, 1000);

  useLayoutEffect(() => {
    return () => {
      clearTimeout(timer);
    };
  }, [timer]);

  let typeIcon: ReactElement;

  switch (toast.type) {
    case 'success': {
      typeIcon = <CheckCircleGreen />;
      break;
    }
    case 'error': {
      typeIcon = <CheckCircleGreen className={styles.error} />;
      break;
    }

    default: {
      typeIcon = <CheckCircleGreen />;
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.leftGroup}>
        {typeIcon}

        <div className={styles.messageGroup}>
          <div className={styles.title}>{toast.title}</div>

          <div className={styles.message}>
            {typeof toast.message === 'string'
              ? toast.message
              : toast.message.map((item, i) => <p key={i}>{item}</p>)}
          </div>
        </div>
      </div>

      <div className={styles.rightGroup}>
        <div className={styles.seconds}>{count}s</div>

        <Close onClick={() => onClose()} className={styles.close} />
      </div>
    </div>
  );
}

export default ToastMessage;
