import styles from './Select.module.scss';
import { ReactComponent as ArrowDown } from '../../../icons/arrow-down.svg';
import {
  Fragment,
  CSSProperties,
  PropsWithoutRef,
  ReactElement,
  useRef,
  useState,
} from 'react';
import { SelectOption } from '../../../models/select-option';
import cn from 'classnames';
import Option from './Option/Option';
import FormError from '../FormError/FormError';

interface SelectProps<T> {
  value: T;
  options: SelectOption<T>[];
  onChange: (value: T) => void;
  placeholder: string;
  required?: boolean;
  className?: string;
  inputClassName?: string;
  optionClassName?: string;
  canSearch?: boolean;
  errorMessage?: string;
  optionRenderer?: (option: SelectOption<T>) => ReactElement;
  disabled?: boolean;
  popperPosition?: 'right' | '';
  fixedWidth?: boolean;
  onOpenChange?: (open: boolean) => void;
  smallHorizontalPadding?: boolean;
  showTooltip?: boolean;
}

/**
 * select component
 * @param value value
 * @param options options
 * @param onChange change handler
 * @param placeholder placeholder
 * @param required required
 * @param className classname
 * @param canSearch can filter options or not
 * @param errorMessage error message
 * @param optionRenderer option content renderer
 * @param fixedWidth boolean to render option container with parent width
 * @param onOpenChange popup open change listener
 */
function Select<T>({
  value,
  options,
  onChange,
  placeholder,
  required,
  className,
  inputClassName,
  optionClassName,
  canSearch = false,
  errorMessage = '',
  optionRenderer,
  disabled = false,
  popperPosition,
  fixedWidth = true,
  onOpenChange,
  smallHorizontalPadding = false,
  showTooltip,
}: PropsWithoutRef<SelectProps<T>>) {
  const selected = options.find((item) => item.value === value)?.name || '';
  const [opened, setOpened] = useState(false);
  const [changed, setChanged] = useState(false);
  const [filter, setFilter] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([...options]);
  const [focused, setFocused] = useState(false);
  const selectContainerRef = useRef<HTMLDivElement>(null);
  const optionStyles: CSSProperties = {};

  // The page may scroll, then the selector position will change
  // So we need generate option container position when opening
  const generatePosition = (): boolean => {
    if (selectContainerRef.current) {
      const domRect = selectContainerRef.current.getBoundingClientRect();
      const maxHeight = domRect.height * 5;
      const bottomRemains = window.innerHeight > domRect.bottom + maxHeight;
      optionStyles.minWidth = domRect.width + 'px';
      if (fixedWidth) {
        optionStyles.width = domRect.width + 'px';
      }
      optionStyles.maxHeight = maxHeight + 'px';

      if (bottomRemains) {
        if (popperPosition === 'right') {
          if (domRect.right + 2 + domRect.width > window.innerWidth) {
            optionStyles.left = window.innerWidth - domRect.width + 'px';
          } else {
            optionStyles.left = domRect.right + 2 + 'px';
          }
          optionStyles.top = domRect.top + 'px';
        } else {
          optionStyles.left = (domRect.left > 0 ? domRect.left : 0) + 'px';
          optionStyles.top = domRect.bottom + 'px';
        }
      } else {
        if (popperPosition === 'right') {
          if (domRect.right + 2 + domRect.width > window.innerWidth) {
            optionStyles.left = window.innerWidth - domRect.width + 'px';
          } else {
            optionStyles.left = domRect.right + 2 + 'px';
          }
          optionStyles.bottom = window.innerHeight - domRect.bottom + 'px';
        } else {
          optionStyles.left = (domRect.left > 0 ? domRect.left : 0) + 'px';
          optionStyles.bottom = window.innerHeight - domRect.top + 'px';
        }
      }
    }

    return true;
  };

  /**
   * option click handler
   * @param changed changed value
   */
  const onOptionClick = (changed: T) => {
    if (changed !== value) {
      closeOptions();
      onChange(changed);
    }
  };

  /**
   * open options
   */
  const openOptions = () => {
    setFilter('');
    setFocused(true);
    setOpened(true);
    if (onOpenChange) {
      onOpenChange(true);
    }
    onSearchChange('');
  };

  /**
   * close options
   */
  const closeOptions = () => {
    setFilter('');
    setFocused(false);
    if (onOpenChange) {
      onOpenChange(false);
    }
    setOpened(false);
    setChanged(true);
  };

  /**
   * handle search change event
   * @param value value
   */
  const onSearchChange = (value: string) => {
    setFilter(value);

    const filtered = options.filter(
      (item) => item.name.toLowerCase().indexOf(value.toLowerCase()) !== -1,
    );

    setFilteredOptions(filtered);
  };

  const requiredInvalid = changed && required && !value;

  return (
    <Fragment>
      <div
        ref={selectContainerRef}
        onClick={() => {
          if (!disabled) {
            openOptions();
          }
        }}
        className={cn(
          styles.selectContainer,
          opened && styles.opened,
          requiredInvalid && styles.invalid,
          disabled && styles.disabled,
          className,
        )}
      >
        {optionRenderer ? (
          <div
            className={cn(
              styles.renderedValueContainer,
              !value && styles.placeholder,
            )}
          >
            {value
              ? optionRenderer(
                  options.find(
                    (item) => item.value === value,
                  ) as SelectOption<T>,
                )
              : placeholder}
          </div>
        ) : !canSearch || disabled ? (
          <div
            className={cn(
              styles.input,
              smallHorizontalPadding && styles.smallHorizontalPadding,
              styles.inputVal,
              !selected && styles.selectPlaceholder,
              inputClassName,
            )}
          >
            {selected || placeholder}
          </div>
        ) : (
          <input
            onInput={(event) =>
              onSearchChange((event.target as HTMLInputElement).value)
            }
            value={canSearch && focused ? filter : selected}
            placeholder={placeholder}
            disabled={!canSearch || disabled}
            type={'text'}
            className={cn(styles.input, inputClassName)}
          />
        )}

        <ArrowDown className={styles.icon} />
      </div>

      <FormError className={styles.error}>{errorMessage}</FormError>

      {opened && (
        <div onClick={() => closeOptions()} className={styles.clickDetector} />
      )}

      {opened && generatePosition() && (
        <div style={optionStyles} className={styles.optionsContainer}>
          {filteredOptions.map((item, index) => {
            const selected = item.value === value;

            return (
              <Option
                key={index}
                selected={selected}
                data={item}
                onClick={onOptionClick}
                optionRenderer={optionRenderer}
                scrollIntoView={filteredOptions.length > 5}
                className={optionClassName}
                showTooltip={showTooltip}
              />
            );
          })}
        </div>
      )}
    </Fragment>
  );
}

export default Select;
