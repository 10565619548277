import { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import OutsideClickDetector from '../../common/OutsideClickDetector/OutsideClickDetector';

import { Actions } from '../../../models/admin-form-types';

import { ReactComponent as SettingsGray } from '../../../icons/settings-gray.svg';
import { ReactComponent as SettingsGreen } from '../../../icons/settings-green.svg';

import styles from './RowActions.module.scss';

type RowActionsProps = {
  className?: string;
  showAction: boolean;
  handleShowAction: (toggleAction: boolean) => void;
  actions: Actions[];
  handleActionClick: (fieldKey: string) => void;
  item?: any;
};

const RowActions = ({
  className,
  showAction,
  handleShowAction,
  actions,
  handleActionClick,
  item,
}: RowActionsProps) => {
  const settingsRef: any = useRef();
  const [hovered, setHovered] = useState(-1);
  const [position, setPosition] = useState({
    top: 0,
    left: 0,
  });

  return (
    <div className={styles.container}>
      {showAction && (
        <div
          className={classNames(styles.actions, className)}
          style={{ top: `${position.top}px`, left: `${position.left - 275}px` }}
        >
          <OutsideClickDetector
            handleOutsideClick={() => handleShowAction(false)}
          >
            {actions
              .filter((action) => !action.isVisible || action.isVisible(item))
              .map((action, index) => (
                <div
                  key={action.nameId}
                  className={styles.action}
                  onMouseEnter={() => setHovered(index)}
                  onMouseLeave={() => setHovered(-1)}
                  onClick={() => {
                    handleActionClick(action.fieldKey);
                    handleShowAction(false);
                    setHovered(-1);
                  }}
                >
                  {hovered === index ? action.iconActive : action.icon}
                  <span>
                    <FormattedMessage id={action.nameId} />
                  </span>
                </div>
              ))}
          </OutsideClickDetector>
        </div>
      )}
      {showAction ? (
        <SettingsGreen
          ref={settingsRef}
          className={styles.settingsIcon}
          onClick={() => handleShowAction(false)}
        />
      ) : (
        <SettingsGray
          ref={settingsRef}
          className={styles.settingsIcon}
          onClick={() => {
            handleShowAction(true);
            const { top, left } = settingsRef.current.getBoundingClientRect();
            setPosition({
              top,
              left,
            });
          }}
        />
      )}
    </div>
  );
};

export default RowActions;
