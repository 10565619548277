import { CancelToken } from 'axios';
import { saveAs } from 'file-saver';
import { ProductDetails } from '../models/search-result-product';
import { SearchResultRange } from '../models/search-result-range';
import { BrowseProductFilterParams } from '../models/browse-product-filter-params';
import { BrowseProductFilterResponse } from '../models/browse-product-filter-response';
import { BrowseProductCountResponse } from '../models/browse-product-count-response';
import { SearchSummaryResponse } from '../models/search-summary-response';
import { apiInstance } from '../api/requestInterceptor';
import { FamilyResponse } from '../models/family-details';
import { ProductSearchResult } from '../models/product-search-result';
import { SearchPortResponse } from '../models/port-details';
import { generateQueryString } from '../utils/api.util';
import { SearchQueryParams } from '../models/search-result';
import {
  AdminProductDetails,
  AllProductItem,
} from '../models/admin-product-details';

/**
 * get product detail
 * @param friendlyUrl product friendly url
 * @param includePorts is include ports
 */
async function getProductDetail(
  friendlyUrl: string,
  includePorts?: boolean,
): Promise<ProductDetails> {
  const res = await apiInstance.get<ProductDetails>(
    '/products/' + friendlyUrl,
    {
      params: includePorts && {
        includePorts,
      },
    },
  );

  return res.data;
}

/**
 * get range details
 * @param friendlyName range id
 */
async function getRangeDetail(
  friendlyName: string,
): Promise<SearchResultRange> {
  const res = await apiInstance.get<SearchResultRange>(
    `ranges/${friendlyName}`,
  );
  return res.data;
}

/**
 * get product family detail
 * @param friendlyName family firendly name
 */
async function getFamilyDetail(friendlyName: string): Promise<FamilyResponse> {
  const res = await apiInstance.get<FamilyResponse>(
    `/families/${friendlyName}`,
  );

  return res.data;
}

/**
 * get search results
 * @param search search string
 * @param cancelToken cancel token
 */
async function getSearchResults(
  searchQueryParams?: SearchQueryParams,
  cancelToken?: CancelToken,
  headers?: any,
): Promise<ProductSearchResult> {
  const {
    search,
    type,
    isMarine,
    application,
    category,
    productType,
    onlyProducts,
    fromBrowse,
  } = searchQueryParams || {};
  let searchQuery: any = {};
  switch (type) {
    case 'family': {
      searchQuery = { ['familyName']: search };
      break;
    }
    case 'range': {
      searchQuery = { ['rangeName']: search };
      break;
    }
    case 'product': {
      searchQuery = { ['name']: search };
      break;
    }
    case 'category': {
      searchQuery = { ['categoryName']: search };
      break;
    }
    case 'application': {
      searchQuery = { ['applicationName']: search };
      break;
    }
    default: {
      break;
    }
  }

  if (isMarine) {
    searchQuery = {
      ...searchQuery,
      ['includePorts']: true,
    };
  }

  if (!!application && application.length > 0) {
    searchQuery = {
      ...searchQuery,
      ['applicationId']: application,
    };
  }

  if (!!category && category.length > 0) {
    searchQuery = {
      ...searchQuery,
      ['categoryId']: category,
    };
  }

  if (!!productType && productType.length > 0) {
    searchQuery = {
      ...searchQuery,
      ['productTypeId']: productType,
    };
  }

  if (onlyProducts) {
    searchQuery = {
      ...searchQuery,
      ['onlyProducts']: true,
    };
  }

  if (fromBrowse) {
    searchQuery = {
      ...searchQuery,
      ['fromBrowse']: true,
    };
  }

  const res = await apiInstance.get<ProductSearchResult>(
    `/products?${generateQueryString(searchQuery)}`,
    {
      cancelToken,
      headers,
    },
  );

  return res.data;
}

/**
 * get search results summary
 * @param search search
 * @param cancelToken cancel token
 */
async function getSearchResultsSummary(
  search: string,
  cancelToken?: CancelToken,
): Promise<SearchSummaryResponse> {
  const res = await apiInstance.get<SearchSummaryResponse>('/search', {
    cancelToken,
    params: {
      text: search,
    },
  });

  return res.data;
}

/**
 * get browse product filters
 * @param params query params
 */
async function getBrowseProductFilters(
  params?: BrowseProductFilterParams,
): Promise<BrowseProductFilterResponse> {
  const res = await apiInstance.get<BrowseProductFilterResponse>(
    '/browseProducts/filters',
    {
      params: {
        ...params,
      },
      cache: {
        ignoreCache: false,
      },
    },
  );

  return res.data;
}

async function getBrowseProductCount(
  params?: BrowseProductFilterParams,
): Promise<BrowseProductCountResponse> {
  const res = await apiInstance.get<BrowseProductCountResponse>(
    '/browseProducts/count',
    {
      params: {
        ...params,
      },
    },
  );

  return res.data;
}
async function getBrowseCaseStudyFilters(
  params?: BrowseProductFilterParams,
): Promise<BrowseProductFilterResponse> {
  const res = await apiInstance.get<BrowseProductFilterResponse>(
    '/browseCaseStudies/filters',
    {
      params: {
        ...params,
      },
      cache: {
        ignoreCache: false,
      },
    },
  );

  return res.data;
}
/**
 * get browse product count
 * @param params query params
 */
async function getBrowseCaseStudyCount(
  params?: BrowseProductFilterParams,
): Promise<BrowseProductCountResponse> {
  const res = await apiInstance.get<BrowseProductCountResponse>(
    '/browseCaseStudies/count',
    {
      params: {
        ...params,
      },
    },
  );

  return res.data;
}
async function getBrowseCaseStudy(
  params?: BrowseProductFilterParams,
): Promise<BrowseProductCountResponse> {
  const res = await apiInstance.get<BrowseProductCountResponse>(
    '/browseCaseStudies/caseStudies',
    {
      params: {
        ...params,
      },
    },
  );

  return res.data;
}

/**
 * get ports for product
 * @param country country
 */
async function getPortsForProduct(
  cancelToken: CancelToken,
  applicationIds: string[] | null = null,
  productName: string | null = null,
  portName: string | null = null,
  countryName: string | null = null,
  countryId: string | null = null,
): Promise<SearchPortResponse> {
  const query = generateQueryString({
    applicationIds: (applicationIds && applicationIds.join(',')) || '',
    productName,
    name: portName,
    countryName,
    countryId,
  });
  const res = await apiInstance.get<SearchPortResponse>(`/ports?${query}`, {
    cancelToken,
    cache: {
      ignoreCache: false,
    },
  });

  return res.data;
}

async function downloadProductDatasheet(productDocumentId: number) {
  const res = await apiInstance.get(
    `/productDocuments/${productDocumentId}/downloadProductDataSheet`,
    { responseType: 'blob' },
  );

  if (res.status === 200) {
    const filename =
      res.headers['content-disposition']?.split('filename=')[1] ||
      'product-datasheet.pdf';
    saveAs(
      res.data,
      decodeURIComponent(filename.replace('"', '').replace('"', '')),
    );
  }

  return res;
}

async function downloadProductSafetyDatasheet(productDocumentId: number) {
  const res = await apiInstance.get(
    `/productDocuments/${productDocumentId}/downloadSafetyDataSheet`,
    { responseType: 'blob' },
  );

  if (res.status === 200) {
    const filename =
      res.headers['content-disposition']?.split('filename=')[1] ||
      'product-datasheet.pdf';
    saveAs(
      res.data,
      decodeURIComponent(filename.replace('"', '').replace('"', '')),
    );
  }

  return res;
}

async function checkAvailability(
  name: string,
  friendlyUrl: string,
  productSectors: {
    id?: number;
    sectorId: number;
  }[],
  productId?: number,
): Promise<any> {
  const res = await apiInstance.post<void>('/products/check', {
    id: productId || null,
    name,
    friendlyUrl,
    productSectors,
  });

  return res.data;
}

async function createProduct(payload: any): Promise<AdminProductDetails> {
  const res = await apiInstance.post<AdminProductDetails>('/products', payload);

  return res.data;
}

async function updateProduct(
  productId: number,
  payload: AdminProductDetails,
): Promise<AdminProductDetails> {
  const res = await apiInstance.put<AdminProductDetails>(
    '/products/byId/' + productId,
    payload,
  );

  return res.data;
}

async function getProductById(productId: number): Promise<AdminProductDetails> {
  const res = await apiInstance.get<AdminProductDetails>(
    '/products/byId/' + productId,
  );

  return res.data;
}

async function getAllProducts(): Promise<AllProductItem[]> {
  const res = await apiInstance.get<AllProductItem[]>('/products/all');
  return res.data;
}

/**
 * deactivate product
 * @param id product id
 */
async function deactivateProduct(id: number | undefined): Promise<void> {
  const res = await apiInstance.put<void>(`products/byId/${id}/deactivate`);

  return res.data;
}
async function activateProduct(id: number | undefined): Promise<void> {
  const res = await apiInstance.put<void>(`products/byId/${id}/activate`);

  return res.data;
}

export {
  getProductDetail,
  getRangeDetail,
  getFamilyDetail,
  getSearchResults,
  getSearchResultsSummary,
  getBrowseProductFilters,
  getBrowseProductCount,
  getPortsForProduct,
  downloadProductDatasheet,
  downloadProductSafetyDatasheet,
  checkAvailability,
  createProduct,
  updateProduct,
  getProductById,
  getAllProducts,
  deactivateProduct,
  activateProduct,
  getBrowseCaseStudyFilters,
  getBrowseCaseStudyCount,
  getBrowseCaseStudy,
};
