import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { FilterOption } from '../models/filter-option';
import { cloneDeep } from 'lodash';
import { PortDetails, SearchPortResponse } from '../models/port-details';
import { CountryItem, LookupItem, SubApplicationItem } from '../models/lookups';

export type DeliveryType = 'Barge' | 'Truck';

interface PortFilters {
  countries: FilterOption[];
  applications: FilterOption[];
  minBulkSupply: FilterOption | null;
  minDumSupply: FilterOption | null;
  noticeRequired: FilterOption[];
  delivery: FilterOption[];
}

export interface PortFiltersOptions {
  countries: FilterOption[];
  applications: FilterOption[];
  minBulkSupply: FilterOption[];
  minDumSupply: FilterOption[];
  noticeRequired: FilterOption[];
  delivery: FilterOption[];
}

interface PortFilterIds {
  countryId: { [k: string]: string };
  applicationId: { [k: string]: string };
  bulkSupplyId: { [k: string]: string };
  dumSupplyId: { [k: string]: string };
  noticePeriodId: { [k: string]: string };
  deliveryId: { [k: string]: string };
}

export interface PortFilterSliceState {
  ports: PortDetails[];
  filteredPorts: PortDetails[];
  filters: PortFiltersOptions;
  selectedFilters: PortFilters;
}

/**
 * filter ports
 * @param ports ports
 * @param selectedFilters filters
 */
function filterPorts(
  ports: PortDetails[],
  selectedFilters: PortFilters,
): PortDetails[] {
  const countryValues: { [k: string]: boolean } = {};
  const noticeValues: { [k: string]: boolean } = {};
  const deliveryValues: { [k: string]: boolean } = {};

  const hasCountryFilter = selectedFilters.countries.length > 0;
  const hasMinBulkSupplyFilter = !!selectedFilters.minBulkSupply;
  const hasMinDrumFilter = !!selectedFilters.minDumSupply;
  const hasNoticeRequiredFilter = selectedFilters.noticeRequired.length > 0;
  const hasDelieryselectedFilters = selectedFilters.delivery.length > 0;

  selectedFilters.countries.forEach(
    (country) => (countryValues[country.value] = true),
  );
  selectedFilters.noticeRequired.forEach(
    (item) => (noticeValues[item.value] = true),
  );
  selectedFilters.delivery.forEach(
    (item) => (deliveryValues[item.value] = true),
  );

  const filteredPorts = ports.filter((port) => {
    const bargeDelivery = port.minBulkBargeOrderInLitres >= 0 ? 'Barge' : '';
    const truckDelivery = port.minBulkTruckOrderInLitres >= 0 ? 'Truck' : '';
    return (
      (hasCountryFilter ? countryValues[port.countryId] : true) &&
      (hasMinBulkSupplyFilter
        ? selectedFilters.minBulkSupply &&
          port.minBulkIbcOrderInLitres >= 0 &&
          port.minBulkIbcOrderInLitres <=
            parseInt(selectedFilters.minBulkSupply.value)
        : true) &&
      (hasMinDrumFilter
        ? selectedFilters.minDumSupply &&
          port.minPackOrderInDrums <=
            parseInt(selectedFilters.minDumSupply.value)
        : true) &&
      (hasNoticeRequiredFilter ? noticeValues[port.workingDaysNotice] : true) &&
      ((hasDelieryselectedFilters ? deliveryValues[bargeDelivery] : true) ||
        (hasDelieryselectedFilters ? deliveryValues[truckDelivery] : true))
    );
  });

  return filteredPorts;
}

/**
 * get filtered ids from ports
 * @param ports ports
 */
function getFilterIds(
  products: PortDetails[],
  applications: SubApplicationItem[],
): PortFilterIds {
  const countryId: { [k: string]: string } = {};
  const applicationId: { [k: string]: string } = {};
  const bulkSupplyId: { [k: string]: string } = {};
  const dumSupplyId: { [k: string]: string } = {};
  const noticePeriodId: { [k: string]: string } = {};
  const deliveryId: { [k: string]: string } = {};

  // to remove duplication
  products.forEach((item) => {
    if (item.countryId) {
      countryId[item.countryId] = item.country || '';
    }
    if (item.minBulkIbcOrderInLitres >= 0) {
      bulkSupplyId[item.minBulkIbcOrderInLitres] =
        item.minBulkIbcOrderInLitres.toString();
    }
    if (item.minPackOrderInDrums >= 0) {
      dumSupplyId[item.minPackOrderInDrums] =
        item.minPackOrderInDrums.toString();
    }
    if (item.workingDaysNotice) {
      noticePeriodId[item.workingDaysNotice] = item.workingDaysNotice;
    }
  });

  deliveryId['Truck'] = 'Truck';
  deliveryId['Barge'] = 'Barge';

  if (applications) {
    applications.forEach((x) => {
      applicationId[x.applicationId] = x.name || '';
    });
  }

  return {
    countryId,
    applicationId,
    bulkSupplyId,
    dumSupplyId,
    noticePeriodId,
    deliveryId,
  };
}

/**
 * get filters with filter ids extracted from products
 * @param familyId family ids
 * @param rangeId range ids
 * @param categoryId category ids
 * @param applicationId application ids
 * @param viscosityId viscosity ids
 */
function getFiltersWithFilterIds({
  countryId,
  applicationId,
  bulkSupplyId,
  dumSupplyId,
  noticePeriodId,
  deliveryId,
}: PortFilterIds) {
  return {
    portName: '',
    countries: Object.keys(countryId).map((key) => {
      return {
        name: countryId[key],
        value: key,
      };
    }),
    applications: Object.keys(applicationId).map((key) => {
      return {
        name: applicationId[key],
        value: key,
      };
    }),
    minBulkSupply: Object.keys(bulkSupplyId).map((key) => {
      return {
        name: bulkSupplyId[key],
        value: key,
      };
    }),
    minDumSupply: Object.keys(dumSupplyId).map((key) => {
      return {
        name: dumSupplyId[key],
        value: key,
      };
    }),
    noticeRequired: Object.keys(noticePeriodId).map((key) => {
      return {
        name: noticePeriodId[key],
        value: key,
      };
    }),
    delivery: Object.keys(deliveryId).map((key) => {
      return {
        name: deliveryId[key],
        value: key,
      };
    }),
  };
}

/**
 * toggle selected filters
 * @param key filters key
 * @param filter filter option to toggle
 * @param selectedFilters selected filters
 */
function toggleSelectedFilter(
  key: keyof PortFilters,
  filter: FilterOption | FilterOption[],
  selectedFilters: PortFilters,
) {
  const selectedFiltersByType = selectedFilters[key];
  let filtered: string | FilterOption | FilterOption[] = [];

  if (
    key === 'countries' ||
    key === 'delivery' ||
    key === 'noticeRequired' ||
    key === 'applications'
  ) {
    filtered = (selectedFiltersByType as FilterOption[]).filter(
      (item) => item.value !== (filter as FilterOption).value,
    );
  } else {
    filtered = filter;
  }

  if (Array.isArray(filtered)) {
    // add
    if (filtered.length === (selectedFiltersByType as FilterOption[]).length) {
      return {
        ...selectedFilters,
        [key]: [...(selectedFiltersByType as FilterOption[]), filter],
      };
    } else {
      return {
        ...selectedFilters,
        [key]: filtered,
      };
    }
  } else {
    // remove
    return {
      ...selectedFilters,
      [key]: filtered,
    };
  }
}

/**
 * get filtered search result
 * @param searchResult search result
 * @param filters filters
 */
function getFilteredSearchResult(
  searchResult: PortDetails[],
  filters?: PortFilters,
): PortDetails[] {
  let clone = cloneDeep(searchResult);

  if (filters) {
    clone = filterPorts(clone, filters);
  }

  return clone;
}

/**
 * manage port filter state
 */
export const portFilterSlice = createSlice({
  name: 'port-filter',
  initialState: {
    ports: [],
    filteredPorts: [],
    filters: {
      countries: [],
      applications: [],
      minBulkSupply: [],
      minDumSupply: [],
      noticeRequired: [],
      delivery: [],
    },
    selectedFilters: {
      countries: [],
      applications: [],
      minBulkSupply: null,
      minDumSupply: null,
      noticeRequired: [],
      delivery: [],
    },
  } as PortFilterSliceState,
  reducers: {
    // set search result and create filters
    setFilterablePortSearchResult: (
      state,
      action: PayloadAction<{
        portResponse: SearchPortResponse;
        countries: CountryItem[];
        applications: LookupItem[];
      }>,
    ) => {
      const ports = action.payload.portResponse.ports;
      const countryMap = new Map(
        action.payload.countries.map((i) => [i.id, i.name]),
      );
      const applicationMap = new Map(
        action.payload.applications.map((i) => [i.id, i.name]),
      );
      ports.forEach((p) => {
        p.country = countryMap.get(p.countryId);
      });
      const portApplications: SubApplicationItem[] =
        action.payload.portResponse.applicationIds.map((x) => ({
          applicationId: x,
          name: applicationMap.get(x) || '',
          id: x,
        }));

      return {
        ...state,
        ports,
        filteredPorts: getFilteredSearchResult(ports, state.selectedFilters),
        filters: getFiltersWithFilterIds(getFilterIds(ports, portApplications)),
      };
    },
    // toggle filter
    toggleFilter: (
      state,
      action: PayloadAction<{
        key: keyof PortFilters;
        filter: FilterOption | FilterOption[];
      }>,
    ) => {
      const { key, filter } = action.payload;
      const selectedFilters = toggleSelectedFilter(
        key,
        filter,
        state.selectedFilters,
      );

      return {
        ...state,
        selectedFilters,
        filteredPorts: getFilteredSearchResult(state.ports, selectedFilters),
      };
    },
    // clear filter for type
    clearFilter: (state, action: PayloadAction<{ key: keyof PortFilters }>) => {
      const { key } = action.payload;
      let defaultValue: FilterOption | FilterOption[] | string | null = [];

      switch (key) {
        case 'countries':
        case 'applications': {
          defaultValue = [];
          break;
        }
        case 'delivery':
        case 'noticeRequired':
        case 'minDumSupply':
        case 'minBulkSupply': {
          defaultValue = null;
          break;
        }
      }

      const selectedFilters = {
        ...state.selectedFilters,
        [key]: defaultValue,
      };

      return {
        ...state,
        selectedFilters,
        filteredPorts: getFilteredSearchResult(state.ports, selectedFilters),
      };
    },
    // clear all filters
    clearAllFilters: (state) => {
      const selectedFilters = {
        countries: [],
        applications: [],
        minBulkSupply: null,
        minDumSupply: null,
        noticeRequired: [],
        delivery: [],
      };

      return {
        ...state,
        selectedFilters,
        filteredPorts: getFilteredSearchResult(state.ports),
      };
    },
    updatePort: (
      state,
      action: PayloadAction<{
        updatedPort: PortDetails;
        countries: CountryItem[];
      }>,
    ) => {
      const { updatedPort } = action.payload;
      const tempFilteredPorts = _.cloneDeep(state.filteredPorts);
      const currentPortIndex = state.filteredPorts.findIndex(
        (port) => port.id === updatedPort.id,
      );
      const countryMap = new Map(
        action.payload.countries.map((i) => [i.id, i.name]),
      );
      if (currentPortIndex !== -1) {
        updatedPort.country = countryMap.get(updatedPort.countryId);
        tempFilteredPorts[currentPortIndex] = updatedPort;
      }

      return {
        ...state,
        filteredPorts: tempFilteredPorts,
      };
    },
  },
});

export const {
  setFilterablePortSearchResult,
  // setFilterableFamily,
  toggleFilter,
  // toggleFilterById,
  clearFilter,
  clearAllFilters,
  updatePort,
} = portFilterSlice.actions;

export default portFilterSlice.reducer;
