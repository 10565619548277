import cn from 'classnames';
import { Fragment, PropsWithoutRef } from 'react';

import { ReactComponent as CheckCircleGreen } from '../../../icons/check-circle-green.svg';
import styles from './HorizontalStepper.module.scss';

interface HorizontalStepperProps {
  steps: string[];
  currentStep: number;
  className?: string;
}

function HorizontalStepper({
  steps = [],
  currentStep = 0,
  className,
}: PropsWithoutRef<HorizontalStepperProps>) {
  if (steps.length === 0) return null;

  return (
    <div className={cn(styles.container, className)}>
      {steps.map((step: string, index: number) => (
        <Fragment key={step}>
          <div className={styles.step}>
            {index < currentStep ? (
              <CheckCircleGreen className={styles.completedStepCircle} />
            ) : (
              <div
                className={cn(styles.numberCircle, {
                  [styles.activeStepCircle]: index === currentStep,
                })}
              >
                {index + 1}
              </div>
            )}
            <div
              className={cn(styles.label, {
                [styles.completedLabel]: index < currentStep,
                [styles.currentLabel]: index === currentStep,
              })}
            >
              {step}
            </div>
          </div>
          {index < steps.length - 1 && (
            <div
              className={cn(styles.seperationBar, {
                [styles.seperationBarCompleted]: index < currentStep,
              })}
            />
          )}
        </Fragment>
      ))}
    </div>
  );
}

export default HorizontalStepper;
