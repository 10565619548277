import styles from './AboutPort.module.scss';
import AboutDetail from '../../common/AboutDetail/AboutDetail';
import AboutDetailItem from '../../common/AboutDetail/AboutDetailItem/AboutDetailItem';
import { ReactComponent as Anchor } from '../../../icons/anchor.svg';
import { ReactComponent as Location } from '../../../icons/location.svg';
import { FormattedMessage, useIntl } from 'react-intl';
import { PortDetails } from '../../../models/port-details';
import { PropsWithoutRef } from 'react';
import { latlng2HumanReadable } from '../../../utils/common.util';

interface AboutPortProps {
  port: PortDetails;
  clickNearby: () => void;
}
/**
 * about port
 * @param port port
 */
function AboutPort({ port, clickNearby }: PropsWithoutRef<AboutPortProps>) {
  const intl = useIntl();

  return (
    <AboutDetail
      className={styles.container}
      title={intl.formatMessage({ id: 'about-this-port-label' })}
    >
      <AboutDetailItem className={styles.item} icon={<Location />}>
        <a
          href={`https://www.google.com/maps/place/${port.latitude}+${port.longitude}`}
          target="_blank"
          rel="noreferrer"
        >
          {latlng2HumanReadable(Number(port.latitude), Number(port.longitude))}
        </a>
      </AboutDetailItem>

      <AboutDetailItem
        onClick={clickNearby}
        className={styles.item}
        icon={<Anchor />}
      >
        <FormattedMessage id={'nearby-ports-label'} />
      </AboutDetailItem>
    </AboutDetail>
  );
}

export default AboutPort;
