import { useState, ReactElement, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import { useLocation, useMatch, useNavigate } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

import OutsideClickDetector from '../../OutsideClickDetector/OutsideClickDetector';

import { ReactComponent as Drum } from '../../../../icons/drum-green.svg';
import { ReactComponent as DrumWhite } from '../../../../icons/drum-white.svg';
import { ReactComponent as Anchor } from '../../../../icons/anchor.svg';
import { ReactComponent as AnchorWhite } from '../../../../icons/anchor-white.svg';
import { ReactComponent as ManageOEM } from '../../../../icons/manage-oem.svg';
import { ReactComponent as ManageOEMWhite } from '../../../../icons/manage-oem-white.svg';
import { ReactComponent as ManageAnnouncements } from '../../../../icons/manage-announcements.svg';
import { ReactComponent as ManageAnnouncementsWhite } from '../../../../icons/manage-announcements-white.svg';
import { ReactComponent as Order } from '../../../../icons/order.svg';
import { ReactComponent as OrderWhite } from '../../../../icons/order-white.svg';
import { ReactComponent as AddIcon } from '../../../../icons/add-circle-green.svg';
import { ReactComponent as AddWhiteIcon } from '../../../../icons/add-circle.svg';
import { ReactComponent as ManageStockpoints } from '../../../../icons/barge-green.svg';
import { ReactComponent as ManageStockpointsWhite } from '../../../../icons/barge-white.svg';
import { ReactComponent as ManageOrderContact } from '../../../../icons/manage-order-contact.svg';
import { ReactComponent as ManageOrderContactWhite } from '../../../../icons/manage-order-contact-white.svg';
import { ReactComponent as ManagePortDistributor } from '../../../../icons/distributor-green.svg';
import { ReactComponent as ManagePortDistributorWhite } from '../../../../icons/distributor-white.svg';
import { ReactComponent as EditIcon } from '../../../../icons/edit-green.svg';
import { ReactComponent as EditWhiteIcon } from '../../../../icons/edit-white.svg';
import { ReactComponent as DocIcon } from '../../../../icons/ic-doc-green.svg';
import { ReactComponent as DocWhiteIcon } from '../../../../icons/ic-doc-white.svg';
import { ReactComponent as XOctagonIcon } from '../../../../icons/x-octagon-green.svg';
import { ReactComponent as XOctagonWhiteIcon } from '../../../../icons/x-octagon-white.svg';
import { ReactComponent as PlusCircleIcon } from '../../../../icons/plus-circle-green.svg';
import { ReactComponent as PlusCircleWhiteIcon } from '../../../../icons/plus-circle-white.svg';
import { ReactComponent as LibraryAddIcon } from '../../../../icons/library-add-green.svg';
import { ReactComponent as LibraryAddWhiteIcon } from '../../../../icons/library-add-white.svg';
import { ReactComponent as WebIcon } from '../../../../icons/web.svg';
import { ReactComponent as WebWhiteIcon } from '../../../../icons/web-white.svg';
import { ReactComponent as ManageAccountIcon } from '../../../../icons/manage-accounts.svg';
import { ReactComponent as ManageAccountWhiteIcon } from '../../../../icons/manage-accounts-white.svg';
import { ReactComponent as ManageBannerIcon } from '../../../../icons/banner.svg';
import { ReactComponent as ManageBannerWhiteIcon } from '../../../../icons/banner-white.svg';
import { ReactComponent as HelpIcon } from '../../../../icons/help.svg';
import { ReactComponent as HelpWhiteIcon } from '../../../../icons/help-white.svg';
import { ReactComponent as LocalizedProduct } from '../../../../icons/checked-lines-green.svg';
import { ReactComponent as LocalizedProductWhite } from '../../../../icons/checked-lines-white.svg';
import { ReactComponent as UsersIcon } from '../../../../icons/users.svg';
import { ReactComponent as UsersWhiteIcon } from '../../../../icons/users-white.svg';
import { ReactComponent as UserPlusIcon } from '../../../../icons/user-plus-green.svg';
import { ReactComponent as UserPlusWhiteIcon } from '../../../../icons/user-plus-white.svg';
import { ReactComponent as UserListIcon } from '../../../../icons/user-list-green.svg';
import { ReactComponent as UserListWhiteIcon } from '../../../../icons/user-list-white.svg';
import { ReactComponent as DeactivateWhite } from '../../../../icons/deactive-white.svg';
import { ReactComponent as DeactivateGreen } from '../../../../icons/deactive-green.svg';
import { ReactComponent as ActivateWhite } from '../../../../icons/check-circle-small-white.svg';
import { ReactComponent as ActivateGreen } from '../../../../icons/check-circle-small.svg';
import { ReactComponent as LinkGreenIcon } from '../../../../icons/link.svg';
import { ReactComponent as LinkWhiteIcon } from '../../../../icons/link-white.svg';

import { RootStore } from '../../../../store';

import { OemDetail } from '../../../../models/oem-detail';

import { updateActivateState } from '../../../../features/oemSlice';
import { showGenericModal } from '../../../../features/genericModalSlice';

import styles from './HeaderMenu.module.scss';
import { ProductDetails } from '../../../../models/search-result-product';
import { getProductInfoFromSessionStorage } from '../../../../services/LocalStorageService';
import {
  toggleDiactiveDialog,
  toggleactiveDialog,
} from '../../../../features/productDetailsSlice';
import { activatePort, deactivatePort } from '../../../../services/PortService';
import { hideLoading, showLoading } from '../../../../features/loadingSlice';

/**
 * main header menu component
 */
type ItemType = {
  nameId: string;
  icon: ReactElement;
  iconActive: ReactElement;
  to?: string;
  render?: (route: string, data: OemDetail | any | null) => boolean;
  subItems?: ItemType[];
  subPages?: string[];
  role?: (role: string, sectionId: number) => boolean;
  parentType?: string;
};

const checkSubItemSelected = (subItems: ItemType[], currentRoute: string) => {
  for (let i = 0; i < subItems.length; i++) {
    // Check currentRoute with any subItems route and set as active
    if (subItems[i].to === currentRoute) {
      return i;
    }
  }

  // Nothing matched
  return -1;
};

type MenuItemProps = {
  item: ItemType;
  active: boolean;
  onClick: () => void;
  subItems?: ItemType[];
  userRole: string;
  sectionId: number;
  hideMenuOnNavigation: () => void;
};

const MenuItem = ({
  item,
  active,
  onClick,
  subItems = [],
  userRole,
  sectionId,
  hideMenuOnNavigation,
}: MenuItemProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { family } = useSelector((state: RootStore) => state.familyFilter);
  const { range } = useSelector((state: RootStore) => state.rangeDetails);
  const { oemDetail } = useSelector((state: RootStore) => state.oem);
  const { port } = useSelector((state: RootStore) => state.portDetails);
  const productDetail = getProductInfoFromSessionStorage();

  const [showSubItems, setShowSubItems] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [subItemActive, setSubItemActive] = useState(-1);

  const { unsaved } = useSelector((state: RootStore) => state.genericModal);

  useEffect(() => {
    const currentRoute = location.pathname;
    setSubItemActive(checkSubItemSelected(subItems, currentRoute));
    setHovered(false);
  }, [location]);

  const changeRoute = (route: string) => {
    hideMenuOnNavigation();
    if (unsaved) {
      dispatch(
        showGenericModal({
          type: 'confirmation',
          titleId: 'unsaved-changes-label',
          messageId: 'unsaved-changes-message',
          to: route,
        }),
      );
    } else {
      navigate(route);
    }
  };

  const handleRouteChange = () => {
    if (item.to) {
      changeRoute(item.to);
    }
    if (item.render) {
      if (location.pathname.startsWith('/port/')) {
        if (item.nameId === 'header-menu-manage-ports-edit-port') {
          const paths = location.pathname.split('/');
          const portFriendlyUrl = paths[paths.length - 1];
          changeRoute(`/admin/port/edit/${portFriendlyUrl}`);
        } else if (
          item.nameId ===
          'header-menu-manage-ports-manage-products-at-this-port'
        ) {
          const paths = location.pathname.split('/');
          const portFriendlyUrl = paths[paths.length - 1];
          changeRoute(`/admin/manage-port-products/${portFriendlyUrl}`);
        } else if (item.nameId === 'deactivate-port') {
          dispatch(showLoading());
          deactivatePort(port?.id || 0)
            .then(() => {
              window.location.reload();
            })
            .finally(() => {
              dispatch(hideLoading());
            });
        } else if (item.nameId === 'activate-port') {
          dispatch(showLoading());
          activatePort(port?.id || 0)
            .then(() => {
              window.location.reload();
            })
            .finally(() => {
              dispatch(hideLoading());
            });
        }
      } else if (location.pathname.startsWith('/admin/oem/')) {
        if (item.nameId === 'header-menu-edit-oem') {
          const paths = location.pathname.split('/');
          const oemId = paths[paths.length - 1];
          changeRoute(`/admin/oem/${oemId}/edit`);
        } else if (item.nameId === 'header-menu-activate-oem') {
          const paths = location.pathname.split('/');
          const oemId = parseInt(paths[paths.length - 1]);
          hideMenuOnNavigation();
          dispatch(
            updateActivateState({
              oemId,
              activate: true,
            }),
          );
        } else if (item.nameId === 'header-menu-deactivate-oem') {
          const paths = location.pathname.split('/');
          const oemId = parseInt(paths[paths.length - 1]);
          hideMenuOnNavigation();
          dispatch(
            updateActivateState({
              oemId,
              activate: false,
            }),
          );
        }
      } else if (location.pathname.startsWith('/range/')) {
        changeRoute(`/admin/range/edit/${range?.id || ''}`);
      } else if (location.pathname.startsWith('/family/')) {
        changeRoute(`/admin/family/edit/${family?.id || ''}`);
      } else if (location.pathname.startsWith('/product/')) {
        if (item.nameId === 'header-menu-deactive-product') {
          dispatch(toggleDiactiveDialog(true));
        }
        if (item.nameId === 'header-menu-active-product') {
          console.log('active');
          dispatch(toggleactiveDialog(true));
        }
      }
    }
    setShowSubItems(!showSubItems);
  };

  return (
    <OutsideClickDetector
      className={styles.outsideClickDetectorContainer}
      handleOutsideClick={() => setShowSubItems(false)}
    >
      {(!item.role || (item.role && item.role(userRole, sectionId))) && (
        <div
          key={item.nameId}
          className={styles.menuItemWrapper}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          onClick={handleRouteChange}
        >
          <div
            className={cn(
              styles.menuItem,
              active || hovered ? styles.menuItemActive : '',
            )}
            onClick={onClick}
          >
            {active || hovered ? item.iconActive : item.icon}
            <span>
              <FormattedMessage id={item.nameId} />
            </span>
          </div>
          {active && showSubItems && subItems?.length > 0 && (
            <div className={styles.subItemWrapper}>
              {subItems?.map((item: ItemType, index: number) => {
                if (item.render) {
                  if (item.parentType === 'products') {
                    return (
                      item.render(location.pathname, productDetail) && (
                        <MenuItem
                          key={item.nameId}
                          item={item}
                          active={subItemActive === index}
                          onClick={() => setSubItemActive(index)}
                          userRole={userRole}
                          sectionId={sectionId}
                          hideMenuOnNavigation={hideMenuOnNavigation}
                        />
                      )
                    );
                  } else if (item.parentType === 'port') {
                    return (
                      item.render(location.pathname, port) && (
                        <MenuItem
                          key={item.nameId}
                          item={item}
                          active={subItemActive === index}
                          onClick={() => setSubItemActive(index)}
                          userRole={userRole}
                          sectionId={sectionId}
                          hideMenuOnNavigation={hideMenuOnNavigation}
                        />
                      )
                    );
                  } else {
                    return (
                      item.render(location.pathname, oemDetail) && (
                        <MenuItem
                          key={item.nameId}
                          item={item}
                          active={subItemActive === index}
                          onClick={() => setSubItemActive(index)}
                          userRole={userRole}
                          sectionId={sectionId}
                          hideMenuOnNavigation={hideMenuOnNavigation}
                        />
                      )
                    );
                  }
                }

                return (
                  <MenuItem
                    key={item.nameId}
                    item={item}
                    active={subItemActive === index}
                    onClick={() => setSubItemActive(index)}
                    userRole={userRole}
                    sectionId={sectionId}
                    hideMenuOnNavigation={hideMenuOnNavigation}
                  />
                );
              })}
            </div>
          )}
        </div>
      )}
    </OutsideClickDetector>
  );
};

type HeaderMenuProps = {
  userRole: string;
  sectionId: number;
  openAdminMenu: boolean;
  toggleAdminMenu: (state: boolean) => void;
};

/**
 * main header menu component
 */
const HeaderMenu = ({
  userRole,
  sectionId,
  openAdminMenu,
  toggleAdminMenu,
}: HeaderMenuProps) => {
  const location = useLocation();
  const [selectedIdx, setSelectedIdx] = useState(-1);
  const currentRoute = location.pathname;

  const matchProductDetailsPage = useMatch(
    '/product/:friendlyUrl/:sector/:culture',
  );

  const matchPortDetailsPage = useMatch('/port/:friendlyUrl');

  const productId = useSelector(
    (rootState: RootStore) => rootState.productDetails.productId,
  );

  const menu: ItemType[] = useMemo(
    () => [
      {
        nameId: 'header-menu-manage-products',
        icon: <Drum />,
        iconActive: <DrumWhite />,
        role: (role, sectionId) => role !== 'Approver',
        subItems: [
          {
            nameId: 'header-menu-add-product',
            icon: <AddIcon />,
            iconActive: <AddWhiteIcon />,
            to: '/admin/product/add',
          },
          {
            nameId: 'header-menu-manage-products-add-casestudy',
            icon: <AddIcon />,
            iconActive: <AddWhiteIcon />,
            to: '/admin/add-casestudy',
            render: (route) => route.startsWith('/product/'),
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            role: (role, sectionId) =>
              role === 'Approver' ||
              role === 'Super Admin' ||
              role === 'DMS Admin',
          },
          ...(matchProductDetailsPage
            ? [
                {
                  nameId: 'header-menu-deactive-product',
                  icon: <XOctagonIcon />,
                  iconActive: <XOctagonWhiteIcon />,
                  parentType: 'products',
                  render: (_path: string, data: ProductDetails) => {
                    return (
                      !!data &&
                      !!data.isActiveInYourCountry &&
                      data.isActiveInYourCountry
                    );
                  },
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  role: (role: any, sectionId: any) =>
                    role === 'Approver' ||
                    role === 'Super Admin' ||
                    role === 'DMS Admin',
                },
                {
                  nameId: 'header-menu-active-product',
                  icon: <ManageOEM />,
                  iconActive: <ManageOEMWhite />,
                  parentType: 'products',
                  render: (_path: string, data: ProductDetails) => {
                    return (
                      !!data &&
                      !data.isActiveInYourCountry &&
                      !data.isActiveInYourCountry
                    );
                  },
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  role: (role: any, sectionId: any) =>
                    role === 'Approver' ||
                    role === 'Super Admin' ||
                    role === 'DMS Admin',
                },
                {
                  nameId: 'header-menu-edit-product',
                  icon: <EditIcon />,
                  iconActive: <EditWhiteIcon />,
                  to: `/admin/product/edit/${productId}`,
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  role: (role: any, sectionId: any) =>
                    role === 'Approver' ||
                    role === 'Super Admin' ||
                    role === 'DMS Admin',
                },
              ]
            : []),
          {
            nameId: 'header-menu-manage-products-manage-find-distributor',
            icon: <AddIcon />,
            iconActive: <AddWhiteIcon />,
            to: '/admin/manage-find-a-distributor-link',
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            role: (role, sectionId) =>
              role === 'Approver' ||
              role === 'Super Admin' ||
              role === 'DMS Admin',
          },
          {
            nameId: 'header-menu-manage-products-add-range',
            icon: <AddIcon />,
            iconActive: <AddWhiteIcon />,
            to: '/admin/range/add',
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            role: (role, sectionId) =>
              role === 'Approver' ||
              role === 'Super Admin' ||
              role === 'DMS Admin',
          },
          {
            nameId: 'header-menu-manage-products-edit-range',
            icon: <EditIcon />,
            iconActive: <EditWhiteIcon />,
            to: '/admin/range/add',
            render: (route) => route.startsWith('/range/'),
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            role: (role, sectionId) =>
              role === 'Approver' ||
              role === 'Super Admin' ||
              role === 'DMS Admin',
          },
          {
            nameId: 'header-menu-manage-products-add-family',
            icon: <AddIcon />,
            iconActive: <AddWhiteIcon />,
            to: '/admin/family/add',
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            role: (role, sectionId) =>
              role === 'Approver' ||
              role === 'Super Admin' ||
              role === 'DMS Admin',
          },
          {
            nameId: 'header-menu-manage-products-edit-family',
            icon: <EditIcon />,
            iconActive: <EditWhiteIcon />,
            to: '/admin/family/add',
            render: (route) => route.startsWith('/family/'),
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            role: (role, sectionId) =>
              role === 'Approver' ||
              role === 'Super Admin' ||
              role === 'DMS Admin',
          },
          {
            nameId: 'header-manage-multiple-products',
            icon: <LocalizedProduct />,
            iconActive: <LocalizedProductWhite />,
            to: '/admin/manage-multiple-products',
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            role: (role, sectionId) =>
              role === 'Approver' ||
              role === 'Super Admin' ||
              role === 'DMS Admin',
          },
          {
            nameId: 'header-manage-localised-products',
            icon: <LocalizedProduct />,
            iconActive: <LocalizedProductWhite />,
            to: '/admin/manage-localised-products',
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            role: (role, sectionId) =>
              role === 'Approver' ||
              role === 'Super Admin' ||
              role === 'DMS Admin',
          },
          {
            nameId:
              'header-menu-manage-products-set-country-level-product-info',
            icon: <WebIcon />,
            iconActive: <WebWhiteIcon />,
            to: '/admin/set-country-level-product-info',
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            role: (role, sectionId) =>
              role === 'Approver' ||
              role === 'Super Admin' ||
              role === 'DMS Admin',
          },
        ],
      },
      {
        nameId: 'header-menu-manage-ports',
        icon: <Anchor />,
        iconActive: <AnchorWhite />,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        role: (role, sectionId) =>
          role === 'DMS Admin' || role === 'Super Admin',
        subItems: [
          {
            nameId: 'header-menu-manage-ports-add-port',
            icon: <AddIcon />,
            iconActive: <AddWhiteIcon />,
            to: '/admin/port/add',
          },
          {
            nameId: 'header-menu-manage-ports-edit-port',
            icon: <EditIcon />,
            iconActive: <EditWhiteIcon />,
            render: (route) => route.startsWith('/port/'),
          },
          {
            nameId: 'deactivate-port',
            icon: <DeactivateGreen />,
            iconActive: <DeactivateWhite />,
            parentType: 'port',
            render: (route: string, data: any) => {
              return matchPortDetailsPage && !!data && data.active;
            },
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            role: (role: any, sectionId: any) =>
              role === 'Approver' ||
              role === 'Super Admin' ||
              role === 'DMS Admin',
          },
          {
            nameId: 'activate-port',
            icon: <ActivateGreen />,
            iconActive: <ActivateWhite />,
            parentType: 'port',
            render: (route: string, data: any) => {
              return matchPortDetailsPage && !!data && !data.active;
            },
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            role: (role: any, sectionId: any) =>
              role === 'Approver' ||
              role === 'Super Admin' ||
              role === 'DMS Admin',
          },
          {
            nameId: 'add-stockpoint-label',
            icon: <AddIcon />,
            iconActive: <AddWhiteIcon />,
            to: '/admin/stockpoint/create',
          },
          {
            nameId: 'header-menu-manage-ports-manage-products-at-this-port',
            icon: <ManageStockpoints />,
            iconActive: <ManageStockpointsWhite />,
            render: (route) => route.startsWith('/port/'),
          },
          {
            nameId: 'header-menu-manage-ports-manage-stockpoints',
            icon: <ManageStockpoints />,
            iconActive: <ManageStockpointsWhite />,
            to: '/admin/stockpoint',
          },
          {
            nameId: 'header-menu-manage-ports-manage-order-contact',
            icon: <ManageOrderContact />,
            iconActive: <ManageOrderContactWhite />,
            to: '/admin/ordercontact',
          },
          {
            nameId: 'header-menu-manage-ports-create-port-distributor',
            icon: <AddIcon />,
            iconActive: <AddWhiteIcon />,
            to: '/admin/portdistributor/add',
          },
          {
            nameId: 'header-menu-manage-ports-manage-port-distributor',
            icon: <ManagePortDistributor />,
            iconActive: <ManagePortDistributorWhite />,
            to: '/admin/portdistributor',
          },
        ],
      },
      {
        nameId: 'header-menu-manage-oems-and-approvals',
        icon: <ManageOEM />,
        iconActive: <ManageOEMWhite />,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        role: (role, sectionId) =>
          role === 'Approver' || role === 'Super Admin',
        subItems: [
          {
            nameId: 'header-menu-edit-oem',
            icon: <EditIcon />,
            iconActive: <EditWhiteIcon />,
            render: (route) =>
              route.startsWith('/admin/oem/') &&
              !route.endsWith('add') &&
              !route.endsWith('edit'),
          },
          {
            nameId: 'header-menu-activate-oem',
            icon: <DocIcon />,
            iconActive: <DocWhiteIcon />,
            render: (route, data: OemDetail) =>
              route.startsWith('/admin/oem/') &&
              !route.endsWith('add') &&
              !route.endsWith('edit') &&
              !!data &&
              !data.active,
          },
          {
            nameId: 'header-menu-deactivate-oem',
            icon: <XOctagonIcon />,
            iconActive: <XOctagonWhiteIcon />,
            render: (route, data: OemDetail) =>
              route.startsWith('/admin/oem/') &&
              !route.endsWith('add') &&
              !route.endsWith('edit') &&
              !!data &&
              data.active,
          },
          {
            nameId: 'header-menu-add-oem',
            icon: <PlusCircleIcon />,
            iconActive: <PlusCircleWhiteIcon />,
            to: '/admin/oem/add',
          },
          {
            nameId: 'header-menu-add-multiple-oems-approvals',
            icon: <LibraryAddIcon />,
            iconActive: <LibraryAddWhiteIcon />,
            to: '/admin/oem-approvals/add',
          },
        ],
      },
      {
        nameId: 'header-menu-manage-users',
        icon: <UsersIcon />,
        iconActive: <UsersWhiteIcon />,
        role: (role) => role === 'Super Admin',
        subItems: [
          {
            nameId: 'header-menu-user-list',
            icon: <UserListIcon />,
            iconActive: <UserListWhiteIcon />,
            to: '/admin/users',
          },
          {
            nameId: 'header-menu-add-user',
            icon: <UserPlusIcon />,
            iconActive: <UserPlusWhiteIcon />,
            to: '/admin/users/add',
          },
          {
            nameId: 'header-menu-add-multi-users',
            icon: <LibraryAddIcon />,
            iconActive: <LibraryAddWhiteIcon />,
            to: '/admin/users/add-multi',
          },
        ],
      },
      {
        nameId: 'header-menu-admin',
        icon: <ManageAccountIcon />,
        iconActive: <ManageAccountWhiteIcon />,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        role: (role, sectionId) =>
          role === 'DMS Admin' || role === 'Super Admin' || role === 'Approver',
        subItems: [
          {
            nameId: 'header-menu-admin-manage-annoucements',
            icon: <ManageAnnouncements />,
            iconActive: <ManageAnnouncementsWhite />,
            to: '/admin/annoucements',
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            role: (role, sectionId) =>
              role === 'Super Admin' || role === 'Approver',
          },
          {
            nameId: 'header-menu-admin-manage-banners',
            icon: <ManageBannerIcon />,
            iconActive: <ManageBannerWhiteIcon />,
            to: '/admin/banners',
          },
          {
            nameId: 'header-menu-admin-manage-links',
            icon: <LinkGreenIcon />,
            iconActive: <LinkWhiteIcon />,
            to: '/admin/links',
          },
          {
            nameId: 'header-menu-admin-help',
            icon: <HelpIcon />,
            iconActive: <HelpWhiteIcon />,
            to: '/admin/helps',
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            role: (role, sectionId) =>
              role === 'Super Admin' || role === 'Approver',
          },
        ],
      },
      {
        nameId: 'header-menu-reports-quick-stats',
        icon: <Order />,
        iconActive: <OrderWhite />,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        role: (role, sectionId) =>
          role === 'DMS Admin' || role === 'Super Admin',
        to: '/admin/reports',
        subPages: [
          '/admin/reports/reports-and-quick-stats',
          '/admin/reports/user-activity',
          '/admin/reports/product-report',
          '/admin/reports/oem-activity',
          '/admin/reports/port-page-report',
          '/admin/reports/port-product-report',
          '/admin/reports/port-product-by-country',
        ],
      },
    ],
    [matchProductDetailsPage, productId],
  );

  useEffect(() => {
    for (let i = 0; i < menu.length; i++) {
      // Check whether the current route matches any parent route
      if (menu[i].to === currentRoute) {
        setSelectedIdx(i);
        return;
      }
      if (menu[i].subItems) {
        const subItemActive = checkSubItemSelected(
          menu[i].subItems || [],
          currentRoute,
        );
        if (subItemActive !== -1) {
          setSelectedIdx(i);
          return;
        }
      }
    }
    // Route didn't match any item or subItems, so don't select anything
    setSelectedIdx(-1);
  }, [location]);

  return (
    <div
      className={cn(
        styles.container,
        openAdminMenu ? styles.adminMenuOpen : '',
      )}
    >
      {menu.map((item: ItemType, index: number) => {
        const isCurrentPageActive: boolean = item?.subPages
          ? item.subPages.includes(currentRoute)
          : false;
        return (
          <MenuItem
            key={item.nameId}
            item={item}
            active={selectedIdx === index || isCurrentPageActive}
            onClick={() => setSelectedIdx(index)}
            subItems={item.subItems}
            userRole={userRole}
            sectionId={sectionId}
            hideMenuOnNavigation={() => toggleAdminMenu(false)}
          />
        );
      })}
    </div>
  );
};

export default HeaderMenu;
