import styles from './OrderAndPortInfo.module.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import { PropsWithoutRef } from 'react';
import { PortDetails } from '../../../models/port-details';
import IconField from '../../common/IconField/IconField';
import { ReactComponent as Ship } from '../../../icons/ship.svg';
import { ReactComponent as Truck } from '../../../icons/truck-green.svg';
import { ReactComponent as IBC } from '../../../icons/ibc.svg';
import { ReactComponent as DRUM } from '../../../icons/drum.svg';

interface OrderAndPortInfoProps {
  port: PortDetails;
}

/**
 * order and port info
 * @param port port
 */
function OrderAndPortInfo({ port }: PropsWithoutRef<OrderAndPortInfoProps>) {
  const intl = useIntl();

  return (
    <div className={styles.container}>
      <h3>
        <FormattedMessage id={'order-and-port-info-label'} />
      </h3>

      <div className={styles.listContainer}>
        <div className={styles.item}>
          <h4>
            <FormattedMessage id={'port-availability-bulk-label'} />
          </h4>
          <div className={styles.cargoContainer}>
            <IconField
              className={styles.cargo}
              icon={<Ship />}
              label={intl.formatMessage({ id: 'barge-label' })}
              value={
                port.minBulkBargeOrderInLitres > 0
                  ? `${port.minBulkBargeOrderInLitres}L`
                  : 'N/A'
              }
            />
            <IconField
              className={styles.cargo}
              icon={<Truck />}
              label={intl.formatMessage({ id: 'truck-label' })}
              value={
                port.minBulkTruckOrderInLitres > 0
                  ? `${port.minBulkTruckOrderInLitres}L`
                  : 'N/A'
              }
            />
            <IconField
              className={styles.cargo}
              icon={<IBC />}
              label={intl.formatMessage({ id: 'ibc-label' })}
              value={
                port.minBulkIbcOrderInLitres > 0
                  ? `${port.minBulkIbcOrderInLitres}L`
                  : 'N/A'
              }
            />
          </div>
        </div>
        <div className={styles.item}>
          <h4>
            <FormattedMessage id={'pack-label'} />
          </h4>
          <div className={styles.cargoContainer}>
            <IconField
              className={styles.cargo}
              icon={<DRUM />}
              label={intl.formatMessage({ id: 'drums-label' })}
              value={
                port.minPackOrderInDrums > 0
                  ? `${port.minPackOrderInDrums}`
                  : 'N/A'
              }
            />
            <IconField
              className={styles.cargo}
              icon={<DRUM />}
              label={intl.formatMessage({ id: 'drums-pumped-label' })}
              value={
                port.minPackOrderInDrumsPumped > 0
                  ? `${port.minPackOrderInDrumsPumped}`
                  : 'N/A'
              }
            />
          </div>
        </div>
      </div>

      {port.charges && (
        <div className={styles.notes}>
          <h4>
            <FormattedMessage id={'charges-label'} />
          </h4>
          <div className={styles.description}>{port.charges}</div>
        </div>
      )}

      {port.notes && (
        <div className={styles.notes}>
          <h4>
            <FormattedMessage id={'notes-label'} />
          </h4>
          <div className={styles.description}>{port.notes}</div>
        </div>
      )}
    </div>
  );
}

export default OrderAndPortInfo;
