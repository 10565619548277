import classNames from 'classnames';
import { useMemo } from 'react';
import Checkbox from '../../common/Checkbox/Checkbox';
import Input from '../../common/Input/Input';
import FormRow from '../FormRow/FormRow';

import { FormRowType } from '../../../models/admin-form-types';
import styles from './CheckboxList.module.scss';

type ItemType = {
  fieldKey?: string | undefined;
  value?: any;
  label: string;
  checked?: boolean;
  id?: any;
};

type CheckboxListType = FormRowType & {
  items: ItemType[];
  selectedIds?: any[];
  onClick: (fieldKey: any) => void;
  showInputField?: boolean;
  suffix?: string;
  handleValueChange?: (label: string, value: string | number) => void;
  className?: string;
  disabled?: boolean;
};

const CheckboxList = ({
  labelId,
  required,
  items,
  selectedIds,
  onClick,
  showInputField = false,
  suffix,
  handleValueChange,
  tooltip,
  className,
  disabled = false,
}: CheckboxListType) => {
  const selectedIdMap = useMemo(() => {
    if (selectedIds) {
      const map = new Map<any, boolean>();
      selectedIds.forEach((id) => map.set(id, true));
      return map;
    } else {
      return null;
    }
  }, [selectedIds]);
  return (
    <div className={classNames(styles.checkboxListWrapper, className)}>
      <FormRow labelId={labelId} required={required} tooltip={tooltip}>
        <div className={styles.optionsContainer}>
          {items.map((d: ItemType, index: number) => (
            <div key={index} className={styles.itemLabelWrapper}>
              <Checkbox
                disabled={disabled}
                checked={selectedIdMap ? selectedIdMap.get(d.id) : !!d.checked}
                onClick={() =>
                  disabled
                    ? null
                    : onClick(selectedIdMap ? d.id : d.fieldKey || '')
                }
              />
              <span className={styles.label}>{d.label}</span>
              {showInputField && d.checked && (
                <div className={styles.optionalInput}>
                  <Input
                    type="number"
                    value={d.value || ''}
                    onChange={(value: string) => {
                      if (handleValueChange) {
                        handleValueChange(d.fieldKey || '', value);
                      }
                    }}
                  />
                  <span>{suffix}</span>
                </div>
              )}
            </div>
          ))}
        </div>
      </FormRow>
    </div>
  );
};

export default CheckboxList;
