import { FormattedMessage } from 'react-intl';
import ProgressBar from '../../common/ProgressBar/ProgressBar';
import styles from './StepProgress.module.scss';

type StepProgressProps = {
  className?: string;
  progress: number;
};

const StepProgress = ({ className = '', progress }: StepProgressProps) => (
  <div className={`${styles.container} ${className}`}>
    <span className={styles.completeAllStepLabel}>
      <FormattedMessage id="complete-all-step-label" />
    </span>
    <ProgressBar progress={progress} />
  </div>
);

export default StepProgress;
