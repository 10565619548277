import styles from './ModalLeftBanner.module.scss';
import { PropsWithChildren } from 'react';
import cn from 'classnames';

interface ModalLeftBannerProps {
  className?: string;
}

/**
 * modal left banner
 * @param className className
 * @param children children
 */
function ModalLeftBanner({
  className,
  children,
}: PropsWithChildren<ModalLeftBannerProps>) {
  return <div className={cn(styles.container, className)}>{children}</div>;
}

export default ModalLeftBanner;
