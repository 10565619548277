/* istanbul ignore file */
import { useNavigate, useParams } from 'react-router';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearProducts } from '../../features/productComparisonSlice';
import {
  activateProduct,
  deactivateProduct,
  getProductDetail,
} from '../../services/ProductService';
import { PreviousLink } from '../../models/previous-link';
import NavigationHeader from '../../components/common/NavigationHeader/NavigationHeader';
import ContainerForDrawer from '../../components/common/ContainerForDrawer/ContainerForDrawer';
import LeftDrawer from '../../components/common/LeftDrawer/LeftDrawer';
import LoadingSpinner from '../../components/common/LoadingSpinner/LoadingSpinner';
import RightContainer from '../../components/common/RightContainer/RightContainer';
import DetailHeader from '../../components/common/DetailHeader/DetailHeader';
import { ProductDetails } from '../../models/search-result-product';
import ProductLeftDetail from '../../components/ProductPage/ProductLeftDetail/ProductLeftDetail';
import styles from './ProductPage.module.scss';
import ProductCharacteristics from '../../components/ProductPage/ProductCharacteristics/ProductCharacteristics';
import ProductPortAvailability from '../../components/ProductPage/ProductPortAvailability/ProductPortAvailability';
import AboutProduct from '../../components/ProductPage/AboutProduct/AboutProduct';
import { useLocation } from 'react-router-dom';
import SearchModal from '../../components/HomePage/SearchModal/SearchModal';
import PortAvailableTable from '../../components/ProductPage/PortAvailableTable/PortAvailableTable';
import cn from 'classnames';
import { useIntl, FormattedMessage } from 'react-intl';
import withSectorCountry from '../../hoc/withSectorCountry';
import { RootStore } from '../../store';
import { mapLookupItemsDictionary } from '../../utils/lookup.util';
import config from '../../configs/config';
import { find, cloneDeep, compact } from 'lodash';

import {
  getOemProductApprovals,
  activateApproval,
  deactivateApproval,
  activateOem,
  deactivateOem,
  shareByEmail,
} from '../../services/OemService';
import { OemApproval } from '../../models/oem-approval';
import OemApprovalTable from '../../components/Admin/common/OemApprovalTable/OemApprovalTable';
import ShareByEmailModal from '../../components/Admin/common/ShareByEmailModal/ShareByEmailModal';
import { setProductInfoToSessionStorage } from '../../services/LocalStorageService';
import { addToast } from '../../features/toastSlice';
import { setSectorIds } from '../../features/productDetailsSlice';
import { showGenericModal } from '../../features/genericModalSlice';
import ProductCaseStudies from '../../components/ProductPage/ProductCaseStudies/ProductCaseStudies';
import { deleteCaseStudy, getCaseStudy } from '../../services/CaseStudyService';
import { CaseStudyFull } from '../../models/case-study';
import {
  setProductId,
  toggleDiactiveDialog,
  toggleactiveDialog,
} from '../../features/productDetailsSlice';
import ConfirmDialog from '../../components/common/ConfirmDialog/ConfirmDialog';
import NotFound from '../Admin/NotFound/NotFound';
import ProductAvailabiltyStatus from '../../components/ProductPage/ProductAvailabiltyStatus/ProductAvailabiltyStatus';
import ProductVariants from '../../components/ProductPage/ProductVariants/ProductVariants';
import { ReactComponent as Warning } from '../../icons/warning-green.svg';
import { MountService } from '../../services/MountService';
import Tabs from '../../components/common/Tabs/Tabs';
/**
 * product detail page
 */
function ProductPage() {
  // init hooks
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { friendlyUrl } = useParams();
  const location = useLocation();
  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });

  // load data from store.
  const { hasWelcomeInfo, sector, culture } = useSelector(
    (state: RootStore) => state.welcomeInfo,
  );
  const { hasLogin, isAdmin, user } = useSelector(
    (state: RootStore) => state.login,
  );
  const { countries, sectors } = useSelector(
    (state: RootStore) => state.lookups,
  );
  const { showDeactivateDialog, showactivateDialog, productId } = useSelector(
    (state: RootStore) => state.productDetails,
  );

  const mountService: MountService = useMemo(() => new MountService(), []);

  // init states.
  const [loading, setLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [showShareByEmail, setShowShareByEmail] = useState<boolean>(false);

  const [details, setDetails] = useState<ProductDetails>();
  const [country, setCountry] = useState('');
  const path = `${location.pathname}?${location.search}`;
  const [previousPath, setPreviousPath] = useState(path);
  const [searchOpened, setSearchOpened] = useState(false);
  const [oemApprovals, setOemApprovals] = useState<OemApproval[]>([]);
  const [caseStudies, setCaseStudies] = useState<CaseStudyFull[]>([]);
  const [tab, setTab] = useState('About');
  const tabs = compact([
    {
      name: 'About',
      key: 'About',
    },
    details?.isAvailableInYourCountry && {
      name: 'CHARACTERISTICS',
      key: 'CHARACTERISTICS',
    },
    (!details?.isActiveInYourCountry || !details?.isAvailableInYourCountry) && {
      name: 'Product Availabilty Status',
      key: 'ProductAvailabiltyStatus',
    },
    details?.isAvailableInYourCountry &&
      details?.isActiveInYourCountry &&
      details?.variantProducts &&
      details?.variantProducts.length > 0 && {
        name: 'Product Variants',
        key: 'ProductVariants',
      },
    oemApprovals.length > 0 && {
      name: 'OEM',
      key: 'OEM',
    },
    sector == config.MARINE_SECTOR &&
      details?.isAvailableInYourCountry && {
        name: 'PORT AVAILABILITY',
        key: 'PORTAVAILABILITY',
      },
    caseStudies.length > 0 && {
      name: 'CASE STUDIES',
      key: 'CASESTUDIES',
    },
  ]);
  const [deletedCaseStudy, setDeletedCaseStudy] = useState<
    number | undefined
  >();

  mountService.addDispatch('setCaseStudies', setCaseStudies);
  mountService.addDispatch('setDetails', setDetails);
  mountService.addDispatch('setLoading', setLoading);

  //init local variables

  const breadcrumbLinks: PreviousLink[] = [
    {
      name: 'Home',
      to: '/home',
    },
  ];

  // there can be a product without family
  if (details?.family) {
    breadcrumbLinks.push({
      name: details?.family?.friendlyUrl || '',
      to: `/family/${details?.family?.friendlyUrl}`,
    });
  }

  // there can be a product without range
  if (details?.range) {
    breadcrumbLinks.push({
      name: details?.range.name || '',
      to: `/range/${details?.range.friendlyUrl}`,
    });
  }

  breadcrumbLinks.push({
    name: details?.product.name || '',
  });

  /**
   * country change handler
   * @param value value
   */
  const onCountryChange = (value: string) => {
    setCountry(value);
  };

  /**
   * generate country options from lookup.
   */
  const countryOptions = useMemo(() => {
    const availableCountries: { [key: number]: boolean } = {};
    const countriesById = mapLookupItemsDictionary(countries);
    details?.availablePorts?.forEach(
      (port) => (availableCountries[port.countryId] = true),
    );
    return Object.keys(availableCountries).map((countryId) => ({
      name: countriesById[Number(countryId)].name,
      value: String(countryId),
    }));
  }, [details, countries]);

  // generate ports from details
  const ports = useMemo(() => {
    return (
      details?.availablePorts?.filter(
        (port) => port.countryId === Number(country),
      ) || []
    );
  }, [country, details]);

  const loadProduct = () => {
    // clear products on load
    mountService.mount();
    dispatch(clearProducts());
    setNotFound(false);
    setLoading(true);

    getProductDetail(
      friendlyUrl as string,
      sector === 'directory-marine-services',
    )
      .then((res) => {
        dispatch(setProductId(res.product.id));
        dispatch(
          setSectorIds(res.product.productSectors.map((item) => item.sectorId)),
        );
        mountService.callDispatch('setDetails', res);
        setProductInfoToSessionStorage(res);
        /* istanbul ignore if */
        if (sector === config.ALL_SECTORS) {
          const localSector =
            sectors.find(
              (x) => x.id === res.product?.productSectors[0]?.sectorId,
            )?.friendlyUrl || sector;
          navigate(`/product/${friendlyUrl}/${localSector}/${culture}`);
        }

        /* istanbul ignore if */
        if (hasLogin) {
          getOemProductApprovals(res.product.id)
            .then((res) => {
              setOemApprovals(res);
            })
            .catch(() => {
              dispatch(
                addToast({
                  type: 'error',
                  title: getTranslatedMessage('oem-get-failure'),
                  message: '',
                }),
              );
            })
            .finally(() => setLoading(false));
        } else {
          mountService.callDispatch('setLoading', false);
        }
      })
      .catch(() => {
        setNotFound(true);
      })
      .finally(() => mountService.callDispatch('setLoading', false));

    () => {
      mountService.unmount();
    };
  };

  //effects

  /**
   * Load data after lookups loaded and welcome state saved.
   */
  useEffect(() => {
    // only call after the welcome form submitted
    if (hasWelcomeInfo && countries && countries.length > 0) {
      loadProduct();
    }
  }, [friendlyUrl, dispatch, hasWelcomeInfo, countries, sector]);

  // check path changes
  /* istanbul ignore next */
  useEffect(() => {
    if (path !== previousPath) {
      setPreviousPath(path);
      setSearchOpened(false);
    }
  }, [path, previousPath]);

  useEffect(() => {
    mountService.mount();
    if (details) {
      getCaseStudy(details.product.id).then((data: CaseStudyFull[]) => {
        mountService.callDispatch('setCaseStudies', data);
      });
    }
    return () => {
      mountService.unmount();
    };
  }, [details]);
  /* istanbul ignore next */
  const updateOemApprovalActive = (
    id: number,
    active: boolean,
    forOem: boolean,
  ): void => {
    const clonedApprovals = cloneDeep(oemApprovals);
    if (forOem) {
      const changedItems = clonedApprovals.filter((item) => item.oemId === id);
      if (changedItems && changedItems.length > 0) {
        changedItems.forEach((item) => {
          item.oem.active = active;
        });
        setOemApprovals(clonedApprovals);
      }
    } else {
      const changedItem = find(clonedApprovals, (item) => item.id === id);
      if (changedItem) {
        changedItem.active = active;
        setOemApprovals(clonedApprovals);
      }
    }
  };

  /* istanbul ignore next */
  const deactivateApprovalFun = (approvalId: number): void => {
    setProcessing(true);
    deactivateApproval(approvalId)
      .then(() => {
        updateOemApprovalActive(approvalId, false, false);
        dispatch(
          showGenericModal({
            titleId: 'oem-model-deactivate-approval',
            messageId: 'oem-model-deactivate-successful',
          }),
        );
      })
      .catch(() => {
        dispatch(
          addToast({
            type: 'error',
            title: getTranslatedMessage('approval-deactivate-failure'),
            message: '',
          }),
        );
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  /* istanbul ignore next */
  const activateApprovalFun = (approvalId: number): void => {
    setProcessing(true);
    activateApproval(approvalId)
      .then(() => {
        updateOemApprovalActive(approvalId, true, false);
        dispatch(
          showGenericModal({
            titleId: 'oem-model-activate-approval',
            messageId: 'oem-model-activate-successful',
          }),
        );
      })
      .catch(() => {
        dispatch(
          addToast({
            type: 'error',
            title: getTranslatedMessage('approval-activate-failure'),
            message: '',
          }),
        );
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  /* istanbul ignore next */
  const deactivateOemFun = (oemId: number): void => {
    setProcessing(true);
    deactivateOem(oemId)
      .then(() => {
        updateOemApprovalActive(oemId, false, true);
        dispatch(
          showGenericModal({
            titleId: 'oem-model-deactivate-oem',
            messageId: 'oem-model-deactivate-successful',
          }),
        );
      })
      .catch(() => {
        dispatch(
          addToast({
            type: 'error',
            title: getTranslatedMessage('oem-activate-failure'),
            message: '',
          }),
        );
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  /* istanbul ignore next */
  const activateOemFun = (oemId: number): void => {
    setProcessing(true);
    activateOem(oemId)
      .then(() => {
        updateOemApprovalActive(oemId, true, true);
        dispatch(
          showGenericModal({
            titleId: 'oem-model-activate-oem',
            messageId: 'oem-model-activate-successful',
          }),
        );
      })
      .catch(() => {
        dispatch(
          addToast({
            type: 'error',
            title: getTranslatedMessage('oem-deactivate-failure'),
            message: '',
          }),
        );
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  /* istanbul ignore next */
  const sendEmail = (data: any): void => {
    if (!details || !details.product.id) {
      return;
    }

    setProcessing(true);
    shareByEmail({
      productId: details.product.id,
      url: window.location.href,
      fromName: data.fromName,
      fromEmail: data.fromEmail,
      recipientName: data.recipientName,
      recipientEmail: data.recipientEmail,
    })
      .then(() => {
        dispatch(
          showGenericModal({
            titleId: 'oem-model-share-email-success',
            messageId: 'oem-model-share-email-success-desc',
          }),
        );
      })
      .catch(() => {
        dispatch(
          addToast({
            type: 'error',
            title: getTranslatedMessage('send-email-failure'),
            message: '',
          }),
        );
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  return (
    <>
      {notFound && <NotFound />}
      {!notFound && (
        <div>
          <NavigationHeader
            onInputFocus={() => setSearchOpened(true)}
            breadcrumbLinks={breadcrumbLinks}
          />
          <ContainerForDrawer>
            <LeftDrawer className={styles.leftDrawer}>
              {loading ? (
                <LoadingSpinner className={styles.loading} />
              ) : (
                details &&
                details.isActiveInYourCountry && (
                  <ProductLeftDetail
                    productDetails={details}
                    casestudies={caseStudies.length.toString()}
                  />
                )
              )}
            </LeftDrawer>

            <RightContainer>
              {loading ? (
                <LoadingSpinner className={styles.loading} />
              ) : (
                details && (
                  <div>
                    <div className={styles.container}>
                      <Tabs
                        className={styles.tabContainer}
                        tabContentClassName={styles.tabContent}
                        tabItemClassName={styles.tabItemClassName}
                        tabItemSelectedClassName={
                          styles.tabItemSelectedClassName
                        }
                        selected={tab}
                        tabs={tabs}
                        onChange={(item) => {
                          setTab(item.key);
                        }}
                      >
                        {tab === 'About' && (
                          <>
                            <div className={styles.productDetailContainer}>
                              <DetailHeader
                                title={details?.product.name}
                                subTitle={details?.product.tagLine}
                                description={details?.product.description}
                                id={details?.product.id}
                                page={'Product'}
                              />

                              {details.isActiveInYourCountry && (
                                <AboutProduct
                                  product={details?.product}
                                  onShareProduct={() =>
                                    setShowShareByEmail(true)
                                  }
                                />
                              )}
                            </div>
                          </>
                        )}
                        {tab === 'CHARACTERISTICS' && (
                          <>
                            {details && details.isAvailableInYourCountry && (
                              <ProductCharacteristics
                                product={details?.product}
                              />
                            )}
                          </>
                        )}
                        {tab === 'ProductAvailabiltyStatus' && (
                          <>
                            {details &&
                              (!details.isActiveInYourCountry ||
                                !details.isAvailableInYourCountry) && (
                                <ProductAvailabiltyStatus details={details} />
                              )}
                          </>
                        )}
                        {tab === 'ProductVariants' && (
                          <>
                            {details &&
                              details.isAvailableInYourCountry &&
                              details.isActiveInYourCountry &&
                              details.variantProducts &&
                              details.variantProducts.length > 0 && (
                                <ProductVariants
                                  productVariants={details.variantProducts}
                                />
                              )}
                          </>
                        )}
                        {tab === 'CASESTUDIES' && (
                          <>
                            {caseStudies.length > 0 && (
                              <ProductCaseStudies
                                caseStudies={caseStudies}
                                isAdmin={isAdmin}
                                userRole={user?.role}
                                onDeleteCaseStudy={(val) => {
                                  setDeletedCaseStudy(val);
                                }}
                              />
                            )}
                          </>
                        )}
                        {tab === 'PORTAVAILABILITY' && (
                          <>
                            {sector === config.MARINE_SECTOR && (
                              <ProductPortAvailability
                                country={country}
                                countries={countryOptions}
                                onCountryChange={onCountryChange}
                              />
                            )}
                            {ports.length > 0 && (
                              <div className={styles.tableContainer}>
                                <PortAvailableTable
                                  className={styles.portTable}
                                  ports={ports}
                                />
                              </div>
                            )}

                            {sector === config.MARINE_SECTOR &&
                              countryOptions.length === 0 &&
                              ports.length === 0 && (
                                <div className={styles.noPortsWithAvailability}>
                                  <Warning className={styles.icon} />
                                  <FormattedMessage
                                    id={'no-ports-with-availability-label'}
                                  />
                                </div>
                              )}
                          </>
                        )}
                        {tab === 'OEM' && (
                          <>
                            {oemApprovals && oemApprovals.length > 0 && (
                              <div>
                                <div
                                  className={cn(styles.divider, styles.mobile)}
                                />
                                <OemApprovalTable
                                  oemApprovals={oemApprovals}
                                  product={{
                                    name: details.product.name,
                                    masterBrandId:
                                      details.product.masterBrandId,
                                  }}
                                  exportFileName={details.product.name}
                                  deactivateApproval={deactivateApprovalFun}
                                  activateApproval={activateApprovalFun}
                                  deactivateOem={deactivateOemFun}
                                  activateOem={activateOemFun}
                                  className={styles.oemApprovalsContainer}
                                />
                              </div>
                            )}
                          </>
                        )}
                      </Tabs>
                    </div>
                  </div>
                )
              )}
            </RightContainer>
          </ContainerForDrawer>

          {searchOpened && (
            <SearchModal onClose={() => setSearchOpened(false)} />
          )}
          {processing && <LoadingSpinner className={'lookupsLoading'} />}
          {showShareByEmail && (
            <ShareByEmailModal
              forProduct={true}
              onClose={() => setShowShareByEmail(false)}
              onSend={sendEmail}
            />
          )}
          {showDeactivateDialog && (
            <ConfirmDialog
              onClose={() => {
                dispatch(toggleDiactiveDialog(false));
              }}
              onConfirm={() => {
                setLoading(true);
                deactivateProduct(productId)
                  .then(() => {
                    loadProduct();
                  })
                  .finally(() => {
                    setLoading(true);
                    dispatch(toggleDiactiveDialog(false));
                  });
              }}
              message={intl.formatMessage({
                id: 'product-deactive-confirmation-message',
              })}
            />
          )}
          {showactivateDialog && (
            <ConfirmDialog
              onClose={() => {
                dispatch(toggleactiveDialog(false));
              }}
              onConfirm={() => {
                setLoading(true);
                activateProduct(productId)
                  .then(() => {
                    loadProduct();
                  })
                  .finally(() => {
                    setLoading(true);
                    dispatch(toggleactiveDialog(false));
                  });
              }}
              message={intl.formatMessage({
                id: 'product-active-confirmation-message',
              })}
            />
          )}
          {deletedCaseStudy && (
            <ConfirmDialog
              onClose={() => {
                setDeletedCaseStudy(undefined);
              }}
              onConfirm={() => {
                setLoading(true);
                deleteCaseStudy(deletedCaseStudy)
                  .then(() => {
                    if (details) {
                      getCaseStudy(details.product.id).then(
                        (data: CaseStudyFull[]) => {
                          setCaseStudies(data);
                          setLoading(false);
                        },
                      );
                    }
                  })
                  .finally(() => {
                    setLoading(false);
                    setDeletedCaseStudy(undefined);
                  });
              }}
              message={intl.formatMessage({
                id: 'case-study-delete-confirmation-message',
              })}
            />
          )}
        </div>
      )}
    </>
  );
}

export default withSectorCountry(ProductPage);
