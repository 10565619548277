import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import ToastMessage from './ToastMessage/ToastMessage';
import { removeToast } from '../../../features/toastSlice';

function Toast() {
  const { toast } = useSelector((state: RootStore) => state.toast);
  const dispatch = useDispatch();

  if (toast) {
    return (
      <ToastMessage toast={toast} onClose={() => dispatch(removeToast())} />
    );
  } else {
    return null;
  }
}

export default Toast;
