import { PropsWithoutRef } from 'react';
import { FormattedMessage } from 'react-intl';
import ReactTooltip from 'react-tooltip';

import { ReactComponent as QuestionMark } from '../../../../../../icons/question-info.svg';
import styles from './FieldLabel.module.scss';

interface FieldLabelProps {
  id: string;
  helpTextId?: string;
}

function FieldLabel({ id, helpTextId = '' }: PropsWithoutRef<FieldLabelProps>) {
  return (
    <div className={styles.container}>
      <div className={styles.fieldName}>
        <FormattedMessage id={id} />
      </div>
      <QuestionMark className={styles.questionMark} data-tip data-for={id} />
      <ReactTooltip id={id} place={'top'} effect={'solid'}>
        <FormattedMessage id={helpTextId} />
      </ReactTooltip>
    </div>
  );
}

export default FieldLabel;
