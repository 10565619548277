const i18nEn = {
  // labels
  'or-browse-label': 'Or Browse',
  'loading-more-label': 'Loading More...',
  'contact-label': 'Contact',
  'contact-us-label': 'Contact Us',
  'view-more-label': 'View More',
  'filters-label': 'Filters',
  'all-label': 'All',
  remove: 'Remove',
  select: 'Select',
  'message-label': 'Message',
  'most-searched-products-label': 'Most Searched Products',
  'welcome-label': 'Welcome',
  'industry-sector-label': 'Industry sector',
  'language-label': 'Language',
  'continue-label': 'Continue',
  'country-label': 'Country',
  'countries-label': 'Countries',
  'port-label': 'Port',
  'pack-label': 'Pack',
  'ibc-label': 'IBC',
  'pail-label': 'pail',
  'drums-label': 'Drums',
  'drums-pumped-label': 'Drums Pumped',
  'ports-label': 'Ports',
  'family-label': 'Family',
  'range-label': 'Range',
  'product-label': 'Product',
  'products-label': 'Products',
  'product(s)-label': 'Product(s)',
  'sector-label': 'Sector',
  'sector(s)-label': 'Sector(s)',
  'oem-label': 'OEM',
  'oems-label': 'OEMs',
  'oem-name-label': 'OEM NAME',
  'send-label': 'Send',
  'close-label': 'Close',
  'product-family-label': 'Product Family',
  'tagline-label': 'Tagline',
  'product-status-label': 'Product Status',
  'product-families-label': 'Product Families',
  'product-ranges-label': 'Product Ranges',
  'category-label': 'Category',
  'subcategory-label': 'Sub-Category',
  'product-category-label': 'Product category',
  'castrol-recommended-label': 'Castrol recommended',
  'ester-ep-additives-label': 'Ester/EP Additives',
  'food-grade-filter-label': 'Food Grade',
  'multi-metals-suitable-label': 'Multi Metals Suitable',
  'formulated-without-silicate-label': 'Formulated without Silicate',
  'superior-biodegradation-label': 'Superior Biodegradation',
  'operating-temperature-label': 'Operating Temperature',
  'min-temperature-label': 'Min {value}℃',
  'max-temperature-label': 'Max {value}℃',
  'product-categories-label': 'Product categories',
  'application-label': 'Application',
  'applications-label': 'Applications',
  'viscosity-label': 'Viscosity',
  'corrosion-protection-label': 'Corrosion Protection',
  'oil-type-label': 'Oil Type',
  'thickener-label': 'Thickener',
  'metal-type-label': 'Metal Type',
  'nlgi-label': 'NLGI',
  'sub-sector-label': 'Market Sector',
  'viscosity-index-label': 'Viscosity Index',
  'no-matched-results-label': 'No matched results',
  'no-results-label': 'No results found.',
  'no-results-matching-label': 'No results found matching your search term.',
  'welcome-use-current-location-label': 'Use current location',
  'welcome-remember-label': 'Remember my selections',
  'learn-more-label': 'Learn More',
  'latest-products-label': 'Latest Products',
  'browse-product-category-label': 'Browse Product Category',
  'browse-product-application-label': 'Browse Product Application',
  'browse-product-group-label': 'Browse Product Group',
  'browse-casestudy-subsector': 'Browse Case Study SubSector',
  'sub-Sector': 'Sub Sector',
  'browse-casestudy-application-label': 'Browse Case Study Application',
  'browse-product-label': 'Browse Products',
  'browse-caseStudy-label': 'Browse Case Study',
  'browse-product-label-with-count': 'Browse {count} Products',
  'browse-casestudy-label-with-count': 'Browse {count} Case Studies',
  'browse-casestudy-customer':
    'only enter if customer gave permission to public his name',
  'browse-casestudy-Previous': 'Enter Previous Product in use',
  'links-label': 'Links',
  'product-counts-label': '{count} Product(s)',
  'port-counts-label': '{count} Ports',
  'search-modal-info-message-link-label': 'Let us know',
  'search-results-label': 'Search results',
  'view-results-by-label': 'View results by',
  'hide-products-label': 'Hide Products',
  'hide-ports-label': 'Hide Ports',
  'hide-ranges-label': 'Hide Ranges',
  'show-products-label': 'Show Products',
  'show-product-s-label': 'Show Product(s)',
  'show-ports-label': 'Show Ports',
  'show-ranges-label': 'Show Ranges',
  'compare-label': 'Compare',
  'product-id-label': 'Product id',
  'product-name-label': 'Product name',
  'product-description-label': 'Product description',
  'range-short-description-label': 'Range short description',
  'product-range-label': 'Product Range Available',
  'min-bulk-supply-label': 'Min bulk supply',
  'min-dum-supply-label': 'Min drum supply',
  'notice-required-label': 'Notice Required\n(Working Days)',
  'barge-label': 'Barge',
  'truck-label': 'Truck',
  'range-name-label': 'Range name',
  'description-label': 'Description',
  'comparison-list-label': 'Comparison list',
  'compare-counts-label': 'Compare ({count})',
  'clear-selection-label': 'Clear Selections',
  'product-comparison-label': 'Product Comparison',
  'download-label': 'Download',
  'print-label': 'Print',
  'esterEpAdditives-label': 'Ester EP Additives',
  'product-comparision-food-grade-label': 'Food Grade',
  'high-flash-point-label': 'High Flash Point',
  'product-comparision-superior-biodegradation-label':
    'Superior Biodegradation',
  'product-comparision-viscosity-index-label': 'Viscosity Index',
  'product-comparision-castrol-recommended-label': 'Castrol Recommended',
  'product-comparision-master-brand-label': 'Master Brand',
  'product-comparision-owner-label': 'Owner',
  'product-comparision-performance-indicator-label': 'Performance Indicator',
  'applicable-sectors-label': 'Applicable sectors',
  'overview-label': 'Overview',
  'other-details-index-label': 'Other details {index}',
  'specification-index-label': 'Specification {index}',
  'product-type-label': 'Product type',
  'see-range-details-label': 'See Range Details',
  'hide-range-details-label': 'Hide Range Details',
  'oem-approved-label': 'OEM approved',
  'oem-approved-label-upper': 'OEM Approved',
  'oem-approval-label': 'OEM approval(s)',
  'approvals-counts-label': '{count} approval(s)',
  'oem-approvals-counts-label': '{count} OEM approvals',
  'characteristics-label': 'Characteristics',
  'viscosity-unit': 'MM/S² AT 40°C',
  'applicable-to-label': 'Applicable to',
  'port-availability-label': 'Port availability',
  'port-availability-port-label': 'Port',
  'port-availability-notice-label': 'Notice required (working days)',
  'port-availability-bulk-label': 'BULK',
  'port-availability-pack-label': 'PACK',
  'port-availability-barge-label': 'Barge',
  'port-availability-truck-label': 'Truck',
  'port-availability-ibc-label': 'IBC',
  'port-availability-drum-label': 'Drum',
  'port-availability-pail-label': 'Pail',
  'about-this-product-label': 'About This Product',
  'product-data-sheet-label': 'Product Datasheet',
  'safety-data-sheet-label': 'Safety Datasheet',
  'find-a-distributor-label': 'Find A Distributor',
  'find-distributor-label': 'Find Distributor',
  'share-product-label': 'Share Product',
  'full-name-label': 'Full Name',
  'email-address-label': 'Email Address',
  'company-name-label': 'Company name',
  'line-of-business-label': 'Line of business',
  'country-of-business-label': 'Country of business',
  'subject-label': 'Subject',
  'comment-or-question-label': 'Your Comment or question',
  'save-label': 'Save',
  'next-label': 'Next',
  'submit-label': 'Submit',
  'confirm-label': 'Confirm',
  'cancel-label': 'Cancel',
  'update-label': 'Update',
  'privacy-statement-label': 'Privacy statement',
  'legal-notice-label': 'Legal notice',
  'oil-gas-terms-and-conditions-label':
    'Energy Terms and Conditions of Sale 2022',
  'dms-terms-and-conditions-label':
    'Castrol Marine Terms and Conditions of Sale 2022',
  'terms-and-conditions-label': 'Terms and conditions',
  'port-work-day-label': 'Working Days',
  'port-notice-required-label': 'Notice Required',
  'port-local-time-label': 'Local Time',
  'port-jump-label': 'Jump To...',
  'cutomer-service-label': 'Castrol Marine Customer Service',
  'phone-label': 'Phone',
  'email-label': 'Email',
  'ooh-phone-label': 'Out of Hours Phone',
  'notes-label': 'Notes',
  'charges-label': 'Charges',
  'refine-product-list-label': 'Refine Product List',
  'about-this-port-label': 'About This Port',
  'see-all-title': 'See All',
  'company-name': 'CASTROL LIMITED',
  'copyright-label': 'COPYRIGHT ©2021',
  'minTemperatureC-label': 'Minimum Temperature in °C',
  'maxTemperatureC-label': 'Maximum Temperature in °C',
  'grade-label': 'Grade',
  'sub-grade-label': 'Subgrade',
  'mineralOilName-label': 'Mineral Oil',
  'corrosionProtectionName-label': 'Corrosion Protection',
  'concentrationRangeName-label': 'Concentration Range',
  'characteristicName-label': 'Characteristic',
  'customerOfferName-label': 'Customer Offer',
  'superiorBiodegradationName-label': 'Superior Biodegradation',
  'thickenerGenericName-label': 'Thickener Generic',
  'thickenerSpecificName-label': 'Thickener Specific',
  'baseOilTypeGenericName-label': 'Base Oil Type Generic',
  'baseOilTypeSpecificName-label': 'Base Oil Type Specific',
  'productCharacteristicNlgis-label': 'Nlgis',
  'productCharacteristicMetalTypes-label': 'Metal Types',
  'quick-links-label': 'Quick Links',
  'yes-label': 'Yes',
  'no-label': 'No',
  'back-label': 'Back',
  'active-label': 'Active',
  'share-by-email-label': 'Share By Email',
  'per-page-label': 'Showing {first} - {end} of {total}',
  'per-page-label-mobile': '{first} - {end} of {total}',
  'inactive-product-label': 'Inactive Product',
  'user-role-label': 'User Role',
  'status-label': 'Status',
  'last-active-label': 'Last Active',
  'inactive-label': 'Inactive',
  'delete-label': 'Delete',
  'user-login-label': 'User LogIn',
  'remember-me-label': 'Remember me',
  'remember-me-desc':
    'How long would you like your details to be remembered for?',
  'two-weeks-label': 'Two weeks',
  'one-month-label': 'One month',
  'six-months-label': 'Six month',
  'my-account-label': 'My Account',
  'phone-number-label': 'Phone Number',
  'account-options-label': 'Account Options',
  'edit-account-label': 'Edit Account',
  'unregister-account-label': 'Unregister Account',
  'receive-product-email-label': 'Receive product promotion email',
  'edit-account-details-label': 'Edit Account Details',
  'change-your-password-label': 'Change Your Password',
  'current-password-label': 'Current Password',
  'password-updated-success': 'Your password have been updated successfully',
  'account-updated-label': 'Account Updated',
  'account-updated-success': 'Your account have been updated successfully',
  'unregister-label': 'Unregister',
  'unregister-account-desc':
    "Unregister account will disable your profile lorem ipsum dolor sit amet. Some information will be dolore acme important descriptions here. Before you unregister your account, please tell us why you're leaving",
  'reason-label': 'Reason',
  'update-account-success': 'Update Account Success',
  'update-account-failure': 'Update Account Failed',
  'change-password-failure': 'Failed to change password',
  'unregister-account-success-info':
    'Your account have been unregistered successfully',
  'get-account-info-failure': 'Get Account Info Failed',
  'no-ports-with-availability-label': 'There are no ports with availability.',
  'product-type-label2': 'Product Type',
  'applicable-these-sectors-label': 'Applicable to these sectors',
  'distributors-label': 'Distributors',
  'distributor-label': 'Distributor',
  'ports-served-label': 'Ports Served',
  'product-group-label': 'Product Group',

  'multiMetalsSuitable-label': 'Multi Metals Suitable',
  'formulatedWithoutSilicate-label': 'Formulated Without Silicate',
  'formulatedWithoutChlorinatedParaffin-label':
    'Formulated Without Chlorinated Paraffin',
  'formulatedWithoutBoron-label': 'Formulated Without Boron',
  'formulatedWithoutHeavyMetal-label': 'Formulated Without Heavy Metal',

  // login modal and message
  'distributor-login-label': 'Distributor Log in',
  'username-label': 'Username',
  'password-label': 'Password',
  'login-label': 'Login',
  'login-with-credentials-label': 'Login with credentials',
  'login-with-sso-label': 'Login with SSO (Castrol Only)',
  'register-label': 'Register',
  'help-label': 'Help',
  'need-help-label': 'Need Help?',
  'help-label-1': "I've forgotten my password",
  'help-label-2': "I'm having technical difficulties with this tool",
  'help-label-3': 'Request an account',
  'help-label-4': 'Request an Internal User Account',
  'login-sso-label': 'Login Through SSO',
  'help-label-5': 'I need access to data sheets',
  'your-email-address-label': 'Your Email Address',
  'email-subject-label': 'Email Subject',
  'body-label': 'Body',
  'comments-label': 'Comments',
  'agree-privacy-policy':
    'I agree to my data being processed as described in the <a>Privacy Statement</a>',
  'how-your-data-used-title': 'How your data will be used :',
  'how-your-data-used-1':
    '- To identity the distributor has legitimate need to access the platform.',
  'how-your-data-used-2':
    '- To give access to the distributor information which is restricted to the general public.',
  'how-your-data-used-3':
    '- To contact the distributor with matters related to distribution of Castrol products.',
  'data-hold-period-title':
    'Data will be held for a period of 2 years of inactivity, after which it will be deleted.',
  'data-hold-period-desc':
    'If you want to be removed, request data, or submit a Subject Access Request please email <email>customerservices1@castrol.com</email>',
  'business-name-label': 'Business Name',
  'preferred-language-label': 'Preferred language',
  'email-subject-tooltip': 'Please select an email subject',
  'email-body-tooltip': 'Please enter an email body',
  'email-send-success': 'Email Send',
  'email-send-success-info': 'Your email has been submitted successfully',
  'email-send-failure': 'Email Send Failed',
  'request-account-success-info':
    'Your request has been submitted successfully',
  'request-account-failure': 'Request Account Failed',
  'castrol-email-error-message':
    'Email is invalid. Should belong to bp.com or castrol.com domains',
  'login-failure': 'Login Failed',
  'edit-note-label': 'Edit Note',
  'apply-to-multi-ports': 'Apply to multiple ports',

  // reset password page
  'reset-password-label': 'Reset Password',
  'reset-password-desc-label':
    "Please enter the email address you registered with below and we'll send you a link to reset your password",
  'send-request-label': 'Send Request',
  'check-in-your-mail-label': 'Check In Your Mail',
  'check-in-your-mail-desc':
    'We just emailed you with the instructions to reset your password.',
  'email-confirm-desc':
    'We just emailed you with the instructions to reset your password.',
  'contact-info-desc':
    'For any questions or problems please contact your local country office, using the Contact us link on your relevant <a>Castrol.com</a> website.',
  'reset-your-password-label': 'Reset Your Password',
  'change-password-label': 'Change Password',
  'reset-your-password-desc':
    'In order to protect your account, make sure our password:',
  'reset-your-password-rule1': '- Is longer than 7 characters.',
  'reset-your-password-rule2':
    '- Does not match or significantly contain your username.',
  'reset-your-password-rule3': '- An uppercase character',
  'reset-your-password-rule4': '- A lowercase character',
  'new-password-label': 'New Password',
  'confirm-new-password-label': 'Confirm New Password',
  'password-not-same-error': "Those passwords didn't match. Try again",
  'password-updated-label': 'Password Updated',
  'password-updated-desc':
    'You can now use your new password to log in to your account',

  // tooltip
  'username-tooltip': 'Please enter the username',
  'password-tooltip': 'Please enter the password',

  // link labels
  'order-and-port-info-label': 'Order & Port Information',
  'product-availability-label': 'Product Availability',
  'how-to-order-label': 'How To Order',
  'nearby-ports-label': 'Nearby Ports',
  'change-region-label': 'Change your region',

  // filters
  'min-bulk-supply-filter': 'Minimum Bulk Supply (L)',
  'min-drum-supply-filter': 'Minimum Drum Supply',
  'notice-required-filter': 'Notice Required (Working Days)',
  'delivery-filter': 'Delivery',
  'product-name-filter': 'Product Name',
  'oem-id-filter': 'OEM Id',
  'oem-name-filter': 'OEM Name',
  'approval-type-filter': 'Approval Type',
  'approval-type-filter-approved': 'OEM Approved',
  'approval-type-filter-listed': 'OEM Listed',
  'oem-only-filter': 'OEM Only',

  // descriptions
  'notes-description':
    'Spanish regulations require that empty drums are retained by the vessel. Pumping is not available for Alpha and Hyspin products.',
  'order-description-1':
    'If you would like further information, please contact your local sales representative.',
  'order-description-2':
    'Please do not contact the office in the country where the supply is required but the customer service center where your offices are based or where you usually place orders.',
  'order-description-3': 'Not your contact office?',
  'welcome-description':
    'Before you start,<br/>We need a few things<br/>from you<br/>',
  'welcome-industry-sector-description':
    'Please choose your industry sector to find relevant product information.You can change the sector at any time using the tool at the top of the page.',
  'welcome-terms-and-conditions-description':
    'By clicking continue button, you have read and agree to <a href="https://thelubricantoracle.castrol.com/BPfilestore/CastrolOracle/TAndC/terms-and-conditions-marine.pdf" target="_blank">Terms & Conditions</a>',
  'search-result-header-description':
    'Listing <b>{count}</b> product(s) matching “<b>{search}</b>”',
  'search-result-port-header-description':
    'Listing <b>{count}</b> port(s) matching “<b>{search}</b>”',
  'search-result-oem-header-description':
    'Listing <b>{count}</b> OEM(s) matching “<b>{search}</b>”',
  'search-result-countries-header-description':
    'Listing <b>{count}</b> port(s) in countries matching “<b>{search}</b>”',
  'search-result-families-header-description':
    'Listing <b>{count}</b> product(s) in families matching “<b>{search}</b>”',
  'search-result-ranges-header-description':
    'Listing <b>{count}</b> product(s) in ranges matching “<b>{search}</b>”',
  'search-result-categories-header-description':
    'Listing <b>{count}</b> product(s) with product categories matching “<b>{search}</b>”',
  'search-result-applications-header-description':
    'Listing <b>{count}</b> product(s) with product applications matching “<b>{search}</b>”',
  'search-result-header-most-searched-description':
    'Listing <b>{count}</b> product(s)',
  'comparison-list-description': 'Select up to 4 products to compare',
  'family-product-selection-description':
    'Expand the items below to select and compare up to 4 products.',
  'contact-description': 'Please complete<br/>All fields',
  'port-availability-description':
    'Select a country to view ports where this product can be obtained',
  'product-non-active-description1':
    'The product is being withdrawn from market. ',
  'product-non-active-description2':
    'Castrol experts recommend {{products}} in its place. ',
  'product-non-active-description3':
    'If you have any questions, please contact your local Castrol representative or use the "Contact Us" button above.',
  'product-non-active-label': 'Important Product Notice',
  'product-non-available-label':
    'THIS PRODUCT IS NOT AVAILABLE IN YOUR COUNTRY.',
  'product-variant-label':
    'This product is also listed in the following sectors',
  // messages
  'login-error-message':
    'Email or Password is not correct, <a href="/users/reset-password">Reset password</a> if forgotten',
  'welcome-login-message1': 'If you already have account, please ',
  'welcome-login-message2': 'login',
  'welcome-login-message3': ' to continue',
  'welcome-login-message4': 'Please select Sector and Country',
  'search-modal-info-message': 'Can’t find what you are looking for?',
  'industry-sector-searching-message':
    'You are searching in <b>{industrySector}</b>',
  'range-products-empty-message-1':
    'The products in this range are not available in your country.',
  'range-products-empty-message-2':
    '{{searchLinkOpen}} Search again{{searchLinkClose}} or',
  'range-products-empty-message-3':
    'to discuss your requirements with a Castrol expert.',
  'product-deactive-confirmation-message':
    'Are you sure you want to deactivate the product?',
  'product-active-confirmation-message':
    'Are you sure you to activate the product?',
  'case-study-delete-confirmation-message':
    'Are you sure you want to delete the case study?',
  '404-header1': '404 Error',
  '404-header2': 'WE’RE SORRY, BUT THE PAGE YOU REQUESTED CAN’T BE FOUND',
  '404-message1':
    'The page you were looking for appears to have been moved, deleted, or does not exist.',
  '404-message2':
    'Please <a href="/">return to the homepage</a> to find what you are looking for',

  // product availability message
  'Rebrand DFC':
    'This product is changing to the Castrol brand and will be known as {product}. If you have any questions, please contact your local Castrol representative or use the "Contact Us" button above.',
  'Rebrand Obsolete':
    'The brand of the product has changed to Castrol {product}. If you have any questions, please contact your local Castrol representative or use the "Contact Us" button above.',
  'Rename DFC':
    'The name of this product is changing to {product} to group it with products with similar attributes. If you have any questions, please contact your local Castrol representative or use the "Contact Us" button above.',
  'Rename Obsolete':
    'The name of this product has changed to {product} to group it with products with similar attributes. If you have any questions, please contact your local Castrol representative or use the "Contact Us" button above.',
  'Replacement 1 DFC':
    'This product is being withdrawn from the market, Castrol experts recommend {product} in its place. If you have any questions, please contact your local Castrol representative or use the "Contact Us" button above.',
  'Replacement 1 Obsolete':
    'The product you have selected is no longer available. Castrol experts recommend {product} in its place. If you have any questions, please contact your local Castrol representative or use the "Contact Us" button above.',
  'Replacement 2 DFC':
    'This product is being withdrawn from the market, Castrol experts recommend these alternatives. If you have any questions, please contact your local Castrol representative or use the "Contact Us" button above.',
  'Replacement 2 Obsolete':
    'The product you have selected is no longer available, Castrol experts recommend these alternatives. If you have any questions, please contact your local Castrol representative or use the "Contact Us" button above.',
  'Withdrawn D':
    'The product you have selected will be withdrawn. Please contact your local Castrol representative (or use the “Contact us” button) to discuss the best suitable alternative for your application needs.',
  'unavailable-common':
    'The product you have selected is no longer available. Please contact your local Castrol representative (or use the “Contact us” button) to discuss the best suitable alternative for your application needs.',
  // placeholders
  'search-placeholder':
    'Search by product name, product category, application, port or country',
  'search-with-oem-placeholder':
    'Search by product name, product category, OEM approval, application, port or country',
  'search-placeholder-other':
    'Search by OEM, product name, product category or application',
  'search-placeholder-short': 'Search Products, Ports or Countries',
  'search-with-oem-placeholder-short':
    'Search Products, Ports or Countries, OEM Approval',
  'search-placeholder-other-short': 'Search Products',
  'start-typing-placeholder': 'Start typing...',
  'navigation-header-input-placeholder':
    'Search Products, Ports or Countries...',
  'select-placeholder': 'Select',
  'select-country-placeholder': 'Select Country',
  'search-country-placeholder': 'Search Country',
  'search-product': 'Search for a product',
  'product-name-placeholder': 'Enter product name...',
  'oem-id-placeholder': 'Enter ID',
  'oem-name-placeholder': 'Search for a OEM',
  'select-date-placeholder': 'Select date',
  'attach-file-placeholder': 'Attach file',
  'enter-placeholder': 'Enter',

  // error message
  'field-required-error': 'Field is required',
  'email-invalid-error': 'Email is invalid',
  'product-get-failure': 'Failed to get product',
  'invalid-username-error': 'Invalid username!',
  'upload-document-error': 'Failed to upload document',
  'request-reset-email-failure': 'Failed to request reset password email',
  'reset-password-failure': 'Failed to reset password',
  'order-contacts-get-failure': 'Failed to get order contacts',
  'username-invalid-error': 'Invalid username!',
  'password-invalid-error': 'Your password is wrong!',

  // cookie popup
  'cookie-acceptance-title': 'SITE TRAFFIC INFORMATION AND COOKIES',
  'cookie-acceptance-message':
    'We use cookies to collect and analyze information on our site’s performance and to enable the site to function. Cookies also allow us and our partners to show you relevant ads when you visit our site and other 3rd party websites, including social networks. You can to accept all cookies by clicking “Accept All” or manage them individually by clicking “Manage Cookie Preferences” where you will also find out more information.',
  'cookie-acceptance-accept': 'ACCEPT ALL',
  'cookie-save-changes': 'Save Changes',
  'cookie-acceptance-manage-preference': 'Manage cookie preferences',

  // contact us success toast
  'contact-us-success-title': 'Successfully submitted',
  'contact-us-success-message':
    'Thanks for contacting us, we will get back to you within 3 business days',

  // header menu bar
  'header-menu-manage-products': 'Manage products',
  'header-menu-manage-ports': 'Manage ports',
  'header-menu-manage-oem-approvals': 'Manage OEM & Approvals',
  'header-menu-manage-users': 'Manage users',
  'header-menu-admin': 'Admin',
  'header-menu-reports-quick-stats': 'Reports and quick stats',
  'header-menu-product-report': 'Product Report',
  'header-menu-port-product-report': 'Port Product Report',
  'header-menu-filter-by-country': 'Filter By Country',

  // validation data cell
  'validation-data-not-available': 'N/A',
  'validation-data-input-placeholder': 'Enter',
  'validation-data-multi-select-more-items': '+ {more} more',
  'validation-data-bool-option-yes': 'Yes',
  'validation-data-bool-option-no': 'No',

  // tooltips
  'product-oem-counts-tooltip':
    '<p>Know more about OEM <a href="/login">here</a></p>',
  'login-tooltip': 'Logged out. Please log in for full information access',

  // logout
  'logout-message': 'Are you sure you want to log out?',
  'login-here': 'Login Here',
};

export default i18nEn;
