import { apiInstance } from '../api/requestInterceptor';

import { DistributorResponse } from '../models/distributor';

/**
 * get distributor detail
 * @param friendlyUrl distributor friendlyUrl
 */
async function getDistributorDetails(
  friendlyUrl: string,
): Promise<DistributorResponse> {
  const res = await apiInstance.get<DistributorResponse>(
    `/distributors/${friendlyUrl}`,
  );

  return res.data;
}

export { getDistributorDetails };
