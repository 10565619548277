import { PropsWithChildren, useState } from 'react';
import { useIntl } from 'react-intl';
import cn from 'classnames';
import styles from './UserActionMenu.module.scss';

import { ReactComponent as SettingIcon } from '../../../../icons/setting-grey.svg';
import { ReactComponent as SettingGreenIcon } from '../../../../icons/settings-green.svg';
import { ReactComponent as EditIcon } from '../../../../icons/edit-green.svg';
import { ReactComponent as EditWhiteIcon } from '../../../../icons/edit-white.svg';
import { ReactComponent as XOctagonIcon } from '../../../../icons/x-octagon-green.svg';
import { ReactComponent as XOctagonWhiteIcon } from '../../../../icons/x-octagon-white.svg';
import { ReactComponent as DocIcon } from '../../../../icons/ic-doc-green.svg';
import { ReactComponent as DocWhiteIcon } from '../../../../icons/ic-doc-white.svg';
import { ReactComponent as LockIcon } from '../../../../icons/lock-outline.svg';
import { ReactComponent as LockWhiteIcon } from '../../../../icons/lock-outline-white.svg';
import { ReactComponent as DeleteIcon } from '../../../../icons/minus-circle.svg';
import { ReactComponent as DeleteWhiteIcon } from '../../../../icons/minus-circle-white.svg';

import MenuOption from '../MenuOption/MenuOption';

interface UserActionMenuProps {
  className?: string;
  active?: boolean;
  idle?: boolean;
  onOptionClick: (value: string) => void;
}

/**
 * user action  menu component
 */
function UserActionMenu({
  className,
  active,
  idle,
  onOptionClick,
}: PropsWithChildren<UserActionMenuProps>) {
  const intl = useIntl();

  const [opened, setOpened] = useState(false);

  return (
    <div className={cn(styles.container, className)}>
      {!opened && (
        <SettingIcon
          className={styles.settingIcon}
          onClick={() => setOpened(true)}
        />
      )}
      {opened && (
        <SettingGreenIcon
          className={styles.settingIcon}
          style={{ width: '20px', height: '20px' }}
        ></SettingGreenIcon>
      )}

      {opened && (
        <div
          onClick={() => setOpened(false)}
          className={styles.clickDetector}
        />
      )}

      {opened && (
        <div
          onClick={() => setOpened(false)}
          className={styles.optionContainer}
        >
          <MenuOption
            label={intl.formatMessage({ id: 'menu-edit-user' })}
            icon={<EditIcon />}
            iconActive={<EditWhiteIcon />}
            onOptionClick={() => onOptionClick('menu-edit-user')}
          />
          {(!active || idle) && (
            <MenuOption
              label={intl.formatMessage({ id: 'menu-activate-user' })}
              icon={<DocIcon />}
              iconActive={<DocWhiteIcon />}
              onOptionClick={() => onOptionClick('menu-activate-user')}
            />
          )}
          {active && !idle && (
            <MenuOption
              label={intl.formatMessage({ id: 'menu-deactivate-user' })}
              icon={<XOctagonIcon />}
              iconActive={<XOctagonWhiteIcon />}
              onOptionClick={() => onOptionClick('menu-deactivate-user')}
            />
          )}
          <MenuOption
            label={intl.formatMessage({ id: 'menu-reset-password' })}
            icon={<LockIcon />}
            iconActive={<LockWhiteIcon />}
            onOptionClick={() => onOptionClick('menu-reset-password')}
          />
          <MenuOption
            label={intl.formatMessage({ id: 'menu-delete-user' })}
            icon={<DeleteIcon />}
            iconActive={<DeleteWhiteIcon />}
            onOptionClick={() => onOptionClick('menu-delete-user')}
          />
        </div>
      )}
    </div>
  );
}

export default UserActionMenu;
