import React, { PropsWithChildren, useState } from 'react';
import { useIntl } from 'react-intl';
import cn from 'classnames';
import styles from './OemApprovalActionMenu.module.scss';

import { ReactComponent as SettingIcon } from '../../../../icons/setting-grey.svg';
import { ReactComponent as SettingGreenIcon } from '../../../../icons/settings-green.svg';
import { ReactComponent as EditIcon } from '../../../../icons/edit-green.svg';
import { ReactComponent as EditWhiteIcon } from '../../../../icons/edit-white.svg';
import { ReactComponent as XOctagonIcon } from '../../../../icons/x-octagon-green.svg';
import { ReactComponent as XOctagonWhiteIcon } from '../../../../icons/x-octagon-white.svg';
import { ReactComponent as DocIcon } from '../../../../icons/ic-doc-green.svg';
import { ReactComponent as DocWhiteIcon } from '../../../../icons/ic-doc-white.svg';

import MenuOption from '../MenuOption/MenuOption';

interface OemApprovalActionMenuProps {
  className?: string;
  forApproval?: boolean;
  forApprovalActivated?: boolean;
  forOemActivated?: boolean;
  forEditOemApproval?: boolean;
  onOptionClick: (value: string) => void;
}

/**
 * oem approval action  menu component
 */
function OemApprovalActionMenu({
  className,
  forApproval = false,
  forApprovalActivated = true,
  forOemActivated = true,
  forEditOemApproval = true,
  onOptionClick,
}: PropsWithChildren<OemApprovalActionMenuProps>) {
  const intl = useIntl();

  const [opened, setOpened] = useState(false);

  return (
    <div className={cn(styles.container, className)}>
      {!opened && (
        <SettingIcon
          className={styles.settingIcon}
          onClick={() => setOpened(true)}
        />
      )}
      {opened && (
        <SettingGreenIcon
          className={styles.settingIcon}
          style={{ width: '20px', height: '20px' }}
        ></SettingGreenIcon>
      )}

      {opened && (
        <div
          onClick={() => setOpened(false)}
          className={styles.clickDetector}
        />
      )}

      {opened && (
        <div
          onClick={() => setOpened(false)}
          className={styles.optionContainer}
        >
          {!forApproval && (
            <React.Fragment>
              <MenuOption
                label={intl.formatMessage({ id: 'menu-edit-oem' })}
                icon={<EditIcon />}
                iconActive={<EditWhiteIcon />}
                onOptionClick={() => onOptionClick('menu-edit-oem')}
              />
              {forApprovalActivated && (
                <MenuOption
                  label={intl.formatMessage({ id: 'menu-activate-approval' })}
                  icon={<DocIcon />}
                  iconActive={<DocWhiteIcon />}
                  onOptionClick={() => onOptionClick('menu-activate-approval')}
                />
              )}
              {!forApprovalActivated && (
                <MenuOption
                  label={intl.formatMessage({ id: 'menu-deactivate-approval' })}
                  icon={<XOctagonIcon />}
                  iconActive={<XOctagonWhiteIcon />}
                  onOptionClick={() =>
                    onOptionClick('menu-deactivate-approval')
                  }
                />
              )}
              {forOemActivated && (
                <MenuOption
                  label={intl.formatMessage({ id: 'menu-activate-oem' })}
                  icon={<DocIcon />}
                  iconActive={<DocWhiteIcon />}
                  onOptionClick={() => onOptionClick('menu-activate-oem')}
                />
              )}
              {!forOemActivated && (
                <MenuOption
                  label={intl.formatMessage({ id: 'menu-deactivate-oem' })}
                  icon={<XOctagonIcon />}
                  iconActive={<XOctagonWhiteIcon />}
                  onOptionClick={() => onOptionClick('menu-deactivate-oem')}
                />
              )}
            </React.Fragment>
          )}
          {forApproval && (
            <React.Fragment>
              {forApprovalActivated && (
                <MenuOption
                  label={intl.formatMessage({ id: 'menu-activate-approval' })}
                  icon={<DocIcon />}
                  iconActive={<DocWhiteIcon />}
                  onOptionClick={() => onOptionClick('menu-activate-approval')}
                />
              )}
              {!forApprovalActivated && (
                <MenuOption
                  label={intl.formatMessage({ id: 'menu-deactivate-approval' })}
                  icon={<XOctagonIcon />}
                  iconActive={<XOctagonWhiteIcon />}
                  onOptionClick={() =>
                    onOptionClick('menu-deactivate-approval')
                  }
                />
              )}
              {forEditOemApproval && (
                <MenuOption
                  label={intl.formatMessage({ id: 'menu-edit-oem-approval' })}
                  icon={<EditIcon />}
                  iconActive={<EditWhiteIcon />}
                  onOptionClick={() => onOptionClick('menu-edit-oem-approval')}
                />
              )}
            </React.Fragment>
          )}
        </div>
      )}
    </div>
  );
}

export default OemApprovalActionMenu;
