import styles from './SearchModalBrowser.module.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import { PropsWithoutRef, useLayoutEffect, useMemo, useState } from 'react';
import { ProductCategory } from '../../../../models/product-category';
import { Application } from '../../../../models/application';
import { ProductType } from '../../../../models/product-type';
import SearchModalBrowseColumn from './SearchModalBrowseColumn/SearchModalBrowseColumn';
import LoadingSpinner from '../../../common/LoadingSpinner/LoadingSpinner';
import { MountService } from '../../../../services/MountService';
import { getBrowseProductFilters } from '../../../../services/ProductService';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../../store';
import {
  mapApplicationsWithIds,
  mapCategoriesWithIds,
  mapProductTypesWithIds,
} from '../../../../utils/lookup.util';
import { IdItemWithProducts } from '../../../../models/id-item-with-products';
import getSearchPlaceholderId from '../../../../utils/common.util';

interface SearchModalBrowserProps {
  className: string;
  closeModal: () => void;
}

/**
 * search modal browser
 * @param className classname
 * @param closeModal close modal
 */
function SearchModalBrowser({
  className,
  closeModal,
}: PropsWithoutRef<SearchModalBrowserProps>) {
  const intl = useIntl();
  const { culture, sector } = useSelector(
    (state: RootStore) => state.welcomeInfo,
  );
  const { categories, applications, productTypes } = useSelector(
    (state: RootStore) => state.lookups,
  );
  const { hasLogin } = useSelector((state: RootStore) => state.login);
  const [displayCategories, setDisplayCategories] = useState<ProductCategory[]>(
    [],
  );
  const [displayApplications, setDisplayApplications] = useState<Application[]>(
    [],
  );
  const [displayProductTypes, setDisplayProductTypes] = useState<ProductType[]>(
    [],
  );

  const [loading, setLoading] = useState(false);

  const mountService = useMemo(() => new MountService(), []);

  mountService.addDispatch('setLoading', setLoading);
  mountService.addDispatch('setCategories', setDisplayCategories);
  mountService.addDispatch('setApplications', setDisplayApplications);
  mountService.addDispatch('setProductTypes', setDisplayProductTypes);

  useLayoutEffect(() => {
    mountService.mount();

    mountService.callDispatch('setLoading', true);

    getBrowseProductFilters({
      withProductCount: true,
    })
      .then((res) => {
        mountService.callDispatch(
          'setCategories',
          mapCategoriesWithIds(
            categories,
            applications,
            res.categories as IdItemWithProducts[],
          ),
        );
        mountService.callDispatch(
          'setApplications',
          mapApplicationsWithIds(
            applications,
            categories,
            res.applications as IdItemWithProducts[],
          ),
        );
        mountService.callDispatch(
          'setProductTypes',
          mapProductTypesWithIds(
            productTypes,
            categories,
            res.productTypes as IdItemWithProducts[],
          ),
        );
      })
      .finally(() => {
        mountService.callDispatch('setLoading', false);
      });

    return () => {
      mountService.unmount();
    };
  }, [mountService, culture, sector]);

  const searchPlaceholderId = getSearchPlaceholderId(sector, '', hasLogin);

  return (
    <div className={className}>
      <div className={styles.searchDescription}>
        <FormattedMessage id={searchPlaceholderId} />
      </div>

      <div className={styles.dividerContainer}>
        <div className={styles.dividerLine} />
        <div className={styles.dividerText}>
          <FormattedMessage id={'or-browse-label'} />
        </div>
        <div className={styles.dividerLine} />
      </div>

      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className={styles.browserColumnContainer}>
          {sector === 'industrial' && (
            <SearchModalBrowseColumn
              closeModal={closeModal}
              type={'productType'}
              data={displayProductTypes}
              className={styles.column}
              title={intl.formatMessage({ id: 'product-group-label' })}
              onClose={closeModal}
            />
          )}

          <SearchModalBrowseColumn
            closeModal={closeModal}
            type={'category'}
            data={displayCategories}
            className={styles.column}
            title={intl.formatMessage({ id: 'product-categories-label' })}
            onClose={closeModal}
          />

          {sector !== 'industrial' && (
            <SearchModalBrowseColumn
              closeModal={closeModal}
              type={'application'}
              data={displayApplications}
              className={styles.column}
              title={intl.formatMessage({ id: 'applications-label' })}
              onClose={closeModal}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default SearchModalBrowser;
