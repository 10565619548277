import { PropsWithoutRef, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDropzone } from 'react-dropzone';

import { ReactComponent as Add } from '../../../../icons/add.svg';
// import { ReactComponent as QuestionMark } from '../../../../icons/question-info.svg';
import Button from '../../../common/Button/Button';
import styles from './UploadData.module.scss';

interface UploadDataProps {
  setFile: (f: any) => void;
  submit: () => void;
}

function UploadData({ setFile, submit }: PropsWithoutRef<UploadDataProps>) {
  const intl = useIntl();

  const onDrop = useCallback(
    (files) => {
      if (setFile) setFile(files[0]);
    },
    [setFile],
  );

  // Hooks used of react-dropzone to get a modular file uploading component
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop,
  });

  const hasFiles = Boolean(acceptedFiles.length);

  return (
    <div className={styles.container}>
      <h2>
        <FormattedMessage id={'upload-data'} />
      </h2>
      <div className={styles.divider} />
      <div className={styles.twoColumnGrid}>
        <div>
          <div className={styles.labelContainer}>
            <div className={styles.label}>
              {intl.formatMessage({ id: 'upload-product-spreadsheet' })}
            </div>
            {/* <QuestionMark /> */}
          </div>
          <div className={styles.inputWrapper} {...getRootProps()}>
            {!hasFiles ? <Add className={styles.add} /> : null}
            <div className={styles.inputText}>
              {hasFiles ? (
                acceptedFiles[0].name
              ) : (
                <>
                  - <FormattedMessage id={'select'} /> -
                </>
              )}
              <input
                type="file"
                name="files[]"
                id="upload-file"
                {...getInputProps()}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
            </div>
          </div>
        </div>
        <Button
          disabled={!hasFiles}
          color={'green'}
          className={styles.continueButton}
          onClick={submit}
        >
          <FormattedMessage id={'upload'} />
        </Button>
      </div>
    </div>
  );
}

export default UploadData;
