import styles from './LeftDrawer.module.scss';
import { PropsWithChildren } from 'react';
import cn from 'classnames';

/**
 * left drawer for search result and details page
 * @param children children
 * @param className className
 */
function LeftDrawer({ className, children }: PropsWithChildren<any>) {
  return <div className={cn(styles.container, className)}>{children}</div>;
}

export default LeftDrawer;
