import { useState, useMemo, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import cn from 'classnames';

import styles from './LoginPage.module.scss';
import config from '../../configs/config';
import Button from '../../components/common/Button/Button';
import InputRow from '../../components/Admin/InputRow/InputRow';
import CheckBox from '../../components/common/Checkbox/Checkbox';
import Radio from '../../components/Admin/Radio/Radio';
import FormError from '../../components/common/FormError/FormError';
import HelpModal, {
  HelpType,
} from '../../components/common/HelpModal/HelpModal';

import { login, loginSSO } from '../../services/AuthService';
import {
  getWelcomeInfoFromStorage,
  setAccessTokenToLocalStorage,
  setCultureToLocalStorage,
  setUserInfoToLocalStorage,
  setWelcomeInfoToStorage,
} from '../../services/LocalStorageService';
import { RootStore } from '../../store';
import { setHasLogin, setAdminLogin } from '../../features/loginSlice';
import { hideLoading, showLoading } from '../../features/loadingSlice';
import { setWelcomeInfo, setCulture } from '../../features/welcomeInfoSlice';
import { UserInfo } from '../../models/user-info';
import { WelcomeInfo } from '../../models/welcome-info';
import { emailValidator, getErrorMessage } from '../../utils/string.util';
import { getSectorAndCulture } from '../../utils/welcome-info.util';
import { useMsal } from '@azure/msal-react';

/**
 * Login page component
 */
function LoginPage() {
  // init hooks
  const intl = useIntl();
  const { instance } = useMsal();
  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [rememberPeriod, setRememberPeriod] = useState('Two Weeks');
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [helpType, setHelpType] = useState<HelpType>('email');
  const [errorMessage, setErrorMessage] = useState('');

  const welcomeInfo = useSelector((state: RootStore) => state.welcomeInfo);
  const { countries } = useSelector((state: RootStore) => state.lookups);
  const loginSlice = useSelector((state: RootStore) => state.login);
  const savedWelcomeInfo = getWelcomeInfoFromStorage();

  useLayoutEffect(() => {
    if (loginSlice && loginSlice.hasLogin) {
      navigate(`/`);
    }
  }, [loginSlice]);

  const rememberMeOptions = useMemo(() => {
    return [
      {
        label: getTranslatedMessage('two-weeks-label'),
        value: 'Two Weeks',
      },
      {
        label: getTranslatedMessage('one-month-label'),
        value: 'One Month',
      },
      {
        label: getTranslatedMessage('six-months-label'),
        value: 'Six Months',
      },
    ];
  }, []);

  const openHelpModal = (type: HelpType) => {
    setHelpType(type);
    setShowHelpModal(true);
  };

  const setLoginData = (data: UserInfo) => {
    setUserInfoToLocalStorage(data);
    if (
      data.role === 'Super Admin' ||
      data.role === 'DMS Admin' ||
      data.role === 'Approver'
    ) {
      dispatch(setAdminLogin(data));
    } else {
      dispatch(setHasLogin(data));
    }
    dispatch(setCulture(config.DEFAULT_CULTURE));

    const info: WelcomeInfo = {
      industrySector: welcomeInfo.welcomeInfo.industrySector,
      country: welcomeInfo.welcomeInfo.country, //countries.find((x) => x.code === 'GB') || null,
      language: welcomeInfo.welcomeInfo.language,
    };

    const { culture } = getSectorAndCulture(info);

    if (savedWelcomeInfo) {
      setWelcomeInfoToStorage(info);
      setCultureToLocalStorage(culture);
    }

    dispatch(setWelcomeInfo(info));
    dispatch(setCulture(culture));
    navigate('/');
  };

  const onLogin = () => {
    dispatch(showLoading());
    login(username, password, rememberMe ? rememberPeriod : undefined)
      .then((res) => {
        setAccessTokenToLocalStorage(res.accessToken);
        setLoginData(res.user as UserInfo);
      })
      .catch(() => {
        setErrorMessage(
          intl.formatMessage({
            id: 'login-error-message',
          }),
        );
      })
      .finally(() => dispatch(hideLoading()));
  };

  const onloginSSO = (accessToken: string) => {
    loginSSO(accessToken, welcomeInfo.sector, welcomeInfo.culture)
      .then((res) => {
        setAccessTokenToLocalStorage(accessToken);
        setLoginData(res.user as UserInfo);
      })
      .catch((err) => {
        const message = getErrorMessage(err);
        if (typeof message === 'string') {
          setErrorMessage(message);
        }
        setTimeout(() => {
          instance.logoutRedirect();
        }, 1000);
      })
      .finally(() => dispatch(hideLoading()));
  };

  const handleLogin = () => {
    dispatch(showLoading());
    instance
      .loginPopup(config.LOGIN_REQUEST)
      .then((val) => {
        onloginSSO(val.accessToken);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(hideLoading());
      });
  };

  const generateErrorMessage = (errorMessage: string) => {
    return (
      <div
        className={styles.errorMessage}
        dangerouslySetInnerHTML={{ __html: errorMessage }}
      ></div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.leftPart}>
        <div className={styles.title}>
          <FormattedMessage id={'user-login-label'} />
        </div>
        {errorMessage && (
          <FormError className={styles.error}>
            {generateErrorMessage(errorMessage)}
          </FormError>
        )}
        <div className={'columnRow'}>
          <InputRow
            labelId={'username-label'}
            className={styles.loginInput}
            value={username}
            required={true}
            onChange={(value: string) => {
              setUsername(value);
            }}
            tooltip={getTranslatedMessage('username-tooltip')}
            customValidator={(value: string) => emailValidator(value)}
          />
        </div>

        <div className={'columnRow'}>
          <InputRow
            labelId={'password-label'}
            className={styles.loginInput}
            value={password}
            required={true}
            isPassword={true}
            onChange={(value: string) => {
              setPassword(value);
            }}
            tooltip={getTranslatedMessage('password-tooltip')}
          />
        </div>

        <div className={'columnRow'}>
          <div
            onClick={() => setRememberMe(!rememberMe)}
            className={styles.rememberMe}
          >
            <CheckBox checked={rememberMe} className={styles.checkbox} />
            <span>
              <FormattedMessage id={'remember-me-label'} />
            </span>
          </div>
        </div>

        {rememberMe && (
          <div className={'columnRow'}>
            <div className={styles.radioContainer}>
              <span className={styles.rememberMeDesc}>
                <FormattedMessage id={'remember-me-desc'} />
              </span>
              {rememberMeOptions.map((d, index: number) => (
                <div key={index} className={styles.radioLabelWrapper}>
                  <Radio
                    checked={rememberPeriod === d.value}
                    onClick={() => setRememberPeriod(d.value)}
                  />
                  <span
                    className={cn(
                      styles.label,
                      rememberPeriod === d.value && styles.active,
                    )}
                    onClick={() => setRememberPeriod(d.value)}
                  >
                    {d.label}
                  </span>
                </div>
              ))}
            </div>
          </div>
        )}

        <div className={styles.buttonContainer}>
          <Button
            onClick={() => onLogin()}
            color={'green'}
            className={cn(styles.button, styles.loginBtn)}
            disabled={!username || !password}
          >
            <FormattedMessage id={'login-with-credentials-label'} />
          </Button>
          <div className={styles.divider}>
            <div className={styles.dividerLabel}>OR</div>
          </div>
          <Button
            onClick={() => openHelpModal('account')}
            color={'green'}
            className={cn(styles.button, styles.registerBtn)}
          >
            <FormattedMessage id={'register-label'} />
          </Button>
          <Button
            onClick={() => handleLogin()}
            color={'green'}
            className={cn(styles.button, styles.loginSSO)}
          >
            <FormattedMessage id={'login-with-sso-label'} />
          </Button>
        </div>
      </div>
      <div className={styles.rightPart}>
        <div className={styles.helpTitle}>
          <FormattedMessage id={'need-help-label'} />
        </div>
        <div className={styles.questions}>
          <p className={styles.question}>
            <span onClick={() => navigate('/users/reset-password')}>
              <FormattedMessage id={'help-label-1'} />
            </span>
          </p>
          <p className={styles.question}>
            <span onClick={() => openHelpModal('email')}>
              <FormattedMessage id={'help-label-2'} />
            </span>
          </p>
          <p className={styles.question}>
            <span onClick={() => openHelpModal('internal-account')}>
              <FormattedMessage id={'help-label-4'} />
            </span>
          </p>
          <p className={styles.question}>
            <a
              href={
                'https://msdspds.castrol.com/msdspds/msdspds.nsf/CastrolHomePage?OpenForm'
              }
              target={'_blank'}
              rel={'noreferrer'}
            >
              <FormattedMessage id={'help-label-5'} />
            </a>
          </p>
        </div>
      </div>
      {showHelpModal && (
        <HelpModal
          type={helpType}
          onClose={() => {
            setShowHelpModal(false);
          }}
        />
      )}
    </div>
  );
}

export default LoginPage;
