import classNames from 'classnames';
import React, { useState } from 'react';
import { ReactComponent as EditIcon } from '../../../icons/edit-green.svg';
import { ValidationError } from '../../../models/sheet-validation';
import ValidationDataCellError from '../ValidationDataCellError/ValidationDataCellError';
import styles from './ValidationDataCell.module.scss';

export interface ValidationDataCellProps {
  className?: string;
  label?: string;
  children: React.ReactElement;
  error?: ValidationError;
  required?: boolean;
  bold?: boolean;
}

function ValidationDataCell({
  className,
  label,
  children,
  error,
  required,
  bold = true,
}: ValidationDataCellProps) {
  const [isEdit, setIsEdit] = useState<boolean>(false);

  return (
    <div
      className={classNames(styles.container, isEdit && styles.edit, className)}
      onClick={() => {
        if (!isEdit) {
          setIsEdit(true);
        }
      }}
    >
      {label && (
        <div
          className={classNames(
            styles.label,
            required && styles.requiredAsterisk,
          )}
        >
          {label}
        </div>
      )}
      <div className={classNames(styles.content, bold && styles.contentBold)}>
        {!!error && !isEdit ? (
          <ValidationDataCellError error={error} />
        ) : (
          React.cloneElement(children, {
            isEdit,
            isValid: !error,
            onDone: () => setIsEdit(false),
          })
        )}
        <EditIcon className={styles.editIcon} />
      </div>
    </div>
  );
}

export default ValidationDataCell;
