import FormRow from '../FormRow/FormRow';
import FileInput from '../FileInput/FileInput';
import { FileInputRowType } from '../../../models/admin-form-types';
import Button from '../../common/Button/Button';
import styles from './FileInputRow.module.scss';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { useRef } from 'react';

const FileInputRow = ({
  labelId,
  required,
  tooltip,
  className,
  file,
  onChange,
  accept,
  addedFileName,
  showBrowseBtn = true,
}: FileInputRowType) => {
  const fileInputRef: any = useRef<HTMLDivElement>(null);

  const onBrowse = () => {
    fileInputRef.current.click();
  };

  return (
    <div className={classNames(styles.rowWrapper, className)}>
      <FormRow
        labelId={labelId}
        required={required}
        tooltip={tooltip}
        className={styles.formRow}
      >
        <FileInput
          ref={fileInputRef}
          file={file}
          onChange={onChange}
          accept={accept}
          addedFileName={addedFileName}
          className={styles.fileInput}
          showIconBtn={!showBrowseBtn}
        />
      </FormRow>
      {showBrowseBtn && (
        <Button onClick={onBrowse} color={'green'} className={styles.browseBtn}>
          <FormattedMessage id={'browse-label'} />
        </Button>
      )}
    </div>
  );
};

export default FileInputRow;
