import { FormattedMessage, useIntl } from 'react-intl';
import cn from 'classnames';

import Button from '../../common/Button/Button';
import FormRow from '../FormRow/FormRow';
import Input from '../../common/Input/Input';

import { ReactComponent as DeleteIcon } from '../../../icons/delete-gray.svg';

import { EmailPhoneFaxOthersType } from '../../../models/order-contacts';

import config from '../../../configs/config';

import styles from './InputRowWithButton.module.scss';

type InputRowWithButtonProps = {
  fieldKey: string;
  labelId: string;
  list?: string[];
  onChange?: (index: number, value: string) => void;
  onAddBtnClick?: (value: any) => void;
  onDeleteBtnClick?: (index: number) => void;
  required?: boolean;
  tooltip?: string;
};

const InputRowWithButton = ({
  fieldKey,
  labelId,
  list = [],
  onChange,
  onAddBtnClick,
  onDeleteBtnClick,
  required,
  tooltip,
}: InputRowWithButtonProps) => {
  const intl = useIntl();

  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });

  const handleAddBtnClick = () => {
    if (!isAddBtnDisabled) {
      return;
    }

    if (onAddBtnClick) {
      onAddBtnClick({
        name: '',
        value: '',
      });
    }
  };

  const handleDeleteBtnClick = (index: number) => {
    if (onDeleteBtnClick) {
      onDeleteBtnClick(index);
    }
  };

  const handleValueChange = (index: number, value: any) => {
    if (onChange) {
      onChange(index, value);
    }
  };

  const isEmailInValid = (value: string) => {
    if (fieldKey === 'emails' && value) {
      return !config.EMAIL_REGEX.test(value.toLowerCase());
    }

    return false;
  };

  const isAddBtnDisabled = (item: EmailPhoneFaxOthersType) => {
    return (
      !item.name.trim() || !item.value.trim() || isEmailInValid(item.value)
    );
  };

  const getTooltip = () => {
    if (tooltip) {
      return getTranslatedMessage(tooltip);
    }

    return undefined;
  };

  return (
    <div className={styles.inputRowWithButtonContainer}>
      <FormRow
        labelId={labelId}
        required={required}
        tooltip={getTooltip()}
        tooltipClassName={styles.tooltip}
      >
        {list.map((item: any, index: number) => (
          <div key={`${item.fieldKey}-${index}`} className={styles.existingRow}>
            <Input
              containerClassName={styles.fullWidth}
              value={item.name}
              onChange={(value: any) => {
                const updatedField = {
                  ...item,
                  name: value,
                };
                handleValueChange(index, updatedField);
              }}
              placeholder={getTranslatedMessage('input-placeholder')}
            />
            <Input
              containerClassName={styles.fullWidth}
              value={item.value}
              onChange={(value: any) => {
                const updatedField = {
                  ...item,
                  value,
                };
                handleValueChange(index, updatedField);
              }}
              placeholder={getTranslatedMessage('input-placeholder')}
              errorMessage={getTranslatedMessage('email-invalid-error')}
              customValidator={(value: string) => isEmailInValid(value)}
            />
            <div
              className={styles.deleteBtn}
              onClick={() => handleDeleteBtnClick(index)}
            >
              <DeleteIcon className={styles.deleteIconBtn} />
              <FormattedMessage id={'delete-label'} />
            </div>
          </div>
        ))}
      </FormRow>
      <Button
        color={'green-outline'}
        className={cn(styles.addBtn)}
        onClick={handleAddBtnClick}
      >
        <FormattedMessage id={'add-prefix-label'} />
        <FormattedMessage id={labelId} />
      </Button>
    </div>
  );
};

export default InputRowWithButton;
