import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { OemDetail } from '../models/oem-detail';
import { OemOption } from '../models/oem';

export interface OemActivateStatus {
  oemId: number;
  activate: boolean;
}

export interface OemSliceState {
  oemDetail: OemDetail | null;
  oemActivateStatus: OemActivateStatus;
  oems: OemOption[];
  activeProducts: OemOption[];
}

/**
 * manage oem state
 */
export const oemSlice = createSlice({
  name: 'oem',
  initialState: {
    oemDetail: null,
    oemActivateStatus: {
      oemId: -1,
      activate: false,
    },
    oems: [],
    activeProducts: [],
  } as OemSliceState,
  reducers: {
    // store oem detail
    storeOem: (state, action: PayloadAction<OemDetail>) => {
      return {
        ...state,
        oemDetail: action.payload,
      };
    },
    // activate / deactivate OEM
    updateOemActive: (state, action: PayloadAction<boolean>) => {
      const newOemDetail = cloneDeep(state.oemDetail);
      if (newOemDetail) {
        newOemDetail.active = action.payload;
      }
      return {
        ...state,
        oemDetail: newOemDetail,
      };
    },
    // remove oem detail
    clearOem: (state) => {
      return {
        ...state,
        oemDetail: null,
      };
    },
    // activate / deactivate OEM
    updateActivateState: (state, action: PayloadAction<OemActivateStatus>) => {
      return {
        ...state,
        oemActivateStatus: action.payload,
      };
    },
    // clear activate / deactivate status
    clearActivateState: (state) => {
      return {
        ...state,
        oemActivateStatus: {
          oemId: -1,
          activate: false,
        },
      };
    },
    // set active oems
    setOems: (state, action: PayloadAction<OemOption[]>) => {
      return {
        ...state,
        oems: action.payload,
      };
    },
    // set active products
    setProductsOems: (state, action: PayloadAction<OemOption[]>) => {
      return {
        ...state,
        activeProducts: action.payload,
      };
    },
  },
});

export const {
  storeOem,
  updateOemActive,
  clearOem,
  updateActivateState,
  clearActivateState,
  setOems,
  setProductsOems,
} = oemSlice.actions;

export default oemSlice.reducer;
