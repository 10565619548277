import { PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';

import Stepper from '../../common/Stepper/Stepper';
import StepProgress from '../StepProgress/StepProgress';
import FormSectionWrapper from '../FormSectionWrapper/FormSectionWrapper';

import {
  GenericManageSkeletonFormWrapperPartial,
  StepperItemType,
} from '../../../models/admin-form-types';

import styles from './GenericManageSkeleton.module.scss';

type GenericManageSkeletonType = GenericManageSkeletonFormWrapperPartial & {
  isEdit?: boolean;
  stepperItems: StepperItemType;
  step: number | number[];
  noLastSaveBtn?: boolean;
  onStepClick?: (label: string, depth: number, parentLabel?: string) => void;
};

const GenericManageSkeleton = ({
  isEdit = false,
  stepperItems,
  step,
  saveBtnDisabled,
  children,
  showUnsavedChangesModal,
  noLastSaveBtn,
  onBackBtnClick,
  onSaveBtnClick,
  onStepClick,
}: PropsWithChildren<GenericManageSkeletonType>) => {
  const intl = useIntl();

  const stepArray = typeof step === 'number' ? [step] : step;

  const getProgress = (activeStep?: number[]) => {
    return stepperItems.reduce<number>((total, currentStep, stepIndex) => {
      if (activeStep) {
        if (stepIndex < activeStep[0]) {
          total += currentStep.children ? currentStep.children.length : 1;
        } else if (stepIndex === activeStep[0]) {
          total += currentStep.children ? activeStep[1] : 0;
        }
      } else {
        total += currentStep.children ? currentStep.children.length : 1;
      }
      return total;
    }, 0);
  };

  const getCurrentProgress = () => {
    if (isEdit) {
      return 100;
    }

    const progress = (getProgress(stepArray) / getProgress()) * 100;
    return Math.floor(progress);
  };

  const lastStep = stepperItems[stepperItems.length - 1];
  const isNotFirstStep = stepArray[0] > 0 || stepArray[1] > 0;
  const isLastStep =
    stepArray[0] === stepperItems.length - 1 &&
    (!lastStep.children || stepArray[1] !== lastStep.children.length - 1);
  const saveBtnLabelId = isLastStep ? 'save-label' : 'next-label';

  const getFormSectionWrapper = () => (
    <FormSectionWrapper
      saveBtnLabel={
        noLastSaveBtn && isLastStep
          ? ''
          : intl.formatMessage({ id: saveBtnLabelId })
      }
      showBackBtn={isNotFirstStep}
      saveBtnDisabled={saveBtnDisabled}
      showUnsavedChangesModal={showUnsavedChangesModal}
      onBackBtnClick={onBackBtnClick}
      onSaveBtnClick={onSaveBtnClick}
    >
      {children}
    </FormSectionWrapper>
  );

  return (
    <div className={styles.container}>
      <div className={styles.contentWrapper}>
        <div className={styles.progressWrapper}>
          <StepProgress
            className={styles.marginBottom}
            progress={getCurrentProgress()}
          />
          <Stepper
            isEdit={isEdit}
            items={stepperItems}
            active={step}
            handleClick={(label, depth, parentLabel) =>
              onStepClick && onStepClick(label, depth, parentLabel)
            }
          >
            {getFormSectionWrapper()}
          </Stepper>
        </div>
        <div className={styles.desktopContainer}>{getFormSectionWrapper()}</div>
      </div>
    </div>
  );
};

export default GenericManageSkeleton;
