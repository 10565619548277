import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import _, { cloneDeep, sortBy } from 'lodash';

import InputRow from '../../InputRow/InputRow';
import ProductListWithNote from '../ProductListWithNote/ProductListWithNote';

import styles from './ManagePortProductForm.module.scss';
import {
  ManagePortProductFormProps,
  StockpointProductType,
} from '../../../../models/admin-form-types';
import Button from '../../../common/Button/Button';

const ManagePortProductForm = ({
  formValue,
  portId,
  portName,
  productStatus,
  initialProductStatus,
  setProductStatus,
}: ManagePortProductFormProps) => {
  const intl = useIntl();
  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });
  const [filteredProducts, setFilteredProducts] = useState(
    formValue.stockpointProducts || [],
  );

  const [productFilter, setProductFilter] = useState('');

  const filterProducts = (
    prods: StockpointProductType[],
    prodFilter: string,
  ) => {
    let tempProducts = cloneDeep(prods);
    if (prodFilter) {
      tempProducts = tempProducts.filter((prod) =>
        prod.name?.toLowerCase()?.includes(prodFilter.toLowerCase()),
      );
    }

    setFilteredProducts(sortBy(tempProducts, 'name'));
  };

  useEffect(() => {
    filterProducts(formValue.stockpointProducts, productFilter);
  }, [formValue.stockpointProducts, productFilter]);

  const filterProductListPlaceholder = getTranslatedMessage(
    'enter-a-product-name-label',
  );

  const toggleExclusionAll = (value: boolean) => {
    const tempStatus = cloneDeep(productStatus);
    if (!value) {
      tempStatus.forEach((status) => {
        status.portProductExclusion.value = value;
      });
    } else {
      tempStatus.forEach((status, index) => {
        status.portProductExclusion.value =
          initialProductStatus[index].portProductExclusion.value;
      });
    }

    setProductStatus(tempStatus);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h4>
          <span>{getTranslatedMessage('product-availability-for-label')}</span>
          <span className={styles.portName}>{portName}</span>
        </h4>
        <div className={styles.selectedStockPoint}>
          <span>{getTranslatedMessage('selected-stock-point-label')}</span>
          <span>{formValue.stockpoint.name}</span>
        </div>
      </div>
      <div className={styles.content}>
        <div className={'columnRow'}>
          <InputRow
            labelId={'filter-product-list-label'}
            value={productFilter}
            onChange={(value: string) => setProductFilter(value)}
            placeholder={filterProductListPlaceholder}
            required={false}
          />
          {productStatus.some((x) => x.portProductExclusion.value) && (
            <Button
              className={styles.button}
              color={'green'}
              onClick={() => toggleExclusionAll(false)}
            >
              <FormattedMessage id={'reset-exclusion-label'} />
            </Button>
          )}
          {!_.isEqual(initialProductStatus, productStatus) &&
            productStatus.every((x) => !x.portProductExclusion.value) && (
              <Button
                className={styles.button}
                color={'nobel'}
                onClick={() => toggleExclusionAll(true)}
              >
                <FormattedMessage id={'undo-reset-exclusion-label'} />
              </Button>
            )}
        </div>
        <ProductListWithNote
          products={filteredProducts}
          ports={formValue.ports}
          defaultPortId={portId}
          productStatus={productStatus}
          setProductStatus={(items) => {
            setProductStatus(items);
          }}
        />
      </div>
    </div>
  );
};

export default ManagePortProductForm;
