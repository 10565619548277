import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '../../../common/Button/Button';
import Modal from '../../../common/Modal/Modal';
import SelectFormRow from '../../SelectFormRow/SelectFormRow';
import styles from './AddPortModal.module.scss';
import { SearchResultPort } from '../../../../models/search-result-port';

type AddPortModalProps = {
  ports: SearchResultPort[];
  onSave: (newPort: SearchResultPort | undefined) => void;
  onClose: () => void;
};

const AddPortModal = ({ ports, onSave, onClose }: AddPortModalProps) => {
  const intl = useIntl();
  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });
  const [selectedPort, setSelectedPort] = useState<
    SearchResultPort | undefined
  >();

  const handleSaveBtnClick = () => {
    onSave(selectedPort);
    onClose();
  };

  return (
    <Modal onClose={onClose} className={styles.addPortContainer}>
      <div className={styles.container}>
        <h1>
          <FormattedMessage id="add-new-port-to-stockpoint-label" />
        </h1>
        <div className={styles.modalContent}>
          <SelectFormRow
            labelId="port-name-label"
            value={selectedPort}
            options={ports.map((port: any) => ({
              name: port.name,
              value: port,
            }))}
            onChange={(value) => {
              setSelectedPort(value);
            }}
            tooltip={getTranslatedMessage('add-new-port-name-tooltip')}
          />
        </div>
        <div className={styles.actionBtnContainer}>
          <Button color="nobel" onClick={() => onClose()}>
            <FormattedMessage id="cancel-label" />
          </Button>
          <Button
            color="green"
            disabled={!selectedPort}
            onClick={() => handleSaveBtnClick()}
          >
            <FormattedMessage id="save-label" />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AddPortModal;
