/* eslint-disable react/jsx-key */
import { useEffect, useState, useMemo, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import XLSX from 'xlsx';
import { uniq, sortBy } from 'lodash';

import { userRolesNoAnonymous } from '../../../configs/admin-constants';
import withAdmin from '../../../hoc/withAdmin';
import { RootStore } from '../../../store';
import { addToast } from '../../../features/toastSlice';
import {
  saveBulkUsers,
  downloadBulkTemplate,
} from '../../../services/UserService';
import styles from './AddMultiUsersPage.module.scss';

import Header from '../../../components/Admin/Header/Header';
import InfoCard from '../../../components/Admin/Products/InfoCard/InfoCard';
import HorizontalStepper from '../../../components/common/HorizontalStepper/HorizontalStepper';
import MobileStepper from '../../../components/common/MobileStepper/MobileStepper';
import UploadUsersData from '../../../components/Admin/common/UploadUsersData/UploadUsersData';
import { PreviousLink } from '../../../models/previous-link';
import { ValidationColumn } from '../../../models/table-column';
import LoadingSpinner from '../../../components/common/LoadingSpinner/LoadingSpinner';
import ValidateData from './components/ValidateData/ValidateData';
import SaveUserData from './components/SaveUserData/SaveUserData';
import { ReactComponent as DocumentIcon } from '../../../icons/document-green.svg';
import { SaveUserRequest } from '../../../models/user-request';
import StepProgress from '../../../components/Admin/StepProgress/StepProgress';
import { emailValidator } from '../../../utils/string.util';
import { useNavigate } from 'react-router';
import { hideLoading, showLoading } from '../../../features/loadingSlice';

export interface ErrorMessage {
  row: number;
  property: keyof SaveUserRequest;
  message: string;
}

/**
 * add multi users page component
 */
function AddMultiUsersPage() {
  // init hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();
  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });

  const { countries, languages } = useSelector(
    (rootState: RootStore) => rootState.lookups,
  );

  const countryOptions = useMemo(() => {
    return (
      countries?.map((item) => ({
        name: item.name,
        value: item.id,
      })) || []
    );
  }, [countries]);

  const languageOptions = useMemo(() => {
    return (
      languages?.map((item) => ({
        name: item.name,
        value: item.id,
      })) || []
    );
  }, [languages]);

  const roleOptions = useMemo(() => {
    return userRolesNoAnonymous.map((item) => ({
      name: item,
      value: item,
    }));
  }, [userRolesNoAnonymous]);

  const activeOptions = [
    {
      name: 'Yes',
      value: true,
    },
    {
      name: 'No',
      value: false,
    },
  ];

  const columns: ValidationColumn<SaveUserRequest>[] = [
    {
      tableHeaderId: 'first-name-label',
      objectKey: 'firstName',
      required: true,
      type: 'input',
      headerKey: ['firstName', 'lastName'],
    },
    {
      tableHeaderId: 'last-name-label',
      objectKey: 'lastName',
      required: true,
      type: 'input',
    },
    {
      tableHeaderId: 'email-label',
      objectKey: 'email',
      required: true,
      type: 'input',
      customValidator: emailValidator,
    },
    {
      tableHeaderId: 'company-name-label',
      objectKey: 'companyName',
      type: 'input',
    },
    {
      tableHeaderId: 'phone-label',
      objectKey: 'phoneNumber',
      type: 'input',
    },
    {
      tableHeaderId: 'country-label',
      objectKey: 'countryId',
      type: 'select',
      required: true,
      selectOptions: countryOptions,
      canSearch: true,
    },
    {
      tableHeaderId: 'language-label',
      objectKey: 'languageId',
      required: true,
      type: 'select',
      selectOptions: languageOptions,
    },
    {
      tableHeaderId: 'role-label',
      objectKey: 'role',
      required: true,
      type: 'select',
      selectOptions: roleOptions,
    },
    {
      tableHeaderId: 'user-countries-label',
      required: true,
      type: 'multi-select',
      objectKey: 'userCountries',
      selectOptions: countryOptions,
      selectSuffixLabel: getTranslatedMessage('countries-label'),
      canSearch: true,
    },
    {
      tableHeaderId: 'active-label',
      objectKey: 'active',
      type: 'select',
      selectOptions: activeOptions,
      placeholder: ' ',
    },
  ];

  const [processing, setProcessing] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [file, setFile] = useState();
  const [data, handleDataChange] = useState<SaveUserRequest[]>([]);
  const [errorCount, setErrorCount] = useState<number>(0);
  const [errorMessages, setErrorMessages] = useState<ErrorMessage[]>([]);
  const [sendEmailFlag, setSendEmailFlag] = useState(false);

  /* istanbul ignore next */
  const countError = (newData: any[]) => {
    let count = 0;
    newData.forEach((row, rowIndex) => {
      if (
        columns.some((column) => {
          const value = row[column.objectKey];
          if (column.required && (!value || value.length === 0)) {
            return true;
          }

          if (column.customValidator && column.customValidator(value)) {
            return true;
          }

          return false;
        }) ||
        errorMessages.findIndex((item) => item.row === rowIndex) > -1
      ) {
        count += 1;
      }
    });
    setErrorCount(count);
  };

  /* istanbul ignore next */
  const setData = (newData: any[]) => {
    if (newData.length === 0) return;

    countError(newData);
    handleDataChange(newData);
  };

  /* istanbul ignore next */
  const generateErrorMessage = (err: any): string | string[] => {
    if (err.response?.data?.errors) {
      const errors: ErrorMessage[] = err.response.data.errors;
      if (errors.length > 0 && errors[0].property && errors[0].message) {
        const errorCount = uniq(errors.map((item) => item.row));
        setErrorCount(errorCount.length);
        setErrorMessages(sortBy(errors, 'row'));
        const res = errors.map(
          (item: any) => `Row ${item.row}: ${item.property} ${item.message}`,
        );
        return res;
      } else {
        return String(errors[0]);
      }
    }
    return String(err);
  };

  /* istanbul ignore next */
  useEffect(() => {
    countError(data);
  }, [errorMessages]);

  useEffect(() => {
    if (currentStep === 3) {
      const timer = setTimeout(() => navigate('/admin/users'), 3000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [currentStep]);

  useEffect(() => {
    if (currentStep === 2) {
      const items = data.map((item) => {
        const selectedAllCounties =
          item.userCountries?.length === 1 && item.userCountries[0] === -1;

        return {
          ...item,
          displayOnlyProductsAvailableInUserCountries: !selectedAllCounties,
          userCountries: selectedAllCounties ? undefined : item.userCountries,
        };
      });

      saveBulkUsers({ items, sendWelcomeEmail: sendEmailFlag })
        .then(() => {
          setCurrentStep(3);
        })
        .catch((err) => {
          generateErrorMessage(err);

          setCurrentStep(1);
          dispatch(
            addToast({
              type: 'error',
              title: getTranslatedMessage('multi-user-added-failure'),
              message: '',
            }),
          );
        });
    }
  }, [currentStep]);

  const parseCountries = (value: string): number[] => {
    if (!value) {
      return [];
    }

    const countryValues = value.split(',');
    const res = countryValues.map((item) => {
      const itemStr = item.trim();
      if (itemStr === 'Global' || itemStr === 'All') {
        return -1;
      }
      return countries.find((country) => country.name === itemStr)?.id || -9;
    });

    return res.filter((item) => item >= -1);
  };

  /* istanbul ignore next */
  const submitFile = () => {
    dispatch(showLoading());
    setCurrentStep(1);
    if (file) {
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;
      reader.onload = (e) => {
        /* Parse data */
        const bstr = e?.target?.result;
        const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const xlsxData = XLSX.utils
          .sheet_to_json(ws, {
            raw: false,
          })
          .map((row: any) => {
            return {
              firstName: row['First Name'],
              lastName: row['Last Name'],
              email: row['Email'],
              companyName: row['Company'],
              phoneNumber: row['Phone Number'],
              countryId:
                row['User Country'] === 'Global'
                  ? -1
                  : countries.find(
                      (country) => country.name === row['User Country'],
                    )?.id || undefined,
              languageId:
                languages.find(
                  (language) => language.name === row['User Language'],
                )?.id || undefined,
              role:
                userRolesNoAnonymous.find(
                  (role) => role === row['User Role'],
                ) || undefined,
              userCountries: parseCountries(row['Country Visibility']),
              active:
                row['Active'] === 'Yes'
                  ? true
                  : row['Active'] === 'No'
                  ? false
                  : true,
            };
          });
        if (xlsxData.length > 500) {
          setCurrentStep(0);
          dispatch(
            addToast({
              type: 'error',
              title: getTranslatedMessage('upload-file-failure'),
              message: getTranslatedMessage('too-many-rows-failure-info'),
            }),
          );
        } else {
          setData(xlsxData);
          // clear error message
          setErrorMessages([]);
        }
        dispatch(hideLoading());
      };
      if (rABS) reader.readAsBinaryString(file as any);
      else reader.readAsArrayBuffer(file as any);
    } else {
      dispatch(hideLoading());
    }
  };

  /* istanbul ignore next */
  const downloadTemplate = (): void => {
    setProcessing(true);
    downloadBulkTemplate()
      .then(() => {
        console.log('download bulk template api success');
      })
      .catch(() => {
        dispatch(
          addToast({
            type: 'error',
            title: getTranslatedMessage('bulk-template-download-failure'),
            message: '',
          }),
        );
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const steps = useMemo(
    () => [
      getTranslatedMessage('upload-data'),
      getTranslatedMessage('validate-data'),
      getTranslatedMessage('save-data'),
    ],
    [],
  );

  const breadcrumbLinks: PreviousLink[] = [
    {
      name: 'Home',
      to: '/home',
    },
    {
      name: getTranslatedMessage('header-menu-manage-users'),
      to: '/admin/users',
    },
    {
      name: getTranslatedMessage('header-menu-add-multi-users'),
    },
  ];

  const descriptionValues = {
    a: (value: string[]) => (
      <a className={styles.redInfo} onClick={() => downloadTemplate()}>
        {value}
      </a>
    ),
  };

  const prefixInfo = (
    <Fragment>
      <p className={styles.title}>
        <FormattedMessage id={'upload-multiple-users-label'} />
      </p>
      <p className={styles.para}>
        <FormattedMessage id={'add-country-para-1'} />
      </p>

      <div className={styles.twoColumnGrid}>
        <InfoCard
          icon={<DocumentIcon />}
          noBoldHeader={true}
          heading={
            <FormattedMessage id={'download-blank-user-template-label'} />
          }
          body={
            <FormattedMessage
              id={'add-user-template-info'}
              values={descriptionValues}
            />
          }
        />
      </div>
    </Fragment>
  );

  /* istanbul ignore next */
  const stepsComponents = [
    <UploadUsersData
      submit={submitFile}
      setFile={setFile}
      emailUserChange={setSendEmailFlag}
      prefixInfo={prefixInfo}
    />,
    <ValidateData
      save={() => setCurrentStep(2)}
      cancel={() => setCurrentStep(0)}
      data={data}
      setData={setData}
      columns={columns}
      errorCount={errorCount}
      errorMessages={errorMessages}
      setErrorMessages={setErrorMessages}
    />,
    <SaveUserData completed={false} success={true} />,
  ];

  const getCurrentProgress = () => {
    const progress = (currentStep / stepsComponents.length) * 100;
    return Math.floor(progress);
  };

  return (
    <div className={styles.container}>
      <Header
        breadcrumbLinks={breadcrumbLinks}
        titleId={'header-menu-add-multi-users'}
      />
      <div className={styles.backdrop}>
        <div className={styles.pageContentContainer}>
          <StepProgress
            className={styles.stepProgress}
            progress={getCurrentProgress()}
          />

          <div className={styles.prefixDesktop}>{prefixInfo}</div>
          <HorizontalStepper steps={steps} currentStep={currentStep} />
          <MobileStepper
            steps={steps}
            currentStep={currentStep}
            stepsComponents={stepsComponents}
          />
          <div className={styles.desktopOnly}>
            {stepsComponents.map((stepComponent, index) =>
              index === currentStep ? (
                <Fragment key={index}>{stepComponent}</Fragment>
              ) : null,
            )}
          </div>
          {currentStep === stepsComponents.length && (
            <SaveUserData completed={true} success={true} />
          )}
        </div>
      </div>
      {/* )} */}
      {processing && <LoadingSpinner className={'lookupsLoading'} />}
    </div>
  );
}

export default withAdmin(AddMultiUsersPage, true);
