import { apiInstance } from '../api/requestInterceptor';

import {
  PortDetails,
  PortResponse,
  SearchPortResponse,
} from '../models/port-details';
import { SearchResultPort } from '../models/search-result-port';
import { PortDetailsFormType } from '../models/admin-form-types';

/**
 * get ports
 */
async function getPorts(): Promise<SearchResultPort[]> {
  const res = await apiInstance.get<{ ports: SearchResultPort[] }>('/ports');

  return res.data.ports;
}

/**
 * get port detail
 * @param friendlyUrl port friendlyUrl
 */
async function getPortDetails(friendlyUrl: string): Promise<PortResponse> {
  const res = await apiInstance.get<PortResponse>(`/ports/${friendlyUrl}`);

  return res.data;
}

/**
 * get nearby ports
 * @param countryId port country
 */
async function getPortsByCountry(
  countryId: number,
): Promise<SearchPortResponse> {
  const res = await apiInstance.get<SearchPortResponse>(
    `/ports?countryId=${countryId}`,
  );

  return res.data;
}

/**
 * Add port
 * @param values the port details
 */
async function addPort(values: PortDetailsFormType): Promise<PortDetails> {
  const res = await apiInstance.post('/ports', values);

  return res.data;
}

/**
 * Edit port
 * @param values the port details
 */
async function editPort(values: PortDetailsFormType): Promise<PortDetails> {
  const res = await apiInstance.put(`/ports/${values.id}`, values);

  return res.data;
}

/**
 * Activate port
 */
async function activatePort(portId: number): Promise<PortDetails> {
  const res = await apiInstance.put(`/ports/${portId}/activate`);

  return res.data;
}

/**
 * Deactivate port
 */
async function deactivatePort(portId: number): Promise<PortDetails> {
  const res = await apiInstance.put(`/ports/${portId}/deactivate`);

  return res.data;
}

/**
 * Update port product note & Exclusions
 */
async function updatePortProductNotesExclusions(
  portProductNotes: any[],
  portProductExclusions: any[],
  portId: number,
): Promise<PortDetails> {
  const res = await apiInstance.put(
    `/ports/${portId}/portProductNotesExclusions`,
    {
      portProductNotes: portProductNotes,
      portProductExclusions: portProductExclusions,
    },
  );

  return res.data;
}

export {
  getPortDetails,
  getPorts,
  getPortsByCountry,
  addPort,
  editPort,
  activatePort,
  deactivatePort,
  updatePortProductNotesExclusions,
};
