import styles from './UserProfile.module.scss';
import UserProfileDropdown from './UserProfileDropdown/UserProfileDropdown';
import UserActionButton from './UserActionButton/UserActionButton';
import { ReactComponent as Contact } from '../../../../icons/contact.svg';
import { ReactComponent as UserLogin } from '../../../../icons/user-login.svg';
import { ReactComponent as Home } from '../../../../icons/home.svg';
import { ReactComponent as Stack } from '../../../../icons/stack-white.svg';
import { ReactComponent as OpenMenu } from '../../../../icons/arrow-right-green.svg';
import { ReactComponent as CloseMenu } from '../../../../icons/arrow-left.svg';
import { ReactComponent as LoginIcon } from '../../../../icons/download-white.svg';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { RootStore } from '../../../../store';
import {
  getWelcomeInfoFromStorage,
  setAccessTokenToLocalStorage,
  setCultureToLocalStorage,
  setSectorToLocalStorage,
  setWelcomeInfoToStorage,
  clearAccessTokenToLocalStorage,
  clearUserInfoToLocalStorage,
} from '../../../../services/LocalStorageService';
import { WelcomeInfo } from '../../../../models/welcome-info';
import {
  setCulture,
  setSector,
  setWelcomeInfo,
} from '../../../../features/welcomeInfoSlice';
import { showGenericModal } from '../../../../features/genericModalSlice';
import { PropsWithoutRef, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import {
  CountryItem,
  LanguageItem,
  SectorItem,
} from '../../../../models/lookups';
import { getSectorAndCulture } from '../../../../utils/welcome-info.util';
import { openContactUsModal } from '../../../../features/contactUsSlice';
import { setHasLogout } from '../../../../features/loginSlice';
import config from '../../../../configs/config';
import { loginAsAnonymous } from '../../../../services/AuthService';
import { UserInfo } from '../../../../models/user-info';
import { useMsal } from '@azure/msal-react';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';
import ReactTooltip from 'react-tooltip';

interface UserProfileProps {
  welcomeInfo: WelcomeInfo;
  showMenu: boolean;
  toggleMainMenu: (state: boolean) => void;
  openAdminMenu: boolean;
  hasLogin: boolean;
  toggleAdminMenu: (state: boolean) => void;
  user: UserInfo | undefined;
}

const defaultSector: SectorItem = {
  friendlyUrl: 'all-sectors',
  id: 0,
  name: 'All Sectors',
  supportFindADistributor: 0,
};

/**
 * user profile section for main header
 * @param welcomeInfo welcomeInfo
 */
function UserProfile({
  welcomeInfo,
  showMenu,
  toggleMainMenu,
  openAdminMenu,
  hasLogin,
  toggleAdminMenu,
  user,
}: PropsWithoutRef<UserProfileProps>) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();
  const { instance, accounts } = useMsal();

  const { sectors, countries, languages } = useSelector(
    (state: RootStore) => state.lookups,
  );

  const { unsaved } = useSelector((state: RootStore) => state.genericModal);

  const updatedSectors = useMemo(() => {
    return hasLogin ? [defaultSector, ...sectors] : sectors;
  }, [sectors, hasLogin]);

  const savedWelcomeInfo = getWelcomeInfoFromStorage();
  const [industrySector, setIndustrySector] = useState<SectorItem>(
    welcomeInfo.industrySector as SectorItem,
  );
  const [country, setCountry] = useState<CountryItem>(
    welcomeInfo.country as CountryItem,
  );
  const [language, setLanguage] = useState<LanguageItem>(
    welcomeInfo.language as LanguageItem,
  );
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const currentCountry = countries.find(
    (item) => item.id === welcomeInfo.country?.id,
  );
  const currentLanguage = languages.find(
    (item) => item.id === welcomeInfo.language?.id,
  );

  useEffect(() => {
    if (
      welcomeInfo &&
      welcomeInfo.country &&
      welcomeInfo.industrySector &&
      welcomeInfo.language
    ) {
      setCountry(welcomeInfo.country);
      setLanguage(welcomeInfo.language);
      setIndustrySector(welcomeInfo.industrySector);
    }
  }, [welcomeInfo]);

  useEffect(() => {
    if (sectors && sectors.length > 0 && user) {
      setTimeout(() => {
        const userSector = updatedSectors.find(
          (item) => item.id === user.sectorAdminSectorId,
        );
        if (userSector) {
          onIndustrySectorChange(userSector);
        }
      }, 1000);
    }
  }, [user, sectors]);

  const onIndustrySectorChange = (value: SectorItem) => {
    toggleMainMenu(false);
    const info: WelcomeInfo = {
      industrySector: value,
      country,
      language,
    };

    const { sector } = getSectorAndCulture(info);

    if (savedWelcomeInfo) {
      setWelcomeInfoToStorage(info);
      setSectorToLocalStorage(sector);
    }

    dispatch(setWelcomeInfo(info));
    dispatch(setSector(sector));
    setIndustrySector(value);
  };

  const onCountryChange = (value: CountryItem) => {
    toggleMainMenu(false);
    const info: WelcomeInfo = {
      industrySector,
      country: value,
      language,
    };

    const { culture } = getSectorAndCulture(info);

    if (savedWelcomeInfo) {
      setWelcomeInfoToStorage(info);
      setCultureToLocalStorage(culture);
    }

    dispatch(setWelcomeInfo(info));
    dispatch(setCulture(culture));
    setCountry(value);
  };

  const onLanguageChange = (value: LanguageItem) => {
    toggleMainMenu(false);
    const info: WelcomeInfo = {
      industrySector,
      country,
      language: value,
    };

    const { culture } = getSectorAndCulture(info);

    if (savedWelcomeInfo) {
      setWelcomeInfoToStorage(info);
      setCultureToLocalStorage(culture);
    }

    dispatch(setWelcomeInfo(info));
    dispatch(setCulture(culture));
    setLanguage(value);
  };

  const industrySectorOptions = updatedSectors.map((item) => ({
    name: item.name,
    value: item,
  }));

  const countryOptions = countries.map((item) => ({
    name: item.name,
    value: item,
  }));

  const languageOptions = languages.map((item) => ({
    name: item.name,
    value: item,
  }));

  const navigateUrl = (url: string) => {
    if (unsaved) {
      dispatch(
        showGenericModal({
          type: 'confirmation',
          titleId: 'unsaved-changes-label',
          messageId: 'unsaved-changes-message',
          to: url,
        }),
      );
    } else {
      navigate(url);
    }
  };

  const onHomeClick = () => {
    toggleMainMenu(false);
    navigateUrl('/home');
  };

  const onContactClick = () => {
    toggleMainMenu(false);
    if (industrySector.friendlyUrl === config.MARINE_SECTOR) {
      dispatch(openContactUsModal({}));
    } else {
      window.open(config.CONTACT_US_LINK);
    }
  };

  const onLogoutClick = async () => {
    if (accounts && accounts.length > 0) {
      await instance.logoutPopup();
    }
    toggleMainMenu(false);
    clearAccessTokenToLocalStorage();
    clearUserInfoToLocalStorage();
    const login = await loginAsAnonymous();
    setAccessTokenToLocalStorage(login.accessToken);
    dispatch(setHasLogout());
    if (industrySector?.friendlyUrl === config.ALL_SECTORS) {
      const marineSector = sectors.find(
        (x) => x.friendlyUrl === config.MARINE_SECTOR,
      );
      if (marineSector) {
        onIndustrySectorChange(marineSector);
      }
    }
    navigateUrl('/');
  };

  const onLoginClick = () => {
    toggleMainMenu(false);
    navigateUrl('/login');
  };

  const handleMenuToggleMobile = () => {
    toggleMainMenu(!showMenu);
    toggleAdminMenu(false);
  };

  const onMyAccountClick = () => {
    toggleMainMenu(false);
    navigateUrl('/users/me');
  };

  const loginTooltip = useMemo(
    () =>
      intl.formatMessage({
        id: 'login-tooltip',
      }),
    [],
  );

  return (
    <div className={classNames(styles.container, showMenu ? styles.open : '')}>
      <div className={styles.menuIcon} onClick={() => handleMenuToggleMobile()}>
        <Stack />
      </div>

      <div
        className={classNames(styles.menuOptions, showMenu ? styles.open : '')}
      >
        <div
          className={styles.menuLabelWrapper}
          onClick={() => toggleAdminMenu(!openAdminMenu)}
        >
          <span>
            <FormattedMessage id={'menu-label'} />
          </span>
          {openAdminMenu ? <CloseMenu /> : <OpenMenu />}
        </div>
        {!openAdminMenu && (
          <>
            <div className={styles.dropdownContainer}>
              <UserProfileDropdown
                onChange={onIndustrySectorChange}
                value={industrySector}
                options={industrySectorOptions}
                className={styles.dropdown}
              >
                <FormattedMessage
                  id={'industry-sector-searching-message'}
                  values={{
                    industrySector: industrySector?.name,
                    b: (values: string[]) => {
                      return <b>{values}</b>;
                    },
                  }}
                />
              </UserProfileDropdown>

              {!hasLogin && (
                <UserProfileDropdown
                  onChange={onCountryChange}
                  value={country}
                  options={countryOptions}
                  className={styles.dropdown}
                  searchable
                >
                  {currentCountry?.name}
                </UserProfileDropdown>
              )}

              <UserProfileDropdown
                onChange={onLanguageChange}
                value={language}
                options={languageOptions}
                className={styles.dropdown}
              >
                {currentLanguage?.name}
              </UserProfileDropdown>

              {hasLogin && (
                <div className={styles.myAccount} onClick={onMyAccountClick}>
                  <FormattedMessage id={'my-account-label'} />
                </div>
              )}
            </div>

            {hasLogin && <div className={styles.divider} />}

            <div className={styles.buttonContainer}>
              <UserActionButton onActionClick={onHomeClick}>
                <Home /> <span>Home</span>
              </UserActionButton>

              <UserActionButton onActionClick={onContactClick}>
                <Contact /> <span>Contact</span>
              </UserActionButton>

              {hasLogin && (
                <UserActionButton
                  onActionClick={() => setShowLogoutModal(true)}
                >
                  <UserLogin /> <span>Logout</span>
                </UserActionButton>
              )}

              {!hasLogin && industrySector?.friendlyUrl !== 'industrial' && (
                <UserActionButton onActionClick={() => onLoginClick()}>
                  <LoginIcon
                    className={styles.loginIcon}
                    data-tip
                    data-for={'login-icon'}
                  />
                  <span>Login</span>
                </UserActionButton>
              )}
            </div>
            {loginTooltip && (
              <ReactTooltip id={'login-icon'} place={'top'} effect={'solid'}>
                {loginTooltip}
              </ReactTooltip>
            )}
            {showLogoutModal && (
              <ConfirmDialog
                onClose={() => {
                  setShowLogoutModal(false);
                }}
                onConfirm={() => {
                  setShowLogoutModal(false);
                  onLogoutClick();
                }}
                message={intl.formatMessage({
                  id: 'logout-message',
                })}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default UserProfile;
