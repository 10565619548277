import styles from './ProductFamilyListItem.module.scss';
import { useIntl } from 'react-intl';
import { PropsWithoutRef, useState, useEffect } from 'react';
import { PortProduct } from '../../../models/port-details';
import { ReactComponent as Caret } from '../../../icons/caret.svg';
import classNames from 'classnames';
import FamiltyRangeTable from '../FamiltyRangeTable/FamiltyRangeTable';
import { FamilySummary } from '../../../models/family-summary';

interface ProductFamilyListItemProps {
  family: FamilySummary;
  products: PortProduct[];
  expand: boolean;
}

/**
 * Product family list item
 * @param family productFamily
 * @param products products
 */
function ProductFamilyListItem({
  family,
  products,
  expand,
}: PropsWithoutRef<ProductFamilyListItemProps>) {
  const intl = useIntl();
  const [childrenOpened, setChildrenOpened] = useState<boolean>(false);

  useEffect(() => {
    setChildrenOpened(expand);
  }, [family, products]);

  return (
    <div
      className={classNames(
        styles.container,
        childrenOpened ? styles.opened : '',
      )}
    >
      <div
        className={styles.headerContainer}
        onClick={() => setChildrenOpened(!childrenOpened)}
      >
        <div className={styles.title}>
          <h4>{family.name}</h4>
        </div>
        <div className={styles.rightSection}>
          <div className={styles.products}>{`${products.length} ${
            products.length === 1
              ? intl.formatMessage({ id: 'product-label' })
              : intl.formatMessage({
                  id: 'products-label',
                })
          }`}</div>
          <Caret className={styles.dropdownArrow} />
        </div>
      </div>
      {childrenOpened && (
        <div className={styles.listContainer}>
          <FamiltyRangeTable items={products} />
        </div>
      )}
    </div>
  );
}

export default ProductFamilyListItem;
