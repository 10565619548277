import cn from 'classnames';
import { debounce } from 'lodash';
import { useEffect, useRef } from 'react';
import config from '../../../../configs/config';
import { ReactComponent as Alert } from '../../../../icons/alert-white.svg';
import { Announcement } from '../../../../models/announcement';
import styles from './AnnouncementSlider.module.scss';

export interface AnnouncementSliderProps {
  announcements: Announcement[];
  className?: string;
}

function AnnouncementSlider({
  announcements,
  className,
}: AnnouncementSliderProps) {
  const messagesRef = useRef<HTMLDivElement>(null);

  /* istanbul ignore next */
  useEffect(() => {
    const observer = new ResizeObserver(
      debounce(() => {
        const ref = messagesRef.current;

        if (ref) {
          ref.style.setProperty('--scroll-left', `-${ref.scrollWidth}px`);
          ref.style.setProperty(
            '--scroll-speed',
            ref.scrollWidth / config.ANNOUNCEMENTS_SCROLL_SPEED + 's',
          );
        }
      }, 100),
    );

    if (messagesRef.current) {
      observer.observe(messagesRef.current);
    }

    return () => observer.disconnect();
  }, [announcements]);

  return (
    <div className={cn(styles.viewport, className)}>
      <div className={styles.messages} ref={messagesRef}>
        {(announcements ?? []).map((entry) => (
          <div key={entry.id} className={styles.message}>
            <Alert className={styles.message__icon} />
            {entry.associateLink ? (
              <a href={entry.associateLink} target="_blank" rel="noreferrer">
                {entry.title}
              </a>
            ) : (
              entry.title
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default AnnouncementSlider;
