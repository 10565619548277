import { PropsWithChildren } from 'react';
import styles from './PortPageReportTable.module.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import OemListTable from '../../../../../components/common/OemListTable/OemListTable';
import { TableColumn } from '../../../../../models/table-column';
import Paginator from '../../../../../components/common/Paginator/Paginator';
import cn from 'classnames';
import { ReactComponent as XlsxFileIcon } from '../../../../../icons/xlsx-file.svg';

type PortPageReportTableType = {
  reportData: any[];
  page: number;
  perPage: number;
  total: number;
  sortBy: string;
  sortDirection: 'asc' | 'desc';
  onClickPage: (page: number) => void;
  onClickPerPage: (page: number) => void;
  onSortChange: (column: any) => void;
  exportToExcel: () => void;
};

const PortPageReportTable = ({
  reportData,
  page,
  perPage,
  total,
  sortBy,
  sortDirection,
  onClickPage,
  onClickPerPage,
  onSortChange,
  exportToExcel,
}: PropsWithChildren<PortPageReportTableType>) => {
  const intl = useIntl();
  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });

  const tableData = reportData.map((item) => ({
    ...item,
    countryName: item?.country?.name,
  }));

  const columns: TableColumn<any>[] = [
    {
      key: 'name',
      property: 'name',
      label: getTranslatedMessage('port-name-label'),
      width: '25%',
      render: (data) => {
        return <div className={styles.green}>{data?.name}</div>;
      },
    },
    {
      key: 'country.name',
      property: 'country.name',
      label: getTranslatedMessage('product-status-country-label'),
      width: '25%',
    },
    {
      key: 'numberOfViews',
      property: 'numberOfViews',
      label: getTranslatedMessage('port-page-table-field-number-of-views'),
      width: '25%',
      render: (data) => <span>{data?.numberOfViews || 0}</span>,
    },
    {
      key: 'numberOfProductViews',
      property: 'numberOfProductViews',
      label: getTranslatedMessage('port-page-table-field-number-of-referrals'),
      width: '25%',
      render: (data) => <span>{data?.numberOfProductViews || 0}</span>,
    },
  ];

  return (
    <div className={styles.tableContainer}>
      <div className={styles.table}>
        <div className={styles.tableTitle}>
          {getTranslatedMessage('port-product-page-result')}
          <div
            className={cn(styles.exportToExcel, styles.desktop)}
            onClick={() => exportToExcel()}
          >
            <XlsxFileIcon />
            <FormattedMessage id={'oem-table-field-export-to-excel'} />
          </div>
        </div>
        <OemListTable
          data={tableData}
          sortBy={sortBy}
          sortDirection={sortDirection}
          columns={columns}
          showCheckbox={false}
          onSortChange={onSortChange}
        />
        <Paginator
          page={page}
          perPage={perPage}
          total={total}
          onPageChange={onClickPage}
          onPerPageChange={onClickPerPage}
        />
      </div>
    </div>
  );
};

export default PortPageReportTable;
