import classNames from 'classnames';
import { PropsWithoutRef, useState } from 'react';

import { ReactComponent as Expand } from '../../../icons/expand.svg';
import { ReactComponent as Unexpand } from '../../../icons/unexpand.svg';
import Checkbox from '../Checkbox/Checkbox';

import styles from './CheckboxTree.module.scss';

interface CheckboxTreeProps {
  tree: {
    value: number;
    label: string;
    children: {
      value: number;
      label: string;
    }[];
  }[];
  selected: number[];
  setSelected: (x: number[]) => void;
  className?: string;
}

function CheckboxTree({
  tree,
  selected,
  setSelected,
  className,
}: PropsWithoutRef<CheckboxTreeProps>) {
  const [expanded, setExpanded] = useState<number[]>([]);

  const allChildrenChecked = (id: number) => {
    const parent = tree.find((parent) => parent.value === id);
    const set = new Set();
    selected.forEach((current) => {
      set.add(current);
    });
    let ans = true;
    for (const child of parent?.children || []) {
      if (!set.has(child.value)) {
        ans = false;
        break;
      }
    }
    return ans;
  };

  const unSelectAllChildren = (id: number) => {
    const parent = tree.find((parent) => parent.value === id);
    const set = new Set();
    parent?.children.forEach((child) => {
      set.add(child.value);
    });
    setSelected(selected.filter((current) => !set.has(current)));
  };

  const selectAllChildren = (id: number) => {
    const parent = tree.find((parent) => parent.value === id);
    const set = new Set();
    selected.forEach((current) => {
      set.add(current);
    });
    const newState = [...selected];
    parent?.children.forEach((child) => {
      if (!set.has(child.value)) newState.push(child.value);
    });
    setSelected(newState);
  };

  return (
    <div className={classNames(styles.container, className)}>
      {tree.map((parent) => (
        <div key={parent.value}>
          <div className={styles.option}>
            {expanded.includes(parent.value) ? (
              <Unexpand
                className={styles.toggleExpand}
                onClick={() => {
                  setExpanded(
                    expanded.filter((current) => parent.value !== current),
                  );
                }}
              />
            ) : (
              <Expand
                className={styles.toggleExpand}
                onClick={() => {
                  setExpanded([...expanded, parent.value]);
                }}
              />
            )}
            {(() => {
              const allChecked = allChildrenChecked(parent.value);
              return (
                <Checkbox
                  checked={allChecked}
                  onClick={() => {
                    if (allChecked) {
                      unSelectAllChildren(parent.value);
                    } else {
                      selectAllChildren(parent.value);
                    }
                  }}
                />
              );
            })()}
            <div className={styles.checkboxLabel}>{parent.label}</div>
          </div>
          {expanded.includes(parent.value) &&
            parent.children.map((child) => (
              <div key={child.value} className={styles.option}>
                <div />
                {(() => {
                  const checked = selected.includes(child.value);
                  return (
                    <Checkbox
                      checked={checked}
                      onClick={() => {
                        if (checked) {
                          setSelected(
                            selected.filter(
                              (current) => current !== child.value,
                            ),
                          );
                        } else {
                          setSelected([...selected, child.value]);
                        }
                      }}
                    />
                  );
                })()}
                <div className={styles.checkboxLabel}>{child.label}</div>
              </div>
            ))}
        </div>
      ))}
    </div>
  );
}

export default CheckboxTree;
