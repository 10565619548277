import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import Button from '../../common/Button/Button';

import { showGenericModal } from '../../../features/genericModalSlice';

import styles from './FormActionBtns.module.scss';

type FormActionBtnsProps = {
  saveBtnLabel: string;
  showBackBtn?: boolean;
  saveBtnDisabled: boolean;
  showUnsavedChangesModal?: boolean;
  onBackBtnClick?: () => void;
  onSaveBtnClick: () => void;
};

const FormActionBtns = ({
  saveBtnLabel,
  showBackBtn = false,
  saveBtnDisabled,
  showUnsavedChangesModal,
  onBackBtnClick,
  onSaveBtnClick,
}: FormActionBtnsProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleCancelBtnClick = () => {
    if (showUnsavedChangesModal) {
      dispatch(
        showGenericModal({
          type: 'confirmation',
          titleId: 'unsaved-changes-label',
          messageId: 'unsaved-changes-message',
          to: '/',
        }),
      );
    } else {
      navigate('/');
    }
  };

  return (
    <div className={styles.container}>
      <Button color={'nobel'} onClick={handleCancelBtnClick}>
        <FormattedMessage id={'cancel-label'} />
      </Button>
      <div className={styles.saveBtnWrapper}>
        {showBackBtn && (
          <Button
            color={'transparent'}
            className={styles.backBtn}
            onClick={onBackBtnClick}
          >
            <FormattedMessage id={'back-label'} />
          </Button>
        )}
        {saveBtnLabel && (
          <Button
            color={'green'}
            disabled={saveBtnDisabled}
            onClick={onSaveBtnClick}
          >
            {saveBtnLabel}
          </Button>
        )}
      </div>
    </div>
  );
};

export default FormActionBtns;
