import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
  GenericFormProps,
  EnvironmentalAndOtherFeaturesType,
} from '../../../../../../models/admin-form-types';
import { RootStore } from '../../../../../../store';
import FormWrapper from '../../../../../../components/Admin/FormWrapper/FormWrapper';
import StepContentContainer from '../../../../../../components/Admin/StepContentContainer/StepContentContainer';
import { useLayoutEffect, useMemo } from 'react';
import '../../../../../../components/Admin/shared.scss';
import RadioGroup from '../../../../../../components/Admin/RadioGroup/RadioGroup';

type EnvironmentalAndOtherProps =
  GenericFormProps<EnvironmentalAndOtherFeaturesType> & {
    formValue: EnvironmentalAndOtherFeaturesType;
    displayOnlyPartialInfo: boolean;
  };

const EnvironmentalAndOther = ({
  formValue,
  handleValueChange,
  displayOnlyPartialInfo,
}: EnvironmentalAndOtherProps) => {
  const intl = useIntl();
  const { yesNoNones } = useSelector((state: RootStore) => state.lookups);

  const yesNoNonesItems = useMemo(() => {
    return yesNoNones.map((item) => ({
      label: item.name,
      value: item.id,
    }));
  }, [yesNoNones]);

  useLayoutEffect(() => {
    const defaultVal = yesNoNones.find((item) => item.name === 'N/A')?.id;
    if (defaultVal) {
      if (!formValue.foodGrade) {
        handleValueChange('foodGrade', defaultVal);
      }
      if (!formValue.superiorBiodegradation) {
        handleValueChange('superiorBiodegradation', defaultVal);
      }
      if (!formValue.multiMetalsSuitable) {
        handleValueChange('multiMetalsSuitable', defaultVal);
      }
      if (!formValue.formulatedWithoutSilicate) {
        handleValueChange('formulatedWithoutSilicate', defaultVal);
      }
      if (!formValue.formulatedWithoutHeavyMetal) {
        handleValueChange('formulatedWithoutHeavyMetal', defaultVal);
      }
      if (!formValue.formulatedWithoutBoron) {
        handleValueChange('formulatedWithoutBoron', defaultVal);
      }
      if (!formValue.formulatedWithoutChlorinatedParaffin) {
        handleValueChange('formulatedWithoutChlorinatedParaffin', defaultVal);
      }
    }
  }, [yesNoNones]);

  return (
    <StepContentContainer
      title={intl.formatMessage({ id: 'product-characteristics-title' })}
    >
      <FormWrapper
        title={intl.formatMessage({
          id: 'environmental-and-other-features-title',
        })}
      >
        <div className="columnRow">
          <RadioGroup
            required={!displayOnlyPartialInfo}
            labelId="food-grade-label"
            items={yesNoNonesItems}
            selected={formValue.foodGrade}
            onClick={(value: number) => {
              if (formValue.foodGrade === value) {
                handleValueChange('foodGrade', null);
              } else {
                handleValueChange('foodGrade', value);
              }
            }}
            tooltip={intl.formatMessage({ id: 'food-grade-tooltip' })}
          />
          <RadioGroup
            required={!displayOnlyPartialInfo}
            labelId="superior-biodegradation-label"
            items={yesNoNonesItems}
            selected={formValue.superiorBiodegradation}
            onClick={(id: number) => {
              if (formValue.superiorBiodegradation === id) {
                handleValueChange('superiorBiodegradation', null);
              } else {
                handleValueChange('superiorBiodegradation', id);
              }
            }}
            tooltip={intl.formatMessage({
              id: 'superior-biodegradation-tooltip',
            })}
          />
        </div>
        <div className="columnRow">
          <RadioGroup
            required={!displayOnlyPartialInfo}
            labelId="multi-metals-suitable-label"
            items={yesNoNonesItems}
            selected={formValue.multiMetalsSuitable}
            onClick={(id: number) => {
              if (formValue.multiMetalsSuitable === id) {
                handleValueChange('multiMetalsSuitable', null);
              } else {
                handleValueChange('multiMetalsSuitable', id);
              }
            }}
            tooltip={intl.formatMessage({
              id: 'multi-metals-suitable-tooltip',
            })}
          />
          <RadioGroup
            required={!displayOnlyPartialInfo}
            labelId="formulated-without-silicate-label"
            items={yesNoNonesItems}
            selected={formValue.formulatedWithoutSilicate}
            onClick={(id: number) => {
              if (formValue.formulatedWithoutSilicate === id) {
                handleValueChange('formulatedWithoutSilicate', null);
              } else {
                handleValueChange('formulatedWithoutSilicate', id);
              }
            }}
            tooltip={intl.formatMessage({
              id: 'formulated-without-silicate-tooltip',
            })}
          />
        </div>
        <div className="columnRow">
          <RadioGroup
            required={!displayOnlyPartialInfo}
            labelId="formulated-without-heavy-metal-label"
            items={yesNoNonesItems}
            selected={formValue.formulatedWithoutHeavyMetal}
            onClick={(id: number) => {
              if (formValue.formulatedWithoutHeavyMetal === id) {
                handleValueChange('formulatedWithoutHeavyMetal', null);
              } else {
                handleValueChange('formulatedWithoutHeavyMetal', id);
              }
            }}
            tooltip={intl.formatMessage({
              id: 'formulated-without-heavy-metal-tooltip',
            })}
          />
          <RadioGroup
            required={!displayOnlyPartialInfo}
            labelId="formulated-without-boron-label"
            items={yesNoNonesItems}
            selected={formValue.formulatedWithoutBoron}
            onClick={(id: number) => {
              if (formValue.formulatedWithoutBoron === id) {
                handleValueChange('formulatedWithoutBoron', null);
              } else {
                handleValueChange('formulatedWithoutBoron', id);
              }
            }}
            tooltip={intl.formatMessage({
              id: 'formulated-without-boron-tooltip',
            })}
          />
        </div>
        <div className="columnRow">
          <RadioGroup
            required={!displayOnlyPartialInfo}
            labelId="formulated-without-chlorinated-paraffin-label"
            items={yesNoNonesItems}
            selected={formValue.formulatedWithoutChlorinatedParaffin}
            onClick={(id: number) => {
              if (formValue.formulatedWithoutChlorinatedParaffin === id) {
                handleValueChange('formulatedWithoutChlorinatedParaffin', null);
              } else {
                handleValueChange('formulatedWithoutChlorinatedParaffin', id);
              }
            }}
            tooltip={intl.formatMessage({
              id: 'formulated-without-chlorinated-paraffin-tooltip',
            })}
          />
        </div>
      </FormWrapper>
    </StepContentContainer>
  );
};

export default EnvironmentalAndOther;
