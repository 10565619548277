export const contactFields = [
  {
    key: 'emails',
    labelId: 'email-label',
    tooltip: 'email-address-tooltip',
  },
  {
    key: 'phones',
    labelId: 'phone-label',
    tooltip: 'phone-number-tooltip',
  },
  {
    key: 'outOfHoursPhones',
    labelId: 'mobile-number-label',
    tooltip: 'mobile-number-tooltip',
  },
  {
    key: 'faxes',
    labelId: 'fax-label',
    tooltip: 'fax-tooltip',
  },
  {
    key: 'others',
    labelId: 'others-label',
  },
];

export const userRolesNoAnonymous = [
  'Approver',
  'Distributor',
  'DMS Admin',
  'Internal User',
  'Super Admin',
];

export const userRoles = [
  'Anonymous',
  'Approver',
  'Distributor',
  'DMS Admin',
  'Internal User',
  'Super Admin',
];

export const userVisibilities = [
  {
    name: 'Active',
    value: 'Active',
  },
  {
    name: 'Inactive',
    value: 'Inactive',
  },
  {
    name: 'Pending Approvals',
    value: 'Pending Approval',
  },
  {
    name: 'Idle - No login for 2 years',
    value: 'Idle',
  },
];

export const urlValidationPattern = /^(https:|http:|www\.)\S*$/gim;
