import { PropsWithoutRef } from 'react';
import { FormattedMessage } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';

import Card from '../../../../../../components/common/Card/Card';
import styles from './SectorVisibility.module.scss';
import { ReactComponent as QuestionMark } from '../../../../../../icons/question-info.svg';
import Checkbox from '../../../../../../components/common/Checkbox/Checkbox';
import { SectorDistributorLinks } from '../../../../../../models/distrubutor-links';

interface SectorVisibilityProps {
  selectedSectors: number[];
  setSelectedSectors: (x: any) => void;
  sectors: SectorDistributorLinks[] | undefined;
}

function SectorVisibility({
  selectedSectors,
  setSelectedSectors,
  sectors = [],
}: PropsWithoutRef<SectorVisibilityProps>) {
  return (
    <div className={styles.container}>
      <Card className={styles.card}>
        <>
          <h2>
            <FormattedMessage id={'sector-visibility'} />
          </h2>
          <div className={styles.divider} />
          <p className={styles.para}>
            <FormattedMessage id={'sector-visibility-para'} />
          </p>
          <div className={styles.options}>
            <div className={styles.optionsHeader}>
              <div className={styles.optionsHeaderText}>
                <FormattedMessage id={'sector-label'} />
              </div>
              <QuestionMark data-tip data-for={'sector-label'} />
              <ReactTooltip id={'sector-label'} place={'top'} effect={'solid'}>
                <FormattedMessage id={'sector-help-text'} />
              </ReactTooltip>
            </div>
            {sectors.map((sector) => (
              <div key={sector.id} className={styles.option}>
                <Checkbox
                  checked={selectedSectors.includes(sector.id)}
                  onClick={() => {
                    if (selectedSectors.includes(sector.id)) {
                      setSelectedSectors(
                        selectedSectors.filter((x) => x !== sector.id),
                      );
                    } else {
                      setSelectedSectors([...selectedSectors, sector.id]);
                    }
                  }}
                />
                <span className={classNames(styles.checkboxLabel)}>
                  {sector.name}
                </span>
              </div>
            ))}
          </div>
        </>
      </Card>
    </div>
  );
}

export default SectorVisibility;
