import {
  CSSProperties,
  MouseEvent,
  PropsWithoutRef,
  useRef,
  useState,
} from 'react';
import cn from 'classnames';

import Checkbox from '../../../../../../components/common/Checkbox/Checkbox';
import { ReactComponent as ArrowDown } from '../../../../../../icons/arrow-down.svg';
import styles from './MultiProductSelect.module.scss';

interface MultiProductSelectProps<T> {
  placeholder?: string;
  value: any[];
  onChange?: (value: T[]) => void;
  className?: string;
  options: {
    name: string;
    value: T;
  }[];
  selectedItemName?: string;
  selectedItemNameSingular?: string;
  inputClassName?: string;
  selectClassName?: string;
  wrapperClass?: string;
}

function MultiProductSelect<T>({
  placeholder = 'Select',
  value,
  onChange,
  className,
  options,
  selectedItemName = 'Products',
  selectedItemNameSingular = 'Product',
  inputClassName,
  selectClassName,
  wrapperClass,
}: PropsWithoutRef<MultiProductSelectProps<T>>) {
  const [opened, setOpened] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filter, setFilter] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([...options]);
  const selectContainerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const optionStyles: CSSProperties = {};

  const generatePosition = (): boolean => {
    if (selectContainerRef.current) {
      const domRect = selectContainerRef.current.getBoundingClientRect();
      const maxHeight = domRect.height * 6;
      const bottomRemains = window.innerHeight > domRect.bottom + maxHeight;

      optionStyles.maxHeight = maxHeight + 'px';

      if (bottomRemains) {
        optionStyles.left = (domRect.left > 0 ? domRect.left : 0) + 'px';
        optionStyles.top = domRect.bottom + 'px';
      } else {
        optionStyles.left = (domRect.left > 0 ? domRect.left : 0) + 'px';
        optionStyles.bottom = window.innerHeight - domRect.top + 'px';
      }
    }

    return true;
  };

  const closeOptions = () => {
    setOpened(false);
    setFilter('');
  };

  const openOptions = () => {
    inputRef.current?.focus();
    onSearchChange('');
    setOpened(true);
  };

  const onOptionClick = (changed: T, e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (value.includes(changed)) {
      onChange && onChange(value.filter((x) => x !== changed));
    } else {
      onChange && onChange([...value, changed]);
    }
  };

  const onSearchChange = (x: string) => {
    setFilter(x);

    const filtered = options.filter(
      (item) => item.name.toLowerCase().indexOf(x.toLowerCase()) !== -1,
    );

    setFilteredOptions(filtered);
  };

  return (
    <div className={cn(styles.wrapper, wrapperClass)} ref={selectContainerRef}>
      <div
        onClick={() => {
          openOptions();
        }}
        className={cn(styles.selectContainer, className)}
      >
        {opened && (
          <div
            onClick={(e) => {
              e.stopPropagation();
              closeOptions();
            }}
            className={styles.clickDetector}
          />
        )}
        <input
          ref={inputRef}
          onInput={(event) =>
            onSearchChange((event.target as HTMLInputElement).value)
          }
          value={
            value.length === 0
              ? placeholder
              : value.length === 1
              ? `${value.length} ${selectedItemNameSingular}`
              : `${value.length} ${selectedItemName}`
          }
          placeholder={placeholder}
          type={'text'}
          className={cn(styles.input, inputClassName)}
        />
        {opened && generatePosition() && (
          <div
            style={optionStyles}
            className={cn(styles.optionsContainer, selectClassName)}
          >
            {filteredOptions.map((item: any, index) => {
              const selected =
                value.includes(item.value) ||
                (!value.length && item.value === -1);

              return (
                <div
                  key={index}
                  className={styles.option}
                  onClick={(e) => onOptionClick(item.value, e)}
                >
                  <Checkbox checked={selected} />
                  <span className={styles.label}>{item.name}</span>
                </div>
              );
            })}
          </div>
        )}
        <ArrowDown
          className={cn(styles.icon, {
            [styles.opened]: opened,
          })}
        />
      </div>
    </div>
  );
}

export default MultiProductSelect;
