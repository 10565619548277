/* eslint-disable no-prototype-builtins */
import config from '../configs/config';
import { loginAsAnonymous } from '../services/AuthService';
import {
  getAccessTokenFromLocalStorage,
  setAccessTokenToLocalStorage,
} from '../services/LocalStorageService';
import { msalInstance } from './auth.utils';

/**
 * axios response sleeper
 * @param ms milliseconds to sleep
 */
export function sleeper<T>(ms: number): (x: T) => Promise<T> {
  return function (res: T) {
    return new Promise((resolve) => setTimeout(() => resolve(res), ms));
  };
}

export const getAccessToken = async (culture?: string, sector?: string) => {
  const accounts = msalInstance.getAllAccounts();
  if (accounts && accounts.length > 0) {
    const response = await msalInstance
      .acquireTokenSilent({
        ...config.LOGIN_REQUEST,
        account: accounts[0],
        forceRefresh: false,
      })
      .catch(async () => {
        const login = await loginAsAnonymous(culture, sector);
        setAccessTokenToLocalStorage(login.accessToken);
        return {
          accessToken: login.accessToken,
        };
      });
    return response.accessToken;
  } else {
    const accessToken = getAccessTokenFromLocalStorage();
    if (!accessToken) {
      const login = await loginAsAnonymous(culture, sector);
      setAccessTokenToLocalStorage(login.accessToken);
      return login.accessToken;
    }
    return accessToken;
  }
};

export const generateQueryString = (obj: any) => {
  const strPart = [];
  for (const p in obj)
    if (obj.hasOwnProperty(p) && obj[p] && Array.isArray(obj[p])) {
      [...obj[p]].forEach((x) => {
        strPart.push(encodeURIComponent(p) + '=' + encodeURIComponent(x));
      });
    } else if (
      obj.hasOwnProperty(p) &&
      ((typeof obj[p] === 'number' && obj[p] !== null) || !!obj[p])
    ) {
      strPart.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
  return strPart.join('&');
};
