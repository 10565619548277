import { useIntl } from 'react-intl';

import CheckboxList from '../../CheckboxList/CheckboxList';
import FormWrapper from '../../FormWrapper/FormWrapper';

import {
  PortAddFormProps,
  PortDetailsFormType,
} from '../../../../models/admin-form-types';

import '../../shared.scss';

const PortOrderQuantitiesForm = ({
  formValue,
  handleValueChange,
}: PortAddFormProps) => {
  const intl = useIntl();

  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });
  const title = getTranslatedMessage('port-order-quantities-label');
  const infoMessage = getTranslatedMessage('select-one-from-below');
  const bulkItemIds = [
    {
      fieldKey: 'minBulkTruckOrderInLitres',
      label: getTranslatedMessage('port-availability-truck-label'),
    },
    {
      fieldKey: 'minBulkBargeOrderInLitres',
      label: getTranslatedMessage('port-availability-barge-label'),
    },
    {
      fieldKey: 'minBulkIbcOrderInLitres',
      label: getTranslatedMessage('port-availability-ibc-label'),
    },
  ];
  const bulkItems = bulkItemIds.map((bulkItem) => ({
    fieldKey: bulkItem.fieldKey,
    value: formValue[bulkItem.fieldKey as keyof PortDetailsFormType],
    label: bulkItem.label,
    checked: formValue[bulkItem.fieldKey as keyof PortDetailsFormType] !== null,
  }));
  const packItemIds = [
    {
      fieldKey: 'minPackOrderInDrums',
      label: getTranslatedMessage('min-no-of-drums'),
    },
    {
      fieldKey: 'minPackOrderInDrumsPumped',
      label: getTranslatedMessage('min-no-of-drums-pumped'),
    },
  ];
  const packItems = packItemIds.map((packItem) => ({
    fieldKey: packItem.fieldKey,
    value: formValue[packItem.fieldKey as keyof PortDetailsFormType],
    label: packItem.label,
    checked: formValue[packItem.fieldKey as keyof PortDetailsFormType] !== null,
  }));

  const handlePortOrderQuantitiesChange = (fieldKey: string) => {
    let val = formValue[fieldKey as keyof PortDetailsFormType];
    if (val === null) {
      val = '';
    } else {
      val = null;
    }

    return val;
  };

  const handleInputValueChange = (fieldKey: string, value: string | number) => {
    let val = value;
    if (val < 0) {
      val = 0;
    }
    handleValueChange(fieldKey, val.toString());
  };

  return (
    <FormWrapper title={title} infoMessage={infoMessage}>
      <div className="columnRow">
        <CheckboxList
          labelId="port-availability-bulk-label"
          required={false}
          items={bulkItems}
          onClick={(fieldKey: string) => {
            const val = handlePortOrderQuantitiesChange(fieldKey);
            handleValueChange(fieldKey, val);
          }}
          showInputField={true}
          suffix={getTranslatedMessage('litres-label')}
          handleValueChange={handleInputValueChange}
          tooltip={getTranslatedMessage('bulk-pack-tooltip')}
        />
        <CheckboxList
          labelId="pack-label"
          required={false}
          items={packItems}
          onClick={(fieldKey) => {
            const val = handlePortOrderQuantitiesChange(fieldKey);
            handleValueChange(fieldKey, val);
          }}
          showInputField={true}
          handleValueChange={handleInputValueChange}
          tooltip={getTranslatedMessage('bulk-pack-tooltip')}
        />
      </div>
    </FormWrapper>
  );
};

export default PortOrderQuantitiesForm;
