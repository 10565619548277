import cn from 'classnames';
import { PropsWithoutRef, ReactElement } from 'react';

import styles from './Card.module.scss';

interface CardProps {
  children: ReactElement<any, any>;
  className?: string;
}

/**
 * Card
 * @param children children
 */
function Card({ children, className = '' }: PropsWithoutRef<CardProps>) {
  return (
    <div className={styles.backdrop}>
      <div className={cn(styles.mainCard, className)}>{children}</div>
    </div>
  );
}

export default Card;
