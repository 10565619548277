import styles from './Button.module.scss';
import { PropsWithChildren } from 'react';
import cn from 'classnames';

interface ButtonProps {
  color:
    | 'green'
    | 'blue'
    | 'ruby'
    | 'red'
    | 'orange'
    | 'transparent'
    | 'green-outline'
    | 'nobel'
    | 'gray'
    | 'red';
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
}

/**
 * button
 * @param color button color
 * @param children children
 * @param className classname to add
 * @param disabled disabled state
 * @param onClick click handler
 */
function Button({
  color,
  children,
  className = '',
  disabled = false,
  onClick,
}: PropsWithChildren<ButtonProps>) {
  return (
    <button
      onClick={() => onClick && onClick()}
      disabled={disabled}
      className={cn(styles.button, className, styles[color])}
    >
      {children}
    </button>
  );
}

export default Button;
