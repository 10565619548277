import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type PageType = 'Product' | 'Range' | 'Family' | 'Other';

export interface ContactUsSliceState {
  showContactUsModal: boolean;
  page?: PageType;
  id?: number;
}

const initialState: ContactUsSliceState = {
  showContactUsModal: false,
};

const contactUsSlice = createSlice({
  name: 'contact-us',
  initialState,
  reducers: {
    openContactUsModal: (
      state,
      action: PayloadAction<{
        page?: PageType;
        id?: number;
      }>,
    ): ContactUsSliceState => ({
      ...state,
      showContactUsModal: true,
      page: action.payload.page,
      id: action.payload.id,
    }),
    closeContactUsModal: (): ContactUsSliceState => ({
      showContactUsModal: false,
      page: undefined,
      id: undefined,
    }),
  },
});

export const { openContactUsModal, closeContactUsModal } =
  contactUsSlice.actions;

export default contactUsSlice.reducer;
