import { useMemo, useState } from 'react';
import styles from './ReportsAndQuickStatsPage.module.scss';
import ContainerForDrawer from '../../../../components/common/ContainerForDrawer/ContainerForDrawer';
import LoadingSpinner from '../../../../components/common/LoadingSpinner/LoadingSpinner';
import NavigationHeader from '../../../../components/common/NavigationHeader/NavigationHeader';
import { PreviousLink } from '../../../../models/previous-link';
import { FormattedMessage, useIntl } from 'react-intl';
import QuickStatsForm from '../components/QuickStatsForm/QuickStatsForm';
import { RootStore } from '../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { CountryItem } from '../../../../models/lookups';
import Button from '../../../../components/common/Button/Button';
import classNames from 'classnames';
import { ReactComponent as CancelIcon } from '../../../../icons/cross-circle.svg';
import { getUserStats } from '../../../../services/StatsService';
import QuickStatsResult from '../components/QuickStatsResult/QuickStatsResult';
import MonthYearField from '../../../../components/Admin/MonthYearField/MonthYearField';
import FormRow from '../../../../components/Admin/FormRow/FormRow';
import moment from 'moment';
import MultiProductSelect from '../../Products/AddCountryLevelProductPage/components/MultiProductSelect/MultiProductSelect';
import { addToast } from '../../../../features/toastSlice';

/**
 * Reports and Quick Stats page component
 */
function ReportsAndQuickStatsPage() {
  const [loading, setLoading] = useState(false);
  const [statsData, setStatsData] = useState<any>(null);
  const intl = useIntl();
  const { countries, languages } = useSelector(
    (state: RootStore) => state.lookups,
  );
  const dispatch = useDispatch();
  const languageOptions = useMemo(() => {
    return languages.map((item) => ({
      name: item.name,
      value: item.id,
    }));
  }, [languages]);

  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });
  const initialState = {
    month: '',
    year: '',
    countryIds: [],
    languageIds: [],
  };

  const [formValue, setFormValue] = useState(initialState);
  const [monthYear, setMonthYear] = useState('');

  const breadcrumbLinks: PreviousLink[] = [
    {
      name: 'Report',
      to: '/admin/reports',
    },
    {
      name: 'Reports and Quick Stats',
    },
  ];

  const handleValueChange = (
    fieldKey: string,
    value: string | boolean | number[],
  ) => {
    setFormValue({
      ...formValue,
      [fieldKey]: value,
    });
  };

  const isFilled = formValue.month && formValue.year;

  const clearFilter = () => {
    setFormValue(initialState);
    setStatsData(null);
  };

  const onFilter = () => {
    setLoading(true);
    getUserStats(
      formValue.month,
      formValue.year,
      formValue.countryIds,
      formValue.languageIds,
    )
      .then((res: any) => {
        setMonthYear(
          `${moment.months(Number(formValue.month) - 1)} ${formValue.year}`,
        );
        setStatsData(res);
      })
      .catch((err) => {
        dispatch(
          addToast({
            type: 'error',
            title: intl.formatMessage({ id: 'report-quick-stats-failure' }),
            message: err?.message || '',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const countryItems = [
    {
      name: getTranslatedMessage('reports-page-select-all'),
      value: -1,
    },
    ...countries.map((country: CountryItem) => ({
      name: country.name,
      value: country.id,
    })),
  ] as any;

  const languageItems = [
    {
      name: getTranslatedMessage('reports-page-select-all'),
      value: -1,
    },
    ...languageOptions.map((option: any) => {
      return {
        name: option.name,
        value: option.value,
      };
    }),
  ];

  return (
    <>
      <div className={classNames(loading ? styles.loadingOverlayDim : null)}>
        <NavigationHeader breadcrumbLinks={breadcrumbLinks} />
        <ContainerForDrawer>
          <div className={styles.container}>
            <div className={classNames(styles.title)}>
              <FormattedMessage id={'reports-page-title'} />
            </div>
            <div className={styles.contentContainer}>
              <div className={classNames(styles.reportDetailContainer)}>
                <div className={styles.quickStatsContainer}>
                  <QuickStatsForm
                    title={getTranslatedMessage('reports-page-quick-stats')}
                    secondaryTitle={getTranslatedMessage(
                      'reports-page-quick-stats-description',
                    )}
                  >
                    <div className={styles.formFieldsContainer}>
                      <MonthYearField
                        labelId={'reports-page-date-field'}
                        monthValue={formValue.month}
                        yearValue={formValue.year}
                        required={false}
                        className={styles.calendarRow}
                        onChangeMonth={(value: string) =>
                          handleValueChange('month', value)
                        }
                        onChangeYear={(value: string) =>
                          handleValueChange('year', value)
                        }
                      />
                      <FormRow
                        labelId={'reports-page-country-field'}
                        required={false}
                        className={styles.multipleSelect}
                      >
                        <MultiProductSelect
                          value={formValue.countryIds}
                          onChange={(value: number[]) => {
                            if (value.includes(-1)) {
                              handleValueChange('countryIds', []);
                            } else {
                              handleValueChange('countryIds', value);
                            }
                          }}
                          placeholder={getTranslatedMessage(
                            'reports-page-select-all',
                          )}
                          options={countryItems}
                          className={styles.multipleSelect}
                          inputClassName={styles.multipleInput}
                          selectClassName={styles.selectOptions}
                          selectedItemName={getTranslatedMessage(
                            'reports-field-country-select',
                          )}
                          selectedItemNameSingular={getTranslatedMessage(
                            'reports-field-country-select-singular',
                          )}
                        />
                      </FormRow>
                      <FormRow
                        labelId={'reports-page-language-field'}
                        required={false}
                        className={styles.multipleSelect}
                      >
                        <MultiProductSelect
                          value={formValue.languageIds}
                          onChange={(value: number[]) => {
                            if (value.includes(-1)) {
                              handleValueChange('languageIds', []);
                            } else {
                              handleValueChange('languageIds', value);
                            }
                          }}
                          placeholder={getTranslatedMessage(
                            'reports-page-select-all',
                          )}
                          options={languageItems}
                          className={styles.multipleSelect}
                          inputClassName={styles.multipleInput}
                          selectClassName={styles.selectOptions}
                          selectedItemName={getTranslatedMessage(
                            'reports-field-language-select',
                          )}
                          selectedItemNameSingular={getTranslatedMessage(
                            'reports-field-language-select-singular',
                          )}
                        />
                      </FormRow>
                    </div>
                    <div className={styles.filterContainer}>
                      <Button
                        color={'green'}
                        className={classNames('rowButton', styles.filterButton)}
                        disabled={!isFilled}
                        onClick={onFilter}
                      >
                        {getTranslatedMessage('reports-page-filter-button')}
                      </Button>
                      <div className={styles.clearFilter} onClick={clearFilter}>
                        <CancelIcon className={styles.cancelIcon} />
                        <div>
                          {getTranslatedMessage('reports-page-clear-filter')}
                        </div>
                      </div>
                    </div>
                  </QuickStatsForm>
                </div>
              </div>

              {statsData && (
                <QuickStatsResult statsData={statsData} date={monthYear} />
              )}
            </div>
          </div>
        </ContainerForDrawer>
      </div>
      {loading && (
        <div className={styles.loadingOverlay}>
          <LoadingSpinner />
        </div>
      )}
    </>
  );
}

export default ReportsAndQuickStatsPage;
