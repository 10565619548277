import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PortDetails } from '../models/port-details';

export interface PortDetailsState {
  stockPointId?: number;
  port?: PortDetails;
}

export const PortDetailsSlice = createSlice({
  name: 'port-details',
  initialState: {
    stockPointId: undefined,
    port: undefined,
  } as PortDetailsState,
  reducers: {
    // set stock point id
    setStockPointId: (
      state: PortDetailsState,
      action: PayloadAction<number | undefined>,
    ): PortDetailsState => {
      return {
        ...state,
        stockPointId: action.payload,
      };
    },
    setPort: (
      state: PortDetailsState,
      action: PayloadAction<PortDetails | undefined>,
    ): PortDetailsState => {
      return {
        ...state,
        port: action.payload,
      };
    },
  },
});

export const { setStockPointId, setPort } = PortDetailsSlice.actions;

export default PortDetailsSlice.reducer;
