import { saveAs } from 'file-saver';

import { apiInstance } from '../api/requestInterceptor';

async function downloadExistingCountryLevelProducts() {
  const res = await apiInstance.get('/products/bulkCountryLevel/download', {
    responseType: 'blob',
  });

  if (res.status === 200) {
    const filename =
      res.headers['content-disposition']?.split('filename=')[1] ||
      'existing-country-level-products.xlsx';
    saveAs(
      res.data,
      decodeURIComponent(filename.replace('"', '').replace('"', '')),
    );
  }

  return res;
}

async function downloadCountryLevelProductsTemplate() {
  const res = await apiInstance.get(
    '/products/bulkCountryLevel/download?onlyTemplate=true',
    {
      responseType: 'blob',
    },
  );

  if (res.status === 200) {
    const filename =
      res.headers['content-disposition']?.split('filename=')[1] ||
      'country-level-products-template.xlsx';
    saveAs(res.data, filename.replace('"', '').replace('"', ''));
  }

  return res;
}

async function saveCountryLevelProducts(data: any): Promise<any> {
  const res = await apiInstance.post<any>(
    '/products/bulkCountryLevel/save',
    data,
  );

  return res.data;
}

export {
  downloadExistingCountryLevelProducts,
  downloadCountryLevelProductsTemplate,
  saveCountryLevelProducts,
};
