import { FormattedMessage } from 'react-intl';
import styles from './ProgressBar.module.scss';

type ProgressBarType = {
  progress: number;
};

const ProgressBar = ({ progress }: ProgressBarType) => (
  <div className={styles.container}>
    <div className={styles.progressIndicatorWrapper}>
      <div
        className={styles.progressIndicator}
        style={{ width: `${progress}%` }}
      ></div>
    </div>
    <span className={styles.progressText}>
      {progress}% <FormattedMessage id={'complete-label'} />
    </span>
  </div>
);

export default ProgressBar;
