import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { PropsWithoutRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { CSVLink } from 'react-csv';
import Button from '../../Button/Button';
import styles from './ComparisonModalHeader.module.scss';
import { ReactComponent as Download } from '../../../../icons/download.svg';
import { ReactComponent as Print } from '../../../../icons/print.svg';
import { ProductComparisonDetail } from '../../../../models/product-comparison-detail';
import { productComparisonFields } from '../productComparisonFields';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../../store';

interface ComparisonModalHeaderProps {
  products: ProductComparisonDetail[];
  downloadTableImage: () => void;
}

function ComparisonModalHeader({
  products,
  downloadTableImage,
}: PropsWithoutRef<ComparisonModalHeaderProps>) {
  const intl = useIntl();
  const { sector } = useSelector(
    (rootState: RootStore) => rootState.welcomeInfo,
  );

  const csvData = useMemo(
    () => [
      [
        intl.formatMessage({ id: 'product-name-label' }),
        ...products.map((item) => item.name),
      ],
      ...productComparisonFields
        .filter((productComparisonField) =>
          products.some((prod) =>
            productComparisonField.isVisibleFunction(prod, sector),
          ),
        )
        .map((productComparisonField) => [
          intl.formatMessage({ id: productComparisonField.formattedMessageId }),
          ...products.map((item) => productComparisonField.value(item)),
        ]),
    ],
    [products],
  );

  return (
    <div className={styles.container}>
      <h2>
        <FormattedMessage id={'product-comparison-label'} />
      </h2>

      <div className={styles.rightGroup}>
        <CSVLink
          data={csvData}
          filename={'product-comparison.csv'}
          className={styles.button}
        >
          <Button color={'transparent'} className={styles.button}>
            <Download className={styles.icon} />

            <FormattedMessage id={'download-label'} />
          </Button>
        </CSVLink>

        <Button
          color={'transparent'}
          className={styles.button}
          onClick={downloadTableImage}
        >
          <Print className={styles.icon} />

          <FormattedMessage id={'print-label'} />
        </Button>
      </div>
    </div>
  );
}

export default ComparisonModalHeader;
