import { memo, PropsWithoutRef, useMemo, useState } from 'react';
import cn from 'classnames';

import ProductValidationCardField from './ProductValidationCardField';
import { ProductValidationColumn } from '../../../models/table-column';
import { ReactComponent as ArrowDown } from '../../../icons/arrow-down.svg';
import { ReactComponent as Error } from '../../../icons/error.svg';
import ProductValidationHiddenField from './ProductValidationHiddenField';
import styles from './ProductValidationTable.module.scss';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { SheetRowError } from '../../../models/sheet-validation';

interface ProductValidationTableRowProps {
  row: any;
  setDataAtIndex: (x: any) => void;
  columns: ProductValidationColumn[];
  rowIndex: number;
  rowClassName?: string;
  rowError?: SheetRowError;
}

function ProductValidationTableRow({
  row,
  setDataAtIndex,
  columns,
  rowIndex,
  rowClassName,
  rowError,
}: PropsWithoutRef<ProductValidationTableRowProps>) {
  const [expanded, setExpanded] = useState(false);

  const errors = useMemo(() => {
    if (rowError) {
      return Object.keys(rowError).map((key) => {
        return `${key}: ${rowError[key]?.errorName}`;
      });
    }

    return [];
  }, [rowError]);

  return (
    <>
      <div className={classNames(styles.tableRow, rowClassName)}>
        {columns
          .filter((column) => !column.hidden)
          .map((column, i) => (
            <ProductValidationCardField
              key={i}
              product={row}
              setDataAtIndex={setDataAtIndex}
              column={column}
              rowIndex={rowIndex}
            />
          ))}
        <div className={cn(styles.tableColumn, styles.tableErrorColumn)}>
          {row.hasError && (
            <div className={styles.error} data-tip data-for={`${rowIndex}`}>
              <Error className={styles.errorIcon} />
              Error
            </div>
          )}
          {row.hasError && (
            <ReactTooltip id={`${rowIndex}`} place="top" effect="solid">
              {errors.map((err, ind) => (
                <div key={ind}>{err}</div>
              ))}
            </ReactTooltip>
          )}
        </div>
        <div
          className={cn(styles.tableColumn, styles.tableDropdownColumn)}
          onClick={() => setExpanded(!expanded)}
        >
          <ArrowDown
            className={cn(styles.arrowDown, {
              [styles.rotate180]: expanded,
            })}
          />
        </div>
      </div>
      {expanded &&
        columns.map((column) => (
          <ProductValidationHiddenField
            key={column.formattedMessageId}
            column={column}
            row={row}
            setDataAtIndex={setDataAtIndex}
            rowIndex={rowIndex}
          />
        ))}
    </>
  );
}

export default memo(ProductValidationTableRow);
