import {
  PropsWithoutRef,
  useCallback,
  useState,
  useEffect,
  ReactElement,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDropzone } from 'react-dropzone';

import { ReactComponent as Add } from '../../../../icons/add.svg';
import { ReactComponent as CrossCircle } from '../../../../icons/cross-circle-ruby.svg';
import Button from '../../../common/Button/Button';
import Checkbox from '../../../common/Checkbox/Checkbox';
import styles from './UploadUsersData.module.scss';

interface UploadUsersDataProps {
  setFile: (f: any) => void;
  submit: () => void;
  emailUserChange?: (value: boolean) => void;
  prefixInfo?: ReactElement<any, any>;
}

function UploadUsersData({
  setFile,
  submit,
  emailUserChange,
  prefixInfo,
}: PropsWithoutRef<UploadUsersDataProps>) {
  const intl = useIntl();
  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });

  const [emailUserFlag, setEmailUserFlag] = useState(false);
  const [hasFiles, setHasFiles] = useState(false);

  const onDrop = useCallback(
    (files) => {
      if (setFile) setFile(files[0]);
    },
    [setFile],
  );

  // Hooks used of react-dropzone to get a modular file uploading component
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop,
  });

  const descriptionValues = {
    span: (value: string[]) => <span className={styles.boldNote}>{value}</span>,
  };

  useEffect(() => {
    setHasFiles(Boolean(acceptedFiles.length));
  }, [acceptedFiles.length]);

  return (
    <div className={styles.container}>
      {!!prefixInfo && (
        <div className={styles.prefixInfo}>
          {prefixInfo}
          <div className={styles.preDivider} />
        </div>
      )}
      <h2>
        <FormattedMessage id={'upload-data'} />
      </h2>
      <div className={styles.divider} />
      <div className={styles.twoColumnGrid}>
        <div>
          <div className={styles.labelContainer}>
            <div className={styles.label}>
              {getTranslatedMessage('select-file-upload-label')}
            </div>
          </div>
          <div className={styles.inputWrapper} {...getRootProps()}>
            {!hasFiles ? (
              <Add className={styles.add} />
            ) : (
              <CrossCircle
                className={styles.remove}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  acceptedFiles && acceptedFiles.splice(0, 1);
                  setHasFiles(false);
                }}
              />
            )}
            <div className={styles.inputText}>
              {hasFiles ? (
                acceptedFiles[0].name
              ) : (
                <>
                  - <FormattedMessage id={'select'} /> -
                </>
              )}
              <input
                type={'file'}
                name={'files[]'}
                id={'upload-file'}
                {...getInputProps()}
                accept={
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                }
              />
            </div>
          </div>
        </div>
        <Button
          disabled={!hasFiles}
          color={'green'}
          className={styles.continueButton}
          onClick={submit}
        >
          <FormattedMessage id={'upload'} />
        </Button>
      </div>

      <div className={styles.emailUser}>
        <Checkbox
          checked={emailUserFlag}
          onClick={() => {
            setEmailUserFlag(!emailUserFlag);
            emailUserChange && emailUserChange(!emailUserFlag);
          }}
        />
        <span className={styles.emailUserDesc}>
          <FormattedMessage id={'email-users-label'} />
        </span>
      </div>
      <div className={styles.note}>
        <FormattedMessage
          id={'max-users-note-label'}
          values={descriptionValues}
        />
      </div>
    </div>
  );
}

export default UploadUsersData;
