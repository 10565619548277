import classNames from 'classnames';
import React, { PropsWithoutRef } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import styles from './ConfirmDialog.module.scss';

interface ConfirmDialogProps {
  onClose: () => void;
  onConfirm: () => void;
  message: string;
}

/**
 * comfirm dialog
 * @param onClose close handler
 * @param onConfirm confirm handler
 * @param message message
 */
function ConfirmDialog({
  onClose,
  onConfirm,
  message,
}: PropsWithoutRef<ConfirmDialogProps>) {
  return (
    <Modal onClose={onClose} hideClose={true} className={styles.modalCustom}>
      <div className={styles.container}>
        <div className={styles.header}>Confirm</div>
        <div className={styles.message}>{message}</div>
        <div className={styles.buttonContainer}>
          <Button
            onClick={() => onConfirm()}
            color={'green'}
            className={classNames(styles.button, styles.submitButton)}
          >
            <FormattedMessage id={'confirm-label'} />
          </Button>

          <Button
            onClick={() => onClose()}
            color={'transparent'}
            className={classNames(styles.button, styles.cancelButton)}
          >
            <FormattedMessage id={'close-label'} />
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default ConfirmDialog;
