import { PropsWithChildren, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { LookupItem } from '../../../models/lookups';
import { SelectOption } from '../../../models/select-option';
import OutsideClickDetector from '../OutsideClickDetector/OutsideClickDetector';
import Select from '../Select/Select';

import styles from './ValidationDataCellSelect.module.scss';

export function useCellSelect(lookupItems: LookupItem[]) {
  const selectOptions = useMemo(() => {
    return lookupItems.map((item) => ({
      name: item.name,
      value: item.id,
    }));
  }, [lookupItems]);

  return {
    selectOptions,
  };
}

export interface ValidationDataCellSelectProps<T> {
  isEdit?: boolean;
  dataNotAvailable?: boolean;
  value: T;
  options: SelectOption<T>[];
  getLabelFn?: (value: T) => string;
  onValueChange: (newVal: T) => void;
  onDone?: () => void;
  smallHorizontalPadding?: boolean;
}

function ValidationDataCellSelect<T>({
  isEdit = false,
  dataNotAvailable = false,
  value,
  options,
  getLabelFn,
  onValueChange,
  onDone,
  smallHorizontalPadding,
}: PropsWithChildren<ValidationDataCellSelectProps<T>>) {
  const intl = useIntl();

  return (
    <div className={styles.container}>
      {isEdit ? (
        <OutsideClickDetector
          handleOutsideClick={() => {
            if (onDone) {
              onDone();
            }
          }}
        >
          <Select
            className={styles.selectDropdown}
            value={value}
            options={options}
            onChange={(val) => onValueChange(val)}
            placeholder={intl.formatMessage({
              id: 'select-placeholder',
            })}
            onOpenChange={(open) => {
              if (!open && onDone) {
                onDone();
              }
            }}
            smallHorizontalPadding={smallHorizontalPadding}
            fixedWidth={false}
          />
        </OutsideClickDetector>
      ) : dataNotAvailable ? (
        <FormattedMessage id="validation-data-not-available" />
      ) : getLabelFn ? (
        getLabelFn(value)
      ) : (
        options.find((opt) => opt.value === value)?.name || ''
      )}
    </div>
  );
}

export default ValidationDataCellSelect;
