/* istanbul ignore file */
import styles from './AboutProduct.module.scss';
import { useSelector } from 'react-redux';
import AboutDetail from '../../common/AboutDetail/AboutDetail';
import AboutDetailItem from '../../common/AboutDetail/AboutDetailItem/AboutDetailItem';
import { ReactComponent as DocumentGreen } from '../../../icons/document-green.svg';
import { ReactComponent as ShareGreen } from '../../../icons/share-green.svg';
import { FormattedMessage, useIntl } from 'react-intl';
import AboutDetailDivider from '../../common/AboutDetail/AboutDetailDivider/AboutDetailDivider';
import { SearchResultProduct } from '../../../models/search-result-product';
import { RootStore } from '../../../store';
import { getLinkUrl } from '../../../utils/product.util';

interface AboutProductProps {
  product: SearchResultProduct;
  onShareProduct?: () => void;
}

/**
 * about product
 */
function AboutProduct({ product, onShareProduct }: AboutProductProps) {
  // init hooks
  const intl = useIntl();

  const {
    welcomeInfo: { industrySector, country, language },
  } = useSelector((state: RootStore) => state.welcomeInfo);

  return (
    <AboutDetail
      className={styles.container}
      title={intl.formatMessage({ id: 'about-this-product-label' })}
    >
      <AboutDetailItem
        className={styles.item}
        icon={<DocumentGreen />}
        onClick={() =>
          window.open(
            getLinkUrl('PDS', country, product.name, industrySector, language),
          )
        }
      >
        <FormattedMessage id={'product-data-sheet-label'} />
      </AboutDetailItem>

      <AboutDetailItem
        className={styles.item}
        icon={<DocumentGreen />}
        onClick={() =>
          window.open(
            getLinkUrl('MSDS', country, product.name, industrySector, language),
          )
        }
      >
        <FormattedMessage id={'safety-data-sheet-label'} />
      </AboutDetailItem>

      <AboutDetailDivider />

      <AboutDetailItem
        className={styles.item}
        icon={<ShareGreen />}
        onClick={() => onShareProduct && onShareProduct()}
      >
        <FormattedMessage id={'share-product-label'} />
      </AboutDetailItem>
    </AboutDetail>
  );
}

export default AboutProduct;
