import styles from './SearchResultHeader.module.scss';
import { FormattedMessage } from 'react-intl';
import { PropsWithoutRef } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

interface SearchResultHeaderProps {
  count: number;
  search?: string | undefined;
}

/**
 * search result header
 * @param count searched count
 */
function SearchResultHeader({
  count,
  search,
}: PropsWithoutRef<SearchResultHeaderProps>) {
  const location = useLocation();
  const [query] = useSearchParams();
  const descriptionValues = {
    count,
    search: query.get('search') || search || '',
    b: (value: string[]) => <b>{value}</b>,
  };

  const from = query.get('from');
  const isMostSearched = from === 'most-searched-result';
  const withoutSearchText = isMostSearched || from === 'browse';

  /* istanbul ignore next */
  const getSearchDescription = () => {
    if (withoutSearchText) {
      return (
        <FormattedMessage
          id={'search-result-header-most-searched-description'}
          values={descriptionValues}
        />
      );
    } else if (location.pathname.includes('oem')) {
      return (
        <FormattedMessage
          id={'search-result-oem-header-description'}
          values={descriptionValues}
        />
      );
    } else if (location.pathname.includes('port')) {
      if (query.get('portName')) {
        return (
          <FormattedMessage
            id={'search-result-port-header-description'}
            values={descriptionValues}
          />
        );
      } else if (query.get('countryName')) {
        return (
          <FormattedMessage
            id={'search-result-countries-header-description'}
            values={descriptionValues}
          />
        );
      }
    } else {
      if (query.get('type') && query.get('type') === 'family') {
        return (
          <FormattedMessage
            id={'search-result-families-header-description'}
            values={descriptionValues}
          />
        );
      } else if (query.get('type') && query.get('type') === 'range') {
        return (
          <FormattedMessage
            id={'search-result-ranges-header-description'}
            values={descriptionValues}
          />
        );
      } else if (query.get('type') && query.get('type') === 'category') {
        return (
          <FormattedMessage
            id={'search-result-categories-header-description'}
            values={descriptionValues}
          />
        );
      } else if (query.get('type') && query.get('type') === 'application') {
        return (
          <FormattedMessage
            id={'search-result-applications-header-description'}
            values={descriptionValues}
          />
        );
      } else {
        return (
          <FormattedMessage
            id={'search-result-header-description'}
            values={descriptionValues}
          />
        );
      }
    }
  };

  return (
    <div className={styles.container}>
      <h1>
        {isMostSearched ? (
          <FormattedMessage id={'most-searched-products-label'} />
        ) : (
          <FormattedMessage id={'search-results-label'} />
        )}
      </h1>

      <div className={styles.searchDescription}>{getSearchDescription()}</div>
    </div>
  );
}

export default SearchResultHeader;
