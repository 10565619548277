import { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { RootStore } from '../store';

export default function withAdmin(Component: any, onlySuperAdmin = false) {
  const AdminComponent = (props: any) => {
    const navigate = useNavigate();
    const loginSlice = useSelector((state: RootStore) => state.login);

    useLayoutEffect(() => {
      if (loginSlice && !loginSlice.isAdmin) {
        navigate(`/`);
      }
      if (
        onlySuperAdmin &&
        loginSlice &&
        loginSlice.user?.role !== 'Super Admin'
      ) {
        navigate(`/`);
      }
    }, [loginSlice]);

    return <Component {...props} />;
  };

  return AdminComponent;
}
