import { FormattedMessage } from 'react-intl';

import Breadcrumbs from '../../common/NavigationHeader/Breadcrumbs/Breadcrumbs';

import { PreviousLink } from '../../../models/previous-link';

import styles from './Header.module.scss';

type HeaderType = {
  titleId: string;
  breadcrumbLinks: PreviousLink[];
  showUnsavedChangesModal?: boolean;
};

const Header = ({
  titleId,
  breadcrumbLinks,
  showUnsavedChangesModal,
}: HeaderType) => (
  <div className={styles.header}>
    <Breadcrumbs
      links={breadcrumbLinks}
      showUnsavedChangesModal={showUnsavedChangesModal}
    />
    <h3>
      <FormattedMessage id={titleId} />
    </h3>
  </div>
);

export default Header;
