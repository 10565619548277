import ProductListTable, {
  sortChangeHandler,
} from '../../ProductListTable/ProductListTable';
import { PropsWithoutRef, useState } from 'react';
import { SearchResultPort } from '../../../../models/search-result-port';
import { useIntl } from 'react-intl';
import { TableColumn } from '../../../../models/table-column';
import { sortMethodWithOrderByColumn } from '../../../../utils/sort.util';
import { ReactComponent as Barge } from '../../../../icons/barge.svg';
import { ReactComponent as Truck } from '../../../../icons/truck.svg';

interface PortChildrenTableProps {
  ports: SearchResultPort[];
}

function PortChildrenTable({ ports }: PropsWithoutRef<PortChildrenTableProps>) {
  const intl = useIntl();

  // table sorts
  const [sortBy, setSortBy] = useState('name');
  const [sortByProperty, setSortByProperty] = useState('name');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [data, setData] = useState(() =>
    [...ports].sort(sortMethodWithOrderByColumn(sortByProperty, sortDirection)),
  );

  // port children columns
  const columns: TableColumn<SearchResultPort>[] = [
    {
      key: 'name',
      property: 'name',
      label: intl.formatMessage({ id: 'port-label' }),
      width: '28.61764705882353%',
    },
    {
      key: 'minBulkSupply',
      property: '',
      render: (data) => {
        let total = 0;
        const properties: (keyof SearchResultPort)[] = [
          'minBulkTruckOrderInLitres',
          'minBulkBargeOrderInLitres',
          'minBulkIbcOrderInLitres',
        ];
        for (const prop of properties) {
          const value = data[prop] as number;
          total += value > 0 ? value : 0;
        }
        return total;
      },
      sort(sortDirection) {
        function minBulkSupply(port: SearchResultPort) {
          let total = 0;
          const properties: (keyof SearchResultPort)[] = [
            'minBulkTruckOrderInLitres',
            'minBulkBargeOrderInLitres',
            'minBulkIbcOrderInLitres',
          ];
          for (const prop of properties) {
            const value = port[prop] as number;
            total += value > 0 ? value : 0;
          }
          return total;
        }

        if (sortDirection === 'asc') {
          return (a, b) => minBulkSupply(b) - minBulkSupply(a);
        } else {
          return (a, b) => minBulkSupply(a) - minBulkSupply(b);
        }
      },
      label: intl.formatMessage({ id: 'min-bulk-supply-label' }),
      width: '18.82352941176471%',
    },
    {
      key: 'minDumSupply',
      property: 'minPackOrderInDrums',
      label: intl.formatMessage({ id: 'min-dum-supply-label' }),
      width: '18.23529411764706%',
    },
    {
      key: 'noticeRequired',
      property: 'workingDaysNotice',
      label: intl.formatMessage({ id: 'notice-required-label' }),
      width: '18.67647058823529%',
    },
    {
      key: 'barge',
      property: 'minBulkBargeOrderInLitres',
      label: intl.formatMessage({ id: 'barge-label' }),
      width: '7.79411764705882%',
      render: (data) => {
        return data.minBulkBargeOrderInLitres > 0 && <Barge />;
      },
    },
    {
      key: 'truck',
      property: 'minBulkTruckOrderInLitres',
      label: intl.formatMessage({ id: 'truck-label' }),
      width: '7.85294117647059%',
      render: (data) => {
        return data.minBulkTruckOrderInLitres > 0 && <Truck />;
      },
    },
  ];

  /**
   * handle sort change
   * @param column column
   */
  const onSortChange = (column: TableColumn<SearchResultPort>) => {
    const changes = sortChangeHandler(
      [sortBy, setSortBy],
      [sortByProperty, setSortByProperty],
      [sortDirection, setSortDirection],
      column,
    );

    if (column.sort) {
      const sorted = [...data].sort(column.sort(sortDirection));

      setData(sorted);
    } else {
      const sorted = [...data].sort(
        sortMethodWithOrderByColumn(
          changes.sortByProperty,
          changes.sortDirection,
        ),
      );

      setData(sorted);
    }
  };

  return (
    <ProductListTable
      data={data}
      sortBy={sortBy}
      sortDirection={sortDirection}
      background={'dark'}
      columns={columns}
      onSortChange={onSortChange}
      showCheckbox={false}
    />
  );
}

export default PortChildrenTable;
