/* eslint-disable react/jsx-key */
import { useEffect, useState, useMemo, Fragment, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import XLSX from 'xlsx';

import Card from '../../../../components/common/Card/Card';
import Header from '../../../../components/Admin/Header/Header';
import InfoCard from '../../../../components/Admin/Products/InfoCard/InfoCard';
import HorizontalStepper from '../../../../components/common/HorizontalStepper/HorizontalStepper';
import MobileStepper from '../../../../components/common/MobileStepper/MobileStepper';
import UploadData from '../../../../components/Admin/Products/UploadData/UploadData';
import { PreviousLink } from '../../../../models/previous-link';
import styles from './ManageMultipleProductsPage.module.scss';
import SaveData from '../../../../components/Admin/Products/SaveData/SaveData';
import { ProductValidationColumn } from '../../../../models/table-column';

import LoadingSpinner from '../../../../components/common/LoadingSpinner/LoadingSpinner';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../../store';
import withAdmin from '../../../../hoc/withAdmin';
import ValidateData from '../../../../components/common/ValidateData/ValidateData';
import {
  downloadExistingBulkProducts,
  downloadProductsTemplate,
  saveBulkProducts,
  SaveRequestBody,
} from '../../../../services/BulkProductService';
import ValidationDataCell from '../../../../components/common/ValidationDataCell/ValidationDataCell';
import ValidationDataCellText from '../../../../components/common/ValidationDataCellText/ValidationDataCellText';
import ValidationDataRow from '../../../../components/common/ValidationDataRow/ValidationDataRow';
import ValidationDataColumn from '../../../../components/common/ValidationDataColumn/ValidationDataColumn';
import ValidationDataCellSelect, {
  useCellSelect,
} from '../../../../components/common/ValidationDataCellSelect/ValidationDataCellSelect';
import { SelectOption } from '../../../../models/select-option';
import ValidationDataCellMultiSelect from '../../../../components/common/ValidationDataCellMultiSelect/ValidationDataCellMultiSelect';
import {
  isIndustrial,
  validateAllRows,
  validateRow,
} from '../../../../utils/add-multiple-products.util';
import { RangeFamily } from '../../../../models/admin-form-types';
import { getRangeFamilies } from '../../../../services/FamilyService';
import {
  LookupItem,
  SubApplicationItem,
  SubSectorItem,
} from '../../../../models/lookups';
import { SheetValidationResult } from '../../../../models/sheet-validation';
import { MountService } from '../../../../services/MountService';
import {
  getBoolValue,
  getMultiSelectValue,
  getNumberArrayValue,
  getNumberValue,
  getSingleSelectValue,
} from '../../../../utils/sheet-data-accessor.util';
import ValidationDataCellTextArea from '../../../../components/common/ValidationDataCellTextArea/ValidationDataCellTextArea';
import { addToast } from '../../../../features/toastSlice';
import { hideLoading, showLoading } from '../../../../features/loadingSlice';
import { generateFriendlyUrl } from '../../../../utils/common.util';
import config from '../../../../configs/config';

function useCellMultiSelect(lookupItems: LookupItem[]) {
  const selectOptions = useMemo(() => {
    return lookupItems.map((item) => ({
      name: item.name,
      value: item.id,
    }));
  }, [lookupItems]);

  return {
    selectOptions,
  };
}

export interface ErrorMessage {
  row: number;
  property: keyof SaveRequestBody;
  message: string;
}

/**
 * manage multiple products page component
 */
function ManageMultipleProductsPage() {
  const navigate = useNavigate();
  const intl = useIntl();
  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const {
    masterBrands,
    categories,
    categoriesById,
    subCategories,
    categorySubCategories,
    subCategoriesById,
    applications,
    applicationsById,
    subApplications,
    subApplicationsById,
    countries,
    countriesById,
    sectors,
    subSectors,
    subSectorsById,
    sectorsById,
    productTypes,
    productTypesById,
    viscosityIndices,
    viscosityIndicesById,
    baseOilTypeGenerics,
    baseOilTypeGenericsById,
    baseOilTypeSpecifics,
    baseOilTypeSpecificsById,
    thickenerGenerics,
    thickenerGenericsById,
    thickenerSpecifics,
    thickenerSpecificsById,
    corrosionProtections,
    corrosionProtectionsById,
    customerOffers,
    customerOffersById,
    characteristics,
    characteristicsById,
    mineralOils,
    mineralOilsById,
    concentrationRanges,
    concentrationRangesById,
    yesNoNones,
    yesNoNonesById,
    nlgis,
    nlgisById,
    metalTypes,
    metalTypesById,
  } = useSelector((rootState: RootStore) => rootState.lookups);

  const [loading, setLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [currentStep, setCurrentStep] = useState(0);
  const [file, setFile] = useState();
  const [families, setFamilies] = useState<RangeFamily[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [errorCount, setErrorCount] = useState<number>(0);
  const [backendErrors, setBackendErrors] = useState<any[]>([]);
  const [sheetValidationResult, setSheetValidationResult] =
    useState<SheetValidationResult>();

  const mountService = useMemo(() => new MountService(), []);
  useEffect(() => {
    mountService.mount();
    return () => mountService.unmount();
  }, []);
  mountService.addDispatch('setFamilies', setFamilies);
  mountService.addDispatch('setLoading', setLoading);

  const { selectOptions: categoryOptions } = useCellSelect(categories);

  const { selectOptions: productTypeOptions } = useCellSelect(productTypes);

  const { selectOptions: viscosityIndexOptions } =
    useCellSelect(viscosityIndices);

  const { selectOptions: corrosionProtectionOptions } =
    useCellSelect(corrosionProtections);

  const { selectOptions: customerOfferOptions } = useCellSelect(customerOffers);

  const { selectOptions: characteristicOptions } =
    useCellSelect(characteristics);

  const { selectOptions: mineralOilOptions } = useCellSelect(mineralOils);

  const { selectOptions: concentrationRangeOptions } =
    useCellSelect(concentrationRanges);

  const { selectOptions: foodGradeOptions } = useCellSelect(yesNoNones);

  const { selectOptions: superiorBiodegradationOptions } =
    useCellSelect(yesNoNones);

  const { selectOptions: multiMetalSuitableOptions } =
    useCellSelect(yesNoNones);

  const { selectOptions: formulatedWithoutSilicateOptions } =
    useCellSelect(yesNoNones);

  const { selectOptions: formulatedWithoutChlorinatedParaffinOptions } =
    useCellSelect(yesNoNones);

  const { selectOptions: formulatedWithoutBoronOptions } =
    useCellSelect(yesNoNones);

  const { selectOptions: formulatedWithoutHeavyMetalOptions } =
    useCellSelect(yesNoNones);

  const subCategoryOptionsMap = useMemo(() => {
    const map = new Map<number, SelectOption<number>[]>();
    categorySubCategories.forEach((categorySubCategory) => {
      const subCats = map.get(categorySubCategory.categoryId) || [];
      const subCat = subCategoriesById[categorySubCategory.subCategoryId];
      map.set(categorySubCategory.categoryId, [
        ...subCats,
        {
          name: subCat.name,
          value: subCat.id,
        },
      ]);
    });
    return map;
  }, [categorySubCategories, subCategoriesById]);

  const getSubCategoryOptions = (categoryIds: number[]) => {
    const subCategoryOptions: Record<number, SelectOption<number>> = {};
    categoryIds.forEach((categoryId) => {
      subCategoryOptionsMap
        .get(categoryId)
        ?.forEach(
          (subCategoryOpt) =>
            (subCategoryOptions[subCategoryOpt.value] = subCategoryOpt),
        );
    });
    return Object.values(subCategoryOptions);
  };

  const subApplicationOptionsMap = useMemo(() => {
    const map = new Map<number, SelectOption<number>[]>();
    subApplications.forEach((subItem) => {
      const subItems = map.get(subItem.applicationId) || [];
      map.set(subItem.applicationId, [
        ...subItems,
        {
          name: subItem.name,
          value: subItem.id,
        },
      ]);
    });
    return map;
  }, [applications, subApplications]);

  const subSectorOptionsMap = useMemo(() => {
    const map = new Map<number, SelectOption<number>[]>();
    subSectors.forEach((subItem) => {
      const subItems = map.get(subItem.sectorId) || [];
      map.set(subItem.sectorId, [
        ...subItems,
        {
          name: subItem.name,
          value: subItem.id,
        },
      ]);
    });
    return map;
  }, [subSectors]);

  const boolOptions = useMemo(() => {
    return [
      {
        name: intl.formatMessage({ id: 'validation-data-bool-option-yes' }),
        value: true,
      },
      {
        name: intl.formatMessage({ id: 'validation-data-bool-option-no' }),
        value: false,
      },
    ];
  }, [intl]);

  const { selectOptions: familyOptions } = useCellSelect(families);
  const { selectOptions: masterBrandOptions } = useCellSelect(masterBrands);
  const familyRangeOptions = useMemo(() => {
    const ranges: Record<number, SelectOption<number>[]> = {};
    families.forEach((family) => {
      ranges[family.id] = (family.ranges || []).map((range) => ({
        name: range.name,
        value: range.id,
      }));
    });
    return ranges;
  }, [families]);
  const { selectOptions: countryOptions } = useCellMultiSelect(countries);
  const { selectOptions: baseOilTypeGenOptions } =
    useCellMultiSelect(baseOilTypeGenerics);
  const { selectOptions: baseOilTypeSpecOptions } =
    useCellMultiSelect(baseOilTypeSpecifics);
  const { selectOptions: thickenerGenOptions } =
    useCellMultiSelect(thickenerGenerics);
  const { selectOptions: thickenerSpecOptions } =
    useCellMultiSelect(thickenerSpecifics);
  const { selectOptions: nlgiOptions } = useCellMultiSelect(nlgis);
  const { selectOptions: metalTypesOptions } = useCellMultiSelect(metalTypes);
  const { selectOptions: applicationOptions } =
    useCellMultiSelect(applications);

  const getSubApplicationOptions = (
    selectedApplications: number[],
    subApplicationItems: SubApplicationItem[],
  ) => {
    const isAllSelected =
      selectedApplications.length === 1 && selectedApplications.includes(-1);
    const filteredSubItems = isAllSelected
      ? subApplicationItems
      : subApplicationItems.filter((subAppItem) =>
          selectedApplications.includes(subAppItem.applicationId),
        );

    return filteredSubItems.map((subAppItem) => ({
      name: subAppItem.name,
      value: subAppItem.id,
    }));
  };

  const { selectOptions: sectorOptions } = useCellMultiSelect(sectors);

  const getSubSectorOptions = (
    selectedSectors: number[],
    subSubSectorItems: SubSectorItem[],
  ) => {
    const isAllSelected =
      selectedSectors.length === 1 && selectedSectors.includes(-1);
    const filteredSubItems = isAllSelected
      ? subSubSectorItems
      : subSubSectorItems.filter((subAppItem) =>
          selectedSectors.includes(subAppItem.sectorId),
        );

    return filteredSubItems.map((subAppItem) => ({
      name: subAppItem.name,
      value: subAppItem.id,
    }));
  };

  const onRowUpdate = (
    updatedData: any,
    rowIndex: number,
    columnHeader?: string,
  ) => {
    // validate the updated row and update validation result
    const rowResult = validateRow(updatedData);
    const newHasError = Object.keys(rowResult).length > 0;
    const oldHasError =
      Object.keys(sheetValidationResult?.errorByRow[rowIndex] || {}).length > 0;
    const oldHasBackendError = backendErrors.find(
      (item) => item.row === rowIndex + 1 && item.property === columnHeader,
    );
    const newData = [...data];
    newData[rowIndex] = {
      ...updatedData,
      hasError: newHasError,
    };
    setData(newData);
    const newErrorByRow = sheetValidationResult?.errorByRow || [];
    newErrorByRow[rowIndex] = rowResult;
    if (newHasError && !oldHasError) {
      setErrorCount(errorCount + 1);
      setSheetValidationResult({
        totalError: errorCount + 1,
        errorByRow: newErrorByRow,
      });
    } else if (!newHasError && oldHasError) {
      setErrorCount(errorCount - 1);
      setSheetValidationResult({
        totalError: errorCount - 1,
        errorByRow: newErrorByRow,
      });
    }

    if (oldHasBackendError) {
      const tmpBackendErrors = backendErrors.filter(
        (item) => item.row !== rowIndex + 1 && item.property !== columnHeader,
      );
      setBackendErrors(tmpBackendErrors);
      if (errorCount > 0) {
        setErrorCount(errorCount - 1);
      }
    }
  };

  /* istanbul ignore next */
  const columns: ProductValidationColumn[] = [
    {
      formattedMessageId: 'add-bulk-products-product-family-label',
      key: (item: any) => item['Product Family'],
      objectKey: 'Product Family',
      hidden: false,
      renderer: (column, row, _updateData, rowIndex) => {
        const updateData = (row: any) => {
          onRowUpdate(row, rowIndex);
        };
        return (
          <ValidationDataCell
            className={styles.visibleDataCell}
            error={
              sheetValidationResult?.errorByRow[rowIndex]['Product Family'] ||
              null
            }
            bold={false}
          >
            <ValidationDataCellSelect
              smallHorizontalPadding
              dataNotAvailable={row['Product Family'] === null}
              value={row['Product Family']}
              options={familyOptions}
              onValueChange={(val) =>
                updateData({
                  ...row,
                  'Product Family': val,
                })
              }
            />
          </ValidationDataCell>
        );
      },
      requiredAsterisk: true,
    },
    {
      formattedMessageId: 'add-bulk-products-product-range-label',
      key: (item: any) => item['Product Range'],
      objectKey: 'Product Range',
      hidden: false,
      renderer: (column, row, _updateData, rowIndex) => {
        const updateData = (row: any) => {
          onRowUpdate(row, rowIndex);
        };
        return (
          <ValidationDataCell
            className={styles.visibleDataCell}
            error={
              sheetValidationResult?.errorByRow[rowIndex]['Product Range'] ||
              null
            }
            bold={false}
          >
            <ValidationDataCellSelect
              smallHorizontalPadding
              dataNotAvailable={
                row['Product Family'] === null || row['Product Range'] === null
              }
              value={row['Product Range']}
              options={
                row['Product Family'] === null
                  ? []
                  : familyRangeOptions[row['Product Family']]
              }
              onValueChange={(val) =>
                updateData({
                  ...row,
                  'Product Range': val,
                })
              }
            />
          </ValidationDataCell>
        );
      },
    },
    {
      formattedMessageId: 'add-bulk-products-id-label',
      key: (item: any) => item['ID'],
      objectKey: 'ID',
      hidden: false,
      renderer: (column, row, _updateData, rowIndex) => {
        const updateData = (row: any) => {
          onRowUpdate(row, rowIndex);
        };
        return (
          <ValidationDataCell
            className={styles.visibleDataCell}
            error={sheetValidationResult?.errorByRow[rowIndex]['ID'] || null}
            bold={false}
          >
            <ValidationDataCellText
              value={row['ID']}
              onValueChange={(val) =>
                updateData({
                  ...row,
                  ID: val,
                })
              }
              smallPadding
            />
          </ValidationDataCell>
        );
      },
    },
    {
      formattedMessageId: 'add-bulk-products-fusion-code-label',
      key: (item: any) => item['Fusion Code'],
      objectKey: 'Fusion Code',
      hidden: false,
      renderer: (column, row, _updateData, rowIndex) => {
        const updateData = (row: any) => {
          onRowUpdate(row, rowIndex);
        };
        return (
          <ValidationDataCell
            className={styles.visibleDataCell}
            error={
              sheetValidationResult?.errorByRow[rowIndex]['Fusion Code'] || null
            }
            bold={false}
            required={!row['Partial']}
          >
            <ValidationDataCellText
              value={row['Fusion Code']}
              onValueChange={(val) =>
                updateData({
                  ...row,
                  'Fusion Code': val,
                })
              }
              smallPadding
            />
          </ValidationDataCell>
        );
      },
    },
    {
      formattedMessageId: 'add-bulk-products-product-name-label',
      key: (item: any) => item['Product Name'],
      objectKey: 'Product Name',
      hidden: false,
      requiredAsterisk: true,
      renderer: (column, row, _updateData, rowIndex) => {
        const updateData = (row: any) => {
          onRowUpdate(row, rowIndex);
        };
        return (
          <ValidationDataCell
            className={styles.visibleDataCell}
            error={
              sheetValidationResult?.errorByRow[rowIndex]['Product Name'] ||
              null
            }
            bold={false}
          >
            <ValidationDataCellText
              value={row['Product Name']}
              onValueChange={(val) => {
                updateData({
                  ...row,
                  'Product Name': val,
                  'Friendly Url': generateFriendlyUrl(val),
                });
              }}
              smallPadding
            />
          </ValidationDataCell>
        );
      },
    },
    {
      formattedMessageId: 'add-bulk-products-friendly-url-label',
      key: (item: any) => item['Friendly Url'],
      objectKey: 'Friendly Url',
      hidden: false,
      requiredAsterisk: true,
      renderer: (column, row, _updateData, rowIndex) => {
        const updateData = (row: any) => {
          onRowUpdate(row, rowIndex, 'friendlyUrl');
        };
        return (
          <ValidationDataCell
            className={styles.visibleDataCell}
            error={
              sheetValidationResult?.errorByRow[rowIndex]['Friendly Url'] ||
              null
            }
            bold={false}
          >
            <ValidationDataCellText
              value={row['Friendly Url']}
              onValueChange={(val) =>
                updateData({
                  ...row,
                  'Friendly Url': val,
                })
              }
              smallPadding
            />
          </ValidationDataCell>
        );
      },
    },
    {
      formattedMessageId: 'add-bulk-products-master-brand-label',
      key: (item: any) => item['Master Brand'],
      objectKey: 'Master Brand',
      hidden: false,
      requiredAsterisk: true,
      renderer: (column, row, _updateData, rowIndex) => {
        const updateData = (row: any) => {
          onRowUpdate(row, rowIndex);
        };
        return (
          <ValidationDataCell
            className={styles.visibleDataCell}
            error={
              sheetValidationResult?.errorByRow[rowIndex]['Master Brand'] ||
              null
            }
            bold={false}
          >
            <ValidationDataCellSelect
              smallHorizontalPadding
              dataNotAvailable={row['Master Brand'] === null}
              value={row['Master Brand']}
              options={masterBrandOptions}
              onValueChange={(val) =>
                updateData({
                  ...row,
                  'Master Brand': val,
                })
              }
            />
          </ValidationDataCell>
        );
      },
    },
    {
      formattedMessageId: 'add-bulk-products-product-description-label',
      key: (item: any) => item['Product Description'],
      objectKey: 'Product Description',
      hidden: true,
      hiddenOnMobile: true,
      renderer: (column, row, _updateData, rowIndex) => {
        const updateData = (row: any) => {
          onRowUpdate(row, rowIndex);
        };
        return (
          <ValidationDataCell
            error={
              sheetValidationResult?.errorByRow[rowIndex][
                'Product Description'
              ] || null
            }
            bold={false}
          >
            <ValidationDataCellTextArea
              value={row['Product Description']}
              onValueChange={(val) =>
                updateData({
                  ...row,
                  'Product Description': val,
                })
              }
            />
          </ValidationDataCell>
        );
      },
    },
    {
      formattedMessageId: 'add-bulk-products-range-short-description-label',
      key: (item: any) => item['Range Short Description'],
      objectKey: 'Range Short Description',
      hidden: true,
      hiddenOnMobile: true,
      renderer: (column, row, _updateData, rowIndex) => {
        const updateData = (row: any) => {
          onRowUpdate(row, rowIndex);
        };
        return (
          <ValidationDataCell
            error={
              sheetValidationResult?.errorByRow[rowIndex][
                'Range Short Description'
              ] || null
            }
            bold={false}
          >
            <ValidationDataCellTextArea
              value={row['Range Short Description']}
              onValueChange={(val) =>
                updateData({
                  ...row,
                  'Range Short Description': val,
                })
              }
            />
          </ValidationDataCell>
        );
      },
    },
    {
      formattedMessageId: 'add-bulk-products-more-details-label',
      key: (item: any) => item['More Details'],
      objectKey: 'More Details',
      hidden: true,
      hiddenOnMobile: true,
      showDivider: true,
      renderer: (_, row, _updateData, rowIndex) => {
        const updateData = (row: any) => {
          onRowUpdate(row, rowIndex);
        };
        return (
          <ValidationDataRow>
            <ValidationDataColumn>
              <ValidationDataCell
                label={intl.formatMessage({
                  id: 'add-bulk-products-product-tag-line-label',
                })}
                error={
                  sheetValidationResult?.errorByRow[rowIndex][
                    'Product Tag line'
                  ]
                }
                required
              >
                <ValidationDataCellText
                  value={row['Product Tag line']}
                  onValueChange={(val) =>
                    updateData({
                      ...row,
                      'Product Tag line': val,
                    })
                  }
                />
              </ValidationDataCell>
              <ValidationDataCell
                label={intl.formatMessage({
                  id: 'add-bulk-products-grade-label',
                })}
                error={sheetValidationResult?.errorByRow[rowIndex]['Grade']}
              >
                <ValidationDataCellText
                  value={row['Grade']}
                  onValueChange={(val) =>
                    updateData({
                      ...row,
                      Grade: val,
                    })
                  }
                />
              </ValidationDataCell>
              <ValidationDataCell
                label={intl.formatMessage({
                  id: 'add-bulk-products-sub-grade-label',
                })}
                error={sheetValidationResult?.errorByRow[rowIndex]['Sub Grade']}
              >
                <ValidationDataCellText
                  value={row['Sub Grade']}
                  onValueChange={(val) =>
                    updateData({
                      ...row,
                      'Sub Grade': val,
                    })
                  }
                />
              </ValidationDataCell>
              <ValidationDataCell
                label={intl.formatMessage({
                  id: 'add-bulk-products-category-label',
                })}
                error={sheetValidationResult?.errorByRow[rowIndex]['Category']}
              >
                <ValidationDataCellMultiSelect
                  value={row['Category']}
                  options={categoryOptions}
                  valueToLabelFn={(val) => categoriesById[val]?.name || ''}
                  onValueChange={(val) =>
                    updateData({
                      ...row,
                      Category: val,
                    })
                  }
                />
              </ValidationDataCell>
              <ValidationDataCell
                label={intl.formatMessage({
                  id: 'add-bulk-products-sub-category-label',
                })}
                error={
                  sheetValidationResult?.errorByRow[rowIndex]['Sub-category']
                }
              >
                <ValidationDataCellSelect
                  dataNotAvailable={row['Sub-category'] === null}
                  value={row['Sub-category']}
                  options={getSubCategoryOptions(row['Category'])}
                  getLabelFn={(val) => subCategoriesById[val]?.name || ''}
                  onValueChange={(val) =>
                    updateData({
                      ...row,
                      'Sub-category': val,
                    })
                  }
                />
              </ValidationDataCell>
              <ValidationDataCell
                label={intl.formatMessage({
                  id: 'add-bulk-products-application-label',
                })}
                error={
                  sheetValidationResult?.errorByRow[rowIndex]['Application']
                }
                required={!row['Partial']}
              >
                <ValidationDataCellMultiSelect
                  value={row['Application']}
                  onValueChange={(val) =>
                    updateData({
                      ...row,
                      Application: val,
                    })
                  }
                  options={applicationOptions}
                  valueToLabelFn={(val) => applicationsById[val]?.name || ''}
                  showTooltip={true}
                />
              </ValidationDataCell>
              <ValidationDataCell
                label={intl.formatMessage({
                  id: 'add-bulk-products-sub-application-label',
                })}
                error={
                  sheetValidationResult?.errorByRow[rowIndex]['Sub-application']
                }
              >
                <ValidationDataCellMultiSelect
                  value={row['Sub-application']}
                  onValueChange={(val) =>
                    updateData({
                      ...row,
                      'Sub-application': val,
                    })
                  }
                  options={getSubApplicationOptions(
                    row['Application'],
                    subApplications,
                  )}
                  valueToLabelFn={(val) => subApplicationsById[val]?.name || ''}
                />
              </ValidationDataCell>
              <ValidationDataCell
                label={intl.formatMessage({
                  id: 'add-bulk-products-sector-label',
                })}
                error={sheetValidationResult?.errorByRow[rowIndex]['Sector']}
                required
              >
                <ValidationDataCellMultiSelect
                  value={row['Sector']}
                  onValueChange={(val) =>
                    updateData({
                      ...row,
                      Sector: val,
                    })
                  }
                  options={sectorOptions}
                  valueToLabelFn={(val) => sectorsById[val]?.name || ''}
                />
              </ValidationDataCell>
              <ValidationDataCell
                label={intl.formatMessage({
                  id: 'add-bulk-products-sub-sector-label',
                })}
                error={
                  sheetValidationResult?.errorByRow[rowIndex]['Sub-sector']
                }
              >
                <ValidationDataCellMultiSelect
                  value={row['Sub Sector']}
                  onValueChange={(val) =>
                    updateData({
                      ...row,
                      'Sub Sector': val,
                    })
                  }
                  options={getSubSectorOptions(row['Sector'], subSectors)}
                  valueToLabelFn={(val) => subSectorsById[val]?.name || ''}
                />
              </ValidationDataCell>
              {isIndustrial(row['Sector']) && (
                <ValidationDataCell
                  label={intl.formatMessage({
                    id: 'add-bulk-products-product-type-label',
                  })}
                  error={
                    sheetValidationResult?.errorByRow[rowIndex]['Product Type']
                  }
                  required={!row['Partial'] && isIndustrial(row['Sector'])}
                >
                  <ValidationDataCellMultiSelect
                    value={row['Product Type']}
                    options={productTypeOptions}
                    onValueChange={(val) => {
                      updateData({
                        ...row,
                        'Product Type': val,
                      });
                    }}
                    notAvailableValue={1} // product type 1 is N.A.
                    valueToLabelFn={(val) => productTypesById[val]?.name || ''}
                  />
                </ValidationDataCell>
              )}
              <ValidationDataCell
                label={intl.formatMessage({
                  id: 'add-bulk-products-country-availability-label',
                })}
                error={
                  sheetValidationResult?.errorByRow[rowIndex][
                    'Country Availability'
                  ]
                }
                required
              >
                <ValidationDataCellMultiSelect
                  value={row['Country Availability']}
                  onValueChange={(val) =>
                    updateData({
                      ...row,
                      'Country Availability': val,
                    })
                  }
                  options={countryOptions}
                  valueToLabelFn={(val) => countriesById[val]?.name || ''}
                />
              </ValidationDataCell>
            </ValidationDataColumn>
            <ValidationDataColumn>
              <ValidationDataCell
                label={intl.formatMessage({
                  id: 'add-bulk-products-viscosity-label',
                })}
                error={sheetValidationResult?.errorByRow[rowIndex]['Viscosity']}
              >
                <ValidationDataCellText
                  value={row['Viscosity']}
                  onValueChange={(val) =>
                    updateData({
                      ...row,
                      Viscosity: val,
                    })
                  }
                />
              </ValidationDataCell>
              <ValidationDataCell
                label={intl.formatMessage({
                  id: 'add-bulk-products-viscosity-index-label',
                })}
                error={
                  sheetValidationResult?.errorByRow[rowIndex]['Viscosity Index']
                }
              >
                <ValidationDataCellSelect
                  dataNotAvailable={row['Viscosity Index'] === null}
                  value={row['Viscosity Index']}
                  options={viscosityIndexOptions}
                  getLabelFn={(val) => viscosityIndicesById[val]?.name || ''}
                  onValueChange={(val) =>
                    updateData({
                      ...row,
                      'Viscosity Index': val,
                    })
                  }
                />
              </ValidationDataCell>
              <ValidationDataCell
                label={intl.formatMessage({
                  id: 'add-bulk-products-min-temperature-label',
                })}
                error={
                  sheetValidationResult?.errorByRow[rowIndex][
                    'Min Temperature C'
                  ]
                }
              >
                <ValidationDataCellText
                  value={row['Min Temperature C']}
                  onValueChange={(val) =>
                    updateData({
                      ...row,
                      'Min Temperature C': val,
                    })
                  }
                />
              </ValidationDataCell>
              <ValidationDataCell
                label={intl.formatMessage({
                  id: 'add-bulk-products-max-temperature-label',
                })}
                error={
                  sheetValidationResult?.errorByRow[rowIndex][
                    'Max Temperature C'
                  ]
                }
              >
                <ValidationDataCellText
                  value={row['Max Temperature C']}
                  onValueChange={(val) =>
                    updateData({
                      ...row,
                      'Max Temperature C': val,
                    })
                  }
                />
              </ValidationDataCell>
              <ValidationDataCell
                label={intl.formatMessage({
                  id: 'add-bulk-products-castrol-recommended-label',
                })}
                error={
                  sheetValidationResult?.errorByRow[rowIndex][
                    'Castrol Recommended'
                  ]
                }
                required
              >
                <ValidationDataCellSelect
                  dataNotAvailable={row['Castrol Recommended'] === null}
                  value={row['Castrol Recommended']}
                  options={boolOptions}
                  onValueChange={(val) =>
                    updateData({
                      ...row,
                      'Castrol Recommended': val,
                    })
                  }
                />
              </ValidationDataCell>
              <ValidationDataCell
                label={intl.formatMessage({
                  id: 'add-bulk-products-base-oil-type-label',
                })}
                error={
                  sheetValidationResult?.errorByRow[rowIndex]['Base Oil Type']
                }
              >
                <ValidationDataCellMultiSelect
                  value={row['Base Oil Type']}
                  onValueChange={(val) =>
                    updateData({
                      ...row,
                      'Base Oil Type': val,
                    })
                  }
                  options={baseOilTypeGenOptions}
                  valueToLabelFn={(val) =>
                    baseOilTypeGenericsById[val]?.name || ''
                  }
                />
              </ValidationDataCell>
              <ValidationDataCell
                label={intl.formatMessage({
                  id: 'add-bulk-products-base-oil-type-spec-label',
                })}
                error={
                  sheetValidationResult?.errorByRow[rowIndex][
                    'Base Oil Type Spec'
                  ]
                }
              >
                <ValidationDataCellMultiSelect
                  value={row['Base Oil Type Spec']}
                  onValueChange={(val) =>
                    updateData({
                      ...row,
                      'Base Oil Type Spec': val,
                    })
                  }
                  options={baseOilTypeSpecOptions}
                  valueToLabelFn={(val) =>
                    baseOilTypeSpecificsById[val]?.name || ''
                  }
                />
              </ValidationDataCell>
              <ValidationDataCell
                label={intl.formatMessage({
                  id: 'add-bulk-products-thickener-gen-label',
                })}
                error={
                  sheetValidationResult?.errorByRow[rowIndex]['Thickener Gen']
                }
              >
                <ValidationDataCellMultiSelect
                  value={row['Thickener Gen']}
                  onValueChange={(val) =>
                    updateData({
                      ...row,
                      'Thickener Gen': val,
                    })
                  }
                  options={thickenerGenOptions}
                  valueToLabelFn={(val) =>
                    thickenerGenericsById[val]?.name || ''
                  }
                />
              </ValidationDataCell>
              <ValidationDataCell
                label={intl.formatMessage({
                  id: 'add-bulk-products-thickener-spec-label',
                })}
                error={
                  sheetValidationResult?.errorByRow[rowIndex]['Thickener Spec']
                }
              >
                <ValidationDataCellMultiSelect
                  value={row['Thickener Spec']}
                  onValueChange={(val) =>
                    updateData({
                      ...row,
                      'Thickener Spec': val,
                    })
                  }
                  options={thickenerSpecOptions}
                  valueToLabelFn={(val) =>
                    thickenerSpecificsById[val]?.name || ''
                  }
                />
              </ValidationDataCell>
              <ValidationDataCell
                label={intl.formatMessage({
                  id: 'add-bulk-products-nlgi-label',
                })}
                error={sheetValidationResult?.errorByRow[rowIndex]['NLGI']}
              >
                <ValidationDataCellMultiSelect
                  value={row['NLGI']}
                  onValueChange={(val) =>
                    updateData({
                      ...row,
                      NLGI: val,
                    })
                  }
                  options={nlgiOptions}
                  valueToLabelFn={(val) => nlgisById[val]?.name || ''}
                />
              </ValidationDataCell>
              <ValidationDataCell
                label={intl.formatMessage({
                  id: 'add-bulk-products-corrosion-protection-label',
                })}
                error={
                  sheetValidationResult?.errorByRow[rowIndex][
                    'Corrosion Protection'
                  ]
                }
              >
                <ValidationDataCellSelect
                  dataNotAvailable={row['Corrosion Protection'] === null}
                  value={row['Corrosion Protection']}
                  options={corrosionProtectionOptions}
                  getLabelFn={(val) =>
                    corrosionProtectionsById[val]?.name || ''
                  }
                  onValueChange={(val) =>
                    updateData({
                      ...row,
                      'Corrosion Protection': val,
                    })
                  }
                />
              </ValidationDataCell>
              <ValidationDataCell
                label={intl.formatMessage({
                  id: 'add-bulk-products-metal-type-label',
                })}
                error={
                  sheetValidationResult?.errorByRow[rowIndex]['Metal type']
                }
              >
                <ValidationDataCellMultiSelect
                  value={row['Metal type']}
                  onValueChange={(val) =>
                    updateData({
                      ...row,
                      'Metal type': val,
                    })
                  }
                  options={metalTypesOptions}
                  valueToLabelFn={(val) => metalTypesById[val]?.name || ''}
                />
              </ValidationDataCell>
              <ValidationDataCell
                label={intl.formatMessage({
                  id: 'add-bulk-products-customer-offer-label',
                })}
                error={
                  sheetValidationResult?.errorByRow[rowIndex]['Customer Offer']
                }
              >
                <ValidationDataCellSelect
                  dataNotAvailable={row['Customer Offer'] === null}
                  value={row['Customer Offer']}
                  options={customerOfferOptions}
                  getLabelFn={(val) => customerOffersById[val]?.name || ''}
                  onValueChange={(val) =>
                    updateData({
                      ...row,
                      'Customer Offer': val,
                    })
                  }
                />
              </ValidationDataCell>
            </ValidationDataColumn>
            <ValidationDataColumn>
              <ValidationDataCell
                label={intl.formatMessage({
                  id: 'add-bulk-products-repository-business-label',
                })}
                error={
                  sheetValidationResult?.errorByRow[rowIndex][
                    'Repository Business'
                  ]
                }
                required={!row['Partial']}
              >
                <ValidationDataCellText
                  value={row['Repository Business']}
                  onValueChange={(val) =>
                    updateData({
                      ...row,
                      'Repository Business': val,
                    })
                  }
                />
              </ValidationDataCell>
              <ValidationDataCell
                label={intl.formatMessage({
                  id: 'add-bulk-products-characteristic-label',
                })}
                error={
                  sheetValidationResult?.errorByRow[rowIndex]['Customer Offer']
                }
              >
                <ValidationDataCellSelect
                  dataNotAvailable={row['Customer Offer'] === null}
                  value={row['Customer Offer']}
                  options={characteristicOptions}
                  getLabelFn={(val) => characteristicsById[val]?.name || ''}
                  onValueChange={(val) =>
                    updateData({
                      ...row,
                      'Customer Offer': val,
                    })
                  }
                />
              </ValidationDataCell>
              <ValidationDataCell
                label={intl.formatMessage({
                  id: 'add-bulk-products-mineral-oil-label',
                })}
                error={
                  sheetValidationResult?.errorByRow[rowIndex]['Mineral Oil']
                }
              >
                <ValidationDataCellSelect
                  dataNotAvailable={row['Mineral Oil'] === null}
                  value={row['Mineral Oil']}
                  options={mineralOilOptions}
                  getLabelFn={(val) => mineralOilsById[val]?.name || ''}
                  onValueChange={(val) =>
                    updateData({
                      ...row,
                      'Mineral Oil': val,
                    })
                  }
                />
              </ValidationDataCell>
              <ValidationDataCell
                label={intl.formatMessage({
                  id: 'add-bulk-products-concentration-range-label',
                })}
                error={
                  sheetValidationResult?.errorByRow[rowIndex][
                    'Concentration Range'
                  ]
                }
              >
                <ValidationDataCellSelect
                  dataNotAvailable={row['Concentration Range'] === null}
                  value={row['Concentration Range']}
                  options={concentrationRangeOptions}
                  getLabelFn={(val) => concentrationRangesById[val]?.name || ''}
                  onValueChange={(val) =>
                    updateData({
                      ...row,
                      'Concentration Range': val,
                    })
                  }
                />
              </ValidationDataCell>
              <ValidationDataCell
                label={intl.formatMessage({
                  id: 'add-bulk-products-food-grade-label',
                })}
                error={
                  sheetValidationResult?.errorByRow[rowIndex]['Food Grade']
                }
              >
                <ValidationDataCellSelect
                  dataNotAvailable={row['Food Grade'] === null}
                  value={row['Food Grade']}
                  options={foodGradeOptions}
                  getLabelFn={(val) => yesNoNonesById[val]?.name || ''}
                  onValueChange={(val) =>
                    updateData({
                      ...row,
                      'Food Grade': val,
                    })
                  }
                />
              </ValidationDataCell>
              <ValidationDataCell
                label={intl.formatMessage({
                  id: 'add-bulk-products-superior-biodegradation-label',
                })}
                error={
                  sheetValidationResult?.errorByRow[rowIndex][
                    'Superior Biodegradation'
                  ]
                }
              >
                <ValidationDataCellSelect
                  dataNotAvailable={row['Superior Biodegradation'] === null}
                  value={row['Superior Biodegradation']}
                  options={superiorBiodegradationOptions}
                  getLabelFn={(val) => yesNoNonesById[val]?.name || ''}
                  onValueChange={(val) =>
                    updateData({
                      ...row,
                      'Superior Biodegradation': val,
                    })
                  }
                />
              </ValidationDataCell>
              <ValidationDataCell
                label={intl.formatMessage({
                  id: 'add-bulk-products-ep-additives-label',
                })}
                error={
                  sheetValidationResult?.errorByRow[rowIndex]['EP Additives']
                }
              >
                <ValidationDataCellSelect
                  dataNotAvailable={row['EP Additives'] === null}
                  value={row['EP Additives']}
                  options={boolOptions}
                  onValueChange={(val) =>
                    updateData({
                      ...row,
                      'EP Additives': val,
                    })
                  }
                />
              </ValidationDataCell>
              <ValidationDataCell
                label={intl.formatMessage({
                  id: 'add-bulk-products-multi-metals-suitable-label',
                })}
                error={
                  sheetValidationResult?.errorByRow[rowIndex][
                    'Multi Metals Suitable'
                  ]
                }
              >
                <ValidationDataCellSelect
                  dataNotAvailable={row['Multi Metals Suitable'] === null}
                  value={row['Multi Metals Suitable']}
                  options={multiMetalSuitableOptions}
                  getLabelFn={(val) => yesNoNonesById[val]?.name || ''}
                  onValueChange={(val) =>
                    updateData({
                      ...row,
                      'Multi Metals Suitable': val,
                    })
                  }
                />
              </ValidationDataCell>
              <ValidationDataCell
                label={intl.formatMessage({
                  id: 'add-bulk-products-formulated-without-silicate-label',
                })}
                error={
                  sheetValidationResult?.errorByRow[rowIndex][
                    'Formulated without Silicate'
                  ]
                }
              >
                <ValidationDataCellSelect
                  dataNotAvailable={row['Formulated without Silicate'] === null}
                  value={row['Formulated without Silicate']}
                  options={formulatedWithoutSilicateOptions}
                  getLabelFn={(val) => yesNoNonesById[val]?.name || ''}
                  onValueChange={(val) =>
                    updateData({
                      ...row,
                      'Formulated without Silicate': val,
                    })
                  }
                />
              </ValidationDataCell>
              <ValidationDataCell
                label={intl.formatMessage({
                  id: 'add-bulk-products-formulated-without-chlorinated-paraffin-label',
                })}
                error={
                  sheetValidationResult?.errorByRow[rowIndex][
                    'Formulated without Chlorinated Paraffin'
                  ]
                }
              >
                <ValidationDataCellSelect
                  dataNotAvailable={
                    row['Formulated without Chlorinated Paraffin'] === null
                  }
                  value={row['Formulated without Chlorinated Paraffin']}
                  options={formulatedWithoutChlorinatedParaffinOptions}
                  getLabelFn={(val) => yesNoNonesById[val]?.name || ''}
                  onValueChange={(val) =>
                    updateData({
                      ...row,
                      'Formulated without Chlorinated Paraffin': val,
                    })
                  }
                />
              </ValidationDataCell>
              <ValidationDataCell
                label={intl.formatMessage({
                  id: 'add-bulk-products-formulated-without-boron-label',
                })}
                error={
                  sheetValidationResult?.errorByRow[rowIndex][
                    'Formulated without Boron'
                  ]
                }
              >
                <ValidationDataCellSelect
                  dataNotAvailable={row['Formulated without Boron'] === null}
                  value={row['Formulated without Boron']}
                  options={formulatedWithoutBoronOptions}
                  getLabelFn={(val) => yesNoNonesById[val]?.name || ''}
                  onValueChange={(val) =>
                    updateData({
                      ...row,
                      'Formulated without Boron': val,
                    })
                  }
                />
              </ValidationDataCell>
              <ValidationDataCell
                label={intl.formatMessage({
                  id: 'add-bulk-products-formulated-without-heavy-metal-label',
                })}
                error={
                  sheetValidationResult?.errorByRow[rowIndex][
                    'Formulated without Heavy Metal'
                  ]
                }
              >
                <ValidationDataCellSelect
                  dataNotAvailable={
                    row['Formulated without Heavy Metal'] === null
                  }
                  value={row['Formulated without Heavy Metal']}
                  options={formulatedWithoutHeavyMetalOptions}
                  getLabelFn={(val) => yesNoNonesById[val]?.name || ''}
                  onValueChange={(val) =>
                    updateData({
                      ...row,
                      'Formulated without Heavy Metal': val,
                    })
                  }
                />
              </ValidationDataCell>
              <ValidationDataCell
                label={intl.formatMessage({
                  id: 'add-bulk-products-partial-label',
                })}
                error={sheetValidationResult?.errorByRow[rowIndex]['Partial']}
                required
              >
                <ValidationDataCellSelect
                  dataNotAvailable={row['Partial'] === null}
                  value={row['Partial']}
                  options={boolOptions}
                  onValueChange={(val) =>
                    updateData({
                      ...row,
                      Partial: val,
                    })
                  }
                />
              </ValidationDataCell>
            </ValidationDataColumn>
          </ValidationDataRow>
        );
      },
    },
  ];

  const reversedLookupItems = useMemo(
    () => ({
      families: families.reduce<
        Record<string, LookupItem & { ranges: Record<string, LookupItem> }>
      >((collection, item) => {
        collection[item.name] = {
          ...item,
          ranges:
            item.ranges?.reduce<Record<string, LookupItem>>(
              (subCollection, subItem) => {
                subCollection[subItem.name] = subItem;
                return subCollection;
              },
              {},
            ) || {},
        };
        return collection;
      }, {}),
      masterBrands: masterBrands.reduce<Record<string, LookupItem>>(
        (collection, item) => {
          collection[item.name] = {
            name: item.name,
            id: item.id,
          };
          return collection;
        },
        {},
      ),
      categories: categories.reduce<
        Record<
          string,
          LookupItem & { subCategories: Record<string, LookupItem> }
        >
      >((collection, item) => {
        collection[item.name] = {
          name: item.name,
          id: item.id,
          subCategories:
            (subCategoryOptionsMap.get(item.id) || []).reduce<
              Record<string, LookupItem>
            >((collection, subItem) => {
              collection[subItem.name] = {
                name: subItem.name,
                id: subItem.value,
              };
              return collection;
            }, {}) || {},
        };
        return collection;
      }, {}),
      subCategories: subCategories.reduce<Record<string, LookupItem>>(
        (collection, item) => {
          collection[item.name] = {
            name: item.name,
            id: item.id,
          };
          return collection;
        },
        {},
      ),
      applications: applications.reduce<
        Record<
          string,
          LookupItem & { subApplications: Record<string, LookupItem> }
        >
      >((collection, item) => {
        collection[item.name] = {
          name: item.name,
          id: item.id,
          subApplications:
            (subApplicationOptionsMap.get(item.id) || []).reduce<
              Record<string, LookupItem>
            >((collection, subItem) => {
              collection[subItem.name] = {
                name: subItem.name,
                id: subItem.value,
              };
              return collection;
            }, {}) || {},
        };
        return collection;
      }, {}),
      subApplications: subApplications.reduce<Record<string, LookupItem>>(
        (subApplicationsCollection, currentSubApplication) => {
          subApplicationsCollection[currentSubApplication.name] =
            currentSubApplication;
          return subApplicationsCollection;
        },
        {},
      ),
      sectors: [
        ...sectors,
        {
          name: 'All',
          id: config.ALL_ITEM_VALUE as any,
        },
      ].reduce<
        Record<string, LookupItem & { subSectors: Record<string, LookupItem> }>
      >((collection, item) => {
        collection[item.name] = {
          name: item.name,
          id: item.id,
          subSectors:
            (subSectorOptionsMap.get(item.id) || []).reduce<
              Record<string, LookupItem>
            >((collection, subItem) => {
              collection[subItem.name] = {
                name: subItem.name,
                id: subItem.value,
              };
              return collection;
            }, {}) || {},
        };
        return collection;
      }, {}),
      subSectors: subSectors.reduce<Record<string, LookupItem>>(
        (subSectorsCollection, currentSubSector) => {
          subSectorsCollection[currentSubSector.name] = currentSubSector;
          return subSectorsCollection;
        },
        {},
      ),
      productTypes: productTypes.reduce<Record<string, LookupItem>>(
        (collection, item) => {
          collection[item.name] = {
            name: item.name,
            id: item.id,
          };
          return collection;
        },
        {},
      ),
      countries: [
        ...countries,
        {
          name: 'All',
          id: config.ALL_ITEM_VALUE as any,
        },
      ].reduce<Record<string, LookupItem>>((collection, item) => {
        collection[item.name] = {
          name: item.name,
          id: item.id,
        };
        return collection;
      }, {}),
      viscosityIndices: viscosityIndices.reduce<Record<string, LookupItem>>(
        (collection, item) => {
          collection[item.name] = {
            name: item.name,
            id: item.id,
          };
          return collection;
        },
        {},
      ),
      baseOilTypesGen: baseOilTypeGenerics.reduce<Record<string, LookupItem>>(
        (collection, item) => {
          collection[item.name] = {
            name: item.name,
            id: item.id,
          };
          return collection;
        },
        {},
      ),
      baseOilTypesSpec: baseOilTypeSpecifics.reduce<Record<string, LookupItem>>(
        (collection, item) => {
          collection[item.name] = {
            name: item.name,
            id: item.id,
          };
          return collection;
        },
        {},
      ),
      thickenersGen: thickenerGenerics.reduce<Record<string, LookupItem>>(
        (collection, item) => {
          collection[item.name] = {
            name: item.name,
            id: item.id,
          };
          return collection;
        },
        {},
      ),
      thickenersSpec: thickenerSpecifics.reduce<Record<string, LookupItem>>(
        (collection, item) => {
          collection[item.name] = {
            name: item.name,
            id: item.id,
          };
          return collection;
        },
        {},
      ),
      nlgis: nlgis.reduce<Record<string, LookupItem>>((collection, item) => {
        collection[item.name] = {
          name: item.name,
          id: item.id,
        };
        return collection;
      }, {}),
      corrosionProtections: corrosionProtections.reduce<
        Record<string, LookupItem>
      >((collection, item) => {
        collection[item.name] = {
          name: item.name,
          id: item.id,
        };
        return collection;
      }, {}),
      metalTypes: metalTypes.reduce<Record<string, LookupItem>>(
        (collection, item) => {
          collection[item.name] = {
            name: item.name,
            id: item.id,
          };
          return collection;
        },
        {},
      ),
      customerOffers: customerOffers.reduce<Record<string, LookupItem>>(
        (collection, item) => {
          collection[item.name] = {
            name: item.name,
            id: item.id,
          };
          return collection;
        },
        {},
      ),
      characteristics: characteristics.reduce<Record<string, LookupItem>>(
        (collection, item) => {
          collection[item.name] = {
            name: item.name,
            id: item.id,
          };
          return collection;
        },
        {},
      ),
      mineralOils: mineralOils.reduce<Record<string, LookupItem>>(
        (collection, item) => {
          collection[item.name] = {
            name: item.name,
            id: item.id,
          };
          return collection;
        },
        {},
      ),
      concentrationRanges: concentrationRanges.reduce<
        Record<string, LookupItem>
      >((collection, item) => {
        collection[item.name] = {
          name: item.name,
          id: item.id,
        };
        return collection;
      }, {}),
      yesNoNones: yesNoNones.reduce<Record<string, LookupItem>>(
        (collection, item) => {
          collection[item.name] = {
            name: item.name,
            id: item.id,
          };
          return collection;
        },
        {},
      ),
      boolValues: {
        [intl.formatMessage({ id: 'validation-data-bool-option-yes' })]: {
          name: intl.formatMessage({ id: 'validation-data-bool-option-yes' }),
          value: true,
        },
        [intl.formatMessage({ id: 'validation-data-bool-option-no' })]: {
          name: intl.formatMessage({ id: 'validation-data-bool-option-no' }),
          value: false,
        },
      },
    }),
    [
      families,
      masterBrands,
      categorySubCategories,
      intl,
      yesNoNones,
      concentrationRanges,
      mineralOils,
      characteristics,
      customerOffers,
      metalTypes,
      corrosionProtections,
      nlgis,
      thickenerSpecifics,
      thickenerGenerics,
      baseOilTypeSpecifics,
      baseOilTypeGenerics,
      viscosityIndices,
      countries,
      productTypes,
      sectors,
      subApplications,
      applications,
      categories,
    ],
  );

  const handleDataChange = (newData: any[]) => {
    const result = validateAllRows(newData);
    setSheetValidationResult(result);
    setErrorCount(result.totalError);
    setData(
      newData.map((row, rowIndex) => ({
        ...row,
        hasError: Object.keys(result.errorByRow[rowIndex]).length > 0,
      })),
    );
    dispatch(hideLoading());
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const rangeFamilies = await getRangeFamilies(false);
        mountService.callDispatch('setFamilies', rangeFamilies);
      } finally {
        mountService.callDispatch('setLoading', false);
      }
    })();
  }, []);

  /* istanbul ignore next */
  const parseAndValidate = (file: any) => {
    if (file) {
      dispatch(showLoading());
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;
      reader.onload = (e) => {
        /* Parse data */
        const bstr = e?.target?.result;
        const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const propertyMap: Record<string, string> = {}; // sometimes column name has whitespace, we use this to map from our standard property name to it
        const getColumnValue = (
          row: any,
          propertyName: string,
          defaultValue?: string,
        ) => {
          if (propertyMap[propertyName]) {
            return (row[propertyMap[propertyName]] || '').trim();
          } else {
            return defaultValue ? defaultValue : '';
          }
        };
        /* Convert array of arrays */
        const newData = XLSX.utils
          .sheet_to_json(ws, {
            raw: false,
          })
          .map((row: any, index) => {
            if (index === 0) {
              Object.keys(row).forEach((columnName) => {
                propertyMap[columnName.trim()] = columnName;
              });
            }
            const application = getColumnValue(row, 'Application')
              ? getMultiSelectValue(
                  getColumnValue(row, 'Application'),
                  reversedLookupItems.applications,
                )
              : [];
            const sectors = getMultiSelectValue(
              getColumnValue(row, 'Sector'),
              reversedLookupItems.sectors,
            );
            return {
              'Product Family': getSingleSelectValue(
                getColumnValue(row, 'Product Family'),
                reversedLookupItems.families,
              ),
              'Product Range':
                getSingleSelectValue(
                  getColumnValue(row, 'Product Range'),
                  reversedLookupItems.families[
                    getColumnValue(row, 'Product Family')
                  ]?.ranges || [],
                ) || null,
              ID: getColumnValue(row, 'ID'),
              'Fusion Code': getColumnValue(row, 'Fusion Code'),
              'Product Name': getColumnValue(row, 'Product Name'),
              'OEM Only': getColumnValue(row, 'OEM Only'),
              'Friendly Url':
                getColumnValue(row, 'Friendly Url')?.trim().length > 0
                  ? getColumnValue(row, 'Friendly Url')
                  : generateFriendlyUrl(getColumnValue(row, 'Product Name')),
              'Product Tag line': getColumnValue(row, 'Product Tag line'),
              'Product Description': getColumnValue(row, 'Product Description'),
              'Range Short Description': getColumnValue(
                row,
                'Range Short Description',
              ),
              Grade: getColumnValue(row, 'Grade'),
              'Sub Grade': getColumnValue(row, 'Sub Grade'),
              'Master Brand': getSingleSelectValue(
                getColumnValue(row, 'Master Brand'),
                reversedLookupItems.masterBrands,
              ),
              Category: getMultiSelectValue(
                getColumnValue(row, 'Category'),
                reversedLookupItems.categories,
              ),
              'Sub-category': getSingleSelectValue(
                getColumnValue(row, 'Sub-category'),
                reversedLookupItems.subCategories,
              ),
              Application: application,
              'Sub-application': application
                ? getMultiSelectValue(
                    getColumnValue(row, 'Sub-application'),
                    reversedLookupItems.subApplications,
                  )
                : [],
              Sector: sectors,
              'Sub Sector': getMultiSelectValue(
                getColumnValue(row, 'Sub Sector'),
                reversedLookupItems.subSectors,
              ),
              'Product Type': getMultiSelectValue(
                getColumnValue(row, 'Product Type'),
                reversedLookupItems.productTypes,
                true,
              ),
              'Country Availability': getMultiSelectValue(
                getColumnValue(row, 'Country Availability'),
                reversedLookupItems.countries,
              ),
              Viscosity: row['Viscosity'] || '',
              'Viscosity Index': getSingleSelectValue(
                getColumnValue(row, 'Viscosity Index'),
                reversedLookupItems.viscosityIndices,
              ),
              'Min Temperature C': getColumnValue(row, 'Min Temperature C'),
              'Max Temperature C': getColumnValue(row, 'Max Temperature C'),
              'Castrol Recommended': getBoolValue(
                getColumnValue(row, 'Castrol Recommended', 'No'),
                reversedLookupItems.boolValues,
                false,
              ),
              'Base Oil Type': getMultiSelectValue(
                getColumnValue(row, 'Base Oil Type'),
                reversedLookupItems.baseOilTypesGen,
              ),
              'Base Oil Type Spec': getMultiSelectValue(
                getColumnValue(row, 'Base Oil Type Spec'),
                reversedLookupItems.baseOilTypesSpec,
              ),
              'Thickener Gen': getMultiSelectValue(
                getColumnValue(row, 'Thickener Gen'),
                reversedLookupItems.thickenersGen,
              ),
              'Thickener Spec': getMultiSelectValue(
                getColumnValue(row, 'Thickener Spec'),
                reversedLookupItems.thickenersSpec,
              ),
              NLGI: getMultiSelectValue(
                getColumnValue(row, 'NLGI'),
                reversedLookupItems.nlgis,
              ),
              'Corrosion Protection': getSingleSelectValue(
                getColumnValue(row, 'Corrosion Protection'),
                reversedLookupItems.corrosionProtections,
              ),
              'Metal type': getMultiSelectValue(
                getColumnValue(row, 'Metal type'),
                reversedLookupItems.metalTypes,
              ),
              'Customer Offer': getSingleSelectValue(
                getColumnValue(row, 'Customer Offer'),
                reversedLookupItems.customerOffers,
              ),
              'Repository Business': getColumnValue(row, 'Repository Business'),
              Characteristic: getSingleSelectValue(
                getColumnValue(row, 'Characteristic'),
                reversedLookupItems.characteristics,
              ),
              'Mineral Oil': getSingleSelectValue(
                getColumnValue(row, 'Mineral Oil'),
                reversedLookupItems.mineralOils,
              ),
              'Concentration Range': getSingleSelectValue(
                getColumnValue(row, 'Concentration Range'),
                reversedLookupItems.concentrationRanges,
              ),
              'Food Grade': getSingleSelectValue(
                getColumnValue(row, 'Food Grade', 'N/A'),
                reversedLookupItems.yesNoNones,
                'N/A',
              ),
              'Superior Biodegradation': getSingleSelectValue(
                getColumnValue(row, 'Superior Biodegradation', 'N/A'),
                reversedLookupItems.yesNoNones,
                'N/A',
              ),
              'EP Additives': getBoolValue(
                getColumnValue(row, 'EP Additives', 'No'),
                reversedLookupItems.boolValues,
                false,
              ),
              'Multi Metals Suitable': getSingleSelectValue(
                getColumnValue(row, 'Multi Metals Suitable', 'N/A'),
                reversedLookupItems.yesNoNones,
                'N/A',
              ),
              'Formulated without Silicate': getSingleSelectValue(
                getColumnValue(row, 'Formulated without Silicate', 'N/A'),
                reversedLookupItems.yesNoNones,
                'N/A',
              ),
              'Formulated without Chlorinated Paraffin': getSingleSelectValue(
                getColumnValue(
                  row,
                  'Formulated without Chlorinated Paraffin',
                  'N/A',
                ),
                reversedLookupItems.yesNoNones,
                'N/A',
              ),
              'Formulated without Boron': getSingleSelectValue(
                getColumnValue(row, 'Formulated without Boron', 'N/A'),
                reversedLookupItems.yesNoNones,
                'N/A',
              ),
              'Formulated without Heavy Metal': getSingleSelectValue(
                getColumnValue(row, 'Formulated without Heavy Metal', 'N/A'),
                reversedLookupItems.yesNoNones,
                'N/A',
              ),
              Partial: getBoolValue(
                getColumnValue(row, 'Partial', 'No'),
                reversedLookupItems.boolValues,
                false,
              ),
            };
          });
        handleDataChange(newData);
      };
      if (rABS) reader.readAsBinaryString(file);
      else reader.readAsArrayBuffer(file);
    }
  };

  const steps = useMemo(
    () => [
      intl.formatMessage({ id: 'upload-data' }),
      intl.formatMessage({ id: 'validate-data' }),
      intl.formatMessage({ id: 'save-data' }),
    ],
    [],
  );

  const breadcrumbLinks: PreviousLink[] = [
    {
      name: 'Home',
      to: '/home',
    },
    {
      name: intl.formatMessage({
        id: 'header-add-multiple-products',
      }),
    },
  ];

  /* istanbul ignore next */
  const descriptionValues = {
    a: (value: string[]) => (
      <a
        onClick={() => {
          if (value.includes('LINK_1')) {
            downloadExistingBulkProducts();
          } else if (value.includes('LINK_2')) {
            downloadProductsTemplate();
          }
        }}
      >
        {value.includes('LINK_1') && (
          <FormattedMessage id={'multiple-products-info-card-link-1'} />
        )}
        {value.includes('LINK_2') && (
          <FormattedMessage id={'multiple-products-info-card-link-2'} />
        )}
      </a>
    ),
    b: (value: string[]) => <b>{value}</b>,
    p: (value: string[]) => <p>{value}</p>,
  };

  const timerHandle = useRef<number | null>(null);

  useEffect(() => {
    return () => {
      if (timerHandle.current) {
        clearTimeout(timerHandle.current);
      }
    };
  }, []);

  /* istanbul ignore next */
  const completeProcess = () => {
    setIsSaving(true);
    const isAllSelected = (value: number[]) =>
      value.length === 1 && value.includes(-1);
    const payload: SaveRequestBody = {
      items: data.map((row: any) => ({
        id: row['ID'] ? getNumberValue(row['ID']) : null,
        isPartial: row['Partial'],
        externalId: row['Fusion Code'],
        name: row['Product Name'],
        friendlyUrl: row['Friendly Url'],
        masterBrandId: row['Master Brand'],
        familyId: row['Product Family'],
        rangeId: row['Product Range'] || null,
        subCategoryId: row['Sub-category'] || null,
        tagLine: row['Product Tag line'],
        description: row['Product Description'] || null,
        repositoryBusiness: row['Repository Business'] || null,
        castrolRecommended: !!row['Castrol Recommended'],
        productSectors: isAllSelected(row['Sector'])
          ? sectors.map((sec) => ({
              sectorId: sec.id,
            }))
          : row['Sector'].map((sectorId: number) => ({
              sectorId,
            })),
        productSubSectors: isAllSelected(row['Sub Sector'])
          ? getSubSectorOptions(row['Sector'], subSectors).map((subSec) => ({
              subSectorId: subSec.value,
            }))
          : row['Sub Sector'].map((subSectorId: number) => ({
              subSectorId,
            })),
        productApplications: isAllSelected(row['Application'])
          ? applications.map((app) => ({ applicationId: app.id }))
          : row['Application'].map((applicationId: number) => ({
              applicationId,
            })),
        productSubApplications: isAllSelected(row['Sub-application'])
          ? getSubApplicationOptions(row['Application'], subApplications).map(
              (subApp) => ({
                subApplicationId: subApp.value,
              }),
            )
          : row['Sub-application'].map((subApplicationId: number) => ({
              subApplicationId,
            })),
        productProductTypes: isAllSelected(row['Product Type'])
          ? productTypes.map((productType) => ({
              productTypeId: productType.id,
            }))
          : row['Product Type'].map((productTypeId: number) => ({
              productTypeId,
            })),
        productCategories: isAllSelected(row['Category'])
          ? categories.map((cat) => ({
              categoryId: cat.id,
            }))
          : row['Category'].map((categoryId: number) => ({
              categoryId,
            })),
        productAvailableCountries: isAllSelected(row['Country Availability'])
          ? countries.map((country) => ({
              countryId: country.id,
            }))
          : row['Country Availability'].map((countryId: number) => ({
              countryId,
            })),
        productCharacteristic: {
          viscosity:
            row['Viscosity']?.trim().length > 0
              ? getNumberArrayValue(row['Viscosity'] || '')
              : [],
          viscosityIndexId: row['Viscosity Index'],
          minTemperatureC: getNumberValue(row['Min Temperature C'] || ''),
          maxTemperatureC: getNumberValue(row['Max Temperature C'] || ''),
          grade: row['Grade'],
          subGrade: row['Sub Grade'] || null,
          esterEpAdditives: row['EP Additives'],
          mineralOilId: row['Mineral Oil'],
          corrosionProtectionId: row['Corrosion Protection'],
          concentrationRangeId: row['Concentration Range'],
          highFlashPointId: row['High Flash Point'],
          characteristicId: row['Characteristic'],
          customerOfferId: row['Customer Offer'],
          foodGradeId: row['Food Grade'],
          superiorBiodegradationId: row['Superior Biodegradation'],
          multiMetalsSuitableId: row['Multi Metals Suitable'],
          formulatedWithoutSilicateId: row['Formulated without Silicate'],
          formulatedWithoutChlorinatedParaffinId:
            row['Formulated without Chlorinated Paraffin'],
          formulatedWithoutBoronId: row['Formulated without Boron'],
          formulatedWithoutHeavyMetalId: row['Formulated without Heavy Metal'],
          productCharacteristicThickeners: [
            ...(isAllSelected(row['Thickener Gen'])
              ? thickenerGenerics.map((thickener) => thickener.id)
              : row['Thickener Gen']),
            ...(isAllSelected(row['Thickener Spec'])
              ? thickenerSpecifics.map((thickener) => thickener.id)
              : row['Thickener Spec']),
          ].map((thickenerId: number) => ({ thickenerId })),
          productCharacteristicBaseOilTypes: [
            ...(isAllSelected(row['Base Oil Type'])
              ? baseOilTypeGenerics.map((baseOilType) => baseOilType.id)
              : row['Base Oil Type']),
            ...(isAllSelected(row['Base Oil Type Spec'])
              ? baseOilTypeSpecifics.map((baseOilType) => baseOilType.id)
              : row['Base Oil Type Spec']),
          ].map((baseOilTypeId: number) => ({
            baseOilTypeId,
          })),
          productCharacteristicNlgis: isAllSelected(row['NLGI'])
            ? nlgis.map((nlgi) => ({
                nlgiId: nlgi.id,
              }))
            : row['NLGI'].map((nlgiId: number) => ({
                nlgiId,
              })),
          productCharacteristicMetalTypes: isAllSelected(row['Metal type'])
            ? metalTypes.map((metalType) => ({
                metalTypeId: metalType.id,
              }))
            : row['Metal type'].map((metalTypeId: number) => ({
                metalTypeId,
              })),
        },
      })),
    };
    setBackendErrors([]);
    saveBulkProducts(payload)
      .then(() => {
        setIsSaving(false);
        timerHandle.current = window.setTimeout(() => navigate('/'), 3000);
      })
      .catch((err) => {
        setBackendErrors(err?.response?.data?.errors);
        setCurrentStep(1);
        dispatch(
          addToast({
            type: 'error',
            title: getTranslatedMessage('bulk-upload-multiple-products-error'),
            message: '',
          }),
        );
      });
  };

  /* istanbul ignore next */
  const stepsComponents = [
    <UploadData
      submit={() => {
        setCurrentStep(1);
        parseAndValidate(file);
      }}
      setFile={setFile}
    />,
    <ValidateData
      save={() => {
        setCurrentStep(2);
        completeProcess();
      }}
      cancel={() => {
        setData([]);
        setErrorCount(0);
        setBackendErrors([]);
        setSheetValidationResult(undefined);
        setCurrentStep(0);
      }}
      data={data}
      setData={setData}
      columns={columns}
      tableRowClassName={styles.tableRow}
      errorCount={errorCount}
      backendErrors={backendErrors}
      validationErrors={sheetValidationResult}
    />,
    <SaveData isLoading={isSaving} />,
  ];

  return (
    <div className={styles.container}>
      <Header
        breadcrumbLinks={breadcrumbLinks}
        titleId={'header-add-multiple-products'}
      />
      {loading ? (
        <LoadingSpinner className={styles.loading} />
      ) : (
        <Card>
          <>
            <p className={styles.para}>
              <FormattedMessage id={'manage-multiple-products-intro-para'} />
            </p>
            <div className={styles.divider} />
            <div className={styles.twoColumnGrid}>
              <InfoCard
                heading={
                  <FormattedMessage
                    id="manage-multiple-products-info-card-heading-1"
                    values={descriptionValues}
                  />
                }
                body={
                  <FormattedMessage
                    id="manage-multiple-products-info-card-body-1"
                    values={descriptionValues}
                  />
                }
              />
              <InfoCard
                heading={
                  <FormattedMessage
                    id="manage-multiple-products-info-card-heading-2"
                    values={descriptionValues}
                  />
                }
                body={
                  <FormattedMessage
                    id="manage-multiple-products-info-card-body-2"
                    values={descriptionValues}
                  />
                }
              />
            </div>
            <HorizontalStepper steps={steps} currentStep={currentStep} />
            <MobileStepper
              steps={steps}
              currentStep={currentStep}
              stepsComponents={stepsComponents}
            />
            <div className={styles.desktopOnly}>
              {stepsComponents.map((stepComponent, index) =>
                index === currentStep ? (
                  <Fragment key={index}>{stepComponent}</Fragment>
                ) : null,
              )}
            </div>
          </>
        </Card>
      )}
    </div>
  );
}

export default withAdmin(ManageMultipleProductsPage);
