import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import styles from './AddEditLinkPage.module.scss';
import Header from '../../../../../components/Admin/Header/Header';
import NavigationHeader from '../../../../../components/common/NavigationHeader/NavigationHeader';
import { PreviousLink } from '../../../../../models/previous-link';
import Button from '../../../../../components/common/Button/Button';
import InputRow from '../../../../../components/Admin/InputRow/InputRow';
import SelectFormRow from '../../../../../components/Admin/SelectFormRow/SelectFormRow';
import TextareaRow from '../../../../../components/Admin/TextareaRow/TextareaRow';

import { showGenericModal } from '../../../../../features/genericModalSlice';
import { hideLoading, showLoading } from '../../../../../features/loadingSlice';
import { addToast } from '../../../../../features/toastSlice';
import { RootStore } from '../../../../../store';
import {
  creatLink,
  getLink,
  updateLink,
} from '../../../../../services/LinkService';
import { LinkResponse } from '../../../../../models/link-response';
import { validateURL } from '../../../../../utils/common.util';

export default function AddEditLinkPage() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { linkId } = useParams();
  const location = useLocation();

  const [linkName, setLinkName] = useState('');
  const [targetUrl, setTargetUrl] = useState('');
  const [sectorId, setSectorId] = useState('');
  const [description, setDescription] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [existingLink, setExistingLink] = useState<LinkResponse>({
    id: 0,
    name: '',
    sectorId: 0,
    targetUrl: '',
    description: '',
  });

  const { sectors } = useSelector((state: RootStore) => state.lookups);

  const sectorOptions = useMemo(() => {
    return sectors.map((item) => ({
      name: item.name,
      value: item.id.toString(),
    }));
  }, []);

  useEffect(() => {
    if (location.pathname?.endsWith('/edit')) {
      setIsEdit(true);
    }
  }, [linkId, location.pathname]);

  useEffect(() => {
    if (linkId) {
      dispatch(showLoading());
      getLink(+linkId)
        .then((res) => {
          setExistingLink(res);
          setLinkName(res.name);
          setTargetUrl(res.targetUrl);
          setSectorId(String(res.sectorId));
          setDescription(res.description);
        })
        .catch(() => {
          dispatch(
            addToast({
              type: 'error',
              title: intl.formatMessage({ id: 'help-load-failure' }),
              message: '',
            }),
          );
        })
        .finally(() => dispatch(hideLoading()));
    }
  }, [linkId]);

  const breadcrumbLinks: PreviousLink[] = useMemo(
    () => [
      {
        name: intl.formatMessage({ id: 'breadcrumb-home' }),
        to: '/home',
      },
      {
        name: intl.formatMessage({ id: 'header-menu-admin-manage-links' }),
        to: '/admin/links',
      },
      {
        name: intl.formatMessage({
          id: isEdit ? 'update-link' : 'create-new-link',
        }),
      },
    ],
    [intl, isEdit],
  );

  const updateClicked = () => {
    if (existingLink.id) {
      dispatch(showLoading());
      updateLink(existingLink.id, {
        name: linkName,
        sectorId: +sectorId,
        targetUrl: targetUrl,
        description: description,
      })
        .then(() => {
          dispatch(
            showGenericModal({
              titleId: 'update-link-success',
              messageId: 'update-link-success-info',
              to: `/admin/links`,
            }),
          );
        })
        .catch(() => {
          dispatch(
            addToast({
              type: 'error',
              title: intl.formatMessage({ id: 'link-update-failure' }),
              message: '',
            }),
          );
        })
        .finally(() => dispatch(hideLoading()));
    }
  };

  const saveClicked = async () => {
    dispatch(showLoading());
    creatLink({
      name: linkName,
      sectorId: +sectorId,
      targetUrl: targetUrl,
      description: description,
    })
      .then(() => {
        dispatch(
          showGenericModal({
            titleId: 'create-new-link-success',
            messageId: 'create-new-link-success-info',
            to: `/admin/links`,
          }),
        );
      })
      .catch(() => {
        dispatch(
          addToast({
            type: 'error',
            title: intl.formatMessage({ id: 'link-create-failure' }),
            message: '',
          }),
        );
      })
      .finally(() => dispatch(hideLoading()));
  };

  const cancelClicked = () => {
    let equalFlag = linkName === existingLink.name;
    equalFlag = equalFlag && targetUrl === existingLink.targetUrl;
    equalFlag =
      equalFlag &&
      (sectorId === '' || sectorId === String(existingLink.sectorId));
    equalFlag = equalFlag && description === existingLink.description;
    if (equalFlag) {
      navigate('/admin/links');
    } else {
      dispatch(
        showGenericModal({
          type: 'confirmation',
          titleId: 'oem-model-unsaved-changes',
          messageId: 'oem-model-unsaved-changes-desc',
          to: '/admin/links',
        }),
      );
    }
  };

  const isValid = () => {
    if (
      isEmpty(linkName.trim()) ||
      validateURL(targetUrl.trim()) ||
      isEmpty(sectorId) ||
      isEmpty(description.trim()) ||
      /^<p>(<br>)*\s*<\/p>$/.test(description.trim())
    ) {
      return false;
    }

    return true;
  };

  return (
    <>
      <div className={styles.desktopOnly}>
        <Header
          titleId={isEdit ? 'update-link' : 'create-new-link'}
          breadcrumbLinks={breadcrumbLinks}
        />
      </div>
      <div className={styles.phoneOnly}>
        <NavigationHeader breadcrumbLinks={breadcrumbLinks} />
        <div className={styles.newHelpTitle}>
          <FormattedMessage id={isEdit ? 'update-link' : 'create-new-link'} />
        </div>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.formWrapper}>
          <div className={styles.sectionHeader}>
            <FormattedMessage id={'details-label'} />
          </div>
          <div className={'columnRow'}>
            <InputRow
              labelId={'title-label'}
              value={linkName}
              required={true}
              onChange={setLinkName}
              tooltip={intl.formatMessage({ id: 'title-label' })}
            />
          </div>
          <div className={'columnRow'}>
            <InputRow
              labelId={'target-url-properties-label'}
              value={targetUrl}
              onChange={setTargetUrl}
              tooltip={intl.formatMessage({
                id: 'target-url-properties-label',
              })}
              customValidator={(value: string) => validateURL(value)}
            />
          </div>
          <div className={'columnRow'}>
            <SelectFormRow
              labelId={'sector-label'}
              tooltip={intl.formatMessage({ id: 'sector-label' })}
              options={sectorOptions}
              required={true}
              value={sectorId}
              onChange={setSectorId}
            />
          </div>
          <div className={'columnRow'}>
            <TextareaRow
              labelId={'description-label'}
              required={true}
              value={description}
              className={styles.textareaRow}
              onChange={setDescription}
              placeholder={intl.formatMessage({
                id: 'input-placeholder',
              })}
              tooltip={intl.formatMessage({ id: 'description-label' })}
            />
          </div>
        </div>
        <div className={styles.actionRow}>
          <Button
            onClick={cancelClicked}
            color={'green-outline'}
            className={styles.cancelButton}
          >
            <FormattedMessage id={'cancel-label'} />
          </Button>

          {isEdit ? (
            <Button
              onClick={updateClicked}
              color={'green'}
              disabled={!isValid()}
              className={styles.updateButton}
            >
              <FormattedMessage id={'update-label'} />
            </Button>
          ) : (
            <Button
              onClick={saveClicked}
              color={'green'}
              disabled={!isValid()}
              className={styles.saveButton}
            >
              <FormattedMessage id={'save-label'} />
            </Button>
          )}
        </div>
      </div>
    </>
  );
}
