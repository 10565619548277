import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useIntl, FormattedMessage } from 'react-intl';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { uniqBy } from 'lodash';
import cn from 'classnames';

import { PreviousLink } from '../../../models/previous-link';
import { Announcement } from '../../../models/announcement';
import NavigationHeader from '../../../components/common/NavigationHeader/NavigationHeader';
import ContainerForDrawer from '../../../components/common/ContainerForDrawer/ContainerForDrawer';
import LoadingSpinner from '../../../components/common/LoadingSpinner/LoadingSpinner';
import AnnouncementSlider from '../../../components/HomePage/Banner/AnnouncementSlider/AnnouncementSlider';
import { getDateString, getDateFormatString } from '../../../utils/date.util';
import { showApprovalDocName } from '../../../utils/approval-document.util';
import styles from './ApprovalDocumentPage.module.scss';

import Button from '../../../components/common/Button/Button';
import UploadDocumentModal from './components/UploadDocumentModal/UploadDocumentModal';
import {
  getApprovalDocumentDetail,
  downloadOemApprovalDocument,
  uploadNewApprovalDoc,
} from '../../../services/OemService';
import { getAnnouncements } from '../../../services/AnnouncementService';
import { uploadDocument } from '../../../services/DocumentService';
import { ApprovalDocumentDetail } from '../../../models/approval-document-detail';
import { ReactComponent as WorkHourIcon } from '../../../icons/work-hour.svg';
import { ReactComponent as DownloadIcon } from '../../../icons/download.svg';
import { addToast } from '../../../features/toastSlice';
import { showGenericModal } from '../../../features/genericModalSlice';
import withAdmin from '../../../hoc/withAdmin';
import { getUserInfoFromLocalStorage } from '../../../services/LocalStorageService';
/**
 * Approval Document page
 */
function ApprovalDocumentPage() {
  // init hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { oemApprovalId } = useParams();
  const [query] = useSearchParams();
  const intl = useIntl();
  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });
  const oemId = query.get('oemId') || '0';

  // init states.
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [showUploadDocumentModal, setShowUploadDocumentModal] = useState(false);
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);
  const [oemEdit, setoemEdit] = useState(false);
  const [approvalDetail, setApprovalDetail] =
    useState<ApprovalDocumentDetail>();
  const [breadcrumbLinks, setBreadcrumbLinks] = useState<PreviousLink[]>([
    {
      name: 'Home',
      to: '/home',
    },
  ]);

  const oems = uniqBy(
    approvalDetail?.oemProductApprovals.map((item) => item.oem),
    'id',
  );

  //effects

  /**
   * Load data by oemId.
   */
  useEffect(() => {
    const userInfo = getUserInfoFromLocalStorage();
    if (userInfo) {
      if (userInfo.role === 'Super Admin' || userInfo.role === 'Approver') {
        setoemEdit(true);
      }
    }
    if (oemApprovalId) {
      setLoading(true);

      Promise.all([
        getAnnouncements(),
        getApprovalDocumentDetail(oemApprovalId, oemId),
      ])
        .then((res) => {
          const announcementTmp = res[0].filter(
            (item) => item.announcementTypeId === 3,
          );
          setAnnouncements(announcementTmp);
          setApprovalDetail(res[1]);
          setBreadcrumbLinks([
            {
              name: 'Home',
              to: '/home',
            },
            {
              name: res[1].linkName,
            },
          ]);
        })
        .catch(() => {
          dispatch(
            addToast({
              type: 'error',
              title: getTranslatedMessage('approval-get-failure'),
              message: '',
            }),
          );
        })
        .finally(() => setLoading(false));
      // getApprovalDocumentDetail(oemApprovalId)
      //   .then((res) => {
      //     setApprovalDetail(res);
      //     setBreadcrumbLinks([
      //       {
      //         name: 'Home',
      //         to: '/home',
      //       },
      //       {
      //         name: res.linkName,
      //       },
      //     ]);
      //   })
      //   .catch(() => {
      //     dispatch(
      //       addToast({
      //         type: 'error',
      //         title: getTranslatedMessage('approval-get-failure'),
      //         message: '',
      //       }),
      //     );
      //   })
      //   .finally(() => setLoading(false));
    }
  }, [oemApprovalId, dispatch]);

  const downloadApprovalDoc = (id: string): void => {
    if (id) {
      setProcessing(true);
      downloadOemApprovalDocument(id)
        .then(() => {
          console.log('download api success');
        })
        .catch(() => {
          dispatch(
            addToast({
              type: 'error',
              title: getTranslatedMessage('approval-doc-download-failure'),
              message: '',
            }),
          );
        })
        .finally(() => {
          setProcessing(false);
        });
    }
  };

  const uploadNewDoc = (issueDate: Date, document: File) => {
    if (oemApprovalId) {
      const formData = new FormData();
      formData.append('type', 'Approval');
      formData.append('file', document);

      setProcessing(true);
      uploadDocument(formData)
        .then((docRes) => {
          uploadNewApprovalDoc(
            oemApprovalId,
            getDateFormatString(issueDate),
            docRes.id,
            oemId,
          )
            .then((approvalDetailRes) => {
              dispatch(
                showGenericModal({
                  titleId: 'upload-document-label',
                  messageId: 'upload-document-success',
                  to: `/admin/approval-document/${approvalDetailRes.id}?oemId=${oemId}`,
                }),
              );
            })
            .catch(() => {
              dispatch(
                addToast({
                  type: 'error',
                  title: getTranslatedMessage('upload-document-failure'),
                  message: '',
                }),
              );
            })
            .finally(() => {
              setProcessing(false);
              setShowUploadDocumentModal(false);
            });
        })
        .catch(() => {
          setProcessing(false);
          setShowUploadDocumentModal(false);
          dispatch(
            addToast({
              type: 'error',
              title: getTranslatedMessage('upload-document-failure'),
              message: '',
            }),
          );
        });
    }
  };

  return (
    <div>
      <NavigationHeader breadcrumbLinks={breadcrumbLinks} />
      <ContainerForDrawer>
        {loading ? (
          <LoadingSpinner className={styles.loading} />
        ) : (
          <div className={styles.baseContainer}>
            {announcements && announcements.length > 0 && (
              <AnnouncementSlider
                className={styles.announcements}
                announcements={announcements}
              />
            )}
            <div
              className={cn(
                styles.oemApprovalPageContainer,
                approvalDetail?.otherVersions?.length === 0 &&
                  styles.noOtherVersions,
              )}
            >
              <div className={styles.mainContent}>
                <div className={styles.leftContent}>
                  <div className={styles.titlePart}>
                    <div>
                      <div className={styles.approvalName}>
                        {showApprovalDocName(approvalDetail)}
                      </div>
                      <div className={styles.title}>
                        <FormattedMessage
                          id={'approval-document-page-field-approval-doc-info'}
                        />
                      </div>
                    </div>
                    {oemEdit && (
                      <Button
                        color={'green'}
                        className={cn(
                          styles.uploadButton,
                          styles.mobile,
                          approvalDetail?.otherVersions?.length === 0 &&
                            styles.noOtherVersions,
                        )}
                        onClick={() => setShowUploadDocumentModal(true)}
                      >
                        <FormattedMessage id={'upload-label'} />
                      </Button>
                    )}
                  </div>
                  <div className={styles.dateAndDownloadRow}>
                    <div className={styles.itemField}>
                      <WorkHourIcon />
                      <div className={styles.fieldContent}>
                        <div className={styles.fieldLabel}>
                          <FormattedMessage id={'oem-table-field-issue-date'} />
                        </div>
                        <div className={styles.fieldValue}>
                          {getDateString(approvalDetail?.issuedAt)}
                        </div>
                      </div>
                    </div>
                    <div
                      className={styles.download}
                      onClick={() =>
                        downloadApprovalDoc(oemApprovalId as string)
                      }
                    >
                      <DownloadIcon />
                      <FormattedMessage id={'download-label'} />
                    </div>
                  </div>

                  <div className={styles.descriptionRow}>
                    <FormattedMessage
                      id={'approval-document-page-description'}
                    />
                  </div>

                  <div className={styles.divider} />

                  <div className={styles.relatesContainer}>
                    <div className={styles.relatesLabel}>
                      <FormattedMessage
                        id={'approval-document-page-field-oems'}
                      />
                      <div className={styles.firstRowDivider} />
                    </div>

                    {oems &&
                      oems.map((item, index) => (
                        <div className={styles.relatesField} key={index}>
                          <Link
                            className={styles.link}
                            to={`/admin/oem/${item.id}`}
                          >
                            {item.name}
                          </Link>
                        </div>
                      ))}
                  </div>

                  <div className={styles.relatesContainer}>
                    <div className={styles.relatesLabel}>
                      <FormattedMessage
                        id={'approval-document-page-field-products'}
                      />
                      <div className={styles.firstRowDivider} />
                    </div>

                    <div className={styles.productListWrapper}>
                      {approvalDetail?.oemProductApprovals &&
                        approvalDetail.oemProductApprovals.map(
                          (item, index) => (
                            <div className={styles.relatesField} key={index}>
                              <Link
                                className={styles.link}
                                to={`/product/${item.product.friendlyUrl}`}
                              >
                                {item.product.name}
                              </Link>
                            </div>
                          ),
                        )}
                    </div>
                  </div>
                </div>

                {approvalDetail?.otherVersions &&
                  approvalDetail?.otherVersions.length > 0 && (
                    <div className={styles.otherVersionContent}>
                      {oemEdit && (
                        <Button
                          color={'green'}
                          className={cn(styles.uploadButton, styles.desktop)}
                          onClick={() => setShowUploadDocumentModal(true)}
                        >
                          <FormattedMessage id={'upload-label'} />
                        </Button>
                      )}

                      <div className={cn(styles.title, styles.otherVersion)}>
                        <FormattedMessage id={'other-versions-label'} />
                      </div>

                      <div className={styles.otherVersionsWrapper}>
                        {approvalDetail?.otherVersions.map((item) => (
                          <div
                            className={cn(
                              styles.dateAndDownloadRow,
                              styles.otherVersion,
                            )}
                            key={item.id}
                          >
                            <div className={styles.itemField}>
                              <WorkHourIcon />
                              <div className={styles.fieldContent}>
                                <div className={styles.fieldLabel}>
                                  <FormattedMessage
                                    id={'oem-table-field-issue-date'}
                                  />
                                </div>
                                <div className={styles.fieldValue}>
                                  {getDateString(item.issuedAt)}
                                </div>
                              </div>
                            </div>
                            <div
                              className={styles.download}
                              onClick={() => downloadApprovalDoc('' + item.id)}
                            >
                              <DownloadIcon />
                              <FormattedMessage id={'download-label'} />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
              </div>

              <div className={styles.actionRow}>
                <Button
                  color={'green-outline'}
                  className={styles.backButton}
                  onClick={() => navigate(-1)}
                >
                  <FormattedMessage id={'back-label'} />
                </Button>
              </div>
              {showUploadDocumentModal && (
                <UploadDocumentModal
                  onUpload={uploadNewDoc}
                  onClose={() => {
                    setShowUploadDocumentModal(false);
                  }}
                />
              )}
            </div>
          </div>
        )}
      </ContainerForDrawer>
      {processing && <LoadingSpinner className={'lookupsLoading'} />}
    </div>
  );
}

export default withAdmin(ApprovalDocumentPage);
