import { useIntl } from 'react-intl';

import Select from '../../common/Select/Select';
import FormRow from '../FormRow/FormRow';

import { SelectRowProps } from '../../../models/admin-form-types';

const SelectFormRow = ({
  labelId,
  value,
  options,
  onChange,
  placeholder,
  required,
  tooltip,
  disabled = false,
  className,
  selectInputBool,
}: SelectRowProps) => {
  const intl = useIntl();
  placeholder = placeholder || intl.formatMessage({ id: 'select-placeholder' });

  return (
    <FormRow
      labelId={labelId}
      required={required}
      tooltip={tooltip}
      className={className}
    >
      <Select
        className={selectInputBool ? 'selectInputRow' : 'selectControl'}
        value={value}
        options={options}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
        canSearch={true}
      />
    </FormRow>
  );
};

export default SelectFormRow;
