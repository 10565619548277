import { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { cloneDeep } from 'lodash';
import cn from 'classnames';
import styles from './EditUserPage.module.scss';

import { RootStore } from '../../../store';
import { addToast } from '../../../features/toastSlice';
import { showGenericModal } from '../../../features/genericModalSlice';
import { getUser, updateUser, saveUser } from '../../../services/UserService';
import { UserResponse } from '../../../models/user-response';
import { CountryItem } from '../../../models/lookups';
import { userRolesNoAnonymous } from '../../../configs/admin-constants';
import withAdmin from '../../../hoc/withAdmin';
import {
  emailValidator,
  castrolEmailValidator,
} from '../../../utils/string.util';

import { PreviousLink } from '../../../models/previous-link';
import NavigationHeader from '../../../components/common/NavigationHeader/NavigationHeader';
import ContainerForDrawer from '../../../components/common/ContainerForDrawer/ContainerForDrawer';
import LoadingSpinner from '../../../components/common/LoadingSpinner/LoadingSpinner';
import Button from '../../../components/common/Button/Button';
import InputRow from '../../../components/Admin/InputRow/InputRow';
import SelectFormRow from '../../../components/Admin/SelectFormRow/SelectFormRow';
import FormRow from '../../../components/Admin/FormRow/FormRow';
import CheckboxTree from '../../../components/common/CheckboxTree/CheckboxTree';
import RadioGroup from '../../../components/Admin/RadioGroup/RadioGroup';

/**
 * Edit user page
 */
function EditUserPage() {
  // init hooks
  const intl = useIntl();
  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });
  const { userId } = useParams();
  const isAddPage = !userId;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState<boolean>(false);

  const [userResponse, setUserResponse] = useState<UserResponse>();
  const breadcrumbLinks: PreviousLink[] = useMemo(() => {
    return [
      {
        name: getTranslatedMessage('breadcrumb-home'),
        to: '/home',
      },
      {
        name: getTranslatedMessage('header-menu-manage-users'),
        to: '/admin/users',
      },
      {
        name: getTranslatedMessage(
          isAddPage ? 'header-menu-add-user' : 'menu-edit-user',
        ),
      },
    ];
  }, [isAddPage]);

  const { countries, languages, regions } = useSelector(
    (rootState: RootStore) => rootState.lookups,
  );

  const countryTree = useMemo(() => {
    const countriesByRegion = new Map<number, CountryItem[]>();
    countries.forEach((country) => {
      if (!countriesByRegion.has(country.regionId)) {
        countriesByRegion.set(country.regionId, []);
      }
      countriesByRegion.get(country.regionId)?.push(country);
    });
    return regions.map((region) => ({
      value: region.id,
      label: region.name,
      children: (countriesByRegion.get(region.id) || []).map((country) => ({
        value: country.id,
        label: country.name,
      })),
    }));
  }, [countries, regions]);

  const countryOptions = useMemo(() => {
    return (
      countries?.map((item) => ({
        name: item.name,
        value: item.id,
      })) || []
    );
  }, [countries]);

  const languageOptions = useMemo(() => {
    return (
      languages?.map((item) => ({
        name: item.name,
        value: item.id,
      })) || []
    );
  }, [languages]);

  const roleOptions = useMemo(() => {
    return userRolesNoAnonymous.map((item) => ({
      name: item,
      value: item,
    }));
  }, [userRolesNoAnonymous]);

  const visibilityOptions = useMemo(() => {
    return [
      {
        label: getTranslatedMessage('active-label'),
        value: true,
      },
      {
        label: getTranslatedMessage('inactive-label'),
        value: false,
      },
    ];
  }, []);

  const countryVisibilityOptions = useMemo(() => {
    return [
      {
        label: getTranslatedMessage('global-label'),
        value: false,
      },
      {
        label: getTranslatedMessage('country-specific-label'),
        value: true,
      },
    ];
  }, []);

  /**
   * Load data by userId.
   */
  useEffect(() => {
    if (userId && !isAddPage) {
      setLoading(true);

      getUser(userId)
        .then((res) => {
          setUserResponse(res);
        })
        .catch(() => {
          dispatch(
            addToast({
              type: 'error',
              title: intl.formatMessage({ id: 'oem-get-failure' }),
              message: '',
            }),
          );
        })
        .finally(() => setLoading(false));
    } else {
      setUserResponse({
        id: 0,
        firstName: '',
        lastName: '',
        email: '',
        companyName: '',
        phoneNumber: '',
        countryId: 0,
        languageId: 0,
        role: '',
        castrolSponsorName: '',
        castrolSponsorEmail: '',
        active: null as any,
        displayOnlyProductsAvailableInUserCountries: null as any,
        userCountries: [],
        externalId: '',
        lastLoginAt: '',
      });
    }
  }, [userId, dispatch]);

  const cancelClicked = () => {
    navigate('/admin/users');
  };

  const getUserData = (values: UserResponse) => {
    return {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      companyName: values.companyName,
      phoneNumber: values.phoneNumber,
      countryId: values.countryId,
      languageId: values.languageId,
      role: values.role,
      castrolSponsorName: values.castrolSponsorName,
      castrolSponsorEmail: values.castrolSponsorEmail,
      active: values.active,
      displayOnlyProductsAvailableInUserCountries:
        values.displayOnlyProductsAvailableInUserCountries,
      userCountries: values.userCountries,
    };
  };

  const updateClicked = () => {
    if (userId && userResponse) {
      setProcessing(true);
      const data = getUserData(userResponse);
      updateUser(userId, data)
        .then(() => {
          dispatch(
            showGenericModal({
              titleId: 'user-edit-modal-success',
              messageId: 'user-edit-modal-success-info',
              to: `/admin/users`,
            }),
          );
        })
        .catch(() => {
          dispatch(
            addToast({
              type: 'error',
              title: getTranslatedMessage('user-update-failure'),
              message: '',
            }),
          );
        })
        .finally(() => {
          setProcessing(false);
        });
    }
  };

  const saveClicked = () => {
    if (userResponse) {
      setProcessing(true);
      const data = getUserData(userResponse);
      saveUser(data)
        .then(() => {
          dispatch(
            showGenericModal({
              titleId: 'user-edit-modal-add-success',
              messageId: 'user-edit-modal-add-success-info',
              to: `/admin/users`,
            }),
          );
        })
        .catch(() => {
          dispatch(
            addToast({
              type: 'error',
              title: getTranslatedMessage('user-save-failure'),
              message: '',
            }),
          );
        })
        .finally(() => {
          setProcessing(false);
        });
    }
  };

  const handleChange = (key: keyof UserResponse, value: any) => {
    const tmp: any = cloneDeep(userResponse) || {};
    tmp[key] = value;
    setUserResponse(tmp);
  };

  const descriptionValues = {
    br: <br />,
  };

  const allowUpdate = () => {
    return (
      userResponse?.firstName &&
      userResponse.firstName.trim().length > 0 &&
      userResponse?.lastName &&
      userResponse.lastName.trim().length > 0 &&
      userResponse?.companyName &&
      userResponse.companyName.trim().length > 0 &&
      userResponse?.email &&
      !emailValidator(userResponse.email) &&
      userResponse?.countryId &&
      userResponse?.languageId &&
      userResponse?.role &&
      userResponse?.castrolSponsorName &&
      userResponse.castrolSponsorName.trim().length > 0 &&
      userResponse?.castrolSponsorEmail &&
      !castrolEmailValidator(userResponse.castrolSponsorEmail) &&
      userResponse?.active !== null &&
      userResponse?.displayOnlyProductsAvailableInUserCountries !== null &&
      (!userResponse.displayOnlyProductsAvailableInUserCountries ||
        (userResponse.userCountries && userResponse.userCountries.length > 0))
    );
  };

  return (
    <div>
      <NavigationHeader breadcrumbLinks={breadcrumbLinks} />
      <ContainerForDrawer>
        {loading ? (
          <LoadingSpinner className={styles.loading} />
        ) : (
          <div className={styles.userPageContainer}>
            <div className={styles.title}>
              {isAddPage ? (
                <FormattedMessage id={'header-menu-add-user'} />
              ) : (
                <FormattedMessage id={'menu-edit-user'} />
              )}
            </div>
            <div className={styles.contentContainer}>
              <div className={styles.userDetailContainer}>
                <div className={styles.fieldTitle}>
                  {isAddPage ? (
                    <FormattedMessage id={'header-menu-add-user'} />
                  ) : (
                    <FormattedMessage id={'menu-edit-user'} />
                  )}
                </div>
                <div className={styles.userFieldsContainer}>
                  <div className={styles.partTitle}>
                    <FormattedMessage id={'personal-information-label'} />
                  </div>
                  <div className={styles.description}>
                    <FormattedMessage id={'personal-information-desc'} />
                  </div>

                  <div className={'columnRow'}>
                    <InputRow
                      labelId={'first-name-label'}
                      value={userResponse?.firstName}
                      required={true}
                      onChange={(value: string) =>
                        handleChange('firstName', value)
                      }
                      tooltip={getTranslatedMessage('first-name-tooltip')}
                    />
                    <InputRow
                      labelId={'last-name-label'}
                      value={userResponse?.lastName}
                      required={true}
                      onChange={(value: string) =>
                        handleChange('lastName', value)
                      }
                      tooltip={getTranslatedMessage('last-name-tooltip')}
                    />
                  </div>

                  <div className={'columnRow'}>
                    <InputRow
                      labelId={'company-name-label'}
                      value={userResponse?.companyName}
                      required={true}
                      onChange={(value: string) =>
                        handleChange('companyName', value)
                      }
                      tooltip={getTranslatedMessage('company-name-tooltip')}
                    />
                  </div>

                  <div className={cn(styles.partTitle, styles.desktop)}>
                    <FormattedMessage id={'contact-information-label'} />
                  </div>

                  <div className={'columnRow'}>
                    <InputRow
                      labelId={'email-address-label'}
                      value={userResponse?.email}
                      required={true}
                      onChange={(value: string) => handleChange('email', value)}
                      customValidator={(value: string) => emailValidator(value)}
                      tooltip={getTranslatedMessage('email-address-2-tooltip')}
                    />
                    <SelectFormRow
                      labelId={'country-label'}
                      tooltip={getTranslatedMessage('country-tooltip')}
                      required={true}
                      options={countryOptions}
                      value={userResponse?.countryId}
                      onChange={(value: number) =>
                        handleChange('countryId', value)
                      }
                    />
                  </div>

                  <div className={'columnRow'}>
                    <InputRow
                      labelId={'telephone-number-label'}
                      value={userResponse?.phoneNumber}
                      required={false}
                      onChange={(value: string) =>
                        handleChange('phoneNumber', value)
                      }
                      tooltip={getTranslatedMessage('phone-number-2-tooltip')}
                    />
                    <SelectFormRow
                      labelId={'language-label'}
                      tooltip={getTranslatedMessage('language-tooltip')}
                      required={true}
                      options={languageOptions}
                      value={userResponse?.languageId}
                      onChange={(value: number) =>
                        handleChange('languageId', value)
                      }
                    />
                  </div>

                  <div className={cn(styles.partTitle, styles.desktop)}>
                    <FormattedMessage id={'additional-information-label'} />
                  </div>

                  <div className={'columnRow'}>
                    <SelectFormRow
                      labelId={'role-label'}
                      tooltip={getTranslatedMessage('user-role-tooltip')}
                      required={true}
                      options={roleOptions}
                      value={userResponse?.role}
                      onChange={(value) => handleChange('role', value)}
                    />
                  </div>

                  <div className={'columnRow'}>
                    <InputRow
                      labelId={'castrol-sponsor-name-label'}
                      value={userResponse?.castrolSponsorName}
                      required={true}
                      onChange={(value) =>
                        handleChange('castrolSponsorName', value)
                      }
                      tooltip={getTranslatedMessage(
                        'castrol-sponsor-name-tooltip',
                      )}
                    />
                    <InputRow
                      labelId={'castrol-sponsor-email-label'}
                      value={userResponse?.castrolSponsorEmail}
                      required={true}
                      onChange={(value) =>
                        handleChange('castrolSponsorEmail', value)
                      }
                      customValidator={(value: string) =>
                        castrolEmailValidator(value)
                      }
                      errorMessage={getTranslatedMessage(
                        'castrol-email-error-message',
                      )}
                      tooltip={getTranslatedMessage(
                        'castrol-sponsor-email-tooltip',
                      )}
                    />
                  </div>

                  <div className={'columnRow'}>
                    <RadioGroup
                      required={true}
                      labelId={'user-visibility-label'}
                      items={visibilityOptions}
                      selected={userResponse?.active}
                      onClick={(value: boolean) =>
                        handleChange('active', value)
                      }
                      tooltip={intl.formatMessage({
                        id: 'user-visibility-tooltip',
                      })}
                    />
                    <RadioGroup
                      required={true}
                      labelId={'user-country-visibility-label'}
                      items={countryVisibilityOptions}
                      selected={
                        userResponse?.displayOnlyProductsAvailableInUserCountries
                      }
                      onClick={(value: boolean) =>
                        handleChange(
                          'displayOnlyProductsAvailableInUserCountries',
                          value,
                        )
                      }
                      tooltip={intl.formatMessage({
                        id: 'user-country-visibility-tooltip',
                      })}
                    />
                    {userResponse?.displayOnlyProductsAvailableInUserCountries && (
                      <FormRow
                        labelId={'regions-countries-label'}
                        className={cn(
                          styles.countriesTreeForm,
                          'needMarginRight',
                        )}
                        required={true}
                        tooltip={
                          intl.formatMessage(
                            { id: 'regions-counties-tooltip' },
                            descriptionValues,
                          ) as string
                        }
                      >
                        <CheckboxTree
                          className={styles.countriesTree}
                          tree={countryTree}
                          selected={userResponse?.userCountries || []}
                          setSelected={(value) =>
                            handleChange('userCountries', value)
                          }
                        />
                      </FormRow>
                    )}
                  </div>
                </div>
              </div>

              <div className={styles.actionRow}>
                <Button
                  onClick={cancelClicked}
                  color={'green-outline'}
                  className={styles.cancelButton}
                >
                  <FormattedMessage id={'cancel-label'} />
                </Button>

                {isAddPage ? (
                  <Button
                    onClick={saveClicked}
                    color={'green'}
                    disabled={!allowUpdate()}
                    className={styles.saveButton}
                  >
                    <FormattedMessage id={'save-label'} />
                  </Button>
                ) : (
                  <Button
                    onClick={updateClicked}
                    color={'green'}
                    disabled={!allowUpdate()}
                    className={styles.updateButton}
                  >
                    <FormattedMessage id={'update-label'} />
                  </Button>
                )}
              </div>
            </div>
          </div>
        )}
      </ContainerForDrawer>
      {processing && <LoadingSpinner className={'lookupsLoading'} />}
    </div>
  );
}

export default withAdmin(EditUserPage, true);
