import { apiInstance } from '../api/requestInterceptor';
import {
  ManageFamilyRangeType,
  ManageRangeTranslationFormType,
  ManageRangeType,
} from '../models/admin-form-types';

/**
 * Get range by id
 */
async function getRangeById(rangeId: string): Promise<any> {
  const res = await apiInstance.get<any>(`/ranges/byId/${rangeId}`);

  return res.data;
}

/**
 * Add range
 */
async function addRange(
  range: ManageRangeType,
): Promise<ManageFamilyRangeType<ManageRangeTranslationFormType>> {
  const res = await apiInstance.post<any>('/ranges', range);

  return res.data;
}

/**
 * Add range
 */
async function updateRange(
  rangeId: string,
  range: ManageRangeType,
): Promise<ManageFamilyRangeType<ManageRangeTranslationFormType>> {
  const res = await apiInstance.put<any>(`/ranges/byId/${rangeId}`, range);

  return res.data;
}

export { getRangeById, addRange, updateRange };
