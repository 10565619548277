import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ContainerForDrawer from '../../../components/common/ContainerForDrawer/ContainerForDrawer';
import NavigationHeader from '../../../components/common/NavigationHeader/NavigationHeader';
import { PreviousLink } from '../../../models/previous-link';
import styles from './ManageBanners.module.scss';
import { Link } from 'react-router-dom';
import RightContainer from '../../../components/common/RightContainer/RightContainer';
import ManageBannerTable from './components/ManageBannerTable/ManageBannerTable';
import {
  activateBanner,
  deactivateBanner,
  deleteBanner,
  getBanners,
} from '../../../services/ManageBannerService';
import { Banner } from '../../../models/banner';
import { addToast } from '../../../features/toastSlice';
import { useDispatch } from 'react-redux';
import LoadingSpinner from '../../../components/common/LoadingSpinner/LoadingSpinner';
import { useNavigate } from 'react-router';
import _ from 'lodash';

export default function ManageBanners() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [banners, setBanners] = useState<Banner[]>([]);
  const [processing, setProcessing] = useState<boolean>(false);
  const [reload, setReload] = useState(0);

  // pagination
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  // sorting
  const [sortBy, setSortBy] = useState('name');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  const onSortChange = (column: string) => {
    if (column !== sortBy) {
      setSortBy(column);
      setSortDirection('asc');
      return;
    }
    setSortDirection((currDirection) =>
      currDirection === 'desc' ? 'asc' : 'desc',
    );
  };

  const breadcrumbLinks: PreviousLink[] = useMemo(
    () => [
      {
        name: intl.formatMessage({ id: 'breadcrumb-home' }),
        to: '/home',
      },
      {
        name: intl.formatMessage({ id: 'header-menu-admin-manage-banners' }),
      },
    ],
    [intl],
  );

  useEffect(() => {
    setLoading(true);
    getBanners({ page, perPage, sortBy, sortDirection })
      .then((res) => {
        setBanners(res.items);
        setTotal(res.total);
      })
      .catch(() => {
        dispatch(
          addToast({
            type: 'error',
            title: intl.formatMessage({ id: 'banners-load-failure' }),
            message: '',
          }),
        );
      })
      .finally(() => setLoading(false));
  }, [page, perPage, sortBy, sortDirection, reload]);

  const onUpdateSuccess = (messageId: string) => {
    dispatch(
      addToast({
        type: 'success',
        title: intl.formatMessage({ id: messageId }),
        message: '',
      }),
    );
  };

  const onUpdateFailure = (messageId: string) => {
    dispatch(
      addToast({
        type: 'error',
        title: intl.formatMessage({ id: messageId }),
        message: '',
      }),
    );
  };

  const setBannerActiveStatus = (bannerId: number, active: boolean) => {
    const newAnnouncements = _.cloneDeep(banners);
    const updatedAnnouncement = _.find(
      newAnnouncements,
      (item) => item.id === bannerId,
    );
    if (updatedAnnouncement) {
      updatedAnnouncement.active = active;
    }
    setBanners(newAnnouncements);
  };

  const onActionMenuClick = (actionType: string, banner: Banner) => {
    switch (actionType) {
      case 'edit-banner':
        navigate(`${banner.id}/edit`);
        break;
      case 'activate-banner':
        setProcessing(true);
        activateBanner(banner.id)
          .then(() => {
            setBannerActiveStatus(banner.id, true);
            onUpdateSuccess('banner-update-success');
          })
          .catch(() => onUpdateFailure('banner-update-failure'))
          .finally(() => setProcessing(false));
        break;
      case 'deactivate-banner':
        setProcessing(true);
        deactivateBanner(banner.id)
          .then(() => {
            setBannerActiveStatus(banner.id, false);
            onUpdateSuccess('banner-update-success');
          })
          .catch(() => onUpdateFailure('banner-update-failure'))
          .finally(() => setProcessing(false));
        break;
      case 'delete-banner':
        setProcessing(true);
        deleteBanner(banner.id)
          .then(() => {
            setReload((prev) => prev + 1);
            onUpdateSuccess('banner-delete-success');
          })
          .catch(() => onUpdateFailure('banner-delete-failure'))
          .finally(() => setProcessing(false));
        break;
    }
  };

  return (
    <>
      <NavigationHeader
        breadcrumbLinks={breadcrumbLinks}
        showBottomLine={true}
      />
      <ContainerForDrawer classes={styles.container}>
        <RightContainer>
          <div className={styles.pageHeader}>
            <h1 className={styles.title}>
              <FormattedMessage id={'header-menu-admin-manage-banners'} />
            </h1>
            <Link className={styles.link} to={`/admin/banners/add`}>
              <FormattedMessage id={'create-new-banner'} />
            </Link>
          </div>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <ManageBannerTable
              banners={banners}
              page={page}
              perPage={perPage}
              onPageChange={setPage}
              onPerPageChange={(val: number) => {
                setPerPage(val);
                setPage(1);
              }}
              total={total}
              sortBy={sortBy}
              sortDirection={sortDirection}
              onSortChange={onSortChange}
              onActionMenuClick={onActionMenuClick}
            />
          )}
        </RightContainer>
      </ContainerForDrawer>
      {processing && <LoadingSpinner className={'lookupsLoading'} />}
    </>
  );
}
