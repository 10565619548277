import styles from './PortStat.module.scss';
import IconField from '../../common/IconField/IconField';
import { useIntl } from 'react-intl';
import { PropsWithoutRef } from 'react';
import { ReactComponent as WorkHour } from '../../../icons/work-hour.svg';
import { ReactComponent as Notice } from '../../../icons/notice.svg';
import { ReactComponent as SettingsGreen } from '../../../icons/clock.svg';
import { PortDetails } from '../../../models/port-details';
import _ from 'lodash';

interface PortStatProps {
  port: PortDetails;
}

const DayShortCodes = {
  Monday: 'Mon',
  Tuesday: 'Tue',
  Wednesday: 'Wed',
  Thursday: 'Thu',
  Friday: 'Fri',
  Saturday: 'Sat',
  Sunday: 'Sun',
};

/**
 * port stat
 * @param port port
 */
function PortStat({ port }: PropsWithoutRef<PortStatProps>) {
  const intl = useIntl();

  /**
   * Generate the working day sequence.
   * @param port the port data.
   * @returns The day sequence.
   */
  const getWorkingDay = (port: PortDetails) => {
    const arr: string[] = [];
    let startIndex: number | null = null;

    // Days in order seqeunce
    const daysSequence = Object.keys(DayShortCodes);
    daysSequence.map((key: string, index: number) => {
      const currentWorkingDayKey: any = `workingOn${key}`;
      const currentDay = _.get(port, currentWorkingDayKey);
      // If the current day is true in the object, we check whether we already have a sequence started
      if (currentDay) {
        // We don't have a sequence started, so we set current index as start of the sequence
        if (startIndex === null) {
          startIndex = index;
        }
        // Edge case, when it is the last day i.e., Sunday, we can have 2 states, either we already have
        // a sequence started or Sunday is true
        if (index === daysSequence.length - 1) {
          const endIndexVal = _.get(DayShortCodes, daysSequence[index]);
          // We already have a sequence started, so we add the sequence
          if (startIndex !== null && startIndex !== index) {
            const startIndexVal = _.get(
              DayShortCodes,
              daysSequence[startIndex],
            );
            arr.push(`${startIndexVal}-${endIndexVal}`);
          } else {
            // We don't have a sequence, so we just add current day i.e., Sunday
            arr.push(`${endIndexVal}`);
          }
        }
      } else {
        // If we don't have a sequence, we don't have to do anything else we check whether the sequence
        // consists of just one day and add it.
        if (startIndex !== null) {
          if (startIndex === index - 1) {
            arr.push(`${_.get(DayShortCodes, daysSequence[startIndex])}`);
          } else {
            // We have a sequence, so we add the seq values
            const startIndexVal = _.get(
              DayShortCodes,
              daysSequence[startIndex],
            );
            const endIndexVal = _.get(DayShortCodes, daysSequence[index - 1]);
            arr.push(`${startIndexVal}-${endIndexVal}`);
          }
          startIndex = null;
        }
      }
    });

    return arr.join(', ');
  };

  /**
   * convert local time to specific timezone.
   */
  const calcTime = (offset: number) => {
    // create Date object for current location
    const d = new Date();

    // get UTC time in msec
    const utc = d.getTime() + d.getTimezoneOffset() * 60 * 1000;

    // create new Date object for different city
    // using supplied offset
    const date = new Date(utc + 3600000 * offset);

    return `${date.getHours()}:${date.getMinutes()} (GMT ${
      offset >= 0 ? ' + ' : '  '
    } ${offset})`;
  };

  return (
    <div>
      <div className={styles.container}>
        <IconField
          className={styles.item}
          icon={<WorkHour />}
          label={intl.formatMessage({ id: 'port-work-day-label' })}
          value={getWorkingDay(port)}
        />

        <IconField
          className={styles.item}
          icon={<Notice />}
          label={intl.formatMessage({ id: 'port-notice-required-label' })}
          value={`${port.workingDaysNotice} ${intl.formatMessage({
            id: 'port-work-day-label',
          })}`}
        />

        <IconField
          className={styles.item}
          icon={<SettingsGreen />}
          label={intl.formatMessage({ id: 'port-local-time-label' })}
          value={calcTime(port.gmtOffsetInHours)}
        />
      </div>
    </div>
  );
}

export default PortStat;
