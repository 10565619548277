import { apiInstance } from '../api/requestInterceptor';
import { DistributorType } from '../models/admin-form-types';

/**
 * Get all distributors
 */
async function getAllDistributors() {
  const res = await apiInstance.get<any>('/distributors');

  return res.data;
}

/**
 * update port distributor
 */
async function updatePortDistributor(
  portDistributorInfo: DistributorType,
): Promise<void> {
  try {
    await apiInstance.put<DistributorType>(
      `/distributors/${portDistributorInfo.id}`,
      portDistributorInfo,
    );
  } catch (e: any) {
    throw e?.response?.data?.message;
  }
}

/**
 * save port distributor
 */
async function savePortDistributor(portDistributorInfo: any): Promise<void> {
  try {
    await apiInstance.post<DistributorType>(
      `/distributors`,
      portDistributorInfo,
    );
  } catch (e: any) {
    throw e?.response?.data?.message;
  }
}

export { getAllDistributors, updatePortDistributor, savePortDistributor };
