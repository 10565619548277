import { saveAs } from 'file-saver';

import { apiInstance } from '../api/requestInterceptor';

async function downloadExistingLocalizedProducts() {
  const res = await apiInstance.get('/products/bulkLocalised/download', {
    responseType: 'blob',
  });

  if (res.status === 200) {
    const filename =
      res.headers['content-disposition']?.split('filename=')[1] ||
      'existing-localized-products.xlsx';
    saveAs(
      res.data,
      decodeURIComponent(filename.replace('"', '').replace('"', '')),
    );
  }

  return res;
}

async function downloadLocalizedProductsTemplate() {
  const res = await apiInstance.get(
    '/products/bulkLocalised/download?onlyTemplate=true',
    {
      responseType: 'blob',
    },
  );

  if (res.status === 200) {
    const filename =
      res.headers['content-disposition']?.split('filename=')[1] ||
      'localized-products-template.xlsx';
    saveAs(
      res.data,
      decodeURIComponent(filename.replace('"', '').replace('"', '')),
    );
  }

  return res;
}

export interface SaveRequestBody {
  items: {
    productId: number;
    languageId: number;
    tagLine?: string;
    description: string;
  }[];
}

async function saveLocalizedProducts(data: SaveRequestBody): Promise<any> {
  const res = await apiInstance.post<any>('/products/bulkLocalised/save', data);

  return res.data;
}

export {
  downloadExistingLocalizedProducts,
  downloadLocalizedProductsTemplate,
  saveLocalizedProducts,
};
