import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import GenericManageSkeleton from '../../../components/Admin/GenericManageSkeleton/GenericManageSkeleton';
import Header from '../../../components/Admin/Header/Header';
import OrderContactDataForm from '../../../components/Admin/OrderContactDataForm/OrderContactDataForm';
import SelectACountryOrDistributorForm from '../../../components/Admin/SelectACountryOrDistributorForm/SelectACountryOrDistributorForm';

import {
  ManageOrderContactType,
  StepperItemType,
} from '../../../models/admin-form-types';
import { CountryItem } from '../../../models/lookups';
import { PreviousLink } from '../../../models/previous-link';

import {
  getOrderContacts,
  updateOrderContacts,
} from '../../../services/OrderContactService';

import { RootStore } from '../../../store';

import { hideLoading, showLoading } from '../../../features/loadingSlice';
import { addToast } from '../../../features/toastSlice';
import { showGenericModal } from '../../../features/genericModalSlice';

import {
  removeEmptyFieldItem,
  validateData,
} from '../../../utils/order-contact-port-distributor';
import { trimSpacesInObj } from '../../../utils/common.util';
import withAdmin from '../../../hoc/withAdmin';

const breadcrumbLinks: PreviousLink[] = [
  {
    name: 'Home',
    to: '/home',
  },
  {
    name: 'Manage Order Contact',
  },
];

const initialState = {
  id: -1,
  country: {} as CountryItem,
  address: '',
  emails: [],
  phones: [],
  outOfHoursPhones: [],
  faxes: [],
  others: [],
};

const ManageOrderContact = () => {
  const intl = useIntl();
  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const { countries } = useSelector((state: RootStore) => state.lookups);
  const [step, setStep] = useState(0);
  const [initState, setInitState] = useState(initialState);
  const [formValue, setFormValue] = useState(initialState);
  const [isFormValid, setIsFormValid] = useState(true);

  const formValueArrayKeys = [
    'emails',
    'phones',
    'outOfHoursPhones',
    'faxes',
    'others',
  ];

  useEffect(() => {
    const isValid = validateData(formValueArrayKeys, formValue);
    setIsFormValid(isValid);
  }, [
    formValue.emails,
    formValue.phones,
    formValue.outOfHoursPhones,
    formValue.faxes,
    formValue.others,
  ]);

  const retrieveOrderContacts = () => {
    dispatch(showLoading());
    getOrderContacts(formValue.country.id)
      .then((d: any) => {
        const formVal = {
          ...formValue,
          ..._.omit(d[0], 'countryId'),
        };
        // formValueArrayKeys.map((key) => {
        //   const fieldValues = _.get(formVal, key);
        //   if (fieldValues?.length === 0) {
        //     fieldValues.push({
        //       name: '',
        //       value: '',
        //     });
        //   }
        // });
        setInitState(formVal);
        setFormValue(formVal);
      })
      .catch(() => {
        dispatch(
          addToast({
            type: 'error',
            title: getTranslatedMessage('order-contacts-get-failure'),
            message: '',
          }),
        );
      })
      .finally(() => dispatch(hideLoading()));
  };

  const handleValueChange = (fieldKey: string, value: string | string[]) => {
    setFormValue({
      ...formValue,
      [fieldKey]: value,
    });
  };

  /* istanbul ignore next */
  const stepperItems: StepperItemType = [
    {
      label: getTranslatedMessage('select-a-country-label'),
      componentToRender: (
        <SelectACountryOrDistributorForm
          titleId="country-details"
          labelId="select-a-country-label"
          selectOptions={countries}
          fieldKey="country"
          formValue={formValue}
          handleValueChange={handleValueChange}
        />
      ),
    },
    {
      label: getTranslatedMessage('order-contact-data-label'),
      componentToRender: (
        <OrderContactDataForm
          formValue={formValue}
          handleValueChange={handleValueChange}
        />
      ),
    },
  ];

  const saveDisabled = () => {
    if (step === 0) {
      return !formValue.country.id;
    }

    if (step === 1) {
      return !formValue.address.trim() || !isFormValid;
    }

    return false;
  };

  const handleSaveBtnClick = () => {
    if (step === 0) {
      if (formValue.country.id) {
        retrieveOrderContacts();
      }
    }
    if (step === 1) {
      let payload: ManageOrderContactType = {
        countryId: formValue.country.id,
        ..._.omit(formValue, 'country'),
      };
      payload = removeEmptyFieldItem(formValueArrayKeys, payload);
      payload = trimSpacesInObj(payload);
      dispatch(showLoading());
      updateOrderContacts(payload)
        .then(() => {
          dispatch(
            showGenericModal({
              titleId: 'order-contact-update-success-label',
              messageId: 'order-contact-update-success-message',
              to: '/',
            }),
          );
        })
        .catch((message) => {
          dispatch(
            addToast({
              type: 'error',
              title: getTranslatedMessage('order-contact-update-failure'),
              message: message || '',
            }),
          );
        })
        .finally(() => dispatch(hideLoading()));
      return;
    }

    setStep(step + 1);
  };

  return (
    <div>
      <Header
        titleId="header-menu-manage-ports-manage-order-contact"
        breadcrumbLinks={breadcrumbLinks}
      />
      <GenericManageSkeleton
        step={step}
        stepperItems={stepperItems}
        saveBtnDisabled={saveDisabled()}
        showUnsavedChangesModal={!_.isEqual(initState, formValue)}
        onBackBtnClick={() => setStep(step - 1)}
        onSaveBtnClick={handleSaveBtnClick}
      >
        {stepperItems[step].componentToRender}
      </GenericManageSkeleton>
    </div>
  );
};

export default withAdmin(ManageOrderContact);
