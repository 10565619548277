import { PropsWithoutRef, useMemo } from 'react';
import Select from '../Select/Select';
import { SelectOption } from '../../../models/select-option';

import { ReactComponent as Close } from '../../../icons/close-thick-white.svg';
import styles from './MultipleSelect.module.scss';
import classNames from 'classnames';

export const multiSelectStyles = styles;

export interface MultipleSelectProps<T> {
  value: T[];
  options: SelectOption<T>[];
  mandatoryValue?: T[];
  onChange?: (value: T[]) => void;
  onAdd?: (value: T) => void;
  onRemove?: (value: T) => void;
  getSelectedTagClassName?: (tagValue: T) => string;
  onClickSelectedTag?: (tagValue: T) => void;
  placeholder?: string;
  className?: string;
  selectClassName?: string;
  canSearch?: boolean;
}

/**
 * Multiple select component
 */
function MultipleSelect<T>({
  value,
  mandatoryValue = [],
  options,
  onChange,
  onAdd,
  onRemove,
  getSelectedTagClassName,
  onClickSelectedTag,
  placeholder = '',
  className,
  selectClassName,
  canSearch,
}: PropsWithoutRef<MultipleSelectProps<T>>) {
  const mandatoryValueMap = useMemo<Map<T, boolean>>(() => {
    const selectedValues = new Map<T, boolean>();
    mandatoryValue.forEach((val) => selectedValues.set(val, true));
    return selectedValues;
  }, [mandatoryValue]);

  const selectedValuesMap = useMemo<Map<T, boolean>>(() => {
    const selectedValues = new Map<T, boolean>();
    value.forEach((val) => selectedValues.set(val, true));
    return selectedValues;
  }, [value]);

  const filteredOptions = useMemo(() => {
    return options.filter((opt) => !selectedValuesMap.get(opt.value));
  }, [selectedValuesMap, options]);

  const selectedOptions = useMemo(() => {
    return options.filter((opt) => selectedValuesMap.get(opt.value));
  }, [selectedValuesMap, options]);

  return (
    <div className={className}>
      <Select
        required={false}
        className={classNames(styles.formSelectField, selectClassName)}
        value={undefined}
        canSearch={canSearch}
        options={filteredOptions}
        onChange={(selectedValue) => {
          if (selectedValue !== undefined) {
            if (onChange) {
              onChange([...value, selectedValue]);
            }
            if (onAdd) {
              onAdd(selectedValue);
            }
          }
        }}
        placeholder={placeholder}
      />

      <div className={styles.optionBoxWrap}>
        <div className={styles.optionBoxContainer}>
          {selectedOptions.length > 0 &&
            selectedOptions.map((opt) => (
              <div
                key={opt.name}
                className={classNames(
                  styles.optionBox,
                  onClickSelectedTag && styles.tagClickable,
                  getSelectedTagClassName && getSelectedTagClassName(opt.value),
                )}
                onClick={() =>
                  onClickSelectedTag && onClickSelectedTag(opt.value)
                }
              >
                {opt.name}
                {!mandatoryValueMap.get(opt.value) && (
                  <Close
                    onClick={(evt) => {
                      evt.stopPropagation();
                      if (onChange) {
                        const idx = value.indexOf(opt.value);
                        const newValues = [...value];
                        newValues.splice(idx, 1);
                        onChange(newValues);
                      }
                      if (onRemove) {
                        onRemove(opt.value);
                      }
                    }}
                    className={styles.close}
                  />
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default MultipleSelect;
