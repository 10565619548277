import styles from './ManageCookies.module.scss';
import Button from '../Button/Button';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import { PropsWithoutRef } from 'react';
import Modal from '../Modal/Modal';
import { ReactComponent as Close } from '../../../icons/close.svg';
import { Switch } from '@material-ui/core';

interface ManageCookiesProps {
  onAccept: () => void;
  onClose: () => void;
}

/**
 * ask cookie acceptance
 * @param onAccept accept handler
 */
function ManageCookies({
  onAccept,
  onClose,
}: PropsWithoutRef<ManageCookiesProps>) {
  return (
    <Modal onClose={onClose} hideClose={true}>
      <div className={styles.container}>
        <div className={styles.closeWrapper}>
          <Close onClick={() => onClose()} className={styles.close} />
        </div>
        <div className={styles.content}>
          <div className={styles.section}>
            <p>
              On your first visit to this site you were asked (by a notification
              banner) if you would like to accept the described usesof cookies
              and similar technologies. You can change your cookies settings at
              any time by visiting this page. We’d like to explain how we use
              these technologies.
            </p>
            <p>
              To learn about what cookies and similar technologies are, we
              recommend that you visit the following third party website:{' '}
              <a
                href="https://www.allaboutcookies.org"
                target="_blank"
                rel="noreferrer"
              >
                www.allaboutcookies.org.
              </a>
            </p>
            <p>
              Like many websites, we use cookiesfor a variety of purposes. Some
              cookies are necessary for the functionality of the site, others
              log data about your device hardware and your interaction with our
              site. It is our policy to use cookies and tracking technologies
              only with the consent of the users of the digital services we
              provide, except where the use of cookies or tracking technologies
              is strictly necessary for the use of a service.
            </p>
            <p>On this website we use:</p>
          </div>
          <div className={styles.section}>
            <h3 className={styles.header}>Functional cookies</h3>
            <p>
              These cookies are required for basic site functionality and are
              therefore always enabled. For instance, when you exit our cookie
              banner a cookie is dropped on your device reminding your browser
              not to re-serve this pop-up box when you later visit this site.
            </p>
          </div>
          <div className={styles.section}>
            <div className={styles.controls}>
              <h3 className={styles.header}>Performance cookies</h3>
              <Switch />
            </div>
            <div>
              <p>
                The information the cookies gather is used to help us customise
                the website for our users.
              </p>
              <p>
                <b>Analytics&nbsp;&nbsp;</b>
              </p>
              <p>
                Where you have permitted the use of performance cookies, we use
                a web analytics tool provided by Google to collect statistical
                information about how visitors use and interact with our site.
              </p>
              <p>The Google Analytics cookies collect information about</p>
              <ul>
                <li>Your IP address</li>
                <li>Preferred language</li>
                <li>The number of visitors to our site,&nbsp;</li>
                <li>The pages or content with the most traffic,&nbsp;</li>
                <li>
                  Approximately where in the world you are visiting the site
                  from,&nbsp;
                </li>
                <li>
                  How you have found our site e.g from a direct search; link to
                  a third-party site; or link in one of our emails.&nbsp;&nbsp;
                </li>
              </ul>
              <p>
                We use this aggregated statistical information to help improve
                the site, for instance by prioritizing updates of the most read
                content or the most used functionality.Google Analytics cookies
                may retain information for up to two years.For more information
                about Google Analytics services, please click here:{' '}
                <a
                  href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage
                </a>
              </p>
              <p>
                <b>Content testing&nbsp;</b>
              </p>
              <p>
                If we are working on updates to our site,we occasionally display
                original content to some visitors and alternate content to other
                visitors to help us assess which version best optimizes user
                experience. When we do this,we drop a cookie on your device to
                remember which version of the page you were served.
              </p>
              <p>
                <b>Surveys&nbsp;</b>
              </p>
              <p>
                We sometimes ask our visitors to complete optional surveys about
                our site or our goods and services. When we do this,we drop a
                cookie to remember not to send you the same survey again if you
                later re-visit our site.
              </p>
            </div>
          </div>
          <div className={styles.section}>
            <h3 className={styles.header}>Functional cookies</h3>
          </div>
          <div className={styles.section}>
            <div className={styles.controls}>
              <h3 className={styles.header}>
                Advertising and campaign cookies
              </h3>
              <Switch />
            </div>
            <div>
              <p>
                <b>Social media and other advertising cookies&nbsp;</b>
              </p>
              <p>
                <span>
                  Sometimes we pair up with social media partners such as
                  Facebook or with online advertising platforms who drop cookies
                  on your device when you visit our site in order to remember
                  that you have looked at our site/expressed an interest in our
                  products (&quot;Third-party cookies&quot;). This means that
                  when you later visit these third-partysites or visit a site
                  which has agreed to host adverts for our online advertising
                  platform, the adverts displayed to you are more tailored to
                  your apparent interests. If you click on these adverts,they
                  will re-direct you back to our site.
                </span>
              </p>
              <p>
                <span>
                  Our third-party partners may pass us information about the
                  total number of views, shares, and click-throughs these
                  adverts have received.The third parties’ use of your
                  information, including their deployment of cookies and
                  tracking technologies, is subject to the third party’s privacy
                  policy, not ours.&nbsp;
                </span>
              </p>
              <p>
                <span>
                  <b>Conversion tracking&nbsp;</b>
                </span>
              </p>
              <p>
                In order to see how successfully our marketing campaigns or
                other website goals are performing we sometimes use conversion
                pixels, which fire a short line of code to tell us when you have
                clicked on a particular button on our website or reached a
                particular page (e.g. a thank you page once you have completed
                the procedure for subscribing to one of our services or have
                completed one of our forms).
              </p>
              <p>
                Unless otherwise specified the above cookies remain when you
                close your browser.
              </p>
            </div>
          </div>
        </div>

        <div className={styles.buttonContainer}>
          <Button
            onClick={() => onAccept()}
            color={'green'}
            className={cn(styles.button, styles.acceptButton)}
          >
            <FormattedMessage id={'cookie-save-changes'} />
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default ManageCookies;
