import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { openContactUsModal } from '../../../features/contactUsSlice';
import styles from './SearchOrContactCastrol.module.scss';

const SearchOrContactCastrol = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  /**
   * Show contact us modal
   */
  const showContactUsModal = () => {
    dispatch(openContactUsModal({}));
  };

  return (
    <div className={styles.container}>
      {/* Safe to use dangerouslySetInnerHTML here since we are reading from translation file */}
      <span
        className={styles.span}
        dangerouslySetInnerHTML={{
          __html: intl
            .formatMessage({
              id: 'range-products-empty-message-2',
            })
            .replace('{{searchLinkOpen}}', '<a href="/">')
            .replace('{{searchLinkClose}}', '</a>'),
        }}
      ></span>
      &nbsp;
      <Link
        to={'#'}
        onClick={(e) => {
          e.preventDefault();
          showContactUsModal();
        }}
      >
        <FormattedMessage id={'contact-us-label'} />
      </Link>
      &nbsp;
      <FormattedMessage id={'range-products-empty-message-3'} />
    </div>
  );
};

export default SearchOrContactCastrol;
