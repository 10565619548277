/* istanbul ignore file */
import axios, { CancelTokenSource } from 'axios';
import classNames from 'classnames';
import { cloneDeep, debounce, isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ContainerForDrawer from '../../components/common/ContainerForDrawer/ContainerForDrawer';
import FilterContainer from '../../components/common/FilterContainer/FilterContainer';
import FilterGroup from '../../components/common/FilterGroup/FilterGroup';
import GridItems from '../../components/common/GridItems/GridItems';
import Input from '../../components/common/Input/Input';
import LeftDrawer from '../../components/common/LeftDrawer/LeftDrawer';
import LoadingSpinner from '../../components/common/LoadingSpinner/LoadingSpinner';
import NavigationHeader from '../../components/common/NavigationHeader/NavigationHeader';
import PortTable from '../../components/common/PortTable/PortTable';
import RangeSlider from '../../components/common/RangeSlider/RangeSlider';
import RightContainer from '../../components/common/RightContainer/RightContainer';
import SearchFilter from '../../components/common/SearchFilter/SearchFilter';
import Select from '../../components/common/Select/Select';
import SearchModal from '../../components/HomePage/SearchModal/SearchModal';
import SearchResultHeader from '../../components/SearchResultPage/SearchResultHeader/SearchResultHeader';
import { showGenericModal } from '../../features/genericModalSlice';
import { hideLoading, showLoading } from '../../features/loadingSlice';
import {
  clearAllFilters,
  clearFilter,
  setFilterablePortSearchResult,
  toggleFilter,
  updatePort,
} from '../../features/portFilterSlice';
import { addToast } from '../../features/toastSlice';
import { FilterOption } from '../../models/filter-option';
import { PortDetails, SearchPortResponse } from '../../models/port-details';
import { PreviousLink } from '../../models/previous-link';
import { activatePort, deactivatePort } from '../../services/PortService';
import { getPortsForProduct } from '../../services/ProductService';
import { RootStore } from '../../store';
import styles from './PortSearchResultPage.module.scss';

function PortSearchResultPage() {
  // init hooks
  const [query] = useSearchParams();
  const dispatch = useDispatch();
  const intl = useIntl();
  const navigate = useNavigate();

  // init local variable
  const portName = query.get('portName') || '';
  const countryName = query.get('countryName') || '';
  const countryId = query.get('countryId') || '';
  // breadcrumb links
  const breadcrumbLinks: PreviousLink[] = [
    {
      name: 'Home',
      to: '/home',
    },
    {
      name: 'Port Results',
    },
  ];
  const { filters, selectedFilters, filteredPorts } = useSelector(
    (state: RootStore) => state.portFilter,
  );
  const { countries, applications } = useSelector(
    (state: RootStore) => state.lookups,
  );
  const { hasWelcomeInfo } = useSelector(
    (state: RootStore) => state.welcomeInfo,
  );

  // init state.
  const [previousSource, setPreviousSource] = useState<CancelTokenSource>();
  const [searchOpened, setSearchOpened] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [loading, setLoading] = useState(true);
  const [ports, setPorts] = useState<SearchPortResponse | null>(null);
  const [openFilter, setOpenFilter] = useState(false);
  const [productName, setProductName] = useState<string>('');
  const [openApplication, setOpenApplication] = useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = useState<number | undefined>(
    Number.parseInt(countryId),
  );

  // local functions

  /**
   * close search modal
   */
  const closeSearchModal = () => {
    setSearchOpened(false);
  };

  /**
   * open search modal
   */
  const openSearchModal = () => {
    setSearchOpened(true);
  };

  const getPorts = (countryId: string) => {
    if (previousSource) {
      previousSource.cancel('cancel');
    }
    const source = axios.CancelToken.source();

    getPortsForProduct(
      source.token,
      selectedFilters.applications?.map((x) => x.value) ?? [],
      productName,
      portName,
      countryName,
      countryId,
    )
      .then((res) => {
        setInitialLoad(false);
        dispatch(
          setFilterablePortSearchResult({
            portResponse: res,
            countries,
            applications,
          }),
        );
        setPorts(res);
      })
      .finally(() => setLoading(false));

    setPreviousSource(source);
  };

  // effects

  /**
   * update port result bu lookup
   */
  useEffect(() => {
    if (countries && countries.length > 0 && ports) {
      dispatch(
        setFilterablePortSearchResult({
          portResponse: cloneDeep(ports),
          countries,
          applications,
        }),
      );
    }
  }, [dispatch, countries, ports]);

  /**
   * get ports when lookup value and welcome data available.
   */
  useEffect(() => {
    if (hasWelcomeInfo && countries && countries.length > 0) {
      if (initialLoad) {
        dispatch(clearAllFilters());
        setLoading(true);
      }

      getPorts(countryId);
    }
  }, [
    dispatch,
    countries,
    productName,
    portName,
    countryName,
    hasWelcomeInfo,
    isEmpty(selectedFilters?.applications)
      ? null
      : selectedFilters.applications,
  ]);

  const onChangeCountry = (countryId: number | undefined) => {
    if (countryId) {
      setSelectedCountry(countryId);
      navigate(`/port-results?countryId=${countryId}`);
      setLoading(true);
      getPorts(`${countryId}`);
    }
  };

  function handlePortActions(action: string, portId: number) {
    let res;
    if (action === 'activate-port') {
      res = activatePort(portId);
    } else {
      res = deactivatePort(portId);
    }

    let messageId = 'deactivate-port-success';
    if (action === 'activate-port') {
      messageId = 'activate-port-success';
    }

    dispatch(showLoading());
    res
      .then((data: PortDetails) => {
        dispatch(updatePort({ updatedPort: data, countries: countries }));
        dispatch(
          showGenericModal({
            titleId: action,
            messageId: messageId,
          }),
        );
      })
      .catch(() => {
        dispatch(
          addToast({
            type: 'error',
            title: intl.formatMessage({ id: 'port-deactive-failure' }),
            message: '',
          }),
        );
      })
      .finally(() => dispatch(hideLoading()));
  }

  const resetFilters = useCallback(() => {
    dispatch(clearAllFilters());
    setProductName('');
  }, []);

  const updateProductName = useCallback(debounce(setProductName, 200), []);

  return (
    <div>
      <NavigationHeader
        onInputFocus={() => openSearchModal()}
        breadcrumbLinks={breadcrumbLinks}
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        showFilterIcon={true}
      />
      <ContainerForDrawer
        classes={openFilter ? styles.filterOpen : styles.filterClose}
      >
        <LeftDrawer>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <SearchFilter clear={resetFilters}>
              <FilterContainer
                title={intl.formatMessage({ id: 'product-name-filter' })}
                className={styles.filterGroup}
              >
                <Input
                  value={productName}
                  onChange={updateProductName}
                  placeholder={intl.formatMessage({ id: 'search-product' })}
                />
              </FilterContainer>
              {!countryId && (
                <FilterGroup
                  filters={filters.countries}
                  onChange={(filter) => {
                    dispatch(
                      toggleFilter({
                        key: 'countries',
                        filter,
                      }),
                    );
                  }}
                  onReset={() => {
                    dispatch(
                      clearFilter({
                        key: 'countries',
                      }),
                    );
                  }}
                  selectedOptions={selectedFilters.countries}
                  title={intl.formatMessage({ id: 'countries-label' })}
                  open={false}
                  className={styles.filterGroup}
                />
              )}
              {countryId && countryId.length > 0 && (
                <FilterContainer
                  title={intl.formatMessage({ id: 'countries-label' })}
                  className={styles.filterGroup}
                >
                  <Select
                    className={classNames(
                      styles.selectControl,
                      styles.nameSelector,
                    )}
                    inputClassName={styles.selectInput}
                    optionClassName={styles.selectOption}
                    value={selectedCountry}
                    placeholder={''}
                    canSearch={true}
                    options={countries.map((x) => ({
                      name: x.name,
                      value: x.id,
                      disabled: false,
                    }))}
                    onChange={onChangeCountry}
                  />
                </FilterContainer>
              )}
              <FilterGroup
                filters={filters.applications}
                onChange={(filter) => {
                  setOpenApplication(true);
                  dispatch(
                    toggleFilter({
                      key: 'applications',
                      filter,
                    }),
                  );
                }}
                onReset={() => {
                  dispatch(
                    clearFilter({
                      key: 'applications',
                    }),
                  );
                }}
                selectedOptions={selectedFilters.applications}
                title={intl.formatMessage({ id: 'application-label' })}
                open={openApplication}
                className={styles.filterGroup}
              />
              {filters.minBulkSupply.length > 0 && (
                <FilterContainer
                  title={intl.formatMessage({ id: 'min-bulk-supply-filter' })}
                  className={styles.filterGroup}
                >
                  <RangeSlider
                    onChange={(_filter: FilterOption) => {
                      dispatch(
                        toggleFilter({
                          key: 'minBulkSupply',
                          filter: _filter,
                        }),
                      );
                    }}
                    onReset={() => {
                      dispatch(
                        clearFilter({
                          key: 'minBulkSupply',
                        }),
                      );
                    }}
                    filters={filters.minBulkSupply}
                    selectedOptions={selectedFilters.minBulkSupply}
                    filerOpenChange={openFilter}
                  ></RangeSlider>
                </FilterContainer>
              )}
              {filters.minDumSupply.length > 0 && (
                <FilterContainer
                  title={intl.formatMessage({ id: 'min-drum-supply-filter' })}
                  className={styles.filterGroup}
                >
                  <RangeSlider
                    onChange={(_filter) => {
                      dispatch(
                        toggleFilter({
                          key: 'minDumSupply',
                          filter: _filter,
                        }),
                      );
                    }}
                    onReset={() => {
                      dispatch(
                        clearFilter({
                          key: 'minDumSupply',
                        }),
                      );
                    }}
                    filters={filters.minDumSupply}
                    selectedOptions={selectedFilters.minDumSupply}
                    filerOpenChange={openFilter}
                  ></RangeSlider>
                </FilterContainer>
              )}
              {filters.noticeRequired.length > 0 && (
                <FilterContainer
                  title={intl.formatMessage({ id: 'notice-required-filter' })}
                  className={styles.filterGroup}
                >
                  <GridItems
                    filters={filters.noticeRequired}
                    selectedOptions={selectedFilters.noticeRequired}
                    onChange={(_filter) => {
                      dispatch(
                        toggleFilter({
                          key: 'noticeRequired',
                          filter: _filter,
                        }),
                      );
                    }}
                    onReset={() => {
                      dispatch(
                        clearFilter({
                          key: 'noticeRequired',
                        }),
                      );
                    }}
                    minWidth={'15px'}
                  />
                </FilterContainer>
              )}
              {filters.delivery.length > 0 && (
                <FilterContainer
                  title={intl.formatMessage({ id: 'delivery-filter' })}
                  className={styles.filterGroup}
                >
                  <GridItems
                    filters={filters.delivery}
                    selectedOptions={selectedFilters.delivery}
                    onChange={(_filter) => {
                      dispatch(
                        toggleFilter({
                          key: 'delivery',
                          filter: _filter,
                        }),
                      );
                    }}
                    onReset={() => {
                      dispatch(
                        clearFilter({
                          key: 'delivery',
                        }),
                      );
                    }}
                    width={'72px'}
                  />
                </FilterContainer>
              )}
            </SearchFilter>
          )}
        </LeftDrawer>
        <RightContainer>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <div>
              <SearchResultHeader
                count={filteredPorts.length}
                search={portName || countryName}
              />
              {filteredPorts && filteredPorts.length > 0 && (
                <PortTable
                  from={'port-results'}
                  ports={filteredPorts}
                  handleActions={handlePortActions}
                  showPagination={true}
                />
              )}
              {filteredPorts && filteredPorts.length == 0 && (
                <div className={styles.noResult}>
                  <FormattedMessage id={'no-results-matching-label'} />
                </div>
              )}
            </div>
          )}
        </RightContainer>
      </ContainerForDrawer>
      {searchOpened && <SearchModal onClose={() => closeSearchModal()} />}
    </div>
  );
}

export default PortSearchResultPage;
