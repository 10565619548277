import { PropsWithoutRef } from 'react';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import { ProductValidationColumn } from '../../../../../../models/table-column';

import styles from './ValidationTable.module.scss';

interface ValidationTableProps {
  data: any[];
  setDataAtIndex: (x: any, i: number) => void;
  columns: ProductValidationColumn[];
}

function ValidationTable({
  data = [],
  setDataAtIndex,
  columns,
}: PropsWithoutRef<ValidationTableProps>) {
  return (
    <div className={styles.tableWrapper}>
      <table className={styles.table}>
        <thead>
          <tr>
            {columns.map((column) => (
              <th
                key={column.formattedMessageId}
                className={cn(styles.tableColumn, {
                  [styles.requiredColumn]: column.requiredAsterisk,
                })}
              >
                <FormattedMessage id={column.formattedMessageId} />
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((product, index) => (
            <tr key={index}>
              {columns.map(
                (column, colInd) =>
                  column.renderer && (
                    <td key={colInd}>
                      {column.renderer(
                        column,
                        product,
                        (newData: any) => setDataAtIndex(newData, index),

                        index,
                      )}
                    </td>
                  ),
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ValidationTable;
