import { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { cloneDeep } from 'lodash';
import cn from 'classnames';
import { PreviousLink } from '../../../models/previous-link';
import NavigationHeader from '../../../components/common/NavigationHeader/NavigationHeader';
import ContainerForDrawer from '../../../components/common/ContainerForDrawer/ContainerForDrawer';
import LoadingSpinner from '../../../components/common/LoadingSpinner/LoadingSpinner';
import styles from './AddEditOEMPage.module.scss';

import LanguageSelect from '../../../components/Admin/LanguageSelect/LanguageSelect';
import { OemDetailRequest } from '../../../models/oem-detail';
import { getOem, createOem, updateOem } from '../../../services/OemService';
import Button from '../../../components/common/Button/Button';
import { LanguageItem } from '../../../models/lookups';

import { RootStore } from '../../../store';
import { storeOem } from '../../../features/oemSlice';
import { addToast } from '../../../features/toastSlice';
import { showGenericModal } from '../../../features/genericModalSlice';
import withAdmin from '../../../hoc/withAdmin';
import InputRow from '../../../components/Admin/InputRow/InputRow';
import RichTextareaRow from '../../../components/Admin/RichTextareaRow/RichTextareaRow';
import FormRow from '../../../components/Admin/FormRow/FormRow';
import validator from 'validator';

/**
 * Add or edit OEM page
 */
function AddEditOEMPage() {
  // init hooks
  const intl = useIntl();
  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });
  const location = useLocation();
  const { oemId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState<boolean>(false);

  const defaultValues = {
    name: '',
    webAddress: '',
    oemInfos: {},
  };

  const [originalValues, setOriginalValues] = useState(defaultValues);
  const [name, setName] = useState(defaultValues.name);
  const [webAddress, setWebAddress] = useState(defaultValues.webAddress);
  const [selectedLanguages, setSelectedLanguages] = useState<
    { id: number; empty: boolean }[]
  >([]);
  const [activeLanguage, setActiveLanguage] = useState(0);
  const [oemInfos, setOemInfos] = useState<any>({});

  const [breadcrumbLinks, setBreadcrumbLinks] = useState<PreviousLink[]>([
    {
      name: 'Home',
      to: '/home',
    },
  ]);

  const { languages } = useSelector(
    (rootState: RootStore) => rootState.lookups,
  );

  const languageOptions = useMemo(
    () =>
      languages.map((c) => ({
        name: c.name,
        value: c,
        disabled: activeLanguage === 0 && c.id !== 1,
      })),
    [languages, activeLanguage],
  );

  //effects
  useEffect(() => {
    const tempSelectedLanguages: any = [];
    for (const oemInfo of Object.keys(oemInfos)) {
      tempSelectedLanguages.push({
        id: Number(oemInfo),
        empty: !oemInfos[oemInfo] || oemInfos[oemInfo] === '<p><br></p>',
      });
    }

    if (activeLanguage === 0 && selectedLanguages.length > 0) {
      setActiveLanguage(1);
    }

    setSelectedLanguages(tempSelectedLanguages);
  }, [oemInfos]);

  /**
   * Load data by oemId.
   */
  useEffect(() => {
    if (oemId && languages && languages.length > 0) {
      setLoading(true);

      /* istanbul ignore next */
      getOem(oemId)
        .then((res) => {
          dispatch(storeOem(res));

          setName(res.name || '');
          setWebAddress(res.websiteUrl || '');
          const infos: any = {
            '1': res.details,
          };
          res.translations.forEach((item) => {
            infos['' + item.languageId] = item.propertyValue;
          });
          setOemInfos(infos);

          // update original data
          defaultValues.name = res.name || '';
          defaultValues.webAddress = res.websiteUrl || '';
          defaultValues.oemInfos = infos;

          setOriginalValues({
            name: res.name || '',
            webAddress: res.websiteUrl || '',
            oemInfos: infos,
          });
          setActiveLanguage(1);
        })
        .catch(() => {
          dispatch(
            addToast({
              type: 'error',
              title: intl.formatMessage({ id: 'oem-get-failure' }),
              message: '',
            }),
          );
        })
        .finally(() => setLoading(false));
    }
  }, [oemId, languages, dispatch]);

  useEffect(() => {
    if (location.pathname) {
      const tmpBreadcrumbLinks: PreviousLink[] = [
        {
          name: 'Home',
          to: '/home',
        },
        {
          name: 'Manage OEMS',
          to: '/admin/oem' + (oemId ? '/' + oemId : ''),
        },
      ];

      if (location.pathname.endsWith('/edit')) {
        setIsEdit(true);

        tmpBreadcrumbLinks.push({
          name: 'Edit OEM',
        });
      } else {
        setIsEdit(false);
        setName('');
        setWebAddress('');
        setSelectedLanguages([]);
        setActiveLanguage(0);
        setOemInfos({});

        tmpBreadcrumbLinks.push({
          name: 'Add OEM',
        });
      }

      setBreadcrumbLinks(tmpBreadcrumbLinks);
    }
  }, [oemId, location.pathname]);

  const generateOemDetail = (): OemDetailRequest => {
    // store current text area first
    const infos = cloneDeep(oemInfos);

    return {
      name,
      websiteUrl: webAddress,
      details: infos['1'],
      translations: selectedLanguages
        .filter((item) => item.id !== 1)
        .map((item) => ({
          languageId: item.id,
          propertyName: 'details',
          propertyValue: infos['' + item.id],
        })),
    };
  };

  const cancelClicked = () => {
    const infos = cloneDeep(oemInfos);
    let equalFlag = name === originalValues.name;
    equalFlag = equalFlag && webAddress === originalValues.webAddress;
    const key1 = Object.keys(originalValues.oemInfos);
    const key2 = Object.keys(infos);
    equalFlag = equalFlag && key1.length === key2.length;
    for (const key in infos) {
      equalFlag =
        equalFlag && infos[key] === (originalValues as any).oemInfos[key];
    }
    if (equalFlag) {
      navigate('/home');
    } else {
      dispatch(
        showGenericModal({
          type: 'confirmation',
          titleId: 'oem-model-unsaved-changes',
          messageId: 'oem-model-unsaved-changes-desc',
          to: '/',
        }),
      );
    }
  };

  /* istanbul ignore next */
  const updateClicked = () => {
    if (oemId) {
      setProcessing(true);
      updateOem(oemId, generateOemDetail())
        .then((res) => {
          dispatch(
            showGenericModal({
              titleId: 'oem-model-edit-oem-success',
              messageId: 'oem-model-edit-oem-success-info',
              to: `/admin/oem/${res.id}`,
            }),
          );
        })
        .catch(() => {
          dispatch(
            addToast({
              type: 'error',
              title: intl.formatMessage({ id: 'oem-update-failure' }),
              message: '',
            }),
          );
        })
        .finally(() => {
          setProcessing(false);
        });
    }
  };

  /* istanbul ignore next */
  const saveClicked = () => {
    setProcessing(true);
    createOem(generateOemDetail())
      .then((res) => {
        dispatch(
          showGenericModal({
            titleId: 'oem-model-add-oem-success',
            messageId: 'oem-model-add-oem-success-info',
            to: `/admin/oem/${res.id}`,
          }),
        );
      })
      .catch((e) => {
        const errMessage = e.response?.data?.message;
        dispatch(
          addToast({
            type: 'error',
            title: intl.formatMessage({ id: 'oem-create-failure' }),
            message: errMessage || '',
          }),
        );
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  /* istanbul ignore next */
  const languageChange = (languages: LanguageItem[]) => {
    const selectedLanguages: string[] = languages.map(
      (language: any) => language.id,
    );
    const infos = cloneDeep(oemInfos);

    // If the language is not present, we just add
    for (const language of selectedLanguages) {
      if (!infos[language]) {
        infos[language] = '';
      }
    }

    // If the language has been removed, we remove it
    for (const language of Object.keys(infos)) {
      const index = selectedLanguages.findIndex(
        (l) => Number(l) === Number(language),
      );
      if (index === -1) {
        delete infos[language];
      }
    }

    setOemInfos(infos);
    const currentActiveTab = languages.find((l: any) => l.active);
    if (currentActiveTab) {
      setActiveLanguage(currentActiveTab.id);
    }
  };

  const invalidData = (): boolean => {
    return (
      !name ||
      name.trim().length === 0 ||
      (!!webAddress && !validator.isURL(webAddress)) ||
      activeLanguage === 0 ||
      Object.keys(oemInfos).length === 0
    );
  };

  return (
    <div>
      <NavigationHeader breadcrumbLinks={breadcrumbLinks} />
      <ContainerForDrawer>
        {loading ? (
          <LoadingSpinner className={styles.loading} />
        ) : (
          <div className={styles.oemPageContainer}>
            <div className={styles.title}>
              {isEdit ? (
                <FormattedMessage id={'oem-page-oem-edit'} />
              ) : (
                <FormattedMessage id={'oem-page-oem-add'} />
              )}
            </div>
            <div className={styles.contentContainer}>
              <div className={styles.oemDetailContainer}>
                <div className={styles.oemNameAddressContainer}>
                  <div className={cn(styles.nameTitle, styles.desktop)}>
                    <FormattedMessage
                      id={'oem-page-oem-name-and-web-address'}
                    />
                  </div>

                  <div className={'columnRow'}>
                    <InputRow
                      labelId={'oem-page-oem-name'}
                      value={name}
                      required={true}
                      showCancelIcon={true}
                      onChange={(value: string) => setName(value)}
                      tooltip={getTranslatedMessage('oem-page-tooltip-name')}
                      errorMessage={getTranslatedMessage(
                        'field-required-error',
                      )}
                    />
                  </div>

                  <div className={'columnRow'}>
                    <InputRow
                      labelId={'oem-page-oem-web-address'}
                      value={webAddress}
                      required={false}
                      showCancelIcon={true}
                      onChange={(value: string) => setWebAddress(value)}
                      customValidator={(value: string) =>
                        !validator.isURL(value)
                      }
                      tooltip={getTranslatedMessage(
                        'oem-page-tooltip-web-address',
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className={styles.oemDetailContainer}>
                <div className={styles.oemNameAddressContainer}>
                  <div className={cn(styles.divider, styles.mobile)} />
                  <div className={styles.nameTitle}>
                    <FormattedMessage id={'oem-page-localised-oem-detail'} />
                  </div>
                  <div className={styles.description}>
                    <b>
                      <FormattedMessage id={'oem-page-please-note'} />
                    </b>
                    <FormattedMessage id={'oem-page-add-language-note'} />
                  </div>
                  <div className={cn(styles.divider, styles.desktop)} />
                  <div className={'columnRow'}>
                    <FormRow
                      labelId={'add-additional-langs-label'}
                      tooltip={intl.formatMessage({
                        id: 'add-additional-langs-tooltip',
                      })}
                      required={false}
                    >
                      <LanguageSelect
                        activeLanguage={activeLanguage}
                        handleActiveLanguageChange={(id: number) =>
                          setActiveLanguage(id)
                        }
                        langIds={selectedLanguages}
                        languageOptions={languageOptions}
                        placeholder={getTranslatedMessage(
                          'oem-page-select-placeholder',
                        )}
                        onChange={languageChange}
                      />
                    </FormRow>
                  </div>

                  {activeLanguage > 0 && (
                    <div className={'columnRow'}>
                      <RichTextareaRow
                        labelId={'oem-page-oem-information'}
                        value={oemInfos[activeLanguage]}
                        onChange={(value) => (oemInfos[activeLanguage] = value)}
                        required={false}
                        tooltip={getTranslatedMessage(
                          'oem-page-tooltip-information',
                        )}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className={styles.actionRow}>
                <Button
                  onClick={cancelClicked}
                  color={'green-outline'}
                  className={styles.cancelButton}
                >
                  <FormattedMessage id={'cancel-label'} />
                </Button>

                {isEdit ? (
                  <Button
                    onClick={updateClicked}
                    color={'green'}
                    disabled={invalidData()}
                    className={styles.updateButton}
                  >
                    <FormattedMessage id={'update-label'} />
                  </Button>
                ) : (
                  <Button
                    onClick={saveClicked}
                    color={'green'}
                    disabled={invalidData()}
                    className={styles.saveButton}
                  >
                    <FormattedMessage id={'save-label'} />
                  </Button>
                )}
              </div>
            </div>
          </div>
        )}
      </ContainerForDrawer>
      {processing && <LoadingSpinner className={'lookupsLoading'} />}
    </div>
  );
}

export default withAdmin(AddEditOEMPage);
