import styles from './DetailHeader.module.scss';
import { PropsWithoutRef } from 'react';
import Button from '../Button/Button';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { openContactUsModal, PageType } from '../../../features/contactUsSlice';
import { RootStore } from '../../../store';
import config from '../../../configs/config';

interface DetailHeaderProps {
  title: string;
  subTitle?: string;
  description: string;
  labelId?: string;
  page: PageType;
  id: number;
}

/**
 * detail page header
 * @param title title
 * @param subTitle sub title
 * @param description description
 * @param page page
 * @id id
 */
function DetailHeader({
  title,
  subTitle,
  description,
  page,
  id,
}: PropsWithoutRef<DetailHeaderProps>) {
  const dispatch = useDispatch();
  const welcomeInfoRoot = useSelector((state: RootStore) => state.welcomeInfo);

  /**
   * Find which label to be shown inside the button
   */
  const getLabelInsideBtn = () => {
    const { welcomeInfo } = welcomeInfoRoot;
    const supportFindADistributor =
      Boolean(welcomeInfo?.industrySector?.supportFindADistributor) &&
      Boolean(welcomeInfo?.country?.supportFindADistributor);

    if (supportFindADistributor) {
      return 'find-a-distributor-label';
    }

    return 'contact-us-label';
  };

  const labelId = getLabelInsideBtn();

  /**
   * open contact us
   */
  const openContactUsOrMap = () => {
    if (labelId === 'contact-us-label') {
      dispatch(
        openContactUsModal({
          id: id,
          page: page,
        }),
      );
      return;
    }

    window.open(`https://maps.castrol.com/?filters=industrial`);
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{title}</h1>

      {subTitle && <h2 className={styles.subTitle}>{subTitle}</h2>}

      <div className={styles.description}>{description}</div>

      <Button
        onClick={() => {
          if (welcomeInfoRoot.sector === config.MARINE_SECTOR) {
            openContactUsOrMap();
          } else if (labelId === 'find-a-distributor-label') {
            window.open(config.FIND_DISTRIBUTOR_LINK);
          } else {
            window.open(config.CONTACT_US_LINK);
          }
        }}
        color={labelId === 'find-a-distributor-label' ? 'ruby' : 'green'}
        className={styles.button}
      >
        <FormattedMessage id={labelId} />
      </Button>
    </div>
  );
}

export default DetailHeader;
