import { apiInstance } from '../api/requestInterceptor';
import {
  DistributorLinks,
  RequestBodyUpdate,
} from '../models/distrubutor-links';

/**
 * get distributor links
 */
async function getDistributorLinks(): Promise<DistributorLinks> {
  const res = await apiInstance.get<DistributorLinks>('/findADistributorLinks');
  return res.data;
}

/**
 * update distributor links
 */
async function updateDistributorLinks(data: RequestBodyUpdate): Promise<any> {
  const res = await apiInstance.put<any>('/findADistributorLinks', data);

  return res.data;
}

export { getDistributorLinks, updateDistributorLinks };
