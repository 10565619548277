import { CancelToken } from 'axios';
import { apiInstance } from '../api/requestInterceptor';

import { SearchLinkResponse, LinkResponse } from '../models/link-response';
import { SaveLinkRequest } from '../models/link-request';

export interface LinkSearchCriteria {
  page: number;
  perPage: number;
  sortBy: string;
  sortDirection: string;
  name?: string;
  sectorId?: number;
}

/**
 * search link
 * @param criteria link query criteria
 */
async function searchLinks(
  criteria: LinkSearchCriteria,
  cancelToken?: CancelToken,
): Promise<SearchLinkResponse> {
  const queryList = [];
  queryList.push(`page=${criteria.page}`);
  queryList.push(`perPage=${criteria.perPage}`);
  queryList.push(`sortBy=${criteria.sortBy}`);
  queryList.push(`sortDirection=${criteria.sortDirection}`);

  if (criteria.name) {
    queryList.push(`name=${criteria.name}`);
  }

  if (criteria.sectorId) {
    queryList.push(`sectorIds=${criteria.sectorId}`);
  }

  const res = await apiInstance.get<SearchLinkResponse>(
    '/links/all?' + queryList.join('&'),
    {
      cancelToken,
    },
  );

  return res.data;
}

/**
 * Get link by Id
 */
async function getLink(id: number): Promise<LinkResponse> {
  const res = await apiInstance.get<LinkResponse>(`/links/${id}`);

  return res.data;
}

/**
 * Update link
 * @param linkId link id
 * @param data data to be updated
 */
async function updateLink(
  linkId: number,
  data: SaveLinkRequest,
): Promise<LinkResponse> {
  const res = await apiInstance.put<LinkResponse>(`/links/${linkId}`, data);

  return res.data;
}

/**
 * Create link
 * @param data data to be created
 */
async function creatLink(data: SaveLinkRequest): Promise<LinkResponse> {
  const res = await apiInstance.post<LinkResponse>(`/links`, data);

  return res.data;
}

/**
 * Delete link
 * @param linkId link id
 */
async function deleteLink(linkId: number): Promise<void> {
  await apiInstance.delete(`/links/${linkId}`);
}

export { searchLinks, getLink, updateLink, creatLink, deleteLink };
