import Header from '../../../../../components/Admin/Header/Header';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './AddEditHelp.module.scss';
import { PreviousLink } from '../../../../../models/previous-link';
import { useEffect, useMemo, useState } from 'react';
import Button from '../../../../../components/common/Button/Button';
import InputRow from '../../../../../components/Admin/InputRow/InputRow';
import SelectFormRow from '../../../../../components/Admin/SelectFormRow/SelectFormRow';
import ReactQuill from 'react-quill';
import FormRow from '../../../../../components/Admin/FormRow/FormRow';
import LoadingSpinner from '../../../../../components/common/LoadingSpinner/LoadingSpinner';
import { useDispatch, useSelector } from 'react-redux';
import { showGenericModal } from '../../../../../features/genericModalSlice';
import NavigationHeader from '../../../../../components/common/NavigationHeader/NavigationHeader';
import { RootStore } from '../../../../../store';
import _ from 'lodash';
import { UploadDocumentResponse } from '../../../../../models/upload-document-response';
import { uploadDocument } from '../../../../../services/DocumentService';
import { addToast } from '../../../../../features/toastSlice';
import {
  createHelp,
  downloadHelpDocument,
  getHelpById,
  updateHelp,
} from '../../../../../services/ManageHelpService';
import FileInput from '../../../../../components/Admin/FileInput/FileInput';
import { useLocation, useNavigate, useParams } from 'react-router';
import { Help } from '../../../../../models/help';
import classNames from 'classnames';

export default function AddEditHelp() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [processing, setProcessing] = useState<boolean>(false);
  const [captionName, setCaptionName] = useState('');
  const [associateFile, setAssociateFile] = useState<File | null>(null);
  const [type, setType] = useState('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const { helpId } = useParams();
  const location = useLocation();
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [addedFileName, setAddedFileName] = useState<string | undefined>(
    undefined,
  );
  const [existingHelp, setExistingHelp] = useState<Help | null>(null);

  const { helpTypes, helpTypesById } = useSelector(
    (state: RootStore) => state.lookups,
  );
  const helpTypeOptions = useMemo(() => {
    return helpTypes.map((type) => ({
      name: type.name,
      value: type.id.toString(),
    }));
  }, []);
  const editorModules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ list: 'bullet' }, { list: 'ordered' }],
    ],
  };

  useEffect(() => {
    if (location.pathname?.endsWith('/edit')) {
      setIsEdit(true);
    } else if (helpId) {
      setIsView(true);
    }
  }, [helpId, location.pathname]);

  useEffect(() => {
    if (helpId) {
      setLoading(true);
      getHelpById(+helpId)
        .then((res) => {
          setExistingHelp(res);
          setCaptionName(res.captionName);
          setAddedFileName(res.associateFile.name);
          setType(res.helpTypeId.toString());
          setDescription(res.description);
        })
        .catch(() => {
          dispatch(
            addToast({
              type: 'error',
              title: intl.formatMessage({ id: 'help-load-failure' }),
              message: '',
            }),
          );
        })
        .finally(() => setLoading(false));
    }
  }, [helpId]);

  const breadcrumbLinks: PreviousLink[] = useMemo(
    () => [
      {
        name: intl.formatMessage({ id: 'breadcrumb-home' }),
        to: '/home',
      },
      {
        name: intl.formatMessage({ id: 'header-menu-admin-help' }),
        to: '/admin/helps',
      },
      {
        name: intl.formatMessage({
          id: isEdit ? 'edit-help' : isView ? 'view-help' : 'create-new-help',
        }),
      },
    ],
    [intl, isEdit, isView],
  );

  const uploadAssociateFile = (file: File): Promise<UploadDocumentResponse> => {
    const formData = new FormData();
    formData.append('type', 'Help');
    formData.append('file', file);

    return uploadDocument(formData);
  };

  const saveClicked = async () => {
    if (!isEdit && !associateFile) return;
    try {
      setProcessing(true);
      let fileId = existingHelp?.associateFile?.id || 0;
      if (associateFile) {
        const res = await uploadAssociateFile(associateFile);
        fileId = res.id;
      }
      if (helpId) {
        await updateHelp(+helpId, {
          captionName,
          description,
          helpTypeId: +type,
          associateFileId: fileId,
        });
      } else {
        await createHelp({
          captionName,
          description,
          helpTypeId: +type,
          associateFileId: fileId,
        });
      }
      dispatch(
        showGenericModal({
          titleId: isEdit ? 'edit-success' : 'create-new-help-success',
          messageId: isEdit
            ? 'update-help-success-info'
            : 'create-new-help-success-info',
          to: `/admin/helps`,
        }),
      );
    } catch (e) {
      dispatch(
        addToast({
          type: 'error',
          title: intl.formatMessage({
            id: isEdit ? 'help-update-failure' : 'help-create-failure',
          }),
          message: '',
        }),
      );
    } finally {
      setProcessing(false);
    }
  };

  const isValid = () => {
    if (
      _.isEmpty(captionName.trim()) ||
      (!isEdit && _.isEmpty(associateFile)) ||
      _.isEmpty(type) ||
      _.isEmpty(description.trim()) ||
      /^<p>(<br>)*\s*<\/p>$/.test(description.trim())
    ) {
      return false;
    }

    return true;
  };

  return (
    <>
      <div className={styles.desktopOnly}>
        <Header
          titleId={
            isEdit ? 'edit-help' : isView ? 'view-help' : 'create-new-help'
          }
          breadcrumbLinks={breadcrumbLinks}
        />
      </div>
      <div className={styles.phoneOnly}>
        <NavigationHeader breadcrumbLinks={breadcrumbLinks} />
        <div className={styles.newHelpTitle}>
          <FormattedMessage id={isEdit ? 'edit-help' : 'create-new-help'} />
        </div>
      </div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className={styles.contentContainer}>
          <div className={styles.formWrapper}>
            <div className={styles.sectionHeader}>
              <FormattedMessage id="details-label" />
            </div>
            <div className="columnRow">
              {isView ? (
                <FormRow labelId="caption-name-label" required={false}>
                  <span className={styles.viewValue}>{captionName}</span>
                </FormRow>
              ) : (
                <InputRow
                  labelId="caption-name-label"
                  value={captionName}
                  onChange={setCaptionName}
                  tooltip={intl.formatMessage({ id: 'caption-name-label' })}
                />
              )}
            </div>
            <div className={classNames(styles.parentWidth, 'columnRow')}>
              <FormRow
                className={styles.parentWidth}
                labelId={'associate-file-label'}
                required={!isView}
                tooltip={
                  isView
                    ? ''
                    : intl.formatMessage({ id: 'associate-file-help-label' })
                }
              >
                {isView && helpId ? (
                  <span
                    className={classNames(styles.viewValue, styles.link)}
                    onClick={() => downloadHelpDocument(+helpId)}
                  >
                    <FormattedMessage id={'download-label'} />
                  </span>
                ) : (
                  <FileInput
                    accept={'application/pdf'}
                    file={associateFile}
                    addedFileName={addedFileName}
                    onChange={setAssociateFile}
                  />
                )}
              </FormRow>
            </div>
            <div className="columnRow">
              {isView ? (
                <FormRow labelId="type-label" required={false}>
                  <span className={styles.viewValue}>
                    {helpTypesById[+type].name}
                  </span>
                </FormRow>
              ) : (
                <SelectFormRow
                  labelId="type-label"
                  tooltip={intl.formatMessage({ id: 'type-label' })}
                  options={helpTypeOptions}
                  value={type}
                  onChange={setType}
                />
              )}
            </div>
            <div className="columnRow">
              <FormRow
                labelId={'description-label'}
                required={!isView}
                tooltip={
                  isView ? '' : intl.formatMessage({ id: 'description-label' })
                }
              >
                {isView ? (
                  <div
                    className={styles.viewValue}
                    dangerouslySetInnerHTML={{ __html: description }}
                  ></div>
                ) : (
                  <ReactQuill
                    className={styles.richTextarea}
                    modules={editorModules}
                    value={description}
                    placeholder={intl.formatMessage({
                      id: 'input-placeholder',
                    })}
                    onChange={setDescription}
                  />
                )}
              </FormRow>
            </div>
          </div>
          {isView ? (
            <div className={styles.actionRow}>
              <Button
                onClick={() => navigate('/admin/helps')}
                color={'green-outline'}
                className={styles.cancelButton}
              >
                <FormattedMessage id={'back-label'} />
              </Button>
            </div>
          ) : (
            <div className={styles.actionRow}>
              <Button
                onClick={() => navigate('/admin/helps')}
                color={'green-outline'}
                className={styles.cancelButton}
              >
                <FormattedMessage id={'cancel-label'} />
              </Button>

              <Button
                onClick={saveClicked}
                color={'green'}
                disabled={!isValid()}
                className={styles.saveButton}
              >
                <FormattedMessage id={'save-label'} />
              </Button>
            </div>
          )}
          {processing && <LoadingSpinner className={'lookupsLoading'} />}
        </div>
      )}
    </>
  );
}
