import { PropsWithoutRef, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import ReactTooltip from 'react-tooltip';

import Card from '../../../../../../components/common/Card/Card';
import styles from './CountryVisibility.module.scss';
import { ReactComponent as QuestionMark } from '../../../../../../icons/question-info.svg';
import CheckboxTree from '../../../../../../components/common/CheckboxTree/CheckboxTree';
import { RegionDistributorLinks } from '../../../../../../models/distrubutor-links';

interface CountryVisibilityProps {
  selected: number[];
  setSelected: (x: number[]) => void;
  regions: RegionDistributorLinks[] | undefined;
}

function CountryVisibility({
  selected,
  setSelected,
  regions = [],
}: PropsWithoutRef<CountryVisibilityProps>) {
  const areas = useMemo(
    () =>
      regions.map((region) => ({
        value: region.id,
        label: region.name,
        children: region.countries
          .sort((a: any, b: any) =>
            a.name < b.name ? -1 : a.name > b.name ? 1 : 0,
          )
          .map((country) => ({
            value: country.id,
            label: country.name,
          })),
      })),
    [regions],
  );

  return (
    <div className={styles.container}>
      <Card className={styles.card}>
        <>
          <h2>
            <FormattedMessage id={'sector-visibility'} />
          </h2>
          <div className={styles.divider} />
          <div className={styles.options}>
            <div className={styles.optionsHeader}>
              <div className={styles.optionsHeaderText}>
                <FormattedMessage id={'select-country-label'} />
              </div>
              <QuestionMark data-tip data-for={'select-country-label'} />
              <ReactTooltip
                id={'select-country-label'}
                place={'top'}
                effect={'solid'}
              >
                <FormattedMessage id={'country-help-text'} />
              </ReactTooltip>
            </div>
            <CheckboxTree
              tree={areas}
              selected={selected}
              setSelected={setSelected}
            />
          </div>
        </>
      </Card>
    </div>
  );
}

export default CountryVisibility;
