import styles from './WelcomeLeftBanner.module.scss';
import ModalLeftBanner from '../../ModalLeftBanner/ModalLeftBanner';
import ModalLeftBannerTitle from '../../ModalLeftBanner/ModalLeftBannerTitle/ModalLeftBannerTitle';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../../store';

function WelcomeLeftBanner() {
  const intl = useIntl();
  const { hasLogin } = useSelector((state: RootStore) => state.login);
  return (
    <ModalLeftBanner className={styles.container}>
      <img alt={'logo'} src={'/images/logo2.png'} />

      <ModalLeftBannerTitle className={styles.title}>
        <FormattedMessage id={'welcome-label'} />
      </ModalLeftBannerTitle>

      {/* Safe to use dangerouslySetInnerHTML here since we are reading from translation file */}
      <h2
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage({ id: 'welcome-description' }),
        }}
      />

      {/* Safe to use dangerouslySetInnerHTML here since we are reading from translation file */}
      {!hasLogin ? (
        <div className={styles.description}>
          {intl.formatMessage({ id: 'welcome-login-message1' })}
          <Link to={'/login'}>
            {intl.formatMessage({ id: 'welcome-login-message2' })}
          </Link>
          {intl.formatMessage({ id: 'welcome-login-message3' })}
        </div>
      ) : (
        <div className={styles.description}>
          {intl.formatMessage({ id: 'welcome-login-message4' })}
        </div>
      )}
    </ModalLeftBanner>
  );
}

export default WelcomeLeftBanner;
