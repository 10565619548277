import { useIntl } from 'react-intl';
import { ReactComponent as Warning } from '../../../icons/warning-red.svg';
import styles from './NotFound.module.scss';

function NotFound() {
  const intl = useIntl();
  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <Warning className={styles.icon} />
        <div className={styles.content}>
          <h2 className={styles.header1}>
            {intl.formatMessage({ id: '404-header1' })}
          </h2>
          <h2>{intl.formatMessage({ id: '404-header2' })}</h2>
          <div className={styles.message1}>
            {intl.formatMessage({ id: '404-message1' })}
          </div>
          {/* Safe to use dangerouslySetInnerHTML here since we are reading from config file */}
          <div
            className={styles.message2}
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({ id: '404-message2' }),
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
