import ProductListTable, {
  sortChangeHandler,
} from '../../ProductListTable/ProductListTable';
import { Fragment, PropsWithoutRef, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../../store';
import { TableColumn } from '../../../../models/table-column';
import { useIntl } from 'react-intl';
import cn from 'classnames';
import { SearchResultProduct } from '../../../../models/search-result-product';
import { sortMethodWithOrderByColumn } from '../../../../utils/sort.util';
import { toggleProduct } from '../../../../features/productComparisonSlice';
import Paginator from '../../../../components/common/Paginator/Paginator';
import styles from './ProductChildrenTable.module.scss';

interface ProductChildTableProps {
  products: SearchResultProduct[];
  background?: 'dark' | 'light';
  className?: string;
  columns?: TableColumn<SearchResultProduct>[];
  defaultSortColumn?: string;
  showPagination?: boolean;
}

/**
 * product child table
 * @param products products
 * @param background background color
 * @param className classname
 * @param columns columns to override
 */
function ProductChildrenTable({
  products,
  background = 'dark',
  className,
  columns,
  defaultSortColumn = 'name',
  showPagination = false,
}: PropsWithoutRef<ProductChildTableProps>) {
  const intl = useIntl();

  const { categories, applications, subCategories } = useSelector(
    (rootState: RootStore) => rootState.lookups,
  );

  // table sorts
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState(defaultSortColumn);
  const [sortByProperty, setSortByProperty] = useState(
    columns?.find((column) => column.key === defaultSortColumn)?.property ||
      'name',
  );
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  const sortedTmp = [...products].sort(
    sortMethodWithOrderByColumn(sortBy, sortDirection),
  );

  const total = products?.length || 0;
  const getPagedList = (
    values: SearchResultProduct[],
  ): SearchResultProduct[] => {
    if (!showPagination) {
      return values;
    }

    if (!values) {
      return [];
    }
    const first = perPage * (page - 1);
    let end = page * perPage;
    end = total > end ? end : total;
    return values.slice(first, end);
  };

  const [sortedData, setSortedData] = useState(sortedTmp);
  const [data, setData] = useState(getPagedList(sortedTmp));

  useEffect(() => {
    setPage(1);
    const sortedPorts = [...products].sort(
      sortMethodWithOrderByColumn(sortBy, sortDirection),
    );
    setSortedData(sortedPorts);
    setData(getPagedList(sortedPorts));
  }, [products]);

  useEffect(() => {
    setData(getPagedList(sortedData));
  }, [page, perPage]);

  // redux
  const productComparison = useSelector(
    (state: RootStore) => state.productComparison,
  );
  const dispatch = useDispatch();

  const categoryNamesById = useMemo(() => {
    const ret: { [key: string]: string } = {};
    categories.forEach((item) => (ret[String(item.id)] = item.name));
    return ret;
  }, [categories]);

  const subCategoryNamesById = useMemo(() => {
    const ret: { [key: string]: string } = {};
    subCategories.forEach((item) => (ret[String(item.id)] = item.name));
    return ret;
  }, [subCategories]);

  const applicationNamesById = useMemo(() => {
    const ret: { [key: string]: string } = {};
    applications.forEach((item) => (ret[String(item.id)] = item.name));
    return ret;
  }, [applications]);

  // product children columns
  const productChildrenColumns: TableColumn<SearchResultProduct>[] = columns
    ? columns
    : [
        {
          key: 'name',
          property: 'name',
          label: intl.formatMessage({ id: 'product-name-label' }),
          width: '36.19344933469806%',
          to: (data) => {
            return `/product/${data.friendlyUrl}`;
          },
        },
        {
          key: 'category',
          property: 'category',
          render: (product) =>
            product.productCategories
              .map((category) => categoryNamesById[String(category.categoryId)])
              .join(', '),
          label: intl.formatMessage({ id: 'category-label' }),
          width: '18.21084953940635%',
        },
        {
          key: 'productSubCategory',
          property: 'productSubCategory',
          render: (product) => subCategoryNamesById[product.subCategoryId],
          label: intl.formatMessage({ id: 'subcategory-label' }),
          width: '18.21084953940635%',
        },
        {
          key: 'application',
          property: 'application',
          render: (product) =>
            product.productApplications
              .map(
                (application) =>
                  applicationNamesById[String(application.applicationId)],
              )
              .join(', '),
          label: intl.formatMessage({ id: 'application-label' }),
          width: '18.12384851586489%',
        },
      ];

  /**
   * handle sort change
   * @param column column
   */
  const onSortChange = (column: TableColumn<SearchResultProduct>) => {
    if (!column.key) {
      return;
    }

    setPage(1);
    const changes = sortChangeHandler(
      [sortBy, setSortBy],
      [sortByProperty, setSortByProperty],
      [sortDirection, setSortDirection],
      column,
    );

    // setData(sorted);
    const sorted = [...products].sort(
      sortMethodWithOrderByColumn(
        changes.sortByProperty,
        changes.sortDirection,
      ),
    );

    setSortedData(sorted);
    setData(getPagedList(sorted));
  };

  /**
   * product check event handler
   * @param product checked product
   */
  const onChecked = (product: SearchResultProduct) => {
    dispatch(toggleProduct(product));
  };

  return (
    <Fragment>
      <ProductListTable
        className={cn(className, showPagination && styles.showPagination)}
        sortBy={sortBy}
        sortDirection={sortDirection}
        onSortChange={onSortChange}
        columns={productChildrenColumns}
        background={background}
        data={data}
        showCheckbox={true}
        onChecked={onChecked}
        checkedData={productComparison.products}
      />
      {showPagination && (
        <Paginator
          page={page}
          perPage={perPage}
          total={total}
          className={styles.pagination}
          onPageChange={setPage}
          onPerPageChange={(val: number) => {
            setPerPage(val);
            setPage(1);
          }}
        />
      )}
    </Fragment>
  );
}

export default ProductChildrenTable;
