import { PropsWithoutRef, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styles from './OemTable.module.scss';

import { TableColumn } from '../../../models/table-column';
import { sortMethodWithOrderByColumn } from '../../../utils/sort.util';
import OemListTable, { sortChangeHandler } from '../OemListTable/OemListTable';
import { OemResponse } from '../../../models/oem';
import Paginator from '../../../components/common/Paginator/Paginator';

interface OemTableProps {
  oems: OemResponse[];
}

function OemTable({ oems }: PropsWithoutRef<OemTableProps>) {
  const intl = useIntl();

  // table sorts
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState('name');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const sortedTmp = [...oems].sort(
    sortMethodWithOrderByColumn(sortBy, sortDirection),
  );

  const total = oems?.length || 0;
  const getPagedList = (values: OemResponse[]): OemResponse[] => {
    if (!values) {
      return [];
    }
    const first = perPage * (page - 1);
    let end = page * perPage;
    end = total > end ? end : total;
    return values.slice(first, end);
  };

  const [sortedData, setSortedData] = useState(sortedTmp);
  const [data, setData] = useState(getPagedList(sortedTmp));

  useEffect(() => {
    setPage(1);
    const sortedOems = [...oems].sort(
      sortMethodWithOrderByColumn(sortBy, sortDirection),
    );
    setSortedData(sortedOems);
    setData(getPagedList(sortedOems));
  }, [oems]);

  useEffect(() => {
    setData(getPagedList(sortedData));
  }, [page, perPage]);

  // port children columns
  const columns: TableColumn<OemResponse>[] = [
    {
      key: 'name',
      property: 'name',
      label: intl.formatMessage({ id: 'oem-name-label' }),
      width: '45%',
      to: (data) => {
        return `/admin/oem/${data.id}`;
      },
    },
    {
      key: 'sectors',
      property: 'sectors',
      label: intl.formatMessage({ id: 'sector(s)-label' }),
      width: '55%',
    },
    // {
    //   key: 'showProducts',
    //   property: '',
    //   label: intl.formatMessage({ id: 'product(s)-label' }),
    //   width: '20%',
    //   render: () => (
    //     <div className={styles.showProducts}>
    //       {intl.formatMessage({ id: 'show-product-s-label' })}
    //     </div>
    //   ),
    // },
  ];

  /**
   * handle sort change
   * @param column column
   */
  const onSortChange = (column: TableColumn<OemResponse>) => {
    setPage(1);
    const changes = sortChangeHandler(
      [sortBy, setSortBy],
      [sortDirection, setSortDirection],
      column,
    );

    const sorted = [...oems].sort(
      sortMethodWithOrderByColumn(changes.sortBy, changes.sortDirection),
    );

    setSortedData(sorted);
    setData(getPagedList(sorted));
  };

  const getColumns = () => {
    const cols = columns;

    return cols;
  };

  return (
    <div className={styles.tableContainer}>
      <OemListTable
        data={data}
        sortBy={sortBy}
        sortDirection={sortDirection}
        columns={getColumns()}
        onSortChange={onSortChange}
        showCheckbox={false}
        columnClassName={styles.tableColumn}
        from={'oem-results'}
      />
      <Paginator
        page={page}
        perPage={perPage}
        total={oems.length}
        onPageChange={setPage}
        onPerPageChange={setPerPage}
      />
    </div>
  );
}

export default OemTable;
