import classNames from 'classnames';
import { PropsWithoutRef } from 'react';
import { FilterOption } from '../../../models/filter-option';

import styles from './GridItems.module.scss';

interface GridItemsProps {
  filters: FilterOption[];
  selectedOptions: FilterOption[] | null;
  width?: string;
  onChange: (filter: FilterOption) => void;
  onReset: () => void;
  className?: string;
  minWidth?: string;
}

/**
 * grid items
 * @param filters filter options
 * @param selectedOptions selected options
 * @param width width
 * @param onChange on change handler
 * @param onReset filter reset handler
 * @param className classname
 * @param minWidth minimum width
 */
function GridItems({
  filters,
  selectedOptions,
  width,
  onChange,
  className,
  minWidth,
}: PropsWithoutRef<GridItemsProps>) {
  return (
    <div className={classNames(styles.gridContainer, className)}>
      {filters.map((filter, index) => (
        <div
          onClick={() => {
            onChange(filter);
          }}
          className={classNames(
            styles.item,
            index === 0 ? styles.first : '',
            index === filters.length - 1 ? styles.last : '',
            selectedOptions &&
              selectedOptions.find((x) => filter.value === x.value)
              ? styles.selected
              : '',
          )}
          style={{
            width: `${width}`,
            padding: !width ? '2px 10px' : '0',
            minWidth: `${minWidth}`,
          }}
          key={index}
        >
          {filter.name}
        </div>
      ))}
    </div>
  );
}

export default GridItems;
