import ProductListTable, {
  sortChangeHandler,
} from '../../ProductListTable/ProductListTable';
import { PropsWithoutRef, useState } from 'react';
import { SearchResultRange } from '../../../../models/search-result-range';
import { useIntl } from 'react-intl';
import { TableColumn } from '../../../../models/table-column';
import { sortMethodWithOrderByColumn } from '../../../../utils/sort.util';

interface RangeChildrenTableProps {
  ranges: SearchResultRange[];
}

/**
 * range children table
 * @param ranges ranges
 */
function RangeChildrenTable({
  ranges,
}: PropsWithoutRef<RangeChildrenTableProps>) {
  const intl = useIntl();

  // table sorts
  const [sortBy, setSortBy] = useState('name');
  const [sortByProperty, setSortByProperty] = useState('name');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [data, setData] = useState(() =>
    [...ranges].sort(sortMethodWithOrderByColumn(sortBy, sortDirection)),
  );

  // table columns
  const columns: TableColumn<SearchResultRange>[] = [
    {
      key: 'name',
      label: intl.formatMessage({ id: 'range-name-label' }),
      property: 'name',
      width: '20%',
    },
    {
      key: 'description',
      label: intl.formatMessage({ id: 'description-label' }),
      property: 'description',
      width: '80%',
    },
  ];

  /**
   * handle sort change
   * @param column column
   */
  const onSortChange = (column: TableColumn<any>) => {
    const changes = sortChangeHandler(
      [sortBy, setSortBy],
      [sortByProperty, setSortByProperty],
      [sortDirection, setSortDirection],
      column,
    );

    const sorted = [...ranges].sort(
      sortMethodWithOrderByColumn(changes.sortBy, changes.sortDirection),
    );

    setData(sorted);
  };

  return (
    <ProductListTable
      data={data}
      sortBy={sortBy}
      sortDirection={sortDirection}
      columns={columns}
      background={'dark'}
      onSortChange={onSortChange}
      showCheckbox={false}
    />
  );
}

export default RangeChildrenTable;
