import styles from './Footer.module.scss';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import { RootStore } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { openContactUsModal } from '../../../features/contactUsSlice';
import config from '../../../configs/config';

function Footer() {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { sector, welcomeInfo } = useSelector(
    (state: RootStore) => state.welcomeInfo,
  );

  return (
    <div className={styles.container}>
      <div className={styles.leftGroup}>
        <div className={styles.corpName}>
          {intl.formatMessage({ id: 'company-name' })}
        </div>

        <div className={classNames(styles.text, styles.copyright)}>
          {intl.formatMessage({ id: 'copyright-label' })}
        </div>
      </div>

      <div className={styles.rightGroup}>
        <a
          target={'_blank'}
          href={`${welcomeInfo?.country?.privacyUrl}`}
          className={styles.text}
          rel="noreferrer"
        >
          <FormattedMessage id={'privacy-statement-label'} />
        </a>
        <a
          target={'_blank'}
          href={`${welcomeInfo?.country?.legalUrl}`}
          className={styles.text}
          rel="noreferrer"
        >
          <FormattedMessage id={'legal-notice-label'} />
        </a>
        {sector === 'directory-marine-services' && (
          <>
            <a
              target={'_blank'}
              href={'/docs/Castrol_Marine_Terms_Conditions_of_Sale_2022.pdf'}
              className={styles.text}
              rel="noreferrer"
            >
              <FormattedMessage id={'dms-terms-and-conditions-label'} />
            </a>
          </>
        )}
        {sector === 'oil-gas' && (
          <a
            target={'_blank'}
            href={'/docs/Energy_Terms_Conditions_of_Sale_2022.pdf'}
            className={styles.text}
            rel="noreferrer"
          >
            <FormattedMessage id={'oil-gas-terms-and-conditions-label'} />
          </a>
        )}
        <Link
          to={'/'}
          className={styles.text}
          onClick={(e) => {
            e.preventDefault();
            if (sector === config.MARINE_SECTOR) {
              dispatch(openContactUsModal({}));
            } else {
              window.open(config.CONTACT_US_LINK);
            }
          }}
        >
          <FormattedMessage id={'contact-us-label'} />
        </Link>
      </div>
    </div>
  );
}

export default Footer;
