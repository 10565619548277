/* eslint-disable no-debugger */
import html2canvas from 'html2canvas';

export const takeScreenShot = (node: any, type: string, quality: number) => {
  if (!node) {
    throw new Error('You should provide correct html node.');
  }

  return html2canvas(node.current, {
    width: node.current.scrollWidth,
    height: node.current.scrollHeight,
    onclone: (_documentObject, element) => {
      element.style.overflow = 'visible';
    },
  })
    .then((canvas) => {
      const croppedCanvas = document.createElement('canvas');
      const croppedCanvasContext = croppedCanvas.getContext('2d');
      // init data
      const cropPositionTop = 0;
      const cropPositionLeft = 0;
      const cropWidth = canvas.width;
      const cropHeight = canvas.height;

      croppedCanvas.width = cropWidth;
      croppedCanvas.height = cropHeight;

      croppedCanvasContext?.drawImage(
        canvas,
        cropPositionLeft,
        cropPositionTop,
      );

      const base64Image = croppedCanvas.toDataURL(type, quality);

      const a = document.createElement('a');
      a.href = base64Image;
      a.download = 'table.jpg';
      a.click();
      return base64Image;
    })
    .catch((err) => {
      console.error('Error in downloading image', err);
    });
};
