import styles from './SearchSummaryColumn.module.scss';
import SearchResultColumn from '../../SearchResultColumn/SearchResultColumn';
import BrowseItem from '../../SearchModalBrowser/BrowseItem/BrowseItem';
import { PropsWithoutRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ProductCategory } from '../../../../../models/product-category';
import { Application } from '../../../../../models/application';
import cn from 'classnames';
import { SearchCountrySummary } from '../../../../../models/search-country-summary';
import { SearchPortSummary } from '../../../../../models/search-port-summary';
import { SearchRangeSummary } from '../../../../../models/search-range-summary';
import { SearchFamilySummary } from '../../../../../models/search-family-summary';
import { SearchProductSummary } from '../../../../../models/search-product-summary';
import { SearchOemSummary } from '../../../../../models/search-oem-summary';
import { SearchDistributorSummary } from '../../../../../models/search-distributor-summary';
import _ from 'lodash';

type HasProductCounts =
  | SearchCountrySummary
  | SearchPortSummary
  | SearchFamilySummary
  | SearchRangeSummary
  | SearchOemSummary
  | ProductCategory
  | Application;

interface SearchSummaryColumnProps {
  data:
    | SearchCountrySummary[]
    | SearchPortSummary[]
    | SearchFamilySummary[]
    | SearchRangeSummary[]
    | SearchProductSummary[]
    | SearchOemSummary[]
    | SearchDistributorSummary[]
    | ProductCategory[]
    | Application[];
  type:
    | 'country'
    | 'port'
    | 'oem'
    | 'distributor'
    | 'family'
    | 'range'
    | 'product'
    | 'category'
    | 'application';
  showSeeAll?: boolean;
  search: string;
  className?: string;
  showAllLink?: string;
  onClose?: () => void;
}

/**
 * search summary column
 * @param data data
 * @param type column type
 * @param showSeeAll see all button showing flag
 * @param search search text
 * @param className classname
 * @param showAllLink show all link
 * @param onClose close the modal
 */
function SearchSummaryColumn({
  data,
  type,
  showSeeAll = false,
  search,
  className,
  showAllLink,
  onClose,
}: PropsWithoutRef<SearchSummaryColumnProps>) {
  const intl = useIntl();
  const hasData = (data?.length || 0) > 0;
  const noMatchedResults = (
    <div className={styles.noMatchedResults}>
      <FormattedMessage id={'no-matched-results-label'} />
    </div>
  );

  let title = '';

  // limit display to `10`
  data = (data as any[]).filter((item, index) => {
    return index < 10;
  });

  switch (type) {
    case 'application': {
      title = intl.formatMessage({ id: 'applications-label' });
      break;
    }

    case 'category': {
      title = intl.formatMessage({ id: 'product-categories-label' });
      break;
    }

    case 'country': {
      title = intl.formatMessage({ id: 'countries-label' });
      break;
    }

    case 'port': {
      title = intl.formatMessage({ id: 'ports-label' });
      break;
    }

    case 'family': {
      title = intl.formatMessage({ id: 'product-families-label' });
      break;
    }

    case 'range': {
      title = intl.formatMessage({ id: 'product-ranges-label' });
      break;
    }

    case 'product': {
      title = intl.formatMessage({ id: 'products-label' });
      break;
    }

    case 'oem': {
      title = intl.formatMessage({ id: 'oems-label' });
      break;
    }

    case 'distributor': {
      title = intl.formatMessage({ id: 'distributors-label' });
      break;
    }
  }

  return (
    <SearchResultColumn
      className={cn(styles.resultContainer, className)}
      showSeeAll={showSeeAll}
      showAllLink={showAllLink}
      title={title}
      onClose={onClose}
      hasResult={hasData}
    >
      {hasData ? (
        <div>
          {(data || []).map((item) => {
            let description: string[] = [];
            let link = '#';
            let hasOemCount = false;

            // product counts
            const productCount = (item as HasProductCounts).products || 0;

            const productMessage = intl.formatMessage(
              {
                id: 'product-counts-label',
              },
              {
                count: productCount,
              },
            );

            switch (type) {
              case 'country': {
                const ports = (item as SearchCountrySummary).portCount || 0;
                const portsMessage = intl.formatMessage(
                  {
                    id: 'port-counts-label',
                  },
                  {
                    count: ports,
                  },
                );

                link = `/port-results?countryId=${item.id}`;
                description = ports > 0 ? [portsMessage] : [];

                break;
              }

              case 'port': {
                const port = item as SearchPortSummary;

                link = `/port/${port.friendlyUrl}`;
                description = [port.country, productMessage];
                break;
              }

              case 'family': {
                const family = item as SearchFamilySummary;

                link = `/family/${family.friendlyUrl}`;
                description = [productMessage, family.description];
                break;
              }

              case 'range': {
                const range = item as SearchRangeSummary;

                link = `/range/${range.friendlyUrl}`;
                description = [
                  range.category,
                  productMessage,
                  range.description,
                ];
                break;
              }

              case 'product': {
                const product = item as SearchProductSummary;

                link = `/product/${product.friendlyUrl}`;
                description = [product.category, product.description];
                hasOemCount = product.hasOemApproved;
                break;
              }

              case 'category': {
                link = `/search-result?from=browse&category=${item.id}`;
                description = [productMessage];
                break;
              }

              case 'application': {
                link = `/search-result?from=browse&application=${item.id}`;
                description = [productMessage];
                break;
              }

              case 'oem': {
                const oem = item as SearchOemSummary;

                link = `/admin/oem/${oem.id}`;
                description = [productMessage];
                break;
              }

              case 'distributor': {
                const distributor = item as SearchDistributorSummary;

                link = `/distributor/${distributor.friendlyUrl}`;
                description = [distributor.country];
                break;
              }
            }

            return (
              <BrowseItem
                className={styles.browseItem}
                key={item.id}
                id={`${item.id}`}
                data={item}
                to={link}
                name={item.name}
                inactive={'active' in item ? !item.active : undefined}
                highlightText={search}
                description={_.compact(description).join(' - ')}
                showOemIcon={hasOemCount}
                onLinkClick={onClose}
                type={type}
              />
            );
          })}
        </div>
      ) : (
        noMatchedResults
      )}
    </SearchResultColumn>
  );
}

export default SearchSummaryColumn;
