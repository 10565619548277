import Button from '../../Button/Button';
import styles from './AdditionalButton.module.scss';
import { PropsWithoutRef } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';

interface AdditionalButtonProps {
  type: 'products' | 'ranges' | 'ports';
  onClick: () => void;
  counts: number;
  opened: boolean;
  className: string;
}

/**
 * additional toggle button for product list item
 * @param type type
 * @param onClick click handler
 * @param counts counts
 * @param opened opened state
 * @param className classname
 */
function AdditionalButton({
  type,
  onClick,
  counts,
  opened,
  className,
}: PropsWithoutRef<AdditionalButtonProps>) {
  const intl = useIntl();
  const hide = intl.formatMessage({ id: `hide-${type}-label` });
  const show = intl.formatMessage({ id: `show-${type}-label` });

  return (
    <Button
      onClick={() => onClick()}
      color={'transparent'}
      className={cn(styles.button, className)}
    >
      {opened ? hide : show} ({counts})
    </Button>
  );
}

export default AdditionalButton;
