import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import styles from './PortDetailFooter.module.scss';
import { ReactComponent as Phone } from '../../../icons/phone.svg';
import { ReactComponent as Email } from '../../../icons/email.svg';
import { ReactComponent as Fax } from '../../../icons/fax.svg';
import { ReactComponent as Web } from '../../../icons/web.svg';
import IconField from '../../common/IconField/IconField';
import { OrderContact } from '../../../models/order-contacts';
import { PropsWithoutRef, useLayoutEffect, useMemo, useState } from 'react';
import FormField from '../../common/FormField/FormField';
import Select from '../../common/Select/Select';
import { MountService } from '../../../services/MountService';
import { CountryItem } from '../../../models/lookups';

interface PortDetailFooterProps {
  contact: OrderContact | undefined;
  allContacts: OrderContact[] | undefined;
  allCountries: CountryItem[];
}

/**
 * footer component.
 * @param contact the contact details
 * @param allContacts all the contacts
 */
function PortDetailFooter({
  contact,
  allContacts,
  allCountries,
}: PropsWithoutRef<PortDetailFooterProps>) {
  const mountService = useMemo(() => new MountService(), []);
  const [currentContact, setCurrentContact] = useState<
    OrderContact | undefined
  >(contact);
  const [currentCountry, setCurrentCountry] = useState<string | undefined>();
  const [showCountries, setShowCountries] = useState<boolean>(false);

  mountService.addDispatch('setCurrentContact', setCurrentContact);
  mountService.addDispatch('setCurrentCountry', setCurrentCountry);

  useLayoutEffect(() => {
    mountService.mount();
    if (contact && allCountries && allCountries.length > 0) {
      mountService.callDispatch('setCurrentContact', contact);
      mountService.callDispatch(
        'setCurrentCountry',
        allCountries.find((x) => x.id === contact.countryId)?.id.toString(),
      );
    }
    return () => {
      mountService.unmount();
    };
  }, [contact, mountService, allCountries]);

  return (
    <>
      {allCountries && allCountries.length && (
        <div className={styles.container}>
          <div className={classNames(styles.left, styles.item)}>
            <h3>
              <FormattedMessage id={'how-to-order-label'} />
            </h3>
            <div
              className={classNames(styles.description1, styles.description)}
            >
              <FormattedMessage id={'order-description-1'} />
            </div>
            <div
              className={classNames(styles.description2, styles.description)}
            >
              <FormattedMessage id={'order-description-2'} />
            </div>
            <div
              className={classNames(styles.description3, styles.description)}
            >
              <FormattedMessage id={'order-description-3'} />
              <span
                className={classNames(styles.link)}
                onClick={() => setShowCountries(true)}
              >
                <FormattedMessage id={'change-region-label'} />
              </span>
            </div>
            {allCountries && allCountries.length && showCountries && (
              <FormField className={styles.formField} label={''}>
                <Select
                  required={true}
                  className={styles.formControl}
                  value={currentCountry}
                  options={allCountries.map((x) => ({
                    value: x.id.toString(),
                    name: x.name,
                  }))}
                  onChange={(value) => {
                    setCurrentCountry(value);
                    setCurrentContact(
                      allContacts?.find(
                        (x) => x.countryId === parseInt(value || ''),
                      ),
                    );
                  }}
                  placeholder={''}
                />
              </FormField>
            )}
          </div>
          {currentContact && (
            <div className={classNames(styles.right, styles.item)}>
              <div className={classNames(styles.address)}>
                {currentContact.address?.split(/\n|\\n/).map(function (item) {
                  return (
                    <span key={item}>
                      {item}
                      <br />
                    </span>
                  );
                })}
              </div>

              {currentContact.phones.map((phone, idx) => (
                <IconField
                  key={phone.name + idx}
                  className={styles.icon}
                  icon={<Phone />}
                  label={phone.name}
                  value={phone.value}
                />
              ))}

              {currentContact.emails.map((email, idx) => (
                <IconField
                  key={email.name + idx}
                  className={styles.icon}
                  icon={<Email />}
                  label={email.name}
                  value={email.value}
                />
              ))}

              {currentContact.outOfHoursPhones.map((outOfHoursPhone, idx) => (
                <IconField
                  key={outOfHoursPhone.name + idx}
                  className={styles.icon}
                  icon={<Phone />}
                  label={outOfHoursPhone.name}
                  value={outOfHoursPhone.value}
                />
              ))}

              {currentContact.faxes.map((fax, idx) => (
                <IconField
                  key={fax.name + idx}
                  className={styles.icon}
                  icon={<Fax />}
                  label={fax.name}
                  value={fax.value}
                />
              ))}

              {currentContact.others.map((other, idx) => (
                <IconField
                  key={other.name + idx}
                  className={styles.icon}
                  icon={<Web />}
                  label={other.name}
                  value={other.value}
                />
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default PortDetailFooter;
