import styles from './LoadingSpinner.module.scss';
import cn from 'classnames';
import { PropsWithoutRef } from 'react';

interface LoadingSpinnerProps {
  className?: string;
}

/**
 * loading spinner
 * @param className classname
 */
function LoadingSpinner({ className }: PropsWithoutRef<LoadingSpinnerProps>) {
  return (
    <div className={cn(styles.container, className)}>
      <svg className={styles.loader} viewBox="0 0 24 24">
        <circle className={styles.loaderStroke} cx="12" cy="12" r="10" />
      </svg>
    </div>
  );
}

export default LoadingSpinner;
