import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
  GenericFormProps,
  GeneralCharacteristicsType,
} from '../../../../../../models/admin-form-types';
import { RootStore } from '../../../../../../store';
import FormWrapper from '../../../../../../components/Admin/FormWrapper/FormWrapper';
import InputRow from '../../../../../../components/Admin/InputRow/InputRow';
import StepContentContainer from '../../../../../../components/Admin/StepContentContainer/StepContentContainer';
import FormRow from '../../../../../../components/Admin/FormRow/FormRow';
import { useMemo } from 'react';
import '../../../../../../components/Admin/shared.scss';
import SelectFormRow from '../../../../../../components/Admin/SelectFormRow/SelectFormRow';
import CheckboxList from '../../../../../../components/Admin/CheckboxList/CheckboxList';
import CheckboxRow from '../../../../../../components/Admin/CheckboxRow/CheckboxRow';

type GeneralCharacteristicsProps =
  GenericFormProps<GeneralCharacteristicsType> & {
    formValue: GeneralCharacteristicsType;
  };

const GeneralCharacteristics = ({
  formValue,
  handleValueChange,
}: GeneralCharacteristicsProps) => {
  const intl = useIntl();
  const {
    viscosityIndices,
    mineralOils,
    corrosionProtections,
    concentrationRanges,
    nlgis,
    baseOilTypes,
    thickeners,
    metalTypes,
    characteristics,
    customerOffers,
  } = useSelector((state: RootStore) => state.lookups);

  const viscosityIndexOptions = useMemo(() => {
    return viscosityIndices.map((item) => ({
      name: item.name,
      value: item.id,
    }));
  }, [viscosityIndices]);

  const mineralOilOptions = useMemo(() => {
    const empty = [
      {
        name: intl.formatMessage({
          id: 'add-product-select-placeholder',
        }),
        value: null as any,
      },
    ];
    return empty.concat(
      mineralOils.map((idx) => ({
        name: idx.name,
        value: idx.id,
      })),
    );
  }, [mineralOils]);

  const corrosionProtectionOptions = useMemo(() => {
    const empty = [
      {
        name: intl.formatMessage({
          id: 'add-product-select-placeholder',
        }),
        value: null as any,
      },
    ];
    return empty.concat(
      corrosionProtections.map((item) => ({
        name: item.name,
        value: item.id,
      })),
    );
  }, [corrosionProtections]);

  const concentrationRangeOptions = useMemo(() => {
    const empty = [
      {
        name: intl.formatMessage({
          id: 'add-product-select-placeholder',
        }),
        value: null as any,
      },
    ];

    return empty.concat(
      concentrationRanges.map((item) => ({
        name: item.name,
        value: item.id,
      })),
    );
  }, [concentrationRanges]);

  const characteristicOptions = useMemo(() => {
    const empty = [
      {
        name: intl.formatMessage({
          id: 'add-product-select-placeholder',
        }),
        value: null as any,
      },
    ];

    return empty.concat(
      characteristics.map((item) => ({
        name: item.name,
        value: item.id,
      })),
    );
  }, [characteristics]);

  const customerOfferOptions = useMemo(() => {
    const empty = [
      {
        name: intl.formatMessage({
          id: 'add-product-select-placeholder',
        }),
        value: null as any,
      },
    ];

    return empty.concat(
      customerOffers.map((item) => ({
        name: item.name,
        value: item.id,
      })),
    );
  }, [customerOffers]);

  const nlgiItems = useMemo(() => {
    return nlgis.map((nlgi) => ({
      label: nlgi.name,
      id: nlgi.id,
    }));
  }, [nlgis]);

  const oilTypeItems = useMemo(() => {
    return (baseOilTypes || []).map((item) => ({
      label: item.name,
      id: item.id,
    }));
  }, [baseOilTypes]);

  const thickenerItems = useMemo(() => {
    return (thickeners || []).map((item) => ({
      label: item.name,
      id: item.id,
    }));
  }, [thickeners]);

  const metalTypeItems = useMemo(() => {
    return metalTypes.map((item) => ({
      label: item.name,
      id: item.id,
    }));
  }, [metalTypes]);

  return (
    <StepContentContainer
      title={intl.formatMessage({ id: 'product-characteristics-title' })}
    >
      <FormWrapper
        title={intl.formatMessage({
          id: 'add-product-step-general-characteristics',
        })}
      >
        <div className="columnRow">
          <InputRow
            className="spanHalfOnDesktop"
            labelId="characteristics-viscosity-label"
            value={formValue.viscosity}
            onChange={(value: string) => handleValueChange('viscosity', value)}
            tooltip={intl.formatMessage({
              id: 'characteristics-viscosity-tooltip',
            })}
            required={false}
          />
        </div>
        <div className="columnRow">
          <SelectFormRow
            className="spanHalfOnDesktop"
            labelId="characteristics-viscosity-index-label"
            options={viscosityIndexOptions}
            value={formValue.viscosityIndex}
            onChange={(value: number) =>
              handleValueChange('viscosityIndex', value)
            }
            required={false}
            tooltip={intl.formatMessage({
              id: 'characteristics-viscosity-index-tooltip',
            })}
          />
        </div>
        <div className="columnRow">
          <CheckboxList
            className="spanHalfOnDesktop"
            labelId="characteristics-nlgi-label"
            items={nlgiItems}
            selectedIds={formValue.nlgi}
            onClick={(id: number) => {
              const idx = formValue.nlgi.indexOf(id);
              const newValues = [...formValue.nlgi];
              if (idx >= 0) {
                newValues.splice(idx, 1);
              } else {
                newValues.push(id);
              }
              handleValueChange('nlgi', newValues);
            }}
            tooltip={intl.formatMessage({ id: 'characteristics-nlgi-tooltip' })}
            required={false}
          />
        </div>
        <div className="columnRow">
          <InputRow
            labelId="characteristics-min-temperature-label"
            value={formValue.minTemperature}
            onChange={(value: string) =>
              handleValueChange('minTemperature', value)
            }
            tooltip={intl.formatMessage({
              id: 'characteristics-min-temperature-tooltip',
            })}
            required={false}
          />
          <InputRow
            labelId="characteristics-max-temperature-label"
            value={formValue.maxTemperature}
            onChange={(value: string) =>
              handleValueChange('maxTemperature', value)
            }
            tooltip={intl.formatMessage({
              id: 'characteristics-max-temperature-tooltip',
            })}
            required={false}
          />
        </div>
        <div className="columnRow">
          <InputRow
            labelId="characteristics-grade-label"
            value={formValue.grade}
            onChange={(value: string) => handleValueChange('grade', value)}
            tooltip={intl.formatMessage({
              id: 'characteristics-grade-tooltip',
            })}
            required={false}
          />
          <InputRow
            labelId="characteristics-sub-grade-label"
            value={formValue.subGrade}
            onChange={(value: string) => handleValueChange('subGrade', value)}
            tooltip={intl.formatMessage({
              id: 'characteristics-sub-grade-tooltip',
            })}
            required={false}
          />
        </div>
        <div className="columnRow">
          <CheckboxList
            labelId="characteristics-oil-type-label"
            items={oilTypeItems}
            selectedIds={formValue.oilTypes}
            onClick={(id: number) => {
              const idx = formValue.oilTypes.indexOf(id);
              const newValues = [...formValue.oilTypes];
              if (idx >= 0) {
                newValues.splice(idx, 1);
              } else {
                newValues.push(id);
              }
              handleValueChange('oilTypes', newValues);
            }}
            tooltip={intl.formatMessage({
              id: 'characteristics-oil-type-tooltip',
            })}
            required={false}
          />
          <CheckboxList
            labelId="characteristics-thickener-label"
            items={thickenerItems}
            selectedIds={formValue.thickener}
            onClick={(id: number) => {
              const idx = formValue.thickener.indexOf(id);
              const newValues = [...formValue.thickener];
              if (idx >= 0) {
                newValues.splice(idx, 1);
              } else {
                newValues.push(id);
              }
              handleValueChange('thickener', newValues);
            }}
            tooltip={intl.formatMessage({
              id: 'characteristics-thickener-tooltip',
            })}
            required={false}
          />
          <CheckboxList
            labelId="characteristics-metal-type-label"
            items={metalTypeItems}
            selectedIds={formValue.metalType}
            onClick={(id: number) => {
              const idx = formValue.metalType.indexOf(id);
              const newValues = [...formValue.metalType];
              if (idx >= 0) {
                newValues.splice(idx, 1);
              } else {
                newValues.push(id);
              }
              handleValueChange('metalType', newValues);
            }}
            tooltip={intl.formatMessage({
              id: 'characteristics-metal-type-tooltip',
            })}
            required={false}
          />
        </div>
        <div className="columnRow">
          <FormRow required={false}>
            <CheckboxRow
              label={
                <FormattedMessage id="characteristics-ester-ep-additives-label" />
              }
              checked={formValue.esterEpAdditives}
              onClick={() =>
                handleValueChange(
                  'esterEpAdditives',
                  !formValue.esterEpAdditives,
                )
              }
              tooltip={intl.formatMessage({
                id: 'characteristics-ester-ep-additives-tooltip',
              })}
            />
          </FormRow>
        </div>
        <div className="columnRow">
          <SelectFormRow
            labelId="characteristics-mineral-oil-label"
            options={mineralOilOptions}
            value={formValue.mineralOil}
            onChange={(value: number) => handleValueChange('mineralOil', value)}
            required={false}
            tooltip={intl.formatMessage({
              id: 'characteristics-mineral-oil-tooltip',
            })}
            placeholder={intl.formatMessage({
              id: 'add-product-select-placeholder',
            })}
          />
          <SelectFormRow
            labelId="characteristics-corrosion-protection-label"
            options={corrosionProtectionOptions}
            value={formValue.corrosionProtection}
            onChange={(value: number) =>
              handleValueChange('corrosionProtection', value)
            }
            required={false}
            tooltip={intl.formatMessage({
              id: 'characteristics-corrosion-protection-tooltip',
            })}
            placeholder={intl.formatMessage({
              id: 'add-product-select-placeholder',
            })}
          />
        </div>
        <div className="columnRow">
          <SelectFormRow
            labelId="characteristics-conentration-range-label"
            options={concentrationRangeOptions}
            value={formValue.concentrationRange}
            onChange={(value: number) =>
              handleValueChange('concentrationRange', value)
            }
            required={false}
            tooltip={intl.formatMessage({
              id: 'characteristics-conentration-range-tooltip',
            })}
            placeholder={intl.formatMessage({
              id: 'add-product-select-placeholder',
            })}
          />
        </div>
        <div className="columnRow">
          <SelectFormRow
            labelId="characteristics-characteristics-label"
            options={characteristicOptions}
            value={formValue.characteristics}
            onChange={(value: number) =>
              handleValueChange('characteristics', value)
            }
            required={false}
            tooltip={intl.formatMessage({
              id: 'characteristics-characteristics-tooltip',
            })}
            placeholder={intl.formatMessage({
              id: 'add-product-select-placeholder',
            })}
          />
          <SelectFormRow
            labelId="characteristics-customer-offer-label"
            options={customerOfferOptions}
            value={formValue.customerOffer}
            onChange={(value: number) =>
              handleValueChange('customerOffer', value)
            }
            required={false}
            tooltip={intl.formatMessage({
              id: 'characteristics-customer-offer-tooltip',
            })}
            placeholder={intl.formatMessage({
              id: 'add-product-select-placeholder',
            })}
          />
        </div>
      </FormWrapper>
    </StepContentContainer>
  );
};

export default GeneralCharacteristics;
