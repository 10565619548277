import { PropsWithChildren } from 'react';
import styles from './UserActivityTable.module.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import OemListTable from '../../../../../components/common/OemListTable/OemListTable';
import cn from 'classnames';
import { TableColumn } from '../../../../../models/table-column';
import Paginator from '../../../../../components/common/Paginator/Paginator';
import { UserActivity } from '../../../../../models/user-acitiviy-report-types';
import { ReactComponent as XlsxFileIcon } from '../../../../../icons/xlsx-file.svg';
import moment from 'moment';
import useWindowDimensions from '../../../../../hooks/useWindowDimensions ';

type UserActivityTableType = {
  reportData: UserActivity[];
  page: number;
  perPage: number;
  total: number;
  sortBy: string;
  sortDirection: 'asc' | 'desc';
  onClickPage: (page: number) => void;
  onClickPerPage: (page: number) => void;
  onSortChange: (column: any) => void;
  exportToExcel: () => void;
};

const UserActivityTable = ({
  reportData,
  page,
  perPage,
  total,
  sortBy,
  sortDirection,
  onClickPage,
  onClickPerPage,
  onSortChange,
  exportToExcel,
}: PropsWithChildren<UserActivityTableType>) => {
  const intl = useIntl();
  const { width } = useWindowDimensions();
  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });

  const tableData = reportData.map((item) => {
    return {
      ...item,
    };
  });

  const columns: TableColumn<any>[] = [
    {
      key: 'user.firstName',
      property: 'user.firstName',
      label: getTranslatedMessage('user-activity-page-first-name'),
      width: '10%',
      render: (data) =>
        data?.user?.firstName
          ? data?.user?.firstName
          : width <= 540
          ? 'Anonymous'
          : '',
    },
    {
      key: 'user.lastName',
      property: 'user.lastName',
      label: getTranslatedMessage('user-activity-page-last-name'),
      width: '10%',
    },
    {
      key: 'user.email',
      property: 'user.email',
      label: getTranslatedMessage('user-activity-page-email'),
      width: '10%',
    },
    {
      key: 'role',
      property: 'role',
      label: getTranslatedMessage('user-activity-role'),
      width: '10%',
    },
    {
      key: 'country.name',
      property: 'country.name',
      label: getTranslatedMessage('user-activity-country'),
      width: '10%',
    },
    {
      key: 'numberOfLogins',
      property: 'numberOfLogins',
      label: getTranslatedMessage('user-activity-logins'),
      width: '10%',
    },
    {
      key: 'numberOfPdsSdsDownloads',
      property: 'numberOfPdsSdsDownloads',
      label: getTranslatedMessage('user-activity-num-of-PDS'),
      width: '10%',
    },
    {
      key: 'numberOfApprovalDocumentDownloads',
      property: 'numberOfApprovalDocumentDownloads',
      label: getTranslatedMessage('user-activity-num-of-OEM'),
      width: '10%',
    },
    {
      key: 'rememberPeriod',
      property: 'rememberPeriod',
      label: getTranslatedMessage('user-activity-remember-periods'),
      width: '10%',
    },
    {
      key: 'lastLoginAt',
      property: 'lastLoginAt',
      label: getTranslatedMessage('user-activity-last-login-date'),
      width: '10%',
      render: (data) => (
        <span>{moment(data.lastLoginAt).format('Do MMMM YYYY')}</span>
      ),
    },
  ];

  return (
    <div className={styles.tableContainer}>
      <div className={styles.table}>
        <div className={styles.tableTitle}>
          {getTranslatedMessage('port-product-page-result')}
          <div
            className={cn(styles.exportToExcel, styles.desktop)}
            onClick={() => exportToExcel()}
          >
            <XlsxFileIcon />
            <FormattedMessage id={'oem-table-field-export-to-excel'} />
          </div>
        </div>
        <OemListTable
          data={tableData}
          sortBy={sortBy}
          sortDirection={sortDirection}
          columns={columns}
          showCheckbox={false}
          onSortChange={onSortChange}
          columnClassName={styles.tableColumn}
          rowClassName={styles.tableRow}
          className={styles.tableContainer}
          headerClassName={styles.headerClassName}
          headerColumnClassName={styles.headerColumn}
        />
        <Paginator
          page={page}
          perPage={perPage}
          total={total}
          onPageChange={onClickPage}
          onPerPageChange={onClickPerPage}
        />
      </div>
    </div>
  );
};

export default UserActivityTable;
