import styles from './SearchResultColumn.module.scss';
import { PropsWithChildren, useRef, useState, WheelEvent } from 'react';
import MoreButton from '../../../common/MoreButton/MoreButton';
import { ReactComponent as Caret } from '../../../../icons/caret.svg';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';

interface SearchResultColumnProps {
  title: string;
  showSeeAll?: boolean;
  className?: string;
  contentClassName?: string;
  onWheelEnd?: () => void;
  hasNextPage?: boolean;
  showAllLink?: string;
  onClose?: () => void;
  hasResult?: boolean;
}

/**
 * search result column
 * @param title title
 * @param showSeeAll show see all button
 * @param className class name for main container
 * @param contentClassName content container class name
 * @param children children
 * @param onWheelEnd wheel end handler
 * @param hasNextPage has next page flag
 * @param showAllLink show all link
 * @param onClose close the modal
 * @param hasResult has result.
 */
function SearchResultColumn({
  title,
  showSeeAll = false,
  className,
  contentClassName,
  children,
  onWheelEnd,
  hasNextPage = false,
  showAllLink,
  onClose,
  hasResult,
}: PropsWithChildren<SearchResultColumnProps>) {
  const intl = useIntl();

  const containerRef = useRef<null | HTMLDivElement>(null);
  const [open, setOpen] = useState(hasResult);

  /**
   * wheel event handler
   * @param event wheel event
   */
  /* istanbul ignore next */
  const onWheelEvent = (event: WheelEvent<HTMLDivElement>) => {
    if (containerRef.current) {
      event.persist();

      const { scrollHeight, scrollTop, offsetHeight } = containerRef.current;

      const isScrollBottom =
        event.deltaY > 0 && scrollTop + offsetHeight >= scrollHeight;

      // go to next page when scroll wheeled on bottom
      // and pages not end
      if (isScrollBottom && hasNextPage && onWheelEnd) {
        onWheelEnd();
      }
    }
  };

  return (
    <div
      ref={containerRef}
      onWheel={onWheelEnd ? (event) => onWheelEvent(event) : undefined}
      className={cn(styles.container, className)}
    >
      <div className={styles.header}>
        <h3>{title}</h3>

        {showSeeAll && (
          <MoreButton
            className={styles.moreButton}
            to={showAllLink}
            onClose={onClose}
          >
            {intl.formatMessage({ id: 'see-all-title' })}
          </MoreButton>
        )}
        <Caret
          className={classNames(styles.icon, open ? styles.open : '')}
          onClick={() => setOpen(!open)}
        />
      </div>

      <div
        className={cn(
          classNames(styles.contentContainer, open ? styles.open : ''),
          contentClassName,
        )}
      >
        {children}
        {showSeeAll && (
          <MoreButton
            className={styles.moreButtonMobile}
            to={showAllLink}
            onClose={onClose}
          >
            {intl.formatMessage({ id: 'see-all-title' })}
          </MoreButton>
        )}
      </div>

      {hasNextPage && (
        <div className={classNames(styles.loadMore, open ? styles.open : '')}>
          <FormattedMessage id={'loading-more-label'} />
        </div>
      )}
    </div>
  );
}

export default SearchResultColumn;
