import axios from 'axios';
import config from '../configs/config';
import { LoginResponse } from '../models/login-response';
import { UserInfo } from '../models/user-info';
import store from '../store';
import { Lookups } from '../models/lookups';
import { apiInstance } from '../api/requestInterceptor';

/**
 * login as anonymous
 */
export async function loginAsAnonymous(
  culture?: string,
  sector?: string,
): Promise<LoginResponse> {
  const welcomeInfo = store.getState().welcomeInfo;
  const res = await axios.post(
    config.API_BASE_URL + '/auth/loginAsAnonymous',
    null,
    {
      headers: {
        sector: sector || welcomeInfo?.sector,
        culture: culture || welcomeInfo?.culture,
      },
    },
  );

  return res.data;
}

/**
 * get all lookups
 * @param cancelToken cancel token
 */
export async function getLookupsWithoutAuth(): Promise<Lookups> {
  // Fetch
  const res = await axios.get(config.API_BASE_URL + '/lookups', {
    headers: {
      sector: config.MARINE_SECTOR,
      culture: config.DEFAULT_CULTURE,
    },
  });

  return res.data;
}

/**
 * get user info
 */
export async function getUserInfo(): Promise<UserInfo> {
  const res = await apiInstance.get<UserInfo>('/users/me');

  return res.data;
}

/**
 * login with username and password.
 */
export async function login(
  username: string,
  password: string,
  rememberPeriod?: string,
): Promise<LoginResponse> {
  const res = await axios.post(config.API_BASE_URL + '/auth/login', {
    email: username,
    password,
    rememberPeriod,
  });

  return res.data;
}

/**
 * login with username and password.
 */
export async function loginSSO(
  token: string,
  sector: string,
  culture: string,
): Promise<LoginResponse> {
  const res = await axios.post(
    config.API_BASE_URL + '/auth/loginUsingSso',
    null,
    {
      headers: {
        sector: sector,
        culture: culture,
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return res.data;
}
