import { Ref, useCallback, ReactElement, forwardRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import { ReactComponent as Add } from '../../../icons/add.svg';
import styles from './FileInput.module.scss';

interface FileInputProps {
  file: File | null;
  onChange: (file: File) => void;
  accept?: string;
  addedFileName?: string;
  icon?: ReactElement;
  placeholderId?: string;
  className?: string;
  inputTextClassName?: string;
  showIconBtn?: boolean;
}

function FileInput(
  {
    file,
    onChange,
    accept,
    addedFileName,
    icon,
    placeholderId,
    className,
    inputTextClassName,
    showIconBtn = true,
  }: FileInputProps,
  ref: Ref<HTMLDivElement>,
) {
  const onDrop = useCallback(
    (files) => {
      onChange(files[0]);
    },
    [onChange],
  );

  // Hooks used of react-dropzone to get a modular file uploading component
  const { getRootProps, getInputProps } = useDropzone({
    accept,
    onDrop,
  });

  const hasFiles = !!file || addedFileName;

  return (
    <div className={cn(styles.inputWrapper, className)} {...getRootProps()}>
      {showIconBtn ? icon || <Add className={styles.add} /> : ''}
      <div
        ref={ref}
        className={cn(
          styles.inputText,
          inputTextClassName,
          !file?.name && !addedFileName && styles.placeholder,
        )}
      >
        {hasFiles ? (
          file?.name || addedFileName
        ) : placeholderId ? (
          <FormattedMessage id={placeholderId} />
        ) : (
          <>
            - <FormattedMessage id={'select'} /> -
          </>
        )}
        <input type={'file'} {...getInputProps()} />
      </div>
    </div>
  );
}

export default forwardRef<HTMLDivElement, FileInputProps>(FileInput);
