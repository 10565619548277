import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FilterOption } from '../models/filter-option';
import {
  userRolesNoAnonymous,
  userVisibilities,
} from '../configs/admin-constants';

interface Pagination {
  page: number;
  perPage: number;
}

interface SortQuery {
  sortBy: string;
  sortDirection: 'asc' | 'desc';
}

interface UserFilters {
  roles: FilterOption[];
  visibilities: FilterOption[];
}

export interface UserFiltersOptions {
  roles: FilterOption[];
  visibilities: FilterOption[];
}

export interface UserFilterSliceState {
  filters: UserFiltersOptions;
  selectedFilters: UserFilters;
  pagination: Pagination;
  sortQuery: SortQuery;
}

/**
 * toggle selected filters
 * @param key filters key
 * @param filter filter option to toggle
 * @param selectedFilters selected filters
 */
function toggleSelectedFilter(
  key: keyof UserFilters,
  filter: string | FilterOption | FilterOption[],
  selectedFilters: UserFilters,
) {
  const selectedFiltersByType = selectedFilters[key];
  let filtered: string | FilterOption | FilterOption[] = [];

  if (key === 'roles' || key === 'visibilities') {
    filtered = (selectedFiltersByType as FilterOption[]).filter(
      (item) => item.value !== (filter as FilterOption).value,
    );
  } else {
    filtered = filter;
  }

  if (Array.isArray(filtered)) {
    // add
    if (filtered.length === (selectedFiltersByType as FilterOption[]).length) {
      return {
        ...selectedFilters,
        [key]: [...(selectedFiltersByType as FilterOption[]), filter],
      };
    } else {
      return {
        ...selectedFilters,
        [key]: filtered,
      };
    }
  } else {
    // remove
    return {
      ...selectedFilters,
      [key]: filtered,
    };
  }
}

/**
 * manage user filter state
 */
export const userFilterSlice = createSlice({
  name: 'user-filter',
  initialState: {
    filters: {
      roles: userRolesNoAnonymous.map((item) => ({ name: item, value: item })),
      visibilities: userVisibilities,
    },
    selectedFilters: {
      name: null,
      email: null,
      roles: [],
      visibilities: [],
    },
    pagination: {
      page: 1,
      perPage: 10,
    },
    sortQuery: {
      sortBy: 'fullName',
      sortDirection: 'asc',
    },
  } as UserFilterSliceState,
  reducers: {
    // toggle filter
    toggleFilter: (
      state,
      action: PayloadAction<{
        key: keyof UserFilters;
        filter: string | FilterOption | FilterOption[];
      }>,
    ) => {
      const { key, filter } = action.payload;
      const selectedFilters = toggleSelectedFilter(
        key,
        filter,
        state.selectedFilters,
      );

      return {
        ...state,
        selectedFilters,
      };
    },
    // clear filter for type
    clearFilter: (state, action: PayloadAction<{ key: keyof UserFilters }>) => {
      const { key } = action.payload;
      let defaultValue: FilterOption | FilterOption[] | string | null = [];

      switch (key) {
        case 'roles':
        case 'visibilities': {
          defaultValue = [];
          break;
        }
        default: {
          defaultValue = null;
          break;
        }
      }

      const selectedFilters = {
        ...state.selectedFilters,
        [key]: defaultValue,
      };

      return {
        ...state,
        selectedFilters,
      };
    },
    // clear all filters
    clearAllFilters: (state) => {
      return {
        ...state,
        selectedFilters: {
          name: null,
          email: null,
          roles: [],
          visibilities: [],
        },
      };
    },
    // Change Pagination
    changePagination: (state, action: PayloadAction<Pagination>) => {
      return {
        ...state,
        pagination: action.payload,
      };
    },
    // Change Sort
    changeSort: (state, action: PayloadAction<SortQuery>) => {
      return {
        ...state,
        sortQuery: action.payload,
      };
    },
  },
});

export const {
  toggleFilter,
  clearFilter,
  clearAllFilters,
  changePagination,
  changeSort,
} = userFilterSlice.actions;

export default userFilterSlice.reducer;
