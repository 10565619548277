import React from 'react';
import styles from './ValidationDataRow.module.scss';

export interface ValidationDataRowProps {
  children?: React.ReactNode;
}

function ValidationDataRow({ children }: ValidationDataRowProps) {
  return <div className={styles.container}>{children}</div>;
}

export default ValidationDataRow;
