import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import cn from 'classnames';

import Checkbox from '../../../common/Checkbox/Checkbox';
import RowActions from '../../RowActions/RowActions';

import { ReactComponent as RemoveGreen } from '../../../../icons/deactive-green.svg';
import { ReactComponent as RemoveWhite } from '../../../../icons/deactive-white.svg';

import {
  Actions,
  StockpointProductType,
} from '../../../../models/admin-form-types';

import styles from './ProductList.module.scss';

type ProductListProps = {
  products: StockpointProductType[];
  handleChange: (index: number, category: string) => void;
  handleRemoveProduct: (id: number) => void;
};

const ProductList = ({
  products,
  handleChange,
  handleRemoveProduct,
}: ProductListProps) => {
  const intl = useIntl();
  const [showAction, setShowAction] = useState(-1);
  const productCategoriesIds = [
    {
      key: 'offerBulkBadge',
      labelId: 'barge-label',
    },
    {
      key: 'offerBulkTruck',
      labelId: 'truck-label',
    },
    {
      key: 'offerBulkIbc',
      labelId: 'port-availability-ibc-label',
    },
    {
      key: 'offerPackDrum',
      labelId: 'port-availability-drum-label',
    },
    {
      key: 'offerPackPail',
      labelId: 'port-availability-pail-label',
    },
  ];
  const productCategories = productCategoriesIds.map((productCategory) => ({
    key: productCategory.key,
    label: intl.formatMessage({ id: productCategory.labelId }),
  }));

  const rowActions: Actions[] = [
    {
      fieldKey: 'remove',
      nameId: 'remove-label',
      icon: <RemoveGreen />,
      iconActive: <RemoveWhite />,
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.desktopContainer}>
        <div className={styles.header}>
          <span>
            <FormattedMessage id="product-label" />
          </span>
          <span>
            <FormattedMessage id="physical-offer-label" />
          </span>
          <span>
            <FormattedMessage id="actions-label" />
          </span>
        </div>
        <div className={styles.productCategories}>
          {productCategories.map((category) => (
            <span key={category.label}>{category.label}</span>
          ))}
        </div>
        <div className={styles.productsWrapper}>
          {products.map((product, index) => (
            <div
              className={cn(
                styles.productContainer,
                product.notFromProduct && styles.notFromProduct,
              )}
              key={`${product.name}-${index}`}
            >
              <span className={styles.productName}>{product.name}</span>
              <div className={styles.productCategoryCheckboxContainer}>
                {productCategories.map((category) => (
                  <div key={category.key} className={styles.checkboxWrapper}>
                    <Checkbox
                      className={styles.checkbox}
                      checked={
                        !!product[category.key as keyof StockpointProductType]
                      }
                      onClick={() =>
                        handleChange(product.productId, category.key)
                      }
                    />
                  </div>
                ))}
              </div>
              <div className={styles.settingsIconWrapper}>
                <RowActions
                  showAction={showAction === index}
                  handleShowAction={(state: boolean) => {
                    if (state) {
                      setShowAction(index);
                    } else {
                      setShowAction(-1);
                    }
                  }}
                  actions={rowActions}
                  handleActionClick={() =>
                    handleRemoveProduct(product.productId)
                  }
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.phoneContainer}>
        {products.map((product, index) => (
          <div key={product.name} className={styles.itemWrapper}>
            <div className={styles.sectionHeader}>
              <div className={styles.firstRow}>
                <span>
                  <FormattedMessage id="product-label" />
                </span>
                <RowActions
                  showAction={showAction === index}
                  handleShowAction={(state: boolean) => {
                    if (state) {
                      setShowAction(index);
                    } else {
                      setShowAction(-1);
                    }
                  }}
                  actions={rowActions}
                  handleActionClick={() => handleRemoveProduct(product.id)}
                />
              </div>
              <span className={styles.productName}>{product.name}</span>
            </div>
            <div className={styles.contentWrapper}>
              <span className={styles.physicalOfferLabel}>
                <FormattedMessage id="physical-offer-label" />
              </span>
              {productCategories.map((category) => (
                <div key={category.key} className={styles.categoryWrapper}>
                  <span>{category.label}</span>
                  <Checkbox
                    className={styles.checkbox}
                    checked={
                      !!product[category.key as keyof StockpointProductType]
                    }
                    onClick={() =>
                      handleChange(product.productId, category.key)
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductList;
