import classNames from 'classnames';
import styles from './FormDividerLine.module.scss';

interface FormDividerLineProps {
  className?: string;
  text?: string;
}

function FormDividerLine({ className, text }: FormDividerLineProps) {
  return (
    <div className={classNames(styles.dividerLine, className)}>
      <div className={styles.line}></div>
      {text && (
        <>
          <div className={styles.text}>{text}</div>
          <div className={styles.line}></div>
        </>
      )}
    </div>
  );
}

export default FormDividerLine;
