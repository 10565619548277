import { WelcomeInfo } from '../models/welcome-info';
import { UserInfo } from '../models/user-info';
import { ProductDetails } from '../models/search-result-product';

const COOKIE_ACCEPTANCE_KEY = 'COOKIE_ACCEPTANCE_KEY';
const WELCOME_INFO_KEY = 'WELCOME_INFO_KEY';
const ACCESS_TOKEN_KEY = 'ACCESS_TOKEN_KEY';
const CULTURE_KEY = 'CULTURE_KEY';
const SECTOR_KEY = 'SECTOR_KEY';
const USER_INFO_KEY = 'USER_INFO_KEY';
const RECENT_PRODUCT_INFO_KEY = 'RECENT_PRODUCT_INFO_KEY';

/**
 * set key / value to local storage
 * @param key key
 * @param value value
 */
function setToLocalStorage<T>(key: string, value: T) {
  localStorage.setItem(key, JSON.stringify(value));
}

/**
 * get value from local storage
 * @param key key
 */
function getFromLocalStorage<T>(key: string): T | undefined {
  const value = localStorage.getItem(key);

  if (value) {
    return JSON.parse(value);
  } else {
    return undefined;
  }
}

/**
 * set key / value to local storage
 * @param key key
 * @param value value
 */
function setToSessionStorage<T>(key: string, value: T) {
  sessionStorage.setItem(key, JSON.stringify(value));
}

/**
 * get value from local storage
 * @param key key
 */
function getFromSessionStorage<T>(key: string): T | undefined {
  const value = sessionStorage.getItem(key);

  if (value) {
    return JSON.parse(value);
  } else {
    return undefined;
  }
}

/**
 * set cookie acceptance
 * @param accepted accepted
 */
function setCookieAcceptance(accepted: boolean): void {
  setToLocalStorage(COOKIE_ACCEPTANCE_KEY, accepted);
}

/**
 * get cookie acceptance
 */
function getCookieAcceptance(): boolean {
  const accepted = getFromLocalStorage<boolean>(COOKIE_ACCEPTANCE_KEY);

  return accepted || false;
}

/**
 * set welcome info
 * @param industrySector industry sector
 * @param country country
 * @param language language
 */
function setWelcomeInfoToStorage({
  industrySector,
  country,
  language,
}: WelcomeInfo): void {
  setToLocalStorage(WELCOME_INFO_KEY, {
    industrySector,
    country,
    language,
  });
}

/**
 * set welcome info
 * @param industrySector industry sector
 * @param country country
 * @param language language
 */
function setWelcomeInfoToSessionStorage({
  industrySector,
  country,
  language,
}: WelcomeInfo): void {
  setToSessionStorage(WELCOME_INFO_KEY, {
    industrySector,
    country,
    language,
  });
}

/**
 * get welcome info
 */
function getWelcomeInfoFromStorage(): WelcomeInfo | undefined {
  const info = getFromLocalStorage<WelcomeInfo>(WELCOME_INFO_KEY);

  if (info) {
    return info;
  } else {
    return undefined;
  }
}

/**
 * get welcome info
 */
function getWelcomeInfoFromSessionStorage(): WelcomeInfo | undefined {
  const info = getFromSessionStorage<WelcomeInfo>(WELCOME_INFO_KEY);

  if (info) {
    return info;
  } else {
    return undefined;
  }
}

/**
 * set welcome info
 * @param productInfo
 */
function setProductInfoToSessionStorage(productInfo: ProductDetails): void {
  setToSessionStorage(RECENT_PRODUCT_INFO_KEY, productInfo);
}

/**
 * get product info
 */
function getProductInfoFromSessionStorage(): ProductDetails | undefined {
  const productInfo = getFromSessionStorage<ProductDetails>(
    RECENT_PRODUCT_INFO_KEY,
  );

  if (productInfo) {
    return productInfo;
  } else {
    return undefined;
  }
}

/**
 * set access token to storage
 * @param token token
 */
function setAccessTokenToLocalStorage(token: string): void {
  setToLocalStorage(ACCESS_TOKEN_KEY, token);
}

/**
 * get access token from storage
 */
function getAccessTokenFromLocalStorage(): string | undefined {
  const token = getFromLocalStorage<string>(ACCESS_TOKEN_KEY);

  if (token) {
    return token;
  }
}

/**
 * clear access token to storage
 */
function clearAccessTokenToLocalStorage(): void {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
}

/**
 * set culture to local storage
 * @param culture culture
 */
function setCultureToLocalStorage(culture: string): void {
  setToLocalStorage(CULTURE_KEY, culture);
}

/**
 * set sector to local storage
 * @param sector sector
 */
function setSectorToLocalStorage(sector: string): void {
  setToLocalStorage(SECTOR_KEY, sector);
}

/**
 * set culture to session storage
 * @param culture culture
 */
function setCultureToSessionStorage(culture: string): void {
  setToSessionStorage(CULTURE_KEY, culture);
}

/**
 * set sector to Session storage
 * @param sector sector
 */
function setSectorToSessionStorage(sector: string): void {
  setToSessionStorage(SECTOR_KEY, sector);
}

/**
 * get culture from local storage
 */
function getCultureFromLocalStorage(): string {
  const culture = getFromLocalStorage<string>(CULTURE_KEY);

  return culture || 'en-GB';
}

/**
 * get sector from local storage
 */
function getSectorFromLocalStorage(): string | undefined {
  return getFromLocalStorage<string>(SECTOR_KEY);
}

/**
 * get culture from Session storage
 */
function getCultureFromSessionStorage(): string {
  const culture = getFromSessionStorage<string>(CULTURE_KEY);

  return culture || 'en-GB';
}

/**
 * get sector from Session storage
 */
function getSectorFromSessionStorage(): string | undefined {
  return getFromSessionStorage<string>(SECTOR_KEY);
}

/**
 * set user info to local storage
 * @param userInfo user info
 */
function setUserInfoToLocalStorage(userInfo: UserInfo): void {
  setToLocalStorage(USER_INFO_KEY, userInfo);
}

/**
 * get user info from local storage
 */
function getUserInfoFromLocalStorage(): UserInfo | undefined {
  return getFromLocalStorage<UserInfo>(USER_INFO_KEY);
}

/**
 * clear access token to storage
 */
function clearUserInfoToLocalStorage(): void {
  localStorage.removeItem(USER_INFO_KEY);
}

export {
  setToLocalStorage,
  getFromLocalStorage,
  setCookieAcceptance,
  getCookieAcceptance,
  getWelcomeInfoFromStorage,
  setWelcomeInfoToStorage,
  getAccessTokenFromLocalStorage,
  setAccessTokenToLocalStorage,
  setCultureToLocalStorage,
  setSectorToLocalStorage,
  getCultureFromLocalStorage,
  getSectorFromLocalStorage,
  getCultureFromSessionStorage,
  getSectorFromSessionStorage,
  getWelcomeInfoFromSessionStorage,
  setCultureToSessionStorage,
  setSectorToSessionStorage,
  setWelcomeInfoToSessionStorage,
  clearAccessTokenToLocalStorage,
  setUserInfoToLocalStorage,
  getUserInfoFromLocalStorage,
  clearUserInfoToLocalStorage,
  setProductInfoToSessionStorage,
  getProductInfoFromSessionStorage,
};
