import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useIntl, FormattedMessage } from 'react-intl';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootStore } from '../../../store';

import { PreviousLink } from '../../../models/previous-link';
import NavigationHeader from '../../../components/common/NavigationHeader/NavigationHeader';
import ContainerForDrawer from '../../../components/common/ContainerForDrawer/ContainerForDrawer';
import LoadingSpinner from '../../../components/common/LoadingSpinner/LoadingSpinner';
import AnnouncementSlider from '../../../components/HomePage/Banner/AnnouncementSlider/AnnouncementSlider';
import styles from './OEMDetailPage.module.scss';
import { find, cloneDeep } from 'lodash';

import Button from '../../../components/common/Button/Button';
import {
  getOemDetail,
  downloadOemApprovalDocument,
  activateApproval,
  deactivateApproval,
  activateOem,
  deactivateOem,
  shareByEmail,
  activateDeactivateOemApproval,
  updateProductOemApproval,
} from '../../../services/OemService';
import { getAnnouncements } from '../../../services/AnnouncementService';
import { OemDetail } from '../../../models/oem-detail';
import { Announcement } from '../../../models/announcement';
import OemDetailTable from '../../../components/Admin/common/OemDetailTable/OemDetailTable';
import { ReactComponent as ShareIcon } from '../../../icons/share-green.svg';
import ShareByEmailModal from '../../../components/Admin/common/ShareByEmailModal/ShareByEmailModal';
import { storeOem, updateOemActive } from '../../../features/oemSlice';
import { addToast } from '../../../features/toastSlice';
import { showGenericModal } from '../../../features/genericModalSlice';
import withAdmin from '../../../hoc/withAdmin';
import { getDateString } from '../../../utils/date.util';

/**
 * OEM Detail page
 */
function OEMDetailPage() {
  // init hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { oemId } = useParams();
  const intl = useIntl();
  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });

  // init states.
  const [initial, setInitial] = useState(true);
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [showShareByEmail, setShowShareByEmail] = useState<boolean>(false);
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);

  const [oemDetail, setOemDetail] = useState<OemDetail>();
  const [breadcrumbLinks, setBreadcrumbLinks] = useState<PreviousLink[]>([
    {
      name: 'Home',
      to: '/home',
    },
  ]);

  const { oemActivateStatus } = useSelector((state: RootStore) => state.oem);

  //effects

  /**
   * Load data by oemId.
   */
  useEffect(() => {
    if (oemId) {
      setLoading(true);

      Promise.all([getAnnouncements(), getOemDetail(oemId)])
        .then((res) => {
          const announcementTmp = res[0].filter(
            (item) => item.announcementTypeId === 3,
          );
          setAnnouncements(announcementTmp);
          setOemDetail(res[1]);
          setInitial(false);
          dispatch(storeOem(res[1]));

          setBreadcrumbLinks([
            {
              name: 'Home',
              to: '/home',
            },
            {
              name: res[1].name,
            },
          ]);
        })
        .catch(() => {
          dispatch(
            addToast({
              type: 'error',
              title: getTranslatedMessage('oem-get-failure'),
              message: '',
            }),
          );
        })
        .finally(() => setLoading(false));
    }
  }, [oemId, dispatch]);

  useEffect(() => {
    if (!initial && oemActivateStatus && oemActivateStatus.oemId > 0) {
      if (oemActivateStatus.activate) {
        activateOemFun(oemActivateStatus.oemId);
      } else {
        deactivateOemFun(oemActivateStatus.oemId);
      }
    }
  }, [oemActivateStatus]);

  /* istanbul ignore next */
  const updateOemApprovalActive = (
    approvalId: number,
    active: boolean,
  ): void => {
    const clonedDetails = cloneDeep(oemDetail);
    const changedItem = find(
      clonedDetails?.oemProductApprovals,
      (item) => item.id === approvalId,
    );
    if (changedItem) {
      changedItem.active = active;
      setOemDetail(clonedDetails);
    }
  };

  // update field for updating the oem
  const updateOemField = (
    id: number,
    typeId: number,
    confidential: boolean,
    notes: string,
    restrictions: string,
  ) => {
    const clonedDetails = cloneDeep(oemDetail);
    const changedItem = find(
      clonedDetails?.oemProductApprovals,
      (item) => item.id === id,
    );
    if (changedItem) {
      changedItem.notes = notes;
      changedItem.oemApprovalTypeId = typeId;
      changedItem.approvalDocument.bpConfidential = confidential;
      changedItem.restrictions = restrictions;
      setOemDetail(clonedDetails);
    }
  };

  // Update status of multiselect oem product
  const updateOemsApprovalActivateDeactivate = (selectedId: any) => {
    const cloneDetails = cloneDeep(oemDetail);
    for (
      let i = 0;
      i < selectedId.ids.length && cloneDetails?.oemProductApprovals;
      i++
    ) {
      for (let j = 0; j < cloneDetails?.oemProductApprovals.length; j++) {
        if (selectedId.ids[i] === cloneDetails?.oemProductApprovals[j].id) {
          cloneDetails.oemProductApprovals[j].active = selectedId.active;
          break;
        }
      }
    }
    setOemDetail(cloneDetails);
  };

  // update fields for multiple oem update
  const updateMultipleOemEdit = (
    selectedId: number[],
    typeId: number,
    confidential: boolean,
    notes: string,
    restrictions: string,
  ) => {
    const cloneDetails = cloneDeep(oemDetail);
    for (
      let i = 0;
      i < selectedId.length && cloneDetails?.oemProductApprovals;
      i++
    ) {
      for (let j = 0; j < cloneDetails?.oemProductApprovals.length; j++) {
        if (selectedId[i] === cloneDetails?.oemProductApprovals[j].id) {
          cloneDetails.oemProductApprovals[j].oemApprovalTypeId = typeId;
          cloneDetails.oemProductApprovals[j].approvalDocument.bpConfidential =
            confidential;
          cloneDetails.oemProductApprovals[j].notes = notes;
          cloneDetails.oemProductApprovals[j].restrictions = restrictions;
          break;
        }
      }
    }
    setOemDetail(cloneDetails);
  };

  /* istanbul ignore next */
  const downloadApprovalDoc = (approvalDocId: number): void => {
    setProcessing(true);
    downloadOemApprovalDocument(approvalDocId)
      .then(() => {
        console.log('download api success');
      })
      .catch(() => {
        dispatch(
          addToast({
            type: 'error',
            title: getTranslatedMessage('approval-doc-download-failure'),
            message: '',
          }),
        );
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  /* istanbul ignore next */
  const deactivateApprovalFun = (approvalId: number): void => {
    setProcessing(true);
    deactivateApproval(approvalId)
      .then(() => {
        updateOemApprovalActive(approvalId, false);
        dispatch(
          showGenericModal({
            titleId: 'oem-model-deactivate-approval',
            messageId: 'oem-model-deactivate-successful',
          }),
        );
      })
      .catch(() => {
        dispatch(
          addToast({
            type: 'error',
            title: getTranslatedMessage('approval-deactivate-failure'),
            message: '',
          }),
        );
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  /* istanbul ignore next */
  const activateApprovalFun = (approvalId: number): void => {
    setProcessing(true);
    activateApproval(approvalId)
      .then(() => {
        updateOemApprovalActive(approvalId, true);
        dispatch(
          showGenericModal({
            titleId: 'oem-model-activate-approval',
            messageId: 'oem-model-activate-successful',
          }),
        );
      })
      .catch(() => {
        dispatch(
          addToast({
            type: 'error',
            title: getTranslatedMessage('approval-activate-failure'),
            message: '',
          }),
        );
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  /* istanbul ignore next */
  const activateOemFun = (oemId: number): void => {
    setProcessing(true);
    activateOem(oemId)
      .then(() => {
        dispatch(updateOemActive(true));
        dispatch(
          showGenericModal({
            titleId: 'oem-model-activate-oem',
            messageId: 'oem-model-activate-successful',
          }),
        );
      })
      .catch(() => {
        dispatch(
          addToast({
            type: 'error',
            title: getTranslatedMessage('oem-activate-failure'),
            message: '',
          }),
        );
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  /* istanbul ignore next */
  const deactivateOemFun = (oemId: number): void => {
    setProcessing(true);
    deactivateOem(oemId)
      .then(() => {
        dispatch(updateOemActive(false));
        dispatch(
          showGenericModal({
            titleId: 'oem-model-deactivate-oem',
            messageId: 'oem-model-deactivate-successful',
          }),
        );
      })
      .catch(() => {
        dispatch(
          addToast({
            type: 'error',
            title: getTranslatedMessage('oem-deactivate-failure'),
            message: '',
          }),
        );
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const sendEmail = (data: any): void => {
    if (!oemId) {
      return;
    }

    setProcessing(true);
    shareByEmail({
      oemId: parseInt(oemId),
      url: window.location.href,
      fromName: data.fromName,
      fromEmail: data.fromEmail,
      recipientName: data.recipientName,
      recipientEmail: data.recipientEmail,
    })
      .then(() => {
        dispatch(
          showGenericModal({
            titleId: 'oem-model-share-email-success',
            messageId: 'oem-model-share-email-success-desc',
          }),
        );
      })
      .catch(() => {
        dispatch(
          addToast({
            type: 'error',
            title: getTranslatedMessage('send-email-failure'),
            message: '',
          }),
        );
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const onBackBtnClick = () => {
    navigate(-1);
  };

  // Callback for Update OEm table data
  const onUpdateOemProductApproval = (
    id: number,
    typeId: number,
    confidential: boolean,
    notes: string,
    restrictions: string,
    approvalDocumentId: number,
    selectedId?: number[],
    documentIds?: number[],
  ) => {
    setProcessing(true);
    updateProductOemApproval(
      id,
      typeId,
      confidential,
      notes,
      restrictions,
      approvalDocumentId,
      selectedId,
      documentIds,
    )
      .then(() => {
        if (selectedId === undefined) {
          updateOemField(id, typeId, confidential, notes, restrictions);
        } else {
          updateMultipleOemEdit(
            selectedId,
            typeId,
            confidential,
            notes,
            restrictions,
          );
        }
        dispatch(
          showGenericModal({
            titleId: 'oem-table-update',
            messageId: 'oem-table-update-succssfull',
          }),
        );
      })
      .catch(() => {
        dispatch(
          addToast({
            type: 'error',
            title: getTranslatedMessage('oem-table-update-failure'),
            message: '',
          }),
        );
      })
      .finally(() => {
        setProcessing(false);
      });
  };
  // CallBack for multiple select
  const onactivateDeactivateOemApproval = (selectedId: any) => {
    setProcessing(true);
    if (selectedId.active) {
      activateDeactivateOemApproval(selectedId)
        .then(() => {
          updateOemsApprovalActivateDeactivate(selectedId);

          dispatch(
            showGenericModal({
              titleId: 'oem-model-activate-approval',
              messageId: 'oem-model-activate-successful',
            }),
          );
        })
        .catch(() => {
          dispatch(
            addToast({
              type: 'error',
              title: getTranslatedMessage('oem-activate-failure'),
              message: '',
            }),
          );
        })
        .finally(() => {
          setProcessing(false);
        });
    } else {
      activateDeactivateOemApproval(selectedId)
        .then(() => {
          updateOemsApprovalActivateDeactivate(selectedId);
          dispatch(
            showGenericModal({
              titleId: 'oem-model-deactivate-approval',
              messageId: 'oem-model-deactivate-successful',
            }),
          );
        })
        .catch(() => {
          dispatch(
            addToast({
              type: 'error',
              title: getTranslatedMessage('oem-deactivate-failure'),
              message: '',
            }),
          );
        })
        .finally(() => {
          setProcessing(false);
        });
    }
  };

  return (
    <div>
      <NavigationHeader breadcrumbLinks={breadcrumbLinks} />
      <ContainerForDrawer>
        {loading ? (
          <LoadingSpinner className={styles.loading} />
        ) : (
          <div className={styles.baseContainer}>
            {announcements && announcements.length > 0 && (
              <AnnouncementSlider
                className={styles.announcements}
                announcements={announcements}
              />
            )}
            <div className={styles.oemDetailPageContainer}>
              <div className={styles.titleRow}>
                <div className={styles.title}>{oemDetail?.name}</div>
                <div
                  className={cn(styles.shareEmail, styles.desktop)}
                  onClick={() => setShowShareByEmail(true)}
                >
                  <ShareIcon />
                  <FormattedMessage id={'share-by-email-label'} />
                </div>
                <div className={cn(styles.divider, styles.mobile)} />
              </div>

              {oemDetail && oemDetail.oemProductApprovals && (
                <OemDetailTable
                  oemProductApprovals={oemDetail.oemProductApprovals.map(
                    (ele) => {
                      return {
                        ...ele,
                        isChecked: false,
                        approvalDocument: {
                          ...ele.approvalDocument,
                          issuedAt: getDateString(
                            ele.approvalDocument.issuedAt,
                          ),
                        },
                      };
                    },
                  )}
                  oemId={oemId}
                  exportFileName={oemDetail.name}
                  className={styles.oemDetailsContainer}
                  downloadApprovalDoc={downloadApprovalDoc}
                  deactivateApproval={deactivateApprovalFun}
                  activateApproval={activateApprovalFun}
                  onactivateDeactivateOemApproval={
                    onactivateDeactivateOemApproval
                  }
                  onUpdateOemProductApproval={onUpdateOemProductApproval}
                />
              )}
              <div className={styles.actionRow}>
                <Button
                  color={'green-outline'}
                  className={styles.backButton}
                  onClick={onBackBtnClick}
                >
                  <FormattedMessage id={'back-label'} />
                </Button>
              </div>
            </div>
          </div>
        )}
      </ContainerForDrawer>
      {processing && <LoadingSpinner className={'lookupsLoading'} />}
      {showShareByEmail && (
        <ShareByEmailModal
          onClose={() => setShowShareByEmail(false)}
          onSend={sendEmail}
        />
      )}
    </div>
  );
}

export default withAdmin(OEMDetailPage);
