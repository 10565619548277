import styles from './LanguageSelect.module.scss';
import { PropsWithoutRef, useState, useEffect } from 'react';
import cn from 'classnames';
import { cloneDeep } from 'lodash';

import { LanguageItem } from '../../../models/lookups';
import { SelectOption } from '../../../models/select-option';
import Select from '../../common/Select/Select';
import { ReactComponent as CloseWhite } from '../../../icons/close-thick-white.svg';

interface LanguageSelectProps {
  languageOptions: SelectOption<LanguageItem>[];
  activeLanguage: number;
  handleActiveLanguageChange: (langId: number) => void;
  langIds?: { id: number; empty?: boolean }[];
  onChange: (values: LanguageItem[]) => void;
  placeholder?: string;
  className?: string;
}

/**
 * input component
 * @param languageOptions languageOptions
 * @param activeLanguage current selected language
 * @param handleActiveLanguageChange event fired when active language changes
 * @param langIds langIds
 * @param onChange change handler
 * @param placeholder placeholder
 * @param className classname
 */
function LanguageSelect({
  languageOptions,
  activeLanguage,
  handleActiveLanguageChange,
  langIds,
  onChange,
  placeholder = '',
  className,
}: PropsWithoutRef<LanguageSelectProps>) {
  const [filteredLanguageOptions, setFilteredLanguageOptions] = useState<
    SelectOption<LanguageItem>[]
  >([]);

  const [selectedLanguages, setSelectedLanguages] = useState<LanguageItem[]>(
    [],
  );

  useEffect(() => {
    const tempSelectedLanguages = [];
    for (const language of langIds || []) {
      const selectedLanguageIndex = languageOptions.findIndex(
        (lang) => lang.value.id === language.id,
      );
      if (selectedLanguageIndex !== -1) {
        tempSelectedLanguages.push({
          ...languageOptions[selectedLanguageIndex].value,
          empty: language.empty,
        });
      }
    }

    setSelectedLanguages(tempSelectedLanguages);

    const tempFilteredLanguageOptions = cloneDeep(languageOptions);
    for (const language of langIds || []) {
      const index = tempFilteredLanguageOptions.findIndex(
        (lang: any) => lang.value.id === language.id,
      );

      if (index >= 0) {
        tempFilteredLanguageOptions.splice(index, 1);
      }
    }
    setFilteredLanguageOptions(tempFilteredLanguageOptions);
  }, [langIds, languageOptions]);

  const selectLanguage = (value: LanguageItem | null) => {
    if (!value) {
      return;
    }

    const newAddLang = cloneDeep(value);
    newAddLang.empty = true;
    const newSelected = [...selectedLanguages, newAddLang];
    setSelectedLanguages(newSelected);

    onChange(newSelected);
    handleActiveLanguageChange(value.id);
  };

  const deleteLanguage = (item: LanguageItem) => {
    const idx = selectedLanguages.findIndex(
      (language) => language.id === item.id,
    );
    const newSelected = [
      ...selectedLanguages.slice(0, idx),
      ...selectedLanguages.slice(idx + 1),
    ];
    setSelectedLanguages(newSelected);

    onChange(newSelected);
    if (activeLanguage === item.id) {
      handleActiveLanguageChange(1);
    }
  };

  const selectedLanguageBox = (languageId: number) => {
    const tmpList = cloneDeep(selectedLanguages);
    tmpList.forEach((item) => {
      item.active = item.id === languageId;
    });
    setSelectedLanguages(tmpList);
    onChange(tmpList);
  };

  return (
    <div className={cn(styles.container, className)}>
      <Select
        required={false}
        className={styles.formSelectField}
        value={null}
        options={filteredLanguageOptions.map((item) => ({
          name: item.name,
          value: item.value,
          disabled: activeLanguage === 0 && item.value.id !== 1,
        }))}
        onChange={(value) => selectLanguage(value)}
        placeholder={placeholder}
      />

      <div className={styles.languageBoxWrap}>
        {selectedLanguages.length > 0 &&
          selectedLanguages.map((language) => (
            <div
              key={language.name}
              className={cn(
                styles.languageBox,
                language.id === activeLanguage && styles.languageActive,
                language.id !== activeLanguage &&
                  language.empty &&
                  styles.languageEmpty,
              )}
            >
              <span onClick={() => selectedLanguageBox(language.id)}>
                {language.name}
              </span>
              {language.name !== 'English' && (
                <CloseWhite
                  onClick={() => deleteLanguage(language)}
                  className={styles.close}
                />
              )}
            </div>
          ))}
      </div>
    </div>
  );
}

export default LanguageSelect;
