import styles from './ComparisonListModal.module.scss';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as ArrowDown } from '../../../icons/arrow-down.svg';
import { PropsWithoutRef, useState } from 'react';
import cn from 'classnames';
import ComparisonListItem from './ComparisonListItem/ComparisonListItem';
import Button from '../Button/Button';
import { SearchResultProduct } from '../../../models/search-result-product';
import { useDispatch } from 'react-redux';
import { clearProducts } from '../../../features/productComparisonSlice';

interface ComparisonListModalProps {
  products: SearchResultProduct[];
  onOpenClick: () => void;
}

/**
 * comparison list modal
 * @param products product list
 * @param onOpenClick open button click handler
 */
function ComparisonListModal({
  products,
  onOpenClick,
}: PropsWithoutRef<ComparisonListModalProps>) {
  const [opened, setOpened] = useState(true);
  const dispatch = useDispatch();
  const compareValues = {
    count: products.length,
  };

  /**
   * clear handler
   */
  const onClearClick = () => {
    dispatch(clearProducts());
  };

  return (
    <div className={styles.container}>
      <div
        onClick={() => setOpened(!opened)}
        className={cn(styles.headerContainer, opened && styles.opened)}
      >
        <div className={styles.leftGroup}>
          <div className={styles.title}>
            <FormattedMessage id={'comparison-list-label'} />
          </div>

          <div className={styles.subTitle}>
            <FormattedMessage id={'comparison-list-description'} />
          </div>
        </div>

        <ArrowDown className={styles.arrow} />
      </div>

      {opened && (
        <div className={styles.listContainer}>
          {products.map((product) => {
            return (
              <ComparisonListItem
                key={product.id}
                product={product}
                className={styles.listItem}
              />
            );
          })}
        </div>
      )}

      <div className={styles.buttonContainer}>
        <Button
          onClick={() => onOpenClick()}
          color={'green'}
          className={cn(styles.button, styles.compareButton)}
        >
          <FormattedMessage
            id={'compare-counts-label'}
            values={compareValues}
          />
        </Button>

        <Button
          onClick={() => onClearClick()}
          color={'transparent'}
          className={cn(styles.button, styles.clearButton)}
        >
          <FormattedMessage id={'clear-selection-label'} />
        </Button>
      </div>
    </div>
  );
}

export default ComparisonListModal;
