import styles from './TabHeader.module.scss';
import { PropsWithoutRef } from 'react';
import cn from 'classnames';
import { TabItem } from '../../../../models/tab-item';

interface TabHeaderProps {
  selected: string;
  tabs: TabItem[];
  tabItemClassName?: string;
  tabItemSelectedClassName?: string;
  onChange: (tab: TabItem) => void;
}

/**
 * tab header
 * @param selected selected tab
 * @param tabs tabs
 * @param onChange tab change handler
 * @param tabItemClassName class name for tab item
 * @param tabItemSelectedClassName class name for tab item
 */
function TabHeader({
  selected,
  tabs,
  tabItemClassName,
  tabItemSelectedClassName,
  onChange,
}: PropsWithoutRef<TabHeaderProps>) {
  return (
    <div className={styles.container}>
      {tabs.map((tab, index) => {
        const isSelected = selected === tab.key;

        return (
          <div
            key={index}
            onClick={() => onChange(tab)}
            className={cn(
              styles.tabItem,
              isSelected && styles.selected,
              isSelected && tabItemSelectedClassName,
              tabItemClassName,
            )}
          >
            {tab.name}

            {isSelected && <span className={styles.bottomBorder} />}
          </div>
        );
      })}

      <div className={styles.bottomBorder} />
    </div>
  );
}

export default TabHeader;
