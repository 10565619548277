import styles from './HelpModal.module.scss';
import { PropsWithoutRef } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import cn from 'classnames';

import Modal from '../Modal/Modal';
import { hideLoading, showLoading } from '../../../features/loadingSlice';
import { addToast } from '../../../features/toastSlice';
import { showGenericModal } from '../../../features/genericModalSlice';
import { ReactComponent as CloseIcon } from '../../../icons/close.svg';
import { ReactComponent as CloseWhiteIcon } from '../../../icons/close-white.svg';

import HelpBanner from './HelpBanner/HelpBanner';
import HelpEmailForm from './HelpEmailForm/HelpEmailForm';
import RequestAccountForm from './RequestAccountForm/RequestAccountForm';
import RequestInternalAccountForm from './RequestInternalAccountForm/RequestInternalAccountForm';
import {
  helpEmail,
  requestAccount,
  requestInternalAccount,
} from '../../../services/UserService';
import { HelpEmail } from '../../../models/help-email';
import {
  RequestAccount,
  RequestInternalAccount,
} from '../../../models/request-account';
import { getErrorMessage } from '../../../utils/string.util';

export type HelpType = 'email' | 'account' | 'internal-account';

interface HelpModalProps {
  type: HelpType;
  onClose: () => void;
}

/**
 * Help modal
 * @param type the help modal type
 * @param onClose close handler
 */
function HelpModal({ type, onClose }: PropsWithoutRef<HelpModalProps>) {
  // init hook
  const dispatch = useDispatch();
  const intl = useIntl();
  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });

  const getMessageId = (): string => {
    switch (type) {
      case 'email':
        return 'help-label-2';
      case 'account':
        return 'help-label-3';
      case 'internal-account':
        return 'help-label-4';
      default:
        return '';
    }
  };

  const sendEmail = (data: HelpEmail) => {
    dispatch(showLoading());
    helpEmail(data)
      .then(() => {
        onClose();
        dispatch(
          showGenericModal({
            titleId: 'email-send-success',
            messageId: 'email-send-success-info',
          }),
        );
      })
      .catch((err) => {
        dispatch(
          addToast({
            type: 'error',
            title: getTranslatedMessage('email-send-failure'),
            message: getErrorMessage(err),
          }),
        );
      })
      .finally(() => dispatch(hideLoading()));
  };

  const requestAccountFunc = (data: RequestAccount) => {
    dispatch(showLoading());
    requestAccount(data)
      .then(() => {
        onClose();
        dispatch(
          showGenericModal({
            titleId: 'help-label-3',
            messageId: 'request-account-success-info',
          }),
        );
      })
      .catch((err) => {
        dispatch(
          addToast({
            type: 'error',
            title: getTranslatedMessage('request-account-failure'),
            message: getErrorMessage(err),
          }),
        );
      })
      .finally(() => dispatch(hideLoading()));
  };

  const requestInternalAccountFunc = (data: RequestInternalAccount) => {
    dispatch(showLoading());
    requestInternalAccount(data)
      .then(() => {
        onClose();
        dispatch(
          showGenericModal({
            titleId: 'help-label-4',
            messageId: 'request-account-success-info',
          }),
        );
      })
      .catch((err) => {
        dispatch(
          addToast({
            type: 'error',
            title: getTranslatedMessage('request-account-failure'),
            message: getErrorMessage(err),
          }),
        );
      })
      .finally(() => dispatch(hideLoading()));
  };

  return (
    <Modal onClose={onClose} hideClose={true} className={styles.modalContainer}>
      <div className={styles.container}>
        <CloseIcon
          onClick={() => onClose()}
          className={cn(styles.close, styles.desktop)}
        />
        <CloseWhiteIcon
          onClick={() => onClose()}
          className={cn(styles.close, styles.mobile)}
        />
        <HelpBanner messageId={getMessageId()} />

        {type === 'email' && (
          <HelpEmailForm className={styles.formContainer} onSend={sendEmail} />
        )}
        {type === 'account' && (
          <RequestAccountForm
            className={styles.formContainer}
            onSubmit={requestAccountFunc}
          />
        )}
        {type === 'internal-account' && (
          <RequestInternalAccountForm
            className={styles.formContainer}
            onSubmit={requestInternalAccountFunc}
          />
        )}
      </div>
    </Modal>
  );
}

export default HelpModal;
