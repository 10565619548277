import styles from './Tabs.module.scss';
import TabHeader from './TabHeader/TabHeader';
import { PropsWithChildren } from 'react';
import { TabItem } from '../../../models/tab-item';
import classNames from 'classnames';

interface TabsProps {
  selected: string;
  tabs: TabItem[];
  onChange: (tab: TabItem) => void;
  className?: string;
  tabContentClassName?: string;
  tabItemClassName?: string;
  tabItemSelectedClassName?: string;
}

/**
 * tab view
 * @param selected selected tab
 * @param tabs tabs
 * @param onChange tab change handler
 * @param children children
 * @param className classname
 * @param tabContentClassName classname
 * @param useLinkTo function to navigate
 * @param tabItemClassName class name for tab items
 * @param tabItemSelectedClassName class name for selected tab items
 */
function Tabs({
  selected,
  tabs,
  onChange,
  children,
  className,
  tabContentClassName,
  tabItemClassName,
  tabItemSelectedClassName,
}: PropsWithChildren<TabsProps>) {
  return (
    <div className={className}>
      <TabHeader
        tabItemClassName={tabItemClassName}
        selected={selected}
        tabs={tabs}
        onChange={onChange}
        tabItemSelectedClassName={tabItemSelectedClassName}
      />

      <div className={classNames(styles.contentContainer, tabContentClassName)}>
        {children}
      </div>
    </div>
  );
}

export default Tabs;
