import styles from './NavigationHeader.module.scss';
import SearchInput from './SearchInput/SearchInput';
import Breadcrumbs from './Breadcrumbs/Breadcrumbs';
import { PropsWithoutRef } from 'react';
import { PreviousLink } from '../../../models/previous-link';
import { ReactComponent as Filter } from '../../../icons/filter.svg';
import { ReactComponent as Caret } from '../../../icons/caret.svg';
import cn from 'classnames';

interface NavigationHeaderProps {
  breadcrumbLinks: PreviousLink[];
  onInputFocus?: () => void;
  showFilterIcon?: boolean;
  openFilter?: boolean;
  setOpenFilter?: (openFilter: boolean) => void;
  className?: string;
  showBottomLine?: boolean;
}

/**
 * navigation header for not home page
 * @param breadcrumbLinks links for breadcrumbs
 * @param onInputFocus search input focus event
 * @param showFilterIcon flag to show filter icon
 * @param openFilter flag to trigger filter open
 * @param setOpenFilter function to set filter open flag
 */
function NavigationHeader({
  breadcrumbLinks,
  onInputFocus,
  showFilterIcon = false,
  openFilter,
  setOpenFilter,
  className,
  showBottomLine = false,
}: PropsWithoutRef<NavigationHeaderProps>) {
  return (
    <div
      className={cn(
        styles.container,
        className,
        onInputFocus || showBottomLine ? styles.bottomLine : '',
      )}
    >
      <Breadcrumbs
        classNames={cn(styles.breadcrumb, openFilter ? styles.filterOpen : '')}
        links={breadcrumbLinks}
      />
      <div
        className={cn(styles.backLink, openFilter ? styles.filterOpen : '')}
        onClick={() => (setOpenFilter ? setOpenFilter(false) : null)}
      >
        <Caret /> Back
      </div>
      {onInputFocus && (
        <SearchInput onInputFocus={onInputFocus} classes={styles.searchInput} />
      )}
      {showFilterIcon && (
        <div
          className={cn(styles.filter, openFilter ? styles.filterOpen : '')}
          onClick={() => (setOpenFilter ? setOpenFilter(true) : null)}
        >
          <Filter />
        </div>
      )}
    </div>
  );
}

export default NavigationHeader;
