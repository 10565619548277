import { useState, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import cn from 'classnames';

import styles from './ResetPasswordPage.module.scss';
import { emailValidator } from '../../utils/string.util';
import { sendResetPasswordMail } from '../../services/UserService';
import { hideLoading, showLoading } from '../../features/loadingSlice';
import { addToast } from '../../features/toastSlice';

import Button from '../../components/common/Button/Button';
import InputRow from '../../components/Admin/InputRow/InputRow';
import { ReactComponent as ArrowIcon } from '../../icons/arrow2-left.svg';
import { ReactComponent as EmailIcon } from '../../icons/email.svg';

/**
 * Reset password page component
 */
function ResetPasswordPage() {
  // init hooks
  const intl = useIntl();
  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [step, setStep] = useState(0);

  const sendRequest = () => {
    dispatch(showLoading());
    sendResetPasswordMail(email)
      .then(() => {
        setStep(1);
      })
      .catch(() => {
        dispatch(
          addToast({
            type: 'error',
            title: getTranslatedMessage('request-reset-email-failure'),
            message: '',
          }),
        );
      })
      .finally(() => dispatch(hideLoading()));
  };

  const descriptionValues = {
    a: (value: string[]) => (
      <a className={styles.link} href={'http://castrol.com'}>
        {value}
      </a>
    ),
  };

  return (
    <div className={styles.container}>
      {step === 0 && (
        <Fragment>
          <div className={styles.title}>
            <ArrowIcon
              className={styles.arrowIcon}
              onClick={() => navigate(-1)}
            />
            <FormattedMessage id={'reset-password-label'} />
          </div>
          <div className={styles.description}>
            <FormattedMessage id={'reset-password-desc-label'} />
          </div>
          <div className={styles.divider} />

          <div className={cn('columnRow', styles.inputFields)}>
            <InputRow
              labelId={'email-address-label'}
              className={styles.emailInput}
              value={email}
              required={false}
              showCancelIcon={true}
              onChange={(value: string) => setEmail(value)}
              customValidator={(value: string) => emailValidator(value)}
              tooltip={getTranslatedMessage('email-address-tooltip')}
              errorMessage={getTranslatedMessage('email-invalid-error')}
            />
          </div>

          <div className={styles.buttonContainer}>
            <Button
              onClick={() => sendRequest()}
              color={'green'}
              className={cn(styles.button, styles.sendRequestBtn)}
              disabled={emailValidator(email)}
            >
              <FormattedMessage id={'send-request-label'} />
            </Button>
          </div>
        </Fragment>
      )}
      {step === 1 && (
        <Fragment>
          <div className={styles.title}>
            <EmailIcon className={styles.emailIcon} />
            <FormattedMessage id={'check-in-your-mail-label'} />
          </div>
          <div className={styles.description}>
            <FormattedMessage id={'check-in-your-mail-desc'} />
          </div>
          <div className={cn(styles.divider, styles.marginBoth)} />
          <div className={styles.description}>
            <FormattedMessage
              id={'contact-info-desc'}
              values={descriptionValues}
            />
          </div>

          <div className={styles.buttonContainer}>
            <Button
              onClick={() => navigate(-1)}
              color={'green'}
              className={cn(styles.button, styles.confirmBtn)}
            >
              <FormattedMessage id={'confirm-label'} />
            </Button>
          </div>
        </Fragment>
      )}
    </div>
  );
}

export default ResetPasswordPage;
