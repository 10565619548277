import { PropsWithoutRef } from 'react';
import { FormattedMessage } from 'react-intl';
import CheckBox from '../../Checkbox/Checkbox';
import { CountryItem } from '../../../../models/lookups';

import styles from './PrivacyPolicyInfo.module.scss';

interface PrivacyPolicyInfoProps {
  agree: boolean;
  country?: CountryItem;
  onAgreeChange: () => void;
}

/**
 * banner for help modal
 */
function PrivacyPolicyInfo({
  agree,
  country,
  onAgreeChange,
}: PropsWithoutRef<PrivacyPolicyInfoProps>) {
  const descriptionValues = {
    a: (value: string[]) => (
      <a
        className={styles.link}
        target={'_blank'}
        rel={'noreferrer'}
        href={
          country
            ? country.privacyUrl
            : 'https://www.castrol.com/en/global/home/privacy-statement.html'
        }
      >
        {value}
      </a>
    ),
    email: (value: string[]) => (
      <a className={styles.link} href={'mailto: customerservices1@castrol.com'}>
        {value}
      </a>
    ),
  };

  return (
    <div className={styles.container}>
      <div className={styles.descRow}>
        <div onClick={onAgreeChange} className={styles.privacyPolicy}>
          <CheckBox checked={agree} className={styles.checkbox} />
          <span>
            <FormattedMessage
              id={'agree-privacy-policy'}
              values={descriptionValues}
            />
          </span>
        </div>
      </div>

      <div className={styles.descRow}>
        <div className={styles.descriptionTitle}>
          <FormattedMessage id={'how-your-data-used-title'} />
        </div>
        <div className={styles.description}>
          <FormattedMessage id={'how-your-data-used-1'} />
        </div>
        <div className={styles.description}>
          <FormattedMessage id={'how-your-data-used-2'} />
        </div>
      </div>

      <div className={styles.descRow}>
        <div className={styles.descriptionTitle}>
          <FormattedMessage id={'data-hold-period-title'} />
        </div>
        <div className={styles.description}>
          <FormattedMessage
            id={'data-hold-period-desc'}
            values={descriptionValues}
          />
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicyInfo;
