import MultipleSelect, {
  MultipleSelectProps,
  multiSelectStyles,
} from '../MultipleSelect/MultipleSelect';

type ValueType = number;

export interface TabbedMultipleSelectProps
  extends MultipleSelectProps<ValueType> {
  activeValue: ValueType | null;
  onChangeActiveValue: (value: ValueType | null) => void;
  wasActiveMap: Record<ValueType, boolean>;
  onChangeWasActiveMap: (map: Record<ValueType, boolean>) => void;
}

function TabbedMultipleSelect({
  activeValue,
  onChangeActiveValue,
  wasActiveMap,
  onChangeWasActiveMap,
  ...props
}: TabbedMultipleSelectProps) {
  return (
    <MultipleSelect
      {...props}
      onAdd={(value) => {
        if (activeValue === null) {
          onChangeActiveValue(value);
          onChangeWasActiveMap({
            [value]: true,
          });
        }
        if (activeValue === 0 && value === 1) {
          onChangeActiveValue(value);
          onChangeWasActiveMap({
            ...wasActiveMap,
            [value]: true,
          });
        }
      }}
      onRemove={(value) => {
        if (activeValue === value) {
          const index = props.value.indexOf(value);
          if (index > 0) {
            onChangeActiveValue(props.value[index - 1] || null);
          } else {
            onChangeActiveValue(props.value[index + 1] || null);
          }
        }
        onChangeWasActiveMap({
          ...wasActiveMap,
          [value]: false,
        });
      }}
      onClickSelectedTag={(tagValue) => {
        onChangeActiveValue(tagValue);
        onChangeWasActiveMap({
          ...wasActiveMap,
          [tagValue]: true,
        });
      }}
      getSelectedTagClassName={(tagValue) => {
        if (tagValue === activeValue) {
          return '';
        } else if (wasActiveMap[tagValue]) {
          return multiSelectStyles.tagDone;
        } else {
          return multiSelectStyles.tagInActive;
        }
      }}
    />
  );
}

export default TabbedMultipleSelect;
