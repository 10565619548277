import { PropsWithoutRef, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import cn from 'classnames';

import styles from './HelpEmailForm.module.scss';
import config from '../../../../configs/config';
import Button from '../../Button/Button';
import InputRow from '../../../Admin/InputRow/InputRow';
import SelectFormRow from '../../../Admin/SelectFormRow/SelectFormRow';
import TextareaRow from '../../../Admin/TextareaRow/TextareaRow';

import { emailValidator } from '../../../../utils/string.util';
import { HelpEmail } from '../../../../models/help-email';

interface HelpEmailFormProps {
  className?: string;
  onSend?: (data: HelpEmail) => void;
}

/**
 * contact us form
 * @param className classname
 * @param onSend handle send click
 */
function HelpEmailForm({
  className,
  onSend,
}: PropsWithoutRef<HelpEmailFormProps>) {
  // init hooks
  const intl = useIntl();
  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });

  const [userEmail, setUserEmail] = useState('');
  const [emailSubject, setEmailSubject] = useState(null);
  const [emailBody, setEmailBody] = useState('');

  const dataValid =
    userEmail &&
    !emailValidator(userEmail) &&
    emailSubject &&
    emailBody &&
    emailBody.trim().length > 0;

  const send = () => {
    onSend &&
      onSend({
        userEmail: userEmail,
        emailSubject: emailSubject || '',
        emailBody,
      });
  };

  const emailSubjectOptions = useMemo(() => {
    return config.HELP_EMAIL_SUBJECTS.map((item) => ({
      name: item,
      value: item,
    }));
  }, [config.HELP_EMAIL_SUBJECTS]);

  return (
    <div className={cn(styles.container, className)}>
      <div className={'columnRow'}>
        <InputRow
          labelId={'your-email-address-label'}
          inputClassName={styles.inputField}
          value={userEmail}
          required={true}
          showCancelIcon={true}
          onChange={(value: string) => setUserEmail(value)}
          tooltip={getTranslatedMessage('email-address-tooltip')}
          customValidator={emailValidator}
        />
      </div>

      <div className={'columnRow'}>
        <SelectFormRow
          labelId={'email-subject-label'}
          tooltip={getTranslatedMessage('email-subject-tooltip')}
          required={true}
          options={emailSubjectOptions}
          value={emailSubject}
          onChange={(value: any) => setEmailSubject(value)}
        />
      </div>

      <div className={'columnRow'}>
        <TextareaRow
          labelId={'body-label'}
          required={true}
          value={emailBody}
          className={styles.emailBody}
          onChange={(value: string) => setEmailBody(value)}
        />
      </div>

      <div className={styles.divider} />

      <div className={styles.buttonContainer}>
        <Button
          onClick={() => send()}
          color={'green'}
          disabled={!dataValid}
          className={styles.sendButton}
        >
          <FormattedMessage id={'send-label'} />
        </Button>
      </div>
    </div>
  );
}

export default HelpEmailForm;
