import { useEffect, useState } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  Location,
} from 'react-router-dom';
import jwt from 'jsonwebtoken';
import './App.css';
import HomePage from './pages/HomePage/HomePage';
import Header from './components/common/Header/Header';
import { IntlProvider } from 'react-intl';
import en from './configs/i18n.en';
import adminEn from './configs/admin-i18n.en';
import Footer from './components/common/Footer/Footer';
import AskCookie from './components/common/AskCookie/AskCookie';
import SearchResultPage from './pages/SearchResultPage/SearchResultPage';
import AddCountryLevelProductPage from './pages/Admin/Products/AddCountryLevelProductPage/AddCountryLevelProductPage';
import ManageLocalisedProductsPage from './pages/Admin/Products/ManageLocalisedProducts/ManageLocalisedProductsPage';
import ManageFindADistributorLinkPage from './pages/Admin/Products/ManageFindADistributorLinkPage/ManageFindADistributorLinkPage';
import AddCaseStudyPage from './pages/Admin/Products/AddCaseStudyPage/AddCaseStudyPage';
import {
  getCookieAcceptance,
  setCookieAcceptance,
} from './services/LocalStorageService';
import ProductFamilyPage from './pages/ProductFamilyPage/ProductFamilyPage';
import ProductRangePage from './pages/ProductRangePage/ProductRangePage';
import ProductPage from './pages/ProductPage/ProductPage';
import WelcomeModal from './components/common/WelcomeModal/WelcomeModal';
import Toast from './components/common/Toast/Toast';
import PortSearchResultPage from './pages/PortSearchResultPage/PortSearchResultPage';
import PortDetailPage from './pages/PortDetailPage/PortDetailPage';
import { getLookups } from './services/LookupService';
import { useDispatch, useSelector } from 'react-redux';
import { setLookups } from './features/lookupsSlice';
import { setWelcomeInfo } from './features/welcomeInfoSlice';
import { RootStore } from './store';
import axios from 'axios';
import LoadingSpinner from './components/common/LoadingSpinner/LoadingSpinner';
import {
  filterCountryByCulture,
  filterLanguageByCulture,
  filterSectorByFriendlyURL,
} from './utils/lookup.util';
import { WelcomeInfo } from './models/welcome-info';
import ContactUsModal from './components/common/ContactUsModal/ContactUsModal';
import { closeContactUsModal } from './features/contactUsSlice';
import AddEditOEMPage from './pages/Admin/AddEditOEMPage/AddEditOEMPage';
import OEMDetailPage from './pages/Admin/OEMDetailPage/OEMDetailPage';
import ApprovalDocumentPage from './pages/Admin/ApprovalDocumentPage/ApprovalDocumentPage';
import config from './configs/config';
import { Lookups } from './models/lookups';
import { validateSectorAndCulture } from './utils/welcome-info.util';
import ManagePort from './pages/Admin/ManagePort/ManagePort';
import ManageStockpoint from './pages/Admin/ManageStockpoint/ManageStockpoint';
import ManageOrderContact from './pages/Admin/ManageOrderContact/ManageOrderContact';
import ManagePortDistributor from './pages/Admin/ManagePortDistributor/ManagePortDistributor';
import CreateStockpoint from './pages/Admin/CreateStockpoint/CreateStockpoint';
import { getUserInfoFromLocalStorage } from './services/LocalStorageService';
import { setHasLogin, setAdminLogin } from './features/loginSlice';
import GenericModal from './components/common/GenericModal/GenericModal';
import {
  hideGenericModal,
  setUnsavedStatus,
} from './features/genericModalSlice';
import ManageFamily from './pages/Admin/Products/ManageFamily/ManageFamily';
import ManageRange from './pages/Admin/Products/ManageRange/ManageRange';
import OemSearchResultPage from './pages/Admin/OemSearchResultPage/OemSearchResultPage';
import AddProduct from './pages/Admin/AddProduct/AddProduct';
import Help from './pages/Admin/Help/Help';
import ManageAnnouncements from './pages/Admin/ManageAnnouncements/ManageAnnouncements';
import ManageBanners from './pages/Admin/ManageBanners/ManageBanners';
import AddEditHelp from './pages/Admin/Help/components/AddEditHelp/AddEditHelp';
import AddEditBanner from './pages/Admin/ManageBanners/components/AddEditBanner/AddEditBanner';
import AddEditAnnouncement from './pages/Admin/ManageAnnouncements/components/AddEditAnnouncement/AddEditAnnouncement';
import AddMultiOEMApprovalsPage from './pages/Admin/AddMultiOEMApprovalsPage/AddMultiOEMApprovalsPage';
import UserListPage from './pages/Admin/UserListPage/UserListPage';
import EditUserPage from './pages/Admin/EditUserPage/EditUserPage';
import AddMultiUsersPage from './pages/Admin/AddMultiUsersPage/AddMultiUsersPage';
import ReportsAndQuickStatsPage from './pages/Admin/Reports/ReportsAndQuickStatsPage/ReportsAndQuickStatsPage';
import ProductReportPage from './pages/Admin/Reports/ProductReportPage/ProductReportPage';
import PortProductReportPage from './pages/Admin/Reports/PortProductReportPage/PortProductReportPage';
import FilterByCountryPage from './pages/Admin/Reports/FilterByCountryPage/FilterByCountryPage';
import UserActivityPage from './pages/Admin/Reports/UserActivityPage/UserActivityPage';
import ReportsMenuPage from './pages/Admin/Reports/ReportsMenuPage/ReportsMenuPage';
import OEMActivitiesPage from './pages/Admin/Reports/OEMActivitiesPage/OEMActivitiesPage';
import PortPageReportPage from './pages/Admin/Reports/PortPageReportPage/PortPageReportPage';
import ResetPasswordPage from './pages/ResetPasswordPage/ResetPasswordPage';
import ResetUserPasswordPage from './pages/ResetUserPasswordPage/ResetUserPasswordPage';
import ManageMultipleProductsPage from './pages/Admin/Products/AddMultipleProducts/ManageMultipleProductsPage';
import AddPortDistributor from './pages/Admin/AddPortDistributor/AddPortDistributor';
import LoginPage from './pages/LoginPage/LoginPage';
import MyAccountPage from './pages/MyAccountPage/MyAccountPage';
import EditAccountPage from './pages/EditAccountPage/EditAccountPage';
import ChangePasswordPage from './pages/ChangePasswordPage/ChangePasswordPage';
import DistributorPage from './pages/DistributorPage/DistributorPage';
import LinkListPage from './pages/Admin/Link/LinkListPage';
import AddEditLinkPage from './pages/Admin/Link/components/AddEditLinkPage/AddEditLinkPage';
import ManagePortProductPage from './pages/Admin/ManagePortProductPage/ManagePortProductPage';
import { getLookupsWithoutAuth } from './services/AuthService';
import {
  getAccessTokenFromLocalStorage,
  clearAccessTokenToLocalStorage,
  clearUserInfoToLocalStorage,
} from './services/LocalStorageService';
import { setHasLogout } from './features/loginSlice';
import ManageCookies from './components/common/ManageCookies/ManageCookies';
import CaseStudies from './pages/ProductPage/CaseStudy';

const locale = navigator.language as keyof typeof messages;
const messages = {
  en: {
    ...en,
    ...adminEn,
  },
  'en-US': {
    ...en,
    ...adminEn,
  },
};
const PORT_URL_PART = '/port';

function App() {
  /**
   * declaring hooks
   */
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  /**
   * declaring the states.
   */
  const { culture, sector, hasWelcomeInfo, welcomeInfo } = useSelector(
    (state: RootStore) => state.welcomeInfo,
  );
  const { countries } = useSelector((state: RootStore) => state.lookups);
  const { showContactUsModal, page, id } = useSelector(
    (state: RootStore) => state.contactUs,
  );
  const { loading: showLoading } = useSelector(
    (state: RootStore) => state.loading,
  );
  const { show: showModal, data: modalData } = useSelector(
    (state: RootStore) => state.genericModal,
  );
  const [loading, setLoading] = useState(false);
  const [previousLanguage, setPreviousLanguage] = useState(
    welcomeInfo.language,
  );
  const [previousCountry, setPreviousCountry] = useState(welcomeInfo.country);
  const [acceptance, setAcceptance] = useState(getCookieAcceptance());
  const [showManageCookies, setShowManageCookies] = useState(false);
  const [welcome, setWelcome] = useState(false);
  const [lookupData, setLookupData] = useState<Lookups>();

  /**
   * declaring the local functions
   */

  /**
   * Callback for accepting cookie.
   */
  const onAcceptCookie = () => {
    setCookieAcceptance(true);

    setAcceptance(true);
  };

  /**
   * Callback for welcome modal close.
   */
  const onCloseWelcome = () => {
    setWelcome(false);
  };

  /**
   * Callback for contact us modal close.
   */
  const onCloseContactUs = () => {
    dispatch(closeContactUsModal());
  };

  const getParams = (location: Location) => {
    if (
      location.pathname.startsWith('/product') ||
      location.pathname.startsWith('/family') ||
      location.pathname.startsWith('/range')
    ) {
      const parts = location.pathname
        .split('/')
        .filter((x) => x.match(/[^ ]+/g));
      if (parts.length === 4) {
        return {
          sector: parts[2],
          culture: parts[3],
        };
      }
    } else {
      const parts = location.pathname
        .split('/')
        .filter((x) => x.match(/[^ ]+/g));
      if (parts.length === 2) {
        return {
          sector: parts[0],
          culture: parts[1],
        };
      }
    }

    return null;
  };

  const checkTokenExpired = () => {
    const accessToken = getAccessTokenFromLocalStorage();
    const decodedToken: any = jwt.decode(accessToken || '');
    if (decodedToken?.exp) {
      const currentTime = Date.now() / 1000;
      if (currentTime > decodedToken.exp) {
        clearAccessTokenToLocalStorage();
        clearUserInfoToLocalStorage();
        dispatch(setHasLogout());
        navigate('/');
      }
    }
  };

  useEffect(() => {
    // load user info from local storage
    const userInfo = getUserInfoFromLocalStorage();
    if (userInfo) {
      if (
        userInfo.role === 'Super Admin' ||
        userInfo.role === 'DMS Admin' ||
        userInfo.role === 'Approver'
      ) {
        dispatch(setAdminLogin(userInfo));
      } else {
        dispatch(setHasLogin(userInfo));
      }
    }

    // Start Timer to check api token expiration
    const interval = setInterval(() => {
      checkTokenExpired();
    }, config.TOKEN_EXPIRED_VERIFY_INTERNAL);

    return () => {
      clearInterval(interval);
    };
  }, []);

  /**
   * Handle port redirection if sector is other than marine.
   */
  useEffect(() => {
    if (
      sector &&
      sector.length > 0 &&
      sector !== config.MARINE_SECTOR &&
      location?.pathname?.startsWith(PORT_URL_PART)
    ) {
      navigate(`/${sector}/${culture}`, {
        replace: true,
      });
    }
  }, [sector, location.pathname]);

  /**
   * Load lookups if welcome info already store and if following changes happens
   * changes in culture
   */
  useEffect(() => {
    if (
      hasWelcomeInfo &&
      (welcomeInfo.language?.id !== previousLanguage?.id ||
        welcomeInfo.country?.id !== previousCountry?.id ||
        countries?.length === 0)
    ) {
      const source = axios.CancelToken.source();

      // get lookups
      getLookups(source.token)
        .then((res) => {
          dispatch(setLookups(res));
          setLoading(false);
        })
        .catch((e) => {
          if (e.message !== 'cancel') {
            setLoading(false);
          }
        });

      setLoading(true);
      setPreviousLanguage(welcomeInfo.language);
      setPreviousCountry(welcomeInfo.country);
    }
  }, [culture, hasWelcomeInfo]);

  /**
   * load lookups in intial state if no welcome state.
   */
  useEffect(() => {
    if (!hasWelcomeInfo) {
      setLoading(true);
      // get lookups
      getLookupsWithoutAuth()
        .then((res) => {
          setLookupData(res);
          setLoading(false);
        })
        .catch((e) => {
          if (e.message !== 'cancel') {
            setLoading(false);
          }
        });
    }
  }, [hasWelcomeInfo]);

  /**
   * Setting up welcome info & lookups when
   * no previous info
   * culture & sector set
   */
  useEffect(() => {
    if (!hasWelcomeInfo && lookupData) {
      const params = getParams(location);
      if (!!sector && sector.length > 0 && !!culture && culture.length > 0) {
        // validate the culture & sector
        if (validateSectorAndCulture(sector, culture, lookupData)) {
          const welcomeInfo: WelcomeInfo = {
            industrySector:
              sector !== config.ALL_SECTORS
                ? filterSectorByFriendlyURL(lookupData.sectors, sector)
                : config.ALL_SECTORS_WELCOME_INFO,
            country: filterCountryByCulture(lookupData.countries, culture),
            language: filterLanguageByCulture(lookupData.languages, culture),
          };
          dispatch(setWelcomeInfo(welcomeInfo));
        } else {
          setWelcome(true);
        }
      } else if (
        params &&
        !!params.sector &&
        params.sector.length > 0 &&
        !!params.culture &&
        params.culture.length > 0
      ) {
        // validate the culture & sector
        if (
          validateSectorAndCulture(params.sector, params.culture, lookupData)
        ) {
          const welcomeInfo: WelcomeInfo = {
            industrySector:
              sector !== config.ALL_SECTORS
                ? filterSectorByFriendlyURL(lookupData.sectors, params.sector)
                : config.ALL_SECTORS_WELCOME_INFO,
            country: filterCountryByCulture(
              lookupData.countries,
              params.culture,
            ),
            language: filterLanguageByCulture(
              lookupData.languages,
              params.culture,
            ),
          };
          dispatch(setWelcomeInfo(welcomeInfo));
        } else if (location.pathname !== '/login') {
          setWelcome(true);
        }
      } else if (location.pathname !== '/login') {
        setWelcome(true);
      }
      // get lookups
    }
  }, [hasWelcomeInfo, sector, culture, lookupData, location.pathname]);

  useEffect(() => {
    if (
      (location.pathname.startsWith('/product') ||
        location.pathname.startsWith('/family') ||
        location.pathname.startsWith('/range')) &&
      sector?.length > 0 &&
      culture?.length > 0
    ) {
      const parts = location.pathname
        .split('/')
        .filter((x) => x.match(/[^ ]+/g));
      if (parts.length <= 2) {
        navigate(
          `${location.pathname}/${sector}/${culture}${location.search}`,
          {
            replace: true,
          },
        );
      }
    } else if (
      location.pathname === '/' &&
      hasWelcomeInfo &&
      sector?.length > 0 &&
      culture?.length > 0
    ) {
      navigate(`/${sector}/${culture}`, {
        replace: true,
      });
    } else if (location.pathname === '/login') {
      setWelcome(false);
    }
  }, [location.pathname]);

  return (
    <IntlProvider locale={locale} messages={messages[locale] || messages.en}>
      <Header />

      <Routes>
        <Route path={'/'} element={<HomePage />} />
        <Route path={'/login'} element={<LoginPage />} />
        <Route path={'/reset-password'} element={<ResetUserPasswordPage />} />
        <Route path={'/casestudies'} element={<CaseStudies />}></Route>
        <Route path={'users'}>
          <Route path={'reset-password'} element={<ResetPasswordPage />} />
          <Route path={'me'} element={<MyAccountPage />} />
          <Route path={'me/edit'} element={<EditAccountPage />} />
          <Route path={'me/change-password'} element={<ChangePasswordPage />} />
        </Route>
        <Route path={'/search-result'} element={<SearchResultPage />} />
        <Route
          path={'/distributor/:friendlyUrl'}
          element={<DistributorPage />}
        />
        <Route path={'/port-results'} element={<PortSearchResultPage />} />
        <Route path={'/port/:friendlyUrl'} element={<PortDetailPage />} />
        <Route path={'/family/:friendlyUrl'} element={<ProductFamilyPage />}>
          <Route path={':sector/:culture'} element={<ProductFamilyPage />} />
          <Route path={''} element={<ProductFamilyPage />} />
        </Route>
        <Route path={'/range/:friendlyName'}>
          <Route path={':sector/:culture'} element={<ProductRangePage />} />
          <Route path={''} element={<ProductRangePage />} />
        </Route>
        <Route path={'/product/:friendlyUrl'}>
          <Route path={':sector/:culture'} element={<ProductPage />} />
          <Route path={''} element={<ProductPage />} />
        </Route>
        <Route path={'/:sector/:culture'} element={<HomePage />} />
        <Route path={'/admin'}>
          <Route
            path={'approval-document/:oemApprovalId'}
            element={<ApprovalDocumentPage />}
          />
          <Route path={'oem-results'} element={<OemSearchResultPage />} />
          <Route
            path={'oem-approvals/add'}
            element={<AddMultiOEMApprovalsPage />}
          />
          <Route path={'oem'}>
            <Route path={'add'} element={<AddEditOEMPage />} />
            <Route path={':oemId'} element={<OEMDetailPage />} />
            <Route path={':oemId/edit'} element={<AddEditOEMPage />} />
          </Route>
          <Route path={'users'}>
            <Route path={''} element={<UserListPage />} />
            <Route path={'add-multi'} element={<AddMultiUsersPage />} />
            <Route path={'add'} element={<EditUserPage />} />
            <Route path={':userId'} element={<EditUserPage />} />
          </Route>
          <Route path={'links'}>
            <Route path={''} element={<LinkListPage />} />
            <Route path={'add'} element={<AddEditLinkPage />} />
            <Route path={':linkId/edit'} element={<AddEditLinkPage />} />
          </Route>
          <Route path={'port/add'} element={<ManagePort />} />
          <Route path={'port/edit/:friendlyUrl'} element={<ManagePort />} />
          <Route
            path={'manage-port-products/:portFriendlyUrl'}
            element={<ManagePortProductPage />}
          />
          <Route path={'stockpoint'} element={<ManageStockpoint />} />
          <Route path={'stockpoint/create'} element={<CreateStockpoint />} />
          <Route path={'ordercontact'} element={<ManageOrderContact />} />
          <Route path={'portdistributor'} element={<ManagePortDistributor />} />
          <Route
            path={'portdistributor/add'}
            element={<AddPortDistributor />}
          />
          <Route
            path={'set-country-level-product-info'}
            element={<AddCountryLevelProductPage />}
          />
          <Route
            path={'manage-multiple-products'}
            element={<ManageMultipleProductsPage />}
          />
          <Route
            path={'manage-localised-products'}
            element={<ManageLocalisedProductsPage />}
          />
          <Route
            path={'manage-find-a-distributor-link'}
            element={<ManageFindADistributorLinkPage />}
          />
          <Route path={'add-casestudy'} element={<AddCaseStudyPage />} />
          <Route path={'range/add'} element={<ManageRange />} />
          <Route path={'range/edit/:rangeId'} element={<ManageRange />} />
          <Route path={'family/add'} element={<ManageFamily />} />
          <Route path={'family/edit/:familyId'} element={<ManageFamily />} />
          <Route path={'product/add'} element={<AddProduct />} />
          <Route path={'product/edit/:productId'} element={<AddProduct />} />

          <Route path={'annoucements'}>
            <Route path={''} element={<ManageAnnouncements />} />
            <Route path={'add'} element={<AddEditAnnouncement />} />
            <Route
              path={':announcementId/edit'}
              element={<AddEditAnnouncement />}
            />
          </Route>
          <Route path={'banners'}>
            <Route path={''} element={<ManageBanners />} />
            <Route path={'add'} element={<AddEditBanner />} />
            <Route path={':bannerId/edit'} element={<AddEditBanner />} />
          </Route>
          <Route path={'helps'}>
            <Route path={''} element={<Help />} />
            <Route path={'add'} element={<AddEditHelp />} />
            <Route path={':helpId'} element={<AddEditHelp />} />
            <Route path={':helpId/edit'} element={<AddEditHelp />} />
          </Route>
          <Route path={'reports/dashboard'} element={<ReportsMenuPage />} />
          <Route
            path={'reports/reports-and-quick-stats'}
            element={<ReportsAndQuickStatsPage />}
          />
          <Route
            path={'reports/product-report'}
            element={<ProductReportPage />}
          />
          <Route
            path={'reports/port-product-report'}
            element={<PortProductReportPage />}
          />
          <Route
            path={'reports/port-product-by-country'}
            element={<FilterByCountryPage />}
          />
          <Route
            path={'reports/user-activity'}
            element={<UserActivityPage />}
          />
          <Route path={'reports'}>
            <Route path={''} element={<ReportsMenuPage />} />
            <Route
              path={'reports-and-quick-stats'}
              element={<ReportsAndQuickStatsPage />}
            />
            <Route path={'product-report'} element={<ProductReportPage />} />
            <Route
              path={'port-product-report'}
              element={<PortProductReportPage />}
            />
            <Route
              path={'port-product-by-country'}
              element={<FilterByCountryPage />}
            />
            <Route path={'user-activity'} element={<UserActivityPage />} />
            <Route path={'oem-activity'} element={<OEMActivitiesPage />} />
            <Route path={'port-page-report'} element={<PortPageReportPage />} />
          </Route>
        </Route>
        <Route path={'/*'} element={<Navigate replace to={'/'} />} />
      </Routes>

      <Footer />

      {!acceptance && (
        <AskCookie
          onAccept={() => onAcceptCookie()}
          onManageClick={() => setShowManageCookies(true)}
        />
      )}
      {!acceptance && showManageCookies && (
        <ManageCookies
          onAccept={() => {
            onAcceptCookie();
            setShowManageCookies(false);
          }}
          onClose={() => setShowManageCookies(false)}
        />
      )}

      {welcome && lookupData && (
        <WelcomeModal lookups={lookupData} onClose={() => onCloseWelcome()} />
      )}

      {(loading || showLoading) && (
        <LoadingSpinner className={'lookupsLoading'} />
      )}

      {showContactUsModal && (
        <ContactUsModal onClose={onCloseContactUs} id={id} page={page} />
      )}
      {showModal && (
        <GenericModal
          type={modalData?.type || 'success'}
          titleId={modalData?.titleId || ''}
          messageId={modalData?.messageId || ''}
          onClose={() => {
            dispatch(hideGenericModal());
            const to = modalData?.to;
            if (to && modalData?.type !== 'confirmation') {
              dispatch(setUnsavedStatus(false));
              navigate(to);
            }
          }}
          onYesClick={() => {
            dispatch(hideGenericModal());
            const to = modalData?.to;
            if (to) {
              dispatch(setUnsavedStatus(false));
              navigate(to);
            }
          }}
        />
      )}
      <Toast />
    </IntlProvider>
  );
}

export default App;
