import styles from './FormField.module.scss';
import { PropsWithChildren } from 'react';
import cn from 'classnames';

interface FormFieldProps {
  label: string;
  className?: string;
}

/**
 * form field
 * @param label label
 * @param className classname
 * @param children children
 */
function FormField({
  label,
  className,
  children,
}: PropsWithChildren<FormFieldProps>) {
  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.label}>{label}</div>

      {children}
    </div>
  );
}

export default FormField;
