import { useState, useMemo, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import cn from 'classnames';
import styles from './ChangePasswordPage.module.scss';

import { changePassword } from '../../services/UserService';
import { hideLoading, showLoading } from '../../features/loadingSlice';
import { addToast } from '../../features/toastSlice';
import { getErrorMessage } from '../../utils/string.util';
import { getUserInfoFromLocalStorage } from '../../services/LocalStorageService';

import { PreviousLink } from '../../models/previous-link';
import NavigationHeader from '../../components/common/NavigationHeader/NavigationHeader';
import Button from '../../components/common/Button/Button';
import InputRow from '../../components/Admin/InputRow/InputRow';
import { ReactComponent as CheckCircleIcon } from '../../icons/check-circle.svg';
import FormError from '../../components/common/FormError/FormError';

/**
 * Change page component
 */
function ChangePasswordPage() {
  // init hooks
  const intl = useIntl();
  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [step, setStep] = useState(0);

  const breadcrumbLinks: PreviousLink[] = [
    {
      name: 'Home',
      to: '/home',
    },
    {
      name: getTranslatedMessage('my-account-label'),
      to: '/users/me',
    },
    {
      name: getTranslatedMessage('change-password-label'),
    },
  ];

  const userName = useMemo(() => {
    const email = getUserInfoFromLocalStorage()?.email || '';
    return email ? email.split('@')[0] : null;
  }, []);

  const changePasswordClick = () => {
    dispatch(showLoading());
    changePassword(currentPassword, password)
      .then(() => {
        setStep(1);
      })
      .catch((err) => {
        dispatch(
          addToast({
            type: 'error',
            title: getTranslatedMessage('change-password-failure'),
            message: getErrorMessage(err),
          }),
        );
      })
      .finally(() => dispatch(hideLoading()));
  };

  /**
   * password validator
   * @param value value
   */
  const passwordValidator = (value: string): boolean => {
    if (!value) {
      return true;
    }
    if (value.length < 8) {
      return true;
    }
    if (!/[A-Z]/.test(value)) {
      return true;
    }
    if (!/[a-z]/.test(value)) {
      return true;
    }
    if (userName && value.toLowerCase().indexOf(userName.toLowerCase()) > -1) {
      return true;
    }

    return false;
  };

  const differentPasswords = (): boolean => {
    return !!password && !!confirmPassword && password !== confirmPassword;
  };

  const samePasswords = (): boolean => {
    return !!password && !!confirmPassword && password === confirmPassword;
  };

  return (
    <div>
      <NavigationHeader
        breadcrumbLinks={breadcrumbLinks}
        showBottomLine={true}
      />
      <div className={styles.container}>
        {step === 0 && (
          <Fragment>
            <div className={styles.title}>
              <FormattedMessage id={'change-your-password-label'} />
            </div>
            <div className={styles.description}>
              <FormattedMessage id={'reset-your-password-desc'} />
            </div>
            <div className={styles.passwordRule}>
              <FormattedMessage id={'reset-your-password-rule1'} />
            </div>
            <div className={styles.passwordRule}>
              <FormattedMessage id={'reset-your-password-rule2'} />
            </div>
            <div className={styles.passwordRule}>
              <FormattedMessage id={'reset-your-password-rule3'} />
            </div>
            <div className={styles.passwordRule}>
              <FormattedMessage id={'reset-your-password-rule4'} />
            </div>
            <div className={styles.divider} />

            <div className={'columnRow'}>
              <InputRow
                labelId={'current-password-label'}
                className={styles.passwordInput}
                value={currentPassword}
                required={true}
                isPassword={true}
                onChange={(value: string) => setCurrentPassword(value)}
              />
            </div>

            <div className={'columnRow'}>
              <InputRow
                labelId={'new-password-label'}
                className={styles.passwordInput}
                inputClassName={cn(
                  differentPasswords() && styles.inputError,
                  !passwordValidator(password) &&
                    samePasswords() &&
                    styles.inputOK,
                )}
                value={password}
                required={true}
                isPassword={true}
                onChange={(value: string) => setPassword(value)}
                customValidator={(value: string) => passwordValidator(value)}
              />
            </div>

            <div className={'columnRow'}>
              <InputRow
                labelId={'confirm-new-password-label'}
                className={styles.passwordInput}
                inputClassName={cn(
                  differentPasswords() && styles.inputError,
                  !passwordValidator(confirmPassword) &&
                    samePasswords() &&
                    styles.inputOK,
                )}
                value={confirmPassword}
                required={true}
                isPassword={true}
                onChange={(value: string) => setConfirmPassword(value)}
                customValidator={(value: string) => passwordValidator(value)}
              />
            </div>
            {differentPasswords() && (
              <FormError className={styles.error}>
                <FormattedMessage id={'password-not-same-error'} />
              </FormError>
            )}

            <div className={styles.buttonContainer}>
              <Button
                onClick={() => changePasswordClick()}
                color={'green'}
                className={cn(styles.button, styles.changePwdBtn)}
                disabled={
                  !currentPassword ||
                  currentPassword.trim().length === 0 ||
                  passwordValidator(password) ||
                  passwordValidator(confirmPassword) ||
                  differentPasswords()
                }
              >
                <FormattedMessage id={'change-password-label'} />
              </Button>
              <Button
                onClick={() => navigate('/users/me')}
                color={'green-outline'}
                className={cn(styles.button, styles.cancelButton)}
              >
                <FormattedMessage id={'cancel-label'} />
              </Button>
            </div>
          </Fragment>
        )}
        {step === 1 && (
          <Fragment>
            <div className={styles.title}>
              <CheckCircleIcon className={styles.checkCircleIcon} />
              <FormattedMessage id={'password-updated-label'} />
            </div>
            <div className={styles.passwordRule}>
              <FormattedMessage id={'password-updated-success'} />
            </div>
            <div className={cn(styles.divider)} />

            <div className={styles.buttonContainer}>
              <Button
                onClick={() => navigate('/users/me')}
                color={'green'}
                className={cn(styles.button, styles.loginBtn)}
              >
                <FormattedMessage id={'done-label'} />
              </Button>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
}

export default ChangePasswordPage;
