import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { RootStore } from '../../../store';
import { uniq } from 'lodash';

import styles from './AddMultiOEMApprovalsPage.module.scss';
import { PreviousLink } from '../../../models/previous-link';
import NavigationHeader from '../../../components/common/NavigationHeader/NavigationHeader';
import ContainerForDrawer from '../../../components/common/ContainerForDrawer/ContainerForDrawer';
import LoadingSpinner from '../../../components/common/LoadingSpinner/LoadingSpinner';

import Button from '../../../components/common/Button/Button';
import {
  getAllOems,
  getActiveProducts,
  createBulkOEMApproval,
} from '../../../services/OemService';
import AddOemApprovalsTable, {
  DataItem,
} from '../../../components/Admin/common/AddOemApprovalsTable/AddOemApprovalsTable';
import { setOems, setProductsOems } from '../../../features/oemSlice';
import { showGenericModal } from '../../../features/genericModalSlice';
import { addToast } from '../../../features/toastSlice';
import { getDateFormatString } from '../../../utils/date.util';
import { OEMApprovalItem } from '../../../models/bulk-oem-approval';
import { uploadDocument } from '../../../services/DocumentService';
import { UploadDocumentResponse } from '../../../models/upload-document-response';

/**
 * OEM Detail page
 */
function AddMultiOEMApprovalsPage() {
  // init hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();
  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });

  // init states.
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState<boolean>(false);

  const [breadcrumbLinks, setBreadcrumbLinks] = useState<PreviousLink[]>([
    {
      name: 'Home',
      to: '/home',
    },
  ]);
  const [tableData, setTableData] = useState<DataItem[]>([]);

  const { oems, activeProducts } = useSelector((state: RootStore) => state.oem);

  //effects

  /**
   * Load data by oemId.
   */
  useEffect(() => {
    setBreadcrumbLinks([
      {
        name: 'Home',
        to: '/home',
      },
      {
        name: getTranslatedMessage('add-multi-oem-approvals-label'),
      },
    ]);

    setLoading(true);
    /* istanbul ignore next */
    Promise.all([getAllOems(), getActiveProducts()])
      .then((values) => {
        dispatch(setOems(values[0]));
        dispatch(setProductsOems(values[1]));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch]);

  const checkChanged = (): boolean => {
    const firstData = tableData[0];
    return (
      tableData.length !== 0 &&
      firstData &&
      ((firstData.oemId !== -1 && firstData.oemId !== null) ||
        firstData.productId !== null ||
        firstData.approvalTypeId !== null ||
        firstData.active ||
        firstData.newDocument !== null ||
        firstData.issueDate !== null ||
        firstData.validUntil !== null ||
        firstData.confidential ||
        firstData.restrictions !== '' ||
        firstData.notes !== '')
    );
  };

  const onCancelBtnClick = () => {
    if (!checkChanged()) {
      navigate('/home');
    } else {
      dispatch(
        showGenericModal({
          type: 'confirmation',
          titleId: 'oem-model-unsaved-changes',
          messageId: 'oem-model-unsaved-changes-desc',
          to: '/home',
        }),
      );
    }
  };

  /* istanbul ignore next */
  const uploadFile = (file: File): Promise<UploadDocumentResponse> => {
    const formData = new FormData();
    formData.append('type', 'Approval');
    formData.append('file', file);

    return uploadDocument(formData);
  };

  /* istanbul ignore next */
  const getFileToken = (file: File | null): string => {
    if (!file) {
      return '';
    }

    return file.name + '-' + file.size + '-' + file.lastModified;
  };

  /* istanbul ignore next */
  const onSaveBtnClick = () => {
    if (tableData.length === 0) {
      return;
    }

    setProcessing(true);

    const allFileIds: { [key: string]: number } = {};
    const allFiles = uniq(tableData.map((item) => item.newDocument as File));
    const uploadFuncs = allFiles.map((item) => uploadFile(item));
    Promise.all(uploadFuncs)
      .then((values) => {
        values.forEach((res, i) => {
          allFileIds[getFileToken(allFiles[i])] = res.id;
        });

        const allGroups = uniq(tableData.map((item) => item.group || 0));
        const bulkData: OEMApprovalItem[] = [];
        // add group data first
        for (const groupId of allGroups) {
          if (groupId === 0) {
            continue;
          }
          const groupData = tableData.filter((item) => item.group === groupId);
          const approvalData = groupData.map((item) => ({
            oemId: item.oemId as number,
            productId: item.productId as number,
            active: item.active,
            oemApprovalTypeId: item.approvalTypeId as number,
            restrictions: item.restrictions,
            notes: item.notes,
          }));
          bulkData.push({
            documentId: allFileIds[getFileToken(groupData[0].newDocument)],
            issuedAt: getDateFormatString(groupData[0].issueDate),
            validUntil: getDateFormatString(groupData[0].validUntil),
            bpConfidential: groupData[0].confidential,
            oemProductApprovals: approvalData,
          });
        }
        // add single data second
        const noGroupData = tableData.filter((item) => !item.group);
        noGroupData.forEach((item) => {
          bulkData.push({
            documentId: allFileIds[getFileToken(item.newDocument)],
            issuedAt: getDateFormatString(item.issueDate),
            validUntil: getDateFormatString(item.validUntil),
            bpConfidential: item.confidential,
            oemProductApprovals: [
              {
                oemId: item.oemId as number,
                productId: item.productId as number,
                active: item.active,
                oemApprovalTypeId: item.approvalTypeId as number,
                restrictions: item.restrictions,
                notes: item.notes,
              },
            ],
          });
        });

        createBulkOEMApproval({ items: bulkData })
          .then(() => {
            dispatch(
              showGenericModal({
                titleId: 'oem-model-add-multi-oem-approvals-success',
                messageId: 'oem-model-add-multi-oem-approvals-success-info',
                to: `/home`,
              }),
            );
          })
          .catch(() => {
            dispatch(
              addToast({
                type: 'error',
                title: getTranslatedMessage('create-bulk-oem-approval-error'),
                message: '',
              }),
            );
          })
          .finally(() => {
            setProcessing(false);
          });
      })
      .catch(() => {
        setProcessing(false);
        dispatch(
          addToast({
            type: 'error',
            title: getTranslatedMessage('upload-document-error'),
            message: '',
          }),
        );
      });
  };

  const invalidData = (): boolean => {
    const res =
      tableData.length > 0 &&
      tableData.every(
        (item) =>
          item.oemId &&
          item.productId &&
          item.approvalTypeId &&
          item.newDocument &&
          item.issueDate,
      );
    return !res;
  };

  return (
    <div>
      <NavigationHeader breadcrumbLinks={breadcrumbLinks} />
      <ContainerForDrawer>
        {loading ? (
          <LoadingSpinner className={styles.loading} />
        ) : (
          <div className={styles.addOemApprovalsPageContainer}>
            <div className={styles.titleRow}>
              <div className={styles.title}>
                <FormattedMessage id={'add-multi-oem-approvals-label'} />
              </div>
            </div>

            <div className={styles.divider}></div>

            <div className={styles.tableContainer}>
              <AddOemApprovalsTable
                oemNames={oems}
                productNames={activeProducts}
                onChange={setTableData}
              />
            </div>

            <div className={styles.actionRow}>
              <Button
                color={'green-outline'}
                className={styles.cancelButton}
                onClick={onCancelBtnClick}
              >
                <FormattedMessage id={'cancel-label'} />
              </Button>
              <Button
                color={'green'}
                className={styles.saveButton}
                onClick={onSaveBtnClick}
                disabled={invalidData()}
              >
                <FormattedMessage id={'save-label'} />
              </Button>
            </div>
          </div>
        )}
      </ContainerForDrawer>
      {processing && <LoadingSpinner className={'lookupsLoading'} />}
    </div>
  );
}

export default AddMultiOEMApprovalsPage;
