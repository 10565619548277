import { PropsWithoutRef, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDropzone } from 'react-dropzone';

import { ReactComponent as Add } from '../../../../../../icons/add.svg';
import Button from '../../../../../../components/common/Button/Button';
import Checkbox from '../../../../../../components/common/Checkbox/Checkbox';
import Input from '../../../../../../components/common/Input/Input';
import FieldLabel from '../FieldLabel/FieldLabel';
import styles from './AddCaseStudyForm.module.scss';
import FormRow from '../../../../../../components/Admin/FormRow/FormRow';
import MultipleSelect from '../../../../../../components/Admin/MultiSelect/MultiSelect';

interface AddCaseStudyFormProps {
  title: string;
  setTitle: (x: any) => void;
  setApplications1: (x: any) => void;
  setSubApplications1: (x: any) => void;
  setSectors1: (x: any) => void;
  setSubSectors1: (x: any) => void;
  setCaseStudycountries1: (x: any) => void;
  setCaseStudyBenefits1: (x: any) => void;
  customer: string;
  setCustomer: (x: any) => void;
  machine: string;
  setMachine: (x: any) => void;
  location: string;
  setLocation: (x: any) => void;
  competitor: string;
  setCompetitor: (x: any) => void;
  visibility: number[];
  setVisibility: (x: any) => void;
  setFile: (x: any) => void;
  enableSave: boolean;
  handleSave: () => void;
  handleCancelBtnClick: () => void;
  applications: any[];
  applications1: any[];
  subApplications: any[];
  subApplications1: any[];
  sectors: any[];
  sectors1: any[];
  subSectors: any[];
  subSectors1: any[];
  countries: any[];
  countries1: any[];
  benefits: any[];
  benefits1: any[];
  // applications2: number[];
}

function AddCaseStudyForm({
  title,
  setTitle,
  customer,
  setCustomer,
  // machine,
  // setMachine,
  location,
  setLocation,
  competitor,
  setCompetitor,
  visibility,
  setVisibility,
  setFile,
  enableSave,
  handleSave,
  handleCancelBtnClick,
  applications,
  applications1,
  setApplications1,
  subApplications,
  subApplications1,
  setSubApplications1,
  sectors,
  sectors1,
  setSectors1,
  subSectors,
  subSectors1,
  setSubSectors1,
  countries,
  countries1,
  setCaseStudycountries1,
  benefits,
  benefits1,
  setCaseStudyBenefits1,
}: // applications2,
PropsWithoutRef<AddCaseStudyFormProps>) {
  // const { applications, subApplications } = useSelector(
  //   (state: RootStore) => state.lookups,
  // );
  const intl = useIntl();

  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });

  const onDrop = useCallback(
    (files) => {
      if (setFile) setFile(files[0]);
    },
    [setFile],
  );

  const visibilityOptions: {
    value: number;
    label: string;
  }[] = useMemo(
    () => [
      // {
      //   value: 1,
      //   label: getTranslatedMessage('case-details-visibility-anonymous'),
      // },
      // {
      //   value: 2,
      //   label: getTranslatedMessage('case-details-visibility-distributor'),
      // },
      {
        value: 3,
        label: getTranslatedMessage('case-details-visibility-others'),
      },
    ],
    [],
  );

  const descriptionValues = {
    b: (value: string[]) => <b>{value}</b>,
  };

  // Hooks used of react-dropzone to get a modular file uploading component
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop,
  });

  const hasFiles = Boolean(acceptedFiles.length);
  const applicationsItems = useMemo(
    () =>
      applications.map((item) => ({
        name: item.name,
        value: item.id,
      })),
    [applications],
  );
  const countryItems = useMemo(
    () =>
      countries.map((item) => ({
        name: item.name,
        value: item.id,
      })),
    [countries],
  );
  const benefitItems = useMemo(
    () =>
      benefits.map((item) => ({
        name: item.name,
        value: item.id,
      })),
    [benefits],
  );
  const sectorItems = useMemo(
    () =>
      sectors.map((item) => ({
        name: item.name,
        value: item.id,
      })),
    [sectors],
  );

  const subApplicationsItems = useMemo(() => {
    if (
      applications1?.length === 0 ||
      !subApplications.some((item) => {
        return applications1?.some((app: any) => app === item.applicationId);
      })
    ) {
      return {
        items: subApplications.map((item) => ({
          name: item.name,
          value: item.id,
        })),
        disabled: true,
      };
    }
    return {
      items: subApplications
        .filter((item) => {
          return applications1?.some((app: any) => app === item.applicationId);
        })
        .map((item) => ({
          name: item.name,
          value: item.id,
        })),
      disabled: false,
    };
  }, [subApplications1, applications1]);
  const subSectorItems = useMemo(() => {
    if (
      sectors1?.length === 0 ||
      !subSectors.some((item) => {
        return sectors1?.some((app: any) => app === item.sectorId);
      })
    ) {
      return {
        items: subSectors.map((item) => ({
          name: item.name,
          value: item.id,
        })),
        disabled: true,
      };
    }
    return {
      items: subSectors
        .filter((item) => {
          return sectors1?.some((app: any) => app === item.sectorId);
        })
        .map((item) => ({
          name: item.name,
          value: item.id,
        })),
      disabled: false,
    };
  }, [subSectors1, sectors1]);
  const handleValueChange = (fieldKey: any, value: any) => {
    if (fieldKey === 'subApplications') {
      setSubApplications1(value);
    } else if (fieldKey === 'applications') {
      setApplications1(value);
    } else if (fieldKey === 'sectors') {
      setSectors1(value);
    } else if (fieldKey === 'subSectors') {
      setSubSectors1(value);
    } else if (fieldKey === 'countries') {
      setCaseStudycountries1(value);
    } else if (fieldKey === 'benefits') {
      setCaseStudyBenefits1(value);
    }

    console.log(fieldKey, value);
  };

  return (
    <>
      <div className={styles.mainCard}>
        <>
          <h2 className={styles.heading}>
            <FormattedMessage id={'case-details-label'} />
          </h2>
          <div className={styles.divider} />
          <FieldLabel
            id={'case-details-title'}
            helpTextId={'casestudy-title-help-text'}
          />
          <Input
            value={title}
            onChange={(value) => setTitle(value)}
            className={styles.input}
            placeholder={getTranslatedMessage('case-details-enter-placeholder')}
            required
          />
          {/* <FieldLabel
            id={'case-details-attributes'}
            helpTextId={'casestudy-title-help-attributes'}
          /> */}
          <div className={styles.twoColumnGrid}>
            <div className={styles.attributeWrapper}>
              {/* <div className={styles.attributeLabel}>
                <FormattedMessage id={'case-details-customer'} />
              </div> */}
              <FormRow
                labelId={'case-details-customer'}
                required={false}
                tooltip={intl.formatMessage({
                  id: 'browse-casestudy-customer',
                })}
              ></FormRow>
              <Input
                value={customer}
                onChange={(value) => setCustomer(value)}
                className={styles.input}
                placeholder={getTranslatedMessage(
                  'case-details-enter-placeholder',
                )}
                required
              />
            </div>
            <div className={styles.attributeWrapper}>
              {/* <div className={styles.attributeLabel}>
                <FormattedMessage id={'case-details-competitor'} />
              </div> */}
              <FormRow
                labelId={'case-details-competitor'}
                required={false}
                tooltip={intl.formatMessage({
                  id: 'browse-casestudy-Previous',
                })}
              ></FormRow>
              <Input
                value={competitor}
                onChange={(value) => setCompetitor(value)}
                className={styles.input}
                placeholder={getTranslatedMessage(
                  'case-details-enter-placeholder',
                )}
                required
              />
            </div>
            <FormRow
              labelId={'product-application-label'}
              // required={!formValue.displayOnlyPartialInfo}
              tooltip={intl.formatMessage({
                id: 'product-application-tooltip',
              })}
            >
              <MultipleSelect
                className={styles.multiSelect}
                values={applications1}
                options={applicationsItems}
                onChange={(value: number[]) =>
                  handleValueChange('applications', value)
                }
                placeholder={intl.formatMessage({ id: 'select-placeholder' })}
                canSearch
                // showTooltip={true}
              />
            </FormRow>
            <FormRow
              labelId={'product-sub-application-label'}
              required={false}
              tooltip={intl.formatMessage({
                id: 'product-sub-application-tooltip',
              })}
            >
              <MultipleSelect
                disabled={subApplicationsItems.disabled}
                className={styles.multiSelect}
                values={subApplications1}
                options={subApplicationsItems.items}
                onChange={(value: number[]) =>
                  handleValueChange('subApplications', value)
                }
                placeholder={intl.formatMessage({ id: 'select-placeholder' })}
                canSearch
              />
            </FormRow>
            <FormRow
              labelId={'product-replacement-sector-label'}
              // required={!formValue.displayOnlyPartialInfo}
              tooltip={intl.formatMessage({
                id: 'product-Sector-tooltip',
              })}
            >
              <MultipleSelect
                className={styles.multiSelect}
                values={sectors1}
                options={sectorItems}
                onChange={(value: number[]) =>
                  handleValueChange('sectors', value)
                }
                placeholder={intl.formatMessage({ id: 'select-placeholder' })}
                canSearch
                // showTooltip={true}
              />
            </FormRow>
            <FormRow
              labelId={'add-bulk-products-sub-sector-label'}
              required={true}
              tooltip={intl.formatMessage({
                id: 'product-subsector-tooltip',
              })}
            >
              <MultipleSelect
                disabled={subSectorItems.disabled}
                className={styles.multiSelect}
                values={subSectors1}
                options={subSectorItems.items}
                onChange={(value: number[]) =>
                  handleValueChange('subSectors', value)
                }
                placeholder={intl.formatMessage({ id: 'select-placeholder' })}
                canSearch
              />
            </FormRow>
            <FormRow
              labelId={'product-status-country-label'}
              required={false}
              tooltip={intl.formatMessage({
                id: 'product-countries-tooltip',
              })}
            >
              <MultipleSelect
                className={styles.multiSelect}
                values={countries1}
                options={countryItems}
                onChange={(value: number[]) =>
                  handleValueChange('countries', value)
                }
                placeholder={intl.formatMessage({ id: 'select-placeholder' })}
                canSearch
              />
            </FormRow>
            <FormRow
              labelId={'product-status-Benefits-label'}
              required={false}
              tooltip={intl.formatMessage({
                id: 'product-Benefits-tooltip',
              })}
            >
              <MultipleSelect
                className={styles.multiSelect}
                values={benefits1}
                options={benefitItems}
                onChange={(value: number[]) =>
                  handleValueChange('benefits', value)
                }
                placeholder={intl.formatMessage({ id: 'select-placeholder' })}
                canSearch
              />
            </FormRow>
            {/* <div className={styles.attributeWrapper}>
              <div className={styles.attributeLabel}>
                <FormattedMessage id={'case-details-machine'} />
              </div>
              <Input
                value={machine}
                onChange={(value) => setMachine(value)}
                className={styles.input}
                placeholder={getTranslatedMessage(
                  'case-details-enter-placeholder',
                )}
                required
              />
            </div> */}
            <div className={styles.attributeWrapper}>
              <FormRow
                labelId={'case-details-location'}
                required={false}
                // tooltip={intl.formatMessage({
                //   id: 'product-sub-application-tooltip',
                // })}
              >
                {/* <div className={styles.attributeLabel}>
                <FormattedMessage id={'case-details-location'} />
              </div> */}
                <Input
                  value={location}
                  onChange={(value) => setLocation(value)}
                  className={styles.input}
                  placeholder={getTranslatedMessage(
                    'case-details-enter-placeholder',
                  )}
                />
              </FormRow>
            </div>
          </div>
          <div className={styles.divider} />
          <div className={styles.twoColumnGrid}>
            <div className={styles.borderedBox}>
              <FieldLabel
                id={'case-details-user-visibility'}
                helpTextId={'casestudy-title-help-visibility'}
              />
              {visibilityOptions.map((option) => (
                <div key={option.value} className={styles.option}>
                  <Checkbox
                    checked={visibility.includes(option.value)}
                    onClick={() => {
                      if (visibility.includes(option.value)) {
                        setVisibility(
                          visibility.filter(
                            (current) => current !== option.value,
                          ),
                        );
                      } else {
                        setVisibility([...visibility, option.value]);
                      }
                    }}
                  />
                  <div className={styles.optionLabel}>{option.label}</div>
                </div>
              ))}
            </div>
            <div className={styles.borderedBox}>
              <FieldLabel
                id={'case-details-upload-study'}
                helpTextId={'casestudy-title-help-upload'}
              />
              <div className={styles.inputWrapper} {...getRootProps()}>
                {!hasFiles ? <Add className={styles.add} /> : null}
                <div className={styles.inputText}>
                  {hasFiles ? (
                    acceptedFiles[0].name
                  ) : (
                    <>
                      - <FormattedMessage id={'select'} /> -
                    </>
                  )}
                  <input
                    type="file"
                    name="files[]"
                    id="upload-file"
                    {...getInputProps()}
                    accept="application/pdf"
                  />
                </div>
              </div>
              <div className={styles.helpText}>
                <FormattedMessage
                  id={'case-details-upload-help'}
                  values={descriptionValues}
                />
              </div>
            </div>
          </div>
        </>
      </div>
      <div className={styles.buttons}>
        <Button
          color={'gray'}
          className={styles.button}
          onClick={handleCancelBtnClick}
        >
          <FormattedMessage id={'cancel-label'} />
        </Button>
        <Button
          color={'green'}
          className={styles.button}
          disabled={!enableSave}
          onClick={handleSave}
        >
          <FormattedMessage id={'save-label'} />
        </Button>
      </div>
    </>
  );
}

export default AddCaseStudyForm;
