import { FormattedMessage } from 'react-intl';

import styles from './CommentModal.module.scss';
import Modal from '../../../common/Modal/Modal';

type CommentModalProps = {
  titleId: string;
  name: string;
  comment: string;
  onClose: () => void;
};

const CommentModal = ({
  titleId,
  name,
  comment,
  onClose,
}: CommentModalProps) => {
  return (
    <Modal center={true} onClose={onClose} className={styles.commentContainer}>
      <div className={styles.container}>
        <h1>
          <FormattedMessage id={titleId} />
          {name}
        </h1>
        <div className={styles.modalContent}>
          <div className={styles.contentWrapper}>{comment}</div>
        </div>
      </div>
    </Modal>
  );
};

export default CommentModal;
