import styles from './ExpandableRow.module.scss';
import { ReactComponent as UpArrowIcon } from '../../../../../icons/arrow-gray.svg';
import { PropsWithChildren, ReactNode, useState } from 'react';
import classNames from 'classnames';

interface ExpandableRowProps {
  title: string | ReactNode;
  className?: string;
}

export default function ExpandableRow({
  title,
  className,
  children,
}: PropsWithChildren<ExpandableRowProps>) {
  const [isExpand, setExpand] = useState(false);

  return (
    <div className={classNames(className, styles.container)}>
      <div
        className={classNames(
          styles.header,
          isExpand ? '' : styles.collapseHead,
        )}
        onClick={() => setExpand((isExpand) => !isExpand)}
      >
        <div className={styles.title}>{title}</div>
        <UpArrowIcon
          className={classNames(
            styles.clickable,
            isExpand ? styles.upArrow : styles.downArrow,
          )}
        />
      </div>
      <div
        className={classNames(styles.body, isExpand ? '' : styles.collapseBody)}
      >
        {children}
      </div>
    </div>
  );
}
