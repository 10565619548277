import FilterGroup from '../FilterGroup';
import { PropsWithoutRef, useMemo } from 'react';
import { FilterOption } from '../../../../models/filter-option';
import { MultiSelectFilter } from '../../../../models/filter';
import { LookupItemDictionary } from '../../../../models/lookups';

interface MultiSelectFilterGroupProps {
  open?: boolean;
  className?: string;
  title: string;
  filterOfKey: MultiSelectFilter;
  lookupDict?: LookupItemDictionary;
  onFilterChange: (filter: FilterOption) => void;
  onFilterReset: () => void;
  hideAll?: boolean;
}

/**
 * filter group for family
 * @param type filter type
 * @param open set true to make options opened on init
 * @param className classname
 */
function MultiSelectFilterGroup({
  open,
  className,
  title,
  filterOfKey,
  lookupDict,
  hideAll,
  onFilterChange,
  onFilterReset,
}: PropsWithoutRef<MultiSelectFilterGroupProps>) {
  const actualFilters = useMemo<FilterOption[]>(() => {
    return filterOfKey.options.map((id) => {
      return {
        name: lookupDict ? lookupDict[id]?.name || '' : String(id),
        value: String(id),
      };
    });
  }, [filterOfKey, lookupDict]);

  const actualSelectedFilters = useMemo<FilterOption[]>(() => {
    return filterOfKey.value.map((id) => ({
      name: lookupDict ? lookupDict[id]?.name || '' : String(id),
      value: String(id),
    }));
  }, [filterOfKey, lookupDict]);

  return (
    <FilterGroup
      open={open}
      className={className}
      title={title}
      filters={actualFilters}
      onChange={onFilterChange}
      onReset={onFilterReset}
      selectedOptions={actualSelectedFilters}
      showAll={!hideAll}
    />
  );
}

export default MultiSelectFilterGroup;
