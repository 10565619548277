import styles from './Banner.module.scss';
import { PropsWithoutRef } from 'react';
import PromotionSlider from './PromotionSlider/PromotionSlider';
import { HomePromotion } from '../../../models/home-promotion';
import HomeSearch from './HomeSearch/HomeSearch';
import LoadingSpinner from '../../common/LoadingSpinner/LoadingSpinner';
import { Announcement } from '../../../models/announcement';
import AnnouncementSlider from './AnnouncementSlider/AnnouncementSlider';
import cn from 'classnames';

interface BannerProps {
  loading: boolean;
  promotions: HomePromotion[];
  announcements: Announcement[];
  onSearchClick: () => void;
}

/**
 * home page banner
 * @param loading loading
 * @param promotions promotions data
 * @param onSearchClick search click handler
 */
function Banner({
  loading,
  promotions,
  announcements,
  onSearchClick,
}: PropsWithoutRef<BannerProps>) {
  return (
    <div
      className={cn(
        styles.container,
        announcements?.length > 0 && styles.hasAnnouncements,
        promotions?.length > 0 && styles.hasPromotions,
      )}
    >
      <HomeSearch
        onSearchClick={onSearchClick}
        hasAnnouncementsNoPromotions={
          announcements?.length > 0 && (!promotions || promotions.length === 0)
        }
      />

      {loading ? (
        <LoadingSpinner className={styles.loading} />
      ) : (
        <>
          <AnnouncementSlider
            className={styles.announcements}
            announcements={announcements}
          />
          {promotions && promotions.length > 0 && (
            <PromotionSlider promotions={promotions} />
          )}
        </>
      )}
    </div>
  );
}

export default Banner;
