import styles from './Input.module.scss';
import { PropsWithoutRef, useState, ChangeEvent, useEffect } from 'react';
import cn from 'classnames';
import FormError from '../FormError/FormError';
import { ReactComponent as CancelIcon } from '../../../icons/cancel-grey.svg';
import { ReactComponent as EyeIcon } from '../../../icons/eye-fill-green.svg';

interface InputProps {
  value: string;
  onChange: (value: string) => void;
  required?: boolean;
  placeholder?: string;
  type?: string;
  containerClassName?: string;
  className?: string;
  errorMessage?: string;
  showCancelIcon?: boolean;
  disabled?: boolean;
  isPassword?: boolean;
  customValidator?: (value: string) => boolean;
}

/**
 * input component
 * @param value value
 * @param onChange change handler
 * @param required required
 * @param placeholder placeholder
 * @param type type
 * @param className classname
 * @param errorMessage error message
 * @param showCancelButton whether show cancel
 * @param customValidator custom validator
 */
function Input({
  value,
  onChange,
  required = false,
  placeholder = '',
  type = 'text',
  containerClassName,
  className,
  errorMessage,
  showCancelIcon = false,
  disabled = false,
  isPassword = false,
  customValidator,
}: PropsWithoutRef<InputProps>) {
  const [inputValue, setInputValue] = useState('');
  const [changed, setChanged] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [showPassword, setShowPassword] = useState(true);

  useEffect(() => {
    setInvalid(false);
  }, []);

  useEffect(() => {
    if (value !== undefined && value !== null) {
      setInputValue(value);
    }
  }, [value]);

  const validate = (value: string): void => {
    if (customValidator) {
      setInvalid(customValidator(value));
    } else if (required) {
      setInvalid(!value || value.trim().length === 0);
    }
  };

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
    setInputValue(event.target.value);
    setChanged(true);

    validate(event.target.value);
  };

  const onBlur = (event: ChangeEvent<HTMLInputElement>) => {
    setChanged(true);
    validate(event.target.value);
  };

  const clearInput = () => {
    setInputValue('');
    onChange('');
    setChanged(false);
    setInvalid(false);
  };

  return (
    <div className={cn(styles.container, containerClassName)}>
      <div
        className={cn(
          styles.inputWrap,
          className,
          changed && styles.changed,
          invalid && styles.invalid,
          disabled && styles.disabled,
        )}
      >
        <input
          onBlur={onBlur}
          value={inputValue}
          onChange={(event) => onInputChange(event)}
          required={required}
          placeholder={placeholder}
          disabled={disabled}
          type={isPassword && showPassword ? 'password' : type}
          className={cn(
            styles.input,
            (showCancelIcon || isPassword) && styles.showIcon,
            isPassword && showPassword && inputValue && styles.password,
          )}
        />
        {showCancelIcon && (
          <div className={styles.iconContainer}>
            <CancelIcon onClick={() => clearInput()} />
          </div>
        )}
        {isPassword && (
          <div
            className={cn(
              styles.iconContainer,
              isPassword && styles.showEyeIcon,
            )}
          >
            <EyeIcon
              className={styles.eyeIcon}
              onClick={() => setShowPassword(!showPassword)}
            />
          </div>
        )}
      </div>

      {errorMessage && (
        <FormError className={styles.error}>{errorMessage}</FormError>
      )}
    </div>
  );
}

export default Input;
