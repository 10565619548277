import styles from './OemApprovalTable.module.scss';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import { PropsWithoutRef, useState, useEffect } from 'react';
import { find, cloneDeep } from 'lodash';
import ReactTooltip from 'react-tooltip';
import XLSX from 'xlsx';

import { RootStore } from '../../../../store';
import { getDateString } from '../../../../utils/date.util';
import { getAlphaDigitName } from '../../../../utils/string.util';
import { ReactComponent as XlsxFileIcon } from '../../../../icons/xlsx-file.svg';
import { ReactComponent as SortArrow } from '../../../../icons/sort-arrow.svg';
import { ReactComponent as InfoIcon } from '../../../../icons/info.svg';
import { ReactComponent as Caret } from '../../../../icons/caret.svg';
import { ReactComponent as EditWhiteIcon } from '../../../../icons/edit-white.svg';
import { ReactComponent as XOctagonWhiteIcon } from '../../../../icons/x-octagon-white.svg';
import { ReactComponent as DocWhiteIcon } from '../../../../icons/ic-doc-white.svg';
import { OemApproval } from '../../../../models/oem-approval';
import OemApprovalActionMenu from '../OemApprovalActionMenu/OemApprovalActionMenu';
import { sortMethodWithOrderByColumn } from '../../../../utils/sort.util';
import Button from '../../../common/Button/Button';
import CommentModal from '../CommentModal/CommentModal';
import DeactivateApprovalModal from '../DeactivateApprovalModal/DeactivateApprovalModal';
import { OemProductApproval } from '../../../../models/oem-product-approval';
import { getUserInfoFromLocalStorage } from '../../../../services/LocalStorageService';
interface OemApprovalTableProps {
  oemApprovals: OemApproval[];
  product: {
    name: string;
    masterBrandId: number;
  };
  exportFileName?: string;
  className?: string;
  activateApproval?: (approvalId: number) => void;
  deactivateApproval?: (approvalId: number) => void;
  activateOem?: (oemId: number) => void;
  deactivateOem?: (oemId: number) => void;
}

/**
 * Oem Approval table
 * @param oemApprovals oem approvals
 * @param className classname
 */
function OemApprovalTable({
  oemApprovals,
  product,
  exportFileName = 'sample',
  className,
  activateApproval,
  deactivateApproval,
  activateOem,
  deactivateOem,
}: PropsWithoutRef<OemApprovalTableProps>) {
  // init hooks
  const intl = useIntl();
  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });
  const navigate = useNavigate();

  const { oemApprovalTypes } = useSelector((state: RootStore) => state.lookups);

  const [sorted, setSorted] = useState<OemApproval[]>([]);
  const [sortBy, setSortBy] = useState('');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [expanded, setExpanded] = useState<{ id: number; expanded: boolean }[]>(
    [],
  );
  const [oemEdit, setoemEdit] = useState(false);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [showDeactivateOemModal, setShowDeactivateOemModal] = useState(false);
  const [selectedOemProductApproval, setSelectedOemProductApproval] =
    useState<OemProductApproval>();
  const [commentData, setCommentData] = useState<{
    titleId: string;
    name: string;
    comment: string;
  }>({
    titleId: '',
    name: '',
    comment: '',
  });
  const current = new Date();

  //effects
  useEffect(() => {
    const userInfo = getUserInfoFromLocalStorage();
    if (userInfo) {
      if (userInfo.role === 'Super Admin' || userInfo.role === 'Approver') {
        setoemEdit(true);
      }
    }
    if (oemApprovals && oemApprovals.length > 0) {
      if (sortBy) {
        const sortedList = oemApprovals.sort(
          sortMethodWithOrderByColumn(sortBy, sortDirection),
        );
        setSorted(sortedList);
      } else {
        setSorted(oemApprovals);
      }
    }
  }, [oemApprovals]);

  const descriptionValues = {
    p: (value: string[]) => <p>{value}</p>,
    strong: (value: string[]) => <strong>{value}</strong>,
  };

  const getOemApprovalTypeName = (id: number): string => {
    if (!oemApprovalTypes) {
      return '';
    }

    const approvalType = find(oemApprovalTypes, (item) => item.id === id);
    return approvalType?.name || '';
  };

  const menuClick = (val: string, item: OemApproval) => {
    const tmpItem: any = cloneDeep(item);
    tmpItem.product = product;
    setSelectedOemProductApproval(tmpItem);
    if (val === 'menu-edit-oem') {
      navigate(`/admin/oem/${item.oemId}/edit`);
    } else if (
      val === 'menu-deactivate-approval' ||
      val === 'menu-activate-approval'
    ) {
      setShowDeactivateOemModal(true);
    } else if (val === 'menu-activate-oem') {
      activateOem && activateOem(item.oemId);
    } else if (val === 'menu-deactivate-oem') {
      deactivateOem && deactivateOem(item.oemId);
    }
  };

  const onSortChange = (key: string) => {
    let changedKey = sortBy;
    let changedDirection: 'asc' | 'desc';

    if (sortBy !== key) {
      changedKey = key;
      changedDirection = 'desc';
    } else {
      if (sortDirection === 'desc') {
        changedDirection = 'asc';
      } else {
        changedDirection = 'desc';
      }
    }

    setSortBy(changedKey);
    setSortDirection(changedDirection);

    const sortedList = oemApprovals.sort(
      sortMethodWithOrderByColumn(changedKey, changedDirection),
    );
    setSorted(sortedList);
  };

  const handleCollapseClick = (
    index: number,
    expanded: { id: number; expanded: boolean }[],
  ) => {
    if (expanded && expanded.length > 0) {
      const items = cloneDeep(expanded);
      const item = expanded.find((x: any) => x.id === index);
      if (item) {
        const itemInd = expanded.findIndex((x: any) => x.id === index) || 0;
        items[itemInd].expanded = !item.expanded;
        setExpanded(items);
      } else {
        items.push({ id: index, expanded: false });
        setExpanded(items);
      }
    } else {
      setExpanded([{ id: index, expanded: true }]);
    }
  };

  const showCommentDetail = (item: OemApproval) => {
    setCommentData({
      titleId: 'oem-model-field-comment-relating-to',
      name: item.oem.name,
      comment: item.notes,
    });
    setShowCommentModal(true);
  };

  const showApprovalRestrictionsDetail = (item: OemApproval) => {
    setCommentData({
      titleId: 'oem-model-field-approval-restrictions-relating-to',
      name: item.oem.name,
      comment: item.restrictions,
    });
    setShowCommentModal(true);
  };

  const handleSave = () => {
    setShowDeactivateOemModal(false);
    if (selectedOemProductApproval) {
      if (selectedOemProductApproval.active) {
        deactivateApproval && deactivateApproval(selectedOemProductApproval.id);
      } else {
        activateApproval && activateApproval(selectedOemProductApproval.id);
      }
    }
  };

  const exportToExcel = () => {
    const fileName = getAlphaDigitName(exportFileName) + '.xlsx';
    const tableHeader = [
      getTranslatedMessage('oem-table-field-oem'),
      getTranslatedMessage('oem-table-field-approval-type'),
      getTranslatedMessage('oem-table-field-issue-date'),
      getTranslatedMessage('oem-table-field-castrol-confidential'),
      getTranslatedMessage('oem-table-field-approval-restrictions'),
      getTranslatedMessage('oem-table-field-comments'),
    ];
    const tableData = oemApprovals.map((item) => {
      return [
        item.oem.name,
        getOemApprovalTypeName(item.oemApprovalTypeId),
        getDateString(item.approvalDocument.issuedAt),
        item.approvalDocument.bpConfidential
          ? getTranslatedMessage('yes-label')
          : getTranslatedMessage('no-label'),
        item.restrictions ||
          getTranslatedMessage('oem-approval-restrictions-none'),
        item.notes,
      ];
    });
    const sheet = XLSX.utils.aoa_to_sheet([tableHeader, ...tableData]);
    const wb: XLSX.WorkBook = {
      SheetNames: ['Sheet1'],
      Sheets: {
        Sheet1: sheet,
      },
    };
    XLSX.writeFile(wb, fileName, {
      bookType: 'xlsx',
    });
  };

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.tableLabelRow}>
        <div className={styles.tableLabel}>
          <FormattedMessage id={'oem-table-field-oem-approvals'} />
        </div>
        <div
          className={cn(styles.exportToExcel, styles.desktop)}
          onClick={() => exportToExcel()}
        >
          <XlsxFileIcon />
          <FormattedMessage id={'oem-table-field-export-list-to-excel'} />
        </div>
      </div>
      <div className={styles.tableHeader}>
        <div className={styles.tableRow}>
          <div
            style={{ flex: '1 1 180px' }}
            onClick={() => onSortChange('oem.name')}
            className={cn(
              styles.tableLabelColumn,
              styles.tableColumn,
              styles.sortableColumn,
            )}
          >
            <FormattedMessage id={'oem-table-field-oem'} />
            {sortBy === 'oem.name' && (
              <SortArrow
                className={cn(
                  styles.sortIcon,
                  sortDirection === 'asc' && styles.asc,
                )}
              />
            )}
          </div>

          <div
            style={{ flex: '1 1 120px' }}
            className={cn(
              styles.tableLabelColumn,
              styles.tableColumn,
              styles.columnWithIcon,
            )}
          >
            <FormattedMessage id={'oem-table-field-approval-type'} />
            <InfoIcon data-tip data-for={'oem-table-field-approval-type'} />
            <ReactTooltip
              id={'oem-table-field-approval-type'}
              place={'top'}
              effect={'solid'}
            >
              <FormattedMessage
                id={'oem-table-field-approval-type-tooltip'}
                values={descriptionValues}
              />
            </ReactTooltip>
          </div>

          <div
            style={{ flex: '1 1 90px' }}
            className={cn(styles.tableLabelColumn, styles.tableColumn)}
          >
            <FormattedMessage id={'oem-table-field-issue-date'} />
          </div>

          <div
            style={{ flex: '1 1 60px' }}
            className={cn(styles.tableLabelColumn, styles.tableColumn)}
          >
            <FormattedMessage id={'oem-table-field-expired'} />
          </div>

          <div
            style={{ flex: '1 1 90px' }}
            className={cn(styles.tableLabelColumn, styles.tableColumn)}
          >
            <FormattedMessage id={'oem-table-field-castrol-confidential'} />
          </div>

          <div
            style={{ flex: '1 1 90px' }}
            className={cn(styles.tableLabelColumn, styles.tableColumn)}
          >
            <FormattedMessage id={'oem-table-field-approval-restrictions'} />
          </div>

          <div
            style={{ flex: '1 1 90px' }}
            className={cn(styles.tableLabelColumn, styles.tableColumn)}
          >
            <FormattedMessage id={'oem-table-field-comments'} />
          </div>
          {oemEdit && (
            <div
              style={{ flex: '1 1 60px', justifyContent: 'center' }}
              className={cn(styles.tableLabelColumn, styles.tableColumn)}
            >
              <FormattedMessage id={'oem-table-field-actions'} />
            </div>
          )}
        </div>
      </div>

      <div className={cn(styles.tableBody, styles.desktop)}>
        {sorted.map((oemApproval) => (
          <div
            className={cn(
              styles.tableRow,
              styles.desktop,
              oemApproval.active ? '' : styles.inactive,
            )}
            key={oemApproval.id}
          >
            <div
              style={{ flex: '1 1 180px' }}
              className={cn(styles.tableColumn, styles.oemName)}
            >
              <Link
                className={styles.link}
                to={`/admin/approval-document/${oemApproval.approvalDocumentId}?oemId=${oemApproval.oemId}`}
              >
                {oemApproval.oem.name}
              </Link>
              {!oemApproval.active && (
                <div className={styles.inactiveLabel}>
                  <FormattedMessage id={'oem-table-field-inactive-approval'} />
                </div>
              )}
              {!oemApproval.oem.active && (
                <div className={styles.inactiveLabel}>
                  <FormattedMessage id={'oem-table-field-inactive-oem'} />
                </div>
              )}
            </div>
            <div style={{ flex: '1 1 120px' }} className={styles.tableColumn}>
              {getOemApprovalTypeName(oemApproval.oemApprovalTypeId)}
            </div>
            <div style={{ flex: '1 1 90px' }} className={styles.tableColumn}>
              {getDateString(oemApproval.approvalDocument.issuedAt)}
            </div>
            <div style={{ flex: '1 1 60px' }} className={styles.tableColumn}>
              {oemApproval.approvalDocument.validUntil &&
                new Date(oemApproval.approvalDocument.validUntil) < current && (
                  <div className={styles.inactiveLabel}>
                    <FormattedMessage id={'oem-table-field-expired'} />
                  </div>
                )}
            </div>
            <div style={{ flex: '1 1 90px' }} className={styles.tableColumn}>
              {oemApproval.approvalDocument.bpConfidential ? (
                <FormattedMessage id={'yes-label'} />
              ) : (
                <FormattedMessage id={'no-label'} />
              )}
            </div>
            <div
              style={{ flex: '1 1 90px' }}
              className={cn(styles.tableColumn, styles.commentLabel)}
            >
              {oemApproval.restrictions ? (
                <span
                  onClick={() => showApprovalRestrictionsDetail(oemApproval)}
                >
                  <FormattedMessage id={'oem-table-field-view-details'} />
                </span>
              ) : (
                <FormattedMessage id={'oem-approval-restrictions-none'} />
              )}
            </div>
            <div
              style={{ flex: '1 1 90px' }}
              className={cn(styles.tableColumn, styles.commentLabel)}
            >
              {oemApproval.notes && (
                <span onClick={() => showCommentDetail(oemApproval)}>
                  <FormattedMessage id={'oem-table-field-view-details'} />
                </span>
              )}
            </div>
            {oemEdit && (
              <div
                style={{ flex: '1 1 60px' }}
                className={cn(styles.tableColumn, styles.settingColumn)}
              >
                <OemApprovalActionMenu
                  forApprovalActivated={!oemApproval.active}
                  forOemActivated={!oemApproval.oem.active}
                  onOptionClick={(val) => menuClick(val, oemApproval)}
                />
              </div>
            )}
          </div>
        ))}
      </div>

      <div className={cn(styles.tableBody, styles.mobile)}>
        {sorted.map((oemApproval, index) => (
          <div
            className={cn(
              styles.tableRow,
              styles.mobile,
              !oemApproval.active && styles.inactive,
              expanded && expanded.find((x) => x.id === index)?.expanded
                ? styles.expanded
                : styles.collapsed,
            )}
            key={index}
          >
            <div
              className={cn(styles.mobileTableRow, styles.firstRow)}
              onClick={() => {
                handleCollapseClick(index, cloneDeep(expanded));
              }}
            >
              <div className={cn(styles.tableColumn, styles.leftColumn)}>
                <Link
                  className={styles.link}
                  to={`/admin/approval-document/${oemApproval.id}?oemId=${oemApproval.oemId}`}
                >
                  {oemApproval.oem.name}
                </Link>
              </div>
              <div className={cn(styles.tableColumn, styles.rightColumn)}>
                {!oemApproval.active && (
                  <div className={styles.inactiveLabel}>
                    <FormattedMessage
                      id={'oem-table-field-inactive-approval'}
                    />
                  </div>
                )}
                {!oemApproval.oem.active && (
                  <div className={styles.inactiveLabel}>
                    <FormattedMessage id={'oem-table-field-inactive-oem'} />
                  </div>
                )}
              </div>
              <Caret className={styles.dropdownArrow} />
              <div className={styles.divider} />
            </div>
            <div className={styles.mobileTableRow}>
              <div
                className={cn(
                  styles.tableColumn,
                  styles.leftColumn,
                  styles.columnWithIcon,
                )}
              >
                <FormattedMessage id={'oem-table-field-approval-type'} />
                <InfoIcon
                  data-tip
                  data-for={'oem-table-field-approval-type-sm'}
                />
                <ReactTooltip
                  class={styles.tooltip}
                  place={'top'}
                  effect={'solid'}
                  id={'oem-table-field-approval-type-sm'}
                  // Position bugfix (see https://github.com/wwayne/react-tooltip/issues/476#issuecomment-607880172)
                  overridePosition={({ left, top }, _, __, node) => ({
                    top,
                    left: typeof node === 'string' ? left : Math.max(left, 0),
                  })}
                >
                  <FormattedMessage
                    id={'oem-table-field-approval-type-tooltip'}
                    values={descriptionValues}
                  />
                </ReactTooltip>
              </div>
              <div className={cn(styles.tableColumn, styles.rightColumn)}>
                {getOemApprovalTypeName(oemApproval.oemApprovalTypeId)}
              </div>
            </div>
            <div className={styles.mobileTableRow}>
              <div className={cn(styles.tableColumn, styles.leftColumn)}>
                <FormattedMessage id={'oem-table-field-issue-date'} />
              </div>
              <div className={cn(styles.tableColumn, styles.rightColumn)}>
                {getDateString(oemApproval.approvalDocument.issuedAt)}
              </div>
            </div>
            <div className={styles.mobileTableRow}>
              <div className={cn(styles.tableColumn, styles.leftColumn)}>
                <FormattedMessage id={'oem-table-field-expired'} />
              </div>
              <div className={cn(styles.tableColumn, styles.rightColumn)}>
                {oemApproval.approvalDocument.validUntil &&
                  new Date(oemApproval.approvalDocument.validUntil) <
                    current && (
                    <div className={styles.inactiveLabel}>
                      <FormattedMessage id={'oem-table-field-expired'} />
                    </div>
                  )}
              </div>
            </div>
            <div className={styles.mobileTableRow}>
              <div className={cn(styles.tableColumn, styles.leftColumn)}>
                <FormattedMessage id={'oem-table-field-castrol-confidential'} />
              </div>
              <div className={cn(styles.tableColumn, styles.rightColumn)}>
                {oemApproval.approvalDocument.bpConfidential ? (
                  <FormattedMessage id={'yes-label'} />
                ) : (
                  <FormattedMessage id={'no-label'} />
                )}
              </div>
            </div>
            <div className={styles.mobileTableRow}>
              <div className={cn(styles.tableColumn, styles.leftColumn)}>
                <FormattedMessage
                  id={'oem-table-field-approval-restrictions'}
                />
              </div>
              <div
                className={cn(
                  styles.tableColumn,
                  styles.rightColumn,
                  styles.commentLabel,
                )}
              >
                {oemApproval.restrictions ? (
                  <span
                    onClick={() => showApprovalRestrictionsDetail(oemApproval)}
                  >
                    <FormattedMessage id={'oem-table-field-view-details'} />
                  </span>
                ) : (
                  <FormattedMessage id={'oem-approval-restrictions-none'} />
                )}
              </div>
            </div>
            <div className={styles.mobileTableRow}>
              <div className={cn(styles.tableColumn, styles.leftColumn)}>
                <FormattedMessage id={'oem-table-field-comments'} />
              </div>
              <div
                className={cn(
                  styles.tableColumn,
                  styles.rightColumn,
                  styles.commentLabel,
                )}
              >
                {oemApproval.notes && (
                  <span onClick={() => showCommentDetail(oemApproval)}>
                    <FormattedMessage id={'oem-table-field-view-details'} />
                  </span>
                )}
              </div>
            </div>
            <Button
              onClick={() => menuClick('menu-edit-oem', oemApproval)}
              color={'green'}
              className={styles.mobileActionButton}
            >
              <EditWhiteIcon />
              <FormattedMessage id={'menu-edit-oem'} />
            </Button>
            {!oemApproval.active ? (
              <Button
                onClick={() => menuClick('menu-activate-approval', oemApproval)}
                color={'green'}
                className={styles.mobileActionButton}
              >
                <DocWhiteIcon />
                <FormattedMessage id={'menu-activate-approval'} />
              </Button>
            ) : (
              <Button
                onClick={() =>
                  menuClick('menu-deactivate-approval', oemApproval)
                }
                color={'green'}
                className={styles.mobileActionButton}
              >
                <XOctagonWhiteIcon />
                <FormattedMessage id={'menu-deactivate-approval'} />
              </Button>
            )}
            {!oemApproval.oem.active ? (
              <Button
                onClick={() => menuClick('menu-activate-oem', oemApproval)}
                color={'green'}
                className={cn(styles.mobileActionButton, styles.lastButton)}
              >
                <DocWhiteIcon />
                <FormattedMessage id={'menu-activate-oem'} />
              </Button>
            ) : (
              <Button
                onClick={() => menuClick('menu-deactivate-oem', oemApproval)}
                color={'green'}
                className={cn(styles.mobileActionButton, styles.lastButton)}
              >
                <XOctagonWhiteIcon />
                <FormattedMessage id={'menu-deactivate-oem'} />
              </Button>
            )}
          </div>
        ))}
      </div>

      {showCommentModal && (
        <CommentModal
          titleId={commentData?.titleId}
          name={commentData?.name}
          comment={commentData?.comment}
          onClose={() => setShowCommentModal(false)}
        />
      )}
      {showDeactivateOemModal && (
        <DeactivateApprovalModal
          oemProductApproval={selectedOemProductApproval}
          onSave={handleSave}
          type={selectedOemProductApproval?.active ? 'deactivate' : 'activate'}
          onClose={() => setShowDeactivateOemModal(false)}
        />
      )}
    </div>
  );
}

export default OemApprovalTable;
