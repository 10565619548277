import styles from './BrowseItem.module.scss';
import React, { PropsWithoutRef, ReactElement, useRef } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as ArrowRight } from '../../../../../icons/arrow-right.svg';
import { ReactComponent as OEM } from '../../../../../icons/oem-enabled.svg';
import DOMPurify from 'dompurify';
import ReactTooltip from 'react-tooltip';

interface BrowseItemProps<T> {
  popup?: ReactElement;
  data: T;
  id?: string;
  to: string;
  name: string;
  description: string;
  inactive?: boolean;
  className?: string;
  showArrow?: boolean;
  onHoverToName?: (element: HTMLDivElement, data: T) => void;
  onLeaveFromName?: () => void;
  highlightText?: string;
  showOemIcon?: boolean;
  onLinkClick?: () => void;
  type?: string;
}

/**
 * transform normal text to highlighted text
 * @param search search string
 * @param text text
 */
function transformToHighlightedText(search: string, text: string): string {
  const index = text.toLowerCase().indexOf(search.toLowerCase());

  if (index !== -1) {
    const before = text.substring(0, index);
    const match = text.substring(index, index + search.length);
    const after = text.substring(index + search.length);

    return `${before}<em>${match}</em>${after}`;
  } else {
    return text;
  }
}

/**
 * browse item
 * @param popup children popup
 * @param data data
 * @param to link to
 * @param name name
 * @param description description
 * @param inactive inactive
 * @param className classname
 * @param showArrow show arrow flag
 * @param onHoverToName name hover event
 * @param onLeaveFromName name leave event
 * @param highlightText highlight text
 * @param showOemIcon show oem icon
 * @param onLinkClick link click handler
 */
function BrowseItem<T>({
  popup,
  data,
  id,
  to,
  name,
  description,
  inactive,
  className,
  showArrow = false,
  onHoverToName,
  onLeaveFromName,
  highlightText = '',
  onLinkClick,
  showOemIcon,
  type,
}: PropsWithoutRef<BrowseItemProps<T>>) {
  const mouseEnterContainerRef = useRef(null);

  if (highlightText) {
    name = transformToHighlightedText(highlightText, name);
  }

  /**
   * window wheel event listener
   */
  const windowWheelEventListener = () => {
    if (onLeaveFromName) {
      onLeaveFromName();
    }

    window.removeEventListener('wheel', windowWheelEventListener);
  };

  /**
   * mouse enter handler
   */
  const onMouseEnter = () => {
    if (onHoverToName && mouseEnterContainerRef.current) {
      onHoverToName(mouseEnterContainerRef.current, data);
      window.addEventListener('wheel', windowWheelEventListener);
    }
  };

  return (
    <div className={cn(styles.container, className)}>
      <div
        ref={mouseEnterContainerRef}
        onMouseLeave={() => onLeaveFromName && onLeaveFromName()}
        onMouseEnter={() => onMouseEnter()}
        className={styles.nameContainer}
      >
        <div className={styles.linkRow}>
          <Link
            onClick={() => onLinkClick && onLinkClick()}
            to={to}
            className={cn(styles.name, inactive && styles.inactive)}
          >
            {/* This dangerouslySetInnerHTML is not a safe one as backend data is using here.
                To make the data clean we are using another library DOMPurify https://github.com/cure53/DOMPurify
                to sanitize the content before use.  */}
            <span
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(name) }}
            />

            {showArrow && <ArrowRight className={styles.rightArrow} />}
          </Link>
          {showOemIcon && (
            <>
              <OEM className={styles.oem} data-tip data-for={id} />
              <ReactTooltip id={id} place={'top'} effect={'solid'}>
                <FormattedMessage id={'oem-approved-label-upper'} />
              </ReactTooltip>
            </>
          )}

          {inactive && (
            <div className={styles.inactiveLabel}>
              {type === 'product' ? (
                <FormattedMessage id={'inactive-product-label'} />
              ) : (
                <FormattedMessage id={'inactive-label2'} />
              )}
            </div>
          )}
        </div>

        {popup}
      </div>

      <div className={styles.description}>{description}</div>
    </div>
  );
}

export default BrowseItem;
