import { PropsWithChildren, useState } from 'react';
import { useIntl } from 'react-intl';
import cn from 'classnames';
import { ReactComponent as SettingIcon } from '../../../../../icons/setting-grey.svg';
import { ReactComponent as SettingGreenIcon } from '../../../../../icons/settings-green.svg';
import { ReactComponent as EditIcon } from '../../../../../icons/edit-green.svg';
import { ReactComponent as EditWhiteIcon } from '../../../../../icons/edit-white.svg';
import { ReactComponent as XOctagonIcon } from '../../../../../icons/x-octagon-green.svg';
import { ReactComponent as XOctagonWhiteIcon } from '../../../../../icons/x-octagon-white.svg';
import { ReactComponent as DocIcon } from '../../../../../icons/ic-doc-green.svg';
import { ReactComponent as DocWhiteIcon } from '../../../../../icons/ic-doc-white.svg';
import { ReactComponent as DeleteIcon } from '../../../../../icons/dustbin-red.svg';
import { ReactComponent as DeleteWhiteIcon } from '../../../../../icons/dustbin.svg';
import styles from './BannerActionMenu.module.scss';
import MenuOption from '../../../../../components/Admin/common/MenuOption/MenuOption';

interface AnnouncementActionMenuProps {
  className?: string;
  active?: boolean;
  onOptionClick: (value: string) => void;
}

/**
 * Announcement action  menu component
 */
function BannerActionMenu({
  className,
  active,
  onOptionClick,
}: PropsWithChildren<AnnouncementActionMenuProps>) {
  const intl = useIntl();

  const [opened, setOpened] = useState(false);

  return (
    <div className={cn(styles.container, className)}>
      {!opened && (
        <SettingIcon
          className={styles.settingIcon}
          onClick={() => setOpened(true)}
        />
      )}
      {opened && (
        <SettingGreenIcon
          className={styles.settingIcon}
          style={{ width: '20px', height: '20px' }}
        ></SettingGreenIcon>
      )}

      {opened && (
        <div
          onClick={() => setOpened(false)}
          className={styles.clickDetector}
        />
      )}

      {opened && (
        <div
          onClick={() => setOpened(false)}
          className={styles.optionContainer}
        >
          <MenuOption
            label={intl.formatMessage({ id: 'edit-banner' })}
            icon={<EditIcon />}
            iconActive={<EditWhiteIcon />}
            onOptionClick={() => onOptionClick('edit-banner')}
          />
          {!active && (
            <MenuOption
              label={intl.formatMessage({ id: 'activate-banner' })}
              icon={<DocIcon />}
              iconActive={<DocWhiteIcon />}
              onOptionClick={() => onOptionClick('activate-banner')}
            />
          )}
          {active && (
            <MenuOption
              label={intl.formatMessage({ id: 'deactivate-banner' })}
              icon={<XOctagonIcon />}
              iconActive={<XOctagonWhiteIcon />}
              onOptionClick={() => onOptionClick('deactivate-banner')}
            />
          )}
          <MenuOption
            label={intl.formatMessage({ id: 'delete-banner' })}
            icon={<DeleteIcon />}
            iconActive={<DeleteWhiteIcon />}
            onOptionClick={() => onOptionClick('delete-banner')}
          />
        </div>
      )}
    </div>
  );
}

export default BannerActionMenu;
