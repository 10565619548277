import cn from 'classnames';
import { memo, PropsWithoutRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { ProductValidationColumn } from '../../../models/table-column';
import styles from './ProductValidationTable.module.scss';

interface ProductValidationHiddenFieldProps {
  column: ProductValidationColumn;
  row: any;
  setDataAtIndex: (x: any) => void;
  rowIndex: number;
  className?: string;
}

function ProductValidationHiddenField({
  column,
  row,
  setDataAtIndex,
  rowIndex,
  className,
}: PropsWithoutRef<ProductValidationHiddenFieldProps>) {
  const [showInput, setShowInput] = useState(false);
  const [showAll, setShowAll] = useState(false);

  if (!column.hidden) return null;

  const getColumnValue = () => {
    if (column.renderer) {
      return column.renderer(column, row, setDataAtIndex, rowIndex);
    }

    if (showInput) {
      return (
        <textarea
          className={styles.textarea}
          defaultValue={
            ['$NULL_STRING$', ''].includes(String(column.key(row)))
              ? ''
              : column.key(row)
          }
          onBlur={(e) => {
            if (e.target.value) {
              setShowInput(false);
              setDataAtIndex({
                ...row,
                [column.objectKey]: e.target.value,
              });
            }
          }}
        />
      );
    } else if (['$NULL_STRING$', ''].includes(String(column.key(row)))) {
      if (column.showNA) return 'N/A';
      else {
        return (
          <span className={styles.errorText} onClick={() => setShowInput(true)}>
            <FormattedMessage id={'cant-empty-label'} />
          </span>
        );
      }
    }

    const arr = String(column.key(row)).split('\n');
    return (
      <div onClick={() => setShowInput(true)}>
        {arr
          .slice(0, showAll ? arr.length : 10)
          .map((str: string, index: number) => (
            <div key={index} className={styles.paraBody}>
              {str}
            </div>
          ))}
        {arr.length > 10 &&
          (showAll ? (
            <div
              className={styles.moreLessLink}
              onClick={(e) => {
                e.stopPropagation();
                setShowAll(false);
              }}
            >
              - Less
            </div>
          ) : (
            <div
              className={styles.moreLessLink}
              onClick={(e) => {
                e.stopPropagation();
                setShowAll(true);
              }}
            >
              + More
            </div>
          ))}
      </div>
    );
  };

  return (
    <div
      key={column.formattedMessageId}
      className={cn(styles.descriptionCard, className)}
    >
      <div
        className={cn(styles.title, {
          [styles.requiredColumn]: column.requiredAsterisk,
        })}
      >
        <FormattedMessage id={column.formattedMessageId} />
      </div>
      {getColumnValue()}
    </div>
  );
}

export default memo(ProductValidationHiddenField);
