import styles from './RichTextarea.module.scss';
import { PropsWithoutRef } from 'react';
import cn from 'classnames';

import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';

interface RichTextareaProps {
  value?: string;
  onChange: (value: string) => void;
  placeholder?: string;
  className?: string;
}

/**
 * input component
 * @param value value
 * @param onChange change handler
 * @param className classname
 */
function RichTextarea({
  value,
  onChange,
  placeholder = '',
  className,
}: PropsWithoutRef<RichTextareaProps>) {
  const editorModules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ list: 'bullet' }, { list: 'ordered' }],
    ],
  };

  return (
    <div className={cn(styles.container, className)}>
      <ReactQuill
        className={styles.textarea}
        modules={editorModules}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  );
}

export default RichTextarea;
