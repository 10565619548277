import { Fragment, ReactNode } from 'react';
import { TableColumn } from '../../../models/table-column';
import styles from './TableMobileView.module.scss';
import ExpandableRow from './Components/ExpandableRow/ExpandableRow';
import _ from 'lodash';
import classNames from 'classnames';

interface TableMobileViewProps<T> {
  className?: string;
  columns: TableColumn<T>[];
  actions: (data: T) => ReactNode;
  rows: T[];
}

export default function TableMobileView({
  className,
  columns,
  actions,
  rows,
}: TableMobileViewProps<any>) {
  const getPropertyValue = (data: any, property: string) => {
    const keys = property.split('.');
    let index = 0;
    let tempData = data;
    while (index < keys.length) {
      if (typeof _.get(tempData, keys[index]) === 'object') {
        tempData = _.get(tempData, keys[index]);
        index++;
      } else {
        return _.get(tempData, keys[index]);
      }
    }
  };

  const getRowColumnValue = (row: any, column: TableColumn<any>) => {
    if (column.render) {
      return column.render(row);
    } else {
      return getPropertyValue(row, column.property);
    }
  };

  return (
    <>
      {rows.map((row: any, index: number) => (
        <ExpandableRow
          key={index}
          className={className}
          title={getRowColumnValue(row, columns[0])}
        >
          {columns.map((column, index) => {
            return index === 0 ? (
              <Fragment key={index}></Fragment>
            ) : (
              <div key={index} className={styles.column}>
                <div className={styles.columnLabel}>{column.label}</div>
                <div
                  className={classNames(styles.columnValue, column.className)}
                >
                  {getRowColumnValue(row, column)}
                </div>
              </div>
            );
          })}
          <div className={classNames(styles.column, styles.actionColumn)}>
            {actions(row)}
          </div>
        </ExpandableRow>
      ))}
    </>
  );
}
