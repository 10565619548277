import { FormattedMessage } from 'react-intl';
import Modal from '../../../common/Modal/Modal';
import styles from './DescriptionPreviewModal.module.scss';

interface DescriptionPreviewModalProps {
  modalDescription: string;
  onHideModal: () => void;
}

const DescriptionPreviewModal = ({
  modalDescription,
  onHideModal,
}: DescriptionPreviewModalProps) => {
  return (
    <Modal
      className={styles.descriptionModal}
      center={true}
      onClose={onHideModal}
    >
      <h3 className={styles.descModalHeader}>
        <FormattedMessage id={'description-label'} />
      </h3>
      <div
        className={styles.descriptionBody}
        dangerouslySetInnerHTML={{ __html: modalDescription }}
      ></div>
    </Modal>
  );
};

export default DescriptionPreviewModal;
