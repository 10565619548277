import { SearchResultProduct } from '../models/search-result-product';
import { SearchResultFamily } from '../models/search-result-family';
import { ProductSearchResult } from '../models/product-search-result';
import { uniq, includes } from 'lodash';
import { SearchResultPort } from '../models/search-result-port';
import { SearchResultRange } from '../models/search-result-range';
import {
  CountryItem,
  LanguageItem,
  Lookups,
  SectorItem,
} from '../models/lookups';

/**
 * group search result
 * @param searchResult search result
 */
export function groupSearchResult(searchResult: ProductSearchResult) {
  const productsById: { [k: number]: SearchResultProduct } = {};
  const productsByCountry: { [k: number]: SearchResultProduct[] } = {};
  const productsByPort: { [k: string]: SearchResultProduct[] } = {};
  const productsByFamily: { [k: number]: SearchResultProduct[] } = {};
  const productsByRange: { [k: number]: SearchResultProduct[] } = {};
  const portsByCountry: { [k: number]: SearchResultPort[] } = {};
  const rangesByFamily: { [k: number]: SearchResultRange[] } = {};

  // group products
  searchResult.products.forEach((product) => {
    productsById[product.id] = product;

    if (productsByFamily[product.familyId]) {
      productsByFamily[product.familyId].push(product);
    } else {
      productsByFamily[product.familyId] = [product];
    }

    if (product.rangeId) {
      if (productsByRange[product.rangeId]) {
        productsByRange[product.rangeId].push(product);
      } else {
        productsByRange[product.rangeId] = [product];
      }
    }
  });

  (searchResult.ports || []).forEach((port) => {
    productsByPort[port.friendlyUrl] = port.productIds
      .map((pid) => productsById[pid])
      .filter((prod) => !!prod);

    if (productsByCountry[port.countryId]) {
      productsByCountry[port.countryId].push(
        ...productsByPort[port.friendlyUrl],
      );
    } else {
      productsByCountry[port.countryId] = [...productsByPort[port.friendlyUrl]];
    }
  });

  // group ports
  (searchResult.ports || []).forEach((port) => {
    if (!portsByCountry[port.countryId]) {
      portsByCountry[port.countryId] = [];
    }

    portsByCountry[port.countryId].push(port);
  });

  // group ranges
  searchResult.ranges.forEach((range) => {
    if (!rangesByFamily[range.familyId]) {
      rangesByFamily[range.familyId] = [];
    }

    rangesByFamily[range.familyId].push(range);
  });

  return {
    ...searchResult,

    // map and filter countries
    countries: Object.entries(productsByCountry)
      .map(([countryId, products]) => {
        return {
          id: Number(countryId),
          name: '',
          description: '',
          portChildren: portsByCountry[Number(countryId)] || [],
          productChildren: products ? uniq(products) : [],
        };
      })
      .filter(
        (item) =>
          item.portChildren.length > 0 || item.productChildren.length > 0,
      ),

    // map and filter ports
    ports: (searchResult.ports || [])
      .map((item) => {
        return {
          ...item,
          productChildren: productsByPort[item.friendlyUrl] || [],
        };
      })
      .filter((item) => item.productChildren.length > 0),

    // map and filter families
    families: searchResult.families
      .map((item) => {
        return {
          ...item,
          rangeChildren: rangesByFamily[item.id] || [],
          productChildren: productsByFamily[item.id] || [],
        };
      })
      .filter(
        (item) =>
          item.rangeChildren.length > 0 || item.productChildren.length > 0,
      ),

    // map and filter ranges
    ranges: searchResult.ranges
      .map((item) => {
        return {
          ...item,
          productChildren: productsByRange[item.id] || [],
        };
      })
      .filter((item) => item.productChildren.length > 0),

    // map and filter oems
    oems: (searchResult.oems || [])
      .map((item) => {
        return {
          ...item,
          description: item.details || '',
          productChildren: searchResult.products.filter((product) =>
            includes(item.productIds, product.id),
          ),
        };
      })
      .filter((item) => item.productChildren.length > 0),
  };
}

/**
 * group product family
 * @param family family
 */
export function groupProductFamily(
  family: SearchResultFamily,
): SearchResultFamily {
  const productsByRange: { [k: string]: SearchResultProduct[] } = {};

  (family.productChildren || []).forEach((product) => {
    if (product.rangeId && !productsByRange[product.rangeId]) {
      productsByRange[product.rangeId] = [];
    }

    if (product.rangeId) {
      productsByRange[product.rangeId].push(product);
    }
  });

  const usedRangeIds: number[] = [];

  // map ranges with products
  const rangeChildren = (family.rangeChildren || []).map((range) => {
    usedRangeIds.push(range.id);

    return {
      ...range,
      productChildren: productsByRange[range.id] || [],
    };
  });

  // remove mapped products
  usedRangeIds.forEach((id) => {
    delete productsByRange[id];
  });

  const otherProductChildren: SearchResultProduct[] = [];

  // create other product children
  Object.keys(productsByRange).forEach((id) => {
    otherProductChildren.push(...productsByRange[id]);
  });

  return {
    ...family,
    rangeChildren,
    productChildren: otherProductChildren,
  };
}

export const getMappingLanguage = (language: string | undefined): string => {
  switch (language) {
    case 'English':
      return 'English (GB)';
    case 'German':
      return 'German (DE)';
    case 'Japanese':
      return 'Japanese (JP)';
    default:
      return 'All';
  }
};

export const getMappingSector = (sectorId: number | undefined): string => {
  switch (sectorId) {
    case 0:
      return 'All';
    case 1:
      return 'Marine';
    case 2:
    case 3:
      return 'Energy';
    case 4:
      return 'Industrial';
    default:
      return '';
  }
};

export const getLinkUrl = (
  type: string,
  country: CountryItem | null,
  productName: string,
  industrySector: SectorItem | null,
  language: LanguageItem | null,
): string => {
  const countryStr = country
    ? `${country.name} (${country.code})`
    : 'International';
  const languageStr = getMappingLanguage(language?.englishName);
  const linkUrl = `https://msdspds.castrol.com/msdspds/msdspds.nsf/CastrolSearch?OpenForm&c=${countryStr}&l=${languageStr}&p=${productName}&b=${getMappingSector(
    industrySector?.id,
  )}&r=&t=${type}&sitelang=en&autosearch=No&autoload=No&unrestrictedmb=No`;
  return linkUrl;
};

/**
 * resolve products lookup value.
 * @param products family
 * @param lookups lookups
 */
export function resolveProductLookups(
  products: SearchResultProduct[],
  lookups: Lookups,
): SearchResultProduct[] {
  const masterBrandMap = new Map(
    lookups.masterBrands.map((i) => [i.id, i.name]),
  );
  const sectorMap = new Map(lookups.sectors.map((i) => [i.id, i.name]));
  const subSectorMap = new Map(lookups.subSectors.map((i) => [i.id, i.name]));
  const applicationMap = new Map(
    lookups.applications.map((i) => [i.id, i.name]),
  );
  const subApplicationMap = new Map(
    lookups.subApplications.map((i) => [i.id, i.name]),
  );
  const productTypeMap = new Map(
    lookups.productTypes.map((i) => [i.id, i.name]),
  );
  const categoryMap = new Map(lookups.categories.map((i) => [i.id, i.name]));
  const subCategoryMap = new Map(
    lookups.subCategories.map((i) => [i.id, i.name]),
  );
  const booleanMap = new Map<boolean, string>([
    [true, lookups.yesNoNonesById[2].name],
    [false, lookups.yesNoNonesById[1].name],
  ]);

  /**Characteristics items */

  const mineralOilMap = new Map(lookups.mineralOils.map((i) => [i.id, i.name]));
  const concentrationRangeMap = new Map(
    lookups.concentrationRanges.map((i) => [i.id, i.name]),
  );
  const corrosionProtectionMap = new Map(
    lookups.corrosionProtections.map((i) => [i.id, i.name]),
  );
  const characteristicMap = new Map(
    lookups.characteristics.map((i) => [i.id, i.name]),
  );
  const customerOfferMap = new Map(
    lookups.customerOffers.map((i) => [i.id, i.name]),
  );
  const thickenerGenericMap = new Map(
    lookups.thickenerGenerics.map((i) => [i.id, i.name]),
  );
  const thickenerSpecificMap = new Map(
    lookups.thickenerSpecifics.map((i) => [i.id, i.name]),
  );
  const baseOilTypeGenericMap = new Map(
    lookups.baseOilTypeGenerics.map((i) => [i.id, i.name]),
  );
  const baseOilTypeSpecificMap = new Map(
    lookups.baseOilTypeSpecifics.map((i) => [i.id, i.name]),
  );
  const viscosityIndexMap = new Map(
    lookups.viscosityIndices.map((i) => [i.id, i.name]),
  );
  const yesNoMap = new Map(lookups.yesNoNones.map((i) => [i.id, i.name]));
  const metalTypeMap = new Map(lookups.metalTypes.map((i) => [i.id, i.name]));
  const nlgisMap = new Map(lookups.nlgis.map((i) => [i.id, i.name]));

  products.forEach((item) => {
    item.castrolRecommendedName = booleanMap.get(item.castrolRecommended) || '';
    item.masterBrandName = masterBrandMap.get(item.masterBrandId) || '';

    item.productSectors.forEach((sector) => {
      sector.name = sectorMap.get(sector.sectorId) || `${sector.sectorId}`;
    });
    item.productSubSectors.forEach((subSector) => {
      subSector.name =
        subSectorMap.get(subSector.subSectorId) || `${subSector.subSectorId}`;
    });
    item.productApplications.forEach((app) => {
      app.name =
        applicationMap.get(app.applicationId) || `${app.applicationId}`;
    });
    item.productSubApplications.forEach((app) => {
      app.name =
        subApplicationMap.get(app.subApplicationId) ||
        `${app.subApplicationId}`;
    });
    item.productProductTypes.forEach((item) => {
      item.name =
        productTypeMap.get(item.productTypeId) || `${item.productTypeId}`;
    });
    item.productCategories.forEach((cat) => {
      cat.name = categoryMap.get(cat.categoryId) || `${cat.categoryId}`;
    });
    if (item.subCategoryId) {
      item.productSubCategory = subCategoryMap.get(item.subCategoryId);
    }

    // characteristics
    item.productCharacteristic.mineralOilName = mineralOilMap.get(
      item.productCharacteristic.mineralOilId,
    );
    item.productCharacteristic.corrosionProtectionName =
      corrosionProtectionMap.get(
        item.productCharacteristic.corrosionProtectionId,
      );
    item.productCharacteristic.concentrationRangeName =
      concentrationRangeMap.get(
        item.productCharacteristic.concentrationRangeId,
      );
    item.productCharacteristic.characteristicName = characteristicMap.get(
      item.productCharacteristic.characteristicId,
    );
    item.productCharacteristic.customerOfferName = customerOfferMap.get(
      item.productCharacteristic.customerOfferId,
    );
    item.productCharacteristic.esterEpAdditivesName =
      booleanMap.get(item.productCharacteristic.esterEpAdditives) || '';
    item.productCharacteristic.foodGradeName =
      yesNoMap.get(item.productCharacteristic.foodGradeId) || '';
    item.productCharacteristic.highFlashPointName =
      yesNoMap.get(item.productCharacteristic.highFlashPointId) || '';
    item.productCharacteristic.superiorBiodegradationName =
      yesNoMap.get(item.productCharacteristic.superiorBiodegradationId) || '';

    item.productCharacteristic.thickenerSpecificName =
      item.productCharacteristic.productCharacteristicThickeners
        .filter((base) => thickenerSpecificMap.has(base.thickenerId))
        .map((base) => thickenerSpecificMap.get(base.thickenerId))
        .join(', ');
    item.productCharacteristic.thickenerGenericName =
      item.productCharacteristic.productCharacteristicThickeners
        .filter((base) => thickenerGenericMap.has(base.thickenerId))
        .map((base) => thickenerGenericMap.get(base.thickenerId))
        .join(', ');

    item.productCharacteristic.baseOilTypeGenericName =
      item.productCharacteristic.productCharacteristicBaseOilTypes
        .filter((base) => baseOilTypeGenericMap.has(base.baseOilTypeId))
        .map((base) => baseOilTypeGenericMap.get(base.baseOilTypeId))
        .join(', ');

    item.productCharacteristic.baseOilTypeSpecificName =
      item.productCharacteristic.productCharacteristicBaseOilTypes
        .filter((base) => baseOilTypeSpecificMap.has(base.baseOilTypeId))
        .map((base) => baseOilTypeSpecificMap.get(base.baseOilTypeId))
        .join(', ');

    item.productCharacteristic.multiMetalsSuitableValue = yesNoMap.get(
      item.productCharacteristic.multiMetalsSuitableId,
    );
    item.productCharacteristic.formulatedWithoutSilicateValue = yesNoMap.get(
      item.productCharacteristic.formulatedWithoutSilicateId,
    );
    item.productCharacteristic.formulatedWithoutChlorinatedParaffinValue =
      yesNoMap.get(
        item.productCharacteristic.formulatedWithoutChlorinatedParaffinId,
      );
    item.productCharacteristic.formulatedWithoutBoronValue = yesNoMap.get(
      item.productCharacteristic.formulatedWithoutBoronId,
    );
    item.productCharacteristic.formulatedWithoutHeavyMetalValue = yesNoMap.get(
      item.productCharacteristic.formulatedWithoutHeavyMetalId,
    );

    item.productCharacteristic.productCharacteristicMetalTypes.forEach(
      (x: any) => {
        x.metalTypeValue = metalTypeMap.get(x.metalTypeId);
      },
    );

    item.productCharacteristic.productCharacteristicNlgis.forEach((x: any) => {
      x.nlgisValue = nlgisMap.get(x.nlgiId);
    });

    item.productCharacteristic.viscosityIndexName =
      viscosityIndexMap.get(item.productCharacteristic.viscosityIndexId) ||
      `${item.productCharacteristic.viscosityIndexId}`;

    item.productCharacteristic.viscosityName =
      item.productCharacteristic.viscosity.join(', ');

    item.category = item.productCategories.map((x) => x.name).join(', ');

    item.application = item.productApplications.map((x) => x.name).join(', ');
  });

  return products;
}
