import { useIntl } from 'react-intl';

import ContactDetails from '../ContactDetails/ContactDetails';
import FormWrapper from '../FormWrapper/FormWrapper';
import InputRow from '../InputRow/InputRow';
import TextareaRow from '../TextareaRow/TextareaRow';
import CheckboxRow from '../CheckboxRow/CheckboxRow';

import { ManagePortDistributorProps } from '../../../models/admin-form-types';

import { validateURL } from '../../../utils/common.util';

import { contactFields } from '../../../configs/admin-constants';

import styles from './DistributorDataForm.module.scss';
import cn from 'classnames';

const DistributorDataForm = ({
  formValue,
  handleValueChange,
  labelId = 'update-distributor-label',
}: ManagePortDistributorProps) => {
  const intl = useIntl();

  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });
  const title = getTranslatedMessage(labelId);
  const placeholder = getTranslatedMessage('input-placeholder');
  const fields = [
    {
      key: 'contactNames',
      labelId: 'contact-name-label',
      tooltip: 'your-name-tooltip',
    },
    ...contactFields,
  ];

  // For port distributor, we don't have outOfHoursPhones key but rather mobileNumbers
  const outOfHoursPhonesIndex = fields.findIndex(
    (f) => f.key === 'outOfHoursPhones',
  );
  if (outOfHoursPhonesIndex !== -1) {
    fields[outOfHoursPhonesIndex].key = 'mobileNumbers';
  }

  return (
    <FormWrapper title={title} message={formValue.country?.name || ''}>
      <div className={cn('columnRow', styles.activeRow)}>
        <CheckboxRow
          label={getTranslatedMessage('active-label')}
          checked={formValue.active}
          onClick={() => handleValueChange('active', !formValue.active as any)}
        />
      </div>

      <div className="columnRow">
        <InputRow
          labelId="name-label"
          value={formValue.name}
          onChange={(value: string) => {
            handleValueChange('name', value);
          }}
          tooltip={getTranslatedMessage('name-tooltip')}
        />
        <InputRow
          labelId="friendly-url-label"
          value={formValue.friendlyUrl}
          onChange={(value: string) => handleValueChange('friendlyUrl', value)}
          tooltip={getTranslatedMessage('friendly-url-tooltip')}
          errorMessage={getTranslatedMessage('url-error-message')}
          required={true}
        />
      </div>

      <div className="columnRow">
        <TextareaRow
          labelId="address-label"
          value={formValue.address}
          onChange={(value: string) => handleValueChange('address', value)}
          placeholder={placeholder}
          tooltip={getTranslatedMessage('address-tooltip')}
        />
        <TextareaRow
          labelId="port-served-label"
          value={formValue.portsServed}
          onChange={(value: string) => handleValueChange('portsServed', value)}
          placeholder={placeholder}
          tooltip={getTranslatedMessage('ports-served-tooltip')}
          required={false}
        />
      </div>
      <InputRow
        labelId="url-label"
        value={formValue.url}
        onChange={(value: string) => handleValueChange('url', value)}
        tooltip={getTranslatedMessage('url-tooltip')}
        customValidator={(value: string) => validateURL(value)}
        errorMessage={getTranslatedMessage('url-error-message')}
        required={false}
      />

      <ContactDetails
        fields={fields}
        formValue={formValue}
        handleValueChange={handleValueChange}
      />
    </FormWrapper>
  );
};

export default DistributorDataForm;
