import styles from './SearchResultList.module.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import Tabs from '../../common/Tabs/Tabs';
import { TabItem } from '../../../models/tab-item';
import { PropsWithoutRef, useEffect, useMemo, useState } from 'react';
import config from '../../../configs/config';
import { SearchResult } from '../../../models/search-result';
import ProductListItem from '../../common/ProductListItem/ProductListItem';
import ProductChildrenTable from '../../common/ProductListItem/ProductChildrenTable/ProductChildrenTable';
import { SearchResultCountry } from '../../../models/search-result-country';
import { SearchResultPort } from '../../../models/search-result-port';
import { SearchResultFamily } from '../../../models/search-result-family';
import { SearchResultRange } from '../../../models/search-result-range';
import { SearchResultOEM } from '../../../models/search-result-oem';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import cn from 'classnames';
import { toggleMultiSelectFilterById } from '../../../features/productFilterSlice';
import { compact } from 'lodash';
import Paginator from '../../../components/common/Paginator/Paginator';

interface SearchResultListProps {
  result: SearchResult;
  className?: string;
}

type SearchResultType =
  | SearchResultCountry
  | SearchResultPort
  | SearchResultFamily
  | SearchResultRange
  | SearchResultOEM;

/**
 * search result list
 * @param result search result
 * @param className classname
 */
function SearchResultList({
  result,
  className,
}: PropsWithoutRef<SearchResultListProps>) {
  const intl = useIntl();
  const hasLogin = useSelector((state: RootStore) => state.login.hasLogin);
  const { filters } = useSelector((state: RootStore) => state.productFilter);

  // tab data
  const tabs: TabItem[] = compact([
    {
      name: intl.formatMessage({ id: 'country-label' }),
      key: config.COUNTRY_TYPE,
    },
    {
      name: intl.formatMessage({ id: 'port-label' }),
      key: config.PORT_TYPE,
    },
    {
      name: intl.formatMessage({ id: 'family-label' }),
      key: config.FAMILY_TYPE,
    },
    {
      name: intl.formatMessage({ id: 'range-label' }),
      key: config.RANGE_TYPE,
    },
    {
      name: intl.formatMessage({ id: 'product-label' }),
      key: config.PRODUCT_TYPE,
    },
    hasLogin && {
      name: intl.formatMessage({ id: 'oem-approved-label' }),
      key: config.OEM_TYPE,
    },
  ]);

  // key set for model
  const tabKeys = {
    [config.COUNTRY_TYPE]: 'countries',
    [config.PORT_TYPE]: 'ports',
    [config.FAMILY_TYPE]: 'families',
    [config.RANGE_TYPE]: 'ranges',
    [config.PRODUCT_TYPE]: 'products',
    [config.OEM_TYPE]: 'oems',
  };

  const [query] = useSearchParams();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [tab, setTab] = useState(query.get('tab') || config.COUNTRY_TYPE);
  const [key, setKey] = useState<keyof SearchResult>(
    tabKeys[config.COUNTRY_TYPE] as keyof SearchResult,
  );
  const [filterTabs, setFilterTabs] = useState<TabItem[]>(tabs);

  const dispatch = useDispatch();

  const isProducts = tab === config.PRODUCT_TYPE;
  const existingQuery: any = {};
  const isMostSearchedResult = query.get('from') === 'most-searched-result';

  query.forEach((item, key) => {
    existingQuery[key] = item;
  });

  const category = query.get('category') || '';
  const application = query.get('application') || '';
  const productType = query.get('productType') || '';

  useEffect(() => {
    if (category) {
      dispatch(
        toggleMultiSelectFilterById({
          key: 'category',
          filter: Number(category),
        }),
      );
    }
    if (application) {
      dispatch(
        toggleMultiSelectFilterById({
          key: 'application',
          filter: Number(application),
        }),
      );
    }
    if (productType) {
      dispatch(
        toggleMultiSelectFilterById({
          key: 'productType',
          filter: Number(productType),
        }),
      );
    }
  }, [application, category, productType, dispatch]);

  // update tab
  useEffect(() => {
    if (query.get('tab')) {
      setTab(query.get('tab') || config.COUNTRY_TYPE);
    }
  }, [query]);

  // update filters
  useEffect(() => {
    if (filters) {
      setPage(1);
      setTotalNum(key);
    }
  }, [filters]);

  // key setup
  useEffect(() => {
    if (tab) {
      const tabKey = tabKeys[tab] as keyof SearchResult;
      setKey(tabKey);
      setPage(1);
      setTotalNum(tabKey);
    }
  }, [tab]);

  const getPagedList = (values: SearchResultType[]): SearchResultType[] => {
    const filteredValues = values.filter(
      (item) => (item.productChildren?.length || 0) > 0,
    );

    if (!filteredValues) {
      return [];
    }
    const first = perPage * (page - 1);
    let end = page * perPage;
    end = filteredValues.length > end ? end : filteredValues.length;
    return filteredValues.slice(first, end);
  };

  const hasContents = useMemo(() => {
    return (result[key]?.length || 0) > 0;
  }, [result, key]);

  if (isMostSearchedResult) {
    tabs.splice(0, 4);
  }

  // assemble countries
  const { countries } = useSelector(
    (rootState: RootStore) => rootState.lookups,
  );

  const { welcomeInfo } = useSelector(
    (rootState: RootStore) => rootState.welcomeInfo,
  );

  useEffect(() => {
    if (welcomeInfo?.industrySector?.friendlyUrl !== config.MARINE_SECTOR) {
      setFilterTabs(
        tabs.filter(
          (x) => !(x.key === config.PORT_TYPE || x.key === config.COUNTRY_TYPE),
        ),
      );
      setTab(query.get('tab') || config.FAMILY_TYPE);
    } else {
      setFilterTabs(tabs);
      setTab(query.get('tab') || config.PRODUCT_TYPE);
    }
  }, [welcomeInfo, welcomeInfo.industrySector]);

  const portsByFriendlyUrl = useMemo(() => {
    const ret: { [key: string]: SearchResultPort } = {};
    result.ports.forEach((port) => (ret[String(port.friendlyUrl)] = port));
    return ret;
  }, [result]);

  const filteredCountries = useMemo(() => {
    return result.countries.map((country) => ({
      ...country,
      name: countries.find((c) => c.id === country.id)?.name || '',
      portChildren:
        country.portChildren
          ?.map((port) => portsByFriendlyUrl[port.friendlyUrl])
          .filter((port) => !!port) || [],
    }));
  }, [portsByFriendlyUrl, result, countries]);

  const setTotalNum = (tabKey: keyof SearchResult) => {
    if (tabKey === config.COUNTRY_TYPE) {
      const productsExist = filteredCountries.filter(
        (item) => (item.productChildren?.length || 0) > 0,
      );
      setTotal(productsExist.length);
    } else {
      const productsExist = (result[tabKey] as any).filter(
        (item: any) => (item.productChildren?.length || 0) > 0,
      );
      setTotal(productsExist.length);
    }
  };

  return (
    <div className={cn(styles.container, className)}>
      <h3>
        <FormattedMessage id={'view-results-by-label'} />
      </h3>

      {filterTabs && filterTabs.length > 0 && (
        <Tabs
          className={styles.tabContainer}
          selected={tab}
          tabs={filterTabs}
          onChange={(item) => setTab(item.key)}
        >
          {hasContents ? (
            isProducts ? (
              <ProductChildrenTable
                background={'light'}
                products={result.products}
                showPagination={true}
              />
            ) : (
              <div>
                {(
                  (tab === config.COUNTRY_TYPE
                    ? getPagedList(filteredCountries)
                    : getPagedList(result[key])) || []
                ).map((item: SearchResultType) => {
                  const key =
                    item.id ||
                    (
                      item as
                        | SearchResultPort
                        | SearchResultFamily
                        | SearchResultRange
                    ).friendlyUrl;

                  return (
                    <ProductListItem
                      key={key}
                      type={tab}
                      product={item}
                      className={styles.searchListItem}
                    />
                  );
                })}

                <Paginator
                  page={page}
                  perPage={perPage}
                  total={total}
                  className={styles.pagination}
                  onPageChange={setPage}
                  onPerPageChange={(val: number) => {
                    setPerPage(val);
                    setPage(1);
                  }}
                />
              </div>
            )
          ) : (
            <div className={styles.noMatchedResults}>
              <FormattedMessage id={'no-matched-results-label'} />
            </div>
          )}
        </Tabs>
      )}
    </div>
  );
}

export default SearchResultList;
