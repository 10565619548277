import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface BrowseProductSliceState {
  categoryId?: number;
  applicationId?: number;
  productTypeId?: number;
}

export const browseProductSlice = createSlice({
  name: 'browse-product',
  initialState: {
    categoryId: undefined,
    applicationId: undefined,
    productTypeId: undefined,
  } as BrowseProductSliceState,
  reducers: {
    // set category id
    setCategoryId: (
      state: BrowseProductSliceState,
      action: PayloadAction<number | undefined>,
    ): BrowseProductSliceState => {
      return {
        ...state,
        categoryId: action.payload,
      };
    },
    // set application id
    setApplicationId: (
      state: BrowseProductSliceState,
      action: PayloadAction<number | undefined>,
    ): BrowseProductSliceState => {
      return {
        ...state,
        applicationId: action.payload,
      };
    },
    // set product type id
    setProductTypeId: (
      state: BrowseProductSliceState,
      action: PayloadAction<number | undefined>,
    ): BrowseProductSliceState => {
      return {
        ...state,
        productTypeId: action.payload,
      };
    },
  },
});

export const { setCategoryId, setApplicationId, setProductTypeId } =
  browseProductSlice.actions;

export default browseProductSlice.reducer;
