import styles from './UserProfileDropdown.module.scss';
import { ReactComponent as ArrowDown } from '../../../../../icons/arrow-down.svg';
import { PropsWithChildren, useState } from 'react';
import { useIntl } from 'react-intl';
import cn from 'classnames';
import { SelectOption } from '../../../../../models/select-option';
import Option from '../../../Select/Option/Option';
import Input from '../../../Input/Input';

interface UserProfileDropdownProps<T> {
  className: string;
  value: T;
  options: SelectOption<T>[];
  onChange: (value: T) => void;
  searchable?: boolean;
}

/**
 * dropdown buttons for user profile section in main header
 * @param value value
 * @param className class name
 * @param options options
 * @param onChange change handler
 * @param children children
 */
function UserProfileDropdown<T extends { id?: number }>({
  value,
  className,
  options,
  onChange,
  children,
  searchable = false,
}: PropsWithChildren<UserProfileDropdownProps<T>>) {
  const intl = useIntl();
  const [searchVal, setSearchVal] = useState('');
  const [opened, setOpened] = useState(false);

  const onOptionClick = (value: T) => {
    setOpened(false);
    onChange(value);
  };

  return (
    <div className={cn(styles.container, className)}>
      <div onClick={() => setOpened(true)} className={styles.innerContainer}>
        <div className={styles.label}>{children}</div>

        <ArrowDown className={styles.arrow} />
      </div>

      {opened && (
        <div
          onClick={() => setOpened(false)}
          className={styles.clickDetector}
        />
      )}

      {opened && searchable && (
        <div className={styles.optionContainer}>
          <Input
            className={styles.inputWrapper}
            value={searchVal}
            onChange={(_value) => {
              setSearchVal(_value);
            }}
            placeholder={intl.formatMessage({
              id: 'search-country-placeholder',
            })}
          />
        </div>
      )}

      {opened && (
        <div
          className={cn(styles.optionContainer, {
            [styles.optionContainerOffsetted]: opened && searchable,
          })}
        >
          {options
            .filter((item) =>
              item.name.toLowerCase().includes(searchVal.toLowerCase()),
            )
            .map((item, index) => {
              const selected = item.value.id === value.id;

              return (
                <Option
                  key={index}
                  selected={selected}
                  data={item}
                  onClick={onOptionClick}
                  scrollIntoView={options.length > 5}
                />
              );
            })}
        </div>
      )}
    </div>
  );
}

export default UserProfileDropdown;
