import { PropsWithoutRef, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import { ReactComponent as FullScreen } from '../../../icons/fullscreen.svg';
import { ReactComponent as CloseIcon } from '../../../icons/close-deep-grey.svg';
import { ReactComponent as Error } from '../../../icons/error.svg';
import { ProductValidationColumn } from '../../../models/table-column';
import styles from './ProductValidationCardMobile.module.scss';
import ProductValidationHiddenField from '../ProductValidationTable/ProductValidationHiddenField';
import classNames from 'classnames';
import { SheetRowError } from '../../../models/sheet-validation';
import ReactTooltip from 'react-tooltip';

interface ProductValidationCardMobileProps {
  product: any;
  setDataAtIndex: (x: any) => void;
  columns: ProductValidationColumn[];
  rowIndex: number;
  rowError?: SheetRowError;
}

function ProductValidationCardMobile({
  product,
  setDataAtIndex,
  columns,
  rowIndex,
  rowError,
}: PropsWithoutRef<ProductValidationCardMobileProps>) {
  const [showModal, setShowModal] = useState<boolean>(false);

  const errors = useMemo(() => {
    if (rowError) {
      return Object.keys(rowError).map((key) => {
        return `${key}: ${rowError[key]?.errorName}`;
      });
    }

    return [];
  }, [rowError]);

  return (
    <div className={styles.productCard}>
      <div className={styles.container}>
        <div className={styles.attributes}>
          {columns
            .filter((col) => !col.hiddenOnMobile)
            .map((column, i) => (
              <ProductValidationCardField
                key={i}
                product={product}
                setDataAtIndex={setDataAtIndex}
                column={column}
                rowIndex={rowIndex}
              />
            ))}
        </div>
        <div className={styles.topRightContainer}>
          {errors && errors.length > 0 && (
            <Error
              className={styles.errorIcon}
              data-tip
              data-for={`${rowIndex}`}
            />
          )}
          {errors && errors.length > 0 && (
            <ReactTooltip id={`${rowIndex}`} place="top" effect="solid">
              {errors.map((err, ind) => (
                <div key={ind}>{err}</div>
              ))}
            </ReactTooltip>
          )}
          <FullScreen
            onClick={() => setShowModal(true)}
            className={styles.fullscreen}
          />
        </div>
      </div>
      {showModal && (
        <div className={styles.fullScreenBackdrop}>
          <div className={styles.modal}>
            <div className={styles.modalHeader}>
              <div
                className={styles.doneButton}
                onClick={() => setShowModal(false)}
              >
                <FormattedMessage id="add-bulk-product-done" />
              </div>
              <CloseIcon
                onClick={() => setShowModal(false)}
                className={styles.closeButton}
              />
            </div>
            <div className={styles.modalContent}>
              {columns
                .filter((col) => col.hiddenOnMobile)
                .map((column) => (
                  <ProductValidationHiddenField
                    className={classNames(
                      column.showDivider && styles.borderTop,
                    )}
                    key={column.formattedMessageId}
                    column={column}
                    row={product}
                    setDataAtIndex={setDataAtIndex}
                    rowIndex={rowIndex}
                  />
                ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

interface ProductValidationCardFieldProps {
  product: any;
  setDataAtIndex: (x: any) => void;
  column: ProductValidationColumn;
  rowIndex: number;
}

function ProductValidationCardField({
  product,
  setDataAtIndex,
  column,
  rowIndex,
}: ProductValidationCardFieldProps) {
  const [showInput, setShowInput] = useState(false);
  const [showAll, setShowAll] = useState(false);

  const getColumnValue = () => {
    if (column.renderer) {
      return column.renderer(column, product, setDataAtIndex, rowIndex);
    }

    if (['$NULL_STRING$', ''].includes(String(column.key(product)))) {
      if (column.showNA) return 'N/A';
      else if (showInput) {
        return column.hidden ? (
          <textarea
            className={styles.textarea}
            defaultValue={
              ['$NULL_STRING$', ''].includes(String(column.key(product)))
                ? ''
                : column.key(product)
            }
            onBlur={(e) => {
              if (e.target.value) {
                setDataAtIndex({
                  ...product,
                  [column.objectKey]: e.target.value,
                });
              } else {
                setShowInput(false);
              }
            }}
          />
        ) : (
          <input
            className={styles.input}
            onBlur={(e) => {
              if (e.target.value) {
                setDataAtIndex({
                  ...product,
                  [column.objectKey]: e.target.value,
                });
              } else {
                setShowInput(false);
              }
            }}
          />
        );
      } else {
        return (
          <span className={styles.errorText} onClick={() => setShowInput(true)}>
            <FormattedMessage id={'cant-empty-label'} />
          </span>
        );
      }
    }
    if (column.hidden) {
      const arr = String(column.key(product)).split('\n');
      return (
        <div onClick={() => setShowInput(true)}>
          {arr
            .slice(0, showAll ? arr.length : 10)
            .map((str: string, index: number) => (
              <div key={index} className={styles.paraBody}>
                {str}
              </div>
            ))}
          {arr.length > 10 &&
            (showAll ? (
              <div
                className={styles.moreLessLink}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowAll(false);
                }}
              >
                - Less
              </div>
            ) : (
              <div
                className={styles.moreLessLink}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowAll(true);
                }}
              >
                + More
              </div>
            ))}
        </div>
      );
    }

    return column.key(product);
  };

  return (
    <div key={column.formattedMessageId} className={styles.attribute}>
      <div
        className={cn(styles.columnName, {
          [styles.requiredColumn]: column.requiredAsterisk,
        })}
      >
        <FormattedMessage id={column.formattedMessageId} />
      </div>
      <div className={styles.columnValue}>{getColumnValue()}</div>
    </div>
  );
}

export default ProductValidationCardMobile;
