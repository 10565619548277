import { PropsWithoutRef, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { ReactComponent as Barge } from '../../../icons/barge.svg';
import { ReactComponent as Truck } from '../../../icons/truck.svg';
import { PortDetails } from '../../../models/port-details';
import { TableColumn } from '../../../models/table-column';
import { sortMethodWithOrderByColumn } from '../../../utils/sort.util';
import RowActions from '../../Admin/RowActions/RowActions';
import { ReactComponent as EditWhite } from '../../../icons/edit-white.svg';
import { ReactComponent as EditGreen } from '../../../icons/edit.svg';
import { ReactComponent as DeactivateWhite } from '../../../icons/deactive-white.svg';
import { ReactComponent as DeactivateGreen } from '../../../icons/deactive-green.svg';
import { ReactComponent as ActivateWhite } from '../../../icons/check-circle-small-white.svg';
import { ReactComponent as ActivateGreen } from '../../../icons/check-circle-small.svg';
import ProductListTable, {
  sortChangeHandler,
} from '../ProductListTable/ProductListTable';
import styles from './PortTable.module.scss';
import { useNavigate } from 'react-router';
import Paginator from '../../../components/common/Paginator/Paginator';
import { RootStore } from '../../../store';

interface PortTableProps {
  from?: string;
  ports: PortDetails[];
  handleActions?: (action: string, portId: number) => void;
  showPagination?: boolean;
}

function PortTable({
  from,
  ports,
  handleActions,
  showPagination = false,
}: PropsWithoutRef<PortTableProps>) {
  const intl = useIntl();
  const navigate = useNavigate();

  const { hasLogin } = useSelector((state: RootStore) => state.login);

  // table sorts
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState('name');
  const [sortByProperty, setSortByProperty] = useState('name');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const sortedTmp = [...ports].sort(
    sortMethodWithOrderByColumn(sortBy, sortDirection),
  );
  const [showActions, setShowActions] = useState(-1);

  const total = ports?.length || 0;
  const getPagedList = (values: PortDetails[]): PortDetails[] => {
    if (!showPagination) {
      return values;
    }

    if (!values) {
      return [];
    }
    const first = perPage * (page - 1);
    let end = page * perPage;
    end = total > end ? end : total;
    return values.slice(first, end);
  };

  const [sortedData, setSortedData] = useState(sortedTmp);
  const [data, setData] = useState(getPagedList(sortedTmp));

  useEffect(() => {
    setPage(1);
    const sortedPorts = [...ports].sort(
      sortMethodWithOrderByColumn(sortBy, sortDirection),
    );
    setSortedData(sortedPorts);
    setData(getPagedList(sortedPorts));
  }, [ports]);

  useEffect(() => {
    setData(getPagedList(sortedData));
  }, [page, perPage]);

  // port children columns
  const columns: TableColumn<PortDetails>[] = [
    {
      key: 'name',
      property: 'name',
      label: intl.formatMessage({ id: 'port-label' }),
      width: '25%',
      to: (data) => {
        return `/port/${data.friendlyUrl}`;
      },
    },
    {
      key: 'country',
      property: 'country',
      label: intl.formatMessage({ id: 'country-label' }),
      width: '15%',
    },
    {
      key: 'minBulkSupply',
      property: 'minBulkIbcOrderInLitres',
      label: intl.formatMessage({ id: 'min-bulk-supply-label' }),
      width: '14%',
      render: (data) => {
        return data.minBulkIbcOrderInLitres >= 0
          ? `${data.minBulkIbcOrderInLitres}`
          : 'N/A';
      },
    },
    {
      key: 'minDumSupply',
      property: 'minPackOrderInDrums',
      label: intl.formatMessage({ id: 'min-dum-supply-label' }),
      width: '14%',
      render: (data) => {
        return data.minPackOrderInDrums >= 0
          ? `${data.minPackOrderInDrums}`
          : 'N/A';
      },
    },
    {
      key: 'noticeRequired',
      property: 'workingDaysNotice',
      label: intl.formatMessage({ id: 'notice-required-label' }),
      width: '14%',
    },
    {
      key: 'barge',
      property: 'minBulkBargeOrderInLitres',
      label: intl.formatMessage({ id: 'barge-label' }),
      width: '5.11770726714432%',
      minWidth: '55px',
      render: (data) => {
        return data.minBulkBargeOrderInLitres >= 0 && <Barge />;
      },
    },
    {
      key: 'truck',
      property: 'minBulkTruckOrderInLitres',
      label: intl.formatMessage({ id: 'truck-label' }),
      width: '5.11770726714432%',
      minWidth: '55px',
      render: (data) => {
        return data.minBulkTruckOrderInLitres >= 0 && <Truck />;
      },
    },
  ];

  const getPortActions = (port: PortDetails) => {
    const portActions = [];
    if (hasLogin) {
      portActions.push({
        fieldKey: 'edit-port',
        nameId: 'header-menu-manage-ports-edit-port',
        icon: <EditGreen />,
        iconActive: <EditWhite />,
      });
    }
    if (port.active) {
      portActions.push({
        fieldKey: 'deactivate-port',
        nameId: 'deactivate-port',
        icon: <DeactivateGreen />,
        iconActive: <DeactivateWhite />,
      });
    } else {
      portActions.push({
        fieldKey: 'activate-port',
        nameId: 'activate-port',
        icon: <ActivateGreen />,
        iconActive: <ActivateWhite />,
      });
    }

    return portActions;
  };

  const handleActionClick = (
    actionKey: string,
    portFriendlyUrl: string,
    portId: number,
  ) => {
    if (actionKey === 'edit-port') {
      navigate(`/admin/port/edit/${portFriendlyUrl}`);
    } else if (
      actionKey === 'activate-port' ||
      actionKey === 'deactivate-port'
    ) {
      if (handleActions) {
        handleActions(actionKey, portId);
      }
    }
  };

  const actionColumn = {
    key: '',
    property: 'action',
    label: intl.formatMessage({ id: 'actions-label' }),
    width: '5%',
    render: (data: PortDetails, index: any) => {
      return (
        <RowActions
          className={styles.mobilePosition}
          actions={getPortActions(data)}
          showAction={showActions === index}
          handleShowAction={(state: boolean) => {
            if (state) {
              setShowActions(index);
            } else {
              setShowActions(-1);
            }
          }}
          handleActionClick={(actionKey: string) =>
            handleActionClick(actionKey, data.friendlyUrl, data.id)
          }
        />
      );
    },
    className: styles.actionsColumn,
  };

  /**
   * handle sort change
   * @param column column
   */
  const onSortChange = (column: TableColumn<PortDetails>) => {
    if (!column.key) {
      return;
    }

    setPage(1);
    const changes = sortChangeHandler(
      [sortBy, setSortBy],
      [sortByProperty, setSortByProperty],
      [sortDirection, setSortDirection],
      column,
    );

    const sorted = [...ports].sort(
      sortMethodWithOrderByColumn(
        changes.sortByProperty,
        changes.sortDirection,
      ),
    );

    setSortedData(sorted);
    setData(getPagedList(sorted));
  };

  const getColumns = () => {
    const cols = columns;
    if (handleActions && hasLogin) {
      cols.push(actionColumn);
    }

    return cols;
  };

  return (
    <div className={styles.tableContainer}>
      <ProductListTable
        data={data}
        sortBy={sortBy}
        sortDirection={sortDirection}
        columns={getColumns()}
        onSortChange={onSortChange}
        showCheckbox={false}
        columnClassName={styles.tableColumn}
        from={from}
        actionColumn={actionColumn}
        className={cn(showPagination && styles.showPagination)}
      />
      {showPagination && (
        <Paginator
          page={page}
          perPage={perPage}
          total={total}
          onPageChange={setPage}
          onPerPageChange={(val: number) => {
            setPerPage(val);
            setPage(1);
          }}
        />
      )}
    </div>
  );
}

export default PortTable;
