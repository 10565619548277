import styles from './Checkbox.module.scss';
import { PropsWithoutRef } from 'react';
import { ReactComponent as Check } from '../../../icons/check.svg';
import cn from 'classnames';

interface CheckboxProps {
  onClick?: () => void;
  checked?: boolean;
  className?: string;
  disabled?: boolean;
  notEditable?: boolean;
}

/**
 * checkbox
 * @param checked checked state
 * @param onClick on click handler
 * @param className classname
 * @param disabled disabled
 */
function Checkbox({
  checked = false,
  onClick,
  className,
  disabled = false,
  notEditable = false,
}: PropsWithoutRef<CheckboxProps>) {
  return (
    <div
      className={cn(
        styles.checkbox,
        checked && styles.checked,
        className,
        disabled ? styles.disabled : '',
        notEditable && styles.notEditable,
      )}
      onClick={() => onClick && onClick()}
    >
      {checked && <Check />}
    </div>
  );
}

export default Checkbox;
