/* eslint-disable react/jsx-key */
import { useEffect, useState, useMemo, Fragment, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import cn from 'classnames';
import XLSX from 'xlsx';

import Card from '../../../../components/common/Card/Card';
import Header from '../../../../components/Admin/Header/Header';
import InfoCard from '../../../../components/Admin/Products/InfoCard/InfoCard';
import HorizontalStepper from '../../../../components/common/HorizontalStepper/HorizontalStepper';
import MobileStepper from '../../../../components/common/MobileStepper/MobileStepper';
import UploadData from '../../../../components/Admin/Products/UploadData/UploadData';
import { PreviousLink } from '../../../../models/previous-link';
import styles from './AddCountryLevelProductPage.module.scss';
import SaveData from '../../../../components/Admin/Products/SaveData/SaveData';
import { ProductValidationColumn } from '../../../../models/table-column';
import ValidateData from './components/ValidateData/ValidateData';
import {
  downloadCountryLevelProductsTemplate,
  downloadExistingCountryLevelProducts,
  saveCountryLevelProducts,
} from '../../../../services/CountryLevelProductService';
import { getAllProducts } from '../../../../services/ProductService';
import {
  AllProductItem,
  AllProductMap,
} from '../../../../models/admin-product-details';
import LoadingSpinner from '../../../../components/common/LoadingSpinner/LoadingSpinner';
import Select from '../../../../components/common/Select/Select';
import withAdmin from '../../../../hoc/withAdmin';
import { RootStore } from '../../../../store';
import MultiProductSelect from './components/MultiProductSelect/MultiProductSelect';
import { responsiveRender } from '../../../../utils/common.util';
import { addToast } from '../../../../features/toastSlice';
import { useNavigate } from 'react-router';

/**
 * add country page component
 */
function AddCountryLevelProductPage() {
  const {
    countries,
    productCountryLevelStatuses,
    productCountryLevelMessages,
  } = useSelector((rootState: RootStore) => rootState.lookups);

  /* istanbul ignore next */
  const columns: ProductValidationColumn[] = [
    {
      formattedMessageId: 'id',
      key: (item: any) => item['Product ID'],
      objectKey: 'Product ID',
      hidden: false,
      requiredAsterisk: true,
      renderer: (column, product) => (
        <span className={cn(styles.tableCell, styles.productId)}>
          {['$NULL_STRING$', ''].includes(String(column.key(product)))
            ? 'N/A'
            : column.key(product)}
        </span>
      ),
    },
    {
      formattedMessageId: 'product-name-label',
      key: (item: any) => item['Product Name'],
      objectKey: 'Product Name',
      hidden: false,
      requiredAsterisk: true,
      renderer: (column, product, setDataAtIndex) => {
        return (
          <Select
            required={true}
            className={cn(styles.selectDropdown, {
              [styles.errorDropdown]: ['$NULL_STRING$', ''].includes(
                String(column.key(product)),
              ),
            })}
            inputClassName={styles.selectInput}
            optionClassName={styles.dropdownOption}
            fixedWidth={false}
            value={product['Product ID'] as number}
            options={Object.keys(allProducts).map((productId) => ({
              name: allProducts[productId as unknown as number].name,
              value: allProducts[productId as unknown as number].id,
            }))}
            onChange={(value) => {
              setDataAtIndex({
                ...product,
                'Product ID': allProducts[value].id,
                'Product Name': allProducts[value].name,
              });
            }}
            placeholder={'Select Product'}
            canSearch
          />
        );
      },
    },
    {
      formattedMessageId: 'country-label',
      key: (item: any) => item['Country'],
      objectKey: 'Country',
      hidden: false,
      requiredAsterisk: true,
      renderer: (column, product, setDataAtIndex) => {
        return (
          <Select
            required={true}
            className={cn(styles.selectDropdown, {
              [styles.errorDropdown]: ['$NULL_STRING$', ''].includes(
                String(column.key(product)),
              ),
            })}
            inputClassName={styles.selectInput}
            optionClassName={styles.dropdownOption}
            fixedWidth={false}
            value={product['Country'] as number}
            options={[
              {
                name: 'All',
                value: -1,
              },
              ...countries.map((country) => ({
                name: country.name,
                value: country.id,
              })),
            ]}
            onChange={(value) => {
              setDataAtIndex({
                ...product,
                Country: value,
              });
            }}
            placeholder={'Select Country'}
            canSearch
          />
        );
      },
    },
    {
      formattedMessageId: 'product-status-label',
      key: (item: any) => item['Product Status'],
      objectKey: 'Product Status',
      hidden: false,
      requiredAsterisk: true,
      renderer: (column, product, setDataAtIndex) => {
        return (
          <Select
            required={true}
            className={cn(styles.selectDropdown, {
              [styles.errorDropdown]: ['$NULL_STRING$', ''].includes(
                String(column.key(product)),
              ),
            })}
            inputClassName={styles.selectInput}
            optionClassName={styles.dropdownOption}
            fixedWidth={false}
            value={product['Product Status'] as number}
            options={productCountryLevelStatuses.map((status) => ({
              name: status.name,
              value: status.id,
            }))}
            onChange={(value) => {
              setDataAtIndex({
                ...product,
                'Product Status': value,
              });
            }}
            placeholder={'Select Status'}
            canSearch
          />
        );
      },
    },
    {
      formattedMessageId: 'message-label',
      key: (item: any) => item['Message'],
      objectKey: 'Message',
      hidden: false,
      showNA: true,
      renderer: (_column, product, setDataAtIndex) => (
        <Select
          required={true}
          className={styles.selectDropdown}
          inputClassName={styles.selectInput}
          optionClassName={styles.dropdownOption}
          fixedWidth={false}
          value={product['Message'] as number}
          options={productCountryLevelMessages.map((message) => ({
            name: message.name,
            value: message.id,
          }))}
          onChange={(value) => {
            setDataAtIndex({
              ...product,
              Message: value,
            });
          }}
          placeholder={'Select Message'}
          canSearch
        />
      ),
    },
    {
      formattedMessageId: 'alternative-products-label',
      key: (item: any) => item['Alternative Products'],
      objectKey: 'Alternative Products',
      hidden: false,
      showNA: true,
      renderer: (_column, product, setDataAtIndex) => (
        <MultiProductSelect
          value={product['Alternative Products']}
          options={Object.keys(allProducts).map((productId) => ({
            name: allProducts[productId as unknown as number].name,
            value: allProducts[productId as unknown as number].id,
          }))}
          onChange={(value: number[]) =>
            setDataAtIndex({
              ...product,
              'Alternative Products': value,
            })
          }
          wrapperClass={styles.multiProductSelectWrapper}
        />
      ),
    },
    {
      formattedMessageId: 'substitute-products-label',
      key: (item: any) => item['Substitute Products'],
      objectKey: 'Substitute Products',
      hidden: false,
      showNA: true,
      renderer: (_column, product, setDataAtIndex) => (
        <MultiProductSelect
          value={product['Substitute Products']}
          options={Object.keys(allProducts).map((productId) => ({
            name: allProducts[productId as unknown as number].name,
            value: allProducts[productId as unknown as number].id,
          }))}
          onChange={(value: number[]) =>
            setDataAtIndex({
              ...product,
              'Substitute Products': value,
            })
          }
          wrapperClass={styles.multiProductSelectWrapper}
        />
      ),
    },
    {
      formattedMessageId: 'competitor-products-label',
      key: (item: any) => item['Competitor Products'],
      objectKey: 'Competitor Products',
      hidden: false,
      showNA: true,
      renderer: (_column, product, setDataAtIndex) => (
        <MultiProductSelect
          value={product['Competitor Products']}
          options={Object.keys(allProducts).map((productId) => ({
            name: allProducts[productId as unknown as number].name,
            value: allProducts[productId as unknown as number].id,
          }))}
          onChange={(value: number[]) =>
            setDataAtIndex({
              ...product,
              'Competitor Products': value,
            })
          }
          wrapperClass={styles.multiProductSelectWrapper}
        />
      ),
    },
  ];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [allProducts, setAllProducts] = useState<AllProductMap>({});
  const [currentStep, setCurrentStep] = useState(0);
  const [file, setFile] = useState();
  const [data, handleDataChange] = useState<unknown[]>([]);
  const [errorCount, setErrorCount] = useState<number>(0);
  const [uniquePair, setUniquePair] = useState<boolean>(true);
  const [backendErrors, setBackendErrors] = useState<any[]>([]);

  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });

  /* istanbul ignore next */
  const setData = (newData: any[]) => {
    let count = 0;
    let areAllPairsUnique = true;
    if (newData.length === 0) return;

    const uniquePairMap: { [key: string]: number[] } = {};

    newData = newData.map((row: any, index: number) => {
      if (
        !(
          ['$NULL_STRING$', '', undefined, null].includes(row['Product ID']) ||
          ['$NULL_STRING$', '', undefined, null].includes(row['Country'])
        )
      ) {
        if (`${row['Product ID']}_${row['Country']}` in uniquePairMap) {
          uniquePairMap[`${row['Product ID']}_${row['Country']}`] = [
            ...uniquePairMap[`${row['Product ID']}_${row['Country']}`],
            index,
          ];
        } else {
          uniquePairMap[`${row['Product ID']}_${row['Country']}`] = [index];
        }
      }
      if (
        columns.some(
          (column) =>
            !column.showNA &&
            !column.hidden &&
            ['$NULL_STRING$', ''].includes(String(column.key(row))),
        )
      ) {
        count++;
        return {
          ...row,
          hasError: true,
        };
      }
      return {
        ...row,
        hasError: false,
      };
    });
    Object.keys(uniquePairMap).forEach((key) => {
      if (uniquePairMap[key].length > 1) {
        uniquePairMap[key].forEach((indx) => {
          newData[indx].hasError = true;
        });
        count++;
        areAllPairsUnique = false;
      }
    });
    setUniquePair(areAllPairsUnique);
    setErrorCount(count);
    handleDataChange(newData);
  };

  useEffect(() => {
    setLoading(true);

    getAllProducts()
      .then((res: AllProductItem[]) => {
        setAllProducts(
          res
            // .filter((product) => product.active)
            .reduce((acc: AllProductMap, current: AllProductItem) => {
              acc[current.id] = current;
              return acc;
            }, {}),
        );
      })
      .finally(() => setLoading(false));
  }, []);

  /* istanbul ignore next */
  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;
      reader.onload = (e) => {
        /* Parse data */
        const bstr = e?.target?.result;
        const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        setData(
          XLSX.utils
            .sheet_to_json(ws, {
              header: [
                'Product ID',
                'Product Name',
                'Country',
                'Product Status',
                'Message',
                'Alternative Products',
                'Substitute Products',
                'Competitor Products',
              ],
              defval: '$NULL_STRING$',
              raw: false,
            })
            .slice(1)
            .map((row: any) => {
              const productExists =
                !isNaN(row['Product ID']) &&
                Number(row['Product ID']) in allProducts;
              return {
                ...row,
                'Product ID': productExists
                  ? allProducts[Number(row['Product ID'])].id
                  : '$NULL_STRING$',
                'Product Name': productExists
                  ? allProducts[Number(row['Product ID'])].name
                  : '$NULL_STRING$',
                Country:
                  row['Country'] === 'All'
                    ? -1
                    : countries.find(
                        (country) => country.name === row['Country'],
                      )?.id || '$NULL_STRING$',
                'Product Status':
                  productCountryLevelStatuses.find(
                    (status) => status.name === row['Product Status'],
                  )?.id || '$NULL_STRING$',
                Message:
                  productCountryLevelMessages.find(
                    (message) => message.name === row['Message'],
                  )?.id || '$NULL_STRING$',
                'Alternative Products': ['$NULL_STRING$', ''].includes(
                  row['Alternative Products'],
                )
                  ? []
                  : row['Alternative Products']
                      .split(',')
                      .map((item: string) => Number(item)),
                'Substitute Products': ['$NULL_STRING$', ''].includes(
                  row['Substitute Products'],
                )
                  ? []
                  : row['Substitute Products']
                      .split(',')
                      .map((item: string) => Number(item)),
                'Competitor Products': ['$NULL_STRING$', ''].includes(
                  row['Competitor Products'],
                )
                  ? []
                  : row['Competitor Products']
                      .split(',')
                      .map((item: string) => Number(item)),
              };
            }),
        );
      };
      if (rABS) reader.readAsBinaryString(file);
      else reader.readAsArrayBuffer(file);
    }
  }, [file]);

  const timerHandle = useRef<number | null>(null);

  useEffect(() => {
    return () => {
      if (timerHandle.current) {
        clearTimeout(timerHandle.current);
      }
    };
  }, []);

  const steps = useMemo(
    () => [
      intl.formatMessage({ id: 'upload-data' }),
      intl.formatMessage({ id: 'validate-data' }),
      intl.formatMessage({ id: 'save-data' }),
    ],
    [],
  );

  const breadcrumbLinks: PreviousLink[] = [
    {
      name: 'Home',
      to: '/home',
    },
    {
      name: intl.formatMessage({
        id: 'header-add-country',
      }),
    },
  ];

  /* istanbul ignore next */
  const descriptionValues = {
    a: (value: string[]) => (
      <a
        onClick={() => {
          if (value.includes('LINK_1')) {
            downloadExistingCountryLevelProducts();
          } else if (value.includes('LINK_2')) {
            downloadCountryLevelProductsTemplate();
          }
        }}
      >
        {value.includes('LINK_1') && (
          <FormattedMessage id={'add-country-info-card-link-1'} />
        )}
        {value.includes('LINK_2') && (
          <FormattedMessage id={'add-country-info-card-link-2'} />
        )}
      </a>
    ),
    b: (value: string[]) => <b>{value}</b>,
  };

  /* istanbul ignore next */
  const completeProcess = () => {
    setIsSaving(true);
    const payload: any = {
      items: data.map((row: any) => ({
        productId: row['Product ID'],
        countryId: row['Country'] === -1 ? null : row['Country'],
        productCountryLevelStatusId: row['Product Status'],
        productCountryLevelMessageId: ['$NULL_STRING$', ''].includes(
          row['Message'],
        )
          ? undefined
          : row['Message'],
        productCountryLevelAlternateProducts: row['Alternative Products'].map(
          (item: number) => ({ productId: item }),
        ),
        productCountryLevelSubstituteProducts: row['Substitute Products'].map(
          (item: number) => ({ productId: item }),
        ),
        productCountryLevelCompetitorProducts: row['Competitor Products'].map(
          (item: number) => ({ productId: item }),
        ),
      })),
    };
    setBackendErrors([]);
    saveCountryLevelProducts(payload)
      .then(() => {
        setIsSaving(false);
        timerHandle.current = window.setTimeout(() => navigate('/'), 3000);
      })
      .catch((err) => {
        setBackendErrors(err?.response?.data?.errors);
        setCurrentStep(1);
        dispatch(
          addToast({
            type: 'error',
            title: getTranslatedMessage('bulk-upload-country-error'),
            message: '',
          }),
        );
      });
  };

  /* istanbul ignore next */
  const stepsComponents = [
    <UploadData submit={() => setCurrentStep(1)} setFile={setFile} />,
    <ValidateData
      save={() => {
        setCurrentStep(2);
        completeProcess();
      }}
      cancel={() => setCurrentStep(0)}
      data={data}
      setData={setData}
      columns={columns}
      errorCount={errorCount}
      uniquePair={uniquePair}
      backendErrors={backendErrors}
    />,
    <SaveData isLoading={isSaving} />,
  ];

  return (
    <div className={styles.container}>
      <Header
        breadcrumbLinks={breadcrumbLinks}
        titleId={'header-add-country'}
      />
      {loading ? (
        <LoadingSpinner className={styles.loading} />
      ) : (
        <Card>
          <>
            <p className={styles.para}>
              <FormattedMessage id={'add-country-para-1'} />
            </p>
            <p className={styles.para}>
              <FormattedMessage id={'add-country-para-2'} />
            </p>
            <div className={styles.divider} />
            <div className={styles.twoColumnGrid}>
              <InfoCard
                heading={
                  <FormattedMessage
                    id="add-country-info-card-heading-1"
                    values={descriptionValues}
                  />
                }
                body={
                  <FormattedMessage
                    id="add-country-info-card-body-1"
                    values={descriptionValues}
                  />
                }
              />
              <InfoCard
                heading={
                  <FormattedMessage
                    id="add-country-info-card-heading-2"
                    values={descriptionValues}
                  />
                }
                body={
                  <FormattedMessage
                    id="add-country-info-card-body-2"
                    values={descriptionValues}
                  />
                }
              />
            </div>
            {responsiveRender(
              'desktop',
              <HorizontalStepper steps={steps} currentStep={currentStep} />,
            )}
            {responsiveRender(
              'mobile',
              <MobileStepper
                steps={steps}
                currentStep={currentStep}
                stepsComponents={stepsComponents}
              />,
            )}
            {responsiveRender(
              'desktop',
              <div>
                {stepsComponents.map((stepComponent, index) =>
                  index === currentStep ? (
                    <Fragment key={index}>{stepComponent}</Fragment>
                  ) : null,
                )}
              </div>,
            )}
          </>
        </Card>
      )}
    </div>
  );
}

export default withAdmin(AddCountryLevelProductPage);
