import styles from './OemDetailTable.module.scss';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { PropsWithoutRef, useState, useEffect, useMemo } from 'react';
import { find, cloneDeep, map } from 'lodash';
import ReactTooltip from 'react-tooltip';
import XLSX from 'xlsx';
import { RootStore } from '../../../../store';
import { getDateString } from '../../../../utils/date.util';
import { getAlphaDigitName } from '../../../../utils/string.util';
import { showApprovalDocName } from '../../../../utils/approval-document.util';
import { ReactComponent as OrderIcon } from '../../../../icons/order.svg';
import { ReactComponent as XlsxFileIcon } from '../../../../icons/xlsx-file.svg';
import { ReactComponent as SortArrow } from '../../../../icons/sort-arrow.svg';
import { ReactComponent as InfoIcon } from '../../../../icons/info.svg';
import { ReactComponent as DownloadIcon } from '../../../../icons/download.svg';
import { ReactComponent as DownloadWhiteIcon } from '../../../../icons/download-white.svg';
import { ReactComponent as Caret } from '../../../../icons/caret.svg';
import { ReactComponent as DocWhiteIcon } from '../../../../icons/ic-doc-white.svg';
import { ReactComponent as XOctagonWhiteIcon } from '../../../../icons/x-octagon-white.svg';
import { OemProductApproval } from '../../../../models/oem-product-approval';
import OemApprovalActionMenu from '../OemApprovalActionMenu/OemApprovalActionMenu';
import { sortMethodWithOrderByColumn } from '../../../../utils/sort.util';
import Button from '../../../common/Button/Button';
import DeactivateApprovalModal from '../DeactivateApprovalModal/DeactivateApprovalModal';
import CommentModal from '../CommentModal/CommentModal';
import LoadingSpinner from '../../../common/LoadingSpinner/LoadingSpinner';
import Checkbox from '../../../common/Checkbox/Checkbox';
import OemDetailEditModal from '../OemDetailEditModal/OemDetailEditModal';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import SelectFormRow from '../../SelectFormRow/SelectFormRow';
import LeftDrawer from '../../../common/LeftDrawer/LeftDrawer';
import SearchFilter from '../../../common/SearchFilter/SearchFilter';
import FilterContainer from '../../../common/FilterContainer/FilterContainer';
import Input from '../../../common/Input/Input';
import RightContainer from '../../../common/RightContainer/RightContainer';
import ContainerForDrawer from '../../../common/ContainerForDrawer/ContainerForDrawer';
import { getUserInfoFromLocalStorage } from '../../../../services/LocalStorageService';
interface OemDetailTableProps {
  oemProductApprovals: OemProductApproval[];
  oemId?: string;
  exportFileName?: string;
  className?: string;
  downloadApprovalDoc?: (approvalDocId: number) => void;
  activateApproval?: (approvalId: number) => void;
  deactivateApproval?: (approvalId: number) => void;
  onactivateDeactivateOemApproval?: (selectId: any) => void;
  onUpdateOemProductApproval: (
    id: number,
    typeId: number,
    confidential: boolean,
    notes: string,
    restrictions: string,
    approvalDocumentId: number,
    selectedIds?: number[],
    documentIds?: number[],
  ) => void;
}

/**
 * Oem Approval table
 * @param OemProductApproval oem approvals
 * @param className classname
 */
function OemDetailTable({
  oemProductApprovals,
  oemId = '',
  exportFileName = 'sample',
  className,
  downloadApprovalDoc,
  activateApproval,
  deactivateApproval,
  onactivateDeactivateOemApproval,
  onUpdateOemProductApproval,
}: PropsWithoutRef<OemDetailTableProps>) {
  // init hooks
  const intl = useIntl();
  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });

  const { oemApprovalTypes, masterBrands } = useSelector(
    (state: RootStore) => state.lookups,
  );

  const [shownOemProductApprovals, setShownOemProductApprovals] = useState<
    OemProductApproval[]
  >([]);
  const [nonFilterProductApprovals, setNonFilterProductApprovals] = useState<
    OemProductApproval[]
  >([]);
  const [oemEdit, setoemEdit] = useState(false);
  const [selectedOemProductApproval, setSelectedOemProductApproval] =
    useState<OemProductApproval>();
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [showDeactivateOemModal, setShowDeactivateOemModal] = useState(false);

  const [sorted, setSorted] = useState<OemProductApproval[]>([]);
  const [sortBy, setSortBy] = useState('');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [expanded, setExpanded] = useState<{ id: number; expanded: boolean }[]>(
    [],
  );
  const [processing, setProcessing] = useState<boolean>(false);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [showApprovalRestrictionModal, setShowApprovalRestrictionModal] =
    useState(false);
  const [showOemConfimationModal, setOemConfirmationModal] = useState(false);
  const [showOemDetailEditModal, setShowOemDetailEditModal] = useState(false);
  const [Activate, setActivate] = useState(false);
  const [commentData, setCommentData] = useState<{
    name: string;
    comment: string;
  }>({
    name: '',
    comment: '',
  });
  const [approvalRestrictionModalData, setApprovalRestrictionModalData] =
    useState<{
      name: string;
      comment: string;
    }>({
      name: '',
      comment: '',
    });
  const [searchProduct, setSearchProduct] = useState('');
  const [selectId, setSelectedId] = useState({
    ids: [],
    active: true,
    documentIds: [],
  });
  const [searchOemApprovalTypeId, setSearchOemApprovalTypeId] = useState(-1);
  const [searchIssueDate, setSearchIssueDate] = useState('');
  const [multiCheckbox, setmultiCheckbox] = useState(false);
  const [activeCheckbox, setActiveCheckbox] = useState(true);
  const current = new Date();

  //effects
  useEffect(() => {
    const userInfo = getUserInfoFromLocalStorage();
    if (userInfo) {
      if (userInfo.role === 'Super Admin' || userInfo.role === 'Approver') {
        setoemEdit(true);
      }
    }
    if (activeCheckbox) {
      if (oemProductApprovals && oemProductApprovals.length > 0) {
        setProcessing(true);
        setNonFilterProductApprovals(oemProductApprovals);
        const active = oemProductApprovals.filter(
          (data) => data.active == true,
        );
        setShownOemProductApprovals(active.slice(0, 300));
        setTimeout(() => {
          setProcessing(false);
        }, 500);
      }
    } else {
      setProcessing(true);
      setShownOemProductApprovals(nonFilterProductApprovals.slice(0, 300));
      setTimeout(() => {
        setProcessing(false);
      }, 500);
    }
  }, [oemProductApprovals, activeCheckbox]);

  useEffect(() => {
    if (shownOemProductApprovals && shownOemProductApprovals.length > 0) {
      if (sortBy) {
        const sortedList = shownOemProductApprovals.sort(
          sortMethodWithOrderByColumn(sortBy, sortDirection),
        );
        setSorted(sortedList);
      } else {
        setSorted(shownOemProductApprovals);
      }
    }
  }, [shownOemProductApprovals]);

  useEffect(() => {
    const arr: any = { ids: [], active: true, documentIds: [] };
    for (let i = 0; i < sorted.length; i++) {
      if (sorted[i].isChecked) {
        arr.ids.push(sorted[i].id);
        arr.documentIds.push(sorted[i].approvalDocumentId);
      }
    }
    setSelectedId(arr);
  }, [sorted]);

  const getMasterBrandName = (id: number): string => {
    if (!masterBrands) {
      return '';
    }

    const masterBrandName = find(masterBrands, (item) => item.id === id);
    return masterBrandName?.name || '';
  };

  const getOemApprovalTypeName = (id: number): string => {
    if (!oemApprovalTypes) {
      return '';
    }

    const approvalType = find(oemApprovalTypes, (item) => item.id === id);
    return approvalType?.name || '';
  };
  const oemApprovalTypesOptions = useMemo(() => {
    // oemApprovalTypes.push({ id: -1, description: 'none', name: 'none' });
    // const newArr = [{ name: 'None', value: -1 }];
    const arr = oemApprovalTypes?.map((item) => ({
      name: item.name,
      value: item.id,
    }));
    arr.push({ name: 'None', value: -1 });
    return arr;
  }, [oemApprovalTypes]);

  const menuClick = (val: string, item: OemProductApproval) => {
    if (val === 'menu-deactivate-approval') {
      setSelectedOemProductApproval(item);
      setShowDeactivateOemModal(true);
    } else if (val === 'menu-activate-approval') {
      setSelectedOemProductApproval(item);
      setShowDeactivateOemModal(true);
    } else if (val === 'menu-edit-oem-approval') {
      showOemDetailEdit();
      setSelectedOemProductApproval(item);
    }
  };

  // Deactivate Modal
  const handleSave = () => {
    setShowDeactivateOemModal(false);
    if (selectedOemProductApproval) {
      if (selectedOemProductApproval.active) {
        deactivateApproval && deactivateApproval(selectedOemProductApproval.id);
      } else {
        activateApproval && activateApproval(selectedOemProductApproval.id);
      }
    }
  };

  const onSortChange = (key: string) => {
    let changedKey = sortBy;
    let changedDirection: 'asc' | 'desc';

    if (sortBy !== key) {
      changedKey = key;
      changedDirection = 'desc';
    } else {
      if (sortDirection === 'desc') {
        changedDirection = 'asc';
      } else {
        changedDirection = 'desc';
      }
    }

    setSortBy(changedKey);
    setSortDirection(changedDirection);
    const sortedList = cloneDeep(shownOemProductApprovals).sort(
      sortMethodWithOrderByColumn(changedKey, changedDirection),
    );
    setSorted(sortedList);
  };

  const sortByDate = (key: string) => {
    let changedKey = sortBy;
    let changedDirection: 'asc' | 'desc';
    if (sortBy !== key) {
      changedKey = key;
      changedDirection = 'desc';
    } else {
      if (sortDirection === 'desc') {
        changedDirection = 'asc';
      } else {
        changedDirection = 'desc';
      }
    }
    if (changedDirection === 'asc') {
      setSorted(
        shownOemProductApprovals.slice().sort((a, b) => {
          const firstDate = a.approvalDocument.issuedAt;
          const secondDate = b.approvalDocument.issuedAt;
          return new Date(secondDate).valueOf() - new Date(firstDate).valueOf();
        }),
      );
      setSortDirection('desc');
    }
    if (changedDirection === 'desc') {
      setSorted(
        shownOemProductApprovals.slice().sort((a, b) => {
          const firstDate = a.approvalDocument.issuedAt;
          const secondDate = b.approvalDocument.issuedAt;
          return new Date(firstDate).valueOf() - new Date(secondDate).valueOf();
        }),
      );
      setSortDirection('asc');
    }

    setSortBy(changedKey);
    setSortDirection(changedDirection);
  };

  const handleCollapseClick = (
    index: number,
    expanded: { id: number; expanded: boolean }[],
  ) => {
    if (expanded && expanded.length > 0) {
      const items = cloneDeep(expanded);
      const item = expanded.find((x: any) => x.id === index);
      if (item) {
        const itemInd = expanded.findIndex((x: any) => x.id === index) || 0;
        items[itemInd].expanded = !item.expanded;
        setExpanded(items);
      } else {
        items.push({ id: index, expanded: false });
        setExpanded(items);
      }
    } else {
      setExpanded([{ id: index, expanded: true }]);
    }
  };

  const descriptionValues = {
    p: (value: string[]) => <p>{value}</p>,
    strong: (value: string[]) => <strong>{value}</strong>,
  };

  const showCommentDetail = (item: OemProductApproval) => {
    setCommentData({
      name: item.product.name,
      comment: item.notes,
    });
    setShowCommentModal(true);
  };

  // Oem Edit modal open-close
  const showOemDetailEdit = () => {
    setShowOemDetailEditModal(true);
  };

  const showApprovalRestriction = (item: OemProductApproval) => {
    setApprovalRestrictionModalData({
      name: item.product.name,
      comment: item.restrictions,
    });
    setShowApprovalRestrictionModal(true);
  };

  const exportToExcel = () => {
    const fileName = getAlphaDigitName(exportFileName) + '.xlsx';
    const tableHeader = [
      getTranslatedMessage('oem-table-field-product'),
      getTranslatedMessage('oem-table-field-master-brand'),
      getTranslatedMessage('oem-table-field-approval-type'),
      getTranslatedMessage('oem-table-field-issue-date'),
      getTranslatedMessage('oem-table-field-castrol-confidential'),
      getTranslatedMessage('oem-table-field-approval-restrictions'),
      getTranslatedMessage('oem-table-field-comments'),
    ];
    const tableData = shownOemProductApprovals.map((item) => {
      return [
        item.product.name,
        getMasterBrandName(item.product.masterBrandId),
        getOemApprovalTypeName(item.oemApprovalTypeId),
        getDateString(item.approvalDocument.issuedAt),
        item.approvalDocument.bpConfidential
          ? getTranslatedMessage('yes-label')
          : getTranslatedMessage('no-label'),
        item.restrictions ||
          getTranslatedMessage('oem-approval-restrictions-none'),
        item.notes,
      ];
    });
    const sheet = XLSX.utils.aoa_to_sheet([tableHeader, ...tableData]);
    const wb: XLSX.WorkBook = {
      SheetNames: ['Sheet1'],
      Sheets: {
        Sheet1: sheet,
      },
    };
    XLSX.writeFile(wb, fileName, {
      bookType: 'xlsx',
    });
  };

  const handleScroll = (event: any) => {
    const elem = event.target;
    if (
      elem.scrollHeight - elem.scrollTop < 900 &&
      shownOemProductApprovals.length < oemProductApprovals.length
    ) {
      setProcessing(true);
      if (activeCheckbox) {
        const newOemApprovals = shownOemProductApprovals.slice(
          shownOemProductApprovals.length,
          shownOemProductApprovals.length + 300,
        );

        setShownOemProductApprovals([
          ...shownOemProductApprovals,
          ...newOemApprovals,
        ]);
      } else {
        const newOemApprovals = oemProductApprovals.slice(
          shownOemProductApprovals.length,
          shownOemProductApprovals.length + 300,
        );

        setShownOemProductApprovals([
          ...shownOemProductApprovals,
          ...newOemApprovals,
        ]);
      }

      setTimeout(() => {
        setProcessing(false);
      }, 500);
    }
  };
  // Search product
  const handleSearch = (data: any) => {
    if (
      searchOemApprovalTypeId === -1 &&
      searchProduct === '' &&
      searchIssueDate === '' //no input given
    ) {
      return data.filter((val: any) => {
        return val;
      });
    }
    if (
      searchOemApprovalTypeId !== -1 && //search with only id
      searchProduct === '' &&
      searchIssueDate === ''
    ) {
      return data.filter((val: any) => {
        if (val.oemApprovalTypeId === searchOemApprovalTypeId) {
          return val;
        }
      });
    }
    if (
      searchOemApprovalTypeId === -1 &&
      searchProduct !== '' && //search with only Product
      searchIssueDate === ''
    ) {
      return data.filter((val: any) => {
        if (
          val.product.name
            .toLowerCase()
            .includes(searchProduct.toLocaleLowerCase())
        ) {
          return val;
        }
      });
    } //if
    if (
      searchOemApprovalTypeId === -1 &&
      searchProduct === '' &&
      searchIssueDate !== '' //search with only Date
    ) {
      return data.filter((val: any) => {
        if (
          val.approvalDocument.issuedAt
            .toLocaleLowerCase()
            .includes(searchIssueDate.toLowerCase())
        ) {
          return val;
        }
      });
    } //if
    if (
      searchOemApprovalTypeId !== -1 &&
      searchProduct !== '' &&
      searchIssueDate !== '' //search withall fields
    ) {
      return data.filter((val: any) => {
        if (
          val.product.name
            .toLowerCase()
            .includes(searchProduct.toLocaleLowerCase()) &&
          val.oemApprovalTypeId === searchOemApprovalTypeId &&
          val.approvalDocument.issuedAt
            .toLocaleLowerCase()
            .includes(searchIssueDate.toLowerCase())
        ) {
          return val;
        }
      });
    }
    if (
      searchOemApprovalTypeId !== -1 &&
      searchProduct !== '' &&
      searchIssueDate === '' //search without date
    ) {
      return data.filter((val: any) => {
        if (
          val.product.name
            .toLowerCase()
            .includes(searchProduct.toLocaleLowerCase()) &&
          val.oemApprovalTypeId === searchOemApprovalTypeId
        ) {
          return val;
        }
      });
    } //if
    if (
      searchOemApprovalTypeId === -1 && //search without approval
      searchProduct !== '' &&
      searchIssueDate !== ''
    ) {
      return data.filter((val: any) => {
        if (
          val.product.name
            .toLowerCase()
            .includes(searchProduct.toLocaleLowerCase()) &&
          val.approvalDocument.issuedAt
            .toLocaleLowerCase()
            .includes(searchIssueDate.toLowerCase())
        ) {
          return val;
        }
      });
    } //if
    if (
      searchOemApprovalTypeId !== -1 &&
      searchProduct === '' && //search without Product
      searchIssueDate !== ''
    ) {
      return data.filter((val: any) => {
        if (
          val.oemApprovalTypeId === searchOemApprovalTypeId &&
          val.approvalDocument.issuedAt
            .toLocaleLowerCase()
            .includes(searchIssueDate.toLowerCase())
        ) {
          return val;
        }
      });
    }
  };

  // Multiselect checkbox
  const handleCheckBox = (id: number, isCheck: boolean) => {
    setSorted((prevData) => {
      return prevData.map((ele) => {
        if (ele.id === id) {
          ele.isChecked = isCheck;
        }
        return ele;
      });
    });
  };

  // Multiple Select Activate Deactivate
  const handleActiveDeactive = (active: boolean) => {
    if (onactivateDeactivateOemApproval && active) {
      setSelectedId((prev) => {
        return { ...prev, active: true };
      });
      onactivateDeactivateOemApproval({ ...selectId, active: true });
    } else if (onactivateDeactivateOemApproval && !active) {
      setSelectedId((prev) => {
        return { ...prev, active: false };
      });
      onactivateDeactivateOemApproval({ ...selectId, active: false });
    }
  };

  // Activate & Deactivate Confirmation Modal
  const handleConfimationModal = () => {
    setOemConfirmationModal(true);
  };

  // Save func for Activate & Deactivate Modal
  const handleConfirmationSave = () => {
    if (Activate) {
      handleActiveDeactive(true);
    } else {
      handleActiveDeactive(false);
    }
  };
  const clearFilters = () => {
    setSearchProduct('');
    setSearchIssueDate('');
    setSearchOemApprovalTypeId(-1);
  };
  // Save Edit modal
  const handleSaveOemDetailModal = (
    id: number,
    typeId: number,
    confidential: boolean,
    notes: string,
    restrictions: string,
    approvalDocumentId: number,
  ) => {
    if (selectId.ids.length > 1) {
      onUpdateOemProductApproval(
        id,
        typeId,
        confidential,
        notes,
        restrictions,
        approvalDocumentId,
        selectId.ids,
        selectId.documentIds,
      );
    } else {
      onUpdateOemProductApproval(
        id,
        typeId,
        confidential,
        notes,
        restrictions,
        approvalDocumentId,
      );
    }
  };
  const handleMultiCheckBox = () => {
    setmultiCheckbox(!multiCheckbox);
    const newarray = sorted.map((d) => {
      return (d.isChecked = !multiCheckbox);
    });
    setSorted(sorted);
    const arr1: any = { ids: [], active: !multiCheckbox, documentIds: [] };
    for (let i = 0; i < sorted.length; i++) {
      arr1.ids.push(sorted[i].id);
      arr1.documentIds.push(sorted[i].approvalDocumentId);
    }
    if (!multiCheckbox) {
      setSelectedId(arr1);
    } else {
      setSelectedId({ ids: [], active: !multiCheckbox, documentIds: [] });
    }
  };
  const handleActiveCheckbox = () => {
    setActiveCheckbox(!activeCheckbox);
  };
  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.tableLabelRow}>
        <div
          className={cn(styles.exportToExcel, styles.mobile)}
          onClick={() => exportToExcel()}
        >
          <XlsxFileIcon />
          <FormattedMessage id={'oem-table-field-export-to-excel'} />
        </div>
        <div className={cn(styles.divider, styles.mobile)} />
        <div className={styles.tableLabel}>
          <FormattedMessage id={'oem-page-oem-detail-header'} />
        </div>
        <div className={cn(styles.rightContent)}>
          {oemEdit && (
            <>
              <span className={cn(styles.totalButton)}>
                Total : {shownOemProductApprovals.length} of{' '}
                {activeCheckbox
                  ? shownOemProductApprovals.length
                  : oemProductApprovals.length}
              </span>
              <Button
                className={cn(styles.activeButton)}
                color="green"
                disabled={selectId.ids.length < 2 ? true : false}
                onClick={() => {
                  setShowOemDetailEditModal(true);
                }}
              >
                Multi Edit
              </Button>
              <Button
                className={cn(styles.activeButton)}
                color="green"
                disabled={selectId.ids.length === 0 ? true : false}
                onClick={() => {
                  setActivate(true);
                  setmultiCheckbox(false);
                  handleConfimationModal();
                }}
              >
                Activate
              </Button>

              <Button
                className={cn(styles.activeButton)}
                color="green"
                disabled={selectId.ids.length === 0 ? true : false}
                onClick={() => {
                  setActivate(false);
                  setmultiCheckbox(false);
                  handleConfimationModal();
                }}
              >
                Deactivate
              </Button>
            </>
          )}

          <div
            className={cn(styles.exportToExcel, styles.desktop)}
            onClick={() => exportToExcel()}
          >
            <XlsxFileIcon />
            <FormattedMessage id={'oem-table-field-export-to-excel'} />
          </div>

          {/* <Input
            placeholder="product"
            type="text"
            value={searchProduct}
            onChange={(value) => setSearchProduct(value)}
            className={cn(styles.inputWrapper, styles.mobile)}
          /> */}
        </div>
      </div>
      <ContainerForDrawer
        classes={openFilter ? styles.filterOpen : styles.filterClose}
      >
        <LeftDrawer>
          <SearchFilter>
            <span className={cn(styles.activeCheckbox)}>
              <Checkbox
                checked={activeCheckbox}
                onClick={() => handleActiveCheckbox()}
              />
              <span className={cn(styles.activeCheckboxSpace)}>Active</span>
            </span>

            <FilterContainer
              title={getTranslatedMessage('oem-table-field-product')}
              className={styles.filterGroup}
            >
              <Input
                placeholder="Product"
                type="text"
                value={searchProduct}
                onChange={(value) => setSearchProduct(value)}
              />
            </FilterContainer>
            <FilterContainer
              title={getTranslatedMessage('oem-table-field-approval-type')}
              className={cn(styles.filterGroup, styles.margin_20)}
            >
              <SelectFormRow
                options={oemApprovalTypesOptions}
                value={searchOemApprovalTypeId}
                onChange={(value: number) => {
                  setSearchOemApprovalTypeId(value);
                }}
                className={cn(styles.padding_0)}
                selectInputBool
              />
            </FilterContainer>
            <FilterContainer
              title={getTranslatedMessage('oem-table-field-issue-date')}
              className={cn(styles.filterGroup, styles.margin_20)}
            >
              <Input
                placeholder="dd/mm/yyyy"
                type="text"
                value={searchIssueDate}
                onChange={(value) => setSearchIssueDate(value)}
              />
            </FilterContainer>
          </SearchFilter>
          <div className={styles.actionBtnContainer}>
            <Button
              onClick={clearFilters}
              color={'green'}
              className={styles.clearButton}
            >
              <FormattedMessage id={'clear-filters-label'} />
            </Button>
          </div>
        </LeftDrawer>
        <RightContainer nopad="true">
          <div className={styles.tableHeader}>
            <div className={styles.tableRow}>
              {oemEdit && (
                <>
                  <div
                    style={{ flex: '1 1 40px' }}
                    className={cn(styles.tableLabelColumn, styles.tableColumn)}
                  >
                    <FormattedMessage id={'oem-table-field-oem-select'} />
                    <Checkbox
                      checked={multiCheckbox}
                      onClick={() => handleMultiCheckBox()}
                    />
                  </div>
                </>
              )}
              <div
                style={{ flex: '1 1 120px' }}
                className={cn(
                  styles.tableLabelColumn,
                  styles.tableColumn,
                  styles.sortableColumn,
                )}
              >
                <FormattedMessage id={'oem-table-field-product'} />

                <SortArrow
                  onClick={() => onSortChange('product.friendlyUrl')}
                  className={cn(
                    styles.sortIcon,
                    sortBy === 'product.friendlyUrl' &&
                      sortDirection === 'desc' &&
                      styles.asc,
                  )}
                />
              </div>

              <div
                style={{ flex: '1 1 90px' }}
                className={cn(styles.tableLabelColumn, styles.tableColumn)}
              >
                <FormattedMessage id={'oem-table-field-approval-document'} />
              </div>

              <div
                style={{ flex: '1 1 90px' }}
                className={cn(
                  styles.tableLabelColumn,
                  styles.tableColumn,
                  styles.sortableColumn,
                )}
              >
                <FormattedMessage id={'oem-table-field-status'} />

                <SortArrow
                  onClick={() => onSortChange('active')}
                  className={cn(
                    styles.sortIcon,
                    sortBy === 'active' &&
                      sortDirection === 'desc' &&
                      styles.asc,
                  )}
                />
              </div>

              <div
                style={{ flex: '1 1 172px' }}
                className={cn(
                  styles.tableLabelColumn,
                  styles.tableColumn,
                  styles.columnWithIcon,
                  styles.sortableColumn,
                )}
              >
                <FormattedMessage id={'oem-table-field-approval-type'} />
                <InfoIcon data-tip data-for={'oem-table-field-approval-type'} />
                <ReactTooltip
                  id={'oem-table-field-approval-type'}
                  place={'top'}
                  effect={'solid'}
                >
                  <FormattedMessage
                    id={'oem-table-field-approval-type-tooltip'}
                    values={descriptionValues}
                  />
                </ReactTooltip>

                <SortArrow
                  onClick={() => {
                    onSortChange('oemApprovalTypeId');
                  }}
                  className={cn(
                    styles.sortIcon,
                    sortBy === 'oemApprovalTypeId' &&
                      sortDirection === 'desc' &&
                      styles.asc,
                  )}
                />
              </div>
              <div
                style={{ flex: '1 1 172px' }}
                className={cn(
                  styles.tableLabelColumn,
                  styles.tableColumn,
                  styles.sortableColumn,
                )}
              >
                <FormattedMessage id={'oem-table-field-document-versions'} />
                <SortArrow
                  onClick={() => {
                    onSortChange('approvalDocument.id');
                  }}
                  className={cn(
                    styles.sortIcon,
                    sortBy === 'approvalDocument.id' &&
                      sortDirection === 'desc' &&
                      styles.asc,
                  )}
                />
              </div>
              <div
                style={{ flex: '1 1 120px' }}
                className={cn(
                  styles.tableLabelColumn,
                  styles.tableColumn,
                  styles.sortableColumn,
                )}
              >
                <FormattedMessage id={'oem-table-field-issue-date'} />

                <SortArrow
                  onClick={() => sortByDate('approvalDocument.issuedAt')}
                  className={cn(
                    styles.sortIcon,
                    sortBy === 'approvalDocument.issuedAt' &&
                      sortDirection === 'desc' &&
                      styles.asc,
                  )}
                />
              </div>

              <div
                style={{ flex: '1 1 85px' }}
                className={cn(styles.tableLabelColumn, styles.tableColumn)}
              >
                <FormattedMessage id={'oem-table-field-expired'} />
              </div>

              <div
                style={{ flex: '1 1 180px' }}
                className={cn(
                  styles.tableLabelColumn,
                  styles.tableColumn,
                  styles.sortableColumn,
                  styles.columnWithIcon,
                )}
              >
                <FormattedMessage id={'oem-table-field-castrol-confidential'} />
                <SortArrow
                  onClick={() =>
                    onSortChange('approvalDocument.bpConfidential')
                  }
                  className={cn(
                    styles.sortIcon,
                    sortBy === 'approvalDocument.bpConfidential' &&
                      sortDirection === 'desc' &&
                      styles.asc,
                  )}
                />
              </div>

              <div
                style={{ flex: '1 1 90px' }}
                className={cn(styles.tableLabelColumn, styles.tableColumn)}
              >
                <FormattedMessage
                  id={'oem-table-field-approval-restrictions'}
                />
              </div>

              <div
                style={{ flex: '1 1 90px' }}
                className={cn(styles.tableLabelColumn, styles.tableColumn)}
              >
                <FormattedMessage id={'oem-table-field-comments'} />
              </div>
              {oemEdit && (
                <div
                  style={{ flex: '1 1 60px', justifyContent: 'center' }}
                  className={cn(styles.tableLabelColumn, styles.tableColumn)}
                >
                  <FormattedMessage id={'oem-table-field-actions'} />
                </div>
              )}
            </div>
          </div>

          <div
            className={cn(styles.tableBody, styles.desktop)}
            onScroll={handleScroll}
          >
            {handleSearch(sorted).map((oemDetail: any) => (
              <div
                className={cn(
                  styles.tableRow,
                  oemDetail.active ? '' : styles.inactive,
                )}
                key={oemDetail.id}
              >
                {oemEdit && (
                  <div
                    style={{ flex: '1 1 60px' }}
                    className={cn(styles.tableColumn, styles.productName)}
                  >
                    <Checkbox
                      checked={oemDetail.isChecked}
                      onClick={() =>
                        handleCheckBox(oemDetail.id, !oemDetail.isChecked)
                      }
                    />
                  </div>
                )}

                {/* end */}
                <div
                  style={{ flex: '1 1 90px' }}
                  className={cn(styles.tableColumn, styles.productName)}
                >
                  <Link
                    className={styles.link}
                    to={`/product/${oemDetail.product.friendlyUrl}`}
                  >
                    {oemDetail.product.name}
                  </Link>
                  {oemDetail.product.masterBrandId && (
                    <div className={styles.brandLabel}>
                      {getMasterBrandName(oemDetail.product.masterBrandId)}
                    </div>
                  )}
                </div>
                <div
                  style={{ flex: '1 1 75px' }}
                  className={cn(styles.tableColumn, styles.downloadColumn)}
                >
                  <DownloadIcon
                    onClick={() =>
                      downloadApprovalDoc &&
                      downloadApprovalDoc(oemDetail.approvalDocument.id)
                    }
                  />
                </div>
                <div
                  style={{ flex: '1 1 100px' }}
                  className={styles.tableColumn}
                >
                  {!oemDetail.active && (
                    <div className={styles.inactiveLabel}>
                      <FormattedMessage
                        id={'oem-table-field-inactive-approval'}
                      />
                    </div>
                  )}
                </div>

                <div
                  style={{ flex: '1 1 150px' }}
                  className={styles.tableColumn}
                >
                  {getOemApprovalTypeName(oemDetail.oemApprovalTypeId)}
                </div>
                <div
                  style={{ flex: '1 1 162px' }}
                  className={cn(styles.tableColumn, styles.documentColumn)}
                >
                  <Link
                    className={styles.link}
                    to={`/admin/approval-document/${oemDetail.approvalDocument.id}?oemId=${oemId}`}
                  >
                    <OrderIcon />
                    {showApprovalDocName(oemDetail.approvalDocument)}
                  </Link>
                </div>
                <div
                  style={{ flex: '1 1 90px' }}
                  className={styles.tableColumn}
                >
                  {oemDetail.approvalDocument.issuedAt}
                </div>
                <div
                  style={{ flex: '1 1 85px' }}
                  className={styles.tableColumn}
                >
                  {oemDetail.approvalDocument.validUntil &&
                    new Date(oemDetail.approvalDocument.validUntil) <
                      current && (
                      <div className={styles.inactiveLabel}>
                        <FormattedMessage id={'oem-table-field-expired'} />
                      </div>
                    )}
                </div>
                <div
                  style={{ flex: '1 1 140px' }}
                  className={styles.tableColumn}
                >
                  {oemDetail.approvalDocument.bpConfidential ? (
                    <FormattedMessage id={'yes-label'} />
                  ) : (
                    <FormattedMessage id={'no-label'} />
                  )}
                </div>
                <div
                  style={{ flex: '1 1 110px' }}
                  className={cn(
                    styles.tableColumn,
                    oemDetail.restrictions && styles.commentLabel,
                  )}
                >
                  {oemDetail.restrictions ? (
                    <span onClick={() => showApprovalRestriction(oemDetail)}>
                      <FormattedMessage id={'oem-table-field-view-details'} />
                    </span>
                  ) : (
                    <FormattedMessage id={'oem-approval-restrictions-none'} />
                  )}
                </div>
                <div
                  style={{ flex: '1 1 110px' }}
                  className={cn(styles.tableColumn, styles.commentLabel)}
                >
                  {oemDetail.notes && (
                    <span onClick={() => showCommentDetail(oemDetail)}>
                      <FormattedMessage id={'oem-table-field-view-details'} />
                    </span>
                  )}
                </div>
                {oemEdit && (
                  <div
                    style={{ flex: '1 1 40px' }}
                    className={cn(styles.tableColumn, styles.settingColumn)}
                  >
                    <OemApprovalActionMenu
                      forEditOemApproval={true}
                      forApproval={true}
                      forApprovalActivated={!oemDetail.active}
                      onOptionClick={(val) => menuClick(val, oemDetail)}
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        </RightContainer>
      </ContainerForDrawer>

      <div
        className={cn(styles.tableBody, styles.mobile)}
        onScroll={handleScroll}
      >
        {handleSearch(sorted).map((oemDetail: any, index: any) => (
          <div
            className={cn(
              styles.tableRow,
              styles.mobile,
              !oemDetail.active && styles.inactive,
              expanded && expanded.find((x) => x.id === index)?.expanded
                ? styles.expanded
                : styles.collapsed,
            )}
            key={index}
          >
            <div className={cn(styles.mobileTableRow, styles.firstRow)}>
              {oemEdit && (
                <div className={cn(styles.tableColumn, styles.leftCheckbox)}>
                  <Checkbox
                    checked={oemDetail.isChecked}
                    onClick={() =>
                      handleCheckBox(oemDetail.id, !oemDetail.isChecked)
                    }
                  />
                </div>
              )}

              <div className={cn(styles.tableColumn, styles.leftColumn)}>
                <Link
                  className={styles.link}
                  to={`/product/${oemDetail.product.friendlyUrl}`}
                >
                  {oemDetail.product.name}
                </Link>
              </div>
              <div className={cn(styles.tableColumn, styles.rightColumn)}>
                {oemDetail.product.masterBrandId && (
                  <div className={styles.brandLabel}>
                    {getMasterBrandName(oemDetail.product.masterBrandId)}
                  </div>
                )}
              </div>
              <Caret
                onClick={() => {
                  handleCollapseClick(index, cloneDeep(expanded));
                }}
                className={styles.dropdownArrow}
              />
              <div className={styles.divider} />
            </div>
            <div className={styles.mobileTableRow}>
              <div className={cn(styles.tableColumn, styles.leftColumn)}>
                <FormattedMessage id={'oem-table-field-status'} />
              </div>
              <div className={cn(styles.tableColumn, styles.rightColumn)}>
                {!oemDetail.active && (
                  <div className={styles.inactiveLabel}>
                    <FormattedMessage
                      id={'oem-table-field-inactive-approval'}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className={styles.mobileTableRow}>
              <div className={cn(styles.tableColumn, styles.leftColumn)}>
                <FormattedMessage id={'oem-table-field-document-versions'} />
              </div>
              <div
                className={cn(
                  styles.tableColumn,
                  styles.rightColumn,
                  styles.documentColumn,
                )}
              >
                <Link
                  className={styles.link}
                  to={`/admin/approval-document/${oemDetail.approvalDocument.id}?oemId=${oemId}`}
                >
                  <OrderIcon />
                  {showApprovalDocName(oemDetail.approvalDocument)}
                </Link>
              </div>
            </div>
            <div className={styles.mobileTableRow}>
              <div
                className={cn(
                  styles.tableColumn,
                  styles.leftColumn,
                  styles.columnWithIcon,
                )}
              >
                <FormattedMessage id={'oem-table-field-approval-type'} />
                <InfoIcon
                  data-tip
                  data-for={'oem-table-field-approval-type-sm'}
                />
                <ReactTooltip
                  id={'oem-table-field-approval-type-sm'}
                  place={'top'}
                  effect={'solid'}
                  // Position bugfix (see https://github.com/wwayne/react-tooltip/issues/476#issuecomment-607880172)
                  overridePosition={({ left, top }, _, __, node) => ({
                    top,
                    left: typeof node === 'string' ? left : Math.max(left, 0),
                  })}
                >
                  <FormattedMessage
                    id={'oem-table-field-approval-type-tooltip'}
                    values={descriptionValues}
                  />
                </ReactTooltip>
              </div>
              <div className={cn(styles.tableColumn, styles.rightColumn)}>
                {getOemApprovalTypeName(oemDetail.oemApprovalTypeId)}
              </div>
            </div>
            <div className={styles.mobileTableRow}>
              <div className={cn(styles.tableColumn, styles.leftColumn)}>
                <FormattedMessage id={'oem-table-field-issue-date'} />
              </div>
              <div className={cn(styles.tableColumn, styles.rightColumn)}>
                {oemDetail.approvalDocument.issuedAt}
              </div>
            </div>
            <div className={styles.mobileTableRow}>
              <div className={cn(styles.tableColumn, styles.leftColumn)}>
                <FormattedMessage id={'oem-table-field-expired'} />
              </div>
              <div className={cn(styles.tableColumn, styles.rightColumn)}>
                {oemDetail.approvalDocument.validUntil &&
                  new Date(oemDetail.approvalDocument.validUntil) < current && (
                    <div className={styles.inactiveLabel}>
                      <FormattedMessage id={'oem-table-field-expired'} />
                    </div>
                  )}
              </div>
            </div>
            <div className={styles.mobileTableRow}>
              <div className={cn(styles.tableColumn, styles.leftColumn)}>
                <FormattedMessage id={'oem-table-field-castrol-confidential'} />
              </div>
              <div className={cn(styles.tableColumn, styles.rightColumn)}>
                {oemDetail.approvalDocument.bpConfidential ? (
                  <FormattedMessage id={'yes-label'} />
                ) : (
                  <FormattedMessage id={'no-label'} />
                )}
              </div>
            </div>
            <div className={styles.mobileTableRow}>
              <div className={cn(styles.tableColumn, styles.leftColumn)}>
                <FormattedMessage
                  id={'oem-table-field-approval-restrictions'}
                />
              </div>
              <div
                className={cn(
                  styles.tableColumn,
                  styles.rightColumn,
                  oemDetail.restrictions && styles.commentLabel,
                )}
              >
                {oemDetail.restrictions ? (
                  <span onClick={() => showApprovalRestriction(oemDetail)}>
                    <FormattedMessage id={'oem-table-field-view-details'} />
                  </span>
                ) : (
                  <FormattedMessage id={'oem-approval-restrictions-none'} />
                )}
              </div>
            </div>
            <div className={styles.mobileTableRow}>
              <div className={cn(styles.tableColumn, styles.leftColumn)}>
                <FormattedMessage id={'oem-table-field-comments'} />
              </div>
              <div
                className={cn(
                  styles.tableColumn,
                  styles.rightColumn,
                  styles.commentLabel,
                )}
              >
                {oemDetail.notes && (
                  <span onClick={() => showCommentDetail(oemDetail)}>
                    <FormattedMessage id={'oem-table-field-view-details'} />
                  </span>
                )}
              </div>
            </div>
            <Button
              onClick={() =>
                downloadApprovalDoc &&
                downloadApprovalDoc(oemDetail.approvalDocument.id)
              }
              color={'green'}
              className={styles.mobileActionButton}
            >
              <DownloadWhiteIcon />
              <FormattedMessage id={'download-label'} />
            </Button>
            {oemEdit &&
              (!oemDetail.active ? (
                <Button
                  onClick={() => menuClick('menu-activate-approval', oemDetail)}
                  color={'green'}
                  className={cn(styles.mobileActionButton, styles.lastButton)}
                >
                  <DocWhiteIcon />
                  <FormattedMessage id={'menu-activate-approval'} />
                </Button>
              ) : (
                <Button
                  onClick={() =>
                    menuClick('menu-deactivate-approval', oemDetail)
                  }
                  color={'green'}
                  className={cn(styles.mobileActionButton, styles.lastButton)}
                >
                  <XOctagonWhiteIcon />
                  <FormattedMessage id={'menu-deactivate-approval'} />
                </Button>
              ))}
          </div>
        ))}
      </div>
      {processing && <LoadingSpinner className={'lookupsLoading'} />}
      {showOemDetailEditModal && (
        <OemDetailEditModal
          oemProductApproval={selectedOemProductApproval}
          onSave={handleSaveOemDetailModal}
          onClose={() => setShowOemDetailEditModal(false)}
        />
      )}
      {showDeactivateOemModal && (
        <DeactivateApprovalModal
          oemProductApproval={selectedOemProductApproval}
          onSave={handleSave}
          type={selectedOemProductApproval?.active ? 'deactivate' : 'activate'}
          onClose={() => setShowDeactivateOemModal(false)}
        />
      )}
      {showOemConfimationModal && (
        <ConfirmationModal
          onSave={() => handleConfirmationSave()}
          type={Activate ? 'activate' : 'deactivate'}
          onClose={() => setOemConfirmationModal(false)}
        />
      )}
      {showCommentModal && (
        <CommentModal
          titleId={'oem-model-field-comment-relating-to'}
          name={commentData?.name}
          comment={commentData?.comment}
          onClose={() => setShowCommentModal(false)}
        />
      )}
      {showApprovalRestrictionModal && (
        <CommentModal
          titleId={'oem-model-field-approval-restrictions-relating-to'}
          name={approvalRestrictionModalData?.name}
          comment={approvalRestrictionModalData?.comment}
          onClose={() => setShowApprovalRestrictionModal(false)}
        />
      )}
    </div>
  );
}

export default OemDetailTable;
