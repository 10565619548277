import { useIntl } from 'react-intl';

import Input from '../../common/Input/Input';
import FormRow from '../FormRow/FormRow';

import { InputRowType } from '../../../models/admin-form-types';

const InputRow = ({
  labelId,
  value,
  onChange,
  placeholder,
  required,
  tooltip,
  showCancelIcon,
  customValidator,
  errorMessage,
  className,
  disabled,
  isPassword,
  inputClassName,
}: InputRowType) => {
  const intl = useIntl();
  placeholder = placeholder || intl.formatMessage({ id: 'input-placeholder' });

  return (
    <FormRow
      labelId={labelId}
      required={required}
      tooltip={tooltip}
      className={className}
    >
      <Input
        value={value}
        onChange={onChange}
        required={required}
        placeholder={placeholder}
        showCancelIcon={showCancelIcon}
        customValidator={customValidator}
        errorMessage={errorMessage}
        disabled={disabled}
        isPassword={isPassword}
        className={inputClassName}
      />
    </FormRow>
  );
};

export default InputRow;
