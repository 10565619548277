import { PropsWithoutRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './ProductPortAvailability.module.scss';
import Select from '../../common/Select/Select';
import { SelectOption } from '../../../models/select-option';

interface ProductPortAvailabilityProps {
  country: string;
  countries: SelectOption<string>[];
  onCountryChange: (value: string) => void;
}

/**
 * product port availability
 * @param country selected country
 * @param countries country options
 * @param onCountryChange country change handler
 */
function ProductPortAvailability({
  country,
  countries,
  onCountryChange,
}: PropsWithoutRef<ProductPortAvailabilityProps>) {
  const intl = useIntl();

  return (
    <div className={styles.container}>
      <h3>
        <FormattedMessage id={'port-availability-label'} />
      </h3>

      {countries && countries.length > 0 && (
        <p>
          <FormattedMessage id={'port-availability-description'} />
        </p>
      )}

      {countries && countries.length > 0 && (
        <Select
          canSearch={true}
          className={styles.select}
          value={country}
          options={countries}
          onChange={onCountryChange}
          placeholder={intl.formatMessage({ id: 'select-country-placeholder' })}
        />
      )}
    </div>
  );
}

export default ProductPortAvailability;
