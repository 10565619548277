import { isArray } from 'lodash';
import { ValidationError } from '../models/sheet-validation';
import { isCommaSeperatedItems, isPositiveInteger } from './validation.util';

export function requiredValidator(
  value: string | number | number[] | boolean | null,
): ValidationError {
  if (
    (!isArray(value) || value.length > 0) &&
    value !== null &&
    value !== undefined &&
    (typeof value !== 'string' || value.trim() !== '')
  ) {
    return null;
  } else {
    return {
      errorName: 'Required',
      errorData: value,
    };
  }
}

export function commaSeperatedPositiveIntegerValidator(
  strVal: string,
): ValidationError {
  if (isCommaSeperatedItems(strVal, isPositiveInteger)) {
    return null;
  } else {
    return {
      errorName: 'PositiveIntegerArray',
      errorData: strVal,
    };
  }
}
