import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import Header from '../../../../../components/Admin/Header/Header';
import HorizontalStepper from '../../../../../components/common/HorizontalStepper/HorizontalStepper';
import { useDispatch, useSelector } from 'react-redux';
import { showGenericModal } from '../../../../../features/genericModalSlice';
import { PreviousLink } from '../../../../../models/previous-link';
import styles from './AddEditAnnouncement.module.scss';
import Details from './Steps/Details/Details';
import Visibility from './Steps/Visibility/Visibility';
import Summary from './Steps/Summary/Summary';
import MobileStepper from '../../../../../components/common/MobileStepper/MobileStepper';
import StepProgress from '../../../../../components/Admin/StepProgress/StepProgress';
import NavigationHeader from '../../../../../components/common/NavigationHeader/NavigationHeader';
import classNames from 'classnames';
import {
  Announcement,
  AnnouncementDetail,
  VisibilityCountryItem,
} from '../../../../../models/announcement';
import { addToast } from '../../../../../features/toastSlice';
import {
  createAnnouncement,
  getAnnouncementById,
  updateAnnouncement,
} from '../../../../../services/ManageAnnouncementService';
import LoadingSpinner from '../../../../../components/common/LoadingSpinner/LoadingSpinner';
import { useParams } from 'react-router';
import { RootStore } from '../../../../../store';
import _ from 'lodash';

const initialAnnouncement = {
  id: 0,
  title: '',
  description: '',
  announcementTypeId: 0,
  associateLink: '',
  announcementVisibilityCountries: [],
  translations: [],
  active: true,
};

const ENGLISH_ID = 1;

export default function AddEditAnnouncement() {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [processing, setProcessing] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [announcement, setAnnouncement] =
    useState<Announcement>(initialAnnouncement);

  const [isEdit, setIsEdit] = useState(false);
  const { announcementId } = useParams();
  const [loading, setLoading] = useState(false);
  const [isExternal, setIsExternal] = useState(true);
  const [isApprover, setIsApprover] = useState(true);

  const { user } = useSelector((state: RootStore) => state.login);

  useEffect(() => {
    setIsApprover(user?.role === 'Approver');
  }, [user]);

  useEffect(() => {
    if (announcementId) {
      setIsEdit(true);
      setLoading(true);
      getAnnouncementById(+announcementId)
        .then((res) => {
          const resAnnouncement = _.cloneDeep(res);
          resAnnouncement.translations.push({
            propertyName: 'title',
            propertyValue: res.title,
            languageId: ENGLISH_ID,
          });
          resAnnouncement.translations.push({
            propertyName: 'description',
            propertyValue: res.description,
            languageId: ENGLISH_ID,
          });
          setAnnouncement(resAnnouncement);
        })
        .catch(() => {
          dispatch(
            addToast({
              type: 'error',
              title: intl.formatMessage({ id: 'announcements-load-failure' }),
              message: '',
            }),
          );
        })
        .finally(() => setLoading(false));
    }
  }, [announcementId]);

  const onDetailNext = (announcement: AnnouncementDetail) => {
    setAnnouncement((prevAnnouncement) => ({
      ...prevAnnouncement,
      title: announcement.title,
      description: announcement.description,
      announcementTypeId: announcement.announcementTypeId,
      associateLink: announcement.associateLink,
      translations: announcement.translations,
    }));
    setCurrentStep((currStep) => currStep + 1);
  };

  const onVisibilityNext = (visibilityCountries: VisibilityCountryItem[]) => {
    setAnnouncement((prevAnnouncement) => ({
      ...prevAnnouncement,
      announcementVisibilityCountries: visibilityCountries,
    }));
    setCurrentStep((currStep) => currStep + 1);
  };

  const baseBreadcrumbLinks: PreviousLink[] = useMemo(
    () => [
      {
        name: intl.formatMessage({ id: 'breadcrumb-home' }),
        to: '/home',
      },
      {
        name: intl.formatMessage({
          id: 'header-menu-admin-manage-annoucements',
        }),
      },
    ],
    [intl],
  );

  const currentBreadcrumbLinks: PreviousLink[] = useMemo(() => {
    const updatedBreadcrumb = baseBreadcrumbLinks.map((b) => ({ ...b }));
    updatedBreadcrumb[1].to = '/admin/annoucements';

    if (isEdit) {
      updatedBreadcrumb.push({
        name: intl.formatMessage({
          id: 'edit-announcement',
        }),
      });
    } else {
      updatedBreadcrumb.push({
        name: intl.formatMessage({
          id: 'add-announcement',
        }),
      });
    }

    return updatedBreadcrumb;
  }, [intl, baseBreadcrumbLinks, isEdit]);

  const onSaveClicked = () => {
    setProcessing(true);
    announcement.translations = announcement.translations.filter(
      (a) => a.languageId !== ENGLISH_ID,
    );
    const upsertPromise = isEdit
      ? updateAnnouncement(announcement)
      : createAnnouncement(announcement);
    upsertPromise
      .then(() => {
        dispatch(
          showGenericModal({
            titleId: isEdit ? 'edit-announcement' : 'add-announcement',
            messageId: isEdit
              ? 'announcement-update-success'
              : 'add-announcement-success-msg',
            to: `/admin/annoucements`,
          }),
        );
      })
      .catch(() => {
        dispatch(
          addToast({
            type: 'error',
            title: intl.formatMessage({
              id: isEdit
                ? 'announcement-update-failure'
                : 'announcement-create-failure',
            }),
            message: '',
          }),
        );
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const stepsComponents = useMemo(() => {
    const detailComponent = (
      <Details
        onTypeChange={(isExternal) => !isApprover && setIsExternal(isExternal)}
        announcement={announcement}
        onNext={onDetailNext}
        key={0}
      />
    );

    const visibilityComponent = (
      <Visibility
        announcement={announcement}
        onNext={onVisibilityNext}
        onBack={() => setCurrentStep(0)}
        key={1}
      />
    );

    const summaryComponent = (
      <Summary
        key={2}
        onSave={onSaveClicked}
        announcement={announcement}
        openTab={(tabIndex: number) => setCurrentStep(tabIndex)}
      />
    );

    if (isExternal) {
      return [detailComponent, visibilityComponent, summaryComponent];
    } else {
      return [detailComponent, summaryComponent];
    }
  }, [announcement, isExternal, isApprover]);

  const steps = useMemo(() => {
    const detailTitle = intl.formatMessage({
      id: 'announcement-details-title',
    });
    const visibilityTitle = intl.formatMessage({
      id: 'announcement-visibility-title',
    });
    const summaryTitle = intl.formatMessage({
      id: 'announcement-summary-title',
    });

    if (isExternal) {
      return [detailTitle, visibilityTitle, summaryTitle];
    } else {
      return [detailTitle, summaryTitle];
    }
  }, [isExternal]);

  useEffect(() => {
    setAnnouncement((prevAnnouncement) => ({
      ...prevAnnouncement,
      announcementVisibilityCountries: [],
    }));
  }, [isExternal]);

  const getCurrentProgress = useCallback(() => {
    if (currentStep == stepsComponents.length - 1) return 100;
    const progress = (currentStep / (stepsComponents.length - 1)) * 100;
    return Math.floor(progress);
  }, [stepsComponents, currentStep]);

  return (
    <>
      <div className={styles.desktopOnly}>
        <Header
          titleId={isEdit ? 'edit-announcement' : 'add-new-announcement'}
          breadcrumbLinks={currentBreadcrumbLinks}
        />
      </div>
      <div className={styles.phoneOnly}>
        <NavigationHeader breadcrumbLinks={baseBreadcrumbLinks} />
      </div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className={styles.contentContainer}>
          <div className={styles.formWrapper}>
            <StepProgress
              className={classNames(
                styles.phoneOnly,
                styles.stepProgessContainer,
              )}
              progress={getCurrentProgress()}
            />
            <HorizontalStepper
              steps={steps}
              currentStep={currentStep}
              className={styles.horizontalStepper}
            />
            <MobileStepper
              steps={steps}
              currentStep={currentStep}
              stepsComponents={stepsComponents}
            />
          </div>
          <div className={styles.desktopOnly}>
            {stepsComponents.map((stepComponent, index) =>
              index === currentStep ? (
                <Fragment key={index}>{stepComponent}</Fragment>
              ) : null,
            )}
          </div>
        </div>
      )}
      {processing && <LoadingSpinner className={'lookupsLoading'} />}
    </>
  );
}
