import styles from './WelcomeModal.module.scss';
import Modal from '../Modal/Modal';
import { PropsWithoutRef } from 'react';
import WelcomeLeftBanner from './WelcomeLeftBanner/WelcomeLeftBanner';
import WelcomeForm from './WelcomeForm/WelcomeForm';
import { Lookups } from '../../../models/lookups';

interface WelcomeModalProps {
  onClose: () => void;
  lookups: Lookups;
}

/**
 * welcome modal
 * @param onClose close handler
 * @param lookups lookups
 */
function WelcomeModal({
  onClose,
  lookups,
}: PropsWithoutRef<WelcomeModalProps>) {
  return (
    <Modal hideClose={true} onClose={onClose}>
      <div className={styles.container}>
        <WelcomeLeftBanner />

        <WelcomeForm onFormSubmit={onClose} lookups={lookups} />
      </div>
    </Modal>
  );
}

export default WelcomeModal;
