import { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';
import ReactTooltip from 'react-tooltip';

import { FormRowType } from '../../../models/admin-form-types';
import { ReactComponent as Help } from '../../../icons/help-green.svg';
import cn from 'classnames';

import './FormRow.scss';

const FormRow = ({
  labelId,
  required = true,
  children,
  tooltip,
  className,
  tooltipClassName,
}: PropsWithChildren<FormRowType>) => (
  <div className={cn('formRow', className)}>
    <div className={'labelWrapper'}>
      {labelId && (
        <div className={'fieldLabelWrapper'}>
          <span className={'fieldLabel'}>
            <FormattedMessage id={labelId} />
          </span>
          {required && <span className={'requiredIndicator'}>*</span>}
        </div>
      )}
      {tooltip && (
        <Help className={cn(tooltipClassName)} data-tip data-for={labelId} />
      )}
    </div>
    {children}
    {tooltip && (
      <ReactTooltip id={labelId} place={'top'} effect={'solid'}>
        {tooltip}
      </ReactTooltip>
    )}
  </div>
);

export default FormRow;
