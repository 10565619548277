import { FormattedMessage } from 'react-intl';
import styles from './NoResult.module.scss';

interface NoResultProps {
  titleId: string;
  messageId: string;
}

const NoResult = ({ titleId, messageId }: NoResultProps) => {
  return (
    <div className={styles.noResult}>
      <div className={styles.noResultTitle}>
        <FormattedMessage id={titleId} />
      </div>
      <div className={styles.noResultMessage}>
        <FormattedMessage id={messageId} />
      </div>
    </div>
  );
};

export default NoResult;
