import { PropsWithoutRef } from 'react';
import { FormattedMessage } from 'react-intl';

import ValidationTable from '../ValidationTable/ValidationTable';
import Button from '../../../../../components/common/Button/Button';
import { ValidationColumn } from '../../../../../models/table-column';
import { ErrorMessage } from '../../AddMultiUsersPage';
import styles from './ValidateData.module.scss';

interface ValidateDataProps<T> {
  save: () => void;
  cancel: () => void;
  data: T[];
  setData: (x: T[]) => void;
  columns: ValidationColumn<T>[];
  errorCount: number;
  errorMessages: ErrorMessage[];
  setErrorMessages: (x: ErrorMessage[]) => void;
}

function ValidateData<T>({
  save,
  cancel,
  data = [],
  setData,
  columns,
  errorCount,
  errorMessages,
  setErrorMessages,
}: PropsWithoutRef<ValidateDataProps<T>>) {
  const descriptionValues = {
    count: errorCount,
    b: (value: string[]) => <b>{value}</b>,
  };

  const setDataAtIndex = (newDataItem: any, index: number) => {
    if (index >= data.length) return;
    setData(data.map((row, i) => (i === index ? newDataItem : row)));
  };

  return (
    <>
      <div className={styles.container}>
        <h2>
          <FormattedMessage id={'validate-data'} />
        </h2>
        <div className={styles.divider} />
        <div className={styles.validationErrCount}>
          <FormattedMessage
            id={'validation-data-count-error'}
            values={descriptionValues}
          />
        </div>
        {errorMessages && errorMessages.length > 0 && (
          <ul className={styles.errorMessageShow}>
            {errorMessages.map((item, idx) => (
              <li
                key={idx}
              >{`Row ${item.row}: ${item.property} ${item.message}`}</li>
            ))}
          </ul>
        )}
        <ValidationTable
          data={data}
          setDataAtIndex={setDataAtIndex}
          columns={columns}
          errorMessages={errorMessages}
          setErrorMessages={setErrorMessages}
        />
      </div>
      <div className={styles.buttons}>
        <Button onClick={cancel} color={'gray'} className={styles.button}>
          <FormattedMessage id={'cancel-label'} />
        </Button>
        <Button
          onClick={save}
          color={'green'}
          className={styles.button}
          disabled={data.length === 0 || errorCount !== 0}
        >
          <FormattedMessage id={'save-label'} />
        </Button>
      </div>
    </>
  );
}

export default ValidateData;
