import styles from './UploadDocumentModal.module.scss';
import { PropsWithoutRef, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import Modal from '../../../../../components/common/Modal/Modal';
import Button from '../../../../../components/common/Button/Button';
import FileInput from '../../../../../components/Admin/FileInput/FileInput';
import DatePicker from '../../../../../components/Admin/DatePicker/DatePicker';
import FormRow from '../../../../../components/Admin/FormRow/FormRow';
import { ReactComponent as CloseIcon } from '../../../../../icons/close.svg';
import { ReactComponent as PinIcon } from '../../../../../icons/pin.svg';

interface UploadDocumentModalProps {
  onUpload: (issueDate: Date, document: File) => void;
  onClose: () => void;
}

/**
 * Help modal
 * @param type the help modal type
 * @param onClose close handler
 */
function UploadDocumentModal({
  onUpload,
  onClose,
}: PropsWithoutRef<UploadDocumentModalProps>) {
  // init hook
  const intl = useIntl();
  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });

  const [issueDate, setIssueDate] = useState<Date | null>(null);
  const [newDocument, setNewDocument] = useState<File | null>(null);

  return (
    <Modal
      onClose={onClose}
      hideClose={true}
      center={true}
      className={styles.modalContainer}
    >
      <div className={styles.container}>
        <CloseIcon onClick={() => onClose()} className={styles.close} />
        <div className={styles.title}>
          <FormattedMessage id={'approval-document-label'} />
        </div>

        <div className={styles.description}>
          <FormattedMessage id={'approval-document-modal-desc'} />
        </div>

        <div className={styles.subTitle}>
          <FormattedMessage id={'add-new-document-label'} />
        </div>

        <div className={styles.modalContent}>
          <div className={'columnRow'}>
            <FormRow
              labelId={'issue-date-label'}
              required={true}
              tooltip={getTranslatedMessage('issue-date-label')}
            >
              <DatePicker
                value={issueDate}
                className={styles.datePicker}
                placeholder={'Select Date'}
                onChange={setIssueDate}
              />
            </FormRow>
          </div>
          <div className={'columnRow'}>
            <FormRow
              labelId={'upload-new-document-label'}
              required={true}
              tooltip={getTranslatedMessage('upload-new-document-tooltip')}
            >
              <FileInput
                accept={'application/pdf'}
                file={newDocument}
                className={styles.fileInput}
                inputTextClassName={styles.fileInputText}
                placeholderId={'attach-file-placeholder'}
                icon={<PinIcon className={styles.pinIcon} />}
                onChange={setNewDocument}
              />
            </FormRow>
          </div>
        </div>

        <div className={styles.actionBtnContainer}>
          <Button
            onClick={() =>
              issueDate && newDocument && onUpload(issueDate, newDocument)
            }
            color={'green'}
            className={styles.uploadButton}
            disabled={!issueDate || !newDocument}
          >
            <FormattedMessage id={'upload-label'} />
          </Button>
          <Button
            onClick={onClose}
            color={'transparent'}
            className={styles.cancelButton}
          >
            <FormattedMessage id={'cancel-label'} />
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default UploadDocumentModal;
