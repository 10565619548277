import { FormattedMessage, useIntl } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import { useNavigate } from 'react-router';
import cn from 'classnames';

import styles from './LinkTable.module.scss';
import Paginator, {
  PaginatorType,
} from '../../../../../components/common/Paginator/Paginator';
import Button from '../../../../../components/common/Button/Button';
import TableMobileView from '../../../TableMobileView/TableMobileView';
import { TableColumn } from '../../../../../models/table-column';
import { LinkResponse } from '../../../../../models/link-response';
import { ReactComponent as InfoIcon } from '../../../../../icons/info.svg';
import { ReactComponent as SortArrow } from '../../../../../icons/sort-arrow.svg';
import { ReactComponent as EditIcon } from '../../../../../icons/edit-white.svg';
import { ReactComponent as DustbinIcon } from '../../../../../icons/dustbin.svg';

interface LinkTableProps extends PaginatorType {
  links: LinkResponse[];
  sortBy: string;
  sortDirection: string;
  onSortChange: (column: string) => void;
  deleteLink: (id: number) => void;
}

export default function LinkTable({
  links,
  page,
  perPage,
  total,
  onPageChange,
  onPerPageChange,
  sortBy,
  sortDirection,
  onSortChange,
  deleteLink,
}: LinkTableProps) {
  const intl = useIntl();
  const navigate = useNavigate();

  const onEdit = (linkId: number) => {
    navigate(`${linkId}/edit`);
  };

  const columns: TableColumn<LinkResponse>[] = [
    {
      key: 'name',
      label: intl.formatMessage({ id: 'title-label' }),
      property: 'name',
      render: (data) => <span className={styles.greenText}>{data.name}</span>,
    },
    {
      key: 'description',
      label: intl.formatMessage({ id: 'description-label' }),
      property: 'description',
      render: (data) => (
        <div
          className={styles.descBody}
          dangerouslySetInnerHTML={{ __html: data.description }}
        />
      ),
    },
    {
      key: 'targetUrl',
      label: intl.formatMessage({ id: 'target-url-properties-label' }),
      property: 'targetUrl',
      render: (data) => (
        <a
          className={styles.link}
          href={data.targetUrl}
          target={'_blank'}
          rel={'noreferrer'}
        >
          {data.targetUrl}
        </a>
      ),
    },
    {
      key: 'sector',
      label: intl.formatMessage({ id: 'sector-title' }),
      property: 'sector',
      render: (data) => data.sector?.name,
    },
  ];

  const mobileRowActions = (link: LinkResponse) => (
    <>
      <Button
        onClick={() => onEdit(link.id)}
        className={styles.mobileActionBtn}
        color={'green'}
      >
        <EditIcon className={styles.actionIcon} />
        <FormattedMessage id={'update-link'} />
      </Button>
      <Button
        onClick={() => deleteLink(link.id)}
        className={styles.mobileActionBtn}
        color={'red'}
      >
        <DustbinIcon className={styles.actionIcon} />
        <FormattedMessage id={'delete-link'} />
      </Button>
    </>
  );

  return (
    <>
      <div>
        <div className={styles.tableHeader}>
          <div className={styles.tableRow}>
            <div
              style={{ flex: '1 1 60px' }}
              onClick={() => onSortChange('name')}
              className={cn(
                styles.tableLabelColumn,
                styles.tableColumn,
                styles.sortableColumn,
              )}
            >
              <FormattedMessage id={'title-label'} />
              {sortBy === 'name' && (
                <SortArrow
                  className={cn(
                    styles.sortIcon,
                    sortDirection === 'asc' && styles.asc,
                  )}
                />
              )}
            </div>
            <div
              style={{ flex: '1 1 250px' }}
              onClick={() => onSortChange('description')}
              className={cn(
                styles.tableLabelColumn,
                styles.tableColumn,
                styles.sortableColumn,
              )}
            >
              <FormattedMessage id={'description-label'} />
              {sortBy === 'description' && (
                <SortArrow
                  className={cn(
                    styles.sortIcon,
                    sortDirection === 'asc' && styles.asc,
                  )}
                />
              )}
            </div>
            <div
              style={{ flex: '1 1 200px' }}
              className={cn(
                styles.tableLabelColumn,
                styles.tableColumn,
                styles.columnWithIcon,
              )}
            >
              <FormattedMessage id={'target-url-properties-label'} />
              <InfoIcon data-tip data-for={'target-url-properties-label'} />
              <ReactTooltip
                id={'target-url-properties-label'}
                place={'top'}
                effect={'solid'}
              >
                <FormattedMessage id={'target-url-properties-label'} />
              </ReactTooltip>
            </div>
            <div
              style={{ flex: '1 1 60px' }}
              onClick={() => onSortChange('sector.name')}
              className={cn(
                styles.tableLabelColumn,
                styles.tableColumn,
                styles.sortableColumn,
              )}
            >
              <FormattedMessage id={'sector-title'} />
              {sortBy === 'sector.name' && (
                <SortArrow
                  className={cn(
                    styles.sortIcon,
                    sortDirection === 'asc' && styles.asc,
                  )}
                />
              )}
            </div>
            <div
              style={{ flex: '0 0 130px' }}
              className={cn(styles.tableLabelColumn, styles.tableColumn)}
            >
              <FormattedMessage id={'actions-label'} />
            </div>
          </div>
        </div>

        <div className={cn(styles.tableBody, styles.desktop)}>
          {links.map((link, index) => (
            <div className={cn(styles.tableRow)} key={index}>
              <div
                style={{ flex: '1 1 60px' }}
                className={cn(styles.tableColumn, styles.name)}
              >
                {link.name}
              </div>
              <div
                style={{ flex: '1 1 250px' }}
                className={cn(styles.tableColumn, styles.descBody)}
              >
                {link.description}
              </div>
              <div
                style={{ flex: '1 1 200px' }}
                className={cn(styles.tableColumn)}
              >
                <a
                  className={styles.link}
                  href={link.targetUrl}
                  target={'_blank'}
                  rel={'noreferrer'}
                >
                  {link.targetUrl}
                </a>
              </div>
              <div
                style={{ flex: '1 1 60px' }}
                className={cn(styles.tableColumn)}
              >
                {link.sector?.name}
              </div>
              <div
                style={{ flex: '0 0 130px' }}
                className={cn(styles.tableColumn, styles.action)}
              >
                <span
                  onClick={() => onEdit(link.id)}
                  className={cn(styles.actionBtn, styles.link)}
                >
                  <FormattedMessage id={'update-label'} />
                </span>
                <span
                  onClick={() => deleteLink(link.id)}
                  className={cn(styles.actionBtn, styles.link)}
                >
                  <FormattedMessage id={'delete-label'} />
                </span>
              </div>
            </div>
          ))}
        </div>

        <div className={cn(styles.tableBody, styles.mobile)}>
          <TableMobileView
            rows={links}
            columns={columns}
            actions={mobileRowActions}
            className={styles.tableMobileView}
          />
        </div>

        <Paginator
          page={page}
          perPage={perPage}
          total={total}
          onPageChange={onPageChange}
          onPerPageChange={onPerPageChange}
        />
      </div>
    </>
  );
}
