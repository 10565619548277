import { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router';
import cn from 'classnames';

import styles from './DistributorPage.module.scss';
import { RootStore } from '../../store';
import { PreviousLink } from '../../models/previous-link';
import NavigationHeader from '../../components/common/NavigationHeader/NavigationHeader';
import { DistributorResponse } from '../../models/distributor';
import IconField from '../../components/common/IconField/IconField';
import { ReactComponent as Phone } from '../../icons/phone.svg';
import { ReactComponent as Email } from '../../icons/email.svg';
import { ReactComponent as Fax } from '../../icons/fax.svg';
import { ReactComponent as Web } from '../../icons/web.svg';

import { hideLoading, showLoading } from '../../features/loadingSlice';
import { addToast } from '../../features/toastSlice';
import { getErrorMessage } from '../../utils/string.util';
import { getDistributorDetails } from '../../services/DistributorService';

/**
 * My account page component
 */
function DistributorPage() {
  // init hooks
  const intl = useIntl();
  const { friendlyUrl } = useParams();
  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [distributorDetail, setDistributorDetail] =
    useState<DistributorResponse>();

  const breadcrumbLinks: PreviousLink[] = [
    {
      name: 'Home',
      to: '/home',
    },
    {
      name: getTranslatedMessage('distributor-label'),
    },
  ];

  const { countries } = useSelector((state: RootStore) => state.lookups);

  useEffect(() => {
    if (friendlyUrl) {
      dispatch(showLoading());
      getDistributorDetails(friendlyUrl)
        .then((res) => {
          if (res) {
            const country = countries.find((item) => item.id === res.countryId);
            if (country) {
              res.country = country.name;
            }
            setDistributorDetail(res);
          }
        })
        .catch((err) => {
          dispatch(
            addToast({
              type: 'error',
              title: getTranslatedMessage('get-account-info-failure'),
              message: getErrorMessage(err),
            }),
          );
        })
        .finally(() => dispatch(hideLoading()));
    }
  }, []);

  return (
    <div>
      <NavigationHeader
        breadcrumbLinks={breadcrumbLinks}
        showBottomLine={true}
      />
      <div className={styles.container}>
        <div className={styles.title}>{distributorDetail?.name}</div>
        <div className={styles.country}>{distributorDetail?.country}</div>

        <div className={styles.divider} />

        <div className={styles.portServed}>
          <div className={styles.infoRow}>
            <h3 className={styles.infoLabel}>
              <FormattedMessage id={'ports-served-label'} />
            </h3>
            <div className={styles.infoValue}>
              {distributorDetail?.portsServed}
            </div>
          </div>
        </div>

        <div className={styles.contactContainer}>
          <div className={cn(styles.left, styles.item)}>
            <h3 className={styles.label}>
              <FormattedMessage id={'how-to-order-label'} />
            </h3>
            <div className={cn(styles.address)}>
              {distributorDetail?.address}
            </div>
          </div>
          <div className={cn(styles.right, styles.item)}>
            {distributorDetail && (
              <Fragment>
                {distributorDetail.contactNames.map((contact, idx) => (
                  <IconField
                    key={contact.name + idx}
                    className={styles.icon}
                    icon={<Phone />}
                    label={contact.name}
                    value={contact.value}
                  />
                ))}
                {distributorDetail.phones.map((phone, idx) => (
                  <IconField
                    key={phone.name + idx}
                    className={styles.icon}
                    icon={<Phone />}
                    label={phone.name}
                    value={phone.value}
                  />
                ))}
                {distributorDetail.mobileNumbers.map((phone, idx) => (
                  <IconField
                    key={phone.name + idx}
                    className={styles.icon}
                    icon={<Phone />}
                    label={phone.name}
                    value={phone.value}
                  />
                ))}
                {distributorDetail.emails.map((email, idx) => (
                  <IconField
                    key={email.name + idx}
                    className={styles.icon}
                    icon={<Email />}
                    label={email.name}
                    value={email.value}
                  />
                ))}
                {distributorDetail.faxes.map((fax, idx) => (
                  <IconField
                    key={fax.name + idx}
                    className={styles.icon}
                    icon={<Fax />}
                    label={fax.name}
                    value={fax.value}
                  />
                ))}

                {distributorDetail.others.map((other, idx) => (
                  <IconField
                    key={other.name + idx}
                    className={styles.icon}
                    icon={<Web />}
                    label={other.name}
                    value={other.value}
                  />
                ))}
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DistributorPage;
