import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import Paginator, {
  PaginatorType,
} from '../../../../../components/common/Paginator/Paginator';
import { ReactComponent as InfoIcon } from '../../../../../icons/info.svg';
import { ReactComponent as SortArrow } from '../../../../../icons/sort-arrow.svg';
import { ReactComponent as EditIcon } from '../../../../../icons/edit-white.svg';
import { ReactComponent as DeactiveIcon } from '../../../../../icons/deactive-white.svg';
import { ReactComponent as DustbinIcon } from '../../../../../icons/dustbin.svg';
import { ReactComponent as DocWhiteIcon } from '../../../../../icons/ic-doc-white.svg';
import styles from './AnnouncementTable.module.scss';
import TableMobileView from '../../../TableMobileView/TableMobileView';
import { TableColumn } from '../../../../../models/table-column';
import Button from '../../../../../components/common/Button/Button';
import classNames from 'classnames';
import { Announcement } from '../../../../../models/announcement';
import AnnouncementActionMenu from '../AnnouncementActionMenu/AnnouncementActionMenu';
import { RootStore } from '../../../../../store';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import DescriptionPreviewModal from '../../../../../components/Admin/common/DescriptionPreviewModal/DescriptionPreviewModal';
interface AnnouncementTableProps extends PaginatorType {
  announcements: Announcement[];
  sortBy: string;
  sortDirection: string;
  onSortChange: (column: string) => void;
  menuClick: (actionType: string, announcement: Announcement) => void;
}

export default function AnnouncementTable({
  announcements,
  page,
  perPage,
  total,
  onPageChange,
  onPerPageChange,
  sortBy,
  sortDirection,
  onSortChange,
  menuClick,
}: AnnouncementTableProps) {
  const intl = useIntl();

  const { announcementTypesById } = useSelector(
    (state: RootStore) => state.lookups,
  );

  const [showDescriptionModal, setShowDescriptionModal] = useState(false);
  const [modalDescription, setModalDescription] = useState('');

  const showDescription = (description: string) => {
    setModalDescription(description);
    setShowDescriptionModal(true);
  };

  const columns: TableColumn<any>[] = [
    {
      key: 'title',
      label: intl.formatMessage({ id: 'title-label' }),
      property: 'title',
      render: (data) => (
        <div className={styles.expanderHeader}>
          <div className={classNames(styles.expanderTitle)}>{data.title}</div>
          <div className={styles.secondaryLabel}>
            {data.visibilityCountries ? data.visibilityCountries[0] : ''}
          </div>
        </div>
      ),
    },
    {
      key: 'description',
      label: intl.formatMessage({ id: 'description-label' }),
      property: 'description',
      className: styles.descColumn,
      render: (data) => (
        <span
          onClick={() => showDescription(data.description)}
          className={styles.link}
        >
          <FormattedMessage id={'view-details-label'} />
        </span>
      ),
    },
    {
      key: 'announcementType',
      label: intl.formatMessage({ id: 'type-label' }),
      property: 'announcementType',
      render: (data) => announcementTypesById[data.announcementTypeId]?.name,
    },
    {
      key: 'status',
      label: intl.formatMessage({ id: 'status-label' }),
      property: 'status',
      render: (data) => (
        <span className={styles.greenText}>
          {data.active ? (
            <FormattedMessage id={'active-label'} />
          ) : (
            <FormattedMessage id={'inactive-label'} />
          )}
        </span>
      ),
    },
  ];

  const mobileRowActions = (announement: Announcement) => {
    return (
      <>
        <Button
          onClick={() => menuClick('edit-announcement', announement)}
          className={styles.mobileActionBtn}
          color={'green'}
        >
          <EditIcon className={styles.actionIcon} />
          <FormattedMessage id={'edit-announcement'} />
        </Button>
        {announement.active ? (
          <Button
            onClick={() => menuClick('deactivate-announcement', announement)}
            className={styles.mobileActionBtn}
            color={'green'}
          >
            <DeactiveIcon className={styles.actionIcon} />
            <FormattedMessage id={'deactivate-announcement'} />
          </Button>
        ) : (
          <Button
            onClick={() => menuClick('activate-announcement', announement)}
            className={styles.mobileActionBtn}
            color={'green'}
          >
            <DocWhiteIcon className={styles.actionIcon} />
            <FormattedMessage id={'activate-announcement'} />
          </Button>
        )}
        <Button
          onClick={() => menuClick('delete-announcement', announement)}
          className={styles.mobileActionBtn}
          color={'red'}
        >
          <DustbinIcon className={styles.actionIcon} />
          <FormattedMessage id={'delete-announcement'} />
        </Button>
      </>
    );
  };

  return (
    <>
      <div>
        <div className={styles.tableHeader}>
          <div className={styles.tableRow}>
            <div
              style={{ flex: '1 1 90px' }}
              onClick={() => onSortChange('title')}
              className={cn(
                styles.tableLabelColumn,
                styles.tableColumn,
                styles.sortableColumn,
              )}
            >
              <FormattedMessage id={'title-label'} />
              {sortBy === 'title' && (
                <SortArrow
                  className={cn(
                    styles.sortIcon,
                    sortDirection === 'asc' && styles.asc,
                  )}
                />
              )}
            </div>
            <div
              style={{ flex: '1 1 200px' }}
              className={cn(styles.tableLabelColumn, styles.tableColumn)}
            >
              <FormattedMessage id={'description-label'} />
            </div>
            <div
              style={{ flex: '1 1 140px' }}
              onClick={() => onSortChange('announcementType.name')}
              className={cn(
                styles.tableLabelColumn,
                styles.tableColumn,
                styles.columnWithIcon,
                styles.sortableColumn,
              )}
            >
              <FormattedMessage id={'type-label'} />
              <InfoIcon data-tip data-for={'type-label'} />
              <ReactTooltip id={'type-label'} place={'top'} effect={'solid'}>
                <FormattedMessage id={'type-label'} />
              </ReactTooltip>
              {sortBy === 'announcementType.name' && (
                <SortArrow
                  className={cn(
                    styles.sortIcon,
                    sortDirection === 'asc' && styles.asc,
                  )}
                />
              )}
            </div>
            <div
              style={{ flex: '0 0 120px' }}
              onClick={() => onSortChange('active')}
              className={cn(
                styles.tableLabelColumn,
                styles.sortableColumn,
                styles.tableColumn,
              )}
            >
              <FormattedMessage id={'status-label'} />
              {sortBy === 'active' && (
                <SortArrow
                  className={cn(
                    styles.sortIcon,
                    sortDirection === 'asc' && styles.asc,
                  )}
                />
              )}
            </div>
            <div
              style={{ flex: '0 0 90px' }}
              className={cn(styles.tableLabelColumn, styles.tableColumn)}
            >
              <FormattedMessage id={'actions-label'} />
            </div>
          </div>
        </div>

        <div className={cn(styles.tableBody, styles.desktop)}>
          {announcements.map((announcement) => (
            <div className={cn(styles.tableRow)} key={announcement.id}>
              <div
                style={{ flex: '1 1 90px' }}
                className={cn(
                  styles.tableColumn,
                  styles.greenText,
                  styles.titleColumn,
                )}
              >
                {announcement.title}
              </div>
              <div
                style={{ flex: '1 1 200px' }}
                className={cn(styles.tableColumn, styles.descColumn)}
              >
                <span
                  onClick={() => showDescription(announcement.description)}
                  className={styles.link}
                >
                  <FormattedMessage id={'view-details-label'} />
                </span>
              </div>
              <div
                style={{ flex: '1 1 140px' }}
                className={cn(styles.tableColumn)}
              >
                {announcementTypesById[announcement.announcementTypeId]?.name}
              </div>
              <div
                style={{ flex: '0 0 120px' }}
                className={cn(styles.tableColumn)}
              >
                {announcement.active ? (
                  <FormattedMessage id={'active-label'} />
                ) : (
                  <FormattedMessage id={'inactive-label'} />
                )}
              </div>
              <div
                style={{ flex: '0 0 90px' }}
                className={cn(styles.tableColumn, styles.actionColumn)}
              >
                <AnnouncementActionMenu
                  active={announcement.active}
                  onOptionClick={(val) => menuClick(val, announcement)}
                />
              </div>
            </div>
          ))}
        </div>

        <div className={cn(styles.tableBody, styles.mobile)}>
          <TableMobileView
            rows={announcements}
            columns={columns}
            actions={mobileRowActions}
            className={styles.tableMobileView}
          />
        </div>

        <Paginator
          page={page}
          perPage={perPage}
          total={total}
          onPageChange={onPageChange}
          onPerPageChange={onPerPageChange}
        />
      </div>
      {showDescriptionModal && (
        <DescriptionPreviewModal
          modalDescription={modalDescription}
          onHideModal={() => setShowDescriptionModal(false)}
        />
      )}
    </>
  );
}
