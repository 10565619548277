import styles from './Textarea.module.scss';
import { PropsWithoutRef, useState } from 'react';
import cn from 'classnames';
import FormError from '../FormError/FormError';

interface TextareaProps {
  value: string;
  onChange: (value: string) => void;
  resize?: 'both' | 'horizontal' | 'vertical' | 'none';
  required?: boolean;
  className?: string;
  errorMessage?: string;
  placeholder?: string;
  maxLength?: number;
}

/**
 * textarea component
 * @param value value
 * @param onChange change handler
 * @param resize resize property
 * @param required required
 * @param className classname
 * @param errorMessage error message
 * @param placeholder placeholder to be shown before entering anything
 * @param maxLength maximium length allowed for the field
 */
function Textarea({
  value,
  onChange,
  resize = 'none',
  required = false,
  className,
  errorMessage,
  placeholder,
  maxLength,
}: PropsWithoutRef<TextareaProps>) {
  const [changed, setChanged] = useState(false);
  const onTextChange = (value: string) => {
    setChanged(true);
    onChange(value);
  };

  return (
    <div>
      <textarea
        onBlur={() => setChanged(true)}
        style={{ resize }}
        value={value}
        required={required}
        className={cn(styles.textarea, changed && styles.changed, className)}
        onInput={(event) =>
          onTextChange((event.target as HTMLTextAreaElement).value)
        }
        placeholder={placeholder}
        maxLength={maxLength}
      />

      <FormError className={styles.error}>{errorMessage}</FormError>
    </div>
  );
}

export default Textarea;
