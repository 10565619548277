import SearchResultColumn from '../../SearchResultColumn/SearchResultColumn';
import styles from './SearchModalBrowseColumn.module.scss';
import BrowseItem from '../BrowseItem/BrowseItem';
import {
  CSSProperties,
  PropsWithoutRef,
  ReactElement,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { ProductCategory } from '../../../../../models/product-category';
import { Application } from '../../../../../models/application';
import { ProductType } from '../../../../../models/product-type';
import BrowseChildren from '../BrowseChildren/BrowseChildren';
import { useIntl } from 'react-intl';
import { MountService } from '../../../../../services/MountService';

interface SearchModalBrowseColumnProps {
  closeModal?: () => void;
  type: 'category' | 'application' | 'productType';
  data: (ProductCategory | Application | ProductType)[];
  title: string;
  className?: string;
  onClose?: () => void;
}

/**
 * search modal browse column
 * @param closeModal close modal
 * @param type type
 * @param data display data
 * @param title title
 * @param className classname
 */
function SearchModalBrowseColumn({
  closeModal,
  type,
  data,
  title,
  className,
  onClose,
}: PropsWithoutRef<SearchModalBrowseColumnProps>) {
  const intl = useIntl();
  const [page, setPage] = useState(1);
  const limit = page * 15;
  const pageEnd = limit > data.length;
  const displayableData = data.filter((item, index) => index < limit);

  // opened popup for category and application
  const [popup, setPopup] = useState<ReactElement | undefined>(undefined);
  const [popupTargetData, setPopupTargetData] = useState<
    ProductCategory | Application | undefined
  >(undefined);

  const mountService = useMemo(() => new MountService(), []);

  mountService.addDispatch('setPopupTargetData', setPopupTargetData);
  mountService.addDispatch('setPopup', setPopup);
  mountService.addDispatch('setPage', setPage);

  useLayoutEffect(() => {
    mountService.mount();

    return () => {
      mountService.unmount();
    };
  }, [mountService]);

  /**
   * wheel end handler
   */
  /* istanbul ignore next */
  const onWheelEnd = () => {
    mountService.callDispatch('setPage', page + 1);
  };

  /**
   * mouse hover event
   * @param element container element
   * @param data data
   */
  /* istanbul ignore next */
  const onHoverToName = (
    element: HTMLDivElement,
    data: ProductCategory | Application,
  ) => {
    if ((data.children?.length || 0) > 0) {
      const domRect = element.getBoundingClientRect();
      const bottomRemains = domRect.top + 290 < window.innerHeight;
      const childStyles: CSSProperties = {
        left: domRect.right + 'px',
      };

      // set position according to window view
      if (bottomRemains) {
        childStyles.top = domRect.top - 8 + 'px';
      } else {
        childStyles.bottom = window.innerHeight - domRect.bottom - 16 + 'px';
      }

      const linkUrl = (itemId: number) => {
        return type === 'category'
          ? `/search-result?from=browse&category=${data.id}&application=${itemId}`
          : `/search-result?from=browse&application=${data.id}&category=${itemId}`;
      };

      const popupComponent: ReactElement = (
        <BrowseChildren
          position={bottomRemains ? 'bottom' : 'top'}
          style={childStyles}
          title={data.name}
        >
          {(data.children || []).map((item, index) => {
            return (
              <BrowseItem
                onLinkClick={() => closeModal && closeModal()}
                showArrow={true}
                data={item}
                key={index}
                to={linkUrl(item.id)}
                name={item.name}
                description={intl.formatMessage(
                  {
                    id: 'product-counts-label',
                  },
                  {
                    count: item.products,
                  },
                )}
              />
            );
          })}
        </BrowseChildren>
      );

      mountService.callDispatch('setPopup', popupComponent);
      mountService.callDispatch('setPopupTargetData', data);
    }
  };

  /**
   * mouse leave event
   */
  /* istanbul ignore next */
  const onLeaveFromName = () => {
    mountService.callDispatch('setPopup', undefined);
    mountService.callDispatch('setPopupTargetData', undefined);
  };

  return (
    <SearchResultColumn
      onWheelEnd={onWheelEnd}
      className={className}
      hasNextPage={!pageEnd}
      title={title}
      onClose={onClose}
    >
      {displayableData.map((item, index) => {
        return (
          <BrowseItem
            onLinkClick={() => closeModal && closeModal()}
            popup={popupTargetData === item ? popup : undefined}
            data={item}
            to={`/search-result?from=browse&${type}=${item.id}`}
            key={index}
            name={item.name}
            className={styles.browseItem}
            onHoverToName={onHoverToName}
            onLeaveFromName={onLeaveFromName}
            description={intl.formatMessage(
              {
                id: 'product-counts-label',
              },
              {
                count: item.products,
              },
            )}
          />
        );
      })}
    </SearchResultColumn>
  );
}

export default SearchModalBrowseColumn;
