import { Banner, BannerData, BannerResponse } from './../models/banner';
import { apiInstance } from '../api/requestInterceptor';

interface GetBannerReqParams {
  page: number;
  perPage: number;
  sortBy: string;
  sortDirection: string;
}

/**
 * get banners
 */
async function getBanners({
  page = 0,
  perPage = 10,
  sortBy = 'name',
  sortDirection = 'asc',
}: GetBannerReqParams): Promise<BannerResponse> {
  const res = await apiInstance.get('/promotions/all', {
    params: {
      page,
      perPage,
      sortBy,
      sortDirection,
    },
  });

  return res.data;
}

/**
 * get banner by id
 */
async function getBannerById(id: number): Promise<Banner> {
  const res = await apiInstance.get(`/promotions/${id}`);
  return res.data;
}

/**
 * Create banner
 * @param data banner data
 */
async function createBanner(data: BannerData): Promise<void> {
  await apiInstance.post<any>('/promotions', data);
}

/**
 * activate banner
 * @param data activate banner data
 */
async function activateBanner(id: number): Promise<void> {
  await apiInstance.put<any>(`/promotions/${id}/activate`);
}

/**
 * update banner
 * @param data banner data
 */
async function deactivateBanner(id: number): Promise<void> {
  await apiInstance.put<any>(`/promotions/${id}/deactivate`);
}

/**
 * update banner
 * @param data banner data
 */
async function updateBanner(id: number, data: BannerData): Promise<void> {
  await apiInstance.put<any>(`/promotions/${id}`, data);
}

/**
 * delete banner
 * @param data banner id
 */
async function deleteBanner(id: number): Promise<void> {
  await apiInstance.delete<any>(`/promotions/${id}`);
}

export {
  getBanners,
  createBanner,
  updateBanner,
  activateBanner,
  deactivateBanner,
  getBannerById,
  deleteBanner,
};
