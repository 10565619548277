import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import Button from '../../../common/Button/Button';
import InputRow from '../../InputRow/InputRow';
import SelectFormRow from '../../SelectFormRow/SelectFormRow';
import AddProductModal from '../AddProductModal/AddProductModal';
import ProductList from '../ProductList/ProductList';

import {
  ManageStockpointFormProps,
  StockpointProductType,
  StockpointType,
} from '../../../../models/admin-form-types';
import { SearchResultProduct } from '../../../../models/search-result-product';

import { RootStore } from '../../../../store';

import { getSearchResults } from '../../../../services/ProductService';

import styles from './ManageStockpointForm.module.scss';
import { Link } from 'react-router-dom';
import AddPortModal from '../AddPortModal/AddPortModal';
import { getPorts } from '../../../../services/PortService';
import { SearchResultPort } from '../../../../models/search-result-port';
import { CountryItem } from '../../../../models/lookups';
import { getStockpoint } from '../../../../services/StockpointService';
import { hideLoading, showLoading } from '../../../../features/loadingSlice';
import { ReactComponent as Success } from '../../../../icons/check-circle-green.svg';
import CheckboxRow from '../../CheckboxRow/CheckboxRow';

const ManageStockpointForm = ({
  stockpoints,
  formValue,
  handleValueChange,
  reset,
  showMessage,
}: ManageStockpointFormProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { countries } = useSelector((state: RootStore) => state.lookups);
  const { sector } = useSelector((state: RootStore) => state.welcomeInfo);
  const [showAddProductModal, setShowAddProductModal] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState(
    formValue.stockpointProducts || [],
  );

  const [productFilter, setProductFilter] = useState('');
  const [products, setProducts] = useState<SearchResultProduct[]>([]);
  const [ports, setPorts] = useState<SearchResultPort[]>([]);
  const [showPorts, setShowPorts] = useState(false);
  const [changeCountry, SetChangeCountry] = useState<CountryItem | undefined>();

  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });

  const filterProducts = (
    prods: StockpointProductType[],
    prodFilter: string,
  ) => {
    let tempProducts = _.cloneDeep(prods);
    if (prodFilter) {
      tempProducts = tempProducts.filter((prod) =>
        prod.name?.toLowerCase()?.includes(prodFilter.toLowerCase()),
      );
    }

    setFilteredProducts(_.sortBy(tempProducts, 'name'));
  };

  useEffect(() => {
    filterProducts(formValue.stockpointProducts, productFilter);
  }, [formValue.stockpointProducts, productFilter]);

  useEffect(() => {
    if (changeCountry) {
      handleValueChange('ports', []);
      fetchProducts();
    }
  }, [changeCountry]);

  useEffect(() => {
    fetchPorts();
  }, []);

  const title = getTranslatedMessage('modify-products-label');
  const filterProductListPlaceholder = getTranslatedMessage(
    'enter-a-product-name-label',
  );

  const handleRemoveProduct = (productId: number) => {
    const tempProducts = _.cloneDeep(formValue.stockpointProducts);
    const index = tempProducts.findIndex(
      (prod: StockpointProductType) => prod.productId === productId,
    );
    tempProducts.splice(index, 1);
    handleValueChange('stockpointProducts', tempProducts);
    filterProducts(tempProducts, productFilter);
  };

  const handleSave = (newProduct: StockpointProductType) => {
    const newProducts = [...formValue.stockpointProducts, newProduct];
    handleValueChange('stockpointProducts', newProducts);
    filterProducts(newProducts, productFilter);
  };

  const handleCopy = (stockpoint: StockpointType) => {
    dispatch(showLoading());

    getStockpoint(stockpoint.id || 0)
      .then((res) => {
        const newProducts = res.stockPointProducts.filter(
          (x: StockpointProductType) =>
            products.find((prod) => prod.id === x.productId) &&
            !formValue.stockpointProducts.some(
              (y: StockpointProductType) => y.productId === x.productId,
            ),
        );
        newProducts.forEach((x: StockpointProductType, ind: number) => {
          x.id = products.length + ind;
          x.name = products.find((prod) => prod.id === x.productId)?.name;
        });
        handleValueChange('stockpointProducts', [
          ...formValue.stockpointProducts,
          ...newProducts,
        ]);
        filterProducts(newProducts, productFilter);
      })
      .finally(() => {
        dispatch(hideLoading());
      });
  };

  const handleProductCategoryChange = (productId: number, key: string) => {
    const tempProducts: any = _.cloneDeep(formValue.stockpointProducts);
    const index = tempProducts.findIndex(
      (prod: StockpointProductType) => prod.productId === productId,
    );
    tempProducts[index][key] = !tempProducts[index][key];

    handleValueChange('stockpointProducts', tempProducts);
    filterProducts(tempProducts, productFilter);
  };

  const fetchProducts = () => {
    dispatch(showLoading());
    const headers = {
      sector,
      culture: `en-${formValue.country.code}`,
    };
    getSearchResults(
      {
        onlyProducts: true,
      },
      undefined,
      headers,
    )
      .then((data) => {
        setProducts(data.products);
        let tempProducts = _.cloneDeep(formValue.stockpointProducts);
        tempProducts = tempProducts.filter((x: StockpointProductType) =>
          data.products?.find((prod) => prod.id === x.productId),
        );

        handleValueChange('stockpointProducts', tempProducts);
        filterProducts(tempProducts, productFilter);
      })
      .finally(() => {
        dispatch(hideLoading());
      });
  };

  const fetchPorts = () => {
    getPorts().then((data) => {
      setPorts(data);
    });
  };

  const handleAddNewProductBtnClick = () => {
    if (products.length === 0) {
      fetchProducts();
    }
    setShowAddProductModal(true);
  };

  const handleAddNewPortBtnClick = () => {
    if (ports.length === 0) {
      fetchPorts();
    }
    setShowPorts(true);
  };

  const getFilteredProducts = () => {
    const productIds = formValue.stockpointProducts.map(
      (stockpointProduct: any) => stockpointProduct.productId,
    );
    const productsToShow = products.filter(
      (product: any) => !productIds.includes(product.id),
    );

    return productsToShow;
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {title && <h4>{title}</h4>}{' '}
        <CheckboxRow
          label={getTranslatedMessage('active-label')}
          checked={formValue.stockpoint.active}
          onClick={() =>
            handleValueChange(
              'stockpoint.active',
              !formValue.stockpoint.active as any,
            )
          }
        />
      </div>
      {showMessage && (
        <div className={styles.messageContainer}>
          <Success className={styles.icon} />
          <div className={styles.wrapper}>
            <span className={styles.message}>
              {intl.formatMessage({
                id: 'stockpoint-success-message-1',
              })}
            </span>{' '}
            <a
              href="#"
              onClick={() => {
                reset ? reset() : null;
              }}
              className={styles.link}
            >
              {intl.formatMessage({
                id: 'stockpoint-success-message-2',
              })}
            </a>
          </div>
        </div>
      )}
      <div className={styles.content}>
        <InputRow
          labelId={`stockpoint-name-label`}
          value={formValue.stockpoint.name}
          onChange={(value: any) => handleValueChange('stockpoint.name', value)}
        />
        <SelectFormRow
          labelId="update-country-label"
          value={formValue.country}
          options={countries.map((country) => ({
            name: country.name,
            value: country,
          }))}
          onChange={(value: any) => {
            handleValueChange('country', value);
            SetChangeCountry(value);
          }}
          required={false}
        />
        <div className={styles.portList}>
          <div className={styles.colHeader}>
            {intl.formatMessage({ id: 'port-list' })}
          </div>
          {formValue.ports.map((port: any) => (
            <div key={port.id} className={styles.portRow}>
              <Link className={styles.link} to={`/port/${port.friendlyUrl}`}>
                {port.name}
              </Link>
              <div
                className={styles.link}
                onClick={() => {
                  handleValueChange(
                    'ports',
                    formValue.ports.filter((x: any) => x.id !== port.id),
                  );
                }}
              >
                {intl.formatMessage({
                  id: 'remove',
                })}
              </div>
            </div>
          ))}
        </div>
        <div className="columnRow">
          <Button
            color="green-outline"
            className={styles.addPort}
            onClick={() => handleAddNewPortBtnClick()}
          >
            <FormattedMessage id="header-menu-manage-ports-add-port" />
          </Button>
        </div>
        <div className="columnRow">
          <InputRow
            labelId="filter-product-list-label"
            value={productFilter}
            onChange={(value: string) => setProductFilter(value)}
            placeholder={filterProductListPlaceholder}
            required={false}
          />
          <Button
            color="green"
            className={styles.addNewProduct}
            onClick={handleAddNewProductBtnClick}
          >
            <FormattedMessage id="add-new-product-label" />
          </Button>
        </div>
        <ProductList
          products={filteredProducts}
          handleChange={handleProductCategoryChange}
          handleRemoveProduct={handleRemoveProduct}
        />
      </div>
      {showAddProductModal && (
        <AddProductModal
          stockpoints={stockpoints.filter(
            (x) => x.id !== formValue.stockpoint.id,
          )}
          products={getFilteredProducts()}
          onSave={handleSave}
          onCopy={handleCopy}
          onClose={() => setShowAddProductModal(false)}
        />
      )}
      {showPorts && (
        <AddPortModal
          ports={ports
            .filter((x) => x.countryId === formValue.country.id)
            .filter((x) => !formValue.ports.some((y: any) => y.id === x.id))}
          onSave={(port) => {
            handleValueChange('ports', [...formValue.ports, port]);
          }}
          onClose={() => setShowPorts(false)}
        />
      )}
    </div>
  );
};

export default ManageStockpointForm;
