import { PropsWithoutRef, ReactElement } from 'react';
import cn from 'classnames';
import styles from './InfoCard.module.scss';

interface InfoCardProps {
  heading: ReactElement<any, any>;
  body: ReactElement<any, any>;
  icon?: ReactElement<any, any>;
  noBoldHeader?: boolean;
}

function InfoCard({
  heading,
  body,
  icon,
  noBoldHeader,
}: PropsWithoutRef<InfoCardProps>) {
  return (
    <div className={styles.infoCard}>
      {icon && <div className={styles.iconContainer}>{icon}</div>}
      <div className={styles.infoContainer}>
        <div className={cn(styles.heading, noBoldHeader && styles.noBold)}>
          {heading}
        </div>
        <div className={styles.body}>{body}</div>
      </div>
    </div>
  );
}

export default InfoCard;
