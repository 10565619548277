import { Announcement } from './../models/announcement';
import { apiInstance } from '../api/requestInterceptor';
import { AnnouncementResponse } from '../models/announcement';

interface GetAnnouncementReqParams {
  page: number;
  perPage: number;
  title: string;
  countryIds: number[];
  active: boolean | null;
  announcementTypeIds: number | null;
  sortBy: string;
  sortDirection: string;
}

/**
 * get announcements
 */
async function getAnnouncements({
  page = 0,
  perPage = 10,
  title = '',
  countryIds = [],
  active,
  announcementTypeIds,
  sortBy = 'title',
  sortDirection = 'asc',
}: GetAnnouncementReqParams): Promise<AnnouncementResponse> {
  const res = await apiInstance.get('/announcements/all', {
    params: {
      page,
      perPage,
      title,
      announcementTypeIds,
      countryIds: countryIds.join(','),
      active,
      sortBy,
      sortDirection,
    },
  });

  return res.data;
}

/**
 * get announcement by id
 */
async function getAnnouncementById(id: number): Promise<Announcement> {
  const res = await apiInstance.get(`/announcements/${id}`);
  return res.data;
}

/**
 * Create announcement
 * @param data announcement data
 */
async function createAnnouncement(data: Announcement): Promise<void> {
  await apiInstance.post<any>('/announcements', data);
}

/**
 * update announcement
 * @param data announcement data
 */
async function updateAnnouncement(data: Announcement): Promise<void> {
  await apiInstance.put<any>(`/announcements/${data.id}`, data);
}

/**
 * delete announcement
 * @param data announcement id
 */
async function deleteAnnouncement(id: number): Promise<void> {
  await apiInstance.delete<any>(`/announcements/${id}`);
}

/**
 * activate announcements
 * @param data announcements banner data
 */
async function activateAnnouncement(id: number): Promise<void> {
  await apiInstance.put<any>(`/announcements/${id}/activate`);
}

/**
 * update announcements
 * @param data announcements data
 */
async function deactivateAnnouncement(id: number): Promise<void> {
  await apiInstance.put<any>(`/announcements/${id}/deactivate`);
}

/**
 * Get announcement countries
 * @param data announcement countries data
 */
async function getAnnouncementCountries(): Promise<number[]> {
  const res = await apiInstance.get('/announcements/all/countryFilter');
  return res.data;
}

export {
  getAnnouncements,
  getAnnouncementById,
  createAnnouncement,
  updateAnnouncement,
  deleteAnnouncement,
  activateAnnouncement,
  deactivateAnnouncement,
  getAnnouncementCountries,
};
