import Header from '../../../../../components/Admin/Header/Header';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './AddEditBanner.module.scss';
import { PreviousLink } from '../../../../../models/previous-link';
import { useEffect, useMemo, useState } from 'react';
import Button from '../../../../../components/common/Button/Button';
import InputRow from '../../../../../components/Admin/InputRow/InputRow';
import SelectFormRow from '../../../../../components/Admin/SelectFormRow/SelectFormRow';
import LoadingSpinner from '../../../../../components/common/LoadingSpinner/LoadingSpinner';
import { useDispatch, useSelector } from 'react-redux';
import { showGenericModal } from '../../../../../features/genericModalSlice';
import FileInputRow from '../../../../../components/Admin/FileInputRow/FileInputRow';
import { ReactComponent as PlusIcon } from '../../../../../icons/plus.svg';
import classNames from 'classnames';
import NavigationHeader from '../../../../../components/common/NavigationHeader/NavigationHeader';
import { uploadDocument } from '../../../../../services/DocumentService';
import { RootStore } from '../../../../../store';
import _ from 'lodash';
import { Banner, Button as BannerButton } from '../../../../../models/banner';
import {
  createBanner,
  getBannerById,
  updateBanner,
} from '../../../../../services/ManageBannerService';
import { addToast } from '../../../../../features/toastSlice';
import { useNavigate, useParams } from 'react-router';
import { validateURL } from '../../../../../utils/common.util';
import { ReactComponent as DeleteIcon } from '../../../../../icons/delete-gray.svg';
import { getUserInfoFromLocalStorage } from '../../../../../services/LocalStorageService';

export default function AddEditBanner() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userInfo = getUserInfoFromLocalStorage();
  const [file, setFile] = useState<File | null>(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [sector, setSector] = useState(-1);
  const [buttons, setButtons] = useState<BannerButton[]>([
    { name: '', value: '' },
  ]);
  const [processing, setProcessing] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const { bannerId } = useParams();
  const [isEdit, setIsEdit] = useState(false);
  const [banner, setBanner] = useState<Banner | null>(null);

  const { sectors } = useSelector((state: RootStore) => state.lookups);
  const sectorOptions = useMemo(() => {
    return sectors.map((sector) => ({
      name: sector.name,
      value: sector.id,
    }));
  }, [sectors]);

  useEffect(() => {
    if (bannerId) {
      setIsEdit(true);
      setLoading(true);
      getBannerById(+bannerId)
        .then((res: Banner) => {
          setBanner(res);
          setTitle(res.name);
          setDescription(res.description);
          setSector(res.sectorId);
          setButtons(res.buttons);
        })
        .catch(() => {
          dispatch(
            addToast({
              type: 'error',
              title: intl.formatMessage({ id: 'banner-load-failure' }),
              message: '',
            }),
          );
        })
        .finally(() => setLoading(false));
    }
  }, [bannerId]);

  const addButton = () => {
    setButtons((currButtons) => {
      const newButtons = _.cloneDeep(currButtons);
      newButtons.push({ name: '', value: '' });
      return newButtons;
    });
  };

  const updateButton = (
    index: number,
    key: keyof BannerButton,
    value: string,
  ) => {
    setButtons((currButtons) => {
      const newButtons = _.cloneDeep(currButtons);
      newButtons[index][key] = value;
      return newButtons;
    });
  };

  const deleteButton = (index: number) => {
    if (buttons.length > 1) {
      const newButtons = _.cloneDeep(buttons);
      newButtons.splice(index, 1);
      setButtons(newButtons);
    }
  };

  const uploadImage = (file: File): Promise<any> => {
    const formData = new FormData();
    formData.append('type', 'Banner');
    formData.append('file', file);

    return uploadDocument(formData);
  };

  const baseBreadcrumbLinks: PreviousLink[] = useMemo(
    () => [
      {
        name: intl.formatMessage({ id: 'breadcrumb-home' }),
        to: '/home',
      },
      {
        name: intl.formatMessage({
          id: 'header-menu-admin-manage-banners',
        }),
      },
    ],
    [intl],
  );

  const currentBreadcrumbLinks: PreviousLink[] = useMemo(() => {
    const updatedBreadcrumb = baseBreadcrumbLinks.map((b) => ({ ...b }));
    updatedBreadcrumb[1].to = '/admin/banners';
    updatedBreadcrumb.push({
      name: intl.formatMessage({
        id: isEdit ? 'edit-banner' : 'create-new-banner',
      }),
    });

    return updatedBreadcrumb;
  }, [intl, baseBreadcrumbLinks, isEdit]);

  const saveClicked = async () => {
    if (!isEdit && !file) return;
    try {
      setProcessing(true);

      let bannerImageUrl = banner?.bannerImageUrl || '';
      if (file) {
        const res = await uploadImage(file);
        bannerImageUrl = res.s3PublicUrl;
      }

      const data = {
        name: title,
        description: description,
        bannerImageUrl,
        buttons,
        sectorId: sector,
        displayIndex: 1,
      };

      if (bannerId) {
        await updateBanner(+bannerId, data);
      } else {
        await createBanner(data);
      }

      dispatch(
        showGenericModal({
          titleId: isEdit ? 'edit-success' : 'create-new-help-success',
          messageId: isEdit
            ? 'banner-update-success'
            : 'new-banner-save-success',
          to: `/admin/banners`,
        }),
      );
    } catch (e) {
      dispatch(
        addToast({
          type: 'error',
          title: intl.formatMessage({
            id: isEdit ? 'banner-update-failure' : 'banner-create-failure',
          }),
          message: '',
        }),
      );
    } finally {
      setProcessing(false);
    }
  };

  const isValidForm = () => {
    if ((!isEdit && _.isEmpty(file)) || sector < 0) {
      return false;
    }

    return true;
  };

  return (
    <>
      <div className={styles.desktopOnly}>
        <Header
          titleId={isEdit ? 'edit-banner' : 'add-banner-title'}
          breadcrumbLinks={currentBreadcrumbLinks}
        />
      </div>
      <div className={styles.phoneOnly}>
        <NavigationHeader breadcrumbLinks={baseBreadcrumbLinks} />
      </div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className={styles.contentContainer}>
          <div className={styles.formWrapper}>
            <div className={styles.formHeader}>
              <div className={styles.message}>
                <FormattedMessage id={'required-field-msg'} />
              </div>
            </div>
            <div
              className={classNames(
                styles.sectionHeader,
                styles.topSectionHeader,
              )}
            >
              <FormattedMessage id="upload-banner-image" />
            </div>
            <div className={classNames(styles.parentWidth, 'columnRow')}>
              <FileInputRow
                labelId="select-image-label"
                required={true}
                tooltip={intl.formatMessage({ id: 'select-image-label' })}
                accept="image/*"
                file={file}
                onChange={setFile}
                addedFileName={banner?.bannerImageUrl || file?.name}
              />
            </div>
            <div className={styles.sectionHeader}>
              <FormattedMessage id="title-and-description" />
            </div>
            <div className="columnRow">
              <InputRow
                labelId="banner-title"
                value={title}
                required={false}
                onChange={setTitle}
                tooltip={intl.formatMessage({ id: 'banner-title' })}
              />
              <InputRow
                labelId="description-label"
                value={description}
                required={false}
                onChange={setDescription}
                tooltip={intl.formatMessage({ id: 'description-label' })}
              />
            </div>
            <div
              className={classNames(styles.sectionHeader, styles.sectionBtn)}
            >
              <FormattedMessage id="button-label" />
              <span className={styles.link} onClick={addButton}>
                <PlusIcon />
                <FormattedMessage id={'add-button'} />
              </span>
            </div>
            {buttons.map((button, index) => {
              return (
                <div key={index} className={'columnRow'}>
                  <InputRow
                    labelId={'button-url-label'}
                    placeholder={'https://'}
                    value={button.value}
                    onChange={(value: string) =>
                      updateButton(index, 'value', value)
                    }
                    required={false}
                    customValidator={(value: string) => validateURL(value)}
                    tooltip={intl.formatMessage({ id: 'button-url-label' })}
                  />
                  <InputRow
                    labelId={'button-header-text'}
                    value={button.name}
                    onChange={(value: string) =>
                      updateButton(index, 'name', value)
                    }
                    required={false}
                    tooltip={intl.formatMessage({ id: 'button-header-text' })}
                  />
                  {buttons.length > 1 && (
                    <div
                      className={styles.deleteBtn}
                      onClick={() => deleteButton(index)}
                    >
                      <DeleteIcon className={styles.deleteIconBtn} />
                      <FormattedMessage id={'delete-label'} />
                    </div>
                  )}
                </div>
              );
            })}
            <div className="columnRow">
              <SelectFormRow
                labelId="sector-label"
                tooltip={intl.formatMessage({ id: 'sector-label' })}
                options={sectorOptions}
                value={sector}
                onChange={setSector}
              />
            </div>
          </div>

          <div className={styles.actionRow}>
            <Button
              onClick={() => navigate('/admin/banners')}
              color={'green-outline'}
              className={styles.cancelButton}
            >
              <FormattedMessage id={'cancel-label'} />
            </Button>

            <Button
              onClick={saveClicked}
              color={'green'}
              disabled={!isValidForm()}
              className={styles.saveButton}
            >
              <FormattedMessage id={'save-label'} />
            </Button>
          </div>
        </div>
      )}
      {processing && <LoadingSpinner className={'lookupsLoading'} />}
    </>
  );
}
