import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import AddCaseStudyForm from './components/AddCaseStudyForm/AddCaseStudyForm';
import Header from '../../../../components/Admin/Header/Header';
import { PreviousLink } from '../../../../models/previous-link';
import styles from './AddCaseStudyPage.module.scss';
import { uploadDocument } from '../../../../services/DocumentService';
import { createCaseStudy } from '../../../../services/CaseStudyService';
import { CaseStudy } from '../../../../models/case-study';
import { showGenericModal } from '../../../../features/genericModalSlice';
import { addToast } from '../../../../features/toastSlice';
import { getProductInfoFromSessionStorage } from '../../../../services/LocalStorageService';
import { hideLoading, showLoading } from '../../../../features/loadingSlice';
import { trimSpacesInObj } from '../../../../utils/common.util';
import withAdmin from '../../../../hoc/withAdmin';
import { useNavigate } from 'react-router';
import { RootStore } from '../../../../store';

function AddCaseStudyPage() {
  const dispatch = useDispatch();
  const [title, setTitle] = useState('');
  const [caseStudyApplications, setApplications1] = useState<any[]>([]);
  const [caseStudySubApplications, setSubApplications1] = useState<any[]>([]);
  const [caseStudySectors, setSectors1] = useState<any[]>([]);
  const [caseStudySubSectors, setSubSectors1] = useState<any[]>([]);
  const [caseStudycountries, setCaseStudycountries1] = useState<any[]>([]);
  const [caseStudyBenefits, setCaseStudyBenefits1] = useState<any[]>([]);
  const [customer, setCustomer] = useState('');
  const [machine, setMachine] = useState('');
  const [location, setLocation] = useState('');
  const [competitor, setCompetitor] = useState('');
  const [visibility, setVisibility] = useState<number[]>([]);
  const [file, setFile] = useState();
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
  const [documentId, setDocumentId] = useState<number>();
  const intl = useIntl();
  const navigate = useNavigate();

  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });
  const {
    applications,
    subApplications,
    sectors,
    subSectors,
    countries,
    benefits,
  } = useSelector((state: RootStore) => state.lookups);

  useEffect(() => {
    if (file) {
      const formData = new FormData();
      formData.append('type', 'CaseStudy');
      formData.append('file', file);

      setDocumentId(undefined);
      uploadDocument(formData).then((res) => {
        setDocumentId(res.id);
      });
    }
  }, [file]);
  useEffect(() => {
    if (title !== '') {
      setShowUnsavedChangesModal(true);
    } else {
      setShowUnsavedChangesModal(false);
    }
  }, [title]);

  const breadcrumbLinks: PreviousLink[] = [
    {
      name: 'Home',
      to: '/home',
    },
    {
      name: intl.formatMessage({
        id: 'header-add-casestudy',
      }),
    },
  ];

  const enableSave = Boolean(
    title.trim() &&
      // customer.trim() &&
      // machine.trim() &&
      // location.trim() && // renamed to comments
      // competitor.trim() && // rename to “Previous product in use” and make voluntary.// non mandatory
      // visibility.length > 0 &&
      caseStudyApplications.length > 0 &&
      caseStudySectors.length > 0 &&
      caseStudySubSectors.length > 0 &&
      documentId, //for testing need to uncomment
  );

  const handleSave = () => {
    const productInfo = getProductInfoFromSessionStorage();
    if (documentId && productInfo?.product.id) {
      let payload: CaseStudy = {
        productId: productInfo?.product.id,
        title,
        customer,
        machine,
        location, // renamed to comments // non mandatory
        competitor, // rename to “Previous product in use” and make voluntary.// non mandatory
        visibleToAnonymousUsers: visibility.includes(1),
        visibleToDistributorUsers: visibility.includes(2),
        visibleToInternalAndApproverUsers: visibility.includes(3),
        documentId,
        caseStudyApplications,
        caseStudySubApplications,
        caseStudySectors,
        caseStudySubSectors,
        caseStudycountries,
        caseStudyBenefits,
      };
      console.log(payload);
      payload = trimSpacesInObj(payload);

      dispatch(showLoading());
      createCaseStudy(payload)
        .then((data) => {
          dispatch(
            showGenericModal({
              titleId: 'casestudy-create-success',
              messageId: 'casestudy-create-success-label',
              to: `/product/${data.product.friendlyUrl}`,
            }),
          );
        })
        .catch(() => {
          dispatch(
            addToast({
              type: 'error',
              title: getTranslatedMessage('casestudy-create-failure-label'),
              message: '',
            }),
          );
        })
        .finally(() => {
          dispatch(hideLoading());
        });
    }
  };
  const handleCancelBtnClick = () => {
    if (showUnsavedChangesModal) {
      dispatch(
        showGenericModal({
          type: 'confirmation',
          titleId: 'unsaved-changes-label',
          messageId: 'unsaved-changes-message',
          to: '/',
        }),
      );
    } else {
      navigate('/');
    }
  };
  return (
    <div className={styles.container}>
      <Header
        breadcrumbLinks={breadcrumbLinks}
        titleId={'header-add-casestudy'}
      />
      <div className={styles.body}>
        <AddCaseStudyForm
          title={title}
          setTitle={setTitle}
          applications={applications}
          subApplications={subApplications}
          sectors={sectors}
          subSectors={subSectors}
          countries={countries}
          benefits={benefits}
          applications1={caseStudyApplications}
          subApplications1={caseStudySubApplications}
          sectors1={caseStudySectors}
          subSectors1={caseStudySubSectors}
          countries1={caseStudycountries}
          benefits1={caseStudyBenefits}
          setApplications1={setApplications1}
          setSubApplications1={setSubApplications1}
          setSectors1={setSectors1}
          setSubSectors1={setSubSectors1}
          setCaseStudycountries1={setCaseStudycountries1}
          setCaseStudyBenefits1={setCaseStudyBenefits1}
          customer={customer}
          setCustomer={setCustomer}
          machine={machine}
          setMachine={setMachine}
          location={location}
          setLocation={setLocation}
          competitor={competitor}
          setCompetitor={setCompetitor}
          visibility={visibility}
          setVisibility={setVisibility}
          setFile={setFile}
          enableSave={enableSave}
          handleSave={handleSave}
          handleCancelBtnClick={handleCancelBtnClick}
        />
      </div>
    </div>
  );
}

export default withAdmin(AddCaseStudyPage);
