import { useIntl } from 'react-intl';
import FormRow from '../FormRow/FormRow';
import { InputRowType } from '../../../models/admin-form-types';
import styles from './DateField.module.scss';
import DatePicker from '../DatePicker/DatePicker';
import classNames from 'classnames';

const DateField = ({
  labelId,
  value,
  onChange,
  placeholder,
  required,
  tooltip,
  className,
  rowClassName,
}: InputRowType) => {
  const intl = useIntl();
  placeholder = placeholder || intl.formatMessage({ id: 'input-placeholder' });

  return (
    <FormRow
      labelId={labelId}
      required={required}
      tooltip={tooltip}
      className={classNames(styles.dateField, rowClassName)}
    >
      <DatePicker
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={classNames(styles.datePicker, className)}
      />
    </FormRow>
  );
};

export default DateField;
