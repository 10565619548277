import _ from 'lodash';

import { EmailPhoneFaxOthersType } from '../models/order-contacts';

import config from '../configs/config';

/**
 * Validate data on given keys on the object
 * @param keys array of string keys present in object to validate
 * @param formValue the object
 * @param ignoreNames the key collection which name can be ignored if not provided.
 * @returns true or false depending on whether data is valid
 */
export const validateData = (
  keys: string[],
  formValue: any,
  ignoreNames?: string[],
) => {
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const fieldItems = _.get(formValue, key);
    const isEmail = key === 'emails';
    // We skip the last item because both the fields are allowed to be empty for that item
    for (let j = 0; j < fieldItems.length - 1; j++) {
      if (
        !fieldItems[j].name.trim() ||
        !fieldItems[j].value.trim() ||
        (isEmail && !config.EMAIL_REGEX.test(fieldItems[j].value.toLowerCase()))
      ) {
        return false;
      }
    }
    const lastItemIndex = fieldItems.length - 1;

    if (
      lastItemIndex >= 0 &&
      ignoreNames &&
      ignoreNames?.includes(key) &&
      fieldItems[lastItemIndex].value.trim()
    ) {
      // We check whether after removing trailing spaces, whether key exists in ignore hash
      // If exists and value is invalid, then the form is invalid
      continue;
    }

    // We check whether after removing trailing spaces, either of the field has value, then the form is invalid
    // If both the fields are empty, then no issues
    if (
      lastItemIndex >= 0 &&
      ((fieldItems[lastItemIndex].name.trim() &&
        !fieldItems[lastItemIndex].value.trim()) ||
        (!fieldItems[lastItemIndex].name.trim() &&
          fieldItems[lastItemIndex].value.trim()) ||
        (isEmail &&
          fieldItems[lastItemIndex].value &&
          !config.EMAIL_REGEX.test(
            fieldItems[lastItemIndex].value.toLowerCase(),
          )))
    ) {
      return false;
    }
  }

  return true;
};

/**
 * Remove object which has all of its values empty
 * @param keys an array of strings of keys to check against
 * @param payload the object
 * @returns filtered object
 */
export const removeEmptyFieldItem = (keys: string[], payload: any) => {
  keys.map((key) => {
    const item = _.get(payload, key);
    const index = item.findIndex(
      (obj: EmailPhoneFaxOthersType) => !obj.name && !obj.value,
    );
    if (index >= 0) {
      item.splice(index, 1);
    }
  });

  return payload;
};
