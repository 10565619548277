import axios, { CancelTokenSource } from 'axios';
import { cloneDeep } from 'lodash';
import debounce from 'lodash.debounce';
import { useEffect, useState, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams, useNavigate } from 'react-router-dom';
import ContainerForDrawer from '../../../components/common/ContainerForDrawer/ContainerForDrawer';
import FilterContainer from '../../../components/common/FilterContainer/FilterContainer';
import FilterGroup from '../../../components/common/FilterGroup/FilterGroup';
import Input from '../../../components/common/Input/Input';
import LeftDrawer from '../../../components/common/LeftDrawer/LeftDrawer';
import LoadingSpinner from '../../../components/common/LoadingSpinner/LoadingSpinner';
import NavigationHeader from '../../../components/common/NavigationHeader/NavigationHeader';
import OemTable from '../../../components/common/OemTable/OemTable';
import RightContainer from '../../../components/common/RightContainer/RightContainer';
import SearchFilter from '../../../components/common/SearchFilter/SearchFilter';
import SearchModal from '../../../components/HomePage/SearchModal/SearchModal';
import SearchResultHeader from '../../../components/SearchResultPage/SearchResultHeader/SearchResultHeader';
import {
  clearAllFilters,
  clearFilter,
  setFilterableOemSearchResult,
  toggleFilter,
} from '../../../features/oemFilterSlice';
import { addToast } from '../../../features/toastSlice';
import { PreviousLink } from '../../../models/previous-link';
import { RootStore } from '../../../store';
import styles from './OemSearchResultPage.module.scss';
import { searchOems } from '../../../services/OemService';
import { SearchOemResponse } from '../../../models/oem';
import withAdmin from '../../../hoc/withAdmin';

function OemSearchResultPage() {
  // init hooks
  const [query] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();
  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });

  // init local variable
  const queryName = query.get('name') || '';

  // breadcrumb links
  const breadcrumbLinks: PreviousLink[] = [
    {
      name: 'Home',
      to: '/home',
    },
    {
      name: 'Search Results',
    },
  ];

  const { filters, selectedFilters, filteredOems } = useSelector(
    (state: RootStore) => state.oemFilter,
  );

  const { oemApprovalTypes, sectors, subSectors } = useSelector(
    (state: RootStore) => state.lookups,
  );

  const { hasWelcomeInfo } = useSelector(
    (state: RootStore) => state.welcomeInfo,
  );

  // init state.
  const [previousSource, setPreviousSource] = useState<CancelTokenSource>();
  const [searchOpened, setSearchOpened] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [loading, setLoading] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [oemNameFilter, setOemNameFilter] = useState<string>('');

  const [oems, setOems] = useState<SearchOemResponse | null>(null);

  // local functions

  /**
   * close search modal
   */
  const closeSearchModal = () => {
    setSearchOpened(false);
  };

  /**
   * open search modal
   */
  const openSearchModal = () => {
    setSearchOpened(true);
  };

  // effects

  /**
   * update port result bu lookup
   */
  useEffect(() => {
    if (
      oemApprovalTypes &&
      oemApprovalTypes.length > 0 &&
      sectors &&
      sectors.length > 0 &&
      subSectors &&
      subSectors.length > 0 &&
      oems
    ) {
      dispatch(
        setFilterableOemSearchResult({
          oemResponse: cloneDeep(oems),
          sectors,
          subSectors,
          oemApprovalTypes,
        }),
      );
    }
  }, [dispatch, oems, oemApprovalTypes]);

  /**
   * get ports when lookup value and welcome data available.
   */
  useEffect(() => {
    if (previousSource) {
      previousSource.cancel('cancel');
    }
    const source = axios.CancelToken.source();

    if (hasWelcomeInfo) {
      if (initialLoad) {
        dispatch(clearAllFilters());
      }
      setLoading(true);

      searchOems(queryName)
        .then((res) => {
          dispatch(clearAllFilters());
          setLoading(true);
          setOemNameFilter(queryName);
          setInitialLoad(false);
          setOems(res);
          dispatch(
            setFilterableOemSearchResult({
              oemResponse: {
                page: res.page,
                perPage: res.perPage,
                total: res.total,
                totalPages: res.totalPages,
                oems: [],
                products: [],
              },
              sectors: [],
              subSectors: [],
              oemApprovalTypes: [],
            }),
          );
          dispatch(
            toggleFilter({
              key: 'oemName',
              filter: queryName,
            }),
          );
        })
        .catch(() => {
          dispatch(
            addToast({
              type: 'error',
              title: getTranslatedMessage('oem-search-failure'),
              message: '',
            }),
          );
        })
        .finally(() => setLoading(false));
    }

    setPreviousSource(source);
  }, [dispatch, queryName, hasWelcomeInfo]);

  const changeHandler = (value: string) => {
    navigate(`/admin/oem-results?name=${value}`);
  };

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 500),
    [],
  );

  return (
    <div>
      <NavigationHeader
        onInputFocus={() => openSearchModal()}
        breadcrumbLinks={breadcrumbLinks}
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        showFilterIcon={true}
      />
      <ContainerForDrawer
        classes={openFilter ? styles.filterOpen : styles.filterClose}
      >
        <LeftDrawer>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <SearchFilter clear={() => dispatch(clearAllFilters())}>
              <FilterContainer
                title={getTranslatedMessage('oem-name-filter')}
                className={styles.filterGroup}
              >
                <Input
                  value={oemNameFilter}
                  onChange={(_value: string) => {
                    setOemNameFilter(_value);
                    debouncedChangeHandler(_value);
                  }}
                  placeholder={getTranslatedMessage('oem-name-placeholder')}
                />
              </FilterContainer>
              <FilterGroup
                filters={filters.oemApprovalTypes}
                onChange={(filter) => {
                  dispatch(
                    toggleFilter({
                      key: 'oemApprovalTypes',
                      filter,
                    }),
                  );
                }}
                onReset={() => {
                  dispatch(
                    clearFilter({
                      key: 'oemApprovalTypes',
                    }),
                  );
                }}
                selectedOptions={selectedFilters.oemApprovalTypes}
                title={intl.formatMessage({ id: 'approval-type-filter' })}
                open={true}
                className={styles.filterGroup}
              />
            </SearchFilter>
          )}
        </LeftDrawer>
        <RightContainer>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <div>
              <SearchResultHeader
                count={filteredOems.length || 0}
                search={queryName}
              />
              {filteredOems && filteredOems.length > 0 && (
                <OemTable oems={filteredOems || []} />
              )}
            </div>
          )}
        </RightContainer>
      </ContainerForDrawer>
      {searchOpened && <SearchModal onClose={() => closeSearchModal()} />}
    </div>
  );
}

export default withAdmin(OemSearchResultPage);
