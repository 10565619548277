import { FormattedMessage } from 'react-intl';
import styles from './Visibility.module.scss';
import { ReactComponent as Help } from '../../../../../../../icons/help-green.svg';
import CheckboxTree from '../../../../../../../components/common/CheckboxTree/CheckboxTree';
import { useMemo, useState } from 'react';
import Button from '../../../../../../../components/common/Button/Button';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../../../../../store';
import { CountryItem } from '../../../../../../../models/lookups';
import {
  Announcement,
  VisibilityCountryItem,
} from '../../../../../../../models/announcement';

interface VisibilityProps {
  onNext: (visibilities: VisibilityCountryItem[]) => void;
  onBack: () => void;
  announcement: Announcement;
}

export default function Visibility({
  onNext,
  onBack,
  announcement,
}: VisibilityProps) {
  const [selectedCountries, setSelectedCountries] = useState<number[]>(
    announcement.announcementVisibilityCountries?.map(
      (item) => item.countryId,
    ) || [],
  );
  const { countries, regions } = useSelector(
    (state: RootStore) => state.lookups,
  );

  const countryTree = useMemo(() => {
    const countriesByRegion = new Map<number, CountryItem[]>();
    countries.forEach((country) => {
      if (!countriesByRegion.has(country.regionId)) {
        countriesByRegion.set(country.regionId, []);
      }
      countriesByRegion.get(country.regionId)?.push(country);
    });
    return regions.map((region) => ({
      value: region.id,
      label: region.name,
      children: (countriesByRegion.get(region.id) || []).map((country) => ({
        value: country.id,
        label: country.name,
      })),
    }));
  }, [countries, regions]);

  const onVisibilityNext = () => {
    onNext(selectedCountries.map((id) => ({ countryId: id })));
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.formWrapper}>
          <div className={styles.header}>
            <div className={styles.labelWrapper}>
              <div className={styles.fieldLabelWrapper}>
                <span className={styles.fieldLabel}>
                  <FormattedMessage id={'regions-label'} />
                </span>
                <span className={styles.requiredIndicator}>*</span>
              </div>
              <Help data-tip data-for={'regions-label'} />
            </div>
            <div className={styles.titleInfo}>
              <FormattedMessage id={'select-announcement-region-msg'} />
            </div>
          </div>
          <div className={styles.body}>
            <CheckboxTree
              className={styles.countriesTree}
              tree={countryTree}
              selected={selectedCountries}
              setSelected={setSelectedCountries}
            />
          </div>
        </div>
      </div>
      <div className={styles.actionRow}>
        <Button
          onClick={onBack}
          color={'green-outline'}
          className={styles.cancelButton}
        >
          <FormattedMessage id={'cancel-label'} />
        </Button>
        <Button
          onClick={onVisibilityNext}
          color={'green'}
          disabled={selectedCountries.length === 0}
          className={styles.nextButton}
        >
          <FormattedMessage id={'next-label'} />
        </Button>
      </div>
    </>
  );
}
