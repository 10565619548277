import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';

import { StepperItemType } from '../../../models/admin-form-types';

import { ReactComponent as Success } from '../../../icons/check-circle-green.svg';
import { ReactComponent as SuccessPink } from '../../../icons/check-circle-pink.svg';

import styles from './Stepper.module.scss';

type StepperType = {
  isEdit?: boolean;
  items: StepperItemType;
  // if the active step has children, pass array. So if step 1.2 is active, active will be `[1, 2]`
  active: number | number[];
  handleClick: (label: string, depth: number, parentLabel?: string) => void;
};

const Stepper = ({
  isEdit = false,
  items,
  active,
  children,
  handleClick,
}: PropsWithChildren<StepperType>) => {
  /**
   * Get current active number of step by level
   * @param level leve index of the steps, 0 or 1
   * @returns the current active number of step
   */
  const getActiveStepByLevel = (level: number) => {
    const activeArray = typeof active === 'number' ? [active] : active;
    return activeArray[level];
  };

  const getLevel0Indicator = (index: number) => {
    if (isEdit && getActiveStepByLevel(0) === index) {
      return <SuccessPink className={styles.SuccessIcon} />;
    }
    if (getActiveStepByLevel(0) > index || isEdit) {
      return <Success className={styles.SuccessIcon} />;
    }

    return <div className={styles.indicator}>{index + 1}</div>;
  };

  return (
    <div className={styles.container}>
      {items.map((item, index) => {
        const level1Open = getActiveStepByLevel(0) === index;
        const isLastLevel1Item = index < items.length - 1;
        return (
          <React.Fragment key={item.label}>
            <div
              className={classNames(
                styles.step,
                level1Open ? styles.active : '',
                !level1Open && !isLastLevel1Item && styles.divider,
              )}
              key={item.label}
              onClick={() => handleClick(item.label, 0)}
            >
              <div className={styles.stepItem}>
                {getLevel0Indicator(index)}
                <span>{item.label}</span>
              </div>
              {level1Open && item.children && (
                <div className={styles.subStepsContainer}>
                  {item.children?.map((child, childIndex) => {
                    const isOnGoing = getActiveStepByLevel(1) === childIndex;
                    const isPast = getActiveStepByLevel(1) > childIndex;
                    const isTodo = getActiveStepByLevel(1) < childIndex;
                    return (
                      <div
                        className={classNames(
                          styles.subStep,
                          isOnGoing && styles.ongoing,
                          isPast && styles.past,
                          isTodo && styles.todo,
                          isEdit && styles.isEdit,
                        )}
                        key={childIndex}
                        onClick={(event) => {
                          handleClick(child.label, 1, item.label);
                          event.stopPropagation();
                        }}
                      >
                        {isEdit ? (
                          isOnGoing ? (
                            <SuccessPink className={styles.indicator} />
                          ) : (
                            <Success className={styles.indicator} />
                          )
                        ) : isPast ? (
                          <Success className={styles.indicator} />
                        ) : (
                          <div className={styles.indicator}></div>
                        )}
                        <span>{child.label}</span>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>

            {level1Open && (
              <div className={styles.phoneContainer}>{children}</div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default Stepper;
