import { useState, useMemo, Fragment } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import cn from 'classnames';
import jwt from 'jsonwebtoken';
import styles from './ResetUserPasswordPage.module.scss';

import { resetPassword } from '../../services/UserService';
import { hideLoading, showLoading } from '../../features/loadingSlice';
import { addToast } from '../../features/toastSlice';

import Button from '../../components/common/Button/Button';
import InputRow from '../../components/Admin/InputRow/InputRow';
import { ReactComponent as KeyIcon } from '../../icons/key.svg';
import { ReactComponent as CheckCircleIcon } from '../../icons/check-circle.svg';
import FormError from '../../components/common/FormError/FormError';

/**
 * Reset password page component
 */
function ResetUserPasswordPage() {
  // init hooks
  const intl = useIntl();
  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const token = query.get('token') || '';

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [step, setStep] = useState(0);

  /* istanbul ignore next */
  const userName = useMemo(() => {
    if (token) {
      const tokenObj: any = jwt.decode(token);
      const userEmail: string = tokenObj?.email;
      return userEmail && userEmail.split('@')[0];
    } else {
      return null;
    }
  }, [token]);

  const changePassword = () => {
    dispatch(showLoading());
    resetPassword(token, password)
      .then(() => {
        setStep(1);
      })
      .catch(() => {
        dispatch(
          addToast({
            type: 'error',
            title: getTranslatedMessage('reset-password-failure'),
            message: '',
          }),
        );
      })
      .finally(() => dispatch(hideLoading()));
  };

  /**
   * password validator
   * @param value value
   */
  const passwordValidator = (value: string): boolean => {
    if (!value) {
      return true;
    }
    if (value.length < 8) {
      return true;
    }
    if (!/[A-Z]/.test(value)) {
      return true;
    }
    if (!/[a-z]/.test(value)) {
      return true;
    }
    if (userName && value.toLowerCase().indexOf(userName.toLowerCase()) > -1) {
      return true;
    }

    return false;
  };

  const differentPasswords = (): boolean => {
    return !!password && !!confirmPassword && password !== confirmPassword;
  };

  const samePasswords = (): boolean => {
    return !!password && !!confirmPassword && password === confirmPassword;
  };

  return (
    <div className={styles.container}>
      {step === 0 && (
        <Fragment>
          <div className={styles.title}>
            <KeyIcon className={styles.keyIcon} />
            <FormattedMessage id={'reset-your-password-label'} />
          </div>
          <div className={styles.description}>
            <FormattedMessage id={'reset-your-password-desc'} />
          </div>
          <div className={styles.passwordRule}>
            <FormattedMessage id={'reset-your-password-rule1'} />
          </div>
          <div className={styles.passwordRule}>
            <FormattedMessage id={'reset-your-password-rule2'} />
          </div>
          <div className={styles.passwordRule}>
            <FormattedMessage id={'reset-your-password-rule3'} />
          </div>
          <div className={styles.passwordRule}>
            <FormattedMessage id={'reset-your-password-rule4'} />
          </div>
          <div className={styles.divider} />

          <div className={'columnRow'}>
            <InputRow
              labelId={'new-password-label'}
              className={styles.passwordInput}
              inputClassName={cn(
                differentPasswords() && styles.inputError,
                !passwordValidator(password) &&
                  samePasswords() &&
                  styles.inputOK,
              )}
              value={password}
              required={true}
              isPassword={true}
              onChange={(value: string) => setPassword(value)}
              customValidator={(value: string) => passwordValidator(value)}
            />
          </div>

          <div className={'columnRow'}>
            <InputRow
              labelId={'confirm-new-password-label'}
              className={styles.passwordInput}
              inputClassName={cn(
                differentPasswords() && styles.inputError,
                !passwordValidator(confirmPassword) &&
                  samePasswords() &&
                  styles.inputOK,
              )}
              value={confirmPassword}
              required={true}
              isPassword={true}
              onChange={(value: string) => setConfirmPassword(value)}
              customValidator={(value: string) => passwordValidator(value)}
            />
          </div>
          {differentPasswords() && (
            <FormError className={styles.error}>
              <FormattedMessage id={'password-not-same-error'} />
            </FormError>
          )}

          <div className={styles.buttonContainer}>
            <Button
              onClick={() => changePassword()}
              color={'green'}
              className={cn(styles.button, styles.changePwdBtn)}
              disabled={
                passwordValidator(password) ||
                passwordValidator(confirmPassword) ||
                differentPasswords()
              }
            >
              <FormattedMessage id={'change-password-label'} />
            </Button>
          </div>
        </Fragment>
      )}
      {step === 1 && (
        <Fragment>
          <div className={styles.title}>
            <CheckCircleIcon className={styles.checkCircleIcon} />
            <FormattedMessage id={'password-updated-label'} />
          </div>
          <div className={styles.passwordRule}>
            <FormattedMessage id={'password-updated-desc'} />
          </div>
          <div className={cn(styles.divider, styles.marginBoth)} />

          <div className={styles.buttonContainer}>
            <Button
              onClick={() => navigate('/home')}
              color={'green'}
              className={cn(styles.button, styles.loginBtn)}
            >
              <FormattedMessage id={'login-label'} />
            </Button>
          </div>
        </Fragment>
      )}
    </div>
  );
}

export default ResetUserPasswordPage;
