import { configureStore } from '@reduxjs/toolkit';
import productComparisonReducer, {
  ProductComparisonSliceState,
} from './features/productComparisonSlice';
import productFilterReducer, {
  ProductFilterSliceState,
} from './features/productFilterSlice';
import welcomeInfoReducer, {
  WelcomeInfoSliceState,
} from './features/welcomeInfoSlice';
import toastReducer, { ToastSliceState } from './features/toastSlice';
import portFilterSlice, {
  PortFilterSliceState,
} from './features/portFilterSlice';
import lookupsSliceReducer, {
  LookupsSliceState,
} from './features/lookupsSlice';
import browseProductSliceReducer, {
  BrowseProductSliceState,
} from './features/browseProductSlice';
import familyFilterSlice, {
  FamilyFilterSliceState,
} from './features/familyFilterSlice';
import contactUsSliceReducer, {
  ContactUsSliceState,
} from './features/contactUsSlice';
import loginSliceReducer, { LoginSliceState } from './features/loginSlice';
import loadingSliceReducer, {
  LoadingSliceState,
} from './features/loadingSlice';
import genericModalSliceReducer, {
  GenericModalSliceState,
} from './features/genericModalSlice';
import rangeDetailsSliceReducer, {
  RangeDetailsSliceState,
} from './features/rangeDetailsSlice';
import oemSliceReducer, {
  OemFilterSliceState,
} from './features/oemFilterSlice';
import oemReducer, { OemSliceState } from './features/oemSlice';
import productDetailsSlice, {
  ProductDetailsState,
} from './features/productDetailsSlice';
import userFilterReducer, {
  UserFilterSliceState,
} from './features/userFilterSlice';
import portDetailsSlice, {
  PortDetailsState,
} from './features/portDetailsSlice';

export interface RootStore {
  productComparison: ProductComparisonSliceState;
  productFilter: ProductFilterSliceState;
  familyFilter: FamilyFilterSliceState;
  portFilter: PortFilterSliceState;
  oemFilter: OemFilterSliceState;
  oem: OemSliceState;
  welcomeInfo: WelcomeInfoSliceState;
  toast: ToastSliceState;
  lookups: LookupsSliceState;
  browseProduct: BrowseProductSliceState;
  contactUs: ContactUsSliceState;
  login: LoginSliceState;
  loading: LoadingSliceState;
  genericModal: GenericModalSliceState;
  rangeDetails: RangeDetailsSliceState;
  productDetails: ProductDetailsState;
  userFilter: UserFilterSliceState;
  portDetails: PortDetailsState;
}

export default configureStore({
  reducer: {
    productComparison: productComparisonReducer,
    productFilter: productFilterReducer,
    welcomeInfo: welcomeInfoReducer,
    toast: toastReducer,
    portFilter: portFilterSlice,
    oemFilter: oemSliceReducer,
    oem: oemReducer,
    lookups: lookupsSliceReducer,
    browseProduct: browseProductSliceReducer,
    familyFilter: familyFilterSlice,
    contactUs: contactUsSliceReducer,
    login: loginSliceReducer,
    loading: loadingSliceReducer,
    genericModal: genericModalSliceReducer,
    rangeDetails: rangeDetailsSliceReducer,
    productDetails: productDetailsSlice,
    userFilter: userFilterReducer,
    portDetails: portDetailsSlice,
  },
});
