import { useMemo, useState } from 'react';
import styles from './UserActivityPage.module.scss';
import ContainerForDrawer from '../../../../components/common/ContainerForDrawer/ContainerForDrawer';
import LoadingSpinner from '../../../../components/common/LoadingSpinner/LoadingSpinner';
import NavigationHeader from '../../../../components/common/NavigationHeader/NavigationHeader';
import { PreviousLink } from '../../../../models/previous-link';
import { FormattedMessage, useIntl } from 'react-intl';
import QuickStatsForm from '../components/QuickStatsForm/QuickStatsForm';
import DateField from '../../../../components/Admin/DateField/DateField';
import { RootStore } from '../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { CountryItem } from '../../../../models/lookups';
import classNames from 'classnames';
import { ReactComponent as CancelIcon } from '../../../../icons/cross-circle.svg';
import Button from '../../../../components/common/Button/Button';
import { getUserActivityReport } from '../../../../services/StatsService';
import UserActivityTable from '../components/UserActivityTable/UserActivityTable';
import InputRow from '../../../../components/Admin/InputRow/InputRow';
import FormRow from '../../../../components/Admin/FormRow/FormRow';
import { userRoles } from '../../../../configs/admin-constants';
import MultiProductSelect from '../../Products/AddCountryLevelProductPage/components/MultiProductSelect/MultiProductSelect';
import SelectFormRow from '../../../../components/Admin/SelectFormRow/SelectFormRow';
import { addToast } from '../../../../features/toastSlice';

/**
 * User Activity Page component
 */
function UserActivityPage() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [hideHeader, setHideHeader] = useState(false);
  const [statsData, setStatsData] = useState<any>([]);
  // table sorts
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [sortBy, setSortBy] = useState('user.firstName');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  const intl = useIntl();
  const { countries } = useSelector((state: RootStore) => state.lookups);
  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });
  const initialState = {
    name: '',
    emailAddress: '',
    userRole: '',
    country: [],
    startDate: '',
    endDate: '',
  };

  const [formValue, setFormValue] = useState(initialState);

  const breadcrumbLinks: PreviousLink[] = [
    {
      name: 'Report',
      to: '/admin/reports',
    },
    {
      name: 'USER ACTIVITY REPORT',
    },
  ];

  const handleValueChange = (fieldKey: string, value: any) => {
    setFormValue({
      ...formValue,
      [fieldKey]: value,
    });
  };

  const isInitialState = useMemo(() => {
    return (
      (!formValue.name || formValue.name.length === 0) &&
      (!formValue.emailAddress || formValue.emailAddress.length === 0) &&
      (!formValue.userRole || formValue.userRole.length === 0) &&
      (!formValue.country || formValue.country.length === 0) &&
      (!formValue.startDate || formValue.startDate.length === 0) &&
      (!formValue.endDate || formValue.endDate.length === 0)
    );
  }, [formValue]);

  const clearFilter = () => {
    setFormValue(initialState);

    setPage(1);
    setPerPage(10);
    setStatsData([]);
  };

  const resetTableFileter = () => {
    setPage(1);
    setPerPage(10);
    setSortBy('user.firstName');
    setSortDirection('asc');

    return {
      page: 1,
      perPage: 10,
      sortBy: 'user.firstName',
      sortDirection: 'asc',
    };
  };

  const onFilter = (
    page: number,
    perPage: number,
    sortBy: string,
    sortDirection: string,
  ) => {
    setHideHeader(true);
    setLoading(true);
    getUserActivityReport(
      page,
      perPage,
      sortBy,
      sortDirection,
      formValue.name,
      formValue.emailAddress,
      formValue.userRole,
      formValue.startDate,
      formValue.endDate,
      formValue.country,
    )
      .then((res: any) => {
        setStatsData(res.items);
        setTotal(res.total);
        setPage(res.page);
        setPerPage(res.perPage);
      })
      .catch((err) => {
        dispatch(
          addToast({
            type: 'error',
            title: intl.formatMessage({ id: 'report-user-activity-failure' }),
            message: err?.message || '',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const exportToExcel = () => {
    setHideHeader(true);
    setLoading(true);
    getUserActivityReport(
      page,
      perPage,
      sortBy,
      sortDirection,
      formValue.name,
      formValue.emailAddress,
      formValue.userRole,
      formValue.startDate,
      formValue.endDate,
      formValue.country,
      true,
    )
      .catch((err) => {
        err?.response?.data.text().then((msg: any) => {
          const errMessage = JSON.parse(msg);
          if (errMessage?.errors && errMessage?.errors.length > 0) {
            dispatch(
              addToast({
                type: 'error',
                title: intl.formatMessage({
                  id: 'report-user-activity-failure',
                }),
                message: errMessage?.errors[0] || '',
              }),
            );
          }
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onShowFilter = () => {
    setHideHeader(!hideHeader);
  };

  const countryItems = [
    {
      name: getTranslatedMessage('reports-page-select-all'),
      value: -1,
    },
    ...countries.map((country: CountryItem) => ({
      name: country.name,
      value: country.id,
    })),
  ] as any;

  const userRoleItems = [
    {
      name: getTranslatedMessage('reports-page-select-all'),
      value: -1,
    },
    ...userRoles.map((role: string) => ({
      name: role,
      value: role,
    })),
  ] as any;

  const onSortChange = (column: any) => {
    let changedKey = sortBy;
    let changedDirection: 'asc' | 'desc';

    if (sortBy !== column.key) {
      changedKey = column.key;
      changedDirection = 'desc';
    } else {
      if (sortDirection === 'desc') {
        changedDirection = 'asc';
      } else {
        changedDirection = 'desc';
      }
    }
    setSortBy(changedKey);
    setSortDirection(changedDirection);
    onFilter(page, perPage, changedKey, changedDirection);
  };

  return (
    <>
      <div className={classNames(loading ? styles.loadingOverlayDim : null)}>
        <NavigationHeader breadcrumbLinks={breadcrumbLinks} />
        <ContainerForDrawer>
          <div className={styles.container}>
            <div className={classNames(styles.title)}>
              <FormattedMessage id={'user-activity-page-title'} />
            </div>
            <div className={classNames(styles.contentContainer)}>
              <div className={classNames(styles.reportDetailContainer)}>
                <div>
                  <QuickStatsForm
                    title={getTranslatedMessage('product-report-page-filter')}
                  >
                    <div
                      className={classNames(
                        styles.formFieldsContainer,
                        hideHeader ? styles.hideHeader : '',
                      )}
                    >
                      <div className={styles.row}>
                        <div className={'columnRow'}>
                          <InputRow
                            value={formValue.name}
                            labelId={'user-activity-page-name'}
                            required={false}
                            placeholder={getTranslatedMessage(
                              'oem-page-input-placeholder',
                            )}
                            onChange={(value) =>
                              handleValueChange('name', value)
                            }
                            errorMessage={getTranslatedMessage(
                              'field-required-error',
                            )}
                            className={styles.formRow}
                          />
                        </div>

                        <div className={'columnRow'}>
                          <InputRow
                            labelId={'user-activity-page-email'}
                            value={formValue.emailAddress}
                            required={false}
                            placeholder={getTranslatedMessage(
                              'oem-page-input-placeholder',
                            )}
                            onChange={(value) =>
                              handleValueChange('emailAddress', value)
                            }
                            errorMessage={getTranslatedMessage(
                              'field-required-error',
                            )}
                            className={styles.formRow}
                          />
                        </div>
                        <SelectFormRow
                          labelId="user-activity-user-role"
                          required={false}
                          value={formValue.userRole}
                          onChange={(value: string) =>
                            handleValueChange('userRole', value)
                          }
                          placeholder={getTranslatedMessage(
                            'reports-page-select-all',
                          )}
                          options={userRoleItems}
                          className={styles.multiplSelect}
                        />

                        <FormRow
                          labelId="user-activity-country"
                          required={false}
                          className={styles.multipleSelect}
                        >
                          <MultiProductSelect
                            value={formValue.country}
                            onChange={(value: number[]) => {
                              if (value.includes(-1)) {
                                handleValueChange('country', []);
                              } else {
                                handleValueChange('country', value);
                              }
                            }}
                            placeholder={getTranslatedMessage(
                              'reports-page-select-all',
                            )}
                            options={countryItems}
                            className={styles.multipleSelect}
                            inputClassName={styles.multipleInput}
                            selectClassName={styles.selectOptions}
                            selectedItemName={getTranslatedMessage(
                              'reports-field-country-select',
                            )}
                            selectedItemNameSingular={getTranslatedMessage(
                              'reports-field-country-select-singular',
                            )}
                          />
                        </FormRow>
                        <DateField
                          labelId="product-report-page-start-date"
                          value={formValue.startDate}
                          onChange={(value: string) =>
                            handleValueChange('startDate', value)
                          }
                          required={false}
                          className={styles.calendarRow}
                          rowClassName={styles.formRow}
                          placeholder={getTranslatedMessage(
                            'reports-page-select-date',
                          )}
                        />
                        <DateField
                          labelId="product-report-page-end-date"
                          value={formValue.endDate}
                          onChange={(value: string) =>
                            handleValueChange('endDate', value)
                          }
                          required={false}
                          className={styles.calendarRow}
                          rowClassName={styles.formRow}
                          placeholder={getTranslatedMessage(
                            'reports-page-select-date',
                          )}
                          tooltip={getTranslatedMessage(
                            'user-activity-page-tooltip',
                          )}
                        />
                      </div>
                    </div>

                    <div
                      className={classNames(
                        styles.filterContainer,
                        hideHeader ? styles.hideHeader : '',
                      )}
                    >
                      <Button
                        color="green"
                        className={classNames('rowButton', styles.filterButton)}
                        // disabled={isInitialState}
                        onClick={() => {
                          const filters = resetTableFileter();
                          onFilter(
                            filters.page,
                            filters.perPage,
                            filters.sortBy,
                            filters.sortDirection,
                          );
                        }}
                      >
                        {getTranslatedMessage('reports-page-filter-button')}
                      </Button>
                      {!isInitialState && (
                        <div
                          className={styles.clearFilter}
                          onClick={clearFilter}
                        >
                          <CancelIcon className={styles.cancelIcon} />
                          <div>
                            {getTranslatedMessage('reports-page-clear-filter')}
                          </div>
                        </div>
                      )}
                    </div>

                    <a
                      className={classNames(
                        styles.showFilter,
                        hideHeader ? '' : styles.hideHeader,
                      )}
                      onClick={onShowFilter}
                    >
                      {getTranslatedMessage('user-activity-show-filter')}
                    </a>
                  </QuickStatsForm>
                </div>
              </div>
              {statsData.length ? (
                <UserActivityTable
                  reportData={statsData || []}
                  page={page}
                  perPage={perPage}
                  total={total}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                  onClickPage={(page) => {
                    setPage(page);
                    onFilter(page, perPage, sortBy, sortDirection);
                  }}
                  onClickPerPage={(perPage) => {
                    setPerPage(perPage);
                    setPage(1);
                    onFilter(1, perPage, sortBy, sortDirection);
                  }}
                  exportToExcel={exportToExcel}
                  onSortChange={onSortChange}
                />
              ) : null}
            </div>
          </div>
        </ContainerForDrawer>
      </div>
      {loading && (
        <div className={styles.loadingOverlay}>
          <LoadingSpinner />
        </div>
      )}
    </>
  );
}

export default UserActivityPage;
