import styles from './BrowseChildren.module.scss';
import React, { CSSProperties, PropsWithChildren } from 'react';
import cn from 'classnames';

interface BrowseChildrenProps {
  title: string;
  position: 'top' | 'bottom';
  style: CSSProperties;
}

/**
 * browse children popup
 * @param position popup showing position
 * @param style style
 * @param title title
 * @param children children
 */
function BrowseChildren({
  position,
  style,
  title,
  children,
}: PropsWithChildren<BrowseChildrenProps>) {
  return (
    <div
      onWheel={(event) => event.stopPropagation()}
      style={style}
      className={styles.container}
    >
      <div className={cn(styles.innerContainer, styles[position])}>
        <div className={styles.header}>
          <div className={styles.title}>{title}</div>
        </div>

        <div className={styles.contentContainer}>{children}</div>
      </div>
    </div>
  );
}

export default BrowseChildren;
