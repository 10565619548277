import { useIntl } from 'react-intl';

import FormWrapper from '../../FormWrapper/FormWrapper';
import InputRow from '../../InputRow/InputRow';
import SelectFormRow from '../../SelectFormRow/SelectFormRow';

import { RangeFamily } from '../../../../models/admin-form-types';

import config from '../../../../configs/config';

type FamilyRangeDetailsProps = {
  form?: 'family' | 'range';
  formValue: any;
  handleValueChange: any;
  rangeFamilies?: RangeFamily[];
};

const FamilyRangeDetails = ({
  form = 'family',
  formValue,
  handleValueChange,
  rangeFamilies = [],
}: FamilyRangeDetailsProps) => {
  const intl = useIntl();

  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });

  return (
    <FormWrapper title={getTranslatedMessage(`${form}-details-label`)}>
      <div className="columnRow">
        <InputRow
          labelId={`${form}-name-label`}
          value={formValue.name}
          onChange={(value: string) => handleValueChange('name', value)}
          tooltip={getTranslatedMessage(`${form}-name-tooltip`)}
        />
        <InputRow
          labelId="friendly-url-label"
          value={formValue.friendlyUrl}
          onChange={(value: string) => {
            const updatedValue = value.replace(
              config.FRIENDLY_URL_NAME_REGEX,
              '',
            );
            handleValueChange('friendlyUrl', updatedValue);
          }}
          tooltip={getTranslatedMessage(`${form}-friendly-url-tooltip`)}
        />
      </div>
      {form === 'range' && (
        <SelectFormRow
          labelId="range-family-label"
          value={formValue.rangeFamily}
          onChange={(value: any) => handleValueChange('rangeFamily', value)}
          options={rangeFamilies.map((rangeFamily) => ({
            name: rangeFamily.name,
            value: rangeFamily,
          }))}
          tooltip={getTranslatedMessage('range-family-tooltip')}
        />
      )}
    </FormWrapper>
  );
};

export default FamilyRangeDetails;
