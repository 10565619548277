import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ReactQuill from 'react-quill';
import { useSelector } from 'react-redux';
import FormRow from '../../../../../../../components/Admin/FormRow/FormRow';
import FormWrapper from '../../../../../../../components/Admin/FormWrapper/FormWrapper';
import InputRow from '../../../../../../../components/Admin/InputRow/InputRow';
import LanguageSelect from '../../../../../../../components/Admin/LanguageSelect/LanguageSelect';
import SelectFormRow from '../../../../../../../components/Admin/SelectFormRow/SelectFormRow';
import Button from '../../../../../../../components/common/Button/Button';
import {
  ManageAnnouncementTranslationFormType,
  TranslationsFormType,
} from '../../../../../../../models/admin-form-types';
import { RootStore } from '../../../../../../../store';
import { validateURL } from '../../../../../../../utils/common.util';
import styles from './Details.module.scss';
import _ from 'lodash';
import { useNavigate } from 'react-router';
import { Translation } from '../../../../../../../models/translation';
import {
  Announcement,
  AnnouncementDetail,
} from '../../../../../../../models/announcement';

interface DetailsProps {
  onNext: (announcement: AnnouncementDetail) => void;
  onTypeChange: (isExternal: boolean) => void;
  announcement: Announcement;
}

interface TranslationProps {
  title: string;
  description: string;
}
interface TranslationForm {
  activeLanguage: number;
  translations: { [id: number]: TranslationProps };
  selectedLanguages: { id: number; empty: boolean }[];
}

const initialFormState: TranslationForm = {
  activeLanguage: 0,
  translations: {},
  selectedLanguages: [
    {
      id: 0,
      empty: false,
    },
  ],
};

export default function Details({
  onNext,
  onTypeChange,
  announcement,
}: DetailsProps) {
  const intl = useIntl();
  const navigate = useNavigate();

  const initState: TranslationForm = _.cloneDeep(initialFormState);
  announcement.translations.forEach((translation) => {
    initState.translations[translation.languageId] =
      initState.translations[translation.languageId] || {};
    initState.translations[translation.languageId][
      translation.propertyName as keyof TranslationProps
    ] = translation.propertyValue;
    initState.selectedLanguages.push({
      id: translation.languageId,
      empty: false,
    });
  });
  const visitedLang: { [id: string]: boolean } = {};
  initState.selectedLanguages = initState.selectedLanguages.filter((lang) => {
    if (visitedLang[lang.id]) {
      return false;
    }
    visitedLang[lang.id] = true;
    return true;
  });

  if (
    initState.activeLanguage === 0 &&
    Object.keys(initState.translations).length > 0
  ) {
    initState.activeLanguage = 1;
  }

  const [translationForm, setTranslationForm] =
    useState<TranslationForm>(initState);
  const [associateLink, setAssociateLink] = useState(
    announcement.associateLink,
  );
  const [type, setType] = useState(announcement.announcementTypeId);
  const { announcementTypes, languages } = useSelector(
    (state: RootStore) => state.lookups,
  );

  const languageOptions = useMemo(
    () =>
      languages.map((c) => ({
        name: c.name,
        value: c,
        disabled: translationForm.activeLanguage === 0 && c.id !== 1,
      })),
    [languages, translationForm.activeLanguage],
  );

  useEffect(() => {
    onTypeChange(type === 2);
  }, [type]);

  const translationEmpty = (translation: any) => {
    let isEmpty = true;
    for (const fieldKey of translationFieldKeys) {
      isEmpty = isEmpty && !translation[fieldKey];
      if (!isEmpty) {
        break;
      }
    }

    return isEmpty;
  };

  const announcementTypesOptions = useMemo(() => {
    return announcementTypes.map((item) => ({
      name: item.name,
      value: item.id,
    }));
  }, [announcementTypes]);

  const cancelClicked = () => {
    navigate('/admin/annoucements');
  };

  const translationFieldKeys: (keyof ManageAnnouncementTranslationFormType)[] =
    ['title', 'description'];

  const editorModules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ list: 'bullet' }, { list: 'ordered' }],
    ],
  };

  const onNextClick = () => {
    const translations: Translation[] = [];
    for (const language of Object.keys(translationForm.translations)) {
      translations.push({
        propertyName: 'title',
        propertyValue: translationForm.translations[+language].title,
        languageId: +language,
      });

      translations.push({
        propertyName: 'description',
        propertyValue: translationForm.translations[+language].description,
        languageId: +language,
      });
    }

    onNext({
      title: translationForm.translations[1].title,
      description: translationForm.translations[1].description,
      announcementTypeId: +type,
      associateLink: associateLink,
      translations,
    });
  };

  const handleLanguageChange = (languages: any) => {
    const selectedLanguages: string[] = languages.map(
      (language: any) => language.id,
    );
    const newTranslations: TranslationsFormType<ManageAnnouncementTranslationFormType> =
      _.cloneDeep(translationForm.translations);

    // If the language is not present, we just add
    for (const language of selectedLanguages) {
      if (!newTranslations[language]) {
        newTranslations[language] = {} as ManageAnnouncementTranslationFormType;
        for (const key of translationFieldKeys) {
          newTranslations[language][
            key as keyof ManageAnnouncementTranslationFormType
          ] = '';
        }
      }
    }

    // If the language has been removed, we remove it
    for (const language of Object.keys(newTranslations)) {
      const index = selectedLanguages.findIndex(
        (l) => Number(l) === Number(language),
      );
      if (index === -1) {
        delete newTranslations[language];
      }
    }

    const tempSelectedLanguages = [];
    for (const language of Object.keys(newTranslations)) {
      tempSelectedLanguages.push({
        id: Number(language),
        empty: translationEmpty(newTranslations[language]),
      });
    }
    const currentActiveTab = languages.find((l: any) => l.active);
    let activeLanguageId = 1;
    if (currentActiveTab) {
      activeLanguageId = currentActiveTab.id;
    }

    setTranslationForm({
      activeLanguage: activeLanguageId,
      translations: newTranslations,
      selectedLanguages: tempSelectedLanguages,
    });
  };

  const handleFieldValueChange = (
    fieldKey: keyof ManageAnnouncementTranslationFormType,
    value: string,
  ) => {
    setTranslationForm((prevTranslationForm) => {
      const newTranslationsForm = _.cloneDeep(prevTranslationForm);
      newTranslationsForm.translations[newTranslationsForm.activeLanguage][
        fieldKey
      ] = value;
      return newTranslationsForm;
    });
  };

  const isValidForm = () => {
    if (validateURL(associateLink) || type <= 0) {
      return false;
    }

    if (Object.keys(translationForm.translations).length === 0) {
      return false;
    }

    for (const langId of Object.keys(translationForm.translations)) {
      if (
        _.isEmpty(translationForm.translations[+langId].title.trim()) ||
        _.isEmpty(translationForm.translations[+langId].description.trim()) ||
        /^<p>(<br>)*\s*<\/p>$/.test(
          translationForm.translations[+langId].description.trim(),
        )
      ) {
        return false;
      }
    }

    return true;
  };

  return (
    <>
      <div className={styles.container}>
        <div className={classNames(styles.formWrapper, styles.subFormWrapper)}>
          <div className={styles.sectionHeader}>
            <FormattedMessage id={'generate-details-title'} />
          </div>
          <div className={'columnRow'}>
            <InputRow
              required={true}
              placeholder={intl.formatMessage({
                id: 'enter-url-placeholder',
              })}
              labelId={'associate-link-label'}
              value={associateLink}
              onChange={setAssociateLink}
              customValidator={(value: string) => validateURL(value)}
              tooltip={intl.formatMessage({ id: 'associate-link-label' })}
            />
          </div>
          <div className={'columnRow'}>
            <SelectFormRow
              labelId={'type-label'}
              tooltip={intl.formatMessage({ id: 'type-label' })}
              options={announcementTypesOptions}
              value={type}
              onChange={setType}
            />
          </div>
        </div>
        <div
          className={classNames(styles.formDivider, styles.mobileOnly)}
        ></div>
        <FormWrapper
          className={classNames(styles.subFormWrapper)}
          title={intl.formatMessage({
            id: 'localised-announcement-details',
          })}
          infoMessage={intl.formatMessage({ id: 'localised-details-info' })}
        >
          <div className={'columnRow'}>
            <FormRow
              className={styles.addLangsRow}
              required={false}
              labelId={'add-additional-langs-label'}
              tooltip={intl.formatMessage({
                id: 'add-additional-langs-tooltip',
              })}
            >
              <LanguageSelect
                activeLanguage={translationForm.activeLanguage}
                handleActiveLanguageChange={(langId) => {
                  setTranslationForm((prev) => {
                    const form = _.cloneDeep(prev);
                    form.activeLanguage = langId;
                    return form;
                  });
                }}
                langIds={translationForm.selectedLanguages}
                languageOptions={languageOptions}
                placeholder={intl.formatMessage({
                  id: 'add-product-select-placeholder',
                })}
                onChange={handleLanguageChange}
              />
            </FormRow>
          </div>
          {translationForm.activeLanguage > 0 && (
            <div className={'columnRow'}>
              <InputRow
                required={true}
                labelId={'title-label'}
                value={
                  translationForm.translations[translationForm.activeLanguage][
                    translationFieldKeys[0]
                  ]
                }
                onChange={(value: string) =>
                  handleFieldValueChange(translationFieldKeys[0], value)
                }
                tooltip={intl.formatMessage({ id: 'title-label' })}
              />
            </div>
          )}
          {translationForm.activeLanguage > 0 && (
            <div className={'columnRow'}>
              <FormRow
                labelId={'description-label'}
                required={true}
                tooltip={intl.formatMessage({ id: 'description-label' })}
              >
                <ReactQuill
                  className={styles.richTextarea}
                  modules={editorModules}
                  value={
                    translationForm.translations[
                      translationForm.activeLanguage
                    ][translationFieldKeys[1]]
                  }
                  placeholder={intl.formatMessage({
                    id: 'input-placeholder',
                  })}
                  onChange={(value: string) => {
                    handleFieldValueChange(translationFieldKeys[1], value);
                  }}
                />
              </FormRow>
            </div>
          )}
        </FormWrapper>
      </div>
      <div className={styles.actionRow}>
        <Button
          onClick={cancelClicked}
          color={'green-outline'}
          className={styles.cancelButton}
        >
          <FormattedMessage id={'cancel-label'} />
        </Button>
        <Button
          onClick={onNextClick}
          color={'green'}
          disabled={!isValidForm()}
          className={styles.nextButton}
        >
          <FormattedMessage id={'next-label'} />
        </Button>
      </div>
    </>
  );
}
