import styles from './OemDetailEditModal.module.scss';
import Button from '../../../common/Button/Button';
import Modal from '../../../common/Modal/Modal';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../../store';
import TextareaRow from '../../TextareaRow/TextareaRow';
import SelectFormRow from '../../SelectFormRow/SelectFormRow';
import RadioGroup from '../../RadioGroup/RadioGroup';
import { useMemo, useState } from 'react';
import { OemProductApproval } from '../../../../models/oem-product-approval';

type OemDetailEditModalProps = {
  oemProductApproval: OemProductApproval | undefined;
  onSave: (
    id: number,
    typeId: number,
    confidential: boolean,
    notes: string,
    restrictions: string,
    approvalDocumentId: number,
  ) => void;
  onClose: () => void;
};

const OemDetailEditModal = ({
  oemProductApproval,
  onSave,
  onClose,
}: OemDetailEditModalProps) => {
  const intl = useIntl();
  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });

  const { oemApprovalTypes } = useSelector((state: RootStore) => state.lookups);
  const oemApprovalTypesOptions = useMemo(() => {
    return (
      oemApprovalTypes?.map((item) => ({
        name: item.name,
        value: item.id,
      })) || []
    );
  }, [oemApprovalTypes]);
  if (oemProductApproval?.restrictions === null) {
    oemProductApproval.restrictions = '';
  }
  const [oemApprovalTypeId, setOemApprovalTypeId] = useState(
    oemProductApproval?.oemApprovalTypeId,
  );
  const [bpConfidential, setBpConfidentail] = useState(
    oemProductApproval?.approvalDocument.bpConfidential,
  );

  const [notes, setNotes] = useState(oemProductApproval?.notes || '');
  const [restrictions, setRestrictions] = useState(
    oemProductApproval?.restrictions || '',
  );
  const visibilityOptions = useMemo(() => {
    return [
      {
        label: getTranslatedMessage('yes-label'),
        value: true,
      },
      {
        label: getTranslatedMessage('no-label'),
        value: false,
      },
    ];
  }, []);

  const handleSaveBtnClick = () => {
    if (
      oemProductApproval === undefined &&
      oemApprovalTypeId &&
      (notes === '' || notes === null || notes) &&
      (restrictions === '' || restrictions === null || restrictions) &&
      (bpConfidential === true || bpConfidential === false)
    ) {
      onSave(0, oemApprovalTypeId, bpConfidential, notes, restrictions, 0);
      onClose();
    } else if (
      oemProductApproval &&
      oemApprovalTypeId &&
      (notes === '' || notes === null || notes) &&
      (restrictions === '' || restrictions === null || restrictions) &&
      (bpConfidential === true || bpConfidential === false)
    ) {
      onSave(
        oemProductApproval?.id,
        oemApprovalTypeId,
        bpConfidential,
        notes,
        restrictions,
        oemProductApproval?.approvalDocumentId,
      );
      onClose();
    } else {
      return;
    }
  };
  return (
    <Modal
      center={true}
      onClose={onClose}
      className={styles.oemDetailEditModalContainer}
    >
      <div className={styles.container}>
        <h1>Edit Oem</h1>

        <div className={styles.modalContent}>
          <div className={styles.oemDetailContainer}>
            <div className={'columnRow'}>
              <SelectFormRow
                labelId={'oem-table-field-approval-type'}
                required={true}
                options={oemApprovalTypesOptions}
                value={oemApprovalTypeId}
                onChange={(value: number) => setOemApprovalTypeId(value)}
              />
            </div>

            <div className={'columnRow'}>
              <RadioGroup
                required={true}
                labelId={'oem-table-field-bp-confidential'}
                items={visibilityOptions}
                selected={bpConfidential}
                className={styles.radio}
                onClick={(value: boolean) => setBpConfidentail(value)}
              />
            </div>

            <div className={'columnRow'}>
              <TextareaRow
                labelId={'oem-table-field-restrictions'}
                required={false}
                value={restrictions}
                onChange={(value: string) => setRestrictions(value)}
              />
              <TextareaRow
                labelId={'oem-table-field-notes'}
                required={false}
                value={notes}
                onChange={(value: string) => setNotes(value)}
              />
            </div>
          </div>
        </div>

        <div className={styles.actionBtnContainer}>
          <Button
            onClick={() => handleSaveBtnClick()}
            color={'green'}
            className={styles.confirmButton}
          >
            <FormattedMessage id={'save-label'} />
          </Button>
          <Button
            onClick={() => onClose()}
            color={'green-outline'}
            className={styles.cancelButton}
          >
            <FormattedMessage id={'cancel-label'} />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default OemDetailEditModal;
