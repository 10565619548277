import axios, { AxiosRequestConfig } from 'axios';
import { setupCache } from 'axios-cache-adapter';
import config from '../configs/config';
import store from '../store';
import { getAccessToken } from '../utils/api.util';

function serializeQuery(req: AxiosRequestConfig) {
  if (!req.params) return '';

  // Probably server-side, just stringify the object
  if (typeof URLSearchParams === 'undefined') return JSON.stringify(req.params);

  let params = req.params;

  const isInstanceOfURLSearchParams = req.params instanceof URLSearchParams;

  // Convert to an instance of URLSearchParams so it get serialized the same way
  if (!isInstanceOfURLSearchParams) {
    params = new URLSearchParams();
    Object.keys(req.params).forEach((key) =>
      params.append(key, req.params[key]),
    );
  }

  return `?${params.toString()}`;
}

// Setup cache for Axios
const cache = setupCache({
  maxAge: 15 * 60 * 1000,
  exclude: {
    query: false,
  },
  debug: false,
  ignoreCache: true,
  key: (req) => {
    const key = `${req.headers.sector}-${req.headers.culture}-${
      req.url
    }${serializeQuery(req)}`;
    return key;
  },
});
const axiosInstance = axios.create({
  baseURL: config.API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  adapter: cache.adapter,
});

// Set interceptor to add requet headers
const reqInterceptor = async (req: any) => {
  const token = await getAccessToken();
  req.headers.Authorization = `Bearer ${token}`;
  const welcomeInfo = store.getState().welcomeInfo?.welcomeInfo;
  const hasLogin = store.getState().login?.hasLogin;

  if (!req.headers.common.culture || !req.headers.common.sector) {
    req.headers.common.culture =
      welcomeInfo && welcomeInfo.language && welcomeInfo.country
        ? `${welcomeInfo.language?.code}-${welcomeInfo.country?.code}`
        : 'en-GB';

    if (hasLogin) {
      if (
        welcomeInfo &&
        welcomeInfo.industrySector?.friendlyUrl !== config.ALL_SECTORS
      ) {
        req.headers.common.sector =
          welcomeInfo && welcomeInfo.industrySector
            ? welcomeInfo.industrySector.friendlyUrl
            : 'directory-marine-services';
      }
    } else {
      req.headers.common.sector =
        welcomeInfo && welcomeInfo.industrySector
          ? welcomeInfo.industrySector.friendlyUrl
          : 'directory-marine-services';
    }
  }

  return req;
};

axiosInstance.interceptors.request.use(reqInterceptor, (error) =>
  Promise.reject(error),
);

export const apiInstance = axiosInstance;
