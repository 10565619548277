import { useIntl } from 'react-intl';

import FormWrapper from '../../FormWrapper/FormWrapper';
import SelectFormRow from '../../SelectFormRow/SelectFormRow';

import { StockpointFormProps } from '../../../../models/admin-form-types';

const SelectStockpointForm = ({
  stockpoints,
  formValue,
  disabled,
  handleValueChange,
  noMessage,
  secondaryTitle,
}: StockpointFormProps) => {
  const intl = useIntl();

  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });
  const title = getTranslatedMessage('select-stockpoint-label');
  const infoMessage = getTranslatedMessage('select-stockpoint-note');
  return (
    <FormWrapper
      title={title}
      infoMessage={noMessage ? '' : infoMessage}
      secondaryTitle={secondaryTitle}
    >
      <SelectFormRow
        labelId={'stockpoint-name-label'}
        value={formValue.stockpoint}
        options={stockpoints.map((stockpoint) => ({
          name: stockpoint.name,
          value: stockpoint,
          inactiveLabel: !stockpoint.active,
          // inactive: !stockpoint.active,
        }))}
        onChange={(value: any) => handleValueChange(value)}
        tooltip={getTranslatedMessage('select-stockpoint-name-tooltip')}
        disabled={disabled}
      />
    </FormWrapper>
  );
};

export default SelectStockpointForm;
