import { PropsWithChildren } from 'react';

import FormActionBtns from '../FormActionBtns/FormActionBtns';

import { GenericManageSkeletonFormWrapperPartial } from '../../../models/admin-form-types';

import styles from './FormSectionWrapper.module.scss';

type FormSectionWrapperProps = GenericManageSkeletonFormWrapperPartial & {
  saveBtnLabel: string;
  showBackBtn: boolean;
};

const FormSectionWrapper = ({
  saveBtnLabel,
  showBackBtn,
  saveBtnDisabled,
  showUnsavedChangesModal,
  onBackBtnClick,
  onSaveBtnClick,
  children,
}: PropsWithChildren<FormSectionWrapperProps>) => (
  <div className={styles.formWrapper}>
    {children}
    <FormActionBtns
      saveBtnLabel={saveBtnLabel}
      showBackBtn={showBackBtn}
      saveBtnDisabled={saveBtnDisabled}
      showUnsavedChangesModal={showUnsavedChangesModal}
      onBackBtnClick={onBackBtnClick}
      onSaveBtnClick={onSaveBtnClick}
    />
  </div>
);

export default FormSectionWrapper;
