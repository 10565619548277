import styles from './AboutDetailItem.module.scss';
import { PropsWithChildren, ReactElement } from 'react';
import cn from 'classnames';

interface AboutDetailItemProps {
  icon: ReactElement;
  className?: string;
  onClick?: () => void;
}

/**
 * about detail item
 * @param children children
 * @param icon icon
 * @param className classname
 */
function AboutDetailItem({
  children,
  icon,
  className,
  onClick,
}: PropsWithChildren<AboutDetailItemProps>) {
  return (
    <div
      className={cn(styles.container, className)}
      onClick={() => (onClick ? onClick() : null)}
    >
      {icon}

      <div className={styles.label}>{children}</div>
    </div>
  );
}

export default AboutDetailItem;
