import styles from './RangeLeftDetail.module.scss';
import { ReactComponent as FamilyGreen } from '../../../icons/family-green.svg';
import { ReactComponent as ListGreen } from '../../../icons/list-green.svg';
import { ReactComponent as SettingsGreen } from '../../../icons/settings-green.svg';
import { ReactComponent as ProductTypeGreen } from '../../../icons/product-type-green.svg';
import { PropsWithoutRef } from 'react';
import { SearchResultRange } from '../../../models/search-result-range';
import { useIntl } from 'react-intl';
import IconField from '../../common/IconField/IconField';

interface RangeLeftDetailProps {
  range: SearchResultRange;
}

/**
 * range detail pages left drawer content
 * @param range range
 */
function RangeLeftDetail({ range }: PropsWithoutRef<RangeLeftDetailProps>) {
  const intl = useIntl();

  return (
    <div className={styles.container}>
      <IconField
        className={styles.item}
        icon={<FamilyGreen />}
        label={intl.formatMessage({ id: 'product-family-label' })}
        value={range.family.name}
      />

      <IconField
        className={styles.item}
        icon={<ListGreen />}
        label={intl.formatMessage({ id: 'product-category-label' })}
        value={range.category}
      />

      <IconField
        className={styles.item}
        icon={<SettingsGreen />}
        label={intl.formatMessage({ id: 'application-label' })}
        value={range.application}
      />

      <IconField
        className={styles.item}
        icon={<ProductTypeGreen />}
        label={intl.formatMessage({ id: 'product-type-label' })}
        value={range.productType}
      />
    </div>
  );
}

export default RangeLeftDetail;
