import { PropsWithoutRef, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ProductDetails } from '../../../models/search-result-product';
import { RootStore } from '../../../store';
import Notice, { NoticeType } from '../../common/Notice/Notice';

interface ProductAvailabiltyStatusProps {
  details: ProductDetails;
}

/**
 * port available table
 * @param details details of product
 * @param className classname
 */
function ProductAvailabiltyStatus({
  details,
}: PropsWithoutRef<ProductAvailabiltyStatusProps>) {
  const intl = useIntl();
  const { productCountryLevelMessagesById } = useSelector(
    (state: RootStore) => state.lookups,
  );

  const getMessage = (id: string) => {
    return intl.formatMessage({
      id: id,
    });
  };

  const noticeInfo = useMemo(() => {
    let message: string | undefined = undefined;
    let header: string | undefined = undefined;
    let type: NoticeType;
    if (details.isAvailableInYourCountry) {
      if (details.productCountryLevelMessageId) {
        const hasAlternate =
          details.alternateProducts && details.alternateProducts?.length > 0;
        const messageName: string | undefined =
          productCountryLevelMessagesById[details.productCountryLevelMessageId]
            ?.name;

        if (
          hasAlternate &&
          messageName &&
          getMessage(messageName) !== messageName
        ) {
          message = getMessage(messageName).replace(
            '{product}',
            details.alternateProducts.map((x) => x.name).join(', '),
          );
          header = getMessage('product-non-active-label');
          type = 'not-active';
        } else {
          message = getMessage('unavailable-common');
          header = getMessage('product-non-active-label');
          type = 'common';
        }
      } else {
        header = getMessage('product-non-active-label');
        type = 'common';
      }
    } else {
      header = getMessage('product-non-available-label');
      type = 'not-available';
    }

    return {
      message,
      header,
      type,
    };
  }, [productCountryLevelMessagesById, details]);

  return (
    <Notice
      message={noticeInfo.message}
      header={noticeInfo.header}
      type={noticeInfo.type}
      alternateProducts={details.alternateProducts}
    />
  );
}

export default ProductAvailabiltyStatus;
