import cn from 'classnames';
import styles from './Option.module.scss';
import { PropsWithoutRef, ReactElement, useEffect, useRef } from 'react';
import { SelectOption } from '../../../../models/select-option';

interface OptionProps<T> {
  selected: boolean;
  data: SelectOption<T>;
  onClick: (value: T) => void;
  optionRenderer?: (option: SelectOption<T>) => ReactElement;
  scrollIntoView: boolean;
  className?: string;
  showTooltip?: boolean;
}

/**
 * option for select
 * @param selected selected state
 * @param data data
 * @param onClick click handler
 * @param optionRenderer option content renderer
 * @param scrollIntoView scrollIntoView when loaded
 */
function Option<T>({
  selected,
  data,
  onClick,
  optionRenderer,
  scrollIntoView,
  className,
  showTooltip = false,
}: PropsWithoutRef<OptionProps<T>>) {
  const optionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selected && scrollIntoView) {
      if (optionRef.current) {
        optionRef.current.scrollIntoView({ block: 'center' });
      }
    }
  }, [scrollIntoView, selected]);

  return (
    <div
      ref={optionRef}
      onClick={() => onClick(data.value)}
      className={cn(
        styles.option,
        selected && styles.selected,
        data.disabled && styles.disabled,
        data.inactive && styles.inactive,
      )}
      title={showTooltip ? data.tooltip || data.name : undefined}
    >
      <div className={cn(styles.optionValue, className)}>
        {optionRenderer ? optionRenderer(data) : data.name}
        {data.inactiveLabel ? (
          <div className={cn(styles.inactive)}>INACTIVE</div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}

export default Option;
