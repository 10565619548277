import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootStore } from '../../../../store';
import { find } from 'lodash';
import cn from 'classnames';

import styles from './DeactivateApprovalModal.module.scss';
import Button from '../../../common/Button/Button';
import Modal from '../../../common/Modal/Modal';
import { getDateString } from '../../../../utils/date.util';
import { showApprovalDocName } from '../../../../utils/approval-document.util';
import { OemProductApproval } from '../../../../models/oem-product-approval';
import { ReactComponent as DrumIcon } from '../../../../icons/drum-green.svg';
import { ReactComponent as DocumentIcon } from '../../../../icons/document-green.svg';
import { ReactComponent as DocIcon } from '../../../../icons/doc.svg';
import { ReactComponent as WorkHourIcon } from '../../../../icons/work-hour.svg';
import { ReactComponent as LockIcon } from '../../../../icons/lock.svg';
import { ReactComponent as CheckListGreenIcon } from '../../../../icons/check-list-green.svg';

type DeactivateApprovalModalProps = {
  oemProductApproval: OemProductApproval | undefined;
  type?: 'deactivate' | 'activate';
  onSave: () => void;
  onClose: () => void;
};

const DeactivateApprovalModal = ({
  oemProductApproval,
  type = 'deactivate',
  onSave,
  onClose,
}: DeactivateApprovalModalProps) => {
  const intl = useIntl();
  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });

  const { oemApprovalTypes, masterBrands } = useSelector(
    (state: RootStore) => state.lookups,
  );

  const getMasterBrandName = (id: number | undefined): any => {
    if (!masterBrands || !id) {
      return <div />;
    }

    const masterBrandName = find(masterBrands, (item) => item.id === id);
    return (
      <div className={styles.brandLabel}>{masterBrandName?.name || ''}</div>
    );
  };

  const getOemApprovalTypeName = (id: number | undefined): any => {
    if (!oemApprovalTypes || !id) {
      return <div />;
    }

    const approvalType = find(oemApprovalTypes, (item) => item.id === id);
    return <div className={styles.brandLabel}>{approvalType?.name || ''}</div>;
  };

  const getApprovalRestrictionsLabel = (value: string | undefined): string => {
    switch (value) {
      case 'OEM':
        return getTranslatedMessage('oem-approval-restrictions-oem');
      case 'Product':
        return getTranslatedMessage('oem-approval-restrictions-product');
      case 'DMG':
        return getTranslatedMessage('oem-approval-restrictions-dmg');
      case 'Ford':
        return getTranslatedMessage('oem-approval-restrictions-ford');
      case 'Opel':
        return getTranslatedMessage('oem-approval-restrictions-opel');
      default:
        return getTranslatedMessage('oem-approval-restrictions-none');
    }
  };

  const handleSaveBtnClick = () => {
    onSave();
    onClose();
  };

  const oemFields = [
    {
      icon: <DrumIcon />,
      label: oemProductApproval?.product?.name,
      value: getMasterBrandName(oemProductApproval?.product?.masterBrandId),
      labelClass: styles.nameLabelClass,
      valueClass: styles.brandClass,
    },
    {
      icon: <DocumentIcon />,
      label: getTranslatedMessage('oem-table-field-document-versions'),
      valueClass: styles.documentClass,
      value: (
        <Link
          className={styles.link}
          to={`/admin/approval-document/${oemProductApproval?.approvalDocumentId}?oemId=${oemProductApproval?.oemId}`}
        >
          {showApprovalDocName(oemProductApproval?.approvalDocument)}
        </Link>
      ),
    },
    {
      icon: <DocIcon />,
      label: getTranslatedMessage('oem-table-field-approval-type'),
      value: getOemApprovalTypeName(oemProductApproval?.oemApprovalTypeId),
    },
    {
      icon: <WorkHourIcon />,
      label: getTranslatedMessage('oem-table-field-issue-date'),
      value: getDateString(oemProductApproval?.approvalDocument.issuedAt),
    },
    {
      icon: (
        <div className={styles.castrolConfidentialIcon}>
          <DocumentIcon />
          <div className={styles.lockIconContainer}>
            <LockIcon className={styles.lockIcon} />
          </div>
        </div>
      ),
      label: getTranslatedMessage('oem-table-field-castrol-confidential'),
      value: oemProductApproval?.approvalDocument.bpConfidential ? (
        <FormattedMessage id={'yes-label'} />
      ) : (
        <FormattedMessage id={'no-label'} />
      ),
    },
    {
      icon: <CheckListGreenIcon />,
      label: getTranslatedMessage('oem-table-field-approval-restrictions'),
      value: getApprovalRestrictionsLabel(oemProductApproval?.restrictions),
    },
  ];

  return (
    <Modal
      center={true}
      onClose={onClose}
      className={styles.deactivateEomModalContainer}
    >
      <div className={styles.container}>
        <h1>
          {type === 'activate' ? (
            <FormattedMessage id={'oem-model-activate-approval'} />
          ) : (
            <FormattedMessage id={'oem-model-deactivate-approval'} />
          )}
        </h1>
        <div className={styles.modalContent}>
          <div className={styles.description}>
            {type === 'activate' ? (
              <FormattedMessage id={'oem-model-activate-confirm-info'} />
            ) : (
              <FormattedMessage id={'oem-model-deactivate-confirm-info'} />
            )}
          </div>
          <div className={styles.infoLabel}>
            <FormattedMessage id={'oem-model-oem-info'} />
          </div>
          <div className={styles.fieldsContainer}>
            {oemFields.map((item, index) => (
              <div className={styles.itemField} key={index}>
                {item.icon}
                <div className={styles.fieldContent}>
                  <div className={cn(styles.fieldLabel, item.labelClass)}>
                    {item.label}
                  </div>
                  <div className={cn(styles.fieldValue, item.valueClass)}>
                    {item.value}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.actionBtnContainer}>
          <Button
            onClick={() => handleSaveBtnClick()}
            color={'green'}
            className={styles.confirmButton}
          >
            <FormattedMessage id={'yes-label'} />
          </Button>
          <Button
            onClick={() => onClose()}
            color={'green-outline'}
            className={styles.cancelButton}
          >
            <FormattedMessage id={'no-label'} />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeactivateApprovalModal;
