import { saveAs } from 'file-saver';

import { apiInstance } from '../api/requestInterceptor';

async function downloadExistingBulkProducts() {
  const res = await apiInstance.get('/products/bulk/download', {
    responseType: 'blob',
  });

  if (res.status === 200) {
    const filename =
      res.headers['content-disposition']?.split('filename=')[1] ||
      'existing-bulk-products.xlsx';
    saveAs(
      res.data,
      decodeURIComponent(filename.replace('"', '').replace('"', '')),
    );
  }

  return res;
}

async function downloadProductsTemplate() {
  const res = await apiInstance.get(
    '/products/bulk/download?onlyTemplate=true',
    {
      responseType: 'blob',
    },
  );

  if (res.status === 200) {
    const filename =
      res.headers['content-disposition']?.split('filename=')[1] ||
      'products-template.xlsx';
    saveAs(
      res.data,
      decodeURIComponent(filename.replace('"', '').replace('"', '')),
    );
  }

  return res;
}

export interface SaveRequestBody {
  items: {
    isPartial: boolean;
    externalId: string | null;
    name: string;
    friendlyUrl: string | null;
    masterBrandId: number;
    familyId: number;
    rangeId: number | null;
    subCategoryId: number;
    tagLine: string;
    description: string | null;
    repositoryBusiness: string;
    castrolRecommended: boolean;
    productSectors: {
      sectorId: number;
    }[];
    productSubSectors: {
      subSectorId: number;
    }[];
    productApplications: {
      applicationId: number;
    }[];
    productSubApplications: {
      subApplicationId: number;
    }[];
    productProductTypes: {
      productTypeId: number;
    }[];
    productCategories: {
      categoryId: number;
    }[];
    productAvailableCountries: {
      countryId: number;
    }[];
    productCharacteristic: {
      viscosity: number[];
      viscosityIndexId: number | null;
      minTemperatureC: number | null;
      maxTemperatureC: number | null;
      grade: string;
      subGrade: null;
      esterEpAdditives: boolean;
      mineralOilId: number | null;
      corrosionProtectionId: number | null;
      concentrationRangeId: number | null;
      characteristicId: number | null;
      customerOfferId: number | null;
      foodGradeId: number;
      superiorBiodegradationId: number;
      multiMetalsSuitableId: number;
      formulatedWithoutSilicateId: number;
      formulatedWithoutChlorinatedParaffinId: number;
      formulatedWithoutBoronId: number;
      formulatedWithoutHeavyMetalId: number;
      productCharacteristicThickeners: {
        thickenerId: number;
      }[];
      productCharacteristicBaseOilTypes: {
        baseOilTypeId: number;
      }[];
      productCharacteristicNlgis: {
        nlgiId: number;
      }[];
      productCharacteristicMetalTypes: {
        metalTypeId: number;
      }[];
    };
  }[];
}

async function saveBulkProducts(data: SaveRequestBody): Promise<any> {
  const res = await apiInstance.post<any>('/products/bulk/save', data);

  return res.data;
}

export {
  downloadExistingBulkProducts,
  downloadProductsTemplate,
  saveBulkProducts,
};
