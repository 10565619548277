import styles from './LinksContainer.module.scss';
import MoreButton from '../../common/MoreButton/MoreButton';
import React, { PropsWithChildren } from 'react';
import LoadingSpinner from '../../common/LoadingSpinner/LoadingSpinner';

interface LinksContainerProps {
  loading: boolean;
  title: string;
  moreLink?: string;
  moreText?: string;
  className?: string;
}

/**
 * link container for home page bottom
 * @param loading loading
 * @param children children
 * @param title container title
 * @param moreLink more link
 * @param moreText more text
 * @param className classname
 */
function LinksContainer({
  loading,
  children,
  title,
  moreLink,
  moreText,
  className,
}: PropsWithChildren<LinksContainerProps>) {
  return (
    <div className={className}>
      <div className={styles.header}>
        <h3>{title}</h3>

        {moreLink && (
          <MoreButton className={styles.viewMore} to={moreLink}>
            {moreText}
          </MoreButton>
        )}
      </div>

      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className={styles.cardsContainer}>{children}</div>
      )}
    </div>
  );
}

export default LinksContainer;
