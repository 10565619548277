import { TextareaAutosize } from '@material-ui/core';
import {
  PropsWithChildren,
  useLayoutEffect,
  useRef,
  useMemo,
  useState,
  createElement,
} from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './ValidationDataCellTextArea.module.scss';

export interface ValidationDataCellTextAreaProps {
  isEdit?: boolean;
  value: string;
  onValueChange: (newVal: string) => void;
  onDone?: () => void;
  autoHeight?: boolean;
}

function ValidationDataCellTextArea({
  isEdit = false,
  value,
  onValueChange,
  onDone,
  autoHeight = true,
}: PropsWithChildren<ValidationDataCellTextAreaProps>) {
  const inputRef = useRef<HTMLTextAreaElement | null>(null);

  const [showAll, setShowAll] = useState(false);

  const isEmpty = ['$NULL_STRING$', ''].includes(value);

  useLayoutEffect(() => {
    if (isEdit) {
      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.selectionStart = inputRef.current.selectionEnd =
          inputRef.current.value.length;
      }
    }
  }, [isEdit]);

  const arr = useMemo(() => String(value).split('\n'), [value]);
  const shortenNeeded = useMemo(() => arr.length > 10, [arr]);
  const shortenValue = useMemo(() => arr.slice(0, 10).join('\n'), [arr]);

  return (
    <div className={styles.container}>
      {isEdit ? (
        createElement(autoHeight ? TextareaAutosize : 'textarea', {
          className: styles.textarea,
          ref: inputRef,
          defaultValue: isEmpty ? '' : value,
          onChange: (el) => {
            onValueChange(el.target.value);
          },
          onBlur: () => {
            if (onDone) {
              onDone();
            }
          },
        })
      ) : isEmpty ? (
        <FormattedMessage id="validation-data-not-available" />
      ) : (
        <>
          <>{shortenNeeded && !showAll ? shortenValue : value}</>
          {shortenNeeded &&
            (showAll ? (
              <div
                className={styles.moreLessLink}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowAll(false);
                }}
              >
                - Less
              </div>
            ) : (
              <div
                className={styles.moreLessLink}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowAll(true);
                }}
              >
                + More
              </div>
            ))}
        </>
      )}
    </div>
  );
}

export default ValidationDataCellTextArea;
