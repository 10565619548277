import { PropsWithChildren } from 'react';
import styles from './MenuCard.module.scss';
import { ReactComponent as ArrowRight } from '../../../../../icons/arrow-right-gray.svg';

type MenuCardPropType = {
  title: string;
  description: string;
  onClick: () => void;
};

const MenuCard = ({
  title,
  description,
  onClick,
}: PropsWithChildren<MenuCardPropType>) => {
  return (
    <div className={styles.menuCard} onClick={onClick}>
      <div className={styles.title}>
        <p dangerouslySetInnerHTML={{ __html: title }}></p>
        <div className={styles.arrowIcon}>
          <ArrowRight />
        </div>
      </div>

      <div className={styles.description}>
        <p dangerouslySetInnerHTML={{ __html: description }}></p>
      </div>
    </div>
  );
};

export default MenuCard;
