import styles from './ContactUsModal.module.scss';
import Modal from '../Modal/Modal';
import { PropsWithoutRef } from 'react';
import ContactUsBanner from './ContactUsBanner/ContactUsBanner';
import ContactUsForm from './ContactUsForm/ContactUsForm';
import { ReactComponent as Close } from '../../../icons/close.svg';
import { PageType } from '../../../features/contactUsSlice';

interface ContactUsModalProps {
  onClose: () => void;
  page?: PageType;
  id?: number;
}

/**
 * contact us modal
 * @param onClose close handler
 */
function ContactUsModal({
  onClose,
  id,
  page,
}: PropsWithoutRef<ContactUsModalProps>) {
  return (
    <Modal onClose={onClose} hideClose={true}>
      <div className={styles.container}>
        <Close onClick={() => onClose()} className={styles.close} />
        <ContactUsBanner />

        <ContactUsForm
          onCancel={onClose}
          className={styles.formContainer}
          id={id}
          page={page}
        />
      </div>
    </Modal>
  );
}

export default ContactUsModal;
