import { FormattedMessage, useIntl } from 'react-intl';
import ExpandableItem from '../../../ExpandableItem/ExpandableItem';
import styles from './Summary.module.scss';
import Button from '../../../../../../../components/common/Button/Button';
import { useNavigate } from 'react-router';
import { RootStore } from '../../../../../../../store';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { CountryItem } from '../../../../../../../models/lookups';
import _ from 'lodash';
import { Announcement } from '../../../../../../../models/announcement';
interface SummaryProps {
  openTab: (index: number) => void;
  onSave: () => void;
  announcement: Announcement;
}

export default function Summary({
  openTab,
  onSave,
  announcement,
}: SummaryProps) {
  const intl = useIntl();
  const navigate = useNavigate();
  const { countries, regions, announcementTypesById, languagesById } =
    useSelector((state: RootStore) => state.lookups);

  const selectedCountryMap = new Map<number, boolean>();
  announcement.announcementVisibilityCountries?.forEach((country) => {
    selectedCountryMap.set(country.countryId, true);
  });

  const countryTree = useMemo(() => {
    const countriesByRegion = new Map<number, CountryItem[]>();
    countries.forEach((country) => {
      if (selectedCountryMap.has(country.id)) {
        if (!countriesByRegion.has(country.regionId)) {
          countriesByRegion.set(country.regionId, []);
        }
        countriesByRegion.get(country.regionId)?.push(country);
      }
    });
    return regions
      .filter((region) => !_.isEmpty(countriesByRegion.get(region.id)))
      .map((region) => ({
        value: region.id,
        label: region.name,
        children: (countriesByRegion.get(region.id) || []).map((country) => ({
          value: country.id,
          label: country.name,
        })),
      }));
  }, [countries, regions]);

  const detailsFields = [
    {
      labelId: 'associate-link-label',
      value: announcement.associateLink,
      type: 'link',
    },
    {
      labelId: 'announcement-title',
      value: announcement.title,
    },
    {
      labelId: 'description-label',
      value: announcement.description,
      type: 'description',
    },
    {
      labelId: 'announcement-type',
      value: announcementTypesById[announcement.announcementTypeId].name,
    },
    {
      labelId: 'language-label',
      value: _.uniq(
        announcement.translations.map(
          (translation) => languagesById[translation.languageId].name,
        ),
      ).join(', '),
    },
  ];

  const detailsSummaryRows = detailsFields.map((field) => (
    <div key={field.value} className={styles.detailsRow}>
      <div className={styles.rowTitle}>
        <FormattedMessage id={field.labelId} />
      </div>
      <div className={styles.rowValue}>
        {field.type === 'link' ? (
          <a
            className={styles.link}
            href={field.value}
            target={'_blank'}
            rel={'noreferrer'}
          >
            {field.value}
          </a>
        ) : field.type === 'description' ? (
          <div dangerouslySetInnerHTML={{ __html: field.value }}></div>
        ) : (
          field.value
        )}
      </div>
    </div>
  ));

  const visibilityBody = countryTree.map((countryGroup) => {
    return countryGroup.children.length > 0 ? (
      <div key={countryGroup.value} className={styles.countryGroup}>
        <div className={styles.countryGroupTitle}>{countryGroup.label}</div>
        <div className={styles.countryGroupGrid}>
          {countryGroup.children.map((country) => (
            <div key={country.value} className={styles.countryName}>
              {country.label}
            </div>
          ))}
        </div>
      </div>
    ) : (
      ''
    );
  });

  const cancelClicked = () => {
    navigate('/admin/annoucements');
  };

  return (
    <>
      <div className={styles.container}>
        <ExpandableItem
          className={styles.expandableItem}
          title={intl.formatMessage({ id: 'announcement-details-title' })}
          onEdit={() => openTab(0)}
        >
          <div className={styles.detailsBody}>{detailsSummaryRows}</div>
        </ExpandableItem>
        {countryTree.length > 0 && (
          <ExpandableItem
            className={styles.expandableItem}
            title={intl.formatMessage({ id: 'announcement-visibility-title' })}
            onEdit={() => openTab(1)}
          >
            <div className={styles.visibilityBody}>{visibilityBody}</div>
          </ExpandableItem>
        )}
      </div>
      <div className={styles.actionRow}>
        <Button
          onClick={cancelClicked}
          color={'green-outline'}
          className={styles.cancelButton}
        >
          <FormattedMessage id={'cancel-label'} />
        </Button>
        <Button
          onClick={onSave}
          color={'green'}
          disabled={false}
          className={styles.saveButton}
        >
          <FormattedMessage id={'save-label'} />
        </Button>
      </div>
    </>
  );
}
