import { apiInstance } from '../api/requestInterceptor';
import {
  ManageFamilyRangeType,
  RangeFamily,
  ManageFamilyTranslationFormType,
  ManageFamilyRangeFormType,
} from '../models/admin-form-types';

/**
 * Get range families
 */
async function getRangeFamilies(onlyFamilies = true): Promise<RangeFamily[]> {
  const res = await apiInstance.get<RangeFamily[]>(
    `/families?onlyFamilies=${onlyFamilies}`,
  );

  return res.data;
}

/**
 * Get family by id
 */
async function getFamilyById(familyId: string): Promise<any> {
  const res = await apiInstance.get<any>(`/families/byId/${familyId}`);

  return res.data;
}

/**
 * Add family
 */
async function addFamily(
  family: ManageFamilyRangeType<ManageFamilyTranslationFormType>,
): Promise<ManageFamilyRangeFormType<ManageFamilyTranslationFormType>> {
  const res = await apiInstance.post<any>('/families', family);

  return res.data;
}

/**
 * Update family
 */
async function updateFamily(
  familyId: string,
  family: ManageFamilyRangeType<ManageFamilyTranslationFormType>,
): Promise<ManageFamilyRangeFormType<ManageFamilyTranslationFormType>> {
  const res = await apiInstance.put<any>(`/families/byId/${familyId}`, family);

  return res.data;
}

export { getRangeFamilies, getFamilyById, addFamily, updateFamily };
