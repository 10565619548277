import styles from './AboutDetailDivider.module.scss';

/**
 * divider for about detail
 */
function AboutDetailDivider() {
  return <div className={styles.divider} />;
}

export default AboutDetailDivider;
