/* istanbul ignore file */
import axios, { CancelTokenSource } from 'axios';
import { cloneDeep, find } from 'lodash';
import { useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import ContainerForDrawer from '../../../components/common/ContainerForDrawer/ContainerForDrawer';
import FilterContainer from '../../../components/common/FilterContainer/FilterContainer';
import FilterGroup from '../../../components/common/FilterGroup/FilterGroup';
import Input from '../../../components/common/Input/Input';
import LeftDrawer from '../../../components/common/LeftDrawer/LeftDrawer';
import LoadingSpinner from '../../../components/common/LoadingSpinner/LoadingSpinner';
import NavigationHeader from '../../../components/common/NavigationHeader/NavigationHeader';
import UserTable from '../../../components/common/UserTable/UserTable';
import RightContainer from '../../../components/common/RightContainer/RightContainer';
import SearchFilter from '../../../components/common/SearchFilter/SearchFilter';
import {
  clearAllFilters,
  clearFilter,
  toggleFilter,
  changePagination,
} from '../../../features/userFilterSlice';
import { addToast } from '../../../features/toastSlice';
import { showGenericModal } from '../../../features/genericModalSlice';
import { PreviousLink } from '../../../models/previous-link';
import { RootStore } from '../../../store';
import styles from './UserListPage.module.scss';
import {
  searchUsers,
  UserSearchCriteria,
  activateUser,
  deactivateUser,
  deleteUser,
  sendResetPasswordMail,
} from '../../../services/UserService';
import { SearchUserResponse } from '../../../models/user-response';
import withAdmin from '../../../hoc/withAdmin';
import Button from '../../../components/common/Button/Button';
import { ReactComponent as FilterIcon } from '../../../icons/filter.svg';

function UserListPage() {
  // init hooks
  const dispatch = useDispatch();
  const intl = useIntl();
  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });

  const { filters, selectedFilters, pagination, sortQuery } = useSelector(
    (state: RootStore) => state.userFilter,
  );

  const { hasWelcomeInfo } = useSelector(
    (state: RootStore) => state.welcomeInfo,
  );

  // breadcrumb links
  const breadcrumbLinks: PreviousLink[] = [
    {
      name: 'Home',
      to: '/home',
    },
    {
      name: getTranslatedMessage('header-menu-user-list'),
    },
  ];

  // init state.
  const [previousSource, setPreviousSource] = useState<CancelTokenSource>();
  const [initialLoad, setInitialLoad] = useState(true);
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [changedPageNum, setChangedPageNum] = useState<boolean>(false);
  const [nameFilter, setNameFilter] = useState('');
  const [emailFilter, setEmailFilter] = useState('');
  const [userResponse, setUserResponse] = useState<SearchUserResponse>();
  const [openFilter, setOpenFilter] = useState<boolean>(false);

  // effects

  useEffect(() => {
    if (hasWelcomeInfo) {
      if (initialLoad) {
        dispatch(clearAllFilters());
      }
      if (!changedPageNum) {
        searchUser();
      }
      setChangedPageNum(false);
      setInitialLoad(false);
    }
  }, [dispatch, pagination, sortQuery, hasWelcomeInfo]);

  // local functions

  const searchUser = (firstPage = false, noFilter = false) => {
    const criteria: UserSearchCriteria = {
      page: firstPage ? 1 : pagination.page,
      perPage: pagination.perPage,
      sortBy: sortQuery.sortBy,
      sortDirection: sortQuery.sortDirection,
    };
    if (!noFilter && nameFilter && nameFilter.length > 0) {
      criteria.name = nameFilter;
    }
    if (!noFilter && emailFilter && emailFilter.length > 0) {
      criteria.email = emailFilter;
    }
    if (
      !noFilter &&
      selectedFilters &&
      selectedFilters.roles &&
      selectedFilters.roles.length > 0
    ) {
      criteria.roles = selectedFilters.roles.map((item) => item.value);
    }

    if (
      !noFilter &&
      selectedFilters &&
      selectedFilters.visibilities &&
      selectedFilters.visibilities.length > 0
    ) {
      criteria.visibilities = selectedFilters.visibilities.map(
        (item) => item.value,
      );
    }

    setLoading(true);
    setOpenFilter(false);

    if (previousSource) {
      previousSource.cancel('cancel');
    }
    const source = axios.CancelToken.source();

    searchUsers(criteria, source.token)
      .then((res) => {
        const tmpData = cloneDeep(res);
        tmpData.items = res.items.map((item) => ({
          ...item,
          fullName: item.firstName + ' ' + item.lastName,
        }));
        setUserResponse(tmpData);
      })
      .catch(() => {
        dispatch(
          addToast({
            type: 'error',
            title: getTranslatedMessage('user-search-failure'),
            message: '',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });

    setPreviousSource(source);
  };

  const clearFilters = () => {
    setNameFilter('');
    setEmailFilter('');
    dispatch(clearAllFilters());
    searchUser(true, true);
  };

  const activateUserFunc = (userId: number): void => {
    setProcessing(true);
    activateUser(userId + '')
      .then(() => {
        dispatch(
          showGenericModal({
            titleId: 'activate-user-label',
            messageId: 'activate-user-successful',
          }),
        );
        // change the user status directly
        const userData = cloneDeep(userResponse);
        const itemData = find(userData?.items, (item) => item.id === userId);
        if (itemData) {
          itemData.active = true;
        }
        setUserResponse(userData);
      })
      .catch(() => {
        dispatch(
          addToast({
            type: 'error',
            title: getTranslatedMessage('activate-user-failure'),
            message: '',
          }),
        );
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const deactivateUserFunc = (userId: number): void => {
    setProcessing(true);
    deactivateUser(userId + '')
      .then(() => {
        dispatch(
          showGenericModal({
            titleId: 'deactivate-user-label',
            messageId: 'deactivate-user-successful',
          }),
        );
        // change the user status directly
        const userData = cloneDeep(userResponse);
        const itemData = find(userData?.items, (item) => item.id === userId);
        if (itemData) {
          itemData.active = false;
        }
        setUserResponse(userData);
      })
      .catch(() => {
        dispatch(
          addToast({
            type: 'error',
            title: getTranslatedMessage('deactivate-user-failure'),
            message: '',
          }),
        );
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const deleteUserFunc = (userId: number): void => {
    setProcessing(true);
    deleteUser(userId + '')
      .then(() => {
        dispatch(
          showGenericModal({
            titleId: 'delete-user-label',
            messageId: 'delete-user-successful',
          }),
        );
        setChangedPageNum(true);
        dispatch(
          changePagination({
            page: 1,
            perPage: pagination.perPage,
          }),
        );
        searchUser(true);
      })
      .catch(() => {
        dispatch(
          addToast({
            type: 'error',
            title: getTranslatedMessage('delete-user-failure'),
            message: '',
          }),
        );
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const resetPasswordFunc = (userEmail: string): void => {
    setProcessing(true);
    sendResetPasswordMail(userEmail)
      .then(() => {
        dispatch(
          showGenericModal({
            titleId: 'reset-password-label',
            messageId: 'reset-password-successful',
          }),
        );
      })
      .catch(() => {
        dispatch(
          addToast({
            type: 'error',
            title: getTranslatedMessage('reset-password-failure'),
            message: '',
          }),
        );
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  return (
    <div>
      <NavigationHeader
        breadcrumbLinks={breadcrumbLinks}
        className={styles.navigationHeader}
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
      />
      <ContainerForDrawer
        classes={openFilter ? styles.filterOpen : styles.filterClose}
      >
        <LeftDrawer>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <SearchFilter>
              <FilterContainer
                title={getTranslatedMessage('name-label')}
                className={styles.filterGroup}
              >
                <Input
                  value={nameFilter}
                  onChange={(_value: string) => {
                    setNameFilter(_value);
                  }}
                  placeholder={getTranslatedMessage('user-name-placeholder')}
                />
              </FilterContainer>
              <FilterContainer
                title={getTranslatedMessage('email-address-label')}
                className={styles.filterGroup}
              >
                <Input
                  value={emailFilter}
                  onChange={(_value: string) => {
                    setEmailFilter(_value);
                  }}
                  placeholder={getTranslatedMessage('email-placeholder')}
                />
              </FilterContainer>
              <FilterGroup
                filters={filters.roles}
                onChange={(filter) => {
                  dispatch(
                    toggleFilter({
                      key: 'roles',
                      filter,
                    }),
                  );
                }}
                onReset={() => {
                  dispatch(
                    clearFilter({
                      key: 'roles',
                    }),
                  );
                }}
                selectedOptions={selectedFilters.roles}
                title={intl.formatMessage({ id: 'user-role-label' })}
                open={true}
                className={styles.filterGroup}
              />
              <FilterGroup
                filters={filters.visibilities}
                onChange={(filter) => {
                  dispatch(
                    toggleFilter({
                      key: 'visibilities',
                      filter,
                    }),
                  );
                }}
                onReset={() => {
                  dispatch(
                    clearFilter({
                      key: 'visibilities',
                    }),
                  );
                }}
                selectedOptions={selectedFilters.visibilities}
                title={intl.formatMessage({ id: 'visibility-label' })}
                open={true}
                className={styles.filterGroup}
              />
              <div className={styles.actionBtnContainer}>
                <Button
                  onClick={searchUser}
                  color={'green'}
                  className={styles.applyButton}
                >
                  <FormattedMessage id={'apply-label'} />
                </Button>
                <Button
                  onClick={clearFilters}
                  color={'transparent'}
                  className={styles.clearButton}
                >
                  <FormattedMessage id={'clear-filters-label'} />
                </Button>
              </div>
            </SearchFilter>
          )}
        </LeftDrawer>
        <RightContainer>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <div>
              <div className={styles.tableTitle}>
                <FormattedMessage id={'header-menu-user-list'} />
                <FilterIcon
                  className={styles.filterButton}
                  onClick={() => setOpenFilter(true)}
                ></FilterIcon>
              </div>
              {userResponse && userResponse.items.length > 0 && (
                <UserTable
                  userResponse={userResponse}
                  activateUser={activateUserFunc}
                  deactivateUser={deactivateUserFunc}
                  deleteUser={deleteUserFunc}
                  resetPassword={resetPasswordFunc}
                />
              )}
              {userResponse && userResponse.items.length === 0 && (
                <div className={styles.noItems}>
                  <FormattedMessage id={'no-items-display-label'} />
                </div>
              )}
            </div>
          )}
        </RightContainer>
      </ContainerForDrawer>
      {processing && <LoadingSpinner className={'lookupsLoading'} />}
    </div>
  );
}

export default withAdmin(UserListPage, true);
