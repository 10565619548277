import { createSlice } from '@reduxjs/toolkit';

export interface LoadingSliceState {
  loading: boolean;
}

const initialState: LoadingSliceState = {
  loading: false,
};

/**
 * manage loading states
 */
export const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    showLoading: (): LoadingSliceState => ({
      loading: true,
    }),
    hideLoading: (): LoadingSliceState => ({
      loading: false,
    }),
  },
});

export const { showLoading, hideLoading } = loadingSlice.actions;

export default loadingSlice.reducer;
