import saveAs from 'file-saver';
import moment from 'moment';
import { apiInstance } from '../api/requestInterceptor';
import { UserActivity } from '../models/user-acitiviy-report-types';

/**
 * get quick stats
 * @param {String} month month
 * @param {String} year year
 * @param {Array} countryIds country ids
 * @param {Array} languageIds language ids
 */
async function getUserStats(
  month?: string,
  year?: string,
  countryIds?: number[],
  languageIds?: number[],
) {
  const res = await apiInstance.get<any>('/reports/quickStats', {
    params: {
      month,
      year,
      countryIds: countryIds?.length ? countryIds?.join(',') : null,
      languageIds: languageIds?.length ? languageIds?.join(',') : null,
    },
  });
  return res.data;
}

/**
 * get port product report
 * @param {Number} page
 * @param {Number} perPage
 * @param {String} sortBy
 * @param {String} sortDirection
 * @param {Array} countryIds country ids
 * @param {Boolean} exportExcel export excel,
 */
async function getPortProductReport(
  page = 1,
  perPage = 10,
  sortBy: string,
  sortDirection: string,
  countryIds: any,
  exportExcel = false,
) {
  if (exportExcel) {
    const res = await apiInstance.get<any>('/reports/portProducts', {
      responseType: 'blob',
      headers: {
        Accept:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      params: {
        countryIds: countryIds?.length ? countryIds?.join(',') : null,
      },
    });
    if (res.status === 200) {
      const filename =
        res.headers['content-disposition']?.split('filename=')[1] ||
        'Port_Product_Report.xlsx';
      saveAs(
        res.data,
        decodeURIComponent(filename.replace('"', '').replace('"', '')),
      );
    }
    return res;
  } else {
    const res = await apiInstance.get<UserActivity>('/reports/portProducts', {
      params: {
        page,
        perPage,
        sortBy,
        sortDirection,
        countryIds: countryIds?.length ? countryIds?.join(',') : null,
      },
    });
    return res.data;
  }
}

/**
 * get countries for report
 */
async function getReportCountries() {
  const res = await apiInstance.get<any>('/reports/portCountryFilter');
  return res.data;
}

/**
 * get port product by country
 * @param {Array} countryIds country ids
 */
async function getPortProductByCountry(countryIds: any) {
  const res = await apiInstance.get<any>('/reports/portProductsByCountry', {
    responseType: 'blob',
    headers: {
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    params: {
      countryIds: countryIds?.length ? countryIds?.join(',') : null,
    },
  });
  if (res.status === 200) {
    const filename =
      res.headers['content-disposition']?.split('filename=')[1] ||
      'Port_Products_By_Country_Report.xlsx';
    saveAs(
      res.data,
      decodeURIComponent(filename.replace('"', '').replace('"', '')),
    );
  }

  return res;
}

/**
 * get user activity report
 * @param {Number} page
 * @param {Number} perPage
 * @param {String} sortBy
 * @param {String} sortDirection
 * @param {String} name
 * @param {String} email email address
 * @param {String} roles
 * @param {String} startDate start date
 * @param {String} endDate end date
 * @param {Array} countryIds country ids
 * @param {Boolean} exportExcel export excel,
 */
async function getUserActivityReport(
  page = 1,
  perPage = 10,
  sortBy: string,
  sortDirection: string,
  name: string,
  email: string,
  roles: string,
  startDate: string,
  endDate: string,
  countryIds: number[] | string[],
  exportExcel = false,
) {
  if (exportExcel) {
    const res = await apiInstance.get<any>('/reports/userActivities', {
      responseType: 'blob',
      headers: {
        Accept:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      params: {
        name: name?.length ? name : null,
        email: email?.length ? email : null,
        roles: roles?.length ? roles : null,
        startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
        endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
        countryIds: countryIds?.length ? countryIds?.join(',') : null,
      },
    });
    if (res.status === 200) {
      const filename =
        res.headers['content-disposition']?.split('filename=')[1] ||
        'User_Activities_Report.xlsx';
      saveAs(
        res.data,
        decodeURIComponent(filename.replace('"', '').replace('"', '')),
      );
    }
    return res;
  } else {
    const res = await apiInstance.get<UserActivity>('/reports/userActivities', {
      params: {
        page,
        perPage,
        sortBy,
        sortDirection,
        name: name?.length ? name : null,
        email: email?.length ? email : null,
        roles: roles?.length ? roles : null,
        startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
        endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
        countryIds: countryIds?.length ? countryIds?.join(',') : null,
      },
    });
    return res.data;
  }
}

/**
 * get product activities
 * @param {String} name name
 * @param {Array} familyIds family ids
 * @param {Array} categoryIds category ids
 * @param {Array} sectorIds sector ids
 * @param {Array} roles roles
 * @param {String} startDate start date
 * @param {String} endDate end date
 * @param {Array} countryIds country ids
 * @param {String} month string
 * @param {String} year string
 */
async function getProductActivities(
  name: string,
  familyIds?: string[],
  categoryIds?: string[],
  sectorIds?: string[],
  roles?: string[],
  startDate?: string | null,
  endDate?: string | null,
  countryIds?: string[] | null,
): Promise<any> {
  const res = await apiInstance.get<any>('/reports/productActivities', {
    responseType: 'blob',
    headers: {
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    params: {
      name: name.length ? name : null,
      familyIds: familyIds?.length ? familyIds?.join(',') : null,
      categoryIds: categoryIds?.length ? categoryIds?.join(',') : null,
      sectorIds: sectorIds?.length ? sectorIds?.join(',') : null,
      roles: roles?.length ? roles?.join(',') : null,
      startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
      endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
      countryIds: countryIds?.length ? countryIds?.join(',') : null,
    },
  });

  if (res.status === 200) {
    const filename =
      res.headers['content-disposition']?.split('filename=')[1] ||
      'Marine_Data_Report.xlsx';
    saveAs(
      res.data,
      decodeURIComponent(filename.replace('"', '').replace('"', '')),
    );
  }

  return res;
}

/**
 * get OEM activities
 * @param {Number} page
 * @param {Number} perPage
 * @param {String} sortBy
 * @param {String} sortDirection
 * @param {String} name name
 * @param {Array} familyIds family ids
 * @param {Array} productIds product ids
 * @param {Array} sectorIds sector ids
 * @param {Array} roles roles
 * @param {String} startDate start date
 * @param {String} endDate end date
 * @param {Boolean} exportExcel export excel,
 */
async function getOemActivities(
  page: number,
  perPage: number,
  sortBy: string,
  sortDirection: string,
  name: string,
  familyIds?: string[],
  productIds?: string[],
  sectorIds?: string[],
  role?: string,
  startDate?: string | null,
  endDate?: string | null,
  exportExcel = false,
): Promise<any> {
  if (exportExcel) {
    const res = await apiInstance.get<any>('/reports/oemActivities', {
      responseType: 'blob',
      headers: {
        Accept:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      params: {
        name: name.length ? name : null,
        familyIds: familyIds?.length ? familyIds?.join(',') : null,
        productIds: productIds?.length ? productIds?.join(',') : null,
        sectorIds: sectorIds?.length ? sectorIds?.join(',') : null,
        roles: role ? [role] : null,
        startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
        endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
      },
    });
    if (res.status === 200) {
      const filename =
        res.headers['content-disposition']?.split('filename=')[1] ||
        'Marine_Data_Report.xlsx';
      saveAs(
        res.data,
        decodeURIComponent(filename.replace('"', '').replace('"', '')),
      );
    }

    return res;
  } else {
    const res = await apiInstance.get<any>('/reports/oemActivities', {
      params: {
        page,
        perPage,
        sortBy,
        sortDirection,
        name: name.length ? name : null,
        familyIds: familyIds?.length ? familyIds?.join(',') : null,
        productIds: productIds?.length ? productIds?.join(',') : null,
        sectorIds: sectorIds?.length ? sectorIds?.join(',') : null,
        roles: role ? [role] : null,
        startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
        endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
      },
    });
    return res.data;
  }
}

/**
 * get Port Page report
 * @param {Number} page
 * @param {Number} perPage
 * @param {String} sortBy
 * @param {String} sortDirection
 * @param {String} name name
 * @param {Array} roles roles
 * @param {String} startDate start date
 * @param {String} endDate end date
 * @param {Boolean} exportExcel export excel,
 */
async function getPortPageReport(
  page: number,
  perPage: number,
  sortBy: string,
  sortDirection: string,
  roles?: string[] | null,
  startDate?: string | null,
  endDate?: string | null,
  exportExcel = false,
): Promise<any> {
  if (exportExcel) {
    const res = await apiInstance.get<any>('/reports/portPageActivities', {
      responseType: 'blob',
      headers: {
        Accept:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      params: {
        roles,
        startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
        endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
      },
    });
    if (res.status === 200) {
      const filename =
        res.headers['content-disposition']?.split('filename=')[1] ||
        'Port_Page_Report.xlsx';
      saveAs(
        res.data,
        decodeURIComponent(filename.replace('"', '').replace('"', '')),
      );
    }

    return res;
  } else {
    const res = await apiInstance.get<any>('/reports/portPageActivities', {
      params: {
        page,
        perPage,
        sortBy,
        sortDirection,
        roles,
        startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
        endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
      },
    });
    return res.data;
  }
}

/**
 * get Marine Data
 */
async function getMarineData(): Promise<any> {
  const res = await apiInstance.get<any>('/reports/marineData', {
    responseType: 'blob',
  });
  if (res.status === 200) {
    const filename =
      res.headers['content-disposition']?.split('filename=')[1] ||
      'Marine_Data_Report.xlsx';
    saveAs(
      res.data,
      decodeURIComponent(filename.replace('"', '').replace('"', '')),
    );
  }

  return res;
}

/**
 * get Request Report Data
 */
async function getRequestReport(): Promise<any> {
  const res = await apiInstance.get<any>('/reports/contactUsRequests', {
    responseType: 'blob',
  });
  if (res.status === 200) {
    const filename =
      res.headers['content-disposition']?.split('filename=')[1] ||
      'Request_Report.xlsx';
    saveAs(
      res.data,
      decodeURIComponent(filename.replace('"', '').replace('"', '')),
    );
  }

  return res;
}

export {
  getUserStats,
  getPortProductReport,
  getUserActivityReport,
  getProductActivities,
  getPortProductByCountry,
  getOemActivities,
  getPortPageReport,
  getMarineData,
  getRequestReport,
  getReportCountries,
};
