import styles from './HomeSearch.module.scss';
import { ReactComponent as Search } from '../../../../icons/search.svg';
import { FormattedMessage } from 'react-intl';
import Button from '../../../common/Button/Button';
import { ReactComponent as List } from '../../../../icons/list.svg';
import { ReactComponent as Settings } from '../../../../icons/settings.svg';
import { ReactComponent as Add } from '../../../../icons/add.svg';
import { ReactComponent as Close } from '../../../../icons/close-white.svg';
import { ReactComponent as ArrowRight } from '../../../../icons/arrow-right.svg';
import cn from 'classnames';
import React, {
  PropsWithoutRef,
  useLayoutEffect,
  useMemo,
  useState,
  useEffect,
} from 'react';
import CategorySearchPopup from './CategorySearchPopup/CategorySearchPopup';
import ApplicationSearchPopup from './ApplicationSearchPopup/ApplicationSearchPopup';
import ProductTypeSearchPopup from './ProductTypeSearchPopup/ProductTypeSearchPopup';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  setApplicationId,
  setCategoryId,
  setProductTypeId,
} from '../../../../features/browseProductSlice';
import { LookupItem } from '../../../../models/lookups';
import {
  getBrowseCaseStudyCount,
  getBrowseProductCount,
} from '../../../../services/ProductService';
import { MountService } from '../../../../services/MountService';
import { RootStore } from '../../../../store';
import LoadingSpinner from '../../../common/LoadingSpinner/LoadingSpinner';
import getSearchPlaceholderId from '../../../../utils/common.util';
import { generateQueryString } from '../../../../utils/api.util';
import config from '../../../../configs/config';
import SubSectorSearchPopup from './SubSectorSearchPopup/SubSectorSearchPopup';
import CaseStudyApplicationSearchPopup from './CaseStudyApplicationSearchPopup/CaseStudyApplicationSearchPopup';

interface HomeSearchProps {
  onSearchClick: () => void;
  hasAnnouncementsNoPromotions: boolean;
}

/**
 * home search component
 * @param onSearchClick search click handler
 */
function HomeSearch({
  onSearchClick,
  hasAnnouncementsNoPromotions,
}: PropsWithoutRef<HomeSearchProps>) {
  const dispatch = useDispatch();
  const mountService: MountService = useMemo(() => new MountService(), []);
  const { culture, sector } = useSelector(
    (state: RootStore) => state.welcomeInfo,
  );
  const { hasLogin } = useSelector((state: RootStore) => state.login);

  const [categoryOpened, setCategoryOpened] = useState(false);
  const [applicationOpened, setApplicationOpened] = useState(false);
  const [caseStudyApplicationOpened, setCaseStudyApplicationOpened] =
    useState(false);
  const [productTypeOpened, setProductTypeOpened] = useState(false);
  const [subSectorTypeOpened, setsubSectorTypeOpened] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<LookupItem>();
  const [selectedApplication, setSelectedApplication] = useState<LookupItem>();
  const [selectedProductType, setSelectedProductType] = useState<LookupItem>();
  const [selectedSubSector, setSelectedSubSector] = useState<LookupItem>();
  const [selectedCaseStudyApplication, setSelectedCaseStudyApplication] =
    useState<LookupItem>();
  const [totalProducts, setTotalProducts] = useState(0);
  const [totalCaseStudies, setTotalCaseStudies] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingCS, setloadingCS] = useState(false);
  const [previousCulture, setPreviousCulture] = useState(culture);
  const [previousSector, setPreviousSector] = useState(sector);

  const navigate = useNavigate();

  const hasSelected = !!(
    selectedCategory ||
    selectedApplication ||
    selectedProductType
  );
  const hasSelectedCaseStudy = !!(
    selectedSubSector || selectedCaseStudyApplication
  );

  mountService.addDispatch('setTotalProducts', setTotalProducts);
  mountService.addDispatch('setTotalCaseStudies', setTotalCaseStudies);
  mountService.addDispatch('setLoading', setLoading);
  mountService.addDispatch('setloadingCS', setloadingCS);
  mountService.addDispatch('setPreviousCulture', setPreviousCulture);
  mountService.addDispatch('setPreviousSector', setPreviousSector);
  mountService.addDispatch('setSelectedCategory', setSelectedCategory);
  mountService.addDispatch('setSelectedApplication', setSelectedApplication);

  useEffect(() => {
    onApplicationSelectionReset();
    onCategorySelectionReset();
    onProductTypeSelectionReset();
  }, [dispatch]);

  useLayoutEffect(() => {
    mountService.mount();

    if (previousCulture !== culture || previousSector !== sector) {
      onApplicationSelectionReset();
      onCategorySelectionReset();
      onProductTypeSelectionReset();

      mountService.callDispatch('setPreviousCulture', culture);
      mountService.callDispatch('setPreviousSector', sector);
    }

    return () => {
      mountService.unmount();
    };
  }, [previousCulture, previousSector, culture, sector]);

  const onCategorySelected = (item: LookupItem) => {
    setCategoryOpened(false);
    setSelectedCategory(item);
    dispatch(setCategoryId(item.id));
  };

  const onCategorySelectionReset = () => {
    setSelectedCategory(undefined);
    dispatch(setCategoryId(undefined));
  };

  const onApplicationSelected = (item: LookupItem) => {
    setApplicationOpened(false);
    setSelectedApplication(item);
    dispatch(setApplicationId(item.id));
  };

  const onApplicationSelectionReset = () => {
    setSelectedApplication(undefined);
    dispatch(setApplicationId(undefined));
  };

  const onProductTypeSelected = (item: LookupItem) => {
    setProductTypeOpened(false);
    setSelectedProductType(item);
    dispatch(setProductTypeId(item.id));
  };
  const onProductTypeSelectionReset = () => {
    setSelectedProductType(undefined);
    dispatch(setProductTypeId(undefined));
  };
  const onsubSectorTypeSelected = (item: LookupItem) => {
    setsubSectorTypeOpened(false);
    setSelectedSubSector(item);
    // dispatch(setProductTypeId(item.id)); // need to work
  };
  const subSectorTypeSelectionReset = () => {
    setSelectedSubSector(undefined);
    // dispatch(setProductTypeId(undefined)); // need to work
  };

  const onCaseStudyApplicationSelected = (item: LookupItem) => {
    setCaseStudyApplicationOpened(false);
    setSelectedCaseStudyApplication(item);
    // dispatch(setApplicationId(item.id)); // need to work
  };

  const onCaseStudyApplicationSelectionReset = () => {
    setSelectedCaseStudyApplication(undefined);
    // dispatch(setApplicationId(undefined)); // need to work
  };

  const onBrowseClick = () => {
    const query = {
      from: 'browse',
      tab: config.PRODUCT_TYPE,
      category: selectedCategory?.id,
      application: selectedApplication?.id,
      productType: selectedProductType?.id,
    };

    navigate('/search-result?' + generateQueryString(query));
  };
  const onBrowseCaseStudyClick = () => {
    const query = {
      subSectorId: selectedSubSector?.id,
      applicationId: selectedCaseStudyApplication?.id,
    };

    navigate('/casestudies?' + generateQueryString(query));
  };

  useLayoutEffect(() => {
    mountService.mount();

    if (hasSelected) {
      mountService.callDispatch('setLoading', true);

      getBrowseProductCount({
        categoryId: selectedCategory?.id,
        applicationId: selectedApplication?.id,
        productTypeId: selectedProductType?.id,
      })
        .then((res) => {
          mountService.callDispatch('setTotalProducts', res.productCount);
        })
        .finally(() => {
          mountService.callDispatch('setLoading', false);
        });
    }

    return () => {
      mountService.unmount();
    };
  }, [
    mountService,
    culture,
    sector,
    selectedCategory,
    selectedApplication,
    selectedProductType,
  ]);
  useLayoutEffect(() => {
    mountService.mount();

    if (hasSelectedCaseStudy) {
      mountService.callDispatch('setloadingCS', true);

      getBrowseCaseStudyCount({
        subSectorId: selectedSubSector?.id,
        applicationId: selectedCaseStudyApplication?.id,
      })
        .then((res) => {
          mountService.callDispatch('setTotalCaseStudies', res.productCount);
        })
        .finally(() => {
          mountService.callDispatch('setloadingCS', false);
        });
    }

    return () => {
      mountService.unmount();
    };
  }, [mountService, selectedSubSector, selectedCaseStudyApplication]);

  const searchPlaceholderId = getSearchPlaceholderId(sector, '', hasLogin);

  return (
    <div
      className={cn(
        styles.container,
        hasAnnouncementsNoPromotions && styles.hasAnnouncementsNoPromotions,
      )}
    >
      <div className={styles.innerContainer}>
        <div onClick={() => onSearchClick()} className={styles.searchInput}>
          <Search />

          <div className={styles.searchPlaceholder}>
            <FormattedMessage id={searchPlaceholderId} />
            ...
          </div>
        </div>

        <div
          className={cn(
            styles.dividerContainer,
            hasAnnouncementsNoPromotions && styles.hasAnnouncementsNoPromotions,
          )}
        >
          <div className={styles.dividerLine} />
          <div className={styles.dividerText}>
            <FormattedMessage id={'or-browse-label'} />
          </div>
          <div className={styles.dividerLine} />
        </div>

        <div className={styles.browseButtonContainer}>
          {sector === 'industrial' && (
            <div
              className={cn(
                styles.buttonContainer,
                styles.browseProductButtonBox,
              )}
            >
              <Button
                onClick={() => setProductTypeOpened(true)}
                color={'green'}
                className={styles.browseProductButton}
              >
                <div className={styles.buttonLeftGroup}>
                  <List className={styles.buttonIcon} />

                  {selectedProductType ? (
                    selectedProductType.name
                  ) : (
                    <FormattedMessage id={'browse-product-group-label'} />
                  )}
                </div>
                <div className={styles.iconContainer}>
                  {selectedProductType && (
                    <Close
                      className={styles.closeIcon}
                      onClick={(e) => {
                        e.stopPropagation();
                        onProductTypeSelectionReset();
                        onCategorySelectionReset();
                      }}
                    />
                  )}
                  {!selectedProductType && <Add />}
                </div>
              </Button>

              {productTypeOpened && (
                <ProductTypeSearchPopup
                  onItemClick={onProductTypeSelected}
                  onClose={() => setProductTypeOpened(false)}
                />
              )}
            </div>
          )}

          <div
            className={cn(
              styles.buttonContainer,
              styles.browseProductButtonBox,
            )}
          >
            <Button
              onClick={() => setCategoryOpened(true)}
              color={'green'}
              className={styles.browseProductButton}
              disabled={sector === 'industrial' && !selectedProductType}
            >
              <div className={styles.buttonLeftGroup}>
                <List className={styles.buttonIcon} />

                {selectedCategory ? (
                  selectedCategory.name
                ) : (
                  <FormattedMessage id={'browse-product-category-label'} />
                )}
              </div>
              <div className={styles.iconContainer}>
                {selectedCategory && (
                  <Close
                    className={styles.closeIcon}
                    onClick={(e) => {
                      e.stopPropagation();
                      onCategorySelectionReset();
                    }}
                  />
                )}
                {!selectedCategory && <Add />}
              </div>
            </Button>

            {categoryOpened && (
              <CategorySearchPopup
                onItemClick={onCategorySelected}
                onClose={() => setCategoryOpened(false)}
              />
            )}
          </div>

          {sector !== 'industrial' && (
            <div
              className={cn(
                styles.buttonContainer,
                styles.browseProductButtonBox,
              )}
            >
              <Button
                onClick={() => setApplicationOpened(true)}
                color={'green'}
                className={styles.browseProductButton}
              >
                <div className={styles.buttonLeftGroup}>
                  <Settings className={styles.buttonIcon} />

                  {selectedApplication ? (
                    selectedApplication.name
                  ) : (
                    <FormattedMessage id={'browse-product-application-label'} />
                  )}
                </div>

                <div className={styles.iconContainer}>
                  {selectedApplication && (
                    <Close
                      className={styles.closeIcon}
                      onClick={(e) => {
                        e.stopPropagation();
                        onApplicationSelectionReset();
                      }}
                    />
                  )}
                  {!selectedApplication && <Add />}
                </div>
              </Button>

              {applicationOpened && (
                <ApplicationSearchPopup
                  onItemClick={onApplicationSelected}
                  onClose={() => setApplicationOpened(false)}
                />
              )}
            </div>
          )}

          <div className={cn(styles.buttonContainer, styles.browseButtonBox)}>
            <Button
              onClick={() => onBrowseClick()}
              disabled={!hasSelected || loading}
              color={'ruby'}
              className={styles.browseButton}
            >
              {hasSelected ? (
                <FormattedMessage
                  id={'browse-product-label-with-count'}
                  values={{
                    count: totalProducts,
                  }}
                />
              ) : (
                <FormattedMessage id={'browse-product-label'} />
              )}
              <ArrowRight className={styles.buttonArrowRight} />

              {loading && <LoadingSpinner className={styles.buttonLoading} />}
            </Button>
          </div>
        </div>
        {hasLogin && sector === 'industrial' && (
          <div className={styles.browseButtonContainer}>
            <div
              className={cn(
                styles.buttonContainer,
                styles.browseProductButtonBox,
              )}
            >
              <Button
                onClick={() => setsubSectorTypeOpened(true)}
                color={'green'}
                className={styles.browseProductButton}
              >
                <div className={styles.buttonLeftGroup}>
                  <List className={styles.buttonIcon} />

                  {selectedSubSector ? (
                    selectedSubSector.name
                  ) : (
                    <FormattedMessage id={'browse-casestudy-subsector'} />
                  )}
                </div>
                <div className={styles.iconContainer}>
                  {selectedSubSector && (
                    <Close
                      className={styles.closeIcon}
                      onClick={(e) => {
                        e.stopPropagation();
                        subSectorTypeSelectionReset();
                      }}
                    />
                  )}
                  {!selectedSubSector && <Add />}
                </div>
              </Button>

              {subSectorTypeOpened && (
                <SubSectorSearchPopup
                  onItemClick={onsubSectorTypeSelected}
                  onClose={() => setsubSectorTypeOpened(false)}
                />
              )}
            </div>
            {/* casestudy-subsector close*/}

            <div
              className={cn(
                styles.buttonContainer,
                styles.browseProductButtonBox,
              )}
            >
              <Button
                onClick={() => setCaseStudyApplicationOpened(true)}
                color={'green'}
                className={styles.browseProductButton}
              >
                <div className={styles.buttonLeftGroup}>
                  <Settings className={styles.buttonIcon} />

                  {selectedCaseStudyApplication ? (
                    selectedCaseStudyApplication.name
                  ) : (
                    <FormattedMessage
                      id={'browse-casestudy-application-label'}
                    />
                  )}
                </div>

                <div className={styles.iconContainer}>
                  {selectedCaseStudyApplication && (
                    <Close
                      className={styles.closeIcon}
                      onClick={(e) => {
                        e.stopPropagation();
                        onCaseStudyApplicationSelectionReset();
                      }}
                    />
                  )}
                  {!selectedCaseStudyApplication && <Add />}
                </div>
              </Button>

              {caseStudyApplicationOpened && (
                <CaseStudyApplicationSearchPopup
                  onItemClick={onCaseStudyApplicationSelected}
                  onClose={() => setCaseStudyApplicationOpened(false)}
                />
              )}
            </div>
            {/* casestudy-application close*/}
            <div className={cn(styles.buttonContainer, styles.browseButtonBox)}>
              <Button
                onClick={() => onBrowseCaseStudyClick()}
                disabled={
                  !hasSelectedCaseStudy || loadingCS || totalCaseStudies == 0
                }
                color={'ruby'}
                className={styles.browseButton}
              >
                {hasSelectedCaseStudy ? (
                  <FormattedMessage
                    id={'browse-casestudy-label-with-count'}
                    values={{
                      count: totalCaseStudies,
                    }}
                  />
                ) : (
                  <FormattedMessage id={'browse-caseStudy-label'} />
                )}
                <ArrowRight className={styles.buttonArrowRight} />

                {loadingCS && (
                  <LoadingSpinner className={styles.buttonLoading} />
                )}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default HomeSearch;
