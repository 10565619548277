import numeral from 'numeral';
import _ from 'lodash';

import config from '../configs/config';
import { ReactNode } from 'react';
import { urlValidationPattern } from '../configs/admin-constants';

/**
 * Find suitable Search placeholder Id
 * @param sector the sector
 * @param suffix the suffix
 * @param hasLogin the hasLogin flag
 * @returns translation id
 */
const getSearchPlaceholderId = (
  sector: string,
  suffix = '',
  hasLogin = false,
) => {
  let searchPlaceholderId = `search-placeholder-other${suffix}`;
  if (sector === 'directory-marine-services') {
    searchPlaceholderId = hasLogin
      ? `search-with-oem-placeholder${suffix}`
      : `search-placeholder${suffix}`;
  }

  return searchPlaceholderId;
};

/**
 * Convert degree number to degree, minute, second components
 * @param degree degree number
 */
export function degree2Components(degree: number): number[] {
  degree = Math.abs(degree);
  const d = Math.floor(degree);
  const minutes = (degree - d) * 60;
  const m = Math.floor(minutes);
  const s = minutes - m;
  return [d, m, s];
}
/**
 * Convert latitude, longitude number to human readable string
 * @param lat latitude number
 * @param lng longitude number
 */
export function latlng2HumanReadable(
  lat: number | undefined,
  lng: number | undefined,
): string {
  if (lat === undefined || lng === undefined) {
    return 'NA';
  }
  const latComponents = degree2Components(lat);
  const lngComponents = degree2Components(lng);
  const getSign = (
    value: number,
    positiveChar: string,
    negativeChar: string,
  ) => {
    if (value > 0) {
      return positiveChar;
    } else if (value < 0) {
      return negativeChar;
    } else {
      return '';
    }
  };
  return `${lngComponents[0]}°${numeral(lngComponents[1]).format(
    '00',
  )}’${getSign(lng, 'N', 'S')} ${latComponents[0]}°${numeral(
    latComponents[1],
  ).format('00')}’${getSign(lat, 'E', 'W')}`;
}

/**
 * Check whether the given value is not null and is greater than zero
 * @param value then value
 * @returns boolean indicating whether the value is not null and is greater than zero
 */
export const valueGreaterThanZero = (value: string | number | null) =>
  value !== null && Number(value) > 0;

/**
 * Replace values of fields passed with given value
 * @param obj the object
 * @param fieldKeys the keys whose values should be replaced
 * @param value the value
 * @param compareValue the value to compare against
 * @returns obj with updated value
 */
export const replaceValues = (
  obj: any,
  fieldKeys: string[],
  value: string | number | null,
  compareValue?: any,
) => {
  const tempObj = _.cloneDeep(obj);
  for (const fieldKey of fieldKeys) {
    if (
      tempObj[fieldKey] === null ||
      tempObj[fieldKey] === '' ||
      (compareValue !== undefined && tempObj[fieldKey] === compareValue)
    ) {
      tempObj[fieldKey] = value;
    }
  }

  return tempObj;
};

/**
 * Convert string to number
 * @param obj the object
 * @param fieldKeys the keys whose values should be converted
 * @returns obj with converted type
 */
export const convertStringToNumber = (obj: any, fieldKeys: string[]) => {
  const tempObj = _.cloneDeep(obj);
  for (const fieldKey of fieldKeys) {
    if (tempObj[fieldKey]) {
      tempObj[fieldKey] = Number(tempObj[fieldKey]);
    }
  }

  return tempObj;
};

/**
 * Generate friendly url value from given value
 * @param value the value
 * @returns friendlyUrl
 */
export const generateFriendlyUrl = (value: string) => {
  let friendlyUrl = value;
  friendlyUrl = friendlyUrl.replaceAll(/\s+/g, '-').toLowerCase();
  friendlyUrl = friendlyUrl.replaceAll(config.FRIENDLY_URL_NAME_REGEX, '-');

  return friendlyUrl;
};

/**
 * Generate friendly url value from given name & country
 * @param value the value
 * @param value the value
 * @returns friendlyUrl
 */
export const generateFriendlyUrlWithCountry = (
  name: string,
  country: string,
) => {
  let friendlyUrl = `${name}-${country}`;
  friendlyUrl = friendlyUrl.replaceAll(/\s+/g, '-').toLowerCase();
  friendlyUrl = friendlyUrl.replaceAll(config.FRIENDLY_URL_NAME_REGEX, '-');

  return friendlyUrl;
};

/**
 * Trim white spaces at the start and end for all strings in an object
 * @param obj the object
 * @returns new object with trimmed values
 */
export const trimSpacesInObj = (obj: any) => {
  const keys = Object.keys(obj);
  keys.map((key: string) => {
    if (_.isString(obj[key])) {
      obj[key] = obj[key].trim();
    }
    if (_.isArray(obj[key])) {
      obj[key] = obj[key].map((item: any) => trimSpacesInObj(item));
    }
  });

  return obj;
};

/** Validate whether URL starts with http:// or https://
 * @param value the value
 * @returns true or false depending whether the string is an URL or not
 */
export const validateURL = (value: string) =>
  !new RegExp(urlValidationPattern).test(value);

/**
 * Render items conditionally incase of mobile and desktop
 */
export const responsiveRender = (
  device: 'mobile' | 'desktop',
  children: ReactNode,
) => {
  const width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  if (device === 'mobile' && width <= 599) {
    return children;
  }
  if (device === 'desktop' && width > 599) {
    return children;
  }
  return null;
};

export default getSearchPlaceholderId;
