import styles from './ProductCharacteristics.module.scss';
import { FormattedMessage } from 'react-intl';
import { PropsWithoutRef, useMemo } from 'react';
import { SearchResultProduct } from '../../../models/search-result-product';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../store';

import { resolveProductLookups } from '../../../utils/product.util';
import { productComparisonFields } from '../../common/ComparisonModal/productComparisonFields';
import { ProductComparisonDetail } from '../../../models/product-comparison-detail';

interface ProductCharacteristicsProps {
  product: SearchResultProduct;
}

/**
 * product characteristics
 * @param product product
 */
function ProductCharacteristics({
  product,
}: PropsWithoutRef<ProductCharacteristicsProps>) {
  const lookups = useSelector((rootState: RootStore) => rootState.lookups);
  const { sector } = useSelector(
    (rootState: RootStore) => rootState.welcomeInfo,
  );

  const data = useMemo<ProductComparisonDetail>(() => {
    const productTemp = resolveProductLookups([product], lookups)[0];
    return {
      castrolRecommended: productTemp.castrolRecommendedName,
      masterBrandName: productTemp.masterBrandName,
      owner: productTemp.owner,
      application: productTemp.application,
      category: productTemp.category,
      characteristics: productTemp.productCharacteristic,
      productDocuments: productTemp.productDocuments || [],
      id: productTemp.id,
      name: productTemp.name,
      sector: productTemp.productSectors.map((x) => x.name).join(', '),
      productType: productTemp.productProductTypes
        .map((x) => x.name)
        .join(', '),
      subSector: productTemp.productSubSectors.map((x) => x.name).join(', '),
    };
  }, [product]);

  return (
    <div className={styles.container}>
      <h3>
        <FormattedMessage id={'characteristics-label'} />
      </h3>

      <div className={styles.listContainer}>
        {productComparisonFields.map(
          (productComparisonField, index) =>
            productComparisonField.formattedMessageId !== 'oem-label' &&
            productComparisonField.isVisibleFunction(data, sector) && (
              <div className={styles.item} key={index}>
                <div className={styles.name}>
                  <FormattedMessage
                    id={productComparisonField.formattedMessageId}
                  />
                </div>
                <div className={styles.value}>
                  {productComparisonField.value(data)}
                </div>
              </div>
            ),
        )}
      </div>
    </div>
  );
}

export default ProductCharacteristics;
