import { PropsWithChildren } from 'react';
import styles from './OEMActivitiesTable.module.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import OemListTable from '../../../../../components/common/OemListTable/OemListTable';
import { TableColumn } from '../../../../../models/table-column';
import Paginator from '../../../../../components/common/Paginator/Paginator';
import cn from 'classnames';
import { ReactComponent as XlsxFileIcon } from '../../../../../icons/xlsx-file.svg';

type OEMActivitiesTableType = {
  reportData: any[];
  page: number;
  perPage: number;
  total: number;
  sortBy: string;
  sortDirection: 'asc' | 'desc';
  onClickPage: (page: number) => void;
  onClickPerPage: (page: number) => void;
  exportToExcel: () => void;
  onSortChange: (column: any) => void;
};

const OEMActivitiesTable = ({
  reportData,
  page,
  perPage,
  total,
  sortBy,
  sortDirection,
  onClickPage,
  onClickPerPage,
  onSortChange,
  exportToExcel,
}: PropsWithChildren<OEMActivitiesTableType>) => {
  const intl = useIntl();
  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });

  const columns: TableColumn<any>[] = [
    {
      key: 'oem.name',
      property: 'oem.name',
      label: getTranslatedMessage('oem-page-oem-name'),
      width: '14.2857142857%',
    },
    {
      key: 'product.name',
      property: 'product.name',
      label: getTranslatedMessage('port-product-page-product'),
      width: '14.2857142857%',
    },
    {
      key: 'product.firstSectorName',
      property: 'product.firstSectorName',
      label: getTranslatedMessage('reports-oems-product-sector'),
      width: '14.2857142857%',
      render: (data) => (
        <span>
          {data?.product?.productSectors
            .map((item: any) => item?.sector?.name)
            .join(', ')}
        </span>
      ),
    },
    {
      key: 'oemApprovalType.name',
      property: 'oemApprovalType.name',
      label: getTranslatedMessage('oem-table-field-approval-type'),
      width: '14.2857142857%',
    },

    {
      key: 'approvalDocument.bpConfidential',
      property: 'approvalDocument.bpConfidential',
      label: getTranslatedMessage('oem-table-field-confidentiality-level'),
      width: '14.2857142857%',
      render: (data) => (
        <span>
          {data.approvalDocument.bpConfidential
            ? getTranslatedMessage('product-summary-yes')
            : getTranslatedMessage('product-summary-no')}
        </span>
      ),
    },
    {
      key: 'numberOfViews',
      property: 'numberOfViews',
      label: getTranslatedMessage('oem-table-field-total-num-of-view'),
      width: '14.2857142857%',
    },
    {
      key: 'numberOfDownloads',
      property: 'numberOfDownloads',
      label: getTranslatedMessage('oem-table-field-total-downloads'),
      width: '14.2857142857%',
    },
  ];

  return (
    <div className={styles.tableContainer}>
      <div className={styles.table}>
        <div className={styles.tableTitle}>
          {getTranslatedMessage('port-product-page-result')}
          <div
            className={cn(styles.exportToExcel, styles.desktop)}
            onClick={() => exportToExcel()}
          >
            <XlsxFileIcon />
            <FormattedMessage id={'oem-table-field-export-to-excel'} />
          </div>
        </div>
        <OemListTable
          data={reportData}
          sortBy={sortBy}
          sortDirection={sortDirection}
          columns={columns}
          showCheckbox={false}
          onSortChange={onSortChange}
        />
        <Paginator
          page={page}
          perPage={perPage}
          total={total}
          onPageChange={onClickPage}
          onPerPageChange={onClickPerPage}
        />
      </div>
    </div>
  );
};

export default OEMActivitiesTable;
