import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import _ from 'lodash';

import Header from '../../../components/Admin/Header/Header';
import FormWrapper from '../../../components/Admin/FormWrapper/FormWrapper';
import InputRow from '../../../components/Admin/InputRow/InputRow';
import SelectFormRow from '../../../components/Admin/SelectFormRow/SelectFormRow';
import Checkbox from '../../../components/common/Checkbox/Checkbox';
import FormActionBtns from '../../../components/Admin/FormActionBtns/FormActionBtns';

import { PreviousLink } from '../../../models/previous-link';
import { CountryItem } from '../../../models/lookups';

import { RootStore } from '../../../store';

import { addToast } from '../../../features/toastSlice';
import { hideLoading, showLoading } from '../../../features/loadingSlice';
import { showGenericModal } from '../../../features/genericModalSlice';

import { addStockpoint } from '../../../services/StockpointService';

import styles from './CreateStockpoint.module.scss';
import withAdmin from '../../../hoc/withAdmin';

const breadcrumbLinks: PreviousLink[] = [
  {
    name: 'Home',
    to: '/home',
  },
  {
    name: 'Manage Stockpoint',
    to: '/admin/stockpoint',
  },
  {
    name: 'Add Stockpoint',
  },
];
const initialState = {
  name: '',
  country: null,
  active: false,
};
const CreateStockpoint = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { countries } = useSelector((state: RootStore) => state.lookups);
  const title = intl.formatMessage({ id: 'add-stockpoint-label' });
  const saveBtnLabel = intl.formatMessage({ id: 'save-label' });
  const [formValue, setFormValue] = useState(initialState);

  const saveDisabled = () => {
    return !formValue.name || !formValue.country;
  };

  const handleValueChange = (fieldKey: string, value: string | boolean) => {
    setFormValue({
      ...formValue,
      [fieldKey]: value,
    });
  };

  const handleSaveBtnClick = () => {
    const country = formValue.country as CountryItem | null;
    dispatch(showLoading());
    addStockpoint({
      ..._.omit(formValue, 'country'),
      countryId: country?.id || -1,
    })
      .then(() => {
        dispatch(
          showGenericModal({
            titleId: 'stockpoint-create-success',
            messageId: 'stockpoint-create-success-message',
            to: '/',
          }),
        );
      })
      .catch((message) => {
        dispatch(
          addToast({
            type: 'error',
            title: intl.formatMessage({ id: 'stockpoint-create-failure' }),
            message: message || '',
          }),
        );
      })
      .finally(() => {
        dispatch(hideLoading());
      });
  };

  return (
    <div>
      <Header
        titleId="add-stockpoint-label"
        breadcrumbLinks={breadcrumbLinks}
      />
      <div className={styles.container}>
        <div className={styles.content}>
          <FormWrapper title={title}>
            <InputRow
              labelId="name-label"
              value={formValue.name}
              onChange={(value: string) => handleValueChange('name', value)}
            />
            <SelectFormRow
              labelId="associated-country-label"
              value={formValue.country}
              options={countries.map((country: CountryItem) => ({
                name: country.name,
                value: country,
              }))}
              onChange={(value: string) => handleValueChange('country', value)}
            />
            <div className={styles.checkboxWrapper}>
              <Checkbox
                className={styles.checkbox}
                checked={formValue.active}
                onClick={() => handleValueChange('active', !formValue.active)}
              />
              <FormattedMessage id="active-label" />
            </div>
          </FormWrapper>
          <FormActionBtns
            saveBtnLabel={saveBtnLabel}
            saveBtnDisabled={saveDisabled()}
            showUnsavedChangesModal={!_.isEqual(initialState, formValue)}
            onSaveBtnClick={() => handleSaveBtnClick()}
          />
        </div>
      </div>
    </div>
  );
};

export default withAdmin(CreateStockpoint);
