import styles from './Breadcrumbs.module.scss';
import { PropsWithoutRef } from 'react';
import { useDispatch } from 'react-redux';
import { PreviousLink } from '../../../../models/previous-link';
// import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { showGenericModal } from '../../../../features/genericModalSlice';
import cn from 'classnames';
import { ReactComponent as ArrowRight } from '../../../../icons/arrow-right.svg';

interface BreadcrumbsProps {
  links: PreviousLink[];
  classNames?: string;
  showUnsavedChangesModal?: boolean;
}

/**
 * breadcrumbs for nav header
 */
function Breadcrumbs({
  links,
  classNames,
  showUnsavedChangesModal = false,
}: PropsWithoutRef<BreadcrumbsProps>) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const clickLink = (url: string | undefined) => {
    if (url) {
      if (showUnsavedChangesModal) {
        dispatch(
          showGenericModal({
            type: 'confirmation',
            titleId: 'unsaved-changes-label',
            messageId: 'unsaved-changes-message',
            to: url,
          }),
        );
      } else {
        navigate(url);
      }
    }
  };

  return (
    <div className={cn(styles.container, classNames)}>
      {links.map((link, index) => {
        if (link.to) {
          return (
            <div
              key={index}
              className={styles.link}
              onClick={() => clickLink(link.to)}
            >
              {link.name}

              <ArrowRight className={styles.arrow} />
            </div>
          );
        } else {
          return (
            <div key={index} className={cn(styles.link, styles.current)}>
              {link.name}
            </div>
          );
        }
      })}
    </div>
  );
}

export default Breadcrumbs;
