import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import GenericManageSkeleton from '../../../../components/Admin/GenericManageSkeleton/GenericManageSkeleton';
import Header from '../../../../components/Admin/Header/Header';
import FamilyRangeDetails from '../../../../components/Admin/ManageFamilyRangePage/FamilyRangeDetails/FamilyRangeDetails';
import LocalisedFamilyRangeDetails from '../../../../components/Admin/ManageFamilyRangePage/LocalisedFamilyRangeDetails/LocalisedFamilyRangeDetails';

import { showGenericModal } from '../../../../features/genericModalSlice';
import { hideLoading, showLoading } from '../../../../features/loadingSlice';
import { addToast } from '../../../../features/toastSlice';

import {
  ManageFamilyRangeFormType,
  ManageFamilyTranslationFormType,
  ManageFamilyRangeType,
  StepperItemType,
} from '../../../../models/admin-form-types';
import { PreviousLink } from '../../../../models/previous-link';

import {
  addFamily,
  getFamilyById,
  updateFamily,
} from '../../../../services/FamilyService';

import { generateFriendlyUrl } from '../../../../utils/common.util';
import constructTranslationsPayload, {
  constructTranslationsFromApiResponse,
  formValuesValid,
} from '../../../../utils/family-range.util';

import { RootStore } from '../../../../store';
import withAdmin from '../../../../hoc/withAdmin';

const breadcrumbLinks: PreviousLink[] = [
  {
    name: 'Home',
    to: '/home',
  },
];

const initialState: ManageFamilyRangeFormType<ManageFamilyTranslationFormType> =
  {
    name: '',
    friendlyUrl: '',
    translations: {},
  };

const ManageFamily = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { familyId } = useParams();
  const { sector, culture } = useSelector(
    (state: RootStore) => state.welcomeInfo,
  );

  const [step, setStep] = useState(0);
  const [formValue, setFormValue] = useState(initialState);

  const fieldKeys = ['description', 'shortDescription'];

  const resetStates = () => {
    setFormValue(initialState);
    setStep(0);
  };

  useEffect(() => {
    if (!familyId) {
      resetStates();
      breadcrumbLinks[1] = {
        name: 'Add Family',
      };
    } else {
      breadcrumbLinks[1] = {
        name: 'Edit Family',
      };
    }
  }, [familyId]);

  useEffect(() => {
    if (familyId) {
      dispatch(showLoading());
      getFamilyById(familyId)
        .then((data) => {
          const translations = constructTranslationsFromApiResponse(
            data,
            fieldKeys,
          );
          setFormValue({
            ...formValue,
            ..._.pick(data, ['name', 'friendlyUrl']),
            translations,
          });
        })
        .finally(() => dispatch(hideLoading()));
    }
  }, [familyId]);

  useEffect(() => {
    if (formValue.name) {
      const friendlyUrl = generateFriendlyUrl(formValue.name);
      setFormValue({
        ...formValue,
        friendlyUrl,
      });
    }
  }, [formValue.name]);

  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });

  const handleValueChange = (fieldKey: string, value: string | boolean) => {
    setFormValue({
      ...formValue,
      [fieldKey]: value,
    });
  };

  const stepperItems: StepperItemType = [
    {
      label: getTranslatedMessage('family-details-label'),
      componentToRender: (
        <FamilyRangeDetails
          formValue={formValue}
          handleValueChange={handleValueChange}
        />
      ),
    },
    {
      label: getTranslatedMessage('localised-family-details-label'),
      componentToRender: (
        <LocalisedFamilyRangeDetails
          formValue={formValue}
          handleValueChange={handleValueChange}
        />
      ),
    },
  ];

  const constructPayload = () => {
    const payload: ManageFamilyRangeType<ManageFamilyTranslationFormType> = {
      ..._.omit(formValue, 'translations'),
      description: formValue.translations[1].description,
      shortDescription: formValue.translations[1].shortDescription,
      translations: constructTranslationsPayload(
        formValue.translations,
        fieldKeys,
      ),
    };

    return payload;
  };

  const handleSaveBtnClick = () => {
    if (step === 1) {
      const payload = constructPayload();
      let req;
      let type = 'add';
      if (familyId) {
        req = updateFamily(familyId, payload);
        type = 'update';
      } else {
        req = addFamily(payload);
      }

      dispatch(showLoading());
      req
        .then(
          (
            data: ManageFamilyRangeFormType<ManageFamilyTranslationFormType>,
          ) => {
            dispatch(
              showGenericModal({
                titleId: `family-${type}-success-label`,
                messageId: `family-${type}-success-message`,
                to: `/family/${data.friendlyUrl}/${sector}/${culture}`,
              }),
            );
          },
        )
        .catch(() => {
          dispatch(
            addToast({
              type: 'error',
              title: getTranslatedMessage(`family-${type}-failure-message`),
              message: '',
            }),
          );
        })
        .finally(() => dispatch(hideLoading()));

      return;
    }

    setStep(step + 1);
  };

  const saveDisabled = () => {
    if (step === 0) {
      return !formValue.name.trim() || !formValue.friendlyUrl.trim();
    }

    if (step === 1) {
      return formValuesValid(formValue.translations, fieldKeys);
    }

    return false;
  };

  return (
    <div>
      <Header
        titleId={
          familyId
            ? 'header-menu-manage-products-edit-family'
            : 'header-menu-manage-products-add-family'
        }
        breadcrumbLinks={breadcrumbLinks}
      />
      <GenericManageSkeleton
        step={step}
        stepperItems={stepperItems}
        saveBtnDisabled={saveDisabled()}
        showUnsavedChangesModal={!_.isEqual(initialState, formValue)}
        onBackBtnClick={() => setStep(step - 1)}
        onSaveBtnClick={() => handleSaveBtnClick()}
      >
        {stepperItems[step].componentToRender}
      </GenericManageSkeleton>
    </div>
  );
};

export default withAdmin(ManageFamily);
