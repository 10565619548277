import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FilterOption } from '../models/filter-option';
import { cloneDeep, includes, some } from 'lodash';
import {
  OemApprovalTypeItem,
  SectorItem,
  SubSectorItem,
} from '../models/lookups';
import { SearchOemResponse, OemResponse } from '../models/oem';

interface OemFilters {
  oemName: string | null;
  oemApprovalTypes: FilterOption[];
}

export interface OemFiltersOptions {
  oemApprovalTypes: FilterOption[];
}

export interface OemFilterSliceState {
  oems: OemResponse[];
  filteredOems: OemResponse[];
  filters: OemFiltersOptions;
  selectedFilters: OemFilters;
}

/**
 * filter oems
 * @param ports ports
 * @param selectedFilters filters
 */
function filterOEMs(
  oems: OemResponse[],
  selectedFilters: OemFilters,
): OemResponse[] {
  const filteredOems = oems.filter((oem) => {
    if (selectedFilters.oemApprovalTypes.length > 0) {
      const approvalTypeFilterIds = selectedFilters.oemApprovalTypes.map(
        (item) => parseInt(item.value),
      );
      const oemApprovalTypeIds = oem.productApprovals.map(
        (item) => item.oemApprovalTypeId,
      );
      return some(approvalTypeFilterIds, (typeId) =>
        includes(oemApprovalTypeIds, typeId),
      );
    } else {
      return true;
    }
  });

  return filteredOems;
}

/**
 * toggle selected filters
 * @param key filters key
 * @param filter filter option to toggle
 * @param selectedFilters selected filters
 */
function toggleSelectedFilter(
  key: keyof OemFilters,
  filter: string | FilterOption | FilterOption[],
  selectedFilters: OemFilters,
) {
  const selectedFiltersByType = selectedFilters[key];
  let filtered: string | FilterOption | FilterOption[] = [];

  if (key === 'oemApprovalTypes') {
    filtered = (selectedFiltersByType as FilterOption[]).filter(
      (item) => item.value !== (filter as FilterOption).value,
    );
  } else {
    filtered = filter;
  }

  if (Array.isArray(filtered)) {
    // add
    if (filtered.length === (selectedFiltersByType as FilterOption[]).length) {
      return {
        ...selectedFilters,
        [key]: [...(selectedFiltersByType as FilterOption[]), filter],
      };
    } else {
      return {
        ...selectedFilters,
        [key]: filtered,
      };
    }
  } else {
    // remove
    return {
      ...selectedFilters,
      [key]: filtered,
    };
  }
}

/**
 * get filtered search result
 * @param searchResult search result
 * @param filters filters
 */
function getFilteredSearchResult(
  searchResult: OemResponse[],
  filters?: OemFilters,
): OemResponse[] {
  let clone = cloneDeep(searchResult);

  if (filters) {
    clone = filterOEMs(clone, filters);
  }

  return clone;
}

/**
 * manage port filter state
 */
export const oemFilterSlice = createSlice({
  name: 'oem-filter',
  initialState: {
    oems: [],
    filteredOems: [],
    filters: {
      oemApprovalTypes: [],
    },
    selectedFilters: {
      oemName: null,
      oemApprovalTypes: [],
    },
  } as OemFilterSliceState,
  reducers: {
    // set search result and create filters
    setFilterableOemSearchResult: (
      state,
      action: PayloadAction<{
        oemResponse: SearchOemResponse;
        sectors: SectorItem[];
        subSectors: SubSectorItem[];
        oemApprovalTypes: OemApprovalTypeItem[];
      }>,
    ) => {
      const oemApprovalTypesOptions = action.payload.oemApprovalTypes.map(
        (i) => ({ name: i.name, value: String(i.id) }),
      );

      const oems = action.payload.oemResponse.oems;
      oems.forEach((item) => {
        if (
          item.sectorIds &&
          item.sectorIds.length > 0 &&
          action.payload.sectors &&
          action.payload.sectors.length > 0 &&
          action.payload.subSectors &&
          action.payload.subSectors.length > 0
        ) {
          const sectors = action.payload.sectors
            .filter((i) => includes(item.sectorIds, i.id))
            .map((i) => i.name);
          const subSectors = action.payload.subSectors
            .filter((i) => includes(item.sectorIds, i.id))
            .map((i) => i.name);
          const allSectors = sectors.concat(subSectors);
          item.sectors = allSectors.join(', ');
        }
      });

      return {
        ...state,
        oems: oems,
        filteredOems: getFilteredSearchResult(oems),
        filters: {
          oemApprovalTypes: oemApprovalTypesOptions,
        },
      };
    },
    // toggle filter
    toggleFilter: (
      state,
      action: PayloadAction<{
        key: keyof OemFilters;
        filter: string | FilterOption | FilterOption[];
      }>,
    ) => {
      const { key, filter } = action.payload;
      const selectedFilters = toggleSelectedFilter(
        key,
        filter,
        state.selectedFilters,
      );

      return {
        ...state,
        selectedFilters,
        filteredOems: getFilteredSearchResult(state.oems, selectedFilters),
      };
    },
    // clear filter for type
    clearFilter: (state, action: PayloadAction<{ key: keyof OemFilters }>) => {
      const { key } = action.payload;
      let defaultValue: FilterOption | FilterOption[] | string | null = [];

      switch (key) {
        case 'oemApprovalTypes': {
          defaultValue = [];
          break;
        }
        case 'oemName': {
          defaultValue = null;
          break;
        }
      }

      const selectedFilters = {
        ...state.selectedFilters,
        [key]: defaultValue,
      };

      return {
        ...state,
        selectedFilters,
        filteredOems: getFilteredSearchResult(state.oems, selectedFilters),
      };
    },
    // clear all filters
    clearAllFilters: (state) => {
      const selectedFilters = {
        oemName: null,
        oemApprovalTypes: [],
      };

      return {
        ...state,
        selectedFilters,
        filteredOems: getFilteredSearchResult(state.oems, selectedFilters),
      };
    },
  },
});

export const {
  setFilterableOemSearchResult,
  toggleFilter,
  clearFilter,
  clearAllFilters,
} = oemFilterSlice.actions;

export default oemFilterSlice.reducer;
