import {
  getHomeLinks,
  getMostSearchedProducts,
  getPromotions,
} from '../../services/HomeService';
import { useEffect, useState } from 'react';
import styles from './HomePage.module.scss';
import Banner from '../../components/HomePage/Banner/Banner';
import { HomePromotion } from '../../models/home-promotion';
import { MostSearchedProduct } from '../../models/most-searched-product';
import { HomeLink } from '../../models/home-link';
import LinksContainer from '../../components/HomePage/LinksContainer/LinksContainer';
import CardLink from '../../components/common/CardLink/CardLink';
import { useIntl } from 'react-intl';
import SearchModal from '../../components/HomePage/SearchModal/SearchModal';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../store';
import { useParams } from 'react-router';
import { setCulture, setSector } from '../../features/welcomeInfoSlice';
import withSectorCountry from '../../hoc/withSectorCountry';
import { getAnnouncements } from '../../services/AnnouncementService';
import { Announcement } from '../../models/announcement';

/**
 * home page component
 */
function HomePage() {
  // init hooks
  const dispatch = useDispatch();
  const params = useParams();
  const intl = useIntl();

  // load data from store.
  const { culture, sector, hasWelcomeInfo, welcomeInfo } = useSelector(
    (state: RootStore) => state.welcomeInfo,
  );
  const { countries } = useSelector((state: RootStore) => state.lookups);

  // init local variables
  const hasParams = params.culture && params.sector;
  const hasDifferentParams =
    params.culture !== culture || params.sector !== sector;

  if (hasParams && hasDifferentParams && !hasWelcomeInfo) {
    dispatch(setCulture(params.culture));
    dispatch(setSector(params.sector));
  }

  // init states.
  const [previousSector, setPreviousSector] = useState(
    welcomeInfo.industrySector?.id,
  );
  const [previousCountry, setPreviousCountry] = useState(
    welcomeInfo.country?.id,
  );
  const [previousLanguage, setPreviousLanguage] = useState(
    welcomeInfo.language?.id,
  );
  const [promotions, setPromotions] = useState<HomePromotion[]>([]);
  const [mostSearchedProducts, setMostSearchedProducts] = useState<
    MostSearchedProduct[]
  >([]);
  const [homeLinks, setHomeLinks] = useState<HomeLink[]>([]);
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);
  const [searchOpened, setSearchOpened] = useState(false);
  const [promotionLoading, setPromotionLoading] = useState(false);
  const [mostSearchedLoading, setMostSearchedLoading] = useState(false);
  const [linkLoading, setLinksLoading] = useState(false);
  const [announcementLoading, setAnnouncementLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);

  const sectorChanged = previousSector !== welcomeInfo.industrySector?.id;
  const countryChanged = previousCountry !== welcomeInfo.country?.id;
  const languageChanged = previousLanguage !== welcomeInfo.language?.id;

  useEffect(() => {
    if (sectorChanged || countryChanged || languageChanged) {
      setPreviousSector(welcomeInfo.industrySector?.id);
      setPreviousCountry(welcomeInfo.country?.id);
      setPreviousLanguage(welcomeInfo.language?.id);
    }
  }, [
    previousSector,
    welcomeInfo,
    previousCountry,
    previousLanguage,
    languageChanged,
    sectorChanged,
    countryChanged,
  ]);

  /**
   * load data
   */
  useEffect(() => {
    if (culture && sector && countries?.length > 0) {
      const promises: Promise<any>[] = [];

      if (languageChanged || sectorChanged || !initialized) {
        // load all
        promises.push(
          getPromotions(),
          getMostSearchedProducts(),
          getHomeLinks(),
          getAnnouncements(),
        );

        Promise.all<any>(promises)
          .then((res) => {
            setPromotions(res[0]);
            setMostSearchedProducts(res[1]);
            setHomeLinks(res[2]);
            const announcementTmp = res[3].filter(
              (item: any) =>
                item.announcementTypeId === 1 || item.announcementTypeId === 2,
            );
            setAnnouncements(announcementTmp);
          })
          .finally(() => {
            setPromotionLoading(false);
            setMostSearchedLoading(false);
            setLinksLoading(false);
            setAnnouncementLoading(false);
          });

        setPromotionLoading(true);
        setMostSearchedLoading(true);
        setLinksLoading(true);
        setAnnouncementLoading(true);
        setInitialized(true);
      } else if (countryChanged) {
        // load only most searched
        promises.push(getMostSearchedProducts());

        setMostSearchedLoading(true);

        Promise.all<any>(promises)
          .then((res) => {
            setMostSearchedProducts(res[0]);
          })
          .finally(() => {
            setMostSearchedLoading(false);
          });
      }
    }
  }, [
    culture,
    sector,
    sectorChanged,
    countryChanged,
    languageChanged,
    countries,
  ]);

  /**
   * close search modal
   */
  const closeSearchModal = () => {
    setSearchOpened(false);
  };

  /**
   * open search modal
   */
  const openSearchModal = () => {
    setSearchOpened(true);
  };

  return (
    <div>
      <Banner
        loading={promotionLoading || announcementLoading}
        onSearchClick={() => openSearchModal()}
        promotions={promotions}
        announcements={announcements}
      />

      <div className={styles.bottomContainer}>
        <div className={styles.bottomInnerContainer}>
          {mostSearchedProducts && mostSearchedProducts.length > 0 && (
            <LinksContainer
              loading={mostSearchedLoading}
              className={styles.linksContainer}
              title={intl.formatMessage({ id: 'most-searched-products-label' })}
              moreLink={'/search-result?from=most-searched-result&tab=product'}
              moreText={intl.formatMessage({ id: 'view-more-label' })}
            >
              {mostSearchedProducts.map((product, index) => {
                return (
                  <CardLink
                    key={index}
                    title={product.name}
                    subTitle={product.tagLine}
                    description={product.description}
                    trimDescription
                    to={`/product/${product.friendlyUrl}`}
                  />
                );
              })}
            </LinksContainer>
          )}

          <LinksContainer
            loading={linkLoading}
            className={styles.linksContainer}
            title={intl.formatMessage({ id: 'links-label' })}
          >
            {homeLinks.map((homeLink, index) => {
              return (
                <CardLink
                  key={index}
                  title={homeLink.name}
                  description={homeLink.description}
                  to={homeLink.targetUrl}
                  useHref={true}
                />
              );
            })}
          </LinksContainer>
        </div>
      </div>

      {searchOpened && <SearchModal onClose={() => closeSearchModal()} />}
    </div>
  );
}

export default withSectorCountry(HomePage);
