import React, { useEffect, useState } from 'react';
import { getBrowseCaseStudy } from '../../services/ProductService';
import { useSearchParams } from 'react-router-dom';
import Tabs from '../../components/common/Tabs/Tabs';
import styles from './ProductPage.module.scss';
import ProductCaseStudies from '../../components/ProductPage/ProductCaseStudies/ProductCaseStudies';
import { useSelector } from 'react-redux';
import { RootStore } from '../../store';
import NavigationHeader from '../../components/common/NavigationHeader/NavigationHeader';
import { PreviousLink } from '../../models/previous-link';
import ContainerForDrawer from '../../components/common/ContainerForDrawer/ContainerForDrawer';
import LeftDrawer from '../../components/common/LeftDrawer/LeftDrawer';
import RightContainer from '../../components/common/RightContainer/RightContainer';
import IconField from '../../components/common/IconField/IconField';
import { ReactComponent as DocumentGreen } from '../../icons/document-green.svg';
const CaseStudies = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    subSectorId: '',
    applicationId: '',
  });
  const [tab, setTab] = useState('CASE STUDIES');
  const [caseStudies, setcaseStudies] = useState([]);
  const { hasLogin, isAdmin, user } = useSelector(
    (state: RootStore) => state.login,
  );
  const breadcrumbLinks: PreviousLink[] = [
    {
      name: 'Home',
      to: '/home',
    },
    {
      name: 'CASE STUDIES',
    },
  ];
  const [tabs, setTabs] = useState([
    {
      name: 'CASE STUDIES',
      key: 'CASE STUDIES',
    },
  ]);
  useEffect(() => {
    getBrowseCaseStudy({
      subSectorId: Number(searchParams.get('subSectorId')),
      applicationId: Number(searchParams.get('applicationId')),
    }).then((res: any) => {
      setcaseStudies(res);
      console.log(res);
    });
  }, []);
  return (
    <div>
      <NavigationHeader
        // onInputFocus={() => setSearchOpened(true)}
        breadcrumbLinks={breadcrumbLinks}
      />
      <div className={styles.csdivider} />
      <ContainerForDrawer>
        <LeftDrawer className={styles.leftDrawer}>
          <IconField
            className={styles.item}
            icon={<DocumentGreen />}
            label={'Case studies'}
            value={caseStudies.length.toString()}
          />
        </LeftDrawer>

        <RightContainer>
          <div className={styles.container}>
            <Tabs
              className={styles.tabContainer}
              tabContentClassName={styles.tabContent}
              tabItemClassName={styles.tabItemClassName}
              tabItemSelectedClassName={styles.tabItemSelectedClassName}
              selected={tab}
              tabs={tabs}
              onChange={(item) => {
                setTab(item.key);
              }}
            >
              {tab === 'CASE STUDIES' && (
                <>
                  {caseStudies.length > 0 && (
                    <ProductCaseStudies
                      caseStudies={caseStudies}
                      isAdmin={isAdmin}
                      showProduct={true}
                      userRole={user?.role}
                    />
                  )}
                </>
              )}
            </Tabs>
          </div>
        </RightContainer>
      </ContainerForDrawer>
    </div>
  );
};
export default CaseStudies;
