import styles from './ReportsMenuPage.module.scss';
import ContainerForDrawer from '../../../../components/common/ContainerForDrawer/ContainerForDrawer';
import NavigationHeader from '../../../../components/common/NavigationHeader/NavigationHeader';
import { PreviousLink } from '../../../../models/previous-link';
import { FormattedMessage, useIntl } from 'react-intl';
import { ReportNavigationType } from '../../../../models/report-nav-types';
import MenuCard from '../components/MenuCard/MenuCard';
import { useNavigate } from 'react-router-dom';
import {
  getMarineData,
  getRequestReport,
} from '../../../../services/StatsService';
import { useState } from 'react';
import LoadingSpinner from '../../../../components/common/LoadingSpinner/LoadingSpinner';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../../store';

/**
 * Reports Menu page component
 */
function ReportsMenuPage() {
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });
  const navigate = useNavigate();
  const { user } = useSelector((state: RootStore) => state.login);
  const breadcrumbLinks: PreviousLink[] = [
    {
      name: 'Home',
      to: '/home',
    },
    {
      name: 'Reports and Quick Stats',
    },
  ];

  const menuItems: ReportNavigationType[] = [
    {
      title: getTranslatedMessage('reports-page-quick-stats'),
      description: getTranslatedMessage('reports-page-quick-stats-description'),
      to: '/admin/reports/reports-and-quick-stats',
      role: ['Super Admin', 'DMS Admin'],
    },
    {
      title: getTranslatedMessage('user-activity-page-title'),
      description: getTranslatedMessage('user-activity-page-description'),
      to: '/admin/reports/user-activity',
      role: ['Super Admin'],
    },
    {
      title: getTranslatedMessage('product-report-page-title'),
      description: getTranslatedMessage('product-report-page-menu-description'),
      to: '/admin/reports/product-report',
      role: ['Super Admin', 'DMS Admin'],
    },
    {
      title: getTranslatedMessage('reports-oems-report'),
      description: getTranslatedMessage('reports-oems-report-description'),
      to: '/admin/reports/oem-activity',
      role: ['Super Admin'],
    },
    {
      title: getTranslatedMessage('reports-contact-requests-report'),
      description: getTranslatedMessage(
        'reports-contact-requests-report-description',
      ),
      to: undefined,
      download: 'requestReport',
      role: ['Super Admin', 'DMS Admin'],
    },
    {
      title: getTranslatedMessage('reports-port-page-report'),
      description: getTranslatedMessage('reports-port-page-report-description'),
      to: '/admin/reports/port-page-report',
      role: ['Super Admin', 'DMS Admin'],
    },
    {
      title: getTranslatedMessage('header-menu-port-product-report'),
      description: getTranslatedMessage('header-menu-port-product-description'),
      to: '/admin/reports/port-product-report',
      role: ['Super Admin', 'DMS Admin'],
    },
    {
      title: getTranslatedMessage('reports-dms-data-report'),
      description: getTranslatedMessage('reports-dms-data-report-description'),
      to: undefined,
      download: 'marineData',
      role: ['Super Admin', 'DMS Admin'],
    },
    {
      title: getTranslatedMessage('header-menu-filter-by-country'),
      description: getTranslatedMessage(
        'header-menu-filter-by-country-description',
      ),
      to: '/admin/reports/port-product-by-country',
      role: ['Super Admin', 'DMS Admin'],
    },
  ];

  return (
    <>
      <div className={classNames(loading ? styles.loadingOverlayDim : null)}>
        <NavigationHeader breadcrumbLinks={breadcrumbLinks} />
        <ContainerForDrawer>
          <div className={classNames(styles.container)}>
            <div className={styles.title}>
              <FormattedMessage id={'reports-page-title'} />
            </div>
            <div className={styles.contentContainer}>
              {menuItems.map((menuItem, index) => {
                let isAllowed = true;

                if (
                  menuItem.role &&
                  user &&
                  !menuItem.role?.includes(user.role)
                ) {
                  isAllowed = false;
                }

                return (
                  isAllowed && (
                    <div key={index}>
                      <MenuCard
                        title={menuItem.title}
                        description={menuItem.description}
                        onClick={() => {
                          /* istanbul ignore else */
                          if (menuItem.to) {
                            navigate(menuItem.to);
                          } else {
                            if (menuItem.download === 'marineData') {
                              setLoading(true);
                              getMarineData().finally(() => setLoading(false));
                            } else if (menuItem.download === 'requestReport') {
                              setLoading(true);
                              getRequestReport().finally(() =>
                                setLoading(false),
                              );
                            }
                          }
                        }}
                      />
                    </div>
                  )
                );
              })}
            </div>
          </div>
        </ContainerForDrawer>
      </div>
      {loading && (
        <div className={styles.loadingOverlay}>
          <LoadingSpinner />
        </div>
      )}
    </>
  );
}

export default ReportsMenuPage;
