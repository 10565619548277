import { useIntl } from 'react-intl';
import { ValidationError } from '../../../models/sheet-validation';
import styles from './ValidationDataCellError.module.scss';

export interface ValidationDataCellErrorProps {
  error: ValidationError;
}

function ValidationDataCellError({ error }: ValidationDataCellErrorProps) {
  const intl = useIntl();
  return (
    <div className={styles.errorText}>
      {
        {
          Required: intl.formatMessage({ id: 'cant-empty-label' }),
          PositiveIntegerArray: intl.formatMessage({
            id: 'value-is-invalid-label',
          }),
        }[error?.errorName || '']
      }
    </div>
  );
}

export default ValidationDataCellError;
