/**
 * return true when string value is valid
 * if value is not string, just check existence
 * @param value string value to check
 */
export function isValidString(value: any): boolean {
  if (typeof value === 'string') {
    return !!(value && value.trim());
  } else {
    return !!value;
  }
}

/**
 * return true when value is float format
 * @param value value
 */
export function isFloat(value: string): boolean {
  const floatReg = /^(([+-])?(0|([1-9][0-9]*))(\.[0-9]+)?)$/gm;

  return !!floatReg.exec(value);
}

/**
 * return true when value is integer format
 * @param value value
 */
export function isInteger(value: string | number): boolean {
  const integerReg = /^[0-9]+$/gm;

  return !!integerReg.exec(
    typeof value === 'string' ? value : value.toString(),
  );
}

/**
 * return true when value is date format
 * @param value value
 */
export function isDate(value: string): boolean {
  const dateReg =
    /(\d{1,2})\s(\w{3}),\s(\d{4})\s(\d{1,2}):(\d{1,2})\s(AM|PM)/gm;
  const dateReg1 = /(\d{2})\/(\d{2})\/(\d{4})/g;
  const dateReg2 = /(\d{4})-(\d{2})-(\d{2})/g;
  const dateReg3 =
    /(\d{1,2})\s(\w{3})\s(\d{4}),\s(\d{1,2}):(\d{1,2})\s(AM|PM)/gm;
  const dateReg4 = /(\d{1,2})\s(\w{3})\s(\d{4})/gm;

  return (
    !!dateReg.exec(value) ||
    !!dateReg1.exec(value) ||
    !!dateReg2.exec(value) ||
    !!dateReg3.exec(value) ||
    !!dateReg4.exec(value)
    // !isNaN(new Date(value).getTime())
  );
}

/**
 * return true when value is not undefined and not null
 * @param value value
 */
export function isDefined(value: any): boolean {
  return value !== undefined && value !== null;
}

/**
 * return true when base string contains search string (case-insensitive)
 * @param base base
 * @param search search
 */
export function containsString(base: string, search: string): boolean {
  return base.toLowerCase().indexOf(search.toLowerCase()) !== -1;
}

/**
 * Validate if a string is empty or a comma seperated items string
 * @param text the text to test
 * @param itemValidator item validator to validate each splitted item
 * @param splitter chars to split the text
 * @returns true if it is valid
 */
export function isCommaSeperatedItems(
  text: string,
  itemValidator: (item: string) => boolean,
  splitter = ',',
): boolean {
  return (
    !text.trim() ||
    !text
      .trim()
      .split(splitter)
      .find((item) => !itemValidator(item.trim()))
  );
}

/**
 * Validate if a string isa positive integer
 * @param text the text to test
 * @returns true if the text is positive integer
 */
export function isPositiveInteger(text: string): boolean {
  const itemInt = Number(text);
  return isInteger(itemInt) && itemInt > 0;
}
