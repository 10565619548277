import { PropsWithoutRef } from 'react';
import styles from './NearbyPortModal.module.scss';
import { PortDetails } from '../../../models/port-details';
import PortTable from '../../common/PortTable/PortTable';
import Modal from '../../common/Modal/Modal';

interface NearbyPortModalProps {
  ports: PortDetails[];
  onClose: () => void;
}

/**
 * nearby port modal
 * @param onClose close handler
 * @ports ports
 */
function NearbyPortModal({
  ports,
  onClose,
}: PropsWithoutRef<NearbyPortModalProps>) {
  return (
    <Modal onClose={() => onClose()}>
      <div className={styles.container}>
        {ports && ports.length > 0 && <PortTable ports={ports} />}
      </div>
    </Modal>
  );
}

export default NearbyPortModal;
