import Modal from '../Modal/Modal';
import { PropsWithoutRef, useLayoutEffect, useRef, useState } from 'react';
import styles from './ComparisonModal.module.scss';
import ComparisonModalHeader from './ComparisonModalHeader/ComparisonModalHeader';
import ComparisonModalTable from './ComparisonModalTable/ComparisonModalTable';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import { ProductComparisonDetail } from '../../../models/product-comparison-detail';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { removeProduct } from '../../../features/productComparisonSlice';
import { SearchResultProduct } from '../../../models/search-result-product';
import { takeScreenShot } from '../../../utils/screenshot.util';

interface ComparisonModalProps {
  onClose: () => void;
}

/**
 * comparison modal
 * @param onClose close handler
 */
function ComparisonModal({ onClose }: PropsWithoutRef<ComparisonModalProps>) {
  // define the ref.
  const tableRef = useRef<null | HTMLDivElement>(null);

  // init hooks.
  const dispatch = useDispatch();

  // get data from store.
  const productComparison = useSelector(
    (state: RootStore) => state.productComparison,
  );

  // get data from store.
  const { hasLogin } = useSelector((state: RootStore) => state.login);

  // define the states.
  const [products, setProducts] = useState<ProductComparisonDetail[]>([]);
  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);

  /**
   * Construct data using lookups.
   */
  const constructData = (
    products: SearchResultProduct[],
    hasLogin: boolean,
  ): ProductComparisonDetail[] => {
    return products.map((p) => ({
      castrolRecommended: p.castrolRecommendedName,
      masterBrandName: p.masterBrandName,
      owner: p.owner,
      application: p.application,
      category: p.category,
      characteristics: p.productCharacteristic,
      productDocuments: p.productDocuments || [],
      id: p.id,
      friendlyUrl: p.friendlyUrl,
      name: p.name,
      sector: p.productSectors.map((x) => x.name).join(', '),
      isLogin: hasLogin || false,
      oemProductApprovalCount: p.oemProductApprovalCount,
    }));
  };

  /**
   * delete product details and product checked list
   * @param product product
   */
  const onProductDelete = (product: ProductComparisonDetail) => {
    const productToRemove = productComparison.products.find(
      (item) => item.id === product.id,
    );

    if (productToRemove) {
      const filtered = products.filter((item) => item.id !== product.id);

      setProducts(filtered);
      dispatch(removeProduct(productToRemove));
    }
  };

  const downloadTableImage = () => {
    takeScreenShot(tableRef, 'image/jpeg', 1.0);
  };

  /**
   * hooks to setup data.
   */
  useLayoutEffect(() => {
    if (!loaded) {
      setProducts(constructData(productComparison.products, hasLogin));

      setLoading(false);
      setLoaded(true);
    } else if (productComparison.products.length === 0) {
      onClose();
    }
  }, [productComparison.products, onClose, loaded, hasLogin]);

  return (
    <Modal onClose={() => onClose()}>
      <div className={styles.container}>
        {loading ? (
          <LoadingSpinner className={styles.loadingContainer} />
        ) : (
          <>
            <ComparisonModalHeader
              products={products}
              downloadTableImage={downloadTableImage}
            />
            <ComparisonModalTable
              className={styles.tableContainer}
              products={products}
              onProductDelete={onProductDelete}
              tableRef={tableRef}
            />
          </>
        )}
      </div>
    </Modal>
  );
}

export default ComparisonModal;
