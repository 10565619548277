/* istanbul ignore file */
import styles from './SearchResultPage.module.scss';
import NavigationHeader from '../../components/common/NavigationHeader/NavigationHeader';
import ContainerForDrawer from '../../components/common/ContainerForDrawer/ContainerForDrawer';
import LeftDrawer from '../../components/common/LeftDrawer/LeftDrawer';
import SearchFilter from '../../components/common/SearchFilter/SearchFilter';
import RightContainer from '../../components/common/RightContainer/RightContainer';
import SearchResultHeader from '../../components/SearchResultPage/SearchResultHeader/SearchResultHeader';
import SearchResultList from '../../components/SearchResultPage/SearchResultList/SearchResultList';
import { useEffect, useMemo, useState } from 'react';
import { getSearchResults } from '../../services/ProductService';
import ComparisonListModal from '../../components/common/ComparisonListModal/ComparisonListModal';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../store';
import ComparisonModal from '../../components/common/ComparisonModal/ComparisonModal';
import { PreviousLink } from '../../models/previous-link';
import { clearProducts } from '../../features/productComparisonSlice';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import LoadingSpinner from '../../components/common/LoadingSpinner/LoadingSpinner';
import SearchModal from '../../components/HomePage/SearchModal/SearchModal';
import {
  changeRangeFilter,
  clearAllFilters,
  clearCombinedMultiSelectFilter,
  clearMultiSelectFilter,
  setFilterableSearchResult,
  toggleCheckboxFilter,
  toggleCombinedMultiSelectFilter,
  toggleMultiSelectFilterById,
} from '../../features/productFilterSlice';
import config from '../../configs/config';
import FilterCheckbox from '../../components/common/FilterCheckbox/FilterCheckbox';
import { FormattedMessage, useIntl } from 'react-intl';
import FilterContainer from '../../components/common/FilterContainer/FilterContainer';
import Slider from '../../components/common/Slider/Slider';
import CombinedMultiSelectFilterGroup from '../../components/common/FilterGroup/CombinedMultiSelectFilterGroup/CombinedMultiSelectFilterGroup';
import MultiSelectFilterGroup from '../../components/common/FilterGroup/MultiSelectFilterGroup/MultiSelectFilterGroup';
import { LookupItemDictionary } from '../../models/lookups';
import {
  SearchResultProduct,
  ProductProductTypes,
  ProductCategory,
} from '../../models/search-result-product';
import _ from 'lodash';

/**
 * search result page
 */
function SearchResultPage() {
  const intl = useIntl();

  // define hooks.
  const dispatch = useDispatch();
  const [query] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  // get data from store.
  const comparison = useSelector((state: RootStore) => state.productComparison);
  const { filters, searchResult, filteredSearchResult } = useSelector(
    (state: RootStore) => state.productFilter,
  );
  const lookups = useSelector((state: RootStore) => state.lookups);
  const { welcomeInfo, hasWelcomeInfo, sector } = useSelector(
    (state: RootStore) => state.welcomeInfo,
  );

  // define the states
  const [comparisonOpened, setComparisonOpened] = useState(false);
  const [loading, setLoading] = useState(false);
  const path = `${location.pathname}?${location.search}`;
  const [previousPath, setPreviousPath] = useState(path);
  const [searchOpened, setSearchOpened] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);

  // initiating local variables.
  const productSelected = comparison.products.length > 0;
  const type = query.get('type') || '';
  const search = query.get('search') || '';
  const category = query.get('category') || '';
  const application = query.get('application') || '';
  const productType = query.get('productType') || '';
  const from = query.get('from') || '';
  const breadcrumbLinks: PreviousLink[] = [
    {
      name: 'Home',
      to: '/home',
    },
    {
      name: 'Search Results',
    },
  ];

  // local functions.

  /**
   * open comparison modal
   */
  const openComparison = () => {
    setComparisonOpened(true);
  };

  /**
   * close comparison modal
   */
  const closeComparison = () => {
    setComparisonOpened(false);
  };

  // get search results
  useEffect(() => {
    // load when welcome info saved.
    if (
      hasWelcomeInfo &&
      welcomeInfo?.industrySector &&
      lookups &&
      lookups.countries.length > 0
    ) {
      // clear products on load
      dispatch(clearProducts());
      dispatch(clearAllFilters());

      setLoading(true);

      getSearchResults({
        search,
        type,
        isMarine:
          welcomeInfo?.industrySector?.friendlyUrl === config.MARINE_SECTOR,
        application,
        category,
        productType,
        fromBrowse: from === 'browse',
      })
        .then((res) => {
          dispatch(setFilterableSearchResult({ res, lookups }));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [dispatch, welcomeInfo.industrySector, hasWelcomeInfo, lookups.countries]);

  // check path changes
  useEffect(() => {
    if (path !== previousPath) {
      setPreviousPath(path);

      dispatch(clearProducts());
      dispatch(clearAllFilters());

      setLoading(true);

      getSearchResults({
        search,
        type,
        isMarine:
          welcomeInfo?.industrySector?.friendlyUrl === config.MARINE_SECTOR,
        application,
        category,
        productType,
        fromBrowse: from === 'browse',
      })
        .then((res) => {
          dispatch(setFilterableSearchResult({ res, lookups }));
        })
        .finally(() => {
          setLoading(false);
          setSearchOpened(false);
        });
    }
  }, [application, category, productType, dispatch, path, previousPath]);

  const {
    categoriesById,
    subCategoriesById,
    applicationsById,
    productTypesById,
    corrosionProtectionsById,
    metalTypesById,
    nlgisById,
    subSectorsById,
    viscosityIndicesById,
    baseOilTypeGenericsById,
    baseOilTypeSpecificsById,
    thickenerGenericsById,
    thickenerSpecificsById,
  } = useSelector((state: RootStore) => state.lookups);

  const familyLookupDict: LookupItemDictionary = useMemo(() => {
    return searchResult.families.reduce<LookupItemDictionary>((idMap, item) => {
      idMap[item.id] = {
        id: item.id,
        name: item.name,
      };
      return idMap;
    }, {});
  }, [searchResult]);

  const rangeLookupDict: LookupItemDictionary = useMemo(() => {
    return searchResult.ranges.reduce<LookupItemDictionary>((idMap, item) => {
      idMap[item.id] = {
        id: item.id,
        name: item.name,
      };
      return idMap;
    }, {});
  }, [searchResult]);

  const oilTypeLookupDicts = useMemo(
    () => [baseOilTypeGenericsById, baseOilTypeSpecificsById],
    [baseOilTypeGenericsById, baseOilTypeSpecificsById],
  );

  const thickenerLookupDicts = useMemo(
    () => [thickenerGenericsById, thickenerSpecificsById],
    [thickenerGenericsById, thickenerSpecificsById],
  );

  const shouldDisplayFamilyFilter = useMemo(
    () => filters.family.options.length > 1 || filters.family.value.length > 0,
    [filters.family.options],
  );

  const shouldDisplayRangeFilter = useMemo(
    () => filters.range.options.length > 1 || filters.range.value.length > 0,
    [filters.range.options],
  );

  const shouldDisplayCategoryFilter = useMemo(
    () =>
      filters.category.options.length > 1 || filters.category.value.length > 0,
    [filters.category.options],
  );

  const shouldDisplayApplicationFilter = useMemo(
    () =>
      filters.application.options.length > 1 ||
      filters.application.value.length > 0,
    [filters.application.options],
  );

  const shouldDisplayProductTypeFilter = useMemo(() => {
    return (
      (filters.productType.options.length > 1 ||
        filters.productType.value.length > 0) &&
      (sector === config.ALL_SECTORS || sector === config.INDUSTRIAL)
    );
  }, [filters.productType.options, sector]);

  const shouldDisplaySubCategoryFilter = useMemo(
    () =>
      filters.subCategory.options.length > 1 ||
      filters.subCategory.value.length > 0,
    [filters.subCategory.options],
  );

  const shouldDisplayMetalTypeFilter = useMemo(
    () =>
      filters.metalType.options.length > 1 ||
      filters.metalType.value.length > 0,
    [filters.metalType.options],
  );
  const shouldDisplayNlgiFilter = useMemo(
    () => filters.nlgi.options.length > 1 || filters.nlgi.value.length > 0,
    [filters.nlgi.options],
  );

  const shouldDisplaySubSectorFilter = useMemo(
    () =>
      filters.subSector.options.length > 1 ||
      filters.subSector.value.length > 0,
    [filters.subSector.options],
  );

  const shouldDisplayViscosityFilter = useMemo(
    () =>
      filters.viscosity.options.length > 1 ||
      filters.viscosity.value.length > 0,
    [filters.viscosity.options],
  );

  const shouldDisplayViscosityIndexFilter = useMemo(
    () =>
      filters.viscosityIndex.options.length > 1 ||
      filters.viscosityIndex.value.length > 0,
    [filters.viscosityIndex.options],
  );

  const shouldDisplayCorrosionProtectionFilter = useMemo(
    () =>
      filters.corrosionProtection.options.length > 1 ||
      filters.corrosionProtection.value.length > 0,
    [filters.corrosionProtection.options],
  );

  const shouldDisplayOilTypeFilter = useMemo(
    () => !!filters.oilType.options.find((opts) => opts.length > 0),
    [filters.oilType.options],
  );

  const shouldDisplayThickenerFilter = useMemo(
    () => !!filters.thickener.options.find((opts) => opts.length > 0),
    [filters.thickener.options],
  );

  return (
    <div>
      <NavigationHeader
        onInputFocus={() => setSearchOpened(true)}
        breadcrumbLinks={breadcrumbLinks}
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        showFilterIcon={true}
      />

      <ContainerForDrawer
        classes={openFilter ? styles.filterOpen : styles.filterClose}
      >
        <LeftDrawer>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <SearchFilter clear={() => dispatch(clearAllFilters())}>
              {shouldDisplayFamilyFilter && (
                <MultiSelectFilterGroup
                  className={styles.filterGroup}
                  title={intl.formatMessage({ id: 'family-label' })}
                  filterOfKey={filters.family}
                  lookupDict={familyLookupDict}
                  onFilterChange={(filter) => {
                    dispatch(
                      toggleMultiSelectFilterById({
                        key: 'family',
                        filter: Number(filter.value),
                      }),
                    );
                  }}
                  onFilterReset={() =>
                    dispatch(
                      clearMultiSelectFilter({
                        key: 'family',
                      }),
                    )
                  }
                />
              )}

              {shouldDisplayRangeFilter && (
                <MultiSelectFilterGroup
                  className={styles.filterGroup}
                  title={intl.formatMessage({ id: 'range-label' })}
                  filterOfKey={filters.range}
                  lookupDict={rangeLookupDict}
                  onFilterChange={(filter) => {
                    dispatch(
                      toggleMultiSelectFilterById({
                        key: 'range',
                        filter: Number(filter.value),
                      }),
                    );
                  }}
                  onFilterReset={() =>
                    dispatch(
                      clearMultiSelectFilter({
                        key: 'range',
                      }),
                    )
                  }
                />
              )}

              {shouldDisplayCategoryFilter && (
                <MultiSelectFilterGroup
                  hideAll={
                    searchResult.products.length === 0 ||
                    _.uniqBy(
                      searchResult.products.reduce(
                        (a: ProductCategory[], b: SearchResultProduct) =>
                          a.concat(b.productCategories),
                        [],
                      ),
                      (x) => x.categoryId,
                    ).length === 1
                  }
                  className={styles.filterGroup}
                  open={!!category}
                  title={intl.formatMessage({ id: 'product-category-label' })}
                  filterOfKey={filters.category}
                  lookupDict={categoriesById}
                  onFilterChange={(filter) => {
                    if (
                      type.length === 0 &&
                      (!!category || !!application || !!productType) &&
                      filters.category?.value?.length === 1 &&
                      filters.category?.value.includes(Number(filter.value))
                    ) {
                      navigate(`/search-result?from=browse`);
                    }
                    dispatch(
                      toggleMultiSelectFilterById({
                        key: 'category',
                        filter: Number(filter.value),
                      }),
                    );
                  }}
                  onFilterReset={() =>
                    dispatch(
                      clearMultiSelectFilter({
                        key: 'category',
                      }),
                    )
                  }
                />
              )}

              {shouldDisplayApplicationFilter && (
                <MultiSelectFilterGroup
                  className={styles.filterGroup}
                  open={!!application}
                  title={intl.formatMessage({ id: 'application-label' })}
                  filterOfKey={filters.application}
                  lookupDict={applicationsById}
                  onFilterChange={(filter) => {
                    if (
                      type.length === 0 &&
                      (!!category || !!application || !!productType) &&
                      filters.application?.value?.length === 1 &&
                      filters.application?.value.includes(Number(filter.value))
                    ) {
                      navigate(
                        `/search-result?from=browse${
                          category ? `&category=${category}` : ''
                        }`,
                      );
                    }
                    dispatch(
                      toggleMultiSelectFilterById({
                        key: 'application',
                        filter: Number(filter.value),
                      }),
                    );
                  }}
                  onFilterReset={() =>
                    dispatch(
                      clearMultiSelectFilter({
                        key: 'application',
                      }),
                    )
                  }
                />
              )}

              {shouldDisplayProductTypeFilter && (
                <MultiSelectFilterGroup
                  hideAll={
                    searchResult.products.length === 0 ||
                    _.uniqBy(
                      searchResult.products.reduce(
                        (a: ProductProductTypes[], b: SearchResultProduct) =>
                          a.concat(b.productProductTypes),
                        [],
                      ),
                      (x) => x.productTypeId,
                    ).length === 1
                  }
                  className={styles.filterGroup}
                  open={!!productType}
                  title={intl.formatMessage({ id: 'product-type-label' })}
                  filterOfKey={filters.productType}
                  lookupDict={productTypesById}
                  onFilterChange={(filter) => {
                    if (
                      type.length === 0 &&
                      (!!category || !!application || !!productType) &&
                      filters.productType?.value?.length === 1 &&
                      filters.productType?.value.includes(Number(filter.value))
                    ) {
                      navigate(`/search-result?from=browse`);
                    }
                    dispatch(
                      toggleMultiSelectFilterById({
                        key: 'productType',
                        filter: Number(filter.value),
                      }),
                    );
                  }}
                  onFilterReset={() =>
                    dispatch(
                      clearMultiSelectFilter({
                        key: 'productType',
                      }),
                    )
                  }
                />
              )}

              {shouldDisplaySubCategoryFilter && (
                <MultiSelectFilterGroup
                  className={styles.filterGroup}
                  title={intl.formatMessage({ id: 'subcategory-label' })}
                  filterOfKey={filters.subCategory}
                  lookupDict={subCategoriesById}
                  onFilterChange={(filter) => {
                    dispatch(
                      toggleMultiSelectFilterById({
                        key: 'subCategory',
                        filter: Number(filter.value),
                      }),
                    );
                  }}
                  onFilterReset={() =>
                    dispatch(
                      clearMultiSelectFilter({
                        key: 'subCategory',
                      }),
                    )
                  }
                />
              )}

              {filters.castrolRecommended.hasDifferentValue && (
                <FilterCheckbox
                  className={styles.filterGroup}
                  title={intl.formatMessage({
                    id: 'castrol-recommended-label',
                  })}
                  checked={filters.castrolRecommended.value}
                  onClick={() => {
                    dispatch(
                      toggleCheckboxFilter({
                        key: 'castrolRecommended',
                      }),
                    );
                  }}
                />
              )}

              {filters.esterEpAdditives.hasDifferentValue && (
                <FilterCheckbox
                  className={styles.filterGroup}
                  title={intl.formatMessage({ id: 'ester-ep-additives-label' })}
                  checked={filters.esterEpAdditives.value}
                  onClick={() => {
                    dispatch(
                      toggleCheckboxFilter({
                        key: 'esterEpAdditives',
                      }),
                    );
                  }}
                />
              )}

              {filters.foodGrade.hasDifferentValue && (
                <FilterCheckbox
                  className={styles.filterGroup}
                  title={intl.formatMessage({ id: 'food-grade-filter-label' })}
                  checked={filters.foodGrade.value}
                  onClick={() => {
                    dispatch(
                      toggleCheckboxFilter({
                        key: 'foodGrade',
                      }),
                    );
                  }}
                />
              )}

              {shouldDisplayMetalTypeFilter && (
                <MultiSelectFilterGroup
                  className={styles.filterGroup}
                  title={intl.formatMessage({ id: 'metal-type-label' })}
                  filterOfKey={filters.metalType}
                  lookupDict={metalTypesById}
                  onFilterChange={(filter) => {
                    dispatch(
                      toggleMultiSelectFilterById({
                        key: 'metalType',
                        filter: Number(filter.value),
                      }),
                    );
                  }}
                  onFilterReset={() =>
                    dispatch(
                      clearMultiSelectFilter({
                        key: 'metalType',
                      }),
                    )
                  }
                />
              )}

              {filters.multiMetalsSuitable.hasDifferentValue && (
                <FilterCheckbox
                  className={styles.filterGroup}
                  title={intl.formatMessage({
                    id: 'multi-metals-suitable-label',
                  })}
                  checked={filters.multiMetalsSuitable.value}
                  onClick={() => {
                    dispatch(
                      toggleCheckboxFilter({
                        key: 'multiMetalsSuitable',
                      }),
                    );
                  }}
                />
              )}

              {shouldDisplayNlgiFilter && (
                <MultiSelectFilterGroup
                  className={styles.filterGroup}
                  title={intl.formatMessage({ id: 'nlgi-label' })}
                  filterOfKey={filters.nlgi}
                  lookupDict={nlgisById}
                  onFilterChange={(filter) => {
                    dispatch(
                      toggleMultiSelectFilterById({
                        key: 'nlgi',
                        filter: Number(filter.value),
                      }),
                    );
                  }}
                  onFilterReset={() =>
                    dispatch(
                      clearMultiSelectFilter({
                        key: 'nlgi',
                      }),
                    )
                  }
                />
              )}

              {shouldDisplayOilTypeFilter && (
                <CombinedMultiSelectFilterGroup
                  className={styles.filterGroup}
                  title={intl.formatMessage({ id: 'oil-type-label' })}
                  filterOfKey={filters.oilType}
                  lookupDicts={oilTypeLookupDicts}
                  onFilterChange={(filter) =>
                    dispatch(
                      toggleCombinedMultiSelectFilter({
                        key: 'oilType',
                        idMap: JSON.parse(filter.value),
                      }),
                    )
                  }
                  onFilterReset={() =>
                    dispatch(
                      clearCombinedMultiSelectFilter({
                        key: 'oilType',
                      }),
                    )
                  }
                />
              )}

              {filters.operatingTemperature.min !== null &&
                filters.operatingTemperature.max !== null &&
                filters.operatingTemperature.value !== null && (
                  <FilterContainer
                    className={styles.filterGroup}
                    title={intl.formatMessage({
                      id: 'operating-temperature-label',
                    })}
                  >
                    <Slider
                      minValue={filters.operatingTemperature.min}
                      maxValue={filters.operatingTemperature.max}
                      renderMinLabel={(value) => (
                        <FormattedMessage
                          id={'min-temperature-label'}
                          values={{ value: value[0] }}
                        />
                      )}
                      renderMaxLabel={(value) => (
                        <FormattedMessage
                          id={'max-temperature-label'}
                          values={{ value: value[1] }}
                        />
                      )}
                      value={filters.operatingTemperature.value}
                      onChange={(newRange) => {
                        dispatch(
                          changeRangeFilter({
                            key: 'operatingTemperature',
                            value: newRange,
                          }),
                        );
                      }}
                    />
                  </FilterContainer>
                )}

              {shouldDisplaySubSectorFilter && (
                <MultiSelectFilterGroup
                  className={styles.filterGroup}
                  title={intl.formatMessage({ id: 'sub-sector-label' })}
                  filterOfKey={filters.subSector}
                  lookupDict={subSectorsById}
                  onFilterChange={(filter) => {
                    dispatch(
                      toggleMultiSelectFilterById({
                        key: 'subSector',
                        filter: Number(filter.value),
                      }),
                    );
                  }}
                  onFilterReset={() =>
                    dispatch(
                      clearMultiSelectFilter({
                        key: 'subSector',
                      }),
                    )
                  }
                />
              )}

              {shouldDisplayThickenerFilter && (
                <CombinedMultiSelectFilterGroup
                  className={styles.filterGroup}
                  title={intl.formatMessage({ id: 'thickener-label' })}
                  filterOfKey={filters.thickener}
                  lookupDicts={thickenerLookupDicts}
                  onFilterChange={(filter) =>
                    dispatch(
                      toggleCombinedMultiSelectFilter({
                        key: 'thickener',
                        idMap: JSON.parse(filter.value),
                      }),
                    )
                  }
                  onFilterReset={() =>
                    dispatch(
                      clearCombinedMultiSelectFilter({
                        key: 'thickener',
                      }),
                    )
                  }
                />
              )}

              {shouldDisplayViscosityFilter && (
                <MultiSelectFilterGroup
                  className={styles.filterGroup}
                  title={intl.formatMessage({ id: 'viscosity-label' })}
                  filterOfKey={filters.viscosity}
                  onFilterChange={(filter) => {
                    dispatch(
                      toggleMultiSelectFilterById({
                        key: 'viscosity',
                        filter: Number(filter.value),
                      }),
                    );
                  }}
                  onFilterReset={() =>
                    dispatch(
                      clearMultiSelectFilter({
                        key: 'viscosity',
                      }),
                    )
                  }
                />
              )}

              {shouldDisplayViscosityIndexFilter && (
                <MultiSelectFilterGroup
                  className={styles.filterGroup}
                  title={intl.formatMessage({ id: 'viscosity-index-label' })}
                  filterOfKey={filters.viscosityIndex}
                  lookupDict={viscosityIndicesById}
                  onFilterChange={(filter) => {
                    dispatch(
                      toggleMultiSelectFilterById({
                        key: 'viscosityIndex',
                        filter: Number(filter.value),
                      }),
                    );
                  }}
                  onFilterReset={() =>
                    dispatch(
                      clearMultiSelectFilter({
                        key: 'viscosityIndex',
                      }),
                    )
                  }
                />
              )}

              {shouldDisplayCorrosionProtectionFilter && (
                <MultiSelectFilterGroup
                  className={styles.filterGroup}
                  title={intl.formatMessage({
                    id: 'corrosion-protection-label',
                  })}
                  filterOfKey={filters.corrosionProtection}
                  lookupDict={corrosionProtectionsById}
                  onFilterChange={(filter) => {
                    dispatch(
                      toggleMultiSelectFilterById({
                        key: 'corrosionProtection',
                        filter: Number(filter.value),
                      }),
                    );
                  }}
                  onFilterReset={() =>
                    dispatch(
                      clearMultiSelectFilter({
                        key: 'corrosionProtection',
                      }),
                    )
                  }
                />
              )}

              {filters.formulatedWithoutSilicate.hasDifferentValue && (
                <FilterCheckbox
                  className={styles.filterGroup}
                  title={intl.formatMessage({
                    id: 'formulated-without-silicate-label',
                  })}
                  checked={filters.formulatedWithoutSilicate.value}
                  onClick={() => {
                    dispatch(
                      toggleCheckboxFilter({
                        key: 'formulatedWithoutSilicate',
                      }),
                    );
                  }}
                />
              )}

              {filters.formulatedWithoutChlorinatedParaffin
                .hasDifferentValue && (
                <FilterCheckbox
                  className={styles.filterGroup}
                  title={intl.formatMessage({
                    id: 'formulated-without-chlorinated-paraffin-label',
                  })}
                  checked={filters.formulatedWithoutChlorinatedParaffin.value}
                  onClick={() => {
                    dispatch(
                      toggleCheckboxFilter({
                        key: 'formulatedWithoutChlorinatedParaffin',
                      }),
                    );
                  }}
                />
              )}

              {filters.formulatedWithoutBoron.hasDifferentValue && (
                <FilterCheckbox
                  className={styles.filterGroup}
                  title={intl.formatMessage({
                    id: 'formulated-without-boron-label',
                  })}
                  checked={filters.formulatedWithoutBoron.value}
                  onClick={() => {
                    dispatch(
                      toggleCheckboxFilter({
                        key: 'formulatedWithoutBoron',
                      }),
                    );
                  }}
                />
              )}

              {filters.formulatedWithoutHeavyMetal.hasDifferentValue && (
                <FilterCheckbox
                  className={styles.filterGroup}
                  title={intl.formatMessage({
                    id: 'formulated-without-heavy-metal-label',
                  })}
                  checked={filters.formulatedWithoutHeavyMetal.value}
                  onClick={() => {
                    dispatch(
                      toggleCheckboxFilter({
                        key: 'formulatedWithoutHeavyMetal',
                      }),
                    );
                  }}
                />
              )}

              {filters.superiorBiodegradation.hasDifferentValue && (
                <FilterCheckbox
                  className={styles.filterGroup}
                  title={intl.formatMessage({
                    id: 'superior-biodegradation-label',
                  })}
                  checked={filters.superiorBiodegradation.value}
                  onClick={() => {
                    dispatch(
                      toggleCheckboxFilter({
                        key: 'superiorBiodegradation',
                      }),
                    );
                  }}
                />
              )}
            </SearchFilter>
          )}
        </LeftDrawer>

        <RightContainer>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <div>
              <SearchResultHeader count={searchResult.products.length} />

              <SearchResultList
                result={filteredSearchResult}
                className={styles.searchResultContainer}
              />
            </div>
          )}
        </RightContainer>
      </ContainerForDrawer>

      {productSelected && !comparisonOpened && !openFilter && (
        <ComparisonListModal
          onOpenClick={() => openComparison()}
          products={comparison.products}
        />
      )}

      {comparisonOpened && (
        <ComparisonModal onClose={() => closeComparison()} />
      )}

      {searchOpened && <SearchModal onClose={() => setSearchOpened(false)} />}
    </div>
  );
}

export default SearchResultPage;
