import styles from './SearchSummaryResult.module.scss';
import { PropsWithoutRef } from 'react';
import { useSelector } from 'react-redux';
import LoadingSpinner from '../../../common/LoadingSpinner/LoadingSpinner';
import SearchSummaryColumn from './SearchSummaryColumn/SearchSummaryColumn';
import config from '../../../../configs/config';
import { SearchResultSummary } from '../../../../models/search-result-summary';
import { RootStore } from '../../../../store';
import classNames from 'classnames';
interface SearchSummaryResultProps {
  result?: SearchResultSummary;
  loading: boolean;
  search: string;
  onClose: () => void;
}

/**
 * Calculate height of the column
 * @param items mapped from the items in the column, true if the item has description text
 * @returns total column height
 */
const calcColumnHeight = (items: boolean[]) => {
  const headerHeight = 43;
  const itemHeightWithDescription = 59;
  const itemHeightWithoutDescription = 42;
  const noItemsContentHeight = 39;
  return items.length > 0
    ? items.reduce(
        (totalHeight, currentHasDescription) =>
          totalHeight +
          (currentHasDescription
            ? itemHeightWithDescription
            : itemHeightWithoutDescription),
        headerHeight,
      )
    : headerHeight + noItemsContentHeight;
};

/**
 * search summary result
 * @param result search result
 * @param loading loading
 * @param search search text
 * @param onClose close the modal
 */
function SearchSummaryResult({
  result,
  loading,
  search,
  onClose,
}: PropsWithoutRef<SearchSummaryResultProps>) {
  const { sectors } = useSelector((rootState: RootStore) => rootState.lookups);
  const { welcomeInfo } = useSelector((state: RootStore) => state.welcomeInfo);
  const hasLogin = useSelector((state: RootStore) => state.login.hasLogin);
  const currentSector = sectors.find(
    (item) => item.id == welcomeInfo?.industrySector?.id,
  );

  if (loading) {
    return <LoadingSpinner className={styles.loading} />;
  } else if (result) {
    const countriesColumn = (
      <SearchSummaryColumn
        className={styles.searchResultContainer}
        key={1}
        showSeeAll={(result?.countries?.length || 0) > 0}
        data={result?.countries || []}
        type={'country'}
        search={search}
        showAllLink={`/port-results?countryName=${search}`}
        onClose={onClose}
      />
    );

    const portsColumn = (
      <SearchSummaryColumn
        className={styles.searchResultContainer}
        key={2}
        showSeeAll={(result?.ports?.length || 0) > 0}
        data={result?.ports || []}
        type={'port'}
        search={search}
        showAllLink={`/port-results?portName=${search}`}
        onClose={onClose}
      />
    );

    const familiesColumn = (
      <SearchSummaryColumn
        className={styles.searchResultContainer}
        key={3}
        showSeeAll={(result?.families?.length || 0) > 0}
        data={result?.families || []}
        type={'family'}
        search={search}
        showAllLink={`/search-result?tab=${config.FAMILY_TYPE}&search=${search}&type=${config.FAMILY_TYPE}`}
        onClose={onClose}
      />
    );

    const rangesColumn = (
      <SearchSummaryColumn
        className={styles.searchResultContainer}
        key={4}
        showSeeAll={(result?.ranges?.length || 0) > 0}
        data={result?.ranges || []}
        type={'range'}
        search={search}
        showAllLink={`/search-result?tab=${config.RANGE_TYPE}&search=${search}&type=${config.RANGE_TYPE}`}
        onClose={onClose}
      />
    );

    const productsColumn = (
      <SearchSummaryColumn
        className={styles.searchResultContainer}
        key={5}
        showSeeAll={(result?.products?.length || 0) > 0}
        data={result?.products || []}
        type={'product'}
        search={search}
        showAllLink={`/search-result?tab=${config.PRODUCT_TYPE}&search=${search}&type=${config.PRODUCT_TYPE}`}
        onClose={onClose}
      />
    );

    const categoriesColumn = (
      <SearchSummaryColumn
        className={styles.searchResultContainer}
        key={6}
        showSeeAll={(result?.categories?.length || 0) > 0}
        data={result?.categories || []}
        type={'category'}
        search={search}
        showAllLink={`/search-result?search=${search}&type=${'category'}`}
        onClose={onClose}
      />
    );

    const applicationsColumn = (
      <SearchSummaryColumn
        className={styles.searchResultContainer}
        key={7}
        showSeeAll={(result?.applications?.length || 0) > 0}
        data={result?.applications || []}
        type={'application'}
        search={search}
        showAllLink={`/search-result?search=${search}&type=${'application'}`}
        onClose={onClose}
      />
    );

    const oemsColumn = hasLogin && (
      <SearchSummaryColumn
        className={styles.searchResultContainer}
        key={8}
        showSeeAll={(result?.oems?.length || 0) > 0}
        data={result?.oems || []}
        type={'oem'}
        search={search}
        showAllLink={`/admin/oem-results?name=${search}`}
        onClose={onClose}
      />
    );

    const distributorsColumn = (
      <SearchSummaryColumn
        className={styles.searchResultContainer}
        key={9}
        showSeeAll={false}
        data={result?.distributors || []}
        type={'distributor'}
        search={search}
        onClose={onClose}
      />
    );

    let leftColumns = [];
    let rightColumns = [];
    const columns =
      currentSector && currentSector.id === 1
        ? [
            productsColumn,
            oemsColumn,
            portsColumn,
            rangesColumn,
            familiesColumn,
            countriesColumn,
            applicationsColumn,
            categoriesColumn,
            distributorsColumn,
          ]
        : [
            productsColumn,
            oemsColumn,
            rangesColumn,
            familiesColumn,
            applicationsColumn,
            categoriesColumn,
            distributorsColumn,
          ];

    const verticalColumnSpacing = 34.5;
    if (currentSector && currentSector.id === 1) {
      leftColumns = [
        productsColumn,
        familiesColumn,
        rangesColumn,
        distributorsColumn,
      ];
      rightColumns = [oemsColumn, portsColumn, countriesColumn];
      // append categoriesColumn and applicationsColumn on either sides to make it look even
      const leftHeight =
        calcColumnHeight(result.products.map(() => true)) +
        verticalColumnSpacing +
        calcColumnHeight(result.families.map(() => true)) +
        verticalColumnSpacing +
        calcColumnHeight(result.ranges.map(() => true));
      const rightHeight =
        calcColumnHeight(result.ports.map(() => true)) +
        verticalColumnSpacing +
        calcColumnHeight(
          result.countries.map((country) => country.portCount > 0),
        );
      const categoriesHeight = calcColumnHeight(
        result.categories.map(() => true),
      );
      const applicationsHeight = calcColumnHeight(
        result.applications.map(() => true),
      );
      // 4 ways to layout the columns
      const newSchemes = [
        {
          leftColumns: [...leftColumns, categoriesColumn],
          rightColumns: [...rightColumns, applicationsColumn],
          diff: Math.abs(
            leftHeight +
              verticalColumnSpacing +
              categoriesHeight -
              rightHeight -
              verticalColumnSpacing -
              applicationsHeight,
          ),
        },
        {
          leftColumns: [...leftColumns, applicationsColumn],
          rightColumns: [...rightColumns, categoriesColumn],
          diff: Math.abs(
            leftHeight +
              verticalColumnSpacing +
              applicationsHeight -
              rightHeight -
              verticalColumnSpacing -
              categoriesHeight,
          ),
        },
        {
          leftColumns: [...leftColumns, categoriesColumn, applicationsColumn],
          rightColumns: [...rightColumns],
          diff: Math.abs(
            leftHeight +
              verticalColumnSpacing * 2 +
              categoriesHeight +
              applicationsHeight -
              rightHeight,
          ),
        },
        {
          leftColumns: [...leftColumns],
          rightColumns: [...rightColumns, categoriesColumn, applicationsColumn],
          diff: Math.abs(
            leftHeight -
              rightHeight -
              verticalColumnSpacing * 2 -
              categoriesHeight -
              applicationsHeight,
          ),
        },
      ];
      const scheme = newSchemes.reduce((finalScheme, currentScheme) => {
        return finalScheme.diff > currentScheme.diff
          ? currentScheme
          : finalScheme;
      }, newSchemes[0]);
      leftColumns = scheme.leftColumns;
      rightColumns = scheme.rightColumns;
    } else {
      leftColumns = [productsColumn, rangesColumn, applicationsColumn];
      rightColumns = [oemsColumn, familiesColumn, categoriesColumn];
    }

    return (
      <>
        <div className={classNames(styles.container, styles.desktop)}>
          <div className={styles.column}>{leftColumns}</div>

          <div className={styles.column}>{rightColumns}</div>
        </div>
        <div className={classNames(styles.container, styles.mobile)}>
          <div className={styles.column}>{columns}</div>
        </div>
      </>
    );
  } else {
    return null;
  }
}

export default SearchSummaryResult;
