import styles from './ProductListTable.module.scss';
import React, {
  Dispatch,
  PropsWithoutRef,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import Checkbox from '../Checkbox/Checkbox';
import { FormattedMessage, useIntl } from 'react-intl';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { ReactComponent as SortArrow } from '../../../icons/sort-arrow.svg';
import { ReactComponent as Caret } from '../../../icons/caret.svg';
import { TableColumn } from '../../../models/table-column';
import _, { debounce } from 'lodash';

interface ProductListTableProps<T> {
  background?: 'dark' | 'light';
  data: T[];
  sortBy: string;
  sortDirection: 'desc' | 'asc';
  columns: TableColumn<T>[];
  onSortChange: (column: TableColumn<T>) => void;
  showCheckbox?: boolean;
  onChecked?: (data: T) => void;
  checkedData?: T[];
  className?: string;
  columnClassName?: string;
  from?: string;
  actionColumn?: TableColumn<T>;
}

/**
 * sort change handler
 * @param sortBy sort column
 * @param setSortBy set sort by state changer
 * @param sortByProperty sort by column property
 * @param setSortByProperty set sort property state changer
 * @param sortDirection sort direction
 * @param setSortDirection set sort direction state changer
 * @param column clicked column
 */
export function sortChangeHandler<T>(
  [sortBy, setSortBy]: [string, Dispatch<SetStateAction<string>>],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [sortByProperty, setSortByProperty]: [
    string,
    Dispatch<SetStateAction<string>>,
  ],
  [sortDirection, setSortDirection]: [
    'asc' | 'desc',
    Dispatch<SetStateAction<'asc' | 'desc'>>,
  ],
  column: TableColumn<T>,
): { sortBy: string; sortByProperty: string; sortDirection: 'asc' | 'desc' } {
  let changedKey = sortBy;
  let changedDirection: 'asc' | 'desc';

  if (sortBy !== column.key) {
    changedKey = column.key;
    changedDirection = 'desc';
  } else {
    if (sortDirection === 'desc') {
      changedDirection = 'asc';
    } else {
      changedDirection = 'desc';
    }
  }

  setSortBy(changedKey);
  setSortByProperty(column.property);
  setSortDirection(changedDirection);

  return {
    sortBy: changedKey,
    sortByProperty: column.property,
    sortDirection: changedDirection,
  };
}

/**
 * product list table
 * @param background background color
 * @param products product list
 * @param sortBy sort field
 * @param sortDirection sort direction
 * @param columns column definitions
 * @param onSortChange sort change handler
 * @param showCheckbox show checkbox
 * @param onChecked checkbox click handler
 * @param checkedData checked data list
 * @param columnClassName className for column
 */
function ProductListTable<T>({
  background = 'light',
  data,
  sortBy,
  sortDirection,
  columns,
  onSortChange,
  showCheckbox,
  onChecked,
  checkedData = [],
  className,
  columnClassName,
  from,
  actionColumn,
}: PropsWithoutRef<ProductListTableProps<T>>) {
  const containerRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();
  const [expanded, setExpanded] = useState<{ id: number; expanded: boolean }[]>(
    [],
  );

  const getPropertyValue = (data: T, property: string) => {
    const keys = property.split('.');
    let index = 0;
    let tempData = data;
    while (index < keys.length) {
      if (typeof _.get(tempData, keys[index]) === 'object') {
        tempData = _.get(tempData, keys[index]);
        index++;
      } else {
        return _.get(tempData, keys[index]);
      }
    }
  };

  const handleCollapseClick = (
    index: number,
    expanded: { id: number; expanded: boolean }[],
  ) => {
    if (expanded && expanded.length > 0) {
      const items = _.cloneDeep(expanded);
      const item = expanded.find((x: any) => x.id === index);
      if (item) {
        const itemInd = expanded.findIndex((x: any) => x.id === index) || 0;
        items[itemInd].expanded = !item.expanded;
        setExpanded(items);
      } else {
        items.push({ id: index, expanded: false });
        setExpanded(items);
      }
    } else {
      setExpanded([{ id: index, expanded: true }]);
    }
  };

  /**
   * Synchronize sort icon position to heading label text right edge
   * (workaround for multiline header label). Possible to do with pure CSS?
   */
  useEffect(() => {
    if (containerRef.current) {
      const observer = new ResizeObserver(
        debounce((changes: ResizeObserverEntry[]) => {
          changes.forEach((change) => {
            const [label, sortIco] = [styles.columnLabel, styles.sortIcon].map(
              (x) => change.target.querySelector('.' + x) as HTMLElement,
            );
            if (sortIco) {
              const [w, wParent] = [label, label.parentElement].map(
                (x) => x?.getBoundingClientRect()?.width,
              );
              sortIco.style.left =
                (w && wParent ? Math.min(w - wParent, 0) : 0) + 'px';
            }
          });
        }, 30),
      );

      containerRef.current
        .querySelectorAll('.' + styles.tableColumn)
        .forEach((el) => {
          observer.observe(el);
        });

      return () => observer.disconnect();
    }
  }, [columns, containerRef.current]);

  return (
    <div
      className={cn(styles.container, styles[background], className)}
      ref={containerRef}
    >
      <div className={styles.tableHeader}>
        <div className={styles.tableRow}>
          {showCheckbox && (
            <div
              className={cn(
                styles.tableColumn,
                styles.tableCheckboxColumn,
                columnClassName,
              )}
            >
              <FormattedMessage id={'compare-label'} />
            </div>
          )}

          {columns.map((column) => {
            const isSorting = sortBy === column.key;
            const isAsc = sortDirection === 'asc';

            return (
              <div
                onClick={() => onSortChange(column)}
                key={column.key}
                style={{ width: column.width, minWidth: column.minWidth }}
                className={cn(
                  styles.tableColumn,
                  column.key && styles.sortableColumn,
                  columnClassName,
                )}
              >
                <div className={styles.columnLabelWrapper}>
                  <span className={styles.columnLabel}>{column.label}</span>
                </div>
                {isSorting && (
                  <SortArrow
                    className={cn(styles.sortIcon, isAsc && styles.asc)}
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>

      <div className={styles.tableBody}>
        {data.map((item, rowIndex) => {
          const selected = checkedData.some(
            (d) => (d as any).id === (item as any).id,
          );

          const itemActive = (item as any).active;
          const fromPortResults = from === 'port-results';

          return (
            <div
              key={rowIndex}
              className={cn(
                styles.tableRow,
                expanded && expanded.find((x) => x.id === rowIndex)?.expanded
                  ? styles.expanded
                  : styles.collapsed,
                fromPortResults && !itemActive ? styles.inActive : '',
              )}
            >
              {showCheckbox && (
                <div
                  className={cn(
                    styles.tableColumn,
                    styles.tableCheckboxColumn,
                    columnClassName,
                  )}
                >
                  <Checkbox
                    onClick={() => onChecked && onChecked(item)}
                    checked={selected}
                  />
                </div>
              )}

              {columns.map((column, index) => {
                const columnContent = column.render ? (
                  <div className={styles.content}>
                    {column.render(item, rowIndex)}
                  </div>
                ) : (
                  <div className={styles.content}>
                    {getPropertyValue(item, column.property)}
                  </div>
                );

                if (column.to) {
                  return (
                    <div
                      key={column.key}
                      style={{ width: column.width, minWidth: column.minWidth }}
                      className={cn(
                        styles.tableColumn,
                        columnClassName,
                        index === 0 ? styles.firstColumn : styles.otherColumns,
                        showCheckbox ? styles.showCheckbox : '',
                      )}
                    >
                      {index > 0 && (
                        <React.Fragment>
                          <span className={styles.label}>{column.label}</span>
                          <Link className={styles.link} to={column.to(item)}>
                            {columnContent}
                          </Link>
                        </React.Fragment>
                      )}
                      {index === 0 && (
                        <React.Fragment>
                          <div className={styles.linkWrapper}>
                            <Link className={styles.link} to={column.to(item)}>
                              {columnContent}
                            </Link>
                            {fromPortResults && !itemActive && (
                              <div className={styles.inActiveLabelWrapper}>
                                {intl.formatMessage({
                                  id: 'inactive-port-label',
                                })}
                              </div>
                            )}
                          </div>
                          <div className={styles.actionsExpandContainer}>
                            {actionColumn &&
                              actionColumn?.render &&
                              actionColumn?.render(item, rowIndex)}
                            <Caret
                              className={styles.dropdownArrow}
                              onClick={() => {
                                handleCollapseClick(
                                  rowIndex,
                                  _.cloneDeep(expanded),
                                );
                              }}
                            />
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                  );
                } else {
                  return (
                    <div
                      key={column.key}
                      style={{ width: column.width, minWidth: column.minWidth }}
                      className={cn(
                        styles.tableColumn,
                        columnClassName,
                        index === 0 ? styles.firstColumn : styles.otherColumns,
                        showCheckbox ? styles.showCheckbox : '',
                        column.className || '',
                      )}
                    >
                      {index > 0 && (
                        <span className={styles.label}>{column.label}</span>
                      )}
                      {columnContent}
                      {index === 0 && (
                        <Caret
                          className={styles.dropdownArrow}
                          onClick={() => {
                            handleCollapseClick(
                              rowIndex,
                              _.cloneDeep(expanded),
                            );
                          }}
                        />
                      )}
                    </div>
                  );
                }
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ProductListTable;
