import { PropsWithoutRef } from 'react';
import { Link } from 'react-router-dom';
import styles from './ProductVariants.module.scss';
import { ReactComponent as Warning } from '../../../icons/warning-green.svg';
import { ProductVariant } from '../../../models/search-result-product';
import { useIntl } from 'react-intl';
import { RootStore } from '../../../store';
import { useSelector } from 'react-redux';

interface ProductVariantsProps {
  productVariants: ProductVariant[];
}

/**
 * port available table
 * @param details details of product
 * @param className classname
 */
function ProductVariants({
  productVariants,
}: PropsWithoutRef<ProductVariantsProps>) {
  const intl = useIntl();
  const { sector, culture } = useSelector(
    (state: RootStore) => state.welcomeInfo,
  );
  const { sectorsById } = useSelector((state: RootStore) => state.lookups);
  return (
    <div className={styles.container}>
      <Warning className={styles.icon} />
      <div className={styles.wrapper}>
        <h3>
          {intl.formatMessage({
            id: 'product-variant-label',
          })}
        </h3>
        <div className={styles.message}>
          {productVariants.map((item) => (
            <div key={item.id}>
              <Link to={`/product/alpha-sp-68/${sector}/${culture}`}>
                {item.name}
              </Link>
              {` [${item.productSectors
                .map((sect) => {
                  return sectorsById[sect.sectorId].name;
                })
                .join(', ')}]`}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ProductVariants;
