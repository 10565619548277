import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WelcomeInfo } from '../models/welcome-info';
import {
  getCultureFromLocalStorage,
  getCultureFromSessionStorage,
  getSectorFromLocalStorage,
  getSectorFromSessionStorage,
  getWelcomeInfoFromSessionStorage,
  getWelcomeInfoFromStorage,
  setCultureToLocalStorage,
  setCultureToSessionStorage,
  setSectorToLocalStorage,
  setSectorToSessionStorage,
  setWelcomeInfoToSessionStorage,
  setWelcomeInfoToStorage,
} from '../services/LocalStorageService';

export interface WelcomeInfoSliceState {
  welcomeInfo: WelcomeInfo;
  sector: string;
  culture: string;
  hasWelcomeInfo: boolean;
}

/**
 * manage welcome info state
 */
export const welcomeInfoSlice = createSlice({
  name: 'welcome',
  initialState: {
    welcomeInfo: getWelcomeInfoFromStorage() ||
      getWelcomeInfoFromSessionStorage() || {
        industrySector: null,
        country: null,
        language: null,
      },
    sector: getSectorFromLocalStorage() || getSectorFromSessionStorage() || '',
    culture:
      getCultureFromLocalStorage() || getCultureFromSessionStorage() || 'en-GB',
    hasWelcomeInfo:
      !!getWelcomeInfoFromStorage() ||
      !!getWelcomeInfoFromSessionStorage() ||
      false,
  } as WelcomeInfoSliceState,
  reducers: {
    // set welcome info
    setWelcomeInfo: (
      state,
      action: PayloadAction<WelcomeInfo>,
    ): WelcomeInfoSliceState => {
      const info = action.payload;
      getWelcomeInfoFromStorage()
        ? setWelcomeInfoToStorage(info)
        : setWelcomeInfoToSessionStorage(info);

      return {
        ...state,
        welcomeInfo: info,
        hasWelcomeInfo:
          !!info.industrySector && !!info.country && !!info.language,
      };
    },

    // set culture
    setCulture: (
      state,
      action: PayloadAction<string | undefined>,
    ): WelcomeInfoSliceState => {
      getWelcomeInfoFromStorage()
        ? setCultureToLocalStorage(action.payload || 'en-GB')
        : setCultureToSessionStorage(action.payload || 'en-GB');
      return {
        ...state,
        culture: action.payload || 'en-GB',
      };
    },

    // set sector
    setSector: (
      state,
      action: PayloadAction<string | undefined>,
    ): WelcomeInfoSliceState => {
      getWelcomeInfoFromStorage()
        ? setSectorToLocalStorage(action.payload || '')
        : setSectorToSessionStorage(action.payload || '');
      return {
        ...state,
        sector: action.payload || '',
      };
    },
  },
});

export const { setWelcomeInfo, setCulture, setSector } =
  welcomeInfoSlice.actions;

export default welcomeInfoSlice.reducer;
