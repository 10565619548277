import { ApprovalDocument } from '../models/approval-document';

/**
 * Show approval document name
 * @param appDoc the approval document
 */
export function showApprovalDocName(
  appDoc: ApprovalDocument | undefined,
): string {
  if (!appDoc) {
    return '';
  }

  if (appDoc.displayDocumentName) {
    const docName = appDoc.document?.name || '';
    const idx = docName.lastIndexOf('.');
    return docName.substring(0, idx);
  }

  return appDoc.linkName;
}
