import { PropsWithoutRef, useState } from 'react';
import { useIntl } from 'react-intl';
import cn from 'classnames';
import { ReactComponent as Barge } from '../../../icons/barge.svg';
import { ReactComponent as Truck } from '../../../icons/truck.svg';
import { ReactComponent as IBC } from '../../../icons/ibc-gray.svg';
import { ReactComponent as Drum } from '../../../icons/drum-gray.svg';
import { ReactComponent as Pail } from '../../../icons/pail.svg';
import { TableColumn } from '../../../models/table-column';
import { sortMethodWithOrderByColumn } from '../../../utils/sort.util';
import ProductListTable, {
  sortChangeHandler,
} from '../../common/ProductListTable/ProductListTable';
import { PortProduct } from '../../../models/port-details';
import styles from './FamiyRangeTable.module.scss';

interface FamiltyRangeTableProps {
  items: PortProduct[];
}

function FamiltyRangeTable({ items }: PropsWithoutRef<FamiltyRangeTableProps>) {
  const intl = useIntl();

  // table sorts
  const [sortBy, setSortBy] = useState('name');
  const [sortByProperty, setSortByProperty] = useState('name');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
  const [data, setData] = useState(() =>
    [...items].sort(sortMethodWithOrderByColumn(sortByProperty, sortDirection)),
  );

  // port children columns
  const columns: TableColumn<PortProduct>[] = [
    {
      key: 'name',
      property: 'product.name',
      label: intl.formatMessage({ id: 'product-label' }),
      width: '46.50856389986825%',
      render: (data) => {
        return (
          <div
            className={cn(data?.portProductNotes && styles.productWithNotes)}
          >
            <a
              href={`/product/${data.product.friendlyUrl}`}
              className={styles.link}
            >
              {data.product.name}
            </a>
            {data?.portProductNotes && (
              <p className={styles.subTitle}>{data?.portProductNotes}</p>
            )}
          </div>
        );
      },
    },
    {
      key: 'barge',
      property: 'offerBulkBadge',
      label: intl.formatMessage({ id: 'barge-label' }),
      width: '10.68090787716956%',
      render: (data) => {
        return data.offerBulkBadge && <Barge />;
      },
    },
    {
      key: 'truck',
      property: 'offerBulkTruck',
      label: intl.formatMessage({ id: 'truck-label' }),
      width: '10.68090787716956%',
      render: (data) => {
        return data.offerBulkTruck && <Truck />;
      },
    },
    {
      key: 'ibc',
      property: 'offerBulkIbc',
      label: intl.formatMessage({ id: 'ibc-label' }),
      width: '10.68090787716956%',
      render: (data) => {
        return data.offerBulkIbc && <IBC />;
      },
    },
    {
      key: 'drum',
      property: 'offerPackDrum',
      label: intl.formatMessage({ id: 'drums-label' }),
      width: '10.68090787716956%',
      render: (data) => {
        return data.offerPackDrum && <Drum />;
      },
    },
    {
      key: 'pail',
      property: 'offerPackPail',
      label: intl.formatMessage({ id: 'pail-label' }),
      width: '10.68090787716956%',
      render: (data) => {
        return data.offerPackPail && <Pail />;
      },
    },
  ];

  /**
   * handle sort change
   * @param column column
   */
  const onSortChange = (column: TableColumn<PortProduct>) => {
    const changes = sortChangeHandler(
      [sortBy, setSortBy],
      [sortByProperty, setSortByProperty],
      [sortDirection, setSortDirection],
      column,
    );

    const sorted = [...data].sort(
      sortMethodWithOrderByColumn(
        changes.sortByProperty,
        changes.sortDirection,
      ),
    );

    setData(sorted);
  };

  return (
    <ProductListTable
      data={data}
      sortBy={sortBy}
      sortDirection={sortDirection}
      background={'dark'}
      columns={columns}
      onSortChange={onSortChange}
      showCheckbox={false}
    />
  );
}

export default FamiltyRangeTable;
