import styles from './AddOemApprovalsTable.module.scss';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { PropsWithoutRef, useState } from 'react';
import { cloneDeep, findIndex, findLastIndex, uniq } from 'lodash';
import ReactTooltip from 'react-tooltip';
import { useNavigate } from 'react-router-dom';

import { RootStore } from '../../../../store';
import { ReactComponent as PinIcon } from '../../../../icons/pin.svg';
import { ReactComponent as CopyIcon } from '../../../../icons/copy.svg';
import { ReactComponent as TrashIcon } from '../../../../icons/trash.svg';
import { ReactComponent as AddIcon } from '../../../../icons/add-green.svg';
import { ReactComponent as GroupTopIcon } from '../../../../icons/group-top.svg';
import { ReactComponent as GroupBodyIcon } from '../../../../icons/group-body.svg';
import { ReactComponent as GroupBottomIcon } from '../../../../icons/group-bottom.svg';
import { ReactComponent as Help } from '../../../../icons/help-green.svg';
import GenericModal from '../../../common/GenericModal/GenericModal';
import { OemOption } from '../../../../models/oem';

import Select from '../../../common/Select/Select';
import Input from '../../../common/Input/Input';
import FileInput from '../../../Admin/FileInput/FileInput';
import Checkbox from '../../../common/Checkbox/Checkbox';
import DatePicker from '../../DatePicker/DatePicker';

interface AddOemApprovalsTableProps {
  oemNames: OemOption[];
  productNames: OemOption[];
  className?: string;
  onChange?: (data: DataItem[]) => void;
}

export interface DataItem {
  oemId: number | null;
  productId: number | null;
  approvalTypeId: number | null;
  active: boolean;
  newDocument: File | null;
  issueDate: Date | null;
  validUntil: Date | null;
  confidential: boolean;
  restrictions: string;
  notes: string;
  group?: number;
}

/**
 * Oem Approval table
 * @param oemApprovals oem approvals
 * @param className classname
 */
function AddOemApprovalsTable({
  oemNames,
  productNames,
  className,
  onChange,
}: PropsWithoutRef<AddOemApprovalsTableProps>) {
  // init hooks
  const intl = useIntl();
  const navigate = useNavigate();
  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });

  const { sectors, oemApprovalTypes } = useSelector(
    (state: RootStore) => state.lookups,
  );

  const tableDataDefault: DataItem[] = [
    {
      oemId: null,
      productId: null,
      approvalTypeId: null,
      active: false,
      newDocument: null,
      issueDate: null,
      validUntil: null,
      confidential: false,
      restrictions: '',
      notes: '',
    },
  ];

  const [tableData, setTableData] = useState(tableDataDefault);
  const [showUnsavedModal, setShowUnsavedModal] = useState(false);

  const checkChanged = (): boolean => {
    const firstData = tableData[0];
    return (
      tableData.length !== 0 &&
      firstData &&
      ((firstData.oemId !== -1 && firstData.oemId !== null) ||
        firstData.productId !== null ||
        firstData.approvalTypeId !== null ||
        firstData.active ||
        firstData.newDocument !== null ||
        firstData.issueDate !== null ||
        firstData.validUntil !== null ||
        firstData.confidential ||
        firstData.restrictions !== '' ||
        firstData.notes !== '')
    );
  };

  const onInputChange = (key: keyof DataItem, index: number, value: any) => {
    const data = cloneDeep(tableData);
    const currentRow = data[index];
    if (key === 'issueDate') {
      const current = addOneDay(value);
      if (
        currentRow['validUntil'] &&
        (current as Date) > currentRow['validUntil']
      ) {
        currentRow['validUntil'] = null;
      }
    }

    if (key === 'oemId' && value === -1) {
      if (!checkChanged()) {
        navigate('/admin/oem/add');
      } else {
        setShowUnsavedModal(true);
        return;
      }
    }

    if (
      currentRow.group &&
      (key === 'newDocument' ||
        key === 'issueDate' ||
        key === 'validUntil' ||
        key === 'confidential')
    ) {
      const group = data.filter((item) => item.group === currentRow.group);
      group.forEach((item: any) => (item[key] = value));
      if (key === 'issueDate' && currentRow['validUntil'] === null) {
        group.forEach((item: any) => (item['validUntil'] = null));
      }
    } else {
      (currentRow as any)[key] = value;
    }
    setTableData(data);
    onChange && onChange(data);
  };

  const findGroupFirstAbove = (index: number): boolean => {
    if (index === tableData.length - 1) {
      return false;
    }
    return !tableData[index].group && !!tableData[index + 1].group;
  };

  const findGroupFirst = (group: number | undefined): number => {
    if (!group && group !== 0) {
      return -1;
    }
    return findIndex(tableData, (item) => item.group === group);
  };

  const findGroupLast = (group: number | undefined): number => {
    if (!group && group !== 0) {
      return -1;
    }
    return findLastIndex(tableData, (item) => item.group === group);
  };

  const copyRow = (index: number) => {
    const data = cloneDeep(tableData);
    const currentRow = data[index];
    if (!currentRow.group) {
      const allGroups = uniq(data.map((item) => item.group || 0));
      currentRow.group = Math.max(...allGroups) + 1;
    }
    const newRow = cloneDeep(currentRow);
    newRow.productId = null;
    data.splice(index + 1, 0, newRow);
    setTableData(data);
    onChange && onChange(data);
  };

  const removeRow = (index: number) => {
    const data = cloneDeep(tableData);
    const currentRow = data[index];
    if (currentRow.group) {
      const group = data.filter((item) => item.group === currentRow.group);
      if (group.length <= 2) {
        group.forEach((item) => delete item.group);
      }
    }
    data.splice(index, 1);
    setTableData(data);
    onChange && onChange(data);
  };

  const addRow = () => {
    const data = cloneDeep(tableData);
    data.push({
      oemId: null,
      productId: null,
      approvalTypeId: null,
      active: false,
      newDocument: null,
      issueDate: null,
      validUntil: null,
      confidential: false,
      restrictions: '',
      notes: '',
    });
    setTableData(data);
    onChange && onChange(data);
  };

  const getOemNameOptions = () => {
    const options = oemNames.map((item) => ({
      name: String(item.name),
      value: item.id,
      inactive: !item.active,
    }));

    return [
      {
        name: getTranslatedMessage('header-menu-add-oem').toUpperCase(),
        value: -1,
      },
      ...options,
    ];
  };

  const getProductNameOptions = () => {
    return productNames.map((item) => {
      let section = undefined;
      if (item.productSectors && item.productSectors.length > 0) {
        const sectorId = item.productSectors[0].sectorId;
        const sectionObj = sectors.find(
          (sectorItem) => sectorItem.id === sectorId,
        );
        section = String(item.name) + ' (' + sectionObj?.name + ')';
      }
      return {
        name: String(item.name),
        value: item.id,
        tooltip: section || undefined,
      };
    });
  };

  const addOneDay = (dateObj: Date | null): Date | null => {
    if (!dateObj) {
      return null;
    }
    const newDate = new Date(dateObj);
    const newDay = newDate.getDate() + 1;
    newDate.setDate(newDay);
    return newDate;
  };

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.tableLegendRow}>
        <div className={styles.titleLabel}>
          <FormattedMessage id={'oem-table-legend-title'} />
        </div>
        <div className={styles.legend}>
          <div className={styles.requiredIndicator}>{'*'}</div>
          <div className={styles.description}>
            <FormattedMessage id={'oem-table-legend-desc-1'} />
          </div>
        </div>
        <div className={styles.legend}>
          <div className={styles.errorLine} />
          <div className={styles.description}>
            <FormattedMessage id={'oem-table-legend-desc-3'} />
          </div>
        </div>
      </div>

      <div style={{ position: 'relative' }}>
        <div className={styles.tableContainer}>
          <div className={styles.tableHeader}>
            <div
              className={cn(
                styles.tableRow,
                tableData.length > 0 &&
                  tableData[0].group &&
                  styles.groupFirstAbove,
              )}
            >
              <div
                style={{ flex: '0 0 250px', width: '250px' }}
                className={cn(styles.tableLabelColumn, styles.tableColumn)}
              >
                <span className={styles.requiredIndicator}>*</span>
                <FormattedMessage id={'oem-page-oem-name'} />
              </div>

              <div
                style={{ flex: '0 0 195px' }}
                className={cn(styles.tableLabelColumn, styles.tableColumn)}
              >
                <span className={styles.requiredIndicator}>*</span>
                <FormattedMessage id={'oem-table-field-product-name'} />
              </div>

              <div
                style={{ flex: '0 0 90px' }}
                className={cn(styles.tableLabelColumn, styles.tableColumn)}
              >
                <FormattedMessage id={'oem-table-field-product-id'} />
              </div>

              <div
                style={{ flex: '0 0 200px' }}
                className={cn(styles.tableLabelColumn, styles.tableColumn)}
              >
                <span className={styles.requiredIndicator}>*</span>
                <FormattedMessage id={'oem-table-field-approval-type'} />
              </div>

              <div
                style={{ flex: '0 0 120px' }}
                className={cn(styles.tableLabelColumn, styles.tableColumn)}
              >
                <FormattedMessage id={'oem-table-field-status'} />
              </div>

              <div
                style={{ flex: '0 0 200px' }}
                className={cn(styles.tableLabelColumn, styles.tableColumn)}
              >
                <div className={styles.newDocumentHeaderContainer}>
                  <div>
                    <span className={styles.requiredIndicator}>*</span>
                    <FormattedMessage id={'oem-table-field-new-document'} />
                  </div>
                  <div>
                    <Help
                      data-tip
                      data-for={'oem-table-field-new-document-tooltip-id'}
                    />
                    <ReactTooltip
                      id={'oem-table-field-new-document-tooltip-id'}
                      place={'top'}
                      effect={'solid'}
                    >
                      <FormattedMessage
                        id={'oem-table-field-new-document-tooltip'}
                      />
                    </ReactTooltip>
                  </div>
                </div>
              </div>

              <div
                style={{ flex: '0 0 130px' }}
                className={cn(styles.tableLabelColumn, styles.tableColumn)}
              >
                <span className={styles.requiredIndicator}>*</span>
                <FormattedMessage id={'oem-table-field-doc-issue-date'} />
              </div>

              <div
                style={{ flex: '0 0 130px' }}
                className={cn(styles.tableLabelColumn, styles.tableColumn)}
              >
                <FormattedMessage id={'oem-table-field-doc-expiry-date'} />
              </div>

              <div
                style={{ flex: '0 0 130px' }}
                className={cn(styles.tableLabelColumn, styles.tableColumn)}
              >
                <FormattedMessage id={'oem-table-field-bp-confidential'} />
              </div>

              <div
                style={{ flex: '0 0 145px' }}
                className={cn(styles.tableLabelColumn, styles.tableColumn)}
              >
                <FormattedMessage id={'oem-table-field-restrictions'} />
              </div>

              <div
                style={{ flex: '0 0 185px' }}
                className={cn(styles.tableLabelColumn, styles.tableColumn)}
              >
                <FormattedMessage id={'oem-table-field-notes'} />
              </div>
            </div>
          </div>

          <div className={cn(styles.tableBody, styles.desktop)}>
            {tableData.map((rowData, index) => (
              <div
                className={cn(
                  styles.tableRow,
                  styles.desktop,
                  rowData.group && styles.group,
                  findGroupFirstAbove(index) && styles.groupFirstAbove,
                  findGroupFirst(rowData.group) === index && styles.groupFirst,
                  findGroupLast(rowData.group) === index && styles.groupLast,
                )}
                key={index}
              >
                <div
                  style={{ flex: '0 0 250px' }}
                  className={cn(
                    styles.tableColumn,
                    styles.oemName,
                    styles.mobile,
                  )}
                >
                  <div className={styles.groupIcon}>
                    <GroupTopIcon className={styles.groupStart} />
                    <GroupBodyIcon className={styles.groupMiddle} />
                    <GroupBottomIcon className={styles.groupEnd} />
                  </div>
                  <Select
                    className={cn(styles.selectControl, styles.nameSelector)}
                    inputClassName={styles.selectInput}
                    optionClassName={styles.selectOption}
                    value={rowData.oemId}
                    placeholder={''}
                    canSearch={true}
                    options={getOemNameOptions()}
                    onChange={(value) => onInputChange('oemId', index, value)}
                    showTooltip={true}
                  />
                </div>
                <div
                  style={{ flex: '0 0 195px' }}
                  className={cn(styles.tableColumn, styles.productName)}
                >
                  <Select
                    className={styles.selectControl}
                    inputClassName={styles.selectInput}
                    optionClassName={styles.selectOption}
                    value={rowData.productId}
                    placeholder={''}
                    canSearch={true}
                    options={getProductNameOptions()}
                    onChange={(value) =>
                      onInputChange('productId', index, value)
                    }
                    showTooltip={true}
                  />
                </div>
                <div
                  style={{ flex: '0 0 90px' }}
                  className={cn(styles.tableColumn, styles.productId)}
                >
                  {rowData.productId}
                </div>
                <div
                  style={{ flex: '0 0 200px' }}
                  className={styles.tableColumn}
                >
                  <Select
                    className={styles.selectControl}
                    inputClassName={styles.selectInput}
                    optionClassName={styles.selectOption}
                    value={rowData.approvalTypeId}
                    placeholder={''}
                    options={oemApprovalTypes.map((item) => ({
                      name: String(item.name),
                      value: item.id,
                    }))}
                    onChange={(value) =>
                      onInputChange('approvalTypeId', index, value)
                    }
                  />
                </div>
                <div
                  style={{ flex: '0 0 120px' }}
                  className={styles.tableColumn}
                >
                  <div className={styles.checkboxWrapper}>
                    <Checkbox
                      checked={rowData.active}
                      onClick={() =>
                        onInputChange('active', index, !rowData.active)
                      }
                    />
                    <div
                      className={styles.checkboxLabel}
                      onClick={() =>
                        onInputChange('active', index, !rowData.active)
                      }
                    >
                      <FormattedMessage id={'active-label'} />
                    </div>
                  </div>
                </div>
                <div
                  style={{ flex: '0 0 200px' }}
                  className={styles.tableColumn}
                >
                  <FileInput
                    accept={'application/pdf'}
                    file={rowData.newDocument}
                    className={styles.fileInput}
                    inputTextClassName={styles.fileInputText}
                    placeholderId={'attach-file-placeholder'}
                    icon={<PinIcon className={styles.pinIcon} />}
                    onChange={(file) =>
                      onInputChange('newDocument', index, file)
                    }
                  />
                </div>
                <div
                  style={{ flex: '0 0 130px' }}
                  className={styles.tableColumn}
                >
                  <DatePicker
                    value={rowData.issueDate}
                    className={styles.datePicker}
                    placeholder={'Select Date'}
                    onChange={(value) =>
                      onInputChange('issueDate', index, value)
                    }
                  />
                </div>
                <div
                  style={{ flex: '0 0 130px' }}
                  className={styles.tableColumn}
                >
                  <DatePicker
                    value={rowData.validUntil}
                    className={styles.datePicker}
                    placeholder={'Select Date'}
                    onChange={(value) =>
                      onInputChange('validUntil', index, value)
                    }
                    minDate={addOneDay(rowData.issueDate)}
                  />
                </div>
                <div
                  style={{ flex: '0 0 130px' }}
                  className={styles.tableColumn}
                >
                  <div className={styles.checkboxWrapper}>
                    <Checkbox
                      checked={rowData.confidential}
                      onClick={() =>
                        onInputChange(
                          'confidential',
                          index,
                          !rowData.confidential,
                        )
                      }
                    />
                    <div
                      className={styles.checkboxLabel}
                      onClick={() =>
                        onInputChange(
                          'confidential',
                          index,
                          !rowData.confidential,
                        )
                      }
                    >
                      <FormattedMessage id={'yes-label'} />
                    </div>
                  </div>
                </div>
                <div
                  style={{ flex: '0 0 145px' }}
                  className={styles.tableColumn}
                >
                  <Input
                    value={rowData.restrictions}
                    className={styles.inputWrapper}
                    onChange={(value) =>
                      onInputChange('restrictions', index, value)
                    }
                    placeholder={getTranslatedMessage('enter-placeholder')}
                  />
                </div>
                <div
                  style={{ flex: '0 0 185px' }}
                  className={cn(styles.tableColumn, styles.lastColumn)}
                >
                  <Input
                    value={rowData.notes}
                    className={styles.inputWrapper}
                    onChange={(value) => onInputChange('notes', index, value)}
                    placeholder={getTranslatedMessage('enter-placeholder')}
                  />
                </div>

                <div
                  style={{ flex: '0 0 250px' }}
                  className={cn(
                    styles.tableColumn,
                    styles.oemName,
                    styles.desktop,
                  )}
                >
                  <div className={styles.groupIcon}>
                    <GroupTopIcon className={styles.groupStart} />
                    <GroupBodyIcon className={styles.groupMiddle} />
                    <GroupBottomIcon className={styles.groupEnd} />
                  </div>
                  <Select
                    className={cn(styles.selectControl, styles.nameSelector)}
                    inputClassName={styles.selectInput}
                    optionClassName={styles.selectOption}
                    value={rowData.oemId}
                    placeholder={''}
                    canSearch={true}
                    options={getOemNameOptions()}
                    onChange={(value) => onInputChange('oemId', index, value)}
                    showTooltip={true}
                  />
                </div>

                <div
                  style={{ flex: '0 0 82px' }}
                  className={cn(styles.tableColumn, styles.actionColumn)}
                >
                  <CopyIcon
                    className={styles.copyIcon}
                    onClick={() => copyRow(index)}
                    data-tip
                    data-for={`copy-icon-${index}`}
                  />
                  <ReactTooltip
                    id={`copy-icon-${index}`}
                    place={'top'}
                    effect={'solid'}
                  >
                    {getTranslatedMessage('duplicate-row-tooltip')}
                  </ReactTooltip>
                  <TrashIcon
                    className={styles.removeIcon}
                    onClick={() => removeRow(index)}
                    data-tip
                    data-for={`trash-icon-${index}`}
                  />
                  <ReactTooltip
                    id={`trash-icon-${index}`}
                    place={'top'}
                    effect={'solid'}
                  >
                    {getTranslatedMessage('delete-row-tooltip')}
                  </ReactTooltip>
                </div>
              </div>
            ))}
          </div>

          <div className={styles.tableBottom}>
            <div className={cn(styles.tableRow, styles.desktop)}>
              <div
                style={{ flex: '0 0 44px' }}
                className={cn(styles.tableColumn, styles.addColumn)}
                onClick={addRow}
              >
                <AddIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
      {showUnsavedModal && (
        <GenericModal
          type={'confirmation'}
          titleId={'oem-model-unsaved-changes'}
          messageId={'oem-model-unsaved-changes-desc'}
          onClose={() => {
            setShowUnsavedModal(false);
          }}
          onYesClick={() => {
            navigate('/admin/oem/add');
          }}
        />
      )}
    </div>
  );
}

export default AddOemApprovalsTable;
