import styles from './PortDetailHeader.module.scss';
import { PropsWithoutRef } from 'react';

interface PortDetailHeaderProps {
  title: string;
  subTitle?: string;
  subTitle2?: string;
  description: string;
}

/**
 * detail page header
 * @param title title
 * @param subTitle sub title
 * @param subTitle2 2nd sub title
 * @param description description
 */
function PortDetailHeader({
  title,
  subTitle,
  subTitle2,
  description,
}: PropsWithoutRef<PortDetailHeaderProps>) {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{title}</h1>

      <div className={styles.subTitleContainer}>
        {subTitle && <h3 className={styles.subTitle}>{subTitle}</h3>}
        {subTitle2 && <h3 className={styles.subTitleDivider}>|</h3>}
        {subTitle2 && <h3 className={styles.subTitle2}>{subTitle2}</h3>}
      </div>

      <div className={styles.description}>{description}</div>
    </div>
  );
}

export default PortDetailHeader;
