import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import Header from '../../../components/Admin/Header/Header';
import SelectACountryOrDistributorForm from '../../../components/Admin/SelectACountryOrDistributorForm/SelectACountryOrDistributorForm';
import DistributorDataForm from '../../../components/Admin/DistributorDataForm/DistributorDataForm';
import GenericManageSkeleton from '../../../components/Admin/GenericManageSkeleton/GenericManageSkeleton';

import { PreviousLink } from '../../../models/previous-link';
import {
  DistributorType,
  StepperItemType,
} from '../../../models/admin-form-types';
import { CountryItem } from '../../../models/lookups';

import { savePortDistributor } from '../../../services/PortDistributorService';

import { RootStore } from '../../../store';

import {
  trimSpacesInObj,
  validateURL,
  generateFriendlyUrlWithCountry,
} from '../../../utils/common.util';
import {
  removeEmptyFieldItem,
  validateData,
} from '../../../utils/order-contact-port-distributor';

import { hideLoading, showLoading } from '../../../features/loadingSlice';
import { addToast } from '../../../features/toastSlice';
import { showGenericModal } from '../../../features/genericModalSlice';
import withAdmin from '../../../hoc/withAdmin';

const initialState = {
  id: -1,
  active: true,
  country: {} as CountryItem,
  distributor: {} as DistributorType,
  name: '',
  friendlyUrl: '',
  address: '',
  portsServed: '',
  url: '',
  contactNames: [],
  emails: [],
  phones: [],
  mobileNumbers: [],
  faxes: [],
  others: [],
};
const AddPortDistributor = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { countries } = useSelector((state: RootStore) => state.lookups);
  const [step, setStep] = useState(0);
  const [initState, setInitState] = useState(initialState);
  const [formValue, setFormValue] = useState(initialState);
  const [isFormValid, setIsFormValid] = useState(true);

  const breadcrumbLinks: PreviousLink[] = [
    {
      name: 'Home',
      to: '/home',
    },
    {
      name: intl.formatMessage({
        id: 'header-menu-manage-ports-create-port-distributor',
      }),
    },
  ];

  const formValueArrayKeys = [
    'contactNames',
    'emails',
    'phones',
    'mobileNumbers',
    'faxes',
    'others',
  ];

  useEffect(() => {
    const isValid = validateData(formValueArrayKeys, formValue, [
      'phones',
      'mobileNumbers',
      'faxes',
      'others',
    ]);
    setIsFormValid(isValid);
  }, [
    formValue.contactNames,
    formValue.emails,
    formValue.phones,
    formValue.mobileNumbers,
    formValue.faxes,
    formValue.others,
  ]);

  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });

  const handleValueChange = (fieldKey: string, value: string | string[]) => {
    const updatedValue = {
      ...formValue,
      [fieldKey]: value,
    };
    if (fieldKey === 'name') {
      updatedValue.friendlyUrl = generateFriendlyUrlWithCountry(
        value as string,
        formValue.country?.name || '',
      );
    }
    setFormValue(updatedValue);
    setInitState(updatedValue);
  };

  const stepperItems: StepperItemType = [
    {
      label: getTranslatedMessage('select-a-country-label'),
      componentToRender: (
        <SelectACountryOrDistributorForm
          titleId="country-details"
          labelId="select-a-country-label"
          selectOptions={countries}
          fieldKey="country"
          formValue={formValue}
          handleValueChange={handleValueChange}
        />
      ),
    },
    {
      label: getTranslatedMessage('distributor-details-label'),
      componentToRender: (
        <DistributorDataForm
          formValue={formValue}
          handleValueChange={handleValueChange}
          labelId="distributor-details-label"
        />
      ),
    },
  ];

  const saveDisabled = () => {
    if (step === 0) {
      return !formValue.country.id;
    }

    if (step === 1) {
      return (
        !formValue.name.trim() ||
        !formValue.address.trim() ||
        (formValue.url?.length && validateURL(formValue.url)) ||
        !isFormValid
      );
    }

    return false;
  };

  const handleSaveBtnClick = () => {
    if (step === 1) {
      let payload: any = _.omit(formValue, [
        'id',
        'country',
        'distributor',
        'combinedName',
      ]);
      payload = removeEmptyFieldItem(formValueArrayKeys, payload);
      payload = trimSpacesInObj(payload);
      payload = {
        ...payload,
        countryId: formValue.country.id,
      };
      dispatch(showLoading());
      savePortDistributor(payload)
        .then(() => {
          dispatch(
            showGenericModal({
              titleId: 'port-distributor-create-success-label',
              messageId: 'port-distributor-create-success',
              to: '/',
            }),
          );
        })
        .catch((message) => {
          dispatch(
            addToast({
              type: 'error',
              title: getTranslatedMessage('port-distributor-create-failure'),
              message: message || '',
            }),
          );
        })
        .finally(() => dispatch(hideLoading()));
      return;
    }

    setStep(step + 1);
  };

  return (
    <div>
      <Header
        titleId="header-menu-manage-ports-create-port-distributor"
        breadcrumbLinks={breadcrumbLinks}
      />
      <GenericManageSkeleton
        step={step}
        stepperItems={stepperItems}
        saveBtnDisabled={saveDisabled()}
        showUnsavedChangesModal={!_.isEqual(initState, formValue)}
        onBackBtnClick={() => setStep(step - 1)}
        onSaveBtnClick={handleSaveBtnClick}
      >
        {stepperItems[step].componentToRender}
      </GenericManageSkeleton>
    </div>
  );
};

export default withAdmin(AddPortDistributor);
