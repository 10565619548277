import styles from './ProductRangePage.module.scss';
import ContainerForDrawer from '../../components/common/ContainerForDrawer/ContainerForDrawer';
import LeftDrawer from '../../components/common/LeftDrawer/LeftDrawer';
import LoadingSpinner from '../../components/common/LoadingSpinner/LoadingSpinner';
import RightContainer from '../../components/common/RightContainer/RightContainer';
import DetailHeader from '../../components/common/DetailHeader/DetailHeader';
import ComparisonListModal from '../../components/common/ComparisonListModal/ComparisonListModal';
import ComparisonModal from '../../components/common/ComparisonModal/ComparisonModal';
import { PreviousLink } from '../../models/previous-link';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../store';
import { SearchResultRange } from '../../models/search-result-range';
import NavigationHeader from '../../components/common/NavigationHeader/NavigationHeader';
import { useIntl } from 'react-intl';
import RangeLeftDetail from '../../components/ProductRangePage/RangeLeftDetail/RangeLeftDetail';
import { getRangeDetail } from '../../services/ProductService';
import RangeDetailProducts from '../../components/ProductRangePage/RangeDetailProducts/RangeDetailProducts';
import SearchModal from '../../components/HomePage/SearchModal/SearchModal';
import { useLocation } from 'react-router-dom';
import { resolveProductLookups } from '../../utils/product.util';
import { Lookups } from '../../models/lookups';
import withSectorCountry from '../../hoc/withSectorCountry';
import { setRangeDetails } from '../../features/rangeDetailsSlice';
import NotFound from '../Admin/NotFound/NotFound';

/**
 * range detail page
 */
function ProductRangePage() {
  // init hooks
  const intl = useIntl();
  const dispatch = useDispatch();
  const location = useLocation();
  const { friendlyName } = useParams();

  // load data from store.
  const { products } = useSelector(
    (state: RootStore) => state.productComparison,
  );
  const lookups = useSelector((state: RootStore) => state.lookups);
  const { hasLogin } = useSelector((state: RootStore) => state.login);
  const welcomeInfoRoot = useSelector((state: RootStore) => state.welcomeInfo);

  // init states.
  const [notFound, setNotFound] = useState(false);
  const [loading, setLoading] = useState(false);
  const [range, setRange] = useState<SearchResultRange>();
  const [comparisonOpened, setComparisonOpened] = useState(false);
  const path = `${location.pathname}?${location.search}`;
  const [previousPath, setPreviousPath] = useState(path);
  const [searchOpened, setSearchOpened] = useState(false);

  // setup local variables.
  const productSelected = products.length > 0;

  // local functions

  /**
   * Updated the product data based on lookup value.
   * @param lookups lookups
   * @param data incoming data
   * @returns modified product range data
   */
  const prepareData = (lookups: Lookups, data: any): SearchResultRange => {
    const products = resolveProductLookups(
      data.products || data.productChildren,
      lookups,
    );
    const applicationMap = new Map();
    const subApplicationMap = new Map();
    const categoryMap = new Map();
    const subCategoryMap = new Map();
    const productTypeMap = new Map();

    products.forEach((product) => {
      product.productApplications.forEach((item) => {
        applicationMap.set(item.name, item.name);
      });
      product.productSubApplications.forEach((item) => {
        subApplicationMap.set(item.name, item.name);
      });
      product.productCategories.forEach((item) => {
        categoryMap.set(item.name, item.name);
      });
      if (product.productSubCategory) {
        subCategoryMap.set(
          product.productSubCategory,
          product.productSubCategory,
        );
      }
      product.productProductTypes.forEach((item) => {
        productTypeMap.set(item.name, item.name);
      });
    });

    const range: SearchResultRange = {
      ...data.range,
      application: [
        ...Array.from(applicationMap.keys()),
        ...Array.from(subApplicationMap.keys()),
      ].join(', '),
      category: [
        ...Array.from(categoryMap.keys()),
        ...Array.from(subCategoryMap.keys()),
      ].join(', '),
      productType: Array.from(productTypeMap.keys()).join(', '),
      productChildren: products,
    };

    return range;
  };

  /**
   * Load data when lookup loads completed and welcome data stored.
   */
  useEffect(() => {
    if (lookups?.countries?.length > 0 && welcomeInfoRoot?.hasWelcomeInfo) {
      setLoading(true);
      setNotFound(false);
      getRangeDetail(friendlyName as string)
        .then((res) => {
          const range = prepareData(lookups, res);
          setRange(range);
          dispatch(setRangeDetails(range));
        })
        .catch(() => {
          setNotFound(true);
        })
        .finally(() => setLoading(false));
    }
  }, [
    friendlyName,
    dispatch,
    welcomeInfoRoot.hasWelcomeInfo,
    welcomeInfoRoot.sector,
    lookups.countries,
  ]);

  // check path changes
  useEffect(() => {
    if (path !== previousPath) {
      setPreviousPath(path);
      setSearchOpened(false);
    }
  }, [path, previousPath]);

  const breadcrumbLinks: PreviousLink[] = [
    {
      name: 'Home',
      to: '/home',
    },
    {
      name: range?.family.name || '',
      to: `/family/${range?.family?.friendlyUrl}`,
    },
    {
      name: range?.name || '',
    },
  ];

  /**
   * open comparison modal
   */
  const openComparison = () => {
    setComparisonOpened(true);
  };

  /**
   * close comparison modal
   */
  const closeComparison = () => {
    setComparisonOpened(false);
  };

  return (
    <>
      {notFound && <NotFound />}
      {!notFound && (
        <div>
          <NavigationHeader
            onInputFocus={() => setSearchOpened(true)}
            breadcrumbLinks={breadcrumbLinks}
          />

          <ContainerForDrawer>
            <LeftDrawer className={styles.LeftDrawer}>
              {loading ? (
                <LoadingSpinner className={styles.loading} />
              ) : (
                range && <RangeLeftDetail range={range} />
              )}
            </LeftDrawer>

            <RightContainer>
              {loading ? (
                <LoadingSpinner className={styles.loading} />
              ) : (
                range && (
                  <div>
                    <DetailHeader
                      title={`${range.name} ${intl.formatMessage({
                        id: 'range-label',
                      })}`}
                      subTitle={range.tagLine}
                      description={range.description}
                      id={range.id}
                      page="Range"
                    />

                    <div className={styles.rangeDetailContainer}>
                      <RangeLeftDetail range={range} />
                    </div>

                    <div className={styles.divider} />

                    <RangeDetailProducts
                      className={styles.productsContainer}
                      range={range}
                      isLoggedIn={hasLogin}
                    />
                  </div>
                )
              )}
            </RightContainer>
          </ContainerForDrawer>

          {productSelected && !comparisonOpened && (
            <ComparisonListModal
              onOpenClick={() => openComparison()}
              products={products}
            />
          )}

          {comparisonOpened && (
            <ComparisonModal onClose={() => closeComparison()} />
          )}

          {searchOpened && (
            <SearchModal onClose={() => setSearchOpened(false)} />
          )}
        </div>
      )}
    </>
  );
}

export default withSectorCountry(ProductRangePage);
