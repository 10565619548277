import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SearchResultProduct } from '../models/search-result-product';
import { SearchResultFamily } from '../models/search-result-family';
import { cloneDeep } from 'lodash';
import { FamilyResponse } from '../models/family-details';
import { Lookups } from '../models/lookups';
import { resolveProductLookups } from '../utils/product.util';
import {
  CheckboxFilter,
  CombinedMultiSelectFilter,
  MultiSelectFilter,
  RangeFilter,
} from '../models/filter';
import {
  arrayToIdMap,
  getRange,
  getUniqueIdArray,
  hasDifferentValue,
  matrixToIdMap,
  testCheckboxFilter,
  testCombinedMultiSelectFilter,
  testMultiSelectFilter,
  testRangeFilter,
  toggleMultiSelectFilter,
} from '../utils/filter.utils';
import { SearchResultRange } from '../models/search-result-range';

type Filters = {
  range: MultiSelectFilter;
  category: MultiSelectFilter;
  subCategory: MultiSelectFilter;
  application: MultiSelectFilter;
  viscosity: MultiSelectFilter;
  corrosionProtection: MultiSelectFilter;
  castrolRecommended: CheckboxFilter;
  esterEpAdditives: CheckboxFilter;
  foodGrade: CheckboxFilter;
  formulatedWithoutSilicate: CheckboxFilter;
  metalType: MultiSelectFilter;
  multiMetalsSuitable: CheckboxFilter;
  nlgi: MultiSelectFilter;
  oilType: CombinedMultiSelectFilter;
  operatingTemperature: RangeFilter;
  subSector: MultiSelectFilter;
  superiorBiodegradation: CheckboxFilter;
  thickener: CombinedMultiSelectFilter;
  viscosityIndex: MultiSelectFilter;
};

export interface FamilyFilterSliceState {
  family: SearchResultFamily | undefined;
  filteredFamily: SearchResultFamily | undefined;
  filters: Filters;
}

/**
 * filter products
 * @param products products
 * @param filters filters
 */
function filterProducts(
  products: SearchResultProduct[],
  filters: Filters,
): SearchResultProduct[] {
  if (filters) {
    const rangeIdMap = arrayToIdMap(filters.range.value);
    const hasNoRangeFilter = filters.range.value.length === 0;
    const categoryIdMap = arrayToIdMap(filters.category.value);
    const hasNoCategoryFilter = filters.category.value.length === 0;
    const subCategoryIdMap = arrayToIdMap(filters.subCategory.value);
    const hasNoSubCategoryFilter = filters.subCategory.value.length === 0;
    const applicationIdMap = arrayToIdMap(filters.application.value);
    const hasNoApplicationFilter = filters.application.value.length === 0;
    const viscosityMap = arrayToIdMap(filters.viscosity.value);
    const hasNoViscosityFilter = filters.viscosity.value.length === 0;
    const corrosionProtectedMap = arrayToIdMap(
      filters.corrosionProtection.value,
    );
    const hasNoCorrosionProtectionFilter =
      filters.corrosionProtection.value.length === 0;
    const metalTypeIdMap = arrayToIdMap(filters.metalType.value);
    const hasNoMetalTypeFilter = filters.metalType.value.length === 0;
    const nlgiMap = arrayToIdMap(filters.nlgi.value);
    const hasNoNlgiFilter = filters.nlgi.value.length === 0;
    const subSectorIdMap = arrayToIdMap(filters.subSector.value);
    const hasNoSubSectorFilter = filters.subSector.value.length === 0;
    const viscosityIndexMap = arrayToIdMap(filters.viscosityIndex.value);
    const hasNoViscosityIndexFilter = filters.viscosityIndex.value.length === 0;
    const oilTypeIdMap = matrixToIdMap(filters.oilType.value);
    const hasNoOilTypeFilter = !filters.oilType.value.find(
      (array) => array.length > 0,
    );
    const thickenerMap = matrixToIdMap(filters.thickener.value);
    const hasNoThickenerFilter = !filters.thickener.value.find(
      (array) => array.length > 0,
    );

    return products.filter((product) => {
      return (
        (hasNoRangeFilter ||
          testMultiSelectFilter(
            rangeIdMap,
            product.rangeId !== null ? [product.rangeId] : [],
          )) &&
        (hasNoCategoryFilter ||
          testMultiSelectFilter(
            categoryIdMap,
            product.productCategories.map((category) => category.categoryId),
          )) &&
        (hasNoSubCategoryFilter ||
          testMultiSelectFilter(
            subCategoryIdMap,
            product.subCategoryId !== null ? [product.subCategoryId] : [],
          )) &&
        (hasNoApplicationFilter ||
          testMultiSelectFilter(
            applicationIdMap,
            product.productApplications.map(
              (application) => application.applicationId,
            ),
          )) &&
        (hasNoViscosityFilter ||
          testMultiSelectFilter(
            viscosityMap,
            product.productCharacteristic.viscosity,
          )) &&
        (hasNoCorrosionProtectionFilter ||
          testMultiSelectFilter(corrosionProtectedMap, [
            product.productCharacteristic.corrosionProtectionId,
          ])) &&
        testRangeFilter(filters.operatingTemperature.value, [
          product.productCharacteristic.minTemperatureC,
          product.productCharacteristic.maxTemperatureC,
        ]) &&
        (hasNoMetalTypeFilter ||
          testMultiSelectFilter(
            metalTypeIdMap,
            product.productCharacteristic.productCharacteristicMetalTypes.map(
              (metalType) => metalType.metalTypeId,
            ),
          )) &&
        (hasNoNlgiFilter ||
          testMultiSelectFilter(
            nlgiMap,
            product.productCharacteristic.productCharacteristicNlgis.map(
              (nlgi) => nlgi.nlgiId,
            ),
          )) &&
        (hasNoSubSectorFilter ||
          testMultiSelectFilter(
            subSectorIdMap,
            product.productSubSectors.map((subSector) => subSector.subSectorId),
          )) &&
        (hasNoViscosityIndexFilter ||
          testMultiSelectFilter(viscosityIndexMap, [
            product.productCharacteristic.viscosityIndexId,
          ])) &&
        testCheckboxFilter(
          filters.castrolRecommended.value,
          product.castrolRecommended,
        ) &&
        testCheckboxFilter(
          filters.esterEpAdditives.value,
          product.productCharacteristic.esterEpAdditives,
        ) &&
        testCheckboxFilter(
          filters.foodGrade.value,
          product.productCharacteristic.foodGradeId,
        ) &&
        testCheckboxFilter(
          filters.formulatedWithoutSilicate.value,
          product.productCharacteristic.formulatedWithoutSilicateId,
        ) &&
        testCheckboxFilter(
          filters.multiMetalsSuitable.value,
          product.productCharacteristic.multiMetalsSuitableId,
        ) &&
        (hasNoOilTypeFilter ||
          testCombinedMultiSelectFilter(oilTypeIdMap, [
            [product.productCharacteristic.baseOilTypeGenericId],
            [product.productCharacteristic.baseOilTypeSpecificId],
          ])) &&
        (hasNoThickenerFilter ||
          testCombinedMultiSelectFilter(thickenerMap, [
            [product.productCharacteristic.thickenerGenericId],
            [product.productCharacteristic.thickenerSpecificId],
          ]))
      );
    });
  } else {
    return products;
  }
}

/**
 * filter ranges
 * @param ranges ranges
 * @param filters filters
 */
function filterRanges(
  ranges: SearchResultRange[] | undefined,
  filters: Filters,
): SearchResultRange[] {
  if (!ranges) {
    return [];
  }

  const rangeValues: { [k: string]: boolean } = arrayToIdMap(
    filters.range.value,
  );

  const hasRangeFilters = filters.range.value.length > 0;

  return ranges.filter((range) => {
    return hasRangeFilters ? rangeValues[range.name] : true;
  });
}

/**
 * get filtered search result
 * @param searchResult search result
 * @param filters filters
 */
function getFilteredSearchResult(
  data: FamilyResponse,
  filters?: Filters,
): FamilyResponse {
  const clone = cloneDeep(data);

  if (filters) {
    clone.products = filterProducts(clone.products, filters);
    clone.ranges = filterRanges(clone.ranges, filters);
  }

  return clone;
}

function getFilteredFamily(family?: SearchResultFamily, filters?: Filters) {
  family = cloneDeep(family);

  const products = filters
    ? filterProducts(family?.productChildren || [], filters)
    : family?.productChildren || [];
  const ranges = filters
    ? filterRanges(family?.rangeChildren || [], filters)
    : family?.rangeChildren || [];

  ranges.forEach(
    (x) => (x.productChildren = products.filter((y) => y.rangeId === x.id)),
  );

  return {
    id: family?.id || 0,
    description: family?.description || '',
    name: family?.name || '',
    friendlyUrl: family?.friendlyUrl || '',
    productChildren: products.filter((x) => !x.rangeId),
    rangeChildren: ranges,
  };
}

/**
 * get filters with filter ids extracted from products
 */
function getFiltersWithFamily(
  searchResult?: SearchResultFamily,
  currentFilters?: Filters,
): Filters {
  const products = [
    ...(searchResult?.rangeChildren || []).reduce<SearchResultProduct[]>(
      (productArray, currentRange) => {
        return [...productArray, ...(currentRange.productChildren || [])];
      },
      [],
    ),
    ...(searchResult?.productChildren || []),
  ];
  return {
    range: {
      options: getUniqueIdArray(
        searchResult?.rangeChildren || [],
        (range) => range.id,
        currentFilters?.range.value,
      ),
      value: currentFilters?.range.value || [],
    },
    category: {
      options: getUniqueIdArray(
        products,
        (prod) => prod.productCategories.map((cat) => cat.categoryId),
        currentFilters?.category.value,
      ),
      value: currentFilters?.category.value || [],
    },
    subCategory: {
      options: getUniqueIdArray(
        products,
        (prod) => prod.subCategoryId,
        currentFilters?.subCategory.value,
      ),
      value: currentFilters?.subCategory.value || [],
    },
    application: {
      options: getUniqueIdArray(
        products,
        (prod) => prod.productApplications.map((app) => app.applicationId),
        currentFilters?.application.value,
      ),
      value: currentFilters?.application.value || [],
    },
    viscosity: {
      options: getUniqueIdArray(
        products,
        (prod) => prod.productCharacteristic.viscosity,
        currentFilters?.viscosity.value,
      ),
      value: currentFilters?.viscosity.value || [],
    },
    corrosionProtection: {
      options: getUniqueIdArray(
        products,
        (prod) => prod.productCharacteristic.corrosionProtectionId,
        currentFilters?.corrosionProtection.value,
      ),
      value: currentFilters?.corrosionProtection.value || [],
    },
    castrolRecommended: {
      hasDifferentValue:
        currentFilters?.multiMetalsSuitable.value ||
        hasDifferentValue(products.map((prod) => prod.castrolRecommended)),
      value: currentFilters?.multiMetalsSuitable.value || false,
    },
    esterEpAdditives: {
      hasDifferentValue:
        currentFilters?.multiMetalsSuitable.value ||
        hasDifferentValue(
          products.map((prod) => prod.productCharacteristic.esterEpAdditives),
        ),
      value: currentFilters?.multiMetalsSuitable.value || false,
    },
    foodGrade: {
      hasDifferentValue:
        currentFilters?.multiMetalsSuitable.value ||
        hasDifferentValue(
          products.map((prod) => prod.productCharacteristic.foodGradeId),
        ),
      value: currentFilters?.multiMetalsSuitable.value || false,
    },
    formulatedWithoutSilicate: {
      hasDifferentValue:
        currentFilters?.multiMetalsSuitable.value ||
        hasDifferentValue(
          products.map(
            (prod) => prod.productCharacteristic.formulatedWithoutSilicateId,
          ),
        ),
      value: currentFilters?.multiMetalsSuitable.value || false,
    },
    metalType: {
      options: getUniqueIdArray(
        products,
        (prod) =>
          prod.productCharacteristic.productCharacteristicMetalTypes.map(
            (metalType) => metalType.metalTypeId,
          ),
        currentFilters?.metalType.value,
      ),
      value: [],
    },
    multiMetalsSuitable: {
      hasDifferentValue:
        currentFilters?.multiMetalsSuitable.value ||
        hasDifferentValue(
          products.map(
            (prod) => prod.productCharacteristic.multiMetalsSuitableId,
          ),
        ),
      value: currentFilters?.multiMetalsSuitable.value || false,
    },
    nlgi: {
      options: getUniqueIdArray(
        products,
        (prod) =>
          prod.productCharacteristic.productCharacteristicNlgis.map(
            (nlgi) => nlgi.nlgiId,
          ),
        currentFilters?.nlgi.value,
      ),
      value: currentFilters?.nlgi.value || [],
    },
    oilType: {
      options: [
        getUniqueIdArray(
          products,
          (prod) => prod.productCharacteristic.baseOilTypeGenericId,
          currentFilters?.oilType.value[0],
        ),
        getUniqueIdArray(
          products,
          (prod) => prod.productCharacteristic.baseOilTypeSpecificId,
          currentFilters?.oilType.value[1],
        ),
      ],
      value: [
        currentFilters?.oilType.value[0] || [],
        currentFilters?.oilType.value[1] || [],
      ],
    },
    operatingTemperature: (() => {
      const range = getRange(products, (prod) => [
        prod.productCharacteristic.minTemperatureC,
        prod.productCharacteristic.maxTemperatureC,
      ]);
      if (currentFilters?.operatingTemperature.value) {
        return {
          ...currentFilters?.operatingTemperature,
        };
      } else {
        return {
          min: range ? range[0] : null,
          max: range ? range[1] : null,
          value: range,
        };
      }
    })(),
    subSector: {
      options: getUniqueIdArray(
        products,
        (prod) =>
          prod.productSubSectors.map((subSector) => subSector.subSectorId),
        currentFilters?.subSector.value,
      ),
      value: currentFilters?.subSector.value || [],
    },
    superiorBiodegradation: {
      hasDifferentValue:
        currentFilters?.superiorBiodegradation.value ||
        hasDifferentValue(
          products.map(
            (prod) => prod.productCharacteristic.superiorBiodegradationId,
          ),
        ),
      value: currentFilters?.superiorBiodegradation.value || false,
    },
    thickener: {
      options: [
        getUniqueIdArray(
          products,
          (prod) => prod.productCharacteristic.thickenerGenericId,
          currentFilters?.thickener.value[0],
        ),
        getUniqueIdArray(
          products,
          (prod) => prod.productCharacteristic.thickenerSpecificId,
          currentFilters?.thickener.value[1],
        ),
      ],
      value: [
        currentFilters?.thickener.value[0] || [],
        currentFilters?.thickener.value[1] || [],
      ],
    },
    viscosityIndex: {
      options: getUniqueIdArray(
        products,
        (prod) => prod.productCharacteristic.viscosityIndexId,
        currentFilters?.viscosityIndex.value,
      ),
      value: currentFilters?.viscosityIndex.value || [],
    },
  };
}

/**
 * manage family filter state
 */
export const familyFilterSlice = createSlice({
  name: 'family-filter',
  initialState: {
    family: undefined,
    filteredFamily: undefined,
    filters: getFiltersWithFamily(),
  } as FamilyFilterSliceState,
  reducers: {
    // set search result for family ranges, products and create filters
    setFilterableFamilyDetails: (
      state,
      action: PayloadAction<{
        data: FamilyResponse;
        lookups: Lookups;
      }>,
    ) => {
      const result = action.payload.data;
      const lookups = action.payload.lookups;

      result.products = resolveProductLookups(result.products, lookups);

      result.ranges.forEach((range) => {
        range.productChildren = result.products.filter(
          (x) => x.rangeId === range.id,
        );
      });

      const filteredResult = getFilteredSearchResult(result);
      const data: SearchResultFamily = {
        id: result.family.id,
        description: result.family.description,
        name: result.family.name,
        friendlyUrl: result.family.friendlyUrl,
        productChildren: filteredResult.products,
        rangeChildren: filteredResult.ranges,
      };

      const filteredData: SearchResultFamily = {
        ...data,
        productChildren: filteredResult.products.filter((x) => !x.rangeId),
      };

      return {
        ...state,
        result,
        family: data,
        filteredFamily: filteredData,
        filters: getFiltersWithFamily(data),
      };
    },
    // toggle filter
    toggleMultiSelectFilterById: (
      state,
      action: PayloadAction<{
        key: keyof Filters;
        filter: number;
      }>,
    ) => {
      const { key, filter } = action.payload;
      const filters = {
        ...state.filters,
        [key]: toggleMultiSelectFilter(
          state.filters[key] as MultiSelectFilter,
          filter,
        ),
      };

      const filteredFamily = getFilteredFamily(state.family, filters);

      console.log(
        'toggleMultiSelectFilterById',
        filters,
        'filteredFamily',
        filteredFamily,
      );

      return {
        ...state,
        filters: getFiltersWithFamily(filteredFamily, filters),
        filteredFamily,
      };
    },
    // clear filter for type
    clearMultiSelectFilter: (
      state,
      action: PayloadAction<{ key: keyof Filters }>,
    ) => {
      const { key } = action.payload;
      const filters = {
        ...state.filters,
        [key]: {
          ...state.filters[key],
          value: [],
        },
      };

      const filteredFamily = getFilteredFamily(state.family, filters);

      return {
        ...state,
        filters: getFiltersWithFamily(state.family, filters),
        filteredFamily,
      };
    },
    changeRangeFilter: (
      state,
      action: PayloadAction<{ key: keyof Filters; value: number[] }>,
    ) => {
      const { key } = action.payload;
      const filters = {
        ...state.filters,
        [key]: {
          ...state.filters[key],
          value: action.payload.value,
        },
      };

      const filteredFamily = getFilteredFamily(state.family, filters);

      return {
        ...state,
        filters: getFiltersWithFamily(filteredFamily, filters),
        filteredFamily,
      };
    },
    toggleCheckboxFilter: (
      state,
      action: PayloadAction<{ key: keyof Filters }>,
    ) => {
      const { key } = action.payload;
      const filters = {
        ...state.filters,
        [key]: {
          ...state.filters[key],
          value: !state.filters[key].value,
        },
      };

      const filteredFamily = getFilteredFamily(state.family, filters);

      console.log(
        'toggleCheckboxFilter',
        filters,
        'filteredFamily',
        filteredFamily,
      );

      return {
        ...state,
        filters: getFiltersWithFamily(filteredFamily, filters),
        filteredFamily,
      };
    },
    toggleCombinedMultiSelectFilter: (
      state,
      action: PayloadAction<{
        key: keyof Filters;
        idMap: { [index: number]: number };
      }>,
    ) => {
      const { key } = action.payload;
      const filtersOfKey = cloneDeep(state.filters[key]);

      Object.entries(action.payload.idMap).forEach(([indexStr, id]) => {
        const index = Number(indexStr);
        const selectedIds = (filtersOfKey.value as number[][])[index];
        const selectedIndex = selectedIds.indexOf(id);
        if (selectedIndex >= 0) {
          // remove
          selectedIds.splice(selectedIndex, 1);
        } else {
          // add
          selectedIds.push(id);
        }
      });

      const newFilters = {
        ...state.filters,
        [key]: filtersOfKey,
      };

      const filteredFamily = getFilteredFamily(state.family, newFilters);

      console.log(
        'toggleCombinedMultiSelectFilter',
        newFilters,
        'filteredFamily',
        filteredFamily,
      );

      return {
        ...state,
        filters: getFiltersWithFamily(filteredFamily, newFilters),
        filteredFamily,
      };
    },
    clearCombinedMultiSelectFilter: (
      state,
      action: PayloadAction<{ key: keyof Filters }>,
    ) => {
      const { key } = action.payload;
      const filtersOfKey = cloneDeep(state.filters[key]);

      (filtersOfKey.value as number[][]).forEach((array) =>
        array.splice(0, array.length),
      );

      const newFilters = {
        ...state.filters,
        [key]: filtersOfKey,
      };

      const filteredFamily = getFilteredFamily(state.family, newFilters);

      return {
        ...state,
        filters: getFiltersWithFamily(filteredFamily),
        filteredFamily,
      };
    },
    // clear all filters
    clearAllFilters: (state) => {
      return {
        ...state,
        filters: getFiltersWithFamily(state.family),
        filteredFamily: getFilteredFamily(state.family),
      };
    },
  },
});

export const {
  setFilterableFamilyDetails,
  toggleMultiSelectFilterById,
  clearMultiSelectFilter,
  changeRangeFilter,
  toggleCheckboxFilter,
  toggleCombinedMultiSelectFilter,
  clearCombinedMultiSelectFilter,
  clearAllFilters,
} = familyFilterSlice.actions;

export default familyFilterSlice.reducer;
