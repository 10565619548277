const config = {
  GOOGLE_API_KEY: 'AIzaSyA7lB56eTJsBpyregS_9GyU8slba63as54',
  API_BASE_URL: '/api',
  TOKEN_EXPIRED_VERIFY_INTERNAL: 5000,
  ENABLE_LANGUAGE: false,
  COUNTRY_TYPE: 'country',
  PORT_TYPE: 'port',
  FAMILY_TYPE: 'family',
  RANGE_TYPE: 'range',
  DEFAULT_CULTURE: 'en-GB',
  PRODUCT_TYPE: 'product',
  OEM_TYPE: 'oem',
  ANNOUNCEMENTS_SCROLL_SPEED: 100, // px/s
  MARINE_SECTOR: 'directory-marine-services',
  ALL_SECTORS: 'all-sectors',
  INDUSTRIAL: 'industrial',
  ALL_ITEM_VALUE: -1,
  CONTACT_US_LINK:
    'https://www.castrol.com/en_gb/united-kingdom/home/site-tunnel.html',
  FIND_DISTRIBUTOR_LINK: 'https://maps.castrol.com/?filters=industrial&types=6',
  ALL_SECTORS_WELCOME_INFO: {
    friendlyUrl: 'all-sectors',
    id: 0,
    name: 'All Sectors',
    supportFindADistributor: 0,
  },
  FRIENDLY_URL_NAME_REGEX: /[^a-z0-9-]/g,
  EMAIL_REGEX:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  HELP_EMAIL_SUBJECTS: [
    'I cannot find the product',
    'I cannot find the port distributor',
    'I cannot find the contact',
  ],
  UNREGISTER_REASONS: [
    'Privacy concerns',
    'Concerned about my data',
    'Too many ads',
    'Trouble getting started',
    'Acme another reason',
    'Lorem another reason',
    'Other, please explain further:',
  ],
  AUTH: {
    auth: {
      clientId: '92cf25b5-fbcf-4526-a20b-25b6dd8d3acf',
      authority:
        'https://login.microsoftonline.com/ea80952e-a476-42d4-aaf4-5457852b0f7e',
      redirectUri: `${window.location.protocol}//${window.location.host}/login`,
    },
    cache: {
      cacheLocation: 'sessionStorage', // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
  },
  LOGIN_REQUEST: {
    scopes: ['92cf25b5-fbcf-4526-a20b-25b6dd8d3acf/.default'],
  },
  bucketUrl:
    'https://navitas-navapp-prod.s3.eu-west-1.amazonaws.com/castrol-lubricantoracle',
  akamiUrl: 'https://thelubricantoracle.castrol.com',
};

export default config;
