import { PropsWithoutRef, useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styles from './UserTable.module.scss';

import { TableColumn } from '../../../models/table-column';
import { getDateTimeString } from '../../../utils/date.util';
import UserListTable, {
  sortChangeHandler,
} from '../UserListTable/UserListTable';
import {
  UserResponse,
  SearchUserResponse,
} from '../../../models/user-response';
import Paginator from '../Paginator/Paginator';
import UserActionMenu from '../../Admin/common/UserActionMenu/UserActionMenu';
import {
  changePagination,
  changeSort,
} from '../../../features/userFilterSlice';
import { RootStore } from '../../../store';
import Button from '../../common/Button/Button';

import { ReactComponent as EditWhiteIcon } from '../../../icons/edit-white.svg';
import { ReactComponent as XOctagonWhiteIcon } from '../../../icons/x-octagon-white.svg';
import { ReactComponent as DocWhiteIcon } from '../../../icons/ic-doc-white.svg';
import { ReactComponent as LockWhiteIcon } from '../../../icons/lock-outline-white.svg';
import { ReactComponent as DeleteWhiteIcon } from '../../../icons/minus-circle-white.svg';

interface UserTableProps {
  userResponse: SearchUserResponse;
  activateUser?: (userId: number) => void;
  deactivateUser?: (userId: number) => void;
  deleteUser?: (userId: number) => void;
  resetPassword?: (userEmail: string) => void;
}

function UserTable({
  userResponse,
  activateUser,
  deactivateUser,
  deleteUser,
  resetPassword,
}: PropsWithoutRef<UserTableProps>) {
  const intl = useIntl();
  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { sortQuery } = useSelector((state: RootStore) => state.userFilter);
  // table sorts
  const [sortBy, setSortBy] = useState('fullName');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  useEffect(() => {
    setSortBy(sortQuery.sortBy);
    setSortDirection(sortQuery.sortDirection);
  }, [sortQuery]);

  const menuClick = (val: string, item: UserResponse) => {
    if (val === 'menu-edit-user') {
      navigate(`/admin/users/${item.id}`);
    } else if (val === 'menu-activate-user') {
      activateUser && activateUser(item.id);
    } else if (val === 'menu-deactivate-user') {
      deactivateUser && deactivateUser(item.id);
    } else if (val === 'menu-delete-user') {
      deleteUser && deleteUser(item.id);
    } else if (val === 'menu-reset-password') {
      resetPassword && resetPassword(item.email);
    }
  };

  const showActiveItem = (data: UserResponse): string => {
    if (data.active === null) {
      return getTranslatedMessage('pending-label');
    }
    if (data.active) {
      if (data.idle) {
        return getTranslatedMessage('idle-label');
      }

      return getTranslatedMessage('active-label');
    }

    return getTranslatedMessage('inactive-label');
  };

  // port children columns
  const columns: TableColumn<UserResponse>[] = [
    {
      key: 'fullName',
      property: 'fullName',
      label: getTranslatedMessage('full-name-label'),
      width: '15%',
      to: (data) => {
        return `/admin/users/${data.id}`;
      },
    },
    {
      key: 'email',
      property: 'email',
      label: getTranslatedMessage('email-address-label'),
      width: '15%',
    },
    {
      key: 'companyName',
      property: 'companyName',
      label: getTranslatedMessage('company-name-label'),
      width: '15%',
    },
    {
      key: 'country.name',
      property: 'country.name',
      label: getTranslatedMessage('country-label'),
      width: '9%',
    },
    {
      key: 'language.name',
      property: 'language.name',
      label: getTranslatedMessage('language-label'),
      width: '9%',
    },
    {
      key: 'role',
      property: 'role',
      label: getTranslatedMessage('user-role-label'),
      width: '10%',
    },
    {
      key: 'active',
      property: '',
      label: getTranslatedMessage('status-label'),
      width: '10%',
      render: (data: UserResponse) => <div>{showActiveItem(data)}</div>,
    },
    {
      key: 'lastLoginAt',
      property: '',
      label: getTranslatedMessage('last-active-label'),
      width: '10%',
      render: (data: UserResponse) => (
        <div>{getDateTimeString(data.lastLoginAt)}</div>
      ),
    },
    {
      key: '',
      property: '',
      label: getTranslatedMessage('actions-label'),
      width: '7%',
      render: (data: UserResponse) => (
        <UserActionMenu
          active={data.active}
          idle={data.idle}
          onOptionClick={(val) => menuClick(val, data)}
        />
      ),
      mobileRender: (data: UserResponse) => (
        <div className={styles.actions}>
          <Button
            onClick={() => menuClick('menu-edit-user', data)}
            color={'green'}
            className={styles.mobileActionButton}
          >
            <EditWhiteIcon />
            <FormattedMessage id={'menu-edit-user'} />
          </Button>

          {(!data.active || data.idle) && (
            <Button
              onClick={() => menuClick('menu-activate-user', data)}
              color={'green'}
              className={styles.mobileActionButton}
            >
              <DocWhiteIcon />
              <FormattedMessage id={'menu-activate-user'} />
            </Button>
          )}

          {data.active && !data.idle && (
            <Button
              onClick={() => menuClick('menu-deactivate-user', data)}
              color={'green'}
              className={styles.mobileActionButton}
            >
              <XOctagonWhiteIcon />
              <FormattedMessage id={'menu-deactivate-user'} />
            </Button>
          )}

          <Button
            onClick={() => menuClick('menu-reset-password', data)}
            color={'green'}
            className={styles.mobileActionButton}
          >
            <LockWhiteIcon />
            <FormattedMessage id={'menu-reset-password'} />
          </Button>

          <Button
            onClick={() => menuClick('menu-delete-user', data)}
            color={'red'}
            className={styles.mobileActionButton}
          >
            <DeleteWhiteIcon />
            <FormattedMessage id={'menu-delete-user'} />
          </Button>
        </div>
      ),
    },
  ];

  /**
   * handle sort change
   * @param column column
   */
  const onSortChange = (column: TableColumn<UserResponse>) => {
    if (!column.key) {
      return;
    }

    const changes = sortChangeHandler(
      [sortBy, setSortBy],
      [sortDirection, setSortDirection],
      column,
    );

    dispatch(
      changePagination({
        page: 1,
        perPage: userResponse.perPage,
      }),
    );
    dispatch(changeSort(changes));
  };

  return (
    <div className={styles.tableContainer}>
      <UserListTable
        data={userResponse.items}
        sortBy={sortBy}
        sortDirection={sortDirection}
        columns={columns}
        onSortChange={onSortChange}
        showCheckbox={false}
        columnClassName={styles.tableColumn}
        from={'user-results'}
      />
      <Paginator
        page={userResponse.page}
        perPage={userResponse.perPage}
        total={userResponse.total}
        onPageChange={(value) =>
          dispatch(
            changePagination({
              page: value,
              perPage: userResponse.perPage,
            }),
          )
        }
        onPerPageChange={(value) =>
          dispatch(
            changePagination({
              page: userResponse.page,
              perPage: value,
            }),
          )
        }
      />
    </div>
  );
}

export default UserTable;
