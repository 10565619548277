import { Help, HelpData, HelpResponse } from './../models/help';
import { apiInstance } from '../api/requestInterceptor';
import { saveAs } from 'file-saver';

interface GetBannerReqParams {
  page: number;
  perPage: number;
  sortBy: string;
  sortDirection: string;
  captionName: string;
  helpTypeId: number | null;
}

/**
 * get helps
 */
async function getHelps({
  page = 0,
  perPage = 10,
  sortBy = 'captionName',
  sortDirection = 'asc',
  captionName = '',
  helpTypeId,
}: GetBannerReqParams): Promise<HelpResponse> {
  const res = await apiInstance.get('/helps', {
    params: {
      page,
      perPage,
      sortBy,
      sortDirection,
      captionName,
      helpTypeIds: helpTypeId ? [helpTypeId] : null,
    },
  });

  return res.data;
}

/**
 * get help by id
 */
async function getHelpById(id: number): Promise<Help> {
  const res = await apiInstance.get(`/helps/${id}`);
  return res.data;
}

/**
 * Create help
 * @param data help data
 */
async function createHelp(data: HelpData): Promise<void> {
  await apiInstance.post<any>('/helps', data);
}

/**
 * update help
 * @param data help data
 */
async function updateHelp(id: number, data: HelpData): Promise<void> {
  await apiInstance.put<any>(`/helps/${id}`, data);
}

/**
 * delete help
 * @param data help id
 */
async function deleteHelp(id: number): Promise<void> {
  await apiInstance.delete<any>(`/helps/${id}`);
}

/**
 * open document to be downloaded
 * @param approvalDocId help document id
 */
async function downloadHelpDocument(id: number): Promise<any> {
  const res = await apiInstance.get<any>(`/helps/${id}/download`, {
    responseType: 'blob',
  });

  if (res.status === 200) {
    const filename =
      res.headers['content-disposition']?.split('filename=')[1] ||
      'help-document.pdf';
    saveAs(
      res.data,
      decodeURIComponent(filename.replace('"', '').replace('"', '')),
    );
  }

  return res.data;
}

export {
  getHelps,
  createHelp,
  downloadHelpDocument,
  getHelpById,
  updateHelp,
  deleteHelp,
};
