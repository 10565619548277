import FilterGroup from '../FilterGroup';
import { PropsWithoutRef, useMemo } from 'react';
import { FilterOption } from '../../../../models/filter-option';
import { LookupItemDictionary } from '../../../../models/lookups';
import { CombinedMultiSelectFilter } from '../../../../models/filter';

interface CombinedMultiSelectFilterGroupProps {
  open?: boolean;
  className?: string;
  title: string;
  filterOfKey: CombinedMultiSelectFilter;
  lookupDicts: LookupItemDictionary[];
  onFilterChange: (filter: FilterOption) => void;
  onFilterReset: () => void;
}

/**
 * filter group for family
 * @param type filter type
 * @param open set true to make options opened on init
 * @param className classname
 */
function CombinedMultiSelectFilterGroup({
  open,
  className,
  title,
  filterOfKey,
  lookupDicts,
  onFilterChange,
  onFilterReset,
}: PropsWithoutRef<CombinedMultiSelectFilterGroupProps>) {
  const actualFilters = useMemo<FilterOption[]>(() => {
    const labelToIdsDict: {
      [label: string]: { [arrayIndex: number]: number };
    } = {};
    filterOfKey.options.forEach((opts, index) => {
      opts.forEach((opt) => {
        const option = lookupDicts[index][opt];
        const optionDict = labelToIdsDict[option.name];
        if (!optionDict) {
          labelToIdsDict[option.name] = {};
        }
        labelToIdsDict[option.name] = {
          ...labelToIdsDict[option.name],
          [index]: option.id,
        };
      });
    });
    return Object.entries(labelToIdsDict).map(([label, ids]) => {
      return {
        name: label,
        value: JSON.stringify(ids),
      };
    });
  }, [filterOfKey, lookupDicts]);

  const actualSelectedFilters = useMemo<FilterOption[]>(() => {
    const selectedLabels: { [label: string]: boolean } = {};
    filterOfKey.value.forEach((ids, arrayIndex) => {
      ids.forEach((id) => {
        const option = lookupDicts[arrayIndex][id];
        selectedLabels[option.name] = true;
      });
    });
    return actualFilters.filter((opt) => selectedLabels[opt.name]);
  }, [filterOfKey, lookupDicts, actualFilters]);

  return (
    <FilterGroup
      open={open}
      className={className}
      title={title}
      filters={actualFilters}
      onChange={onFilterChange}
      onReset={onFilterReset}
      selectedOptions={actualSelectedFilters}
    />
  );
}

export default CombinedMultiSelectFilterGroup;
