import { useIntl } from 'react-intl';

import FormRow from '../FormRow/FormRow';

import { TextareaRowProps } from '../../../models/admin-form-types';
import Textarea from '../../common/Textarea/Textarea';

const TextareaRow = ({
  labelId,
  value,
  onChange,
  placeholder,
  required,
  tooltip,
  maxLength,
  className,
}: TextareaRowProps) => {
  const intl = useIntl();
  placeholder = placeholder || intl.formatMessage({ id: 'input-placeholder' });

  return (
    <FormRow labelId={labelId} required={required} tooltip={tooltip}>
      <Textarea
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        maxLength={maxLength}
        className={className}
      />
    </FormRow>
  );
};

export default TextareaRow;
