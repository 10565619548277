import {
  CountryItem,
  LanguageItem,
  LookupItem,
  LookupItemDictionary,
  Lookups,
  SectorItem,
} from '../models/lookups';
import { IdItem } from '../models/id-item';
import { ProductCategory } from '../models/product-category';
import { Application } from '../models/application';
import { ProductType } from '../models/product-type';
import { IdItemWithProducts } from '../models/id-item-with-products';
import { SearchResultSummary } from '../models/search-result-summary';
import {
  FamilyItem,
  PortItem,
  ProductItem,
  RangeItem,
  SearchSummaryResponse,
  OemItem,
  CountrySearchItem,
  DistributorItem,
} from '../models/search-summary-response';
import { SearchCountrySummary } from '../models/search-country-summary';
import { SearchPortSummary } from '../models/search-port-summary';
import { SearchFamilySummary } from '../models/search-family-summary';
import { SearchRangeSummary } from '../models/search-range-summary';
import { SearchProductSummary } from '../models/search-product-summary';
import { SearchOemSummary } from '../models/search-oem-summary';
import { SearchDistributorSummary } from '../models/search-distributor-summary';

/**
 * filter lookup item by id
 * @param items all lookups
 * @param ids ids
 */
export function filterLookupItemByIds(
  items: LookupItem[],
  ids: IdItem[],
): LookupItem[] {
  const map: { [k: number]: boolean } = {};

  ids.forEach((item) => (map[item.id] = true));

  return items.filter((item) => map[item.id]);
}

/**
 * filter sector by friendly url
 * @param sectors sectors
 * @param url url
 */
export function filterSectorByFriendlyURL(
  sectors: SectorItem[],
  url: string,
): SectorItem | null {
  return sectors.find((item) => item.friendlyUrl === url) || null;
}

/**
 * filter countries by culture code
 * @param countries countries
 * @param culture culture
 */
export function filterCountryByCulture(
  countries: CountryItem[],
  culture: string,
): CountryItem | null {
  const split = culture.split('-');

  return countries.find((item) => item.code === split[1]) || null;
}

/**
 * filter languages by culture code
 * @param languages languages
 * @param culture culture
 */
export function filterLanguageByCulture(
  languages: LanguageItem[],
  culture: string,
): LanguageItem | null {
  const split = culture.split('-');

  return languages.find((item) => item.code === split[0]) || null;
}

/**
 * map categories with id items
 * @param categories categories
 * @param applications applications
 * @param ids ids
 */
export function mapCategoriesWithIds(
  categories: LookupItem[],
  applications: LookupItem[],
  ids: IdItemWithProducts[],
): ProductCategory[] {
  const map: { [k: number]: IdItemWithProducts } = {};

  ids.forEach((item) => (map[item.id] = item));

  return categories
    .filter((item) => map[item.id])
    .map((item) => {
      return {
        id: item.id,
        name: item.name,
        products: map[item.id].productCount,
        children: mapApplicationsWithIds(
          applications,
          categories,
          map[item.id].applications || [],
        ),
      };
    });
}

/**
 * map applications with id items
 * @param applications applications
 * @param categories categories
 * @param ids ids
 */
export function mapApplicationsWithIds(
  applications: LookupItem[],
  categories: LookupItem[],
  ids: IdItemWithProducts[],
): Application[] {
  const map: { [k: number]: IdItemWithProducts } = {};

  ids.forEach((item) => (map[item.id] = item));

  return applications
    .filter((item) => map[item.id])
    .map((item) => {
      return {
        id: item.id,
        name: item.name,
        products: map[item.id].productCount,
        children: mapCategoriesWithIds(
          categories,
          applications,
          map[item.id].categories || [],
        ),
      };
    });
}

/**
 * map product types with id items
 * @param productTypes product types
 * @param categories categories
 * @param ids ids
 */
export function mapProductTypesWithIds(
  productTypes: LookupItem[],
  categories: LookupItem[],
  ids: IdItemWithProducts[],
): ProductType[] {
  const map: { [k: number]: IdItemWithProducts } = {};

  ids.forEach((item) => (map[item.id] = item));

  return productTypes
    .filter((item) => map[item.id])
    .map((item) => {
      return {
        id: item.id,
        name: item.name,
        products: map[item.id].productCount,
        categories: mapCategoriesWithIds(
          categories,
          [],
          map[item.id].categories || [],
        ),
      };
    });
}

/**
 * map search summary response with lookups
 * @param res response
 * @param lookups lookups
 */
export function mapSearchSummaryResponseWithLookups(
  res: SearchSummaryResponse,
  lookups: Lookups,
): SearchResultSummary {
  return {
    countries: mapSearchSummaryCountry(
      lookups.countries,
      res.countries || [],
      res.ports || [],
    ),
    ports: mapSearchSummaryPorts(lookups.countries, res.ports || []),
    families: mapSearchSummaryFamilies(res.families || []),
    ranges: mapSearchSummaryRanges(res.ranges || []),
    products: mapSearchSummaryProducts(res.products || []),
    oems: mapSearchSummaryOems(res.oems || []),
    categories: mapCategoriesWithIds(
      lookups.categories,
      lookups.applications,
      res.categories || [],
    ),
    applications: mapApplicationsWithIds(
      lookups.applications,
      lookups.categories,
      res.applications || [],
    ),
    distributors: mapSearchSummaryDistributors(
      lookups.countries,
      res.distributors || [],
    ),
  };
}

export function mapSearchSummaryCountry(
  countries: LookupItem[],
  ids: CountrySearchItem[],
  ports: PortItem[],
): SearchCountrySummary[] {
  const map: { [k: number]: CountrySearchItem } = {};
  const portMap: { [k: number]: PortItem[] } = {};

  ids.forEach((item) => (map[item.id] = item));
  ports.forEach((item) => {
    if (!portMap[item.countryId]) {
      portMap[item.countryId] = [];
    }

    portMap[item.countryId].push(item);
  });

  return countries
    .filter((item) => map[item.id])
    .map((item) => {
      return {
        id: item.id,
        name: item.name,
        products: map[item.id]?.productCount || 0,
        portCount: map[item.id]?.portCount || 0,
      };
    });
}

export function mapSearchSummaryPorts(
  countries: LookupItem[],
  ports: PortItem[],
): SearchPortSummary[] {
  const map: { [k: number]: string } = {};

  countries.forEach((item) => (map[item.id] = item.name));

  return ports.map((item) => {
    return {
      id: item.id,
      friendlyUrl: item.friendlyUrl,
      name: item.name,
      description: '',
      products: item.productCount,
      country: map[item.countryId] || '',
    };
  });
}

export function mapSearchSummaryFamilies(
  families: FamilyItem[],
): SearchFamilySummary[] {
  return families.map((item) => {
    return {
      id: item.id,
      friendlyUrl: item.friendlyUrl,
      name: item.name,
      description: item.description,
      products: item.productCount,
    };
  });
}

export function mapSearchSummaryRanges(
  ranges: RangeItem[],
): SearchRangeSummary[] {
  return ranges.map((item) => {
    return {
      id: item.id,
      friendlyUrl: item.friendlyUrl,
      name: item.name,
      description: item.description,
      products: item.productCount,
      category: item.tagLine,
    };
  });
}

export function mapSearchSummaryProducts(
  products: ProductItem[],
): SearchProductSummary[] {
  return products.map((item) => {
    return {
      id: item.id,
      friendlyUrl: item.friendlyUrl,
      name: item.name,
      description: item.description,
      category: item.tagLine,
      active: item.active,
      hasOemApproved:
        !!item.oemProductApprovalCount && item.oemProductApprovalCount > 0,
    };
  });
}

export function mapSearchSummaryOems(oems: OemItem[]): SearchOemSummary[] {
  return oems.map((item) => {
    return {
      id: item.id,
      name: item.name,
      description: item.detail,
      products: item.productCount,
    };
  });
}

export function mapSearchSummaryDistributors(
  countries: LookupItem[],
  distributors: DistributorItem[],
): SearchDistributorSummary[] {
  return distributors.map((item) => {
    return {
      id: item.id,
      name: item.name,
      friendlyUrl: item.friendlyUrl,
      active: item.active,
      country:
        countries.find((country) => country.id === item.countryId)?.name || '',
    };
  });
}

export function mapLookupItemsDictionary(
  lookupItems: LookupItem[],
): LookupItemDictionary {
  const dict: LookupItemDictionary = {};
  lookupItems.forEach((item) => (dict[item.id] = item));
  return dict;
}
