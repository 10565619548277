import { CancelToken } from 'axios';
import { saveAs } from 'file-saver';
import { apiInstance } from '../api/requestInterceptor';

import { SearchUserResponse, UserResponse } from '../models/user-response';
import {
  UpdateUserRequest,
  SaveBulkUsersRequest,
} from '../models/user-request';
import { HelpEmail } from '../models/help-email';
import {
  RequestAccount,
  RequestInternalAccount,
} from '../models/request-account';

export interface UserSearchCriteria {
  page: number;
  perPage: number;
  sortBy: string;
  sortDirection: string;
  name?: string;
  email?: string;
  roles?: string[];
  visibilities?: string[];
}

/**
 * search user
 * @param criteria user query criteria
 */
async function searchUsers(
  criteria: UserSearchCriteria,
  cancelToken?: CancelToken,
): Promise<SearchUserResponse> {
  const queryList = [];
  queryList.push(`page=${criteria.page}`);
  queryList.push(`perPage=${criteria.perPage}`);
  queryList.push(`sortBy=${criteria.sortBy}`);
  queryList.push(`sortDirection=${criteria.sortDirection}`);

  if (criteria.name) {
    queryList.push(`name=${criteria.name}`);
  }
  if (criteria.email) {
    queryList.push(`email=${criteria.email}`);
  }
  if (criteria.roles && criteria.roles.length > 0) {
    queryList.push('roles=' + criteria.roles.join(','));
  }
  if (criteria.visibilities && criteria.visibilities.length > 0) {
    queryList.push('visibilities=' + criteria.visibilities.join(','));
  }

  const res = await apiInstance.get<SearchUserResponse>(
    '/users?' + queryList.join('&'),
    {
      cancelToken,
    },
  );

  return res.data;
}

/**
 * Get user by Id
 */
async function getUser(id: string): Promise<UserResponse> {
  const res = await apiInstance.get<UserResponse>(`/users/${id}`);

  return res.data;
}

/**
 * Update user
 * @param userId user id
 * @param data data to be updated
 */
async function updateUser(
  userId: string,
  data: UpdateUserRequest,
): Promise<UserResponse> {
  const res = await apiInstance.put<UserResponse>(`/users/${userId}`, data);

  return res.data;
}

/**
 * Save user
 * @param data data to be saved
 */
async function saveUser(data: UpdateUserRequest): Promise<UserResponse> {
  const res = await apiInstance.post<UserResponse>(`/users`, data);

  return res.data;
}

/**
 * Delete user
 * @param userId user id
 */
async function deleteUser(userId: string): Promise<void> {
  await apiInstance.delete(`/users/${userId}`);
}

/**
 * Save bulk users
 * @param data data to be saved
 */
async function saveBulkUsers(
  data: SaveBulkUsersRequest,
): Promise<UserResponse[]> {
  const res = await apiInstance.post<UserResponse[]>(`/users/bulk/save`, data);

  return res.data;
}

/**
 * Activate user
 * @param userId user id
 */
async function activateUser(userId: string): Promise<UserResponse> {
  const res = await apiInstance.put<UserResponse>(`/users/${userId}/activate`);

  return res.data;
}

/**
 * Deactivate user
 * @param userId user id
 */
async function deactivateUser(userId: string): Promise<UserResponse> {
  const res = await apiInstance.put<UserResponse>(
    `/users/${userId}/deactivate`,
  );

  return res.data;
}

/**
 * Download bulk template
 */
async function downloadBulkTemplate(): Promise<UserResponse> {
  const res = await apiInstance.get<any>(`/users/bulkTemplate/download`, {
    responseType: 'blob',
  });

  if (res.status === 200) {
    const filename =
      res.headers['content-disposition']?.split('filename=')[1] ||
      'approval-document.pdf';
    saveAs(
      res.data,
      decodeURIComponent(filename.replace('"', '').replace('"', '')),
    );
  }

  return res.data;
}

/**
 * Send reset password mail.
 * @param email user email
 */
async function sendResetPasswordMail(email: string): Promise<void> {
  await apiInstance.post('/users/sendResetPasswordMail', {
    email,
  });
}

/**
 * Reset user password.
 * @param token the token
 * @param password the password
 */
async function resetPassword(token: string, password: string): Promise<void> {
  await apiInstance.post('/users/resetPassword', {
    token,
    password,
  });
}

/**
 * Send help email
 * @param emailData the data
 */
async function helpEmail(emailData: HelpEmail): Promise<void> {
  await apiInstance.post('/users/help', emailData);
}

/**
 * Request an account
 * @param account the account
 */
async function requestAccount(account: RequestAccount): Promise<void> {
  await apiInstance.post('/users/requestAccount', account);
}

/**
 * Request an internal account
 * @param account the account
 */
async function requestInternalAccount(
  account: RequestInternalAccount,
): Promise<void> {
  await apiInstance.post('/users/requestInternalUserAccount', account);
}

/**
 * Update user self
 * @param data data to be updated
 */
async function updateUserSelf(data: UpdateUserRequest): Promise<UserResponse> {
  const res = await apiInstance.put<UserResponse>('/users/me', data);

  return res.data;
}

/**
 * Change password
 * @param currentPassword the current password
 * @param password the password
 */
async function changePassword(
  currentPassword: string,
  password: string,
): Promise<void> {
  await apiInstance.put<UserResponse>('/users/changePassword', {
    currentPassword,
    password,
  });
}

/**
 * Unregister
 * @param currentPassword the current password
 * @param reason the reason
 */
async function unregister(reason: string): Promise<void> {
  await apiInstance.put<UserResponse>('/users/unregister', {
    unregisterReason: reason,
  });
}

export {
  searchUsers,
  getUser,
  updateUser,
  saveUser,
  deleteUser,
  saveBulkUsers,
  activateUser,
  deactivateUser,
  downloadBulkTemplate,
  sendResetPasswordMail,
  resetPassword,
  helpEmail,
  requestAccount,
  requestInternalAccount,
  updateUserSelf,
  changePassword,
  unregister,
};
