import { useIntl } from 'react-intl';

import FormWrapper from '../../FormWrapper/FormWrapper';
import TextareaRow from '../../TextareaRow/TextareaRow';

import { PortAddFormProps } from '../../../../models/admin-form-types';

import '../../shared.scss';

const PortNotesAndChargesForm = ({
  formValue,
  handleValueChange,
}: PortAddFormProps) => {
  const intl = useIntl();

  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });
  const title = getTranslatedMessage('port-notes-and-charges-label');
  const placeholder = getTranslatedMessage('input-placeholder');
  return (
    <FormWrapper title={title}>
      <div className="columnRow">
        <TextareaRow
          labelId="notes-label"
          value={formValue.notes}
          onChange={(value: string) => handleValueChange('notes', value)}
          placeholder={placeholder}
          required={false}
          maxLength={500}
          tooltip={getTranslatedMessage('notes-tooltip')}
        />
        <TextareaRow
          labelId="charges-label"
          value={formValue.charges}
          onChange={(value: string) => handleValueChange('charges', value)}
          placeholder={placeholder}
          required={false}
          maxLength={300}
          tooltip={getTranslatedMessage('charges-tooltip')}
        />
      </div>
    </FormWrapper>
  );
};

export default PortNotesAndChargesForm;
