import { FormattedMessage, useIntl } from 'react-intl';
import { PropsWithoutRef } from 'react';
import { SearchResultRange } from '../../../models/search-result-range';
import ProductChildrenTable from '../../common/ProductListItem/ProductChildrenTable/ProductChildrenTable';
import styles from './RangeDetailProducts.module.scss';
import cn from 'classnames';
import { TableColumn } from '../../../models/table-column';
import { SearchResultProduct } from '../../../models/search-result-product';
import { ReactComponent as ApprovalGreen } from '../../../icons/approval-green.svg';
import Notice from '../../common/Notice/Notice';

interface RangeDetailProductsProps {
  range: SearchResultRange;
  className?: string;
  isLoggedIn?: boolean;
}

/**
 * range detail products section
 * @param range range detail
 * @param className classname
 * @param isLoggedIn is logged in
 */
function RangeDetailProducts({
  range,
  className,
  isLoggedIn,
}: PropsWithoutRef<RangeDetailProductsProps>) {
  const intl = useIntl();
  const width = isLoggedIn
    ? `${(100 - 7.47185261003071) / 3}%`
    : `${(100 - 7.47185261003071) / 2}%`;

  // product children columns
  const columns: TableColumn<SearchResultProduct>[] = [
    {
      key: 'name',
      property: 'name',
      label: intl.formatMessage({ id: 'product-name-label' }),
      width,
      to: (data) => `/product/${data.friendlyUrl}`,
    },
    {
      key: 'viscosity',
      property: 'productCharacteristic.viscosityName',
      label: intl.formatMessage({ id: 'viscosity-label' }),
      width,
    },
  ];

  if (isLoggedIn) {
    columns.push({
      key: 'oemProductApprovalCount',
      property: 'oemProductApprovalCount',
      label: intl.formatMessage({ id: 'oem-approval-label' }),
      width,
      render: (data) => {
        return data.oemProductApprovalCount > 0 && <ApprovalGreen />;
      },
    });
  }

  return (
    <div className={cn(styles.container, className)}>
      {(range.productChildren || []).length > 0 ? (
        <>
          <h3>
            <FormattedMessage id={'products-label'} />
          </h3>

          <ProductChildrenTable
            columns={columns}
            className={styles.tableContainer}
            background={'light'}
            products={range.productChildren || []}
          />
        </>
      ) : (
        <Notice
          header={intl.formatMessage({ id: 'range-products-empty-message-1' })}
          type="not-available"
          showIcon={true}
        />
      )}
    </div>
  );
}

export default RangeDetailProducts;
