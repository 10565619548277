import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import _ from 'lodash';

import PortDetailsForm, {
  floatValidator,
} from '../../../components/Admin/ManagePortContent/PortDetailsForm/PortDetailsForm';
import PortNotesAndChargesForm from '../../../components/Admin/ManagePortContent/PortNotesAndChargesForm/PortNotesAndChargesForm';
import PortOrderQuantitiesForm from '../../../components/Admin/ManagePortContent/PortOrderQuantitiesForm/PortOrderQuantitiesForm';
import GenericManageSkeleton from '../../../components/Admin/GenericManageSkeleton/GenericManageSkeleton';
import Header from '../../../components/Admin/Header/Header';

import { addToast } from '../../../features/toastSlice';
import {
  PortDetailsFormType,
  StepperItemType,
  StockpointType,
  ValueOf,
} from '../../../models/admin-form-types';
import { PreviousLink } from '../../../models/previous-link';
import { CountryItem } from '../../../models/lookups';
import { PortDetails } from '../../../models/port-details';

import { getStockpoints } from '../../../services/StockpointService';
import {
  addPort,
  editPort,
  getPortDetails,
} from '../../../services/PortService';

import { RootStore } from '../../../store';

import { hideLoading, showLoading } from '../../../features/loadingSlice';
import { showGenericModal } from '../../../features/genericModalSlice';

import {
  convertStringToNumber,
  generateFriendlyUrl,
  replaceValues,
  valueGreaterThanZero,
} from '../../../utils/common.util';

import styles from './ManagePort.module.scss';
import withAdmin from '../../../hoc/withAdmin';
import { getErrorMessage } from '../../../utils/string.util';

const breadcrumbLinks: PreviousLink[] = [
  {
    name: 'Home',
    to: '/home',
  },
];

const initialState: PortDetailsFormType = {
  stockpoint: {} as StockpointType,
  name: '',
  country: {} as CountryItem,
  latitude: '',
  longitude: '',
  gmtOffsetInHours: '',
  friendlyUrl: '',
  workingDaysNotice: '',
  minPackOrderInDrums: null,
  minPackOrderInDrumsPumped: null,
  minBulkBargeOrderInLitres: null,
  minBulkIbcOrderInLitres: null,
  minBulkTruckOrderInLitres: null,
  workingOnMonday: false,
  workingOnTuesday: false,
  workingOnWednesday: false,
  workingOnThursday: false,
  workingOnFriday: false,
  workingOnSaturday: false,
  workingOnSunday: false,
  notes: '',
  charges: '',
};

const ManagePort = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { friendlyUrl } = useParams();
  const { countries } = useSelector((state: RootStore) => state.lookups);
  const [step, setStep] = useState(0);
  const [stockpoints, setStockpoints] = useState([]);
  const [initState, setInitState] = useState(initialState);
  const [formValue, setFormValue] = useState(initialState);
  const bulkAndPackFieldKeys = [
    'minBulkBargeOrderInLitres',
    'minBulkIbcOrderInLitres',
    'minBulkTruckOrderInLitres',
    'minPackOrderInDrums',
    'minPackOrderInDrumsPumped',
  ];

  const resetStates = () => {
    setFormValue(initialState);
    setInitState(initialState);
    setStep(0);
  };

  useEffect(() => {
    if (!friendlyUrl) {
      resetStates();
    }
  }, [friendlyUrl]);

  /* istanbul ignore next */
  const fetchPortDetails = (stockpoints: StockpointType[]) => {
    return new Promise((resolve, reject) => {
      if (friendlyUrl) {
        getPortDetails(friendlyUrl as string)
          .then((data) => {
            const {
              port: { stockPointId },
            } = data;
            const stockpoint = stockpoints.find(
              (stockpoint: StockpointType) => stockpoint.id === stockPointId,
            );
            let tempFormValue = _.cloneDeep(data.port);
            tempFormValue = replaceValues(
              tempFormValue,
              bulkAndPackFieldKeys,
              null,
              -1,
            );
            const formVal = {
              ...formValue,
              ..._.omit(tempFormValue, ['active', 'countryId']),
              country: {} as CountryItem,
              stockpoint: stockpoint || ({} as StockpointType),
            };
            setFormValue(formVal);
            setInitState(formVal);
            resolve(true);
          })
          .catch(() => reject());
      } else {
        resolve(true);
      }
    });
  };

  useEffect(() => {
    dispatch(showLoading());
    getStockpoints()
      .then(async (data) => {
        setStockpoints(data);
        await fetchPortDetails(data);
      })
      .finally(() => {
        dispatch(hideLoading());
      });
  }, []);

  useEffect(() => {
    if (formValue.name) {
      const friendlyUrl = generateFriendlyUrl(formValue.name);
      setFormValue({
        ...formValue,
        friendlyUrl,
      });
    }
  }, [formValue.name]);

  useEffect(() => {
    if (formValue.stockpoint.id) {
      const countryId = formValue.stockpoint.countryId;
      const country = countries.find((countr) => countr.id === countryId);
      setFormValue({
        ...formValue,
        country: country || ({} as CountryItem),
      });
    }
  }, [formValue.stockpoint.id]);

  const handleValueChange = (
    fieldKey: string,
    value: ValueOf<PortDetailsFormType>,
  ) => {
    setFormValue({
      ...formValue,
      [fieldKey]: value,
    });
  };

  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });
  /* istanbul ignore next */
  const stepperItems: StepperItemType = [
    {
      label: getTranslatedMessage('port-details-label'),
      componentToRender: (
        <PortDetailsForm
          stockpoints={stockpoints}
          formValue={formValue}
          handleValueChange={handleValueChange}
        />
      ),
    },
    {
      label: getTranslatedMessage('port-order-quantities-label'),
      componentToRender: (
        <PortOrderQuantitiesForm
          formValue={formValue}
          handleValueChange={handleValueChange}
        />
      ),
    },
    {
      label: getTranslatedMessage('port-notes-and-charges-label'),
      componentToRender: (
        <PortNotesAndChargesForm
          formValue={formValue}
          handleValueChange={handleValueChange}
        />
      ),
    },
  ];

  const workingDaysInCountryNotSelected = () => {
    const {
      workingOnMonday,
      workingOnTuesday,
      workingOnWednesday,
      workingOnThursday,
      workingOnFriday,
      workingOnSaturday,
      workingOnSunday,
    } = formValue;
    return (
      !workingOnMonday &&
      !workingOnTuesday &&
      !workingOnWednesday &&
      !workingOnThursday &&
      !workingOnFriday &&
      !workingOnSaturday &&
      !workingOnSunday
    );
  };

  const saveDisabled = () => {
    switch (step) {
      case 0: {
        const {
          stockpoint,
          name,
          latitude,
          longitude,
          gmtOffsetInHours,
          friendlyUrl,
          workingDaysNotice,
        } = formValue;
        return (
          !stockpoint.name ||
          !name ||
          !latitude ||
          floatValidator(String(latitude), -90, 90) ||
          !longitude ||
          floatValidator(String(longitude), -180, 180) ||
          (!gmtOffsetInHours && gmtOffsetInHours !== 0) ||
          floatValidator(String(gmtOffsetInHours), -11, 12) ||
          !friendlyUrl ||
          !workingDaysNotice ||
          workingDaysInCountryNotSelected()
        );
      }
      case 1: {
        const {
          minBulkBargeOrderInLitres,
          minBulkTruckOrderInLitres,
          minBulkIbcOrderInLitres,
          minPackOrderInDrums,
          minPackOrderInDrumsPumped,
        } = formValue;
        const bulkValue =
          valueGreaterThanZero(minBulkBargeOrderInLitres) ||
          valueGreaterThanZero(minBulkTruckOrderInLitres) ||
          valueGreaterThanZero(minBulkIbcOrderInLitres);
        const packValue =
          valueGreaterThanZero(minPackOrderInDrums) ||
          valueGreaterThanZero(minPackOrderInDrumsPumped);
        return !(bulkValue || packValue);
      }
    }

    return false;
  };

  /* istanbul ignore next */
  const handleSaveBtnClick = () => {
    if (step === Object.keys(stepperItems).length - 1) {
      let tempValue: any = _.cloneDeep(formValue);
      tempValue.countryId = tempValue.country.id;
      tempValue.stockPointId = tempValue.stockpoint.id;
      tempValue = _.omit(tempValue, ['country', 'stockpoint']);
      tempValue = replaceValues(tempValue, bulkAndPackFieldKeys, -1);
      tempValue = convertStringToNumber(tempValue, [
        ...bulkAndPackFieldKeys,
        'gmtOffsetInHours',
      ]);
      let res;
      let toastTitleId: string;
      let titleId: string;
      let messageId: string;
      if (friendlyUrl) {
        res = editPort(tempValue);
        toastTitleId = 'port-update-failure';
        titleId = 'edit-port-success-label';
        messageId = 'port-update-success-message';
      } else {
        res = addPort(tempValue);
        toastTitleId = 'port-create-failure';
        titleId = 'add-port-success-label';
        messageId = 'port-create-success-message';
      }
      dispatch(showLoading());
      res
        .then((data: PortDetails) => {
          dispatch(
            showGenericModal({
              titleId,
              messageId,
              to: `/port/${data.friendlyUrl}`,
            }),
          );
        })
        .catch((err) => {
          dispatch(
            addToast({
              type: 'error',
              title: intl.formatMessage({ id: toastTitleId }),
              message: getErrorMessage(err),
            }),
          );
        })
        .finally(() => dispatch(hideLoading()));
      return;
    }

    setStep(step + 1);
  };

  const getTitleId = () => {
    if (friendlyUrl) {
      return 'header-menu-manage-ports-edit-port';
    }

    return 'header-menu-manage-ports-add-port';
  };

  const getBreadcrumbLinks = () => {
    if (friendlyUrl) {
      return [
        ...breadcrumbLinks,
        {
          name: 'Edit Port',
        },
      ];
    }

    return [
      ...breadcrumbLinks,
      {
        name: 'Add Port',
      },
    ];
  };

  const shouldShowUnsavedChangesModal = () =>
    !_.isEqual(_.omit(initState, 'country'), _.omit(formValue, ['country']));

  return (
    <div className={styles.container}>
      <Header titleId={getTitleId()} breadcrumbLinks={getBreadcrumbLinks()} />
      <GenericManageSkeleton
        isEdit={!!friendlyUrl}
        step={step}
        stepperItems={stepperItems}
        saveBtnDisabled={saveDisabled()}
        showUnsavedChangesModal={shouldShowUnsavedChangesModal()}
        onBackBtnClick={() => {
          if (step > 0) {
            setStep(step - 1);
          }
        }}
        onSaveBtnClick={() => handleSaveBtnClick()}
      >
        {stepperItems[step].componentToRender}
      </GenericManageSkeleton>
    </div>
  );
};

export default withAdmin(ManagePort);
