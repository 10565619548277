import styles from './SearchModal.module.scss';
import Modal from '../../common/Modal/Modal';
import { PropsWithoutRef, useState } from 'react';
import SearchModalInput from './SearchModalInput/SearchModalInput';
import SearchModalBrowser from './SearchModalBrowser/SearchModalBrowser';
import SearchSummaryResult from './SearchSummaryResult/SearchSummaryResult';
import axios, { CancelTokenSource } from 'axios';
import { getSearchResultsSummary } from '../../../services/ProductService';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { SearchResultSummary } from '../../../models/search-result-summary';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import { mapSearchSummaryResponseWithLookups } from '../../../utils/lookup.util';
import { openContactUsModal } from '../../../features/contactUsSlice';

interface SearchModalProps {
  onClose: () => void;
}

/**
 * search modal for home page
 * @param onClose close handler
 */
function SearchModal({ onClose }: PropsWithoutRef<SearchModalProps>) {
  // defines hooks
  const dispatch = useDispatch();

  // define states.
  const [search, setSearch] = useState('');
  const [timer, setTimer] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [previousSource, setPreviousSource] = useState<CancelTokenSource>();
  const [searchResult, setSearchResult] = useState<SearchResultSummary>();
  const lookups = useSelector((state: RootStore) => state.lookups);

  /**
   * get search result
   * @param search search string
   */
  /* istanbul ignore next */
  const getSearchResult = (search: string) => {
    if (previousSource) {
      previousSource.cancel();
    }

    const source = axios.CancelToken.source();

    setLoading(true);
    setPreviousSource(source);

    getSearchResultsSummary(search, source.token)
      .then((res) => {
        setSearchResult(mapSearchSummaryResponseWithLookups(res, lookups));
      })
      .catch(() => null)
      .finally(() => setLoading(false));
  };

  /**
   * search change handler
   * @param value changed value
   */
  const onSearchChange = (value: string) => {
    if (timer) {
      clearTimeout(timer);
    }

    if (!value) {
      setSearch(value);
    } else {
      const t = setTimeout(() => {
        setSearch(value);
        getSearchResult(value);
      }, 500);

      setTimer(t);
    }
  };

  /**
   * Handle `Let us know` link click
   */
  const onLetUsKnowClick = () => {
    onClose();
    dispatch(openContactUsModal({}));
  };

  return (
    <Modal onClose={onClose}>
      <div className={styles.container}>
        <SearchModalInput onChange={(value) => onSearchChange(value)} />

        {search ? (
          <SearchSummaryResult
            result={searchResult}
            loading={loading}
            search={search}
            onClose={onClose}
          />
        ) : (
          <SearchModalBrowser
            closeModal={() => onClose()}
            className={styles.browserContainer}
          />
        )}
      </div>

      <div className={styles.infoMessage}>
        <div>
          <FormattedMessage id={'search-modal-info-message'} />
          &nbsp;
          <Link to={'#'} onClick={onLetUsKnowClick}>
            <FormattedMessage id={'search-modal-info-message-link-label'} />
          </Link>
        </div>
      </div>
    </Modal>
  );
}

export default SearchModal;
