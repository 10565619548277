import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import _, { compact } from 'lodash';

import Button from '../../../common/Button/Button';
import Modal from '../../../common/Modal/Modal';
import CheckboxList from '../../CheckboxList/CheckboxList';
import SelectFormRow from '../../SelectFormRow/SelectFormRow';

import {
  StockpointProductType,
  StockpointType,
} from '../../../../models/admin-form-types';

import styles from './AddProductModal.module.scss';
import { TabItem } from '../../../../models/tab-item';
import Tabs from '../../../common/Tabs/Tabs';

type AddProductModalProps = {
  products: any;
  stockpoints: StockpointType[];
  onSave: (newProduct: StockpointProductType) => void;
  onCopy: (newProduct: StockpointType) => void;
  onClose: () => void;
};

const AddProductModal = ({
  products,
  stockpoints,
  onSave,
  onCopy,
  onClose,
}: AddProductModalProps) => {
  const intl = useIntl();

  const tabs: TabItem[] = compact([
    {
      name: intl.formatMessage({ id: 'add-new-label' }),
      key: 'new',
    },
    {
      name: intl.formatMessage({ id: 'stockpoint-copy-label' }),
      key: 'copy',
    },
  ]);
  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });
  const itemIds = [
    {
      fieldKey: 'offerBulkBadge',
      label: getTranslatedMessage('barge-label'),
    },
    {
      fieldKey: 'offerBulkTruck',
      label: getTranslatedMessage('truck-label'),
    },
    {
      fieldKey: 'offerBulkIbc',
      label: getTranslatedMessage('port-availability-ibc-label'),
    },
    {
      fieldKey: 'offerPackDrum',
      label: getTranslatedMessage('port-availability-drum-label'),
    },
    {
      fieldKey: 'offerPackPail',
      label: getTranslatedMessage('port-availability-pail-label'),
    },
  ];
  const [selectedProduct, setSelectedProduct] = useState(
    {} as StockpointProductType,
  );
  const [selectedStockpoint, setSelectedStockpoint] = useState(
    {} as StockpointType,
  );

  const [tab, setTab] = useState('new');
  const [product, setProduct] = useState({
    name: '',
    offerBulkBadge: false,
    offerBulkIbc: false,
    offerBulkTruck: false,
    offerPackDrum: false,
    offerPackPail: false,
  } as StockpointProductType);
  const items = itemIds.map(({ fieldKey, label }) => ({
    fieldKey,
    label,
    checked: !!product[fieldKey as keyof StockpointProductType],
  }));

  const handleSaveBtnClick = () => {
    if (tab === 'new') {
      onSave({
        ...product,
        id: products.length + 1,
        name: selectedProduct.name,
        productId: selectedProduct.id,
      });
    } else {
      onCopy(selectedStockpoint);
    }
    onClose();
  };

  return (
    <Modal onClose={onClose} className={styles.addProductModalContainer}>
      <div className={styles.container}>
        <h1>
          <FormattedMessage id="add-new-product-to-stockpoint-label" />
        </h1>
        <div className={styles.modalContent}>
          <Tabs
            className={styles.tabContainer}
            tabContentClassName={styles.tabContent}
            tabItemClassName={styles.tabItemClassName}
            tabItemSelectedClassName={styles.tabItemSelectedClassName}
            selected={tab}
            tabs={tabs}
            onChange={(item) => {
              setSelectedStockpoint({} as StockpointType);
              setSelectedProduct({} as StockpointProductType);
              setTab(item.key);
            }}
          >
            {tab === 'new' && (
              <>
                <SelectFormRow
                  labelId="product-name-label"
                  value={selectedProduct}
                  options={products.map((product: any) => ({
                    name: product.name,
                    value: product,
                  }))}
                  onChange={(value) => setSelectedProduct(value)}
                  tooltip={getTranslatedMessage('add-new-product-name-tooltip')}
                />
                <CheckboxList
                  labelId="physical-offer-label"
                  items={items}
                  onClick={(fieldKey: string) => {
                    const tempProduct: any = _.cloneDeep(product);
                    tempProduct[fieldKey] = !tempProduct[fieldKey];
                    setProduct({
                      ...product,
                      ...tempProduct,
                    });
                  }}
                  required={false}
                  className={styles.physicalOfferContainer}
                />
              </>
            )}
            {tab === 'copy' && (
              <>
                <SelectFormRow
                  labelId="choose-stockpoint-label"
                  value={selectedStockpoint}
                  options={stockpoints.map((stockpoint: StockpointType) => ({
                    name: stockpoint.name,
                    value: stockpoint,
                  }))}
                  onChange={(value) => setSelectedStockpoint(value)}
                  tooltip={getTranslatedMessage('copy-stockpoint-tooltip')}
                />
              </>
            )}
          </Tabs>
        </div>
        <div className={styles.actionBtnContainer}>
          <Button color="nobel" onClick={() => onClose()}>
            <FormattedMessage id="cancel-label" />
          </Button>
          <Button
            color="green"
            disabled={!selectedProduct.id && !selectedStockpoint.id}
            onClick={() => handleSaveBtnClick()}
          >
            <FormattedMessage id="save-label" />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AddProductModal;
