import classNames from 'classnames';
import Radio from '../Radio/Radio';
import FormRow from '../FormRow/FormRow';

import { FormRowType } from '../../../models/admin-form-types';
import styles from './RadioGroup.module.scss';

type ItemType = {
  label: string;
  value: any;
};

type RadioGroupType = FormRowType & {
  items: ItemType[];
  selected?: any;
  onClick: (fieldKey: any) => void;
  className?: string;
  disabled?: boolean;
};

const RadioGroup = ({
  labelId,
  required,
  items,
  selected,
  onClick,
  tooltip,
  className,
  disabled = false,
}: RadioGroupType) => {
  return (
    <div className={classNames(styles.radioGroupWrapper, className)}>
      <FormRow labelId={labelId} required={required} tooltip={tooltip}>
        <div className={styles.optionsContainer}>
          {items.map((d: ItemType, index: number) => (
            <div
              key={index}
              className={styles.itemLabelWrapper}
              onClick={() => (disabled ? null : onClick(d.value))}
            >
              <Radio
                disabled={disabled}
                checked={selected === d.value}
                onClick={() => (disabled ? null : onClick(d.value))}
              />
              <span
                className={styles.label}
                onClick={() => (disabled ? null : onClick(d.value))}
              >
                {d.label}
              </span>
            </div>
          ))}
        </div>
      </FormRow>
    </div>
  );
};

export default RadioGroup;
