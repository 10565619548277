import { isDate } from './validation.util';

/**
 * prefix zero for number to make it two digital
 * @param value number value
 */
export function _prefixZero(value: number): string {
  if (value < 10) {
    return '0' + value;
  }

  return '' + value;
}

/**
 * transfer date string to UI format string
 * @param value the date string
 */
export function getDateString(value: string | undefined): string {
  if (!value || !isDate(value)) {
    return '';
  }

  const date = value.substring(0, 10).split('-');
  return date[1] + '/' + date[2] + '/' + date[0];
}

/**
 * transfer date string to UI format string
 * @param value the date string
 */
export function getDateFormatString(value: Date | null): string {
  if (!value) {
    return '';
  }

  return (
    value.getFullYear() +
    '-' +
    _prefixZero(value.getMonth() + 1) +
    '-' +
    _prefixZero(value.getDate())
  );
}

/**
 * transfer time string to UI format string
 * @param value the time string
 */
export function getDateTimeString(value: string | undefined): string {
  if (!value || !isDate(value)) {
    return '';
  }

  const date = value.substring(0, 10).split('-');
  const time = value.substring(11, 19);
  return date[1] + '/' + date[2] + '/' + date[0] + ' ' + time;
}
