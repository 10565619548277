import { FormattedMessage, useIntl } from 'react-intl';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { debounce } from 'lodash';

import styles from './LinkListPage.module.scss';
import { PreviousLink } from '../../../models/previous-link';
import NavigationHeader from '../../../components/common/NavigationHeader/NavigationHeader';
import ContainerForDrawer from '../../../components/common/ContainerForDrawer/ContainerForDrawer';
import LeftDrawer from '../../../components/common/LeftDrawer/LeftDrawer';
import RightContainer from '../../../components/common/RightContainer/RightContainer';
import SearchFilter from '../../../components/common/SearchFilter/SearchFilter';
import Input from '../../../components/common/Input/Input';
import FilterContainer from '../../../components/common/FilterContainer/FilterContainer';
import Select from '../../../components/common/Select/Select';
import NoResult from '../../../components/Admin/NoResult/NoResult';
import LinkTable from './components/LinkTable/LinkTable';
import { RootStore } from '../../../store';

import { ReactComponent as PlusIcon } from '../../../icons/plus.svg';
import { deleteLink, searchLinks } from '../../../services/LinkService';
import { addToast } from '../../../features/toastSlice';
import { hideLoading, showLoading } from '../../../features/loadingSlice';
import { LinkResponse } from '../../../models/link-response';

export default function LinkPage() {
  const intl = useIntl();
  const dispatch = useDispatch();
  // pagination
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  // sorting
  const [sortBy, setSortBy] = useState('name');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  // filters
  const [filterName, setFilterName] = useState('');
  const [filterSector, setFilterSector] = useState(0);
  const [links, setLinks] = useState<LinkResponse[]>([]);
  const [reload, setReload] = useState(0);

  const { sectors } = useSelector((state: RootStore) => state.lookups);

  useEffect(() => {
    dispatch(showLoading());
    searchLinks({
      page,
      perPage,
      sortBy,
      sortDirection,
      name: filterName,
      sectorId: filterSector || undefined,
    })
      .then((res) => {
        setLinks(res.items);
        setTotal(res.total);
      })
      .catch(() => {
        dispatch(
          addToast({
            type: 'error',
            title: intl.formatMessage({ id: 'links-load-failure' }),
            message: '',
          }),
        );
      })
      .finally(() => dispatch(hideLoading()));
  }, [page, perPage, sortBy, sortDirection, filterName, filterSector, reload]);

  const allOption = useMemo(
    () => ({
      name: intl.formatMessage({ id: 'all-label' }),
      value: 0,
    }),
    [],
  );

  const sectorOptions = useMemo(() => {
    const sectorsTemp = sectors.map((item) => ({
      name: item.name,
      value: item.id,
    }));
    sectorsTemp.unshift(allOption);
    return sectorsTemp;
  }, [sectors]);

  const breadcrumbLinks: PreviousLink[] = useMemo(
    () => [
      {
        name: intl.formatMessage({ id: 'breadcrumb-home' }),
        to: '/home',
      },
      {
        name: intl.formatMessage({ id: 'header-menu-admin-manage-links' }),
      },
    ],
    [intl],
  );

  const onSortChange = (column: string) => {
    if (column !== sortBy) {
      setSortBy(column);
      setSortDirection('asc');
      return;
    }
    setSortDirection((currDirection) =>
      currDirection === 'desc' ? 'asc' : 'desc',
    );
  };

  const removeLink = (id: number) => {
    dispatch(showLoading());
    deleteLink(id)
      .then(() => {
        setReload((prev) => prev + 1);
        dispatch(
          addToast({
            type: 'success',
            title: intl.formatMessage({ id: 'link-delete-success' }),
            message: '',
          }),
        );
      })
      .catch(() => {
        dispatch(
          addToast({
            type: 'error',
            title: intl.formatMessage({ id: 'link-delete-failure' }),
            message: '',
          }),
        );
      })
      .finally(() => dispatch(hideLoading()));
  };

  return (
    <>
      <NavigationHeader
        breadcrumbLinks={breadcrumbLinks}
        showBottomLine={true}
      />
      <ContainerForDrawer classes={styles.filterClose}>
        <LeftDrawer>
          <SearchFilter>
            <FilterContainer
              title={intl.formatMessage({ id: 'name-label' })}
              className={styles.filterGroup}
            >
              <Input
                value={filterName}
                onChange={debounce(setFilterName, 400)}
                placeholder={intl.formatMessage({
                  id: 'search-name-placeholder',
                })}
              />
            </FilterContainer>

            <FilterContainer
              title={intl.formatMessage({ id: 'sector-label' })}
              className={styles.filterGroup}
            >
              <Select
                required={false}
                className={styles.formSelectField}
                value={filterSector}
                options={sectorOptions}
                onChange={setFilterSector}
                placeholder={''}
              />
            </FilterContainer>
          </SearchFilter>
        </LeftDrawer>
        <RightContainer>
          <div className={styles.pageHeader}>
            <h1 className={styles.title}>
              <FormattedMessage id={'manage-link-title'} />
            </h1>
            <Link className={styles.link} to={`/admin/links/add`}>
              <PlusIcon />
              <FormattedMessage id={'create-new-link'} />
            </Link>
          </div>
          {links.length > 0 ? (
            <>
              <LinkTable
                page={page}
                perPage={perPage}
                onPageChange={setPage}
                onPerPageChange={(val: number) => {
                  setPerPage(val);
                  setPage(1);
                }}
                total={total}
                links={links}
                sortBy={sortBy}
                sortDirection={sortDirection}
                onSortChange={onSortChange}
                deleteLink={removeLink}
              />
            </>
          ) : (
            <NoResult
              titleId={'no-links-available'}
              messageId={'no-search-result-message'}
            />
          )}
        </RightContainer>
      </ContainerForDrawer>
    </>
  );
}
