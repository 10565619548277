import { useParams } from 'react-router';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { getPortDetails, getPortsByCountry } from '../../services/PortService';
import { PreviousLink } from '../../models/previous-link';
import NavigationHeader from '../../components/common/NavigationHeader/NavigationHeader';
import ContainerForDrawer from '../../components/common/ContainerForDrawer/ContainerForDrawer';
import LeftDrawer from '../../components/common/LeftDrawer/LeftDrawer';
import LoadingSpinner from '../../components/common/LoadingSpinner/LoadingSpinner';
import RightContainer from '../../components/common/RightContainer/RightContainer';
import styles from './PortDetailPage.module.scss';
import SearchModal from '../../components/HomePage/SearchModal/SearchModal';
import PortLeftDetail from '../../components/PortDetailPage/PortLeftDetail/PortLeftDetail';
import { PortDetails, PortResponse } from '../../models/port-details';
import AboutPort from '../../components/PortDetailPage/AboutPort/AboutPort';
import PortDetailHeader from '../../components/PortDetailPage/PortDetailHeader/PortDetailHeader';
import OrderAndPortInfo from '../../components/PortDetailPage/OrderAndPortInfo/OrderAndPortInfo';
import ProductAvailability from '../../components/PortDetailPage/ProductAvailability/ProductAvailability';
import PortDetailFooter from '../../components/PortDetailPage/PortDetailFooter/PortDetailFooter';
import _ from 'lodash';
import PortSectionNavigation from '../../components/PortDetailPage/PortSectionNavigation/PortSectionNavigation';
import PortStat from '../../components/PortDetailPage/PortStat/PortStat';
import { CountryItem } from '../../models/lookups';
import { getLookups } from '../../services/LookupService';
import axios from 'axios';
import { OrderContact } from '../../models/order-contacts';
import { getOrderContacts } from '../../services/OrderContactService';
import NearbyPortModal from '../../components/PortDetailPage/NearbyPortModal/NearbyPortModal';
import { RootStore } from '../../store';
import NotFound from '../Admin/NotFound/NotFound';
import { setPort, setStockPointId } from '../../features/portDetailsSlice';

/**
 * port detail page
 */
function PortDetailPage() {
  // init hooks.
  const dispatch = useDispatch();
  const { friendlyUrl } = useParams();

  // load data from store.
  const { hasWelcomeInfo, welcomeInfo } = useSelector(
    (state: RootStore) => state.welcomeInfo,
  );

  const { hasLogin } = useSelector((state: RootStore) => state.login);

  // init states.
  const [notFound, setNotFound] = useState(false);
  const [loading, setLoading] = useState(false);
  const [portData, setPortData] = useState<PortResponse>();
  const [countries, setCountries] = useState<CountryItem[]>([]);
  const [orderContacts, setOrderContacts] = useState<OrderContact[]>([]);
  const [searchOpened, setSearchOpened] = useState(false);
  const [nearbyOpened, setNearbyOpened] = useState(false);
  const [nearbyPorts, setNearbyPorts] = useState<PortDetails[]>([]);

  const refs = {
    '1': useRef(null),
    '2': useRef(null),
    '3': useRef(null),
  };

  const breadcrumbLinks: PreviousLink[] = [
    {
      name: 'Home',
      to: '/home',
    },
    {
      name: 'Port Results',
      to: `/port-results`,
    },
  ];

  breadcrumbLinks.push({
    name: portData?.port?.name || '',
  });

  const getNearbyPort = () => {
    if (nearbyPorts && nearbyPorts.length > 0) {
      setNearbyOpened(true);
    } else if (portData) {
      setLoading(true);
      getPortsByCountry(portData?.port.countryId)
        .then((res) => {
          const countryMap = new Map(countries.map((i) => [i.id, i.name]));
          res.ports.forEach((p) => {
            p.country = countryMap.get(p.countryId);
          });
          setNearbyPorts(res.ports);
          setNearbyOpened(true);
        })
        .finally(() => setLoading(false));
    }
  };

  // effects

  useEffect(() => {
    // only call when user info saved.
    if (hasWelcomeInfo) {
      setLoading(true);
      setNotFound(false);
      Promise.all([
        getPortDetails(friendlyUrl as string),
        getLookups(axios.CancelToken.source().token),
        getOrderContacts(),
      ])
        .then(
          (res) => {
            // fix bulk and pack show,
            // the issue is from https://gitlab.com/lubricant-oil/lubricant-frontend/-/issues/727
            const offerBarge = res[0].port.minBulkBargeOrderInLitres > 0;
            const offerTruck = res[0].port.minBulkTruckOrderInLitres > 0;
            const offerIBC = res[0].port.minBulkIbcOrderInLitres > 0;

            res[0].portProducts.forEach((portProduct) => {
              if (!offerBarge) {
                portProduct.offerBulkBadge = false;
              }
              if (!offerTruck) {
                portProduct.offerBulkTruck = false;
              }
              if (!offerIBC) {
                portProduct.offerBulkIbc = false;
              }
            });

            setPortData(res[0]);
            setCountries(res[1].countries);
            setOrderContacts(res[2]);
            dispatch(setStockPointId(res[0]?.port?.stockPointId));
            dispatch(setPort(res[0]?.port));
          },
          () => {
            setNotFound(true);
          },
        )
        .finally(() => setLoading(false));
    }
  }, [friendlyUrl, dispatch, hasWelcomeInfo, welcomeInfo.country]);

  return (
    <>
      {notFound && <NotFound />}
      {!notFound && (
        <div>
          <NavigationHeader
            onInputFocus={() => setSearchOpened(true)}
            breadcrumbLinks={breadcrumbLinks}
          />

          <ContainerForDrawer>
            <LeftDrawer className={styles.leftDrawer}>
              {loading ? (
                <LoadingSpinner className={styles.loading} />
              ) : (
                !!portData &&
                portData.port && (
                  <PortLeftDetail
                    port={portData.port}
                    onLinkCliked={(val) => {
                      const ref = _.get(refs, val.toString());
                      ref.current.scrollIntoView();
                    }}
                  />
                )
              )}
            </LeftDrawer>

            <RightContainer>
              {loading ? (
                <LoadingSpinner className={styles.loading} />
              ) : (
                portData?.port && (
                  <div>
                    <div
                      className={cn(
                        styles.navigationContainer,
                        !hasLogin && styles.notLogin,
                      )}
                    >
                      <PortSectionNavigation
                        onLinkCliked={(val) => {
                          const ref = _.get(refs, val.toString());
                          ref.current.scrollIntoView();
                        }}
                      />
                    </div>
                    <div className={styles.portDetailContainer}>
                      <PortDetailHeader
                        title={portData.port.name}
                        subTitle={''}
                        subTitle2={''}
                        description={''}
                      />

                      <AboutPort
                        clickNearby={() => getNearbyPort()}
                        port={portData.port}
                      />
                    </div>

                    <div className={styles.statContainer}>
                      <PortStat port={portData.port} />
                    </div>

                    <div className={styles.divider} ref={refs[1]} />

                    <OrderAndPortInfo port={portData.port} />

                    <div className={styles.divider} ref={refs[2]} />

                    <ProductAvailability
                      families={portData.families}
                      products={portData.portProducts.filter(
                        (x) => x.portProductExclusion === false,
                      )}
                    />

                    <div className={styles.divider} ref={refs[3]} />

                    <PortDetailFooter
                      contact={orderContacts.find(
                        (x) => x.countryId === welcomeInfo.country?.id,
                      )}
                      allContacts={orderContacts}
                      allCountries={countries}
                    />
                  </div>
                )
              )}
            </RightContainer>
          </ContainerForDrawer>

          {searchOpened && (
            <SearchModal onClose={() => setSearchOpened(false)} />
          )}
          {nearbyOpened && (
            <NearbyPortModal
              ports={nearbyPorts}
              onClose={() => setNearbyOpened(false)}
            />
          )}
        </div>
      )}
    </>
  );
}

export default PortDetailPage;
