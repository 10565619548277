import styles from './PortSectionNavigation.module.scss';
import IconField from '../../common/IconField/IconField';
import { useIntl } from 'react-intl';
import { PropsWithoutRef, useState } from 'react';
import { ReactComponent as Order } from '../../../icons/order.svg';
import { ReactComponent as Drop } from '../../../icons/drop.svg';
import { ReactComponent as Cart } from '../../../icons/cart.svg';
import { ReactComponent as Caret } from '../../../icons/arrow-down.svg';
import classNames from 'classnames';

interface PortSectionNavigationProps {
  onLinkCliked: (id: number) => void;
}

/**
 * port navigation
 * @param onLinkCliked navigation click event handler
 */
function PortSectionNavigation({
  onLinkCliked,
}: PropsWithoutRef<PortSectionNavigationProps>) {
  const intl = useIntl();
  const [expand, setExpand] = useState(false);

  return (
    <div className={styles.container}>
      <div
        className={classNames(styles.labelContainer)}
        onClick={() => setExpand(!expand)}
      >
        <div className={classNames(styles.item, styles.label)}>
          {intl.formatMessage({ id: 'port-jump-label' })}
        </div>
        <Caret className={expand ? styles.expanded : ''} />
      </div>
      <div
        className={classNames(
          styles.linkContainer,
          expand ? styles.expanded : '',
        )}
      >
        <IconField
          className={classNames(styles.item, styles.link)}
          icon={<Order />}
          label={intl.formatMessage({ id: 'order-and-port-info-label' })}
          value={''}
          onClick={() => {
            setExpand(false);
            onLinkCliked(1);
          }}
        />

        <IconField
          className={classNames(styles.item, styles.link)}
          icon={<Drop />}
          label={intl.formatMessage({ id: 'product-availability-label' })}
          value={''}
          onClick={() => {
            setExpand(false);
            onLinkCliked(2);
          }}
        />

        <IconField
          className={classNames(styles.item, styles.link)}
          icon={<Cart />}
          label={intl.formatMessage({ id: 'how-to-order-label' })}
          value={''}
          onClick={() => {
            setExpand(false);
            onLinkCliked(3);
          }}
        />
      </div>
    </div>
  );
}

export default PortSectionNavigation;
