import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as InfoIcon } from '../../../../../icons/info.svg';
import { ReactComponent as SortArrow } from '../../../../../icons/sort-arrow.svg';
import styles from './ManageBannerTable.module.scss';
import { TableColumn } from '../../../../../models/table-column';
import TableMobileView from '../../../TableMobileView/TableMobileView';
import classNames from 'classnames';
import Button from '../../../../../components/common/Button/Button';
import { ReactComponent as EditIcon } from '../../../../../icons/edit-white.svg';
import { ReactComponent as DeactiveIcon } from '../../../../../icons/deactive-white.svg';
import { Banner, Button as BannerButton } from '../../../../../models/banner';
import { ReactComponent as DocIcon } from '../../../../../icons/ic-doc-white.svg';
import { ReactComponent as DustbinIcon } from '../../../../../icons/dustbin.svg';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../../../store';
import Paginator, {
  PaginatorType,
} from '../../../../../components/common/Paginator/Paginator';
import BannerActionMenu from '../BannerActionMenu/BannerActionMenu';
import config from '../../../../../configs/config';
interface ManageBannerTableProps extends PaginatorType {
  banners: Banner[];
  sortBy: string;
  sortDirection: string;
  onSortChange: (column: string) => void;
  onActionMenuClick: (actionType: string, announcement: Banner) => void;
}

export default function ManageBannerTable({
  banners,
  page,
  perPage,
  total,
  onPageChange,
  onPerPageChange,
  sortBy,
  sortDirection,
  onSortChange,
  onActionMenuClick,
}: ManageBannerTableProps) {
  const intl = useIntl();
  const { sectorsById } = useSelector((state: RootStore) => state.lookups);

  const bannerTitleColumn = (title: string, active: boolean) => (
    <>
      <div className={styles.bannerColumnTitle}>{title}</div>
      <div
        className={classNames(
          styles.titleTag,
          active ? styles.activeTag : styles.inactiveTag,
        )}
      >
        {active ? (
          <FormattedMessage id={'active-label'} />
        ) : (
          <FormattedMessage id={'inactive-label'} />
        )}
      </div>
    </>
  );

  const imageColumn = (imageUrl: string) => (
    <img
      src={imageUrl}
      alt={intl.formatMessage({
        id: 'banner-image-alt',
      })}
      className={styles.bannerImage}
    />
  );

  const buttonColumn = (buttons: BannerButton[]) =>
    buttons.map((button, i: number) => (
      <div key={i}>
        <a
          className={styles.btnLink}
          href={button.value}
          target="_blank"
          rel="noopener noreferrer"
        >
          {button.name}
        </a>
      </div>
    ));

  const columns: TableColumn<any>[] = [
    {
      key: 'bannerTitle',
      label: intl.formatMessage({ id: 'banner-title-label' }),
      property: 'bannerTitle',
      render: (data) => bannerTitleColumn(data.name, data.active),
    },
    {
      key: 'imageUrl',
      label: intl.formatMessage({ id: 'image-label' }),
      property: 'imageUrl',
      render: (data) => imageColumn(data.bannerImageUrl),
    },
    {
      key: 'description',
      label: intl.formatMessage({ id: 'description-label' }),
      property: 'description',
      className: styles.descColumn,
    },
    {
      key: 'buttons',
      label: intl.formatMessage({ id: 'buttons-label' }),
      property: 'buttons',
      render: (data) => buttonColumn(data.buttons),
    },
    {
      key: 'promotions',
      label: intl.formatMessage({ id: 'sector-title' }),
      property: 'promotions',
      render: (data) => sectorsById[data.sectorId]?.name,
    },
  ];

  const mobileRowActions = (banner: Banner) => {
    return (
      <>
        <Button
          onClick={() => onActionMenuClick('edit-banner', banner)}
          className={styles.mobileActionBtn}
          color={'green'}
        >
          <EditIcon className={styles.actionIcon} />
          <FormattedMessage id={'edit-banner'} />
        </Button>
        {banner.active ? (
          <Button
            onClick={() => onActionMenuClick('deactivate-banner', banner)}
            className={styles.mobileActionBtn}
            color={'green'}
          >
            <DeactiveIcon className={styles.actionIcon} />
            <FormattedMessage id={'deactivate-banner'} />
          </Button>
        ) : (
          <Button
            onClick={() => onActionMenuClick('activate-banner', banner)}
            className={styles.mobileActionBtn}
            color={'green'}
          >
            <DocIcon className={styles.actionIcon} />
            <FormattedMessage id={'activate-banner'} />
          </Button>
        )}
        <Button
          onClick={() => onActionMenuClick('delete-banner', banner)}
          className={styles.mobileActionBtn}
          color={'red'}
        >
          <DustbinIcon className={styles.actionIcon} />
          <FormattedMessage id={'delete-banner'} />
        </Button>
      </>
    );
  };

  return (
    <div>
      <div className={styles.tableHeader}>
        <div className={styles.tableRow}>
          <div
            style={{ flex: '1 1 120px' }}
            onClick={() => onSortChange('name')}
            className={cn(
              styles.tableLabelColumn,
              styles.tableColumn,
              styles.sortableColumn,
            )}
          >
            <FormattedMessage id={'banner-title-label'} />
            {sortBy === 'name' && (
              <SortArrow
                className={cn(
                  styles.sortIcon,
                  sortDirection === 'asc' && styles.asc,
                )}
              />
            )}
          </div>
          <div
            style={{ flex: '1 1 90px' }}
            className={cn(styles.tableLabelColumn, styles.tableColumn)}
          >
            <FormattedMessage id={'image-label'} />
          </div>
          <div
            style={{ flex: '1 1 200px' }}
            onClick={() => onSortChange('description')}
            className={cn(
              styles.tableLabelColumn,
              styles.tableColumn,
              styles.sortableColumn,
            )}
          >
            <FormattedMessage id={'description-label'} />
            {sortBy === 'description' && (
              <SortArrow
                className={cn(
                  styles.sortIcon,
                  sortDirection === 'asc' && styles.asc,
                )}
              />
            )}
          </div>
          <div
            style={{ flex: '1 1 60px' }}
            className={cn(styles.tableLabelColumn, styles.tableColumn)}
          >
            <FormattedMessage id={'buttons-label'} />
          </div>
          <div
            style={{ flex: '1 1 60px' }}
            onClick={() => onSortChange('sector.name')}
            className={cn(
              styles.tableLabelColumn,
              styles.tableColumn,
              styles.columnWithIcon,
              styles.sortableColumn,
            )}
          >
            <FormattedMessage id={'sector-title'} />
            <InfoIcon data-tip data-for={'sector-title'} />
            <ReactTooltip id={'sector-title'} place={'top'} effect={'solid'}>
              <FormattedMessage id={'sector-title'} />
            </ReactTooltip>
            {sortBy === 'sector.name' && (
              <SortArrow
                className={cn(
                  styles.sortIcon,
                  sortDirection === 'asc' && styles.asc,
                )}
              />
            )}
          </div>
          <div
            style={{ flex: '0 0 90px' }}
            className={cn(styles.tableLabelColumn, styles.tableColumn)}
          >
            <FormattedMessage id={'actions-label'} />
          </div>
        </div>
      </div>

      <div className={cn(styles.tableBody, styles.desktop)}>
        {banners.map((banner) => (
          <div className={cn(styles.tableRow)} key={banner.id}>
            <div
              style={{ flex: '1 1 120px' }}
              className={cn(
                styles.tableColumn,
                styles.link,
                styles.bannerTitle,
              )}
            >
              {bannerTitleColumn(banner.name, banner.active)}
            </div>
            <div
              style={{ flex: '1 1 90px' }}
              className={cn(styles.tableColumn)}
            >
              {imageColumn(
                banner.bannerImageUrl.replace(
                  config.bucketUrl,
                  config.akamiUrl,
                ),
              )}
            </div>
            <div
              style={{ flex: '1 1 200px' }}
              className={cn(styles.tableColumn)}
            >
              {banner.description}
            </div>
            <div
              style={{ flex: '1 1 60px' }}
              className={cn(styles.tableColumn, styles.link)}
            >
              {buttonColumn(banner.buttons)}
            </div>
            <div
              style={{ flex: '1 1 60px' }}
              className={cn(styles.tableColumn)}
            >
              {sectorsById[banner.sectorId]?.name}
            </div>
            <div
              style={{ flex: '0 0 90px' }}
              className={cn(styles.tableColumn, styles.actionColumn)}
            >
              <BannerActionMenu
                active={banner.active}
                onOptionClick={(action) => onActionMenuClick(action, banner)}
              />
            </div>
          </div>
        ))}
      </div>

      <div className={cn(styles.tableBody, styles.mobile)}>
        <TableMobileView
          rows={banners}
          columns={columns}
          actions={mobileRowActions}
          className={styles.tableMobileView}
        />
      </div>
      <Paginator
        page={page}
        perPage={perPage}
        total={total}
        onPageChange={onPageChange}
        onPerPageChange={onPerPageChange}
      />
    </div>
  );
}
