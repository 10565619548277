import { FormattedMessage } from 'react-intl';
import styles from './ConfirmationModal.module.scss';
import Button from '../../../common/Button/Button';
import Modal from '../../../common/Modal/Modal';

type ConfirmationModalProps = {
  type?: 'deactivate' | 'activate';
  onSave: () => void;
  onClose: () => void;
};

const ConfirmationModal = ({
  type = 'deactivate',
  onSave,
  onClose,
}: ConfirmationModalProps) => {
  const handleSaveBtnClick = () => {
    onSave();
    onClose();
  };

  return (
    <Modal
      center={true}
      onClose={onClose}
      className={styles.deactivateEomModalContainer}
    >
      <div className={styles.container}>
        <h1>
          {type === 'activate' ? (
            <FormattedMessage id={'oem-model-activate-approval'} />
          ) : (
            <FormattedMessage id={'oem-model-deactivate-approval'} />
          )}
        </h1>
        <div className={styles.modalContent}>
          <div className={styles.description}>
            {type === 'activate' ? (
              <FormattedMessage id={'oem-models-activate-confirm-info'} />
            ) : (
              <FormattedMessage id={'oem-models-deactivate-confirm-info'} />
            )}
          </div>
        </div>
        <div className={styles.actionBtnContainer}>
          <Button
            onClick={() => handleSaveBtnClick()}
            color={'green'}
            className={styles.confirmButton}
          >
            <FormattedMessage id={'yes-label'} />
          </Button>
          <Button
            onClick={() => onClose()}
            color={'green-outline'}
            className={styles.cancelButton}
          >
            <FormattedMessage id={'no-label'} />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
