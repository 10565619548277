/* istanbul ignore file */
import { uniq } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import GenericManageSkeleton from '../../../components/Admin/GenericManageSkeleton/GenericManageSkeleton';
import Header from '../../../components/Admin/Header/Header';
import {
  showGenericModal,
  setUnsavedStatus,
} from '../../../features/genericModalSlice';
import { hideLoading, showLoading } from '../../../features/loadingSlice';
import withAdmin from '../../../hoc/withAdmin';
// import { CountrySupportingDocument } from '../../../models/country-supporting-document';
import {
  AddProductFormValues,
  RangeFamily,
  StepperItemType,
  ValueOf,
} from '../../../models/admin-form-types';
import { AdminProductDetails } from '../../../models/admin-product-details';

import { PreviousLink } from '../../../models/previous-link';
// import { uploadDocument } from '../../../services/DocumentService';
import { getRangeFamilies } from '../../../services/FamilyService';
import { MountService } from '../../../services/MountService';
import {
  createProduct,
  getProductById,
  updateProduct,
} from '../../../services/ProductService';
import { isNotNullOrWhiteSpace } from '../../../utils/string.util';

import styles from './AddProduct.module.scss';
// import AddSupportingDocuments from './steps/AddSupportingDocuments/AddSupportingDocuments';
import RegionAvailability from './steps/ProductAvailability/RegionAvailability/RegionAvailability';
// import UnavailableRegionMessage from './steps/ProductAvailability/UnavailableRegionMessage/UnavailableRegionMessage';
import EnvironmentalAndOther from './steps/ProductCharacteristics/EnvironmentalAndOther/EnvironmentalAndOther';
import GeneralCharacteristics from './steps/ProductCharacteristics/GeneralCharacteristics/GeneralCharacteristics';
import Categorisation from './steps/ProductOverview/Categorisation/Categorisation';
import LocalisedProductDetails from './steps/ProductOverview/LocalisedProductDetails/LocalisedProductDetails';
import PartialProductInformation from './steps/ProductOverview/PartialProductInformation/PartialProductInformation';
import ProductSummary from './steps/ProductSummary/ProductSummary';
// import ReplacementProducts from './steps/ReplacementProducts/ReplacementProducts';
import {
  isCommaSeperatedItems,
  isPositiveInteger,
} from '../../../utils/validation.util';
import { RootStore } from '../../../store';

const AddProduct = () => {
  const intl = useIntl();

  const addProductDefaultFormValue: AddProductFormValues = {
    displayOnlyPartialInfo: false,
    productExternalId: '',
    productName: '',
    sectorId: [],
    subSectorIds: [],
    friendlyUrl: '',
    languageIds: [],
    activeLanguage: 0,
    wasLanguageActiveMap: { [1]: true },
    localisedContentByLang: {},
    masterBrand: null,
    productFamily: null,
    productRange: null,
    productCategory: [],
    productSubCategory: null,
    productTypes: [],
    applications: [],
    subApplications: [],
    repositoryBusiness: '',
    recommendedByCastrol: false,
    avaiableCountries: [],
    unavailableLanguages: [],
    localisedMessageByLang: {
      1: intl.formatMessage({ id: 'default-product-availability-description' }),
    },
    viscosity: '',
    viscosityIndex: null,
    nlgi: [],
    minTemperature: '',
    maxTemperature: '',
    grade: '',
    subGrade: '',
    oilTypes: [],
    thickener: [],
    metalType: [],
    esterEpAdditives: false,
    mineralOil: null,
    corrosionProtection: null,
    concentrationRange: null,
    characteristics: null,
    customerOffer: null,
    foodGrade: null,
    superiorBiodegradation: null,
    multiMetalsSuitable: null,
    formulatedWithoutSilicate: null,
    formulatedWithoutHeavyMetal: null,
    formulatedWithoutBoron: null,
    formulatedWithoutChlorinatedParaffin: null,
    supportingDocCountries: [],
    activeCountry: null,
    wasCountryActiveMap: {},
    supportingDocByCountry: {},
    productCountryLevels: [],
  };

  const dispatch = useDispatch();

  const params = useParams();
  const [step, setStep] = useState<number[]>([0, 0]);

  const { sectors, categories, applications } = useSelector(
    (state: RootStore) => state.lookups,
  );

  const industrial = useMemo(() => {
    return sectors.find((x) => x.friendlyUrl === 'industrial')?.id || 4;
  }, [sectors]);

  const mountService = useMemo(() => new MountService(), []);
  useEffect(() => {
    mountService.mount();
    return () => mountService.unmount();
  }, []);

  const [families, setFamilies] = useState<RangeFamily[]>([]);
  mountService.addDispatch('setFamilies', setFamilies);

  useEffect(() => {
    (async () => {
      const families = await getRangeFamilies(false);
      mountService.callDispatch('setFamilies', families);
    })();
  }, []);

  const productDetails = useRef<AdminProductDetails | null>(null);

  const isEdit = !!params.productId;

  const [isNextDisabled, setDisableNext] = useState<boolean>(false);

  const [formValue, setFormValue] = useState<AddProductFormValues>(
    addProductDefaultFormValue,
  );
  const [formOriginalValue, setFormOriginalValue] = useState<string>('');

  mountService.addDispatch('setFormValue', setFormValue);
  mountService.addDispatch('setFormOriginalValue', setFormOriginalValue);
  mountService.addDispatch('setStep', setStep);

  useEffect(() => {
    if (params.productId) {
      (async () => {
        const productId = Number(params.productId);
        try {
          dispatch(showLoading());
          productDetails.current = await getProductById(productId);
          const productFormVal = {
            displayOnlyPartialInfo: productDetails.current.isPartial,
            productExternalId: productDetails.current.externalId,
            productName: productDetails.current.name,
            sectorId: productDetails.current.productSectors.map(
              (sector) => sector.sectorId,
            ),
            subSectorIds: productDetails.current.productSubSectors.map(
              (subSector) => subSector.subSectorId,
            ),
            friendlyUrl: productDetails.current.friendlyUrl || '',
            languageIds: [
              1, // 1 is for default language, i.e. English
              ...uniq(
                productDetails.current.translations
                  .filter(
                    (translation) =>
                      ['tagLine', 'description'].indexOf(
                        translation.propertyName,
                      ) >= 0,
                  )
                  .map((translation) => translation.languageId),
              ),
            ],
            activeLanguage: 1,
            wasLanguageActiveMap: {
              [1]: true,
              ...productDetails.current.translations
                .filter(
                  (translation) =>
                    ['tagLine', 'description'].indexOf(
                      translation.propertyName,
                    ) >= 0,
                )
                .map((translation) => translation.languageId)
                .reduce<Record<number, boolean>>((collection, languageId) => {
                  collection[languageId] = true;
                  return collection;
                }, {}),
            },
            localisedContentByLang: {
              [1]: {
                tagLine: productDetails.current.tagLine,
                description: productDetails.current.description,
              },
              ...productDetails.current.translations
                .filter(
                  (translation) =>
                    ['tagLine', 'description'].indexOf(
                      translation.propertyName,
                    ) >= 0,
                )
                .reduce<
                  Record<
                    number,
                    {
                      productTagLine: string;
                      productDescription: string;
                    }
                  >
                >((collection, translation) => {
                  const col = collection[translation.languageId] || {};
                  collection[translation.languageId] = {
                    ...col,
                    [translation.propertyName]: translation.propertyValue,
                  };
                  return collection;
                }, {}),
            },
            masterBrand: productDetails.current.masterBrandId,
            productFamily: productDetails.current.familyId || null,
            productRange: productDetails.current.rangeId || null,
            productCategory:
              productDetails.current.productCategories &&
              productDetails.current.productCategories.length > 0
                ? productDetails.current.productCategories.map(
                    (x) => x.categoryId,
                  )
                : [],
            productSubCategory: productDetails.current.subCategoryId || null,
            productTypes: productDetails.current.productProductTypes.map(
              (productType) => productType.productTypeId,
            ),
            applications: productDetails.current.productApplications.map(
              (application) => application.applicationId,
            ),
            subApplications: productDetails.current.productSubApplications.map(
              (subApplication) => subApplication.subApplicationId,
            ),
            repositoryBusiness: productDetails.current.repositoryBusiness,
            recommendedByCastrol: productDetails.current.castrolRecommended,
            avaiableRegion: null, // TODO
            avaiableCountries:
              productDetails.current.productAvailableCountries.map(
                (country) => country.countryId,
              ),
            unavailableLanguages: [
              1,
              ...uniq(
                productDetails.current.translations
                  .filter(
                    (translation) =>
                      ['unavailableInACountryMessage'].indexOf(
                        translation.propertyName,
                      ) >= 0,
                  )
                  .map((translation) => translation.languageId),
              ),
            ],
            localisedMessageByLang: {
              [1]: productDetails.current.unavailableInACountryMessage,
              ...productDetails.current.translations
                .filter(
                  (translation) =>
                    ['unavailableInACountryMessage'].indexOf(
                      translation.propertyName,
                    ) >= 0,
                )
                .reduce<Record<number, string>>((collection, translation) => {
                  collection[translation.languageId] =
                    translation.propertyValue;
                  return collection;
                }, {}),
            },
            viscosity: (
              productDetails.current.productCharacteristic.viscosity || []
            ).join(','),
            viscosityIndex:
              productDetails.current.productCharacteristic.viscosityIndexId,
            nlgi: productDetails.current.productCharacteristic.productCharacteristicNlgis.map(
              (nlgi) => nlgi.nlgiId,
            ),
            minTemperature:
              productDetails.current.productCharacteristic.minTemperatureC ===
              null
                ? ''
                : String(
                    productDetails.current.productCharacteristic
                      .minTemperatureC,
                  ),
            maxTemperature:
              productDetails.current.productCharacteristic.maxTemperatureC ===
              null
                ? ''
                : String(
                    productDetails.current.productCharacteristic
                      .maxTemperatureC,
                  ),
            grade: productDetails.current.productCharacteristic.grade || '',
            subGrade:
              productDetails.current.productCharacteristic.subGrade || '',
            oilTypes:
              productDetails.current.productCharacteristic.productCharacteristicBaseOilTypes.map(
                (oilType) => oilType.baseOilTypeId,
              ),
            thickener:
              productDetails.current.productCharacteristic.productCharacteristicThickeners.map(
                (thickener) => thickener.thickenerId,
              ),
            metalType:
              productDetails.current.productCharacteristic.productCharacteristicMetalTypes.map(
                (metalType) => metalType.metalTypeId,
              ),
            esterEpAdditives:
              productDetails.current.productCharacteristic.esterEpAdditives,
            mineralOil:
              productDetails.current.productCharacteristic.mineralOilId,
            corrosionProtection:
              productDetails.current.productCharacteristic
                .corrosionProtectionId,
            concentrationRange:
              productDetails.current.productCharacteristic.concentrationRangeId,
            characteristics:
              productDetails.current.productCharacteristic.characteristicId,
            customerOffer:
              productDetails.current.productCharacteristic.customerOfferId,
            foodGrade: productDetails.current.productCharacteristic.foodGradeId,
            superiorBiodegradation:
              productDetails.current.productCharacteristic
                .superiorBiodegradationId,
            multiMetalsSuitable:
              productDetails.current.productCharacteristic
                .multiMetalsSuitableId,
            formulatedWithoutSilicate:
              productDetails.current.productCharacteristic
                .formulatedWithoutSilicateId,
            formulatedWithoutHeavyMetal:
              productDetails.current.productCharacteristic
                .formulatedWithoutHeavyMetalId,
            formulatedWithoutBoron:
              productDetails.current.productCharacteristic
                .formulatedWithoutBoronId,
            formulatedWithoutChlorinatedParaffin:
              productDetails.current.productCharacteristic
                .formulatedWithoutChlorinatedParaffinId,
            supportingDocCountries: uniq(
              productDetails.current.productDocuments.map(
                (document) => document.countryId || -1,
              ),
            ),
            supportingLanaguages: [],
            activeCountry:
              productDetails.current.productDocuments.length > 0
                ? productDetails.current.productDocuments?.[0]?.countryId || -1
                : null,
            wasCountryActiveMap: {
              ...productDetails.current.productDocuments.reduce<
                Record<number, boolean>
              >((collection, currentCountry) => {
                if (currentCountry.countryId !== null) {
                  collection[currentCountry.countryId] = true;
                } else {
                  collection[-1] = true;
                }
                return collection;
              }, {}),
            },
            // supportingDocByCountry:
            //   productDetails.current.productDocuments.reduce<
            //     Record<number, CountrySupportingDocument>
            //   >((collection, document) => {
            //     const countryId = document.countryId || -1;
            //     if (!collection[countryId]) {
            //       collection[countryId] = {
            //         productDatasheetNlgi: null,
            //         productDatasheetViscosity: null,
            //         productDatasheetType: 'url',
            //         productDatasheet: '',
            //         materialSafetyDatasheetType: 'url',
            //         materialSafetyDatasheet: '',
            //         addedDocuments: [],
            //       };
            //     }
            //     collection[countryId].addedDocuments.push({
            //       id: document.id as number,
            //       viscosity: document.viscosity,
            //       nlgiId: document.nlgiId,
            //       addedProductDataSheetDocument:
            //         document.productDataSheetDocument,
            //       addedSafetyDataSheetDocument:
            //         document.safetyDataSheetDocument,
            //     });
            //     return collection;
            //   }, {}),
            // productCountryLevels: productDetails.current.productCountryLevels,
          };
          mountService.callDispatch('setFormValue', productFormVal);
          mountService.callDispatch(
            'setFormOriginalValue',
            JSON.stringify(productFormVal),
          );
          mountService.callDispatch('setStep', [0, 0]);
        } catch (err) {
          console.log(err);
        } finally {
          dispatch(hideLoading());
        }
      })();
    } else {
      setFormValue(addProductDefaultFormValue);
      setFormOriginalValue(JSON.stringify(addProductDefaultFormValue));
      setStep([0, 0]);
    }
  }, [params.productId]);

  const handleValueChange = (
    fieldKey: string,
    value: ValueOf<AddProductFormValues>,
  ) => {
    const newValue = {
      ...formValue,
      [fieldKey]: value,
    };
    dispatch(setUnsavedStatus(formOriginalValue !== JSON.stringify(newValue)));
    mountService.callDispatch(
      'setFormValue',
      (oldValues: AddProductFormValues) => {
        return {
          ...oldValues,
          [fieldKey]: value,
        };
      },
    );
  };

  const breadcrumbLinks: PreviousLink[] = useMemo(
    () => [
      {
        name: intl.formatMessage({ id: 'breadcrumb-home' }),
        to: '/home',
      },
      {
        name: intl.formatMessage({
          id: isEdit ? 'breadcrumb-edit-products' : 'breadcrumb-add-products',
        }),
      },
    ],
    [intl, isEdit],
  );

  const stepperItems: StepperItemType = [
    {
      label: intl.formatMessage({ id: 'add-product-step-product-overview' }),
      children: [
        {
          label: intl.formatMessage({
            id: 'add-product-step-partial-info',
          }),
          componentToRender: (
            <PartialProductInformation
              formValue={formValue}
              handleValueChange={handleValueChange}
              setNextDisable={setDisableNext}
              productId={
                params.productId ? parseInt(params.productId) : undefined
              }
            />
          ),
        },
        {
          label: intl.formatMessage({
            id: 'add-product-step-localised-details',
          }),
          componentToRender: (
            <LocalisedProductDetails
              formValue={formValue}
              handleValueChange={handleValueChange}
            />
          ),
        },
        {
          label: intl.formatMessage({
            id: 'add-product-step-categorisation',
          }),
          componentToRender: (
            <Categorisation
              families={families}
              formValue={formValue}
              handleValueChange={handleValueChange}
              isEdit={isEdit}
            />
          ),
        },
      ],
    },
    {
      label: intl.formatMessage({ id: 'add-product-step-availability' }),
      children: [
        {
          label: intl.formatMessage({
            id: 'add-product-step-region-availability',
          }),
          componentToRender: (
            <RegionAvailability
              formValue={formValue}
              handleValueChange={handleValueChange}
            />
          ),
        },
        // {
        //   label: intl.formatMessage({
        //     id: 'add-product-step-unavailable-message',
        //   }),
        //   componentToRender: (
        //     <UnavailableRegionMessage
        //       formValue={formValue}
        //       handleValueChange={handleValueChange}
        //     />
        //   ),
        // },
      ],
    },
    {
      label: intl.formatMessage({ id: 'add-product-step-characteristics' }),
      children: [
        {
          label: intl.formatMessage({
            id: 'add-product-step-general-characteristics',
          }),
          componentToRender: (
            <GeneralCharacteristics
              formValue={formValue}
              handleValueChange={handleValueChange}
            />
          ),
        },
        {
          label: intl.formatMessage({
            id: 'add-product-step-other-features',
          }),
          componentToRender: (
            <EnvironmentalAndOther
              formValue={formValue}
              handleValueChange={handleValueChange}
              displayOnlyPartialInfo={formValue.displayOnlyPartialInfo}
            />
          ),
        },
      ],
    },
    // {
    //   label: intl.formatMessage({ id: 'add-product-step-documents' }),
    //   componentToRender: (
    //     <AddSupportingDocuments
    //       formValue={formValue}
    //       handleValueChange={handleValueChange}
    //     />
    //   ),
    // },
    // {
    //   label: intl.formatMessage({ id: 'add-product-step-replacement' }),
    //   componentToRender: <ReplacementProducts formValue={formValue} />,
    // },
    {
      label: intl.formatMessage({ id: 'add-product-step-summary' }),
      componentToRender: (
        <ProductSummary families={families} formValue={formValue} />
      ),
    },
  ];

  const isLastPage = step[0] === stepperItems.length - 1;
  const isLastSubStep =
    !stepperItems[step[0]].children ||
    step[1] === (stepperItems[step[0]].children?.length || 0) - 1;

  // const uploadDoc = async (type: string, file: File) => {
  //   const formData = new FormData();
  //   formData.append('type', type);
  //   formData.append('file', file);
  //   return uploadDocument(formData);
  // };

  const getCategoryIds = (items: any[]) => {
    if (items.length === 1 && items[0] === -1) {
      return categories.map((category) => ({
        categoryId: category.id,
      }));
    } else {
      return items.map((categoryId) => ({ categoryId }));
    }
  };

  const getApplicationIds = (items: any[]) => {
    if (items.length === 1 && items[0] === -1) {
      return applications.map((application) => ({
        applicationId: application.id,
      }));
    } else {
      return items.map((applicationId) => ({ applicationId }));
    }
  };

  const clickSave = async () => {
    const payload: AdminProductDetails = {
      isPartial: formValue.displayOnlyPartialInfo,
      externalId: formValue.productExternalId,
      name: formValue.productName,
      friendlyUrl: formValue.friendlyUrl,
      productSectors: formValue.sectorId.map((sectorId) => ({ sectorId })),
      productSubSectors: formValue.subSectorIds.map((subSectorId) => ({
        subSectorId,
      })),
      tagLine: formValue.localisedContentByLang[1].tagLine,
      description: formValue.localisedContentByLang[1].description,

      translations: [
        ...formValue.languageIds.slice(1).map((langId) => ({
          propertyName: 'tagLine',
          propertyValue: formValue.localisedContentByLang[langId].tagLine,
          languageId: langId,
        })),
        ...formValue.languageIds.slice(1).map((langId) => ({
          propertyName: 'description',
          propertyValue: formValue.localisedContentByLang[langId].description,
          languageId: langId,
        })),
        ...formValue.unavailableLanguages.slice(1).map((langId) => ({
          propertyName: 'unavailableInACountryMessage',
          propertyValue: formValue.localisedMessageByLang[langId],
          languageId: langId,
        })),
      ],
      masterBrandId: formValue.masterBrand as number,
      familyId: formValue.productFamily as number,
      rangeId: formValue.productRange,
      productCategories: getCategoryIds(formValue.productCategory),
      subCategoryId: formValue.productSubCategory as number,
      productProductTypes: formValue.productTypes.map((productTypeId) => ({
        productTypeId,
      })),
      productApplications: getApplicationIds(formValue.applications),
      productSubApplications: formValue.subApplications.map(
        (subApplicationId) => ({
          subApplicationId,
        }),
      ),
      repositoryBusiness: formValue.repositoryBusiness,
      castrolRecommended: formValue.recommendedByCastrol,
      productAvailableCountries: formValue.avaiableCountries.map(
        (countryId) => ({
          countryId,
        }),
      ),
      unavailableInACountryMessage: formValue.localisedMessageByLang[1],
      productCharacteristic: {
        viscosity: formValue.viscosity.trim()
          ? formValue.viscosity.split(',').map((vis) => Number(vis))
          : [],
        viscosityIndexId: formValue.viscosityIndex,
        productCharacteristicNlgis: formValue.nlgi.map((nlgiId) => ({
          nlgiId,
        })),
        minTemperatureC: Number(formValue.minTemperature),
        maxTemperatureC: Number(formValue.maxTemperature),
        grade: Number(formValue.grade),
        subGrade: Number(formValue.subGrade),
        productCharacteristicBaseOilTypes: formValue.oilTypes.map(
          (baseOilTypeId) => ({ baseOilTypeId }),
        ),
        productCharacteristicThickeners: formValue.thickener.map(
          (thickenerId) => ({ thickenerId }),
        ),
        productCharacteristicMetalTypes: formValue.metalType.map(
          (metalTypeId) => ({ metalTypeId }),
        ),
        esterEpAdditives: formValue.esterEpAdditives,
        mineralOilId: formValue.mineralOil,
        corrosionProtectionId: formValue.corrosionProtection,
        concentrationRangeId: formValue.concentrationRange,
        characteristicId: formValue.characteristics,
        customerOfferId: formValue.customerOffer,
        foodGradeId: formValue.foodGrade,
        superiorBiodegradationId: formValue.superiorBiodegradation,
        multiMetalsSuitableId: formValue.multiMetalsSuitable,
        formulatedWithoutSilicateId: formValue.formulatedWithoutSilicate,
        formulatedWithoutChlorinatedParaffinId:
          formValue.formulatedWithoutChlorinatedParaffin,
        formulatedWithoutBoronId: formValue.formulatedWithoutBoron,
        formulatedWithoutHeavyMetalId: formValue.formulatedWithoutHeavyMetal,
      },
      productDocuments: [],
    };

    // add documents
    dispatch(showLoading());
    try {
      // const supportingCountries = Object.entries(
      //   formValue.supportingDocByCountry,
      // )
      //   .filter(
      //     ([, countrySupportingDocument]) =>
      //       countrySupportingDocument.addedDocuments.length > 0,
      //   )
      //   .map(([countryId]) => Number(countryId));
      // for (const country of supportingCountries) {
      //   const countryDocuments = formValue.supportingDocByCountry[country];
      //   // upload documents in added documents
      //   for (const addedDoc of countryDocuments.addedDocuments) {
      //     const realCountryId = country > 0 ? country : null;
      //     const documentsItem: any = {
      //       countryId: realCountryId,
      //       viscosity: addedDoc.viscosity,
      //       nlgiId: addedDoc.nlgiId,
      //       languageId: null,
      //     };
      //     if (addedDoc.addedProductDataSheetDocument instanceof File) {
      //       const res = await uploadDoc(
      //         'PDS',
      //         addedDoc.addedProductDataSheetDocument,
      //       );
      //       documentsItem.productDataSheetDocumentId = res.id;
      //     } else if (
      //       typeof addedDoc.addedProductDataSheetDocument === 'string'
      //     ) {
      //       documentsItem.productDataSheetDocument = {
      //         url: addedDoc.addedProductDataSheetDocument,
      //         type: 'PDS',
      //       };
      //     } else {
      //       documentsItem.productDataSheetDocument =
      //         addedDoc.addedProductDataSheetDocument;
      //       documentsItem.id = addedDoc.id;
      //     }
      //     if (addedDoc.addedSafetyDataSheetDocument instanceof File) {
      //       const res = await uploadDoc(
      //         'SDS',
      //         addedDoc.addedSafetyDataSheetDocument,
      //       );
      //       documentsItem.safetyDataSheetDocumentId = res.id;
      //     } else if (
      //       typeof addedDoc.addedSafetyDataSheetDocument === 'string'
      //     ) {
      //       documentsItem.productDataSheetDocument = {
      //         url: addedDoc.addedSafetyDataSheetDocument,
      //         type: 'SDS',
      //       };
      //     } else {
      //       documentsItem.safetyDataSheetDocument =
      //         addedDoc.addedSafetyDataSheetDocument;
      //       documentsItem.id = addedDoc.id;
      //     }
      //     if (isEdit && productDetails.current) {
      //       const oldProductDocuments =
      //         productDetails.current.productDocuments.find(
      //           (item) =>
      //             item.countryId === realCountryId &&
      //             item.nlgiId === addedDoc.nlgiId &&
      //             item.viscosity === addedDoc.viscosity,
      //         );
      //       if (oldProductDocuments) {
      //         // use old productDataSheet if no new value is set
      //         if (
      //           !documentsItem.productDataSheetDocument &&
      //           !documentsItem.productDataSheetDocumentId
      //         ) {
      //           documentsItem.productDataSheetDocument =
      //             oldProductDocuments.productDataSheetDocument || null;
      //         }
      //         // use old safetyDataSheetDocument if no new value is set
      //         if (
      //           !documentsItem.safetyDataSheetDocument &&
      //           !documentsItem.safetyDataSheetDocumentId
      //         ) {
      //           documentsItem.safetyDataSheetDocument =
      //             oldProductDocuments.safetyDataSheetDocument || null;
      //         }
      //       }
      //       payload.productDocuments.push(documentsItem);
      //     }
      //   }
      // }
      // create product
      if (isEdit) {
        if (productDetails.current) {
          const res = await updateProduct(
            productDetails.current.id as number,
            payload,
          );
          dispatch(
            showGenericModal({
              titleId: 'update-product-success-modal-title',
              messageId: 'update-product-success-modal-message',
              to: '/product/' + res.friendlyUrl,
            }),
          );
        }
      } else {
        const res = await createProduct(payload);
        dispatch(
          showGenericModal({
            titleId: 'add-product-success-modal-title',
            messageId: 'add-product-success-modal-message',
            to: '/product/' + res.friendlyUrl,
          }),
        );
      }
    } finally {
      dispatch(hideLoading());
    }
  };

  const isSaveButtonDisabled = (step: number[]) => {
    const {
      displayOnlyPartialInfo,
      productExternalId,
      productName,
      sectorId,
      friendlyUrl,
      masterBrand,
      productFamily,
      productCategory,
      productTypes,
      applications,
      repositoryBusiness,
      viscosity,
      foodGrade,
      superiorBiodegradation,
      multiMetalsSuitable,
      formulatedWithoutSilicate,
      formulatedWithoutHeavyMetal,
      formulatedWithoutBoron,
      formulatedWithoutChlorinatedParaffin,
    } = formValue;
    switch (step[0]) {
      case 0: {
        switch (step[1]) {
          case 0: {
            return (
              (!displayOnlyPartialInfo &&
                !isNotNullOrWhiteSpace(productExternalId)) ||
              !isNotNullOrWhiteSpace(productName) ||
              !isNotNullOrWhiteSpace(friendlyUrl) ||
              sectorId === null ||
              sectorId.length === 0
            );
          }
          case 1: {
            const {
              displayOnlyPartialInfo,
              languageIds,
              localisedContentByLang,
            } = formValue;
            return (
              languageIds.length === 0 ||
              languageIds.length !==
                Object.values(localisedContentByLang).length ||
              !!Object.values(localisedContentByLang).find(
                (content) =>
                  !isNotNullOrWhiteSpace(content.tagLine) ||
                  (!displayOnlyPartialInfo &&
                    !isNotNullOrWhiteSpace(content.description)),
              )
            );
          }
          case 2: {
            return (
              masterBrand === null ||
              productFamily === null ||
              (!displayOnlyPartialInfo && productCategory === null) ||
              (!displayOnlyPartialInfo &&
                sectorId.some((x) => x === industrial) &&
                productTypes.length === 0) ||
              (!displayOnlyPartialInfo && applications.length === 0) ||
              (!displayOnlyPartialInfo &&
                !isNotNullOrWhiteSpace(repositoryBusiness))
            );
          }
        }
        break;
      }
      case 1: {
        switch (step[1]) {
          case 0: {
            const { avaiableCountries } = formValue;
            return avaiableCountries.length === 0;
          }
          // case 1: {
          //   const { unavailableLanguages } = formValue;
          //   return unavailableLanguages.length === 0;
          // }
        }
        break;
      }
      case 2: {
        switch (step[1]) {
          case 0: {
            return !isCommaSeperatedItems(viscosity, isPositiveInteger);
          }
          case 1: {
            return (
              (!displayOnlyPartialInfo && foodGrade === null) ||
              (!displayOnlyPartialInfo && superiorBiodegradation === null) ||
              (!displayOnlyPartialInfo && multiMetalsSuitable === null) ||
              (!displayOnlyPartialInfo && formulatedWithoutSilicate === null) ||
              (!displayOnlyPartialInfo &&
                formulatedWithoutHeavyMetal === null) ||
              (!displayOnlyPartialInfo && formulatedWithoutBoron === null) ||
              (!displayOnlyPartialInfo &&
                formulatedWithoutChlorinatedParaffin === null)
            );
          }
        }
        break;
      }
      case 3: {
        return false;
      }
      case 4: {
        return false;
      }
      case 5: {
        return false;
      }
    }
    return false;
  };

  return (
    <div className={styles.container}>
      <Header
        titleId={isEdit ? 'edit-products-title' : 'add-products-title'}
        breadcrumbLinks={breadcrumbLinks}
        showUnsavedChangesModal={
          formOriginalValue !== JSON.stringify(formValue)
        }
      />
      <GenericManageSkeleton
        step={step}
        stepperItems={stepperItems}
        isEdit={isEdit}
        showUnsavedChangesModal={
          formOriginalValue !== JSON.stringify(formValue)
        }
        saveBtnDisabled={isSaveButtonDisabled(step) || isNextDisabled}
        onBackBtnClick={() => {
          if (step.length === 2 && step[1] > 0) {
            setStep([step[0], step[1] - 1]);
          } else {
            const newStep = [step[0] - 1];
            const newChildren = stepperItems[step[0] - 1].children;
            if (newChildren) {
              newStep.push(newChildren.length - 1);
            }
            setStep(newStep);
          }
        }}
        onSaveBtnClick={() => {
          if (isLastPage && isLastSubStep) {
            clickSave();
          } else {
            if (step.length === 2 && !isLastSubStep) {
              setStep([step[0], step[1] + 1]);
            } else {
              const newStep = [step[0] + 1];
              const newChildren = stepperItems[step[0] + 1].children;
              if (newChildren) {
                newStep.push(0);
              }
              setStep(newStep);
            }
          }
        }}
      >
        {stepperItems[step[0]].children?.[step[1]]?.componentToRender ||
          stepperItems[step[0]].componentToRender}
      </GenericManageSkeleton>
    </div>
  );
};

export default withAdmin(AddProduct);
