import { PropsWithoutRef } from 'react';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as CheckCircle } from '../../../../icons/check-circle.svg';
import LoadingSpinner from '../../../common/LoadingSpinner/LoadingSpinner';
import styles from './SaveData.module.scss';

interface SaveDataProps {
  isLoading: boolean;
}

function SaveData({ isLoading }: PropsWithoutRef<SaveDataProps>) {
  return (
    <div className={styles.container}>
      <h2>
        <FormattedMessage id={'save-data'} />
      </h2>
      <div className={styles.divider} />
      <div className={styles.resultContainer}>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <CheckCircle />
            <div className={styles.successText}>
              <FormattedMessage id={'country-success-label'} />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default SaveData;
