import cn from 'classnames';
import React, { PropsWithoutRef, useEffect, useState } from 'react';
import { HomePromotion } from '../../../../models/home-promotion';
import Button from '../../../common/Button/Button';
import styles from './PromotionSlider.module.scss';
import config from '../../../../configs/config';

interface PromotionSliderProps {
  promotions: HomePromotion[];
}

/**
 * promotion slider for home page
 * @param promotions promotion data
 */
function PromotionSlider({
  promotions,
}: PropsWithoutRef<PromotionSliderProps>) {
  const [slideIndex, setSlideIndex] = useState(0);
  const [promotion, setPromotion] = useState(promotions[0]);

  // clear timeout when component removed
  /* istanbul ignore next */
  useEffect(() => {
    const timer = setTimeout(() => {
      const nextIndex = slideIndex + 1;
      const index = nextIndex > promotions.length - 1 ? 0 : nextIndex;

      setSlideIndex(index);
      setPromotion(promotions[index]);
    }, 3000);

    return () => clearTimeout(timer);
  }, [slideIndex, promotions]);

  return (
    <div className={styles.container}>
      {promotions.map((promotion, index) => {
        return (
          <img
            key={index}
            className={cn(
              styles.banner,
              slideIndex === index && styles.bannerShow,
            )}
            alt={promotion.name}
            src={promotion.bannerImageUrl.replace(
              config.bucketUrl,
              config.akamiUrl,
            )}
          />
        );
      })}

      <div className={styles.cover} />

      <div className={styles.controlContainer}>
        <div className={styles.controlInnerContainer}>
          <div className={styles.promotionDetailsContainer}>
            <div className={styles.promotionTextContainer}>
              <div className={styles.title}>{promotions[slideIndex]?.name}</div>

              <div className={styles.description}>
                {promotions[slideIndex]?.description}
              </div>
            </div>

            <div className={styles.sliderContainer}>
              {promotions.map((promotion, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => setSlideIndex(index)}
                    className={cn(
                      styles.sliderButton,
                      slideIndex === index && styles.sliderShow,
                    )}
                  />
                );
              })}
            </div>
          </div>

          <div className={styles.promotionActionsContainer}>
            {promotion?.buttons
              .filter(
                (button) =>
                  button.name?.trim().length > 0 &&
                  button.value?.trim().length > 0,
              )
              .map((button, index) => {
                return (
                  <a
                    key={index}
                    target={'_blank'}
                    href={button.value}
                    rel={'noreferrer'}
                  >
                    <Button
                      key={`button-${index}`}
                      className={styles.promotionButton}
                      color={index % 2 ? 'blue' : 'green'}
                    >
                      {button.name}
                    </Button>
                  </a>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PromotionSlider;
