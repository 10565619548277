import { PropsWithoutRef } from 'react';
import cn from 'classnames';
import styles from './SwitchToggle.module.scss';

interface SwitchToggleProps {
  checked?: boolean;
  label?: string;
  className?: string;
  labelClassName?: string;
  disabled?: boolean;
  onClick?: () => void;
}

function SwitchToggle({
  checked = false,
  label,
  onClick,
  className,
  labelClassName,
  disabled = false,
}: PropsWithoutRef<SwitchToggleProps>) {
  return (
    <div
      className={cn(styles.container, className)}
      onClick={() => !disabled && onClick && onClick()}
    >
      <div
        className={cn(
          styles.switchBody,
          checked && styles.checked,
          disabled && styles.disabled,
        )}
      >
        <div className={styles.innerCircle} />
      </div>
      {label && (
        <span className={cn(styles.label, labelClassName)}>{label}</span>
      )}
    </div>
  );
}

export default SwitchToggle;
