import { PropsWithoutRef } from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './HelpBanner.module.scss';

interface HelpBannerProps {
  messageId: string;
}

/**
 * banner for help modal
 */
function HelpBanner({ messageId }: PropsWithoutRef<HelpBannerProps>) {
  return (
    <div className={styles.container}>
      <img src={'/images/logo2.png'} alt={'logo'} />

      <div className={styles.title}>
        <FormattedMessage id={'help-label'} />
      </div>

      <div className={styles.question}>
        <FormattedMessage id={messageId} />
      </div>
    </div>
  );
}

export default HelpBanner;
