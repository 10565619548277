import { PropsWithoutRef, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { cloneDeep, find } from 'lodash';
import cn from 'classnames';

import styles from './RequestAccountForm.module.scss';
import { RootStore } from '../../../../store';
import Button from '../../Button/Button';
import InputRow from '../../../Admin/InputRow/InputRow';
import SelectFormRow from '../../../Admin/SelectFormRow/SelectFormRow';

import PrivacyPolicyInfo from '../PrivacyPolicyInfo/PrivacyPolicyInfo';
import { RequestAccount } from '../../../../models/request-account';
import { CountryItem } from '../../../../models/lookups';
import {
  emailValidator,
  castrolEmailValidator,
} from '../../../../utils/string.util';

interface RequestAccountFormProps {
  className?: string;
  onSubmit?: (data: RequestAccount) => void;
}

/**
 * request account form
 * @param className classname
 * @param onSubmit handle submit click
 */
function RequestAccountForm({
  className,
  onSubmit,
}: PropsWithoutRef<RequestAccountFormProps>) {
  // init hooks
  const intl = useIntl();
  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });

  const { countries, languages } = useSelector(
    (rootState: RootStore) => rootState.lookups,
  );

  const [userRequestAccount, setUserRequestAccount] =
    useState<RequestAccount>();
  const [acceptPrivacyPolicy, setAcceptPrivacyPolicy] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<CountryItem>();

  const countryOptions = useMemo(() => {
    return (
      countries?.map((item) => ({
        name: item.name,
        value: item.id,
      })) || []
    );
  }, [countries]);

  const languageOptions = useMemo(() => {
    return (
      languages?.map((item) => ({
        name: item.name,
        value: item.id,
      })) || []
    );
  }, [languages]);

  const allowSubmit = () => {
    return (
      userRequestAccount &&
      userRequestAccount.firstName &&
      userRequestAccount.firstName.trim().length > 0 &&
      userRequestAccount.lastName &&
      userRequestAccount.lastName.trim().length > 0 &&
      userRequestAccount.companyName &&
      userRequestAccount.companyName.trim().length > 0 &&
      userRequestAccount.email &&
      !emailValidator(userRequestAccount.email) &&
      userRequestAccount.countryId &&
      userRequestAccount.languageId &&
      userRequestAccount.castrolSponsorName &&
      userRequestAccount.castrolSponsorName.trim().length > 0 &&
      userRequestAccount.castrolSponsorEmail &&
      !castrolEmailValidator(userRequestAccount.castrolSponsorEmail) &&
      acceptPrivacyPolicy
    );
  };

  const handleChange = (key: keyof RequestAccount, value: any) => {
    const tmp: any = cloneDeep(userRequestAccount) || {};
    tmp[key] = value;
    setUserRequestAccount(tmp);
  };

  const submit = () => {
    onSubmit && onSubmit(userRequestAccount as RequestAccount);
  };

  return (
    <div className={cn(styles.container, className)}>
      <div className={'columnRow'}>
        <InputRow
          labelId={'first-name-label'}
          value={userRequestAccount?.firstName}
          inputClassName={styles.inputField}
          required={true}
          onChange={(value: string) => handleChange('firstName', value)}
          tooltip={getTranslatedMessage('first-name-tooltip')}
        />
        <InputRow
          labelId={'last-name-label'}
          value={userRequestAccount?.lastName}
          inputClassName={styles.inputField}
          required={true}
          onChange={(value: string) => handleChange('lastName', value)}
          tooltip={getTranslatedMessage('last-name-tooltip')}
        />
      </div>

      <div className={'columnRow'}>
        <InputRow
          labelId={'company-name-label'}
          value={userRequestAccount?.companyName}
          inputClassName={styles.inputField}
          required={true}
          onChange={(value: string) => handleChange('companyName', value)}
          tooltip={getTranslatedMessage('company-name-tooltip')}
        />
      </div>

      <div className={'columnRow'}>
        <InputRow
          labelId={'email-address-label'}
          value={userRequestAccount?.email}
          inputClassName={styles.inputField}
          required={true}
          onChange={(value: string) => handleChange('email', value)}
          customValidator={(value: string) => emailValidator(value)}
          tooltip={getTranslatedMessage('email-address-3-tooltip')}
        />
      </div>

      <div className={'columnRow'}>
        <SelectFormRow
          labelId={'country-of-business-label'}
          tooltip={getTranslatedMessage('country-tooltip')}
          required={true}
          options={countryOptions}
          value={userRequestAccount?.countryId}
          onChange={(value: number) => {
            handleChange('countryId', value);
            const tmpCountry = find(countries, (item) => item.id === value);
            setSelectedCountry(tmpCountry);
          }}
        />
        <SelectFormRow
          labelId={'preferred-language-label'}
          required={true}
          options={languageOptions}
          value={userRequestAccount?.languageId}
          onChange={(value: number) => handleChange('languageId', value)}
        />
      </div>

      <div className={'columnRow'}>
        <InputRow
          labelId={'castrol-sponsor-name-label'}
          value={userRequestAccount?.castrolSponsorName}
          inputClassName={styles.inputField}
          required={true}
          onChange={(value) => handleChange('castrolSponsorName', value)}
          tooltip={getTranslatedMessage('castrol-sponsor-name-tooltip')}
        />
        <InputRow
          labelId={'castrol-sponsor-email-label'}
          value={userRequestAccount?.castrolSponsorEmail}
          inputClassName={styles.inputField}
          required={true}
          onChange={(value) => handleChange('castrolSponsorEmail', value)}
          customValidator={(value: string) => castrolEmailValidator(value)}
          errorMessage={getTranslatedMessage('castrol-email-error-message')}
          tooltip={getTranslatedMessage('castrol-sponsor-email-tooltip')}
        />
      </div>

      <PrivacyPolicyInfo
        agree={acceptPrivacyPolicy}
        country={selectedCountry}
        onAgreeChange={() => setAcceptPrivacyPolicy(!acceptPrivacyPolicy)}
      />

      <div className={styles.divider} />

      <div className={styles.buttonContainer}>
        <Button
          onClick={() => submit()}
          color={'green'}
          disabled={!allowSubmit()}
          className={styles.submitButton}
        >
          <FormattedMessage id={'submit-label'} />
        </Button>
      </div>
    </div>
  );
}

export default RequestAccountForm;
