import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SearchResultRange } from '../models/search-result-range';

export interface RangeDetailsSliceState {
  range: SearchResultRange;
}

const initialState: RangeDetailsSliceState = {
  range: {} as SearchResultRange,
};

/**
 * Range details
 */
export const rangeDetailsSlice = createSlice({
  name: 'rangeDetails',
  initialState,
  reducers: {
    setRangeDetails: (state, action: PayloadAction<SearchResultRange>) => ({
      ...state,
      range: action.payload,
    }),
  },
});

export const { setRangeDetails } = rangeDetailsSlice.actions;

export default rangeDetailsSlice.reducer;
