import { PropsWithoutRef } from 'react';
import { Link } from 'react-router-dom';
import SearchOrContactCastrol from '../../common/SearchOrContactCastrol/SearchOrContactCastrol';
import styles from './Notice.module.scss';
import { ReactComponent as Warning } from '../../../icons/warning-green.svg';
import { ProductSummary } from '../../../models/product-summary';
import classNames from 'classnames';

export type NoticeType = 'common' | 'not-available' | 'not-active';

interface NoticeProps {
  message?: string;
  header: string;
  type: NoticeType;
  alternateProducts?: ProductSummary[];
  showIcon?: boolean;
}

/**
 * port available table
 * @param details details of product
 * @param className classname
 */
function Notice({
  message,
  header,
  type,
  alternateProducts,
  showIcon,
}: PropsWithoutRef<NoticeProps>) {
  return (
    <div
      className={classNames(
        styles.messageContainer,
        type === 'not-active'
          ? styles.notActive
          : type === 'not-available'
          ? styles.notAvailable
          : styles.common,
      )}
    >
      {type === 'not-active' ||
        (showIcon && <Warning className={styles.icon} />)}
      <div className={styles.wrapper}>
        <h3>{header}</h3>
        {message && <div className={styles.message}>{message}</div>}
        {!message && <SearchOrContactCastrol />}
        {alternateProducts && alternateProducts.length > 0 && (
          <div className={styles.alternateProducts}>
            {alternateProducts.map((item, index) => (
              <div key={index} className={styles.item}>
                <Link to={`/product/${item.friendlyUrl}`}>{item.name}</Link>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default Notice;
