import styles from './ComparisonListItem.module.scss';
import { ReactComponent as Close } from '../../../../icons/close.svg';
import { Link } from 'react-router-dom';
import { PropsWithoutRef } from 'react';
import { useDispatch } from 'react-redux';
import { removeProduct } from '../../../../features/productComparisonSlice';
import cn from 'classnames';
import { SearchResultProduct } from '../../../../models/search-result-product';

interface ComparisonListItemProps {
  product: SearchResultProduct;
  className?: string;
}

/**
 * comparison list item
 * @param param product to display
 * @param className classname
 */
function ComparisonListItem({
  product,
  className,
}: PropsWithoutRef<ComparisonListItemProps>) {
  const dispatch = useDispatch();

  /**
   * close click handler
   */
  const deleteProduct = () => {
    dispatch(removeProduct(product));
  };

  return (
    <div className={cn(styles.container, className)}>
      <Link className={styles.productName} to={`/product/${product.id}`}>
        {product.name}
      </Link>

      <Close onClick={() => deleteProduct()} className={styles.close} />
    </div>
  );
}

export default ComparisonListItem;
