import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type GenericModalType = {
  type?: string;
  titleId: string;
  messageId: string;
  to?: string;
};

export interface GenericModalSliceState {
  show: boolean;
  data: GenericModalType | null;
  unsaved: boolean;
}

const initialState: GenericModalSliceState = {
  show: false,
  data: null,
  unsaved: false,
};

/**
 * Generic modal
 */
export const genericModalSlice = createSlice({
  name: 'genericModal',
  initialState,
  reducers: {
    setUnsavedStatus: (
      state: GenericModalSliceState,
      action: PayloadAction<boolean>,
    ): GenericModalSliceState => ({
      ...state,
      unsaved: action.payload,
    }),
    showGenericModal: (
      state: GenericModalSliceState,
      action: PayloadAction<GenericModalType>,
    ): GenericModalSliceState => ({
      ...state,
      show: true,
      data: action.payload,
    }),
    hideGenericModal: (
      state: GenericModalSliceState,
    ): GenericModalSliceState => ({
      ...state,
      show: false,
      data: null,
    }),
  },
});

export const { setUnsavedStatus, showGenericModal, hideGenericModal } =
  genericModalSlice.actions;

export default genericModalSlice.reducer;
