import { PropsWithChildren, useEffect, useState } from 'react';
import styles from './QuickStatsTable.module.scss';
import { QuickStats } from '../../../../../models/user-stats';
import { useIntl } from 'react-intl';
import { ReactComponent as UsersIcon } from '../../../../../icons/users.svg';
import { ReactComponent as EyeIcon } from '../../../../../icons/eye-green.svg';
import {
  Chart,
  PieSeries,
  Legend,
} from '@devexpress/dx-react-chart-material-ui';
import OemListTable from '../../../../../components/common/OemListTable/OemListTable';
import { TableColumn } from '../../../../../models/table-column';
import useWindowDimensions from '../../../../../hooks/useWindowDimensions ';

type QuickStatsTableType = {
  statsData: QuickStats;
  type: string;
  date: any;
};

const QuickStatsTable = ({
  statsData,
  type,
  date,
}: PropsWithChildren<QuickStatsTableType>) => {
  const [graphData, setGraphData] = useState<any>(null);
  const [tableData, setTableData] = useState<any>(null);
  const [graphTitle, setGraphTitle] = useState('');
  const intl = useIntl();
  const { width } = useWindowDimensions();
  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });

  const headerTitle =
    type === 'Users'
      ? getTranslatedMessage('reports-page-total-users')
      : getTranslatedMessage('reports-page-total-views');

  useEffect(() => {
    switch (type) {
      case 'Users':
        setGraphTitle(getTranslatedMessage('reports-page-user-by-role'));
        setGraphData(statsData.userStats);
        setTableData(statsData.loginStats);
        break;
      case 'Product Page Views':
        setGraphTitle(getTranslatedMessage('reports-page-view-by-role'));
        setGraphData(statsData.productViewStats);
        setTableData(statsData.productViewStats);
        break;
      case 'OEM Page Views':
        setGraphTitle(getTranslatedMessage('reports-page-result-by-role'));
        setGraphData(statsData.oemViewStats);
        setTableData(statsData.oemViewStats);
        break;
    }
  }, [statsData, type]);

  const columns: TableColumn<any>[] = [
    {
      key: 'role',
      property: 'role',
      label: getTranslatedMessage('reports-page-role-column'),
      width: '25%',
      render: (data) => {
        return <div className={styles.green}>{data?.role}</div>;
      },
    },
    {
      key: 'thisMonthCount',
      property: 'thisMonthCount',
      label: getTranslatedMessage('reports-page-this-month-column'),
      width: '25%',
    },
    {
      key: 'previousMonthCount',
      property: 'previousMonthCount',
      label: getTranslatedMessage('reports-page-prev-month-column'),
      width: '35%',
    },
    {
      key: 'changePercent',
      property: 'changePercent',
      label: getTranslatedMessage('reports-page-change-column'),
      width: '15%',
      render: (data) => {
        return (
          <div
            className={
              data?.changePercent > 0
                ? styles.green
                : data?.changePercent < 0
                ? styles.red
                : styles.zero
            }
          >
            {data?.changePercent > 0 ? (
              <>&#9650;</>
            ) : data?.changePercent < 0 ? (
              <>&#9660;</>
            ) : (
              <>&#9664; &#9654;</>
            )}
            <span className={styles.change}>
              {Math.floor(data?.changePercent) || 0} %
            </span>
          </div>
        );
      },
    },
  ];

  const chartData = (graphData?.byRoles || []).map((data: any) => ({
    ...data,
    title: `${data.allTimeCount || 0} (${
      ((data.allTimeCount / graphData.total) * 100.0).toFixed(2) || 0
    }%) ${data.role}`,
  }));

  const tableDataLocal = (tableData?.byRoles || []).map((data: any) => ({
    ...data,
    title: `${data.allTimeCount || 0} (${
      ((data.allTimeCount / graphData.total) * 100.0).toFixed(2) || 0
    }%) ${data.role}`,
  }));

  return (
    <div className={styles.tableContainer}>
      <div className={styles.header}>
        <div className={styles.usersIcon}>
          {type === 'Users' ? <UsersIcon /> : <EyeIcon />}
        </div>
        <div className={styles.totalUserWrapper}>
          <div className={styles.totalUser}>{headerTitle}</div>
          <div className={styles.userCount}>{graphData?.total}</div>
        </div>
      </div>

      <div className={styles.graphContainer}>
        <div className={styles.graphTitle}>{graphTitle}</div>
        <div className={styles.chart}>
          {chartData && (
            <Chart data={chartData} height={width <= 540 ? 650 : 250}>
              <PieSeries valueField="allTimeCount" argumentField="title" />
              <Legend position={width <= 540 ? 'bottom' : 'right'} />
            </Chart>
          )}
        </div>
      </div>
      <div className={styles.table}>
        <div className={styles.tableTitle}>
          {type === 'Users'
            ? getTranslatedMessage('reports-page-date')
            : getTranslatedMessage('reports-page-other-tab')}
          <span className={styles.date}>{date}</span>
        </div>
        <OemListTable
          data={tableDataLocal}
          sortBy={''}
          sortDirection={'asc'}
          columns={columns}
          showCheckbox={false}
          onSortChange={() => {
            console.log('sa');
          }}
        />
      </div>
    </div>
  );
};

export default QuickStatsTable;
