import styles from './ContainerForDrawer.module.scss';
import { PropsWithChildren } from 'react';
import classNames from 'classnames';

/**
 * container with left drawer
 * @param children children
 */
function ContainerForDrawer({ classes, children }: PropsWithChildren<any>) {
  return (
    <div className={classNames(styles.container, classes)}>{children}</div>
  );
}

export default ContainerForDrawer;
