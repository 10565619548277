import styles from './ProductAvailability.module.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import { PropsWithoutRef, useState } from 'react';
import { PortProduct } from '../../../models/port-details';
import Input from '../../common/Input/Input';
import ProductFamilyListItem from '../ProductFamilyListItem/ProductFamilyListItem';
import { FamilySummary } from '../../../models/family-summary';
import Button from '../../common/Button/Button';
import classNames from 'classnames';
interface ProductAvailabilityProps {
  families: FamilySummary[];
  products: PortProduct[];
}

/**
 * prodct availability
 * @param families product families
 * @param products products
 */
function ProductAvailability({
  families,
  products,
}: PropsWithoutRef<ProductAvailabilityProps>) {
  const intl = useIntl();
  const [searchVal, setSearchVal] = useState('');
  const [expand, setExpand] = useState(false);
  return (
    <div className={styles.container}>
      <h3>
        <FormattedMessage id={'product-availability-label'} />
      </h3>
      <div className={styles.inputContainer}>
        <h4>
          <FormattedMessage id={'refine-product-list-label'} />
        </h4>
        <Input
          className={styles.inputWrapper}
          value={searchVal}
          onChange={(_value) => {
            setSearchVal(_value);
          }}
          placeholder={intl.formatMessage({ id: 'product-name-placeholder' })}
        />
        {products.length > 0 &&
          (expand ? (
            <Button
              color={'green'}
              className={classNames(styles.button)}
              onClick={() => setExpand(!expand)}
            >
              Collapse All
            </Button>
          ) : (
            <Button
              color={'green'}
              className={classNames(styles.button)}
              onClick={() => setExpand(!expand)}
            >
              Expand All
            </Button>
          ))}
      </div>
      <div className={styles.listContainer}>
        {families
          .filter(
            (x) =>
              !(searchVal && searchVal.length > 0) ||
              x.name.toLowerCase().startsWith(searchVal.toLowerCase()),
          )
          .map((family, index) => (
            <ProductFamilyListItem
              family={family}
              expand={expand}
              products={products.filter(
                (x) => x.product.familyId === family.id,
              )}
              key={index}
            />
          ))}
        {!families.find(
          (x) =>
            !(searchVal && searchVal.length > 0) ||
            x.name.toLowerCase().startsWith(searchVal.toLowerCase()),
        ) && (
          <div className={styles.empty}>
            <FormattedMessage id={'no-results-label'} />
          </div>
        )}
      </div>
    </div>
  );
}

export default ProductAvailability;
