import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useParams } from 'react-router';

import GenericManageSkeleton from '../../../../components/Admin/GenericManageSkeleton/GenericManageSkeleton';
import Header from '../../../../components/Admin/Header/Header';
import FamilyRangeDetails from '../../../../components/Admin/ManageFamilyRangePage/FamilyRangeDetails/FamilyRangeDetails';
import LocalisedFamilyRangeDetails from '../../../../components/Admin/ManageFamilyRangePage/LocalisedFamilyRangeDetails/LocalisedFamilyRangeDetails';

import {
  ManageRangeFormType,
  ManageRangeType,
  RangeFamily,
  StepperItemType,
} from '../../../../models/admin-form-types';
import { PreviousLink } from '../../../../models/previous-link';

import { getRangeFamilies } from '../../../../services/FamilyService';

import { generateFriendlyUrl } from '../../../../utils/common.util';
import constructTranslationsPayload, {
  constructTranslationsFromApiResponse,
  formValuesValid,
} from '../../../../utils/family-range.util';

import {
  addRange,
  updateRange,
  getRangeById,
} from '../../../../services/RangeService';

import { hideLoading, showLoading } from '../../../../features/loadingSlice';
import { showGenericModal } from '../../../../features/genericModalSlice';
import { addToast } from '../../../../features/toastSlice';

import { RootStore } from '../../../../store';
import withAdmin from '../../../../hoc/withAdmin';

const breadcrumbLinks: PreviousLink[] = [
  {
    name: 'Home',
    to: '/home',
  },
];

const initialState: ManageRangeFormType = {
  name: '',
  friendlyUrl: '',
  rangeFamily: {} as RangeFamily,
  translations: {},
};

const ManageRange = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { rangeId } = useParams();
  const { sector, culture } = useSelector(
    (state: RootStore) => state.welcomeInfo,
  );

  const [step, setStep] = useState(0);
  const [initState, setInitState] = useState(initialState);
  const [formValue, setFormValue] = useState(initialState);
  const [rangeFamilies, setRangeFamilies] = useState<RangeFamily[]>([]);

  const fieldKeys = ['tagLine'];

  const resetStates = () => {
    setFormValue(initialState);
    setInitState(initialState);
    setStep(0);
  };

  useEffect(() => {
    if (!rangeId) {
      resetStates();
      breadcrumbLinks[1] = {
        name: 'Add Range',
      };
    } else {
      breadcrumbLinks[1] = {
        name: 'Edit Range',
      };
    }
  }, [rangeId]);

  /* istanbul ignore next */
  const fetchRangeById = (rangeFamilies: RangeFamily[]) => {
    return new Promise((resolve) => {
      if (rangeId) {
        getRangeById(rangeId).then((data) => {
          const translations = constructTranslationsFromApiResponse(
            data,
            fieldKeys,
          );
          const family = rangeFamilies.find(
            (rangeFamily) => rangeFamily.id === data.familyId,
          );
          const formVal = {
            ...formValue,
            ..._.pick(data, ['name', 'friendlyUrl']),
            rangeFamily: family || ({} as RangeFamily),
            translations,
          };
          setFormValue(formVal);
          setInitState(formVal);
          resolve(true);
        });
      } else {
        resolve(true);
      }
    });
  };

  useEffect(() => {
    dispatch(showLoading());
    getRangeFamilies()
      .then(async (res: RangeFamily[]) => {
        setRangeFamilies(res);
        await fetchRangeById(res);
      })
      .finally(() => dispatch(hideLoading()));
  }, []);

  useEffect(() => {
    if (formValue.name) {
      const friendlyUrl = generateFriendlyUrl(formValue.name);
      setFormValue({
        ...formValue,
        friendlyUrl,
      });
    }
  }, [formValue.name]);

  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });

  const handleValueChange = (fieldKey: string, value: string | boolean) => {
    setFormValue({
      ...formValue,
      [fieldKey]: value,
    });
  };

  const stepperItems: StepperItemType = [
    {
      label: getTranslatedMessage('range-details-label'),
      componentToRender: (
        <FamilyRangeDetails
          form="range"
          formValue={formValue}
          handleValueChange={handleValueChange}
          rangeFamilies={rangeFamilies}
        />
      ),
    },
    {
      label: getTranslatedMessage('localised-range-details-label'),
      componentToRender: (
        <LocalisedFamilyRangeDetails
          form="range"
          formValue={formValue}
          handleValueChange={handleValueChange}
        />
      ),
    },
  ];

  const saveDisabled = () => {
    if (step === 0) {
      return (
        !formValue.name.trim() ||
        !formValue.friendlyUrl.trim() ||
        !formValue.rangeFamily.id
      );
    }

    if (step === 1) {
      return formValuesValid(formValue.translations, fieldKeys);
    }

    return false;
  };

  const constructPayload = () => {
    const payload: ManageRangeType = {
      ..._.omit(formValue, ['translations', 'rangeFamily']),
      tagLine: formValue.translations[1].tagLine,
      description: formValue.translations[1].description,
      active: true,
      familyId: formValue.rangeFamily.id,
      translations: constructTranslationsPayload(
        formValue.translations,
        fieldKeys,
      ),
    };

    return payload;
  };

  const handleSaveBtnClick = () => {
    if (step === 1) {
      const payload = constructPayload();
      dispatch(showLoading());
      let req;
      let type = 'add';
      if (rangeId) {
        req = updateRange(rangeId, payload);
        type = 'update';
      } else {
        req = addRange(payload);
      }
      req
        .then((data) => {
          dispatch(
            showGenericModal({
              titleId: `range-${type}-success-label`,
              messageId: `range-${type}-success-message`,
              to: `/range/${data.friendlyUrl}/${sector}/${culture}`,
            }),
          );
        })
        .catch(() => {
          dispatch(
            addToast({
              type: 'error',
              title: getTranslatedMessage(`range-${type}-failure-message`),
              message: '',
            }),
          );
        })
        .finally(() => dispatch(hideLoading()));

      return;
    }

    setStep(step + 1);
  };

  return (
    <div>
      <Header
        titleId={
          rangeId
            ? 'header-menu-manage-products-edit-range'
            : 'header-menu-manage-products-add-range'
        }
        breadcrumbLinks={breadcrumbLinks}
      />
      <GenericManageSkeleton
        step={step}
        stepperItems={stepperItems}
        saveBtnDisabled={saveDisabled()}
        showUnsavedChangesModal={!_.isEqual(initState, formValue)}
        onBackBtnClick={() => setStep(step - 1)}
        onSaveBtnClick={() => handleSaveBtnClick()}
      >
        {stepperItems[step].componentToRender}
      </GenericManageSkeleton>
    </div>
  );
};

export default withAdmin(ManageRange);
