import styles from './ProductFamilyChildren.module.scss';
import { PropsWithoutRef } from 'react';
import { SearchResultFamily } from '../../../models/search-result-family';
import ProductListItem from '../../common/ProductListItem/ProductListItem';
import cn from 'classnames';
import config from '../../../configs/config';
import { FormattedMessage, useIntl } from 'react-intl';
import Notice from '../../common/Notice/Notice';

interface ProductFamilyChildrenProps {
  family: SearchResultFamily | undefined;
  className?: string;
}

/**
 * product family's children
 * @param family family data
 * @param className classname
 */
function ProductFamilyChildren({
  family,
  className,
}: PropsWithoutRef<ProductFamilyChildrenProps>) {
  const intl = useIntl();

  if (!family?.rangeChildren?.length && !family?.productChildren?.length) {
    return (
      <div className={styles.listContainer}>
        <Notice
          header={intl.formatMessage({ id: 'range-products-empty-message-1' })}
          type="not-available"
          showIcon={true}
        />
      </div>
    );
  }
  return (
    <div className={cn(styles.container, className)}>
      <p>
        <FormattedMessage id={'family-product-selection-description'} />
      </p>

      <div className={styles.listContainer}>
        {(family?.rangeChildren || []).map((item) => {
          return (
            <ProductListItem
              showRangeDetailsBtn
              key={item.id}
              type={config.RANGE_TYPE}
              product={item}
              className={styles.listItem}
            />
          );
        })}

        {!!family && (family?.productChildren?.length || 0) > 0 && (
          <ProductListItem
            productsForFamily={true}
            className={styles.listItem}
            type={config.FAMILY_TYPE}
            product={family}
          />
        )}
      </div>
    </div>
  );
}

export default ProductFamilyChildren;
