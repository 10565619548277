import classNames from 'classnames';
import { PropsWithChildren, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import MultiSelect from '../../../components/Admin/MultiSelect/MultiSelect';
import { SelectOption } from '../../../models/select-option';
import OutsideClickDetector from '../OutsideClickDetector/OutsideClickDetector';
import styles from './ValidationDataCellMultiSelect.module.scss';

export interface ValidationDataCellMultiSelectProps {
  isEdit?: boolean;
  value: number[];
  valueToLabelFn: (val: number) => string;
  options: SelectOption<number>[];
  onValueChange: (newVal: number[]) => void;
  onDone?: () => void;
  notAvailableValue?: number;
  showTooltip?: boolean;
}

function ValidationDataCellMultiSelect({
  isEdit = false,
  value,
  valueToLabelFn,
  options,
  onValueChange,
  onDone,
  notAvailableValue,
  showTooltip,
}: PropsWithChildren<ValidationDataCellMultiSelectProps>) {
  const intl = useIntl();

  const isEmpty = value.length === 0;

  const actualValue = useMemo(() => {
    const allSelected = value.length === 1 && value.includes(-1);
    return allSelected
      ? options
          .filter((opt) => opt.value !== notAvailableValue)
          .map((opt) => opt.value)
      : value;
  }, [value, options]);

  const displayedValue = useMemo(() => {
    return actualValue
      .slice(0, 5)
      .map((val) => valueToLabelFn(val) || '')
      .join(', ');
  }, [actualValue, valueToLabelFn]);

  return (
    <div className={styles.container}>
      {isEdit ? (
        <OutsideClickDetector
          handleOutsideClick={() => {
            if (onDone) {
              onDone();
            }
          }}
        >
          <MultiSelect
            className={classNames(styles.selectDropdown, styles.multiSelect)}
            optionClassName={styles.selectOption}
            values={value}
            options={options}
            onChange={(value) => {
              if (notAvailableValue === undefined) {
                onValueChange(value);
              }
            }}
            placeholder={intl.formatMessage({ id: 'select-placeholder' })}
            canSearch
            onOpenChange={(open) => {
              if (!open && onDone) {
                onDone();
              }
            }}
            onSelect={(val) => {
              if (notAvailableValue !== undefined) {
                if (val === -1 || val === notAvailableValue) {
                  // select all or N.A
                  onValueChange([val]);
                } else {
                  if (value[0] === notAvailableValue || value[0] === -1) {
                    onValueChange([val]);
                  } else {
                    onValueChange([...value, val]);
                  }
                }
              }
            }}
            onDeselect={(val) => {
              if (notAvailableValue !== undefined) {
                if (val === -1 || val === notAvailableValue) {
                  // deselect all or N.A
                  onValueChange([notAvailableValue]);
                } else {
                  const newValue = value.filter((type: number) => type !== val);
                  onValueChange(
                    newValue.length === 0 ? [notAvailableValue] : newValue,
                  );
                }
              }
            }}
            showTooltip={showTooltip}
          />
        </OutsideClickDetector>
      ) : isEmpty ? (
        <FormattedMessage id="validation-data-not-available" />
      ) : (
        <>
          {displayedValue}
          {actualValue.length > 5 && (
            <div className={styles.moreItems}>
              <FormattedMessage
                id="validation-data-multi-select-more-items"
                values={{ more: actualValue.length - 5 }}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default ValidationDataCellMultiSelect;
