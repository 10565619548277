import { PropsWithChildren } from 'react';
import styles from './StepContentContainer.module.scss';

interface StepContentContainerProps {
  title: string;
}

function StepContentContainer({
  title,
  children,
}: PropsWithChildren<StepContentContainerProps>) {
  return (
    <div className={styles.container}>
      <div className={styles.title}>{title}</div>
      {children}
    </div>
  );
}

export default StepContentContainer;
