import _ from 'lodash';

import {
  ManageFamilyTranslationFormType,
  ManageRangeTranslationFormType,
  TranslationsFormType,
  TranslationType,
} from '../models/admin-form-types';

export const formValuesValid = (
  translations: TranslationsFormType<
    ManageFamilyTranslationFormType | ManageRangeTranslationFormType
  >,
  formKeys: string[],
) => {
  let disable = false;
  const languageIds = Object.keys(translations);
  if (languageIds.length === 0) {
    disable = true;
  }
  for (const languageId of languageIds) {
    if (
      !_.trim(
        translations[languageId][
          formKeys[0] as keyof (
            | ManageFamilyTranslationFormType
            | ManageRangeTranslationFormType
          )
        ],
      ) ||
      (formKeys[1] === 'description' &&
        !_.trim(
          translations[languageId][
            formKeys[1] as keyof (
              | ManageFamilyTranslationFormType
              | ManageRangeTranslationFormType
            )
          ],
        ))
    ) {
      disable = true;
      break;
    }
  }

  return disable;
};

const constructTranslationsPayload = (
  formTranslations: TranslationsFormType<
    ManageFamilyTranslationFormType | ManageRangeTranslationFormType
  >,
  formKeys: string[],
) => {
  const tempTranslations: TranslationsFormType<
    ManageFamilyTranslationFormType | ManageRangeTranslationFormType
  > = _.cloneDeep(formTranslations);
  delete tempTranslations[1];
  const translations: TranslationType[] = [];
  for (const t of Object.keys(tempTranslations)) {
    translations.push({
      propertyName: formKeys[0],
      propertyValue:
        tempTranslations[t][
          formKeys[0] as keyof (
            | ManageFamilyTranslationFormType
            | ManageRangeTranslationFormType
          )
        ],
      languageId: Number(t),
    });

    const value =
      tempTranslations[t][
        formKeys[1] as keyof (
          | ManageFamilyTranslationFormType
          | ManageRangeTranslationFormType
        )
      ];
    if (formKeys[1] === 'shortDescription') {
      if (value && value.length > 0) {
        translations.push({
          propertyName: formKeys[1],
          propertyValue: value,
          languageId: Number(t),
        });
      }
    } else {
      translations.push({
        propertyName: formKeys[1],
        propertyValue: value,
        languageId: Number(t),
      });
    }
  }

  return translations;
};

export const constructTranslationsFromApiResponse = (
  data: any,
  fieldKeys: string[],
) => {
  const translations: TranslationsFormType<any> = {
    1: {
      [fieldKeys[0]]: data[fieldKeys[0]] || '',
      [fieldKeys[1]]: data[fieldKeys[1]] || '',
    },
  };
  for (const translation of data.translations) {
    const languageId = translation.languageId;
    if (!translations[languageId]) {
      translations[languageId] = {} as any;
    }
    translations[languageId] = {
      ...translations[languageId],
      [translation.propertyName]: translation.propertyValue,
    };
  }

  return translations;
};

export default constructTranslationsPayload;
