import styles from './ExpandableItem.module.scss';
import { ReactComponent as EditIcon } from '../../../../../icons/edit-green.svg';
import { ReactComponent as UpArrowIcon } from '../../../../../icons/arrow-up-green.svg';
import { PropsWithChildren, useState } from 'react';
import classNames from 'classnames';

interface ExpandableItemProps {
  title: string;
  onEdit: () => void;
  className?: string;
}

export default function ExpandableItem({
  title,
  onEdit,
  className,
  children,
}: PropsWithChildren<ExpandableItemProps>) {
  const [isExpand, setExpand] = useState(true);

  return (
    <div className={className}>
      <div
        className={styles.header}
        onClick={() => setExpand((isExpand) => !isExpand)}
      >
        <div className={styles.title}>{title}</div>
        <EditIcon
          className={classNames(styles.clickable, styles.editIcon)}
          onClick={onEdit}
        />
        <UpArrowIcon
          className={classNames(
            styles.clickable,
            isExpand ? styles.upArrow : styles.downArrow,
          )}
        />
      </div>
      <div
        className={classNames(styles.body, isExpand ? '' : styles.collapseBody)}
      >
        {children}
      </div>
    </div>
  );
}
