import { useState, useEffect, forwardRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { ReactComponent as WorkHourIcon } from '../../../icons/work-hour.svg';
import styles from './DatePicker.module.scss';
import cn from 'classnames';
import './datepicker-fix.css';

export type DatePickerType = {
  value: Date | null;
  onChange: (value: Date) => void;
  className?: string;
  inputClassName?: string;
  placeholder?: string;
  minDate?: Date | null;
};

const DatePicker = ({
  value,
  onChange,
  className,
  inputClassName,
  placeholder,
  minDate,
}: DatePickerType) => {
  const [dateVal, setDateVal] = useState<Date | null>(null);

  useEffect(() => {
    setDateVal(value);
  }, [value]);

  // eslint-disable-next-line
  const CustomInput = forwardRef((props: any, ref: any) => {
    return (
      <div
        className={cn(
          styles.inputWrapper,
          inputClassName,
          !props.value && styles.placeholder,
          className,
        )}
      >
        <label onClick={props.onClick} ref={ref}>
          {props.value || props.placeholder}
        </label>
        <WorkHourIcon onClick={props.onClick} />
      </div>
    );
  });

  const onDateChange = (value: Date | null) => {
    if (value) {
      setDateVal(value);
      onChange(value);
    }
  };

  return (
    <ReactDatePicker
      showMonthDropdown={true}
      showYearDropdown={true}
      className={cn(styles.container, className)}
      selectsRange={false}
      showPopperArrow={false}
      useWeekdaysShort={true}
      placeholderText={placeholder}
      popperClassName={styles.datePickerPopper}
      popperModifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, 0],
          },
        },
        {
          name: 'preventOverflow',
          options: {
            rootBoundary: 'viewport',
            tether: false,
            altAxis: true,
          },
        },
      ]}
      popperPlacement={'bottom-end'}
      selected={dateVal}
      onChange={onDateChange}
      customInput={<CustomInput />}
      minDate={minDate}
    />
  );
};

export default DatePicker;
