import { PropsWithoutRef } from 'react';
import cn from 'classnames';

import styles from './FilterCheckbox.module.scss';
import Checkbox from '../Checkbox/Checkbox';

interface FilterCheckboxProps {
  title: string;
  className?: string;
  onClick?: () => void;
  checked?: boolean;
}

/**
 * filter container
 * @param title title
 * @param className classname
 * @param children children
 */
function FilterCheckbox({
  title,
  className,
  checked,
  onClick,
}: PropsWithoutRef<FilterCheckboxProps>) {
  return (
    <div className={cn(styles.container, className)}>
      <Checkbox
        className={styles.checkbox}
        checked={checked}
        onClick={onClick}
      />
      <div className={cn(styles.titleContainer)}>{title}</div>
    </div>
  );
}

export default FilterCheckbox;
