import styles from './AboutDetail.module.scss';
import { PropsWithChildren } from 'react';
import classNames from 'classnames';

interface AboutDetailProps {
  title: string;
  className?: string;
}

/**
 * about details
 * @param title title
 * @param className classname
 * @param children children
 */
function AboutDetail({
  title,
  className,
  children,
}: PropsWithChildren<AboutDetailProps>) {
  return (
    <div className={classNames(className, styles.container)}>
      <div className={styles.title}>{title}</div>

      <div className={styles.itemContainer}>{children}</div>
    </div>
  );
}

export default AboutDetail;
