import {
  SheetRow,
  SheetRowError,
  SheetValidationResult,
} from '../models/sheet-validation';

import {
  commaSeperatedPositiveIntegerValidator,
  requiredValidator,
} from './sheet-validator.util';

export const isIndustrial = (sectors: any[]) => {
  if (!sectors || sectors.length === 0) {
    return false;
  }

  if (sectors.some((x) => x === -1)) {
    return true;
  }

  const industrial =
    sectors.find((x) => x.friendlyUrl === 'industrial')?.id || 4;
  return sectors.some((x) => x === industrial);
};

export function validateRow(row: SheetRow | any): SheetRowError {
  const rowError: SheetRowError = {};
  const validators = [
    {
      fieldName: 'Partial',
      validators: [requiredValidator],
    },
    {
      fieldName: 'Product Family',
      validators: [requiredValidator],
    },
    {
      fieldName: 'Fusion Code',
      validators: [
        (strVal: string) =>
          !rowError['Partial'] && !row['Partial']
            ? requiredValidator(strVal)
            : null,
      ],
    },
    { fieldName: 'Product Name', validators: [requiredValidator] },
    {
      fieldName: 'Friendly Url',
      validators: [requiredValidator],
    },
    { fieldName: 'Product Tag line', validators: [requiredValidator] },
    {
      fieldName: 'Product Description',
      validators: [
        (strVal: string) =>
          !rowError['Partial'] && !row['Partial']
            ? requiredValidator(strVal)
            : null,
      ],
    },
    {
      fieldName: 'Master Brand',
      validators: [requiredValidator],
    },
    {
      fieldName: 'Application',
      validators: [
        (strVal: string) =>
          !rowError['Partial'] && !row['Partial']
            ? requiredValidator(strVal)
            : null,
      ],
    },
    {
      fieldName: 'Sector',
      validators: [requiredValidator],
    },
    {
      fieldName: 'Product Type',
      validators: [
        (strVal: string) => {
          return !rowError['Partial'] &&
            !row['Partial'] &&
            isIndustrial(row['Sector'])
            ? requiredValidator(strVal)
            : null;
        },
      ],
    },
    {
      fieldName: 'Country Availability',
      validators: [requiredValidator],
    },
    {
      fieldName: 'Castrol Recommended',
      validators: [requiredValidator],
    },
    {
      fieldName: 'Repository Business',
      validators: [
        (strVal: string) =>
          !rowError['Partial'] && !row['Partial']
            ? requiredValidator(strVal)
            : null,
      ],
    },
    {
      fieldName: 'Viscosity',
      validators: [commaSeperatedPositiveIntegerValidator],
    },
  ];
  for (const { fieldName, validators: fieldValidators } of validators) {
    for (const fieldValidator of fieldValidators) {
      const error = fieldValidator(row[fieldName]);
      if (error) {
        rowError[fieldName] = error;
        break;
      }
    }
  }
  return rowError;
}

export function validateAllRows(rows: SheetRow[]): SheetValidationResult {
  const validationResult: SheetValidationResult = {
    totalError: 0,
    errorByRow: [],
  };
  rows.forEach((row) => {
    const rowError = validateRow(row);
    validationResult.totalError += Object.keys(rowError).length > 0 ? 1 : 0;
    validationResult.errorByRow.push(rowError);
  });
  return validationResult;
}
