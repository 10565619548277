import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import styles from './ShareByEmailModal.module.scss';
import Button from '../../../common/Button/Button';
import Modal from '../../../common/Modal/Modal';
import InputRow from '../../InputRow/InputRow';
import { emailValidator } from '../../../../utils/string.util';

type ShareByEmailModalProps = {
  onClose: () => void;
  onSend?: (value: any) => void;
  forProduct?: boolean;
};

const ShareByEmailModal = ({
  onClose,
  onSend,
  forProduct = false,
}: ShareByEmailModalProps) => {
  // init hooks
  const intl = useIntl();
  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });

  const [fromName, setFromName] = useState('');
  const [fromEmail, setFromEmail] = useState('');
  const [recipientName, setRecipientName] = useState('');
  const [recipientEmail, setRecipientEmail] = useState('');

  const onClickSend = () => {
    onSend &&
      onSend({
        fromName,
        fromEmail,
        recipientName,
        recipientEmail,
      });
    onClose();
  };

  return (
    <Modal
      center={true}
      onClose={onClose}
      className={styles.shareByEmailContainer}
    >
      <div className={styles.container}>
        <h1>
          {forProduct ? (
            <FormattedMessage id={'oem-model-share-product-via-email'} />
          ) : (
            <FormattedMessage id={'oem-model-share-oem-via-email'} />
          )}
        </h1>
        <div className={styles.modalContent}>
          <div className={'columnRow'}>
            <InputRow
              labelId={'oem-model-field-your-name'}
              value={fromName}
              required={true}
              showCancelIcon={true}
              onChange={setFromName}
              tooltip={getTranslatedMessage('your-name-tooltip')}
              errorMessage={getTranslatedMessage('field-required-error')}
            />
          </div>

          <div className={'columnRow'}>
            <InputRow
              labelId={'oem-model-field-email-address'}
              value={fromEmail}
              required={true}
              showCancelIcon={true}
              onChange={setFromEmail}
              tooltip={getTranslatedMessage('your-email-tooltip')}
              errorMessage={getTranslatedMessage('email-invalid-error')}
              customValidator={emailValidator}
            />
          </div>

          <div className={styles.subTitle}>
            <FormattedMessage id={'oem-model-field-recipient-detail'} />
          </div>

          <div className={'columnRow'}>
            <InputRow
              labelId={'oem-model-field-recipient-name'}
              value={recipientName}
              required={true}
              showCancelIcon={true}
              onChange={setRecipientName}
              tooltip={getTranslatedMessage('recipient-name-tooltip')}
              errorMessage={getTranslatedMessage('field-required-error')}
            />
          </div>

          <div className={'columnRow'}>
            <InputRow
              labelId={'oem-model-field-recipient-email-address'}
              value={recipientEmail}
              required={true}
              showCancelIcon={true}
              onChange={setRecipientEmail}
              tooltip={getTranslatedMessage('recipient-email-tooltip')}
              errorMessage={getTranslatedMessage('email-invalid-error')}
              customValidator={emailValidator}
            />
          </div>
        </div>
        <div className={styles.actionBtnContainer}>
          <Button
            onClick={onClickSend}
            color={'green'}
            disabled={
              !fromName ||
              fromName.trim().length === 0 ||
              !fromEmail ||
              emailValidator(fromEmail) ||
              !recipientName ||
              recipientName.trim().length === 0 ||
              !recipientEmail ||
              emailValidator(recipientEmail)
            }
            className={styles.sendButton}
          >
            <FormattedMessage id={'send-label'} />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ShareByEmailModal;
