import styles from './Help.module.scss';
import { PreviousLink } from '../../../models/previous-link';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEffect, useMemo, useState } from 'react';
import NavigationHeader from '../../../components/common/NavigationHeader/NavigationHeader';
import ContainerForDrawer from '../../../components/common/ContainerForDrawer/ContainerForDrawer';
import LeftDrawer from '../../../components/common/LeftDrawer/LeftDrawer';
import RightContainer from '../../../components/common/RightContainer/RightContainer';
import LoadingSpinner from '../../../components/common/LoadingSpinner/LoadingSpinner';
import SearchFilter from '../../../components/common/SearchFilter/SearchFilter';
import Input from '../../../components/common/Input/Input';
import FilterContainer from '../../../components/common/FilterContainer/FilterContainer';
import Select from '../../../components/common/Select/Select';
import HelpTable from './components/HelpTable/HelpTable';
import { ReactComponent as PlusIcon } from '../../../icons/plus.svg';
import { Link } from 'react-router-dom';
import { deleteHelp, getHelps } from '../../../services/ManageHelpService';
import { useDispatch, useSelector } from 'react-redux';
import { Help as HelpType } from '../../../models/help';
import { addToast } from '../../../features/toastSlice';
import { RootStore } from '../../../store';
import _ from 'lodash';
import NoResult from '../../../components/Admin/NoResult/NoResult';

export default function Help() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  // pagination
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  // sorting
  const [sortBy, setSortBy] = useState('captionName');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  // filters
  const [filterCaptionName, setFilterCaptionName] = useState('');
  const [filterType, setFilterType] = useState(0);
  const [helps, setHelps] = useState<HelpType[]>([]);
  const [processing, setProcessing] = useState<boolean>(false);
  const [reload, setReload] = useState(0);

  const onSortChange = (column: string) => {
    if (column !== sortBy) {
      setSortBy(column);
      setSortDirection('asc');
      return;
    }
    setSortDirection((currDirection) =>
      currDirection === 'desc' ? 'asc' : 'desc',
    );
  };

  const allOption = useMemo(
    () => ({
      name: intl.formatMessage({ id: 'all-label' }),
      value: 0,
    }),
    [],
  );

  const removeHelp = (id: number) => {
    setProcessing(true);
    deleteHelp(id)
      .then(() => {
        setReload((prev) => prev + 1);
        dispatch(
          addToast({
            type: 'success',
            title: intl.formatMessage({ id: 'help-delete-success' }),
            message: '',
          }),
        );
      })
      .catch(() => {
        dispatch(
          addToast({
            type: 'error',
            title: intl.formatMessage({ id: 'help-delete-failure' }),
            message: '',
          }),
        );
      })
      .finally(() => setProcessing(false));
  };

  useEffect(() => {
    setLoading(true);
    getHelps({
      page,
      perPage,
      sortBy,
      sortDirection,
      captionName: filterCaptionName,
      helpTypeId: filterType || null,
    })
      .then((res) => {
        setHelps(res.items);
        setTotal(res.total);
      })
      .catch(() => {
        dispatch(
          addToast({
            type: 'error',
            title: intl.formatMessage({ id: 'helps-load-failure' }),
            message: '',
          }),
        );
      })
      .finally(() => setLoading(false));
  }, [
    page,
    perPage,
    sortBy,
    sortDirection,
    filterCaptionName,
    filterType,
    reload,
  ]);

  const { helpTypes } = useSelector((state: RootStore) => state.lookups);
  const helpOptions = useMemo(() => {
    const helpTypesTemp = helpTypes.map((helpType) => ({
      name: helpType.name,
      value: helpType.id,
    }));
    helpTypesTemp.unshift(allOption);
    return helpTypesTemp;
  }, [helpTypes]);

  const breadcrumbLinks: PreviousLink[] = useMemo(
    () => [
      {
        name: intl.formatMessage({ id: 'breadcrumb-home' }),
        to: '/home',
      },
      {
        name: intl.formatMessage({ id: 'header-menu-admin-help' }),
      },
    ],
    [intl],
  );

  return (
    <>
      <NavigationHeader
        breadcrumbLinks={breadcrumbLinks}
        showBottomLine={true}
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        showFilterIcon={true}
      />
      <ContainerForDrawer
        classes={openFilter ? styles.filterOpen : styles.filterClose}
      >
        <LeftDrawer>
          <SearchFilter>
            <FilterContainer
              title={intl.formatMessage({ id: 'name-label' })}
              className={styles.filterGroup}
            >
              <Input
                value={filterCaptionName}
                onChange={_.debounce(setFilterCaptionName, 400)}
                placeholder={intl.formatMessage({
                  id: 'search-caption-name-placeholder',
                })}
              />
            </FilterContainer>

            <FilterContainer
              title={intl.formatMessage({ id: 'type-label' })}
              className={styles.filterGroup}
            >
              <Select
                required={false}
                className={styles.formSelectField}
                value={filterType}
                options={helpOptions}
                onChange={setFilterType}
                placeholder={''}
              />
            </FilterContainer>
          </SearchFilter>
        </LeftDrawer>
        <RightContainer>
          <div className={styles.pageHeader}>
            <h1 className={styles.title}>
              <FormattedMessage id={'manage-help-title'} />
            </h1>
            <Link className={styles.link} to={`/admin/helps/add`}>
              <PlusIcon />
              <FormattedMessage id={'create-new-help'} />
            </Link>
          </div>
          {loading ? (
            <LoadingSpinner />
          ) : helps.length > 0 ? (
            <>
              <HelpTable
                page={page}
                perPage={perPage}
                onPageChange={setPage}
                onPerPageChange={(val: number) => {
                  setPerPage(val);
                  setPage(1);
                }}
                total={total}
                helps={helps}
                sortBy={sortBy}
                sortDirection={sortDirection}
                onSortChange={onSortChange}
                deleteHelp={removeHelp}
              />
            </>
          ) : (
            <NoResult
              titleId={'no-helps-available'}
              messageId={'no-search-result-message'}
            />
          )}
        </RightContainer>
      </ContainerForDrawer>
      {processing && <LoadingSpinner className={'lookupsLoading'} />}
    </>
  );
}
