import config from '../configs/config';
import { HomePromotion } from '../models/home-promotion';
import { MostSearchedProduct } from '../models/most-searched-product';
import { HomeLink } from '../models/home-link';
import { ContactUsRequest } from '../models/contact-us-request';
import { PagingResponse } from '../models/paging-response';
import { GoogleGeocodingResponse } from '../models/google-geocoding-response';
import { apiInstance } from '../api/requestInterceptor';
import axios from 'axios';

const ignoreCacheConfig = {
  cache: {
    ignoreCache: false,
  },
};

/**
 * get promotions for home page
 */
async function getPromotions(): Promise<HomePromotion[]> {
  const res = await apiInstance.get<HomePromotion[]>(
    '/promotions',
    ignoreCacheConfig,
  );
  return res.data;
}

/**
 * get most searched products
 */
async function getMostSearchedProducts(): Promise<MostSearchedProduct[]> {
  const res = await apiInstance.get<PagingResponse<MostSearchedProduct>>(
    `/frequentSearchedProducts`,
    {
      params: {
        page: 1,
        perPage: 6,
      },
      cache: {
        ignoreCache: false,
      },
    },
  );

  return res.data.items;
}

/**
 * get home links
 */
async function getHomeLinks(): Promise<HomeLink[]> {
  const res = await apiInstance.get<HomeLink[]>('/links', ignoreCacheConfig);

  return res.data;
}

/**
 * get current country from ip info
 * @param lat latitude
 * @param long longitude
 */
async function getCurrentCountry(
  lat: number,
  long: number,
): Promise<GoogleGeocodingResponse> {
  const res = await axios.get<GoogleGeocodingResponse>(
    'https://maps.googleapis.com/maps/api/geocode/json',
    {
      params: {
        latlng: `${lat},${long}`,
        key: config.GOOGLE_API_KEY,
      },
    },
  );

  return res.data;
}

/**
 * mock for contact us submit
 * @param values values
 */
async function submitContactUs(values: ContactUsRequest): Promise<void> {
  await apiInstance.post('contactUsRequests', values);
}

export {
  getPromotions,
  getMostSearchedProducts,
  getHomeLinks,
  getCurrentCountry,
  submitContactUs,
};
