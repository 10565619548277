import styles from './MoreButton.module.scss';
import { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowRight } from '../../../icons/arrow-right.svg';
import cn from 'classnames';

interface MoreButtonProps {
  to?: string;
  className?: string;
  onClose?: () => void;
}

/**
 * more button
 * @param children children
 * @param to link to string
 * @param className classname
 * @param onClose close the modal
 */
function MoreButton({
  children,
  to,
  className,
  onClose,
}: PropsWithChildren<MoreButtonProps>) {
  const navigate = useNavigate();

  const handleNavigation = () => {
    if (to) {
      if (onClose) {
        onClose();
      }
      navigate(to);
    }

    return null;
  };

  return (
    <button
      className={cn(styles.button, className)}
      onClick={() => handleNavigation()}
    >
      {children}

      <ArrowRight className={styles.icon} />
    </button>
  );
}

export default MoreButton;
