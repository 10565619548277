import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import Header from '../../../components/Admin/Header/Header';
import SelectACountryOrDistributorForm from '../../../components/Admin/SelectACountryOrDistributorForm/SelectACountryOrDistributorForm';
import DistributorDataForm from '../../../components/Admin/DistributorDataForm/DistributorDataForm';
import GenericManageSkeleton from '../../../components/Admin/GenericManageSkeleton/GenericManageSkeleton';

import { PreviousLink } from '../../../models/previous-link';
import {
  DistributorType,
  StepperItemType,
} from '../../../models/admin-form-types';
import { CountryItem } from '../../../models/lookups';

import {
  getAllDistributors,
  updatePortDistributor,
} from '../../../services/PortDistributorService';

import { RootStore } from '../../../store';

import {
  replaceValues,
  trimSpacesInObj,
  validateURL,
} from '../../../utils/common.util';
import {
  removeEmptyFieldItem,
  validateData,
} from '../../../utils/order-contact-port-distributor';

import { hideLoading, showLoading } from '../../../features/loadingSlice';
import { addToast } from '../../../features/toastSlice';
import { showGenericModal } from '../../../features/genericModalSlice';
import withAdmin from '../../../hoc/withAdmin';

const breadcrumbLinks: PreviousLink[] = [
  {
    name: 'Home',
    to: '/home',
  },
  {
    name: 'Manage Port Distributor',
  },
];
const initialState = {
  distributor: {} as DistributorType,
  name: '',
  address: '',
  portsServed: '',
  url: '',
  friendlyUrl: '',
  contactNames: [],
  emails: [],
  phones: [],
  mobileNumbers: [],
  faxes: [],
  others: [],
};
const ManagePortDistributor = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { countries } = useSelector((state: RootStore) => state.lookups);
  const [step, setStep] = useState(0);
  const [initState, setInitState] = useState(initialState);
  const [formValue, setFormValue] = useState(initialState);
  const [distributors, setDistributors] = useState([]);
  const [isFormValid, setIsFormValid] = useState(true);

  const formValueArrayKeys = [
    'contactNames',
    'emails',
    'phones',
    'mobileNumbers',
    'faxes',
    'others',
  ];

  useEffect(() => {
    dispatch(showLoading());
    getAllDistributors()
      .then((data) => {
        const tempData = _.cloneDeep(data);
        tempData.map((d: any) => {
          formValueArrayKeys.map((key) => {
            const item = _.get(d, key);
            if (!item) {
              d[key] = [];
            }
          });
        });
        setDistributors(tempData);
      })
      .finally(() => dispatch(hideLoading()));
  }, []);

  useEffect(() => {
    const isValid = validateData(formValueArrayKeys, formValue);
    setIsFormValid(isValid);
  }, [
    formValue.contactNames,
    formValue.emails,
    formValue.phones,
    formValue.mobileNumbers,
    formValue.faxes,
    formValue.others,
  ]);

  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });

  const handleValueChange = (fieldKey: string, value: string | string[]) => {
    setFormValue({
      ...formValue,
      [fieldKey]: value,
    });
  };

  const distributorValues = useMemo(() => {
    const tempDistributors = distributors.map(
      (distributor: DistributorType) => {
        const tempDistributor: any = _.cloneDeep(distributor);
        const countryId = distributor.countryId;
        if (countryId) {
          const country = countries.find((c) => c.id === countryId);
          tempDistributor.combinedName = `${country?.name} - ${distributor.name}`;
        }

        return tempDistributor;
      },
    );

    return tempDistributors;
  }, [distributors, countries]);

  /* istanbul ignore next */
  const stepperItems: StepperItemType = [
    {
      label: getTranslatedMessage('select-a-distributor-label'),
      componentToRender: (
        <SelectACountryOrDistributorForm
          titleId="distributor-details-label"
          labelId="select-a-distributor-label"
          selectOptions={distributorValues}
          fieldKey="distributor"
          formValue={formValue}
          optionDisplayKey="combinedName"
          handleValueChange={(fieldKey, value) => {
            let tempValues = _.cloneDeep(value);
            let country = countries.find((c) => c.id === value.countryId);
            if (!country) {
              country = {} as CountryItem;
            }
            tempValues = replaceValues(tempValues, ['portsServed', 'url'], '');
            tempValues.others = tempValues.others || [];
            const formVal = {
              ...formValue,
              distributor: value,
              ...tempValues,
              country,
            };
            setInitState(formVal);
            setFormValue(formVal);
          }}
        />
      ),
    },
    {
      label: getTranslatedMessage('distributor-data-label'),
      componentToRender: (
        <DistributorDataForm
          formValue={formValue}
          handleValueChange={handleValueChange}
        />
      ),
    },
  ];

  const saveDisabled = () => {
    if (step === 0) {
      return !formValue.distributor.id;
    }

    if (step === 1) {
      return (
        !formValue.name.trim() ||
        !formValue.friendlyUrl.trim() ||
        !formValue.address.trim() ||
        (formValue.url?.length > 0 && validateURL(formValue.url)) ||
        !isFormValid
      );
    }

    return false;
  };

  const handleSaveBtnClick = () => {
    if (step === 1) {
      let payload: any = _.omit(formValue, [
        'country',
        'distributor',
        'combinedName',
        'countryName',
      ]);
      payload = removeEmptyFieldItem(formValueArrayKeys, payload);
      payload = trimSpacesInObj(payload);
      dispatch(showLoading());
      updatePortDistributor(payload)
        .then(() => {
          dispatch(
            showGenericModal({
              titleId: 'port-distributor-update-success-label',
              messageId: 'port-distributor-update-success',
              to: '/',
            }),
          );
        })
        .catch((message) => {
          dispatch(
            addToast({
              type: 'error',
              title: getTranslatedMessage('port-distributor-update-failure'),
              message: message || '',
            }),
          );
        })
        .finally(() => dispatch(hideLoading()));
      return;
    }

    setStep(step + 1);
  };

  return (
    <div>
      <Header
        titleId="header-menu-manage-ports-manage-port-distributor"
        breadcrumbLinks={breadcrumbLinks}
      />
      <GenericManageSkeleton
        step={step}
        stepperItems={stepperItems}
        saveBtnDisabled={saveDisabled()}
        showUnsavedChangesModal={!_.isEqual(initState, formValue)}
        onBackBtnClick={() => setStep(step - 1)}
        onSaveBtnClick={handleSaveBtnClick}
      >
        {stepperItems[step].componentToRender}
      </GenericManageSkeleton>
    </div>
  );
};

export default withAdmin(ManagePortDistributor);
