import { useEffect, useState } from 'react';
import styles from './PortPageReportPage.module.scss';
import ContainerForDrawer from '../../../../components/common/ContainerForDrawer/ContainerForDrawer';
import NavigationHeader from '../../../../components/common/NavigationHeader/NavigationHeader';
import { PreviousLink } from '../../../../models/previous-link';
import { FormattedMessage, useIntl } from 'react-intl';
import QuickStatsForm from '../components/QuickStatsForm/QuickStatsForm';
import DateField from '../../../../components/Admin/DateField/DateField';
import classNames from 'classnames';
import { ReactComponent as CancelIcon } from '../../../../icons/cross-circle.svg';
import { getPortPageReport } from '../../../../services/StatsService';
import LoadingSpinner from '../../../../components/common/LoadingSpinner/LoadingSpinner';
import { userRoles } from '../../../../configs/admin-constants';
import Button from '../../../../components/common/Button/Button';
import PortPageReportTable from '../components/PortPageReportTable/PortPageReportTable';
import SelectFormRow from '../../../../components/Admin/SelectFormRow/SelectFormRow';
import { useDispatch } from 'react-redux';
import { addToast } from '../../../../features/toastSlice';

/**
 * Product Report page component
 */
function PortPageReportPage() {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState<any>(null);
  // table sorts
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [sortBy, setSortBy] = useState('name');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [filterClicked, setFilterClicked] = useState(false);

  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });
  const initialState: any = {
    userRole: null,
    startDate: null,
    endDate: null,
  };

  const [formValue, setFormValue] = useState(initialState);

  const breadcrumbLinks: PreviousLink[] = [
    {
      name: 'Report',
      to: '/admin/reports',
    },
    {
      name: 'Port Page Report',
    },
  ];

  const handleValueChange = (
    fieldKey: string,
    value: string | boolean | number[],
  ) => {
    setFormValue({
      ...formValue,
      [fieldKey]: value,
    });
  };

  const clearFilter = () => {
    setFormValue(initialState);
    setPage(1);
    setPerPage(10);
    setReportData(null);
  };

  const onFilter = () => {
    setLoading(true);
    setFilterClicked(true);
    getPortPageReport(
      page,
      perPage,
      sortBy,
      sortDirection,
      formValue.userRole,
      formValue.startDate,
      formValue.endDate,
    )
      .then((res: any) => {
        console.log('s', res);
        setReportData(res.items);
        setPage(res.page);
        setPerPage(res.perPage);
        setTotal(res.total);
      })
      .catch((err) => {
        dispatch(
          addToast({
            type: 'error',
            title: intl.formatMessage({ id: 'report-port-page-failure' }),
            message: err?.message || '',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (filterClicked) {
      onFilter();
    }
  }, [page, perPage, sortBy, sortDirection]);

  const onExport = () => {
    setLoading(true);
    getPortPageReport(
      page,
      perPage,
      sortBy,
      sortDirection,
      formValue.userRole,
      formValue.startDate,
      formValue.endDate,
      true,
    )
      .catch((err) => {
        dispatch(
          addToast({
            type: 'error',
            title: intl.formatMessage({ id: 'report-port-page-failure' }),
            message: err?.message || '',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const roleItems = [
    {
      name: getTranslatedMessage('reports-page-select-all'),
      value: null,
    },
    ...userRoles.map((role: any) => ({
      name: role,
      value: role,
    })),
  ] as any;

  const onSortChange = (column: any) => {
    let changedKey = sortBy;
    let changedDirection: 'asc' | 'desc';

    if (sortBy !== column.key) {
      changedKey = column.key;
      changedDirection = 'desc';
    } else {
      if (sortDirection === 'desc') {
        changedDirection = 'asc';
      } else {
        changedDirection = 'desc';
      }
    }
    setSortBy(changedKey);
    setSortDirection(changedDirection);
  };

  return (
    <>
      <div className={classNames(loading ? styles.loadingOverlayDim : null)}>
        <NavigationHeader breadcrumbLinks={breadcrumbLinks} />
        <ContainerForDrawer>
          <div className={styles.container}>
            <div className={styles.title}>
              <FormattedMessage id={'reports-port-page-report'} />
            </div>
            <div className={styles.contentContainer}>
              <div className={styles.reportDetailContainer}>
                <div>
                  <QuickStatsForm
                    title={getTranslatedMessage('reports-port-page-report')}
                    secondaryTitle={getTranslatedMessage(
                      'reports-port-page-report-description',
                    )}
                  >
                    <div className={styles.formFieldsContainer}>
                      <div className={styles.row}>
                        <SelectFormRow
                          labelId="product-report-page-user-role"
                          required={false}
                          value={formValue.userRole}
                          onChange={(value: any) =>
                            handleValueChange('userRole', value)
                          }
                          placeholder={getTranslatedMessage(
                            'reports-page-select-all',
                          )}
                          options={roleItems}
                          className={styles.formRow}
                        />
                        <DateField
                          labelId="product-report-page-start-date"
                          value={formValue.startDate}
                          onChange={(value: string) =>
                            handleValueChange('startDate', value)
                          }
                          required={false}
                          className={styles.calendarRow}
                          placeholder={getTranslatedMessage(
                            'reports-page-select-date',
                          )}
                        />
                        <DateField
                          labelId="product-report-page-end-date"
                          value={formValue.endDate}
                          onChange={(value: string) =>
                            handleValueChange('endDate', value)
                          }
                          required={false}
                          className={styles.calendarRow}
                          placeholder={getTranslatedMessage(
                            'reports-page-select-date',
                          )}
                          tooltip={getTranslatedMessage(
                            'reports-port-page-report-tooltip',
                          )}
                        />
                      </div>
                    </div>

                    <div className={styles.filterContainer}>
                      <Button
                        color="green"
                        className={classNames('rowButton', styles.filterButton)}
                        onClick={onFilter}
                      >
                        {getTranslatedMessage('reports-page-filter-button')}
                      </Button>
                      <div className={styles.clearFilter} onClick={clearFilter}>
                        <CancelIcon className={styles.cancelIcon} />
                        <div>
                          {getTranslatedMessage('reports-page-clear-filter')}
                        </div>
                      </div>
                    </div>
                  </QuickStatsForm>
                </div>
                {reportData && (
                  <PortPageReportTable
                    reportData={reportData}
                    page={page}
                    perPage={perPage}
                    total={total}
                    sortBy={sortBy}
                    sortDirection={sortDirection}
                    onClickPage={(page) => setPage(page)}
                    onClickPerPage={(perPage) => {
                      setPerPage(perPage);
                      setPage(1);
                    }}
                    exportToExcel={onExport}
                    onSortChange={onSortChange}
                  />
                )}
              </div>
            </div>
          </div>
        </ContainerForDrawer>
      </div>
      {loading && (
        <div className={styles.loadingOverlay}>
          <LoadingSpinner />
        </div>
      )}
    </>
  );
}

export default PortPageReportPage;
