import styles from './AskCookie.module.scss';
import Button from '../Button/Button';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import { PropsWithoutRef } from 'react';

interface AskCookieProps {
  onAccept: () => void;
  onManageClick: () => void;
}

/**
 * ask cookie acceptance
 * @param onAccept accept handler
 */
function AskCookie({
  onAccept,
  onManageClick,
}: PropsWithoutRef<AskCookieProps>) {
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <FormattedMessage id={'cookie-acceptance-title'} />
      </div>

      <div className={styles.text}>
        <FormattedMessage id={'cookie-acceptance-message'} />
      </div>

      <div className={styles.buttonContainer}>
        <Button
          onClick={() => onAccept()}
          color={'green'}
          className={cn(styles.button, styles.acceptButton)}
        >
          <FormattedMessage id={'cookie-acceptance-accept'} />
        </Button>

        <Button
          onClick={() => onManageClick()}
          color={'transparent'}
          className={cn(styles.button, styles.manageButton)}
        >
          <FormattedMessage id={'cookie-acceptance-manage-preference'} />
        </Button>
      </div>
    </div>
  );
}

export default AskCookie;
