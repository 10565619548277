import styles from './Modal.module.scss';
import { PropsWithChildren } from 'react';
import classNames from 'classnames';
import { ReactComponent as Close } from '../../../icons/close.svg';

interface ModalProps {
  onClose: () => void;
  hideClose?: boolean;
  center?: boolean;
  className?: string;
}

/**
 * modal base
 * @param children children
 * @param onClose close handler
 * @param hideClose hide close button
 * @param className the classname
 */
function Modal({
  children,
  onClose,
  hideClose = false,
  center = false,
  className = '',
}: PropsWithChildren<ModalProps>) {
  return (
    <div className={styles.container}>
      <div className={styles.cover} />

      <div
        className={classNames(
          styles.contentContainer,
          center ? styles.contentCenter : '',
        )}
      >
        <div className={classNames(styles.modalContainer, className)}>
          {!hideClose && (
            <Close onClick={() => onClose()} className={styles.close} />
          )}

          {children}
        </div>
      </div>
    </div>
  );
}

export default Modal;
