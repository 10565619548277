import styles from './RightContainer.module.scss';
import { PropsWithChildren } from 'react';
import cn from 'classnames';
/**
 * right container with left drawer
 * @param children children
 */
function RightContainer({ children, nopad }: PropsWithChildren<any>) {
  return (
    <div className={cn(styles.container, nopad && styles.nopad)}>
      {children}
    </div>
  );
}

export default RightContainer;
