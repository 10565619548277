import { apiInstance } from '../api/requestInterceptor';
import { StockpointType } from '../models/admin-form-types';

/**
 * Get stockpoints
 */
async function getStockpoints(): Promise<any> {
  const res = await apiInstance.get<StockpointType>('/stockpoints', {
    cache: {
      ignoreCache: false,
    },
  });

  return res.data;
}

/**
 * Get stockpoint
 */
async function getStockpoint(id: number): Promise<any> {
  const res = await apiInstance.get<StockpointType>(`/stockpoints/${id}`, {
    cache: {
      ignoreCache: true,
    },
  });

  return res.data;
}

/**
 * Add stockpoint
 */
async function addStockpoint(stockpoint: StockpointType): Promise<void> {
  try {
    await apiInstance.post<any>('/stockpoints', stockpoint);
  } catch (e: any) {
    throw e?.response?.data?.message;
  }
}

/**
 * Update stockpoint
 */
async function updateStockpoint(stockpoint: any): Promise<any> {
  try {
    const res = await apiInstance.put<StockpointType>(
      `/stockPoints/${stockpoint.id}`,
      stockpoint,
    );
    return res.data;
  } catch (e: any) {
    throw e?.response?.data?.message;
  }
}

export { getStockpoints, getStockpoint, addStockpoint, updateStockpoint };
