import styles from './UnregisterModal.module.scss';
import { PropsWithoutRef, useState, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import config from '../../../configs/config';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import TextareaRow from '../../Admin/TextareaRow/TextareaRow';
import Radio from '../../Admin/Radio/Radio';
import { ReactComponent as CloseIcon } from '../../../icons/close.svg';

interface UnregisterModalProps {
  onClose: () => void;
  onUnregister?: (value: string) => void;
}

/**
 * Unregister modal
 * @param onClose close handler
 * @param onUnregister unregister handler
 */
function UnregisterModal({
  onClose,
  onUnregister,
}: PropsWithoutRef<UnregisterModalProps>) {
  const [reason, setReason] = useState('');
  const [reasonDetail, setReasonDetail] = useState('');

  const reasonOptions = useMemo(() => {
    return (
      config.UNREGISTER_REASONS?.map((item) => ({
        label: item,
        value: item,
      })) || []
    );
  }, []);

  const unregisterClick = () => {
    const reasonStr =
      reasonDetail && reasonDetail.length > 0 ? reasonDetail : reason;
    onUnregister && onUnregister(reasonStr);
    onClose();
  };

  return (
    <Modal onClose={onClose} hideClose={true} className={styles.modalContainer}>
      <div className={styles.container}>
        <CloseIcon onClick={() => onClose()} className={styles.close} />

        <div className={styles.title}>
          <FormattedMessage id={'unregister-account-label'} />
        </div>

        <div className={styles.description}>
          <FormattedMessage id={'unregister-account-desc'} />
        </div>

        <div className={styles.radioContainer}>
          {reasonOptions.map((d, index: number) => (
            <div
              key={index}
              className={styles.radioItem}
              onClick={() => setReason(d.value)}
            >
              <Radio large={true} checked={reason === d.value} />
              <span
                className={cn(
                  styles.label,
                  reason === d.value && styles.active,
                )}
              >
                {d.label}
              </span>
            </div>
          ))}
        </div>

        <div className={'columnRow'}>
          <TextareaRow
            labelId={'reason-label'}
            required={false}
            value={reasonDetail}
            className={styles.emailBody}
            onChange={(value: string) => setReasonDetail(value)}
          />
        </div>

        <div className={styles.actionRow}>
          <Button
            onClick={() => unregisterClick()}
            color={'green'}
            disabled={!reason}
            className={styles.unregisterBtn}
          >
            <FormattedMessage id={'unregister-label'} />
          </Button>
          <Button
            onClick={() => onClose()}
            color={'green-outline'}
            className={styles.cancelBtn}
          >
            <FormattedMessage id={'cancel-label'} />
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default UnregisterModal;
