import React, { PropsWithoutRef, useEffect, useState } from 'react';
import ReactSlider from 'react-slider';
import classNames from 'classnames';

import styles from './Slider.module.scss';

interface SliderProps {
  minValue: number;
  maxValue: number;
  renderMinLabel: (value: number[]) => React.ReactNode;
  renderMaxLabel: (value: number[]) => React.ReactNode;
  value: number[];
  step?: number;
  onChange: (range: number[]) => void;
  className?: string;
}

function Slider({
  minValue,
  maxValue,
  renderMinLabel,
  renderMaxLabel,
  value,
  step,
  onChange,
  className,
}: PropsWithoutRef<SliderProps>) {
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const [slider, setSlider] = useState<ReactSlider<number[]> | null>(null);

  useEffect(() => {
    if (typeof ResizeObserver === 'undefined' || !container || !slider) {
      return;
    }

    const resizeObserver = new ResizeObserver(() => {
      slider.resize();
    });
    resizeObserver.observe(container);

    return () => {
      resizeObserver.unobserve(container);
    };
  }, [slider, container]);

  return (
    <div
      className={classNames(styles.sliderContainer, className)}
      ref={setContainer}
    >
      <ReactSlider
        className={styles.slider}
        ref={setSlider}
        min={minValue}
        max={maxValue}
        value={value}
        step={step}
        onChange={onChange}
        trackClassName={styles.track}
        renderTrack={({ className: trackClassName, ...otherProps }, state) => (
          <div
            className={classNames(
              trackClassName,
              state.index === 1 && styles.coveredRange,
            )}
            {...otherProps}
          />
        )}
        thumbClassName={styles.thumb}
        renderThumb={({ ...props }) => <div {...props} />}
      />
      <div className={styles.labelContainer}>
        <div className={styles.label}>{renderMinLabel(value)}</div>
        <div className={styles.label}>{renderMaxLabel(value)}</div>
      </div>
    </div>
  );
}

export default Slider;
