import styles from './FilterGroup.module.scss';
import { PropsWithoutRef, useState } from 'react';
import { FilterOption } from '../../../models/filter-option';
import { ReactComponent as ArrowDown } from '../../../icons/arrow-down.svg';
import cn from 'classnames';
import Checkbox from '../Checkbox/Checkbox';
import { FormattedMessage } from 'react-intl';

interface FilterGroupProps {
  open?: boolean;
  title: string;
  filters: FilterOption[];
  selectedOptions: FilterOption[];
  onChange: (filter: FilterOption) => void;
  onReset: () => void;
  className?: string;
  showAll?: boolean;
}

/**
 * filter group
 * @param open set true to make opened on init
 * @param title group title
 * @param filters filter options
 * @param selectedOptions selected options
 * @param onChange on change handler
 * @param onReset filter reset handler
 * @param className classname
 */
function FilterGroup({
  open = false,
  title,
  filters,
  selectedOptions,
  onChange,
  onReset,
  className,
  showAll = true,
}: PropsWithoutRef<FilterGroupProps>) {
  const [opened, setOpened] = useState(open);
  const empty = selectedOptions.length === 0;

  return (
    <div className={className}>
      <div
        onClick={() => setOpened(!opened)}
        className={cn(styles.titleContainer, opened && styles.opened)}
      >
        <div className={styles.title}>{title}</div>

        <ArrowDown className={styles.arrow} />
      </div>

      {opened && (
        <div className={styles.optionsContainer}>
          {showAll && (
            <div onClick={() => onReset()} className={styles.option}>
              <Checkbox checked={empty} className={styles.checkbox} />
              <FormattedMessage id={'all-label'} />
            </div>
          )}

          {filters.map((filter) => {
            const checked = selectedOptions.some(
              (item) => item.value === filter.value,
            );

            return (
              <div
                key={filter.value}
                onClick={() => onChange(filter)}
                className={styles.option}
              >
                <Checkbox checked={checked} className={styles.checkbox} />

                {filter.name}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default FilterGroup;
