import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ToastItem } from '../models/toast-item';

export interface ToastSliceState {
  toast: ToastItem | undefined;
}

/**
 * manage toast state
 */
export const toastSlice = createSlice({
  name: 'toast',
  initialState: {
    toast: undefined,
  } as ToastSliceState,
  reducers: {
    // add toast
    addToast: (state, action: PayloadAction<ToastItem>) => {
      return {
        ...state,
        toast: action.payload,
      };
    },
    // remove toast
    removeToast: (state) => {
      return {
        ...state,
        toast: undefined,
      };
    },
  },
});

export const { addToast, removeToast } = toastSlice.actions;

export default toastSlice.reducer;
