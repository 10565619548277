import styles from './Radio.module.scss';
import { PropsWithoutRef } from 'react';
import cn from 'classnames';

interface RadioProps {
  onClick?: () => void;
  checked?: boolean;
  className?: string;
  disabled?: boolean;
  large?: boolean;
}

/**
 * checkbox
 * @param checked checked state
 * @param onClick on click handler
 * @param className classname
 * @param disabled disabled
 */
function Radio({
  checked = false,
  onClick,
  className,
  disabled = false,
  large = false,
}: PropsWithoutRef<RadioProps>) {
  return (
    <div
      className={cn(
        styles.radio,
        checked && styles.checked,
        large && styles.large,
        className,
        disabled ? styles.disabled : '',
      )}
      onClick={() => onClick && onClick()}
    >
      {checked && (
        <div className={cn(styles.innerCircle, large && styles.large)} />
      )}
    </div>
  );
}

export default Radio;
