import styles from './IconField.module.scss';
import { PropsWithoutRef, ReactElement } from 'react';
import cn from 'classnames';

interface IconFieldProps {
  icon: ReactElement;
  label: string;
  value: string;
  className?: string;
  onClick?: () => void;
}

/**
 * icon field
 * @param icon icon element
 * @param label label
 * @param value value
 * @param className classname
 */
function IconField({
  icon,
  label,
  value,
  className,
  onClick,
}: PropsWithoutRef<IconFieldProps>) {
  return (
    <div
      className={cn(styles.container, className)}
      onClick={() => {
        onClick ? onClick() : null;
      }}
    >
      <div className={styles.icon}>{icon}</div>

      <div className={styles.contentContainer}>
        <div className={styles.label}>{label}</div>

        <div className={styles.value}>{value}</div>
      </div>
    </div>
  );
}

export default IconField;
