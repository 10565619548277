import { PropsWithChildren, useState } from 'react';
import styles from './QuickStatsResult.module.scss';
import { QuickStats } from '../../../../../models/user-stats';
import { useIntl } from 'react-intl';
import Tab from '../Tab/Tab';
import { ReactComponent as DrumGreenIcon } from '../../../../../icons/drum-green.svg';
import { ReactComponent as TotalOemsIcon } from '../../../../../icons/total-oems.svg';
import QuickStatsTable from '../QuickStatsTable/QuickStatsTable';

type QuickStatsFormType = {
  statsData: QuickStats;
  date: string;
};

const QuickStatsResult = ({
  statsData,
  date,
}: PropsWithChildren<QuickStatsFormType>) => {
  const { totalProducts, totalOems } = statsData;
  const [currentTab, setCurrentTab] = useState('Users');
  const intl = useIntl();
  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });

  const tabNames = [
    getTranslatedMessage('reports-page-users'),
    getTranslatedMessage('reports-page-product-page-view'),
    getTranslatedMessage('reports-page-oem-page-view'),
  ];

  return (
    <div className={styles.container}>
      <h2 className={styles.results}>
        {getTranslatedMessage('port-product-page-result')}
      </h2>
      <div className={styles.header}>
        <div className={styles.card}>
          <div className={styles.icon}>
            <DrumGreenIcon />
          </div>
          <div className={styles.right}>
            <p className={styles.title}>
              {getTranslatedMessage('reports-page-total-products')}
            </p>
            <p className={styles.total}>{totalProducts}</p>
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.icon}>
            <TotalOemsIcon />
          </div>
          <div className={styles.right}>
            <p className={styles.title}>
              {getTranslatedMessage('reports-page-total-oems')}
            </p>
            <p className={styles.total}>{totalOems}</p>
          </div>
        </div>
      </div>
      <div className={styles.details}>
        <h3 className={styles.detailsTitle}>
          {getTranslatedMessage('reports-page-view-results-by')}
        </h3>

        <Tab
          currentTab={currentTab}
          tabNames={tabNames}
          onClickTab={setCurrentTab}
        />
        <QuickStatsTable statsData={statsData} type={currentTab} date={date} />
      </div>
    </div>
  );
};

export default QuickStatsResult;
