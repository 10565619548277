import styles from './ProductFamilyPage.module.scss';
import NavigationHeader from '../../components/common/NavigationHeader/NavigationHeader';
import ContainerForDrawer from '../../components/common/ContainerForDrawer/ContainerForDrawer';
import LeftDrawer from '../../components/common/LeftDrawer/LeftDrawer';
import RightContainer from '../../components/common/RightContainer/RightContainer';
import { useParams } from 'react-router';
import { useEffect, useMemo, useState } from 'react';
import { PreviousLink } from '../../models/previous-link';
import { useDispatch, useSelector } from 'react-redux';
import { clearProducts } from '../../features/productComparisonSlice';
import { getFamilyDetail } from '../../services/ProductService';
import LoadingSpinner from '../../components/common/LoadingSpinner/LoadingSpinner';
import SearchFilter from '../../components/common/SearchFilter/SearchFilter';
import { FormattedMessage, useIntl } from 'react-intl';
import DetailHeader from '../../components/common/DetailHeader/DetailHeader';
import ProductFamilyChildren from '../../components/ProductFamilyPage/ProductFamilyChildren/ProductFamilyChildren';
import { RootStore } from '../../store';
import ComparisonListModal from '../../components/common/ComparisonListModal/ComparisonListModal';
import ComparisonModal from '../../components/common/ComparisonModal/ComparisonModal';
import { useLocation } from 'react-router-dom';
import SearchModal from '../../components/HomePage/SearchModal/SearchModal';
import withSectorCountry from '../../hoc/withSectorCountry';
import {
  changeRangeFilter,
  clearAllFilters,
  clearCombinedMultiSelectFilter,
  clearMultiSelectFilter,
  setFilterableFamilyDetails,
  toggleCheckboxFilter,
  toggleCombinedMultiSelectFilter,
  toggleMultiSelectFilterById,
} from '../../features/familyFilterSlice';
import MultiSelectFilterGroup from '../../components/common/FilterGroup/MultiSelectFilterGroup/MultiSelectFilterGroup';
import FilterCheckbox from '../../components/common/FilterCheckbox/FilterCheckbox';
import { LookupItemDictionary } from '../../models/lookups';
import CombinedMultiSelectFilterGroup from '../../components/common/FilterGroup/CombinedMultiSelectFilterGroup/CombinedMultiSelectFilterGroup';
import FilterContainer from '../../components/common/FilterContainer/FilterContainer';
import Slider from '../../components/common/Slider/Slider';
import useWindowDimensions from '../../hooks/useWindowDimensions ';
import NotFound from '../Admin/NotFound/NotFound';

/**
 * product family page
 * @returns {JSX.Element}
 */
function ProductFamilyPage() {
  // init hooks
  const { friendlyUrl } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const intl = useIntl();

  const { width } = useWindowDimensions();

  // load data from store.
  const { products } = useSelector(
    (state: RootStore) => state.productComparison,
  );
  const { family, filteredFamily, filters } = useSelector(
    (state: RootStore) => {
      return state.familyFilter;
    },
  );
  const { hasWelcomeInfo, sector } = useSelector(
    (state: RootStore) => state.welcomeInfo,
  );
  const lookups = useSelector((state: RootStore) => state.lookups);

  // init states.
  const [notFound, setNotFound] = useState(false);
  const [loading, setLoading] = useState(false);
  const [comparisonOpened, setComparisonOpened] = useState(false);
  const path = `${location.pathname}?${location.search}`;
  const [previousPath, setPreviousPath] = useState(path);
  const [searchOpened, setSearchOpened] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);

  // init local variables.
  const productSelected = products.length > 0;
  const breadcrumbLinks: PreviousLink[] = [
    {
      name: 'Home',
      to: '/home',
    },
    {
      name: family?.name || '',
    },
  ];

  // local functions.

  /**
   * open comparison modal
   */
  const openComparison = () => {
    setComparisonOpened(true);
  };

  /**
   * close comparison modal
   */
  const closeComparison = () => {
    setComparisonOpened(false);
  };

  // effects

  /**
   * Get server data when lookup load completed and welcome data already stored.
   */
  useEffect(() => {
    // load data when welcome info saved.
    if (hasWelcomeInfo && lookups?.countries && lookups.countries.length > 0) {
      // clear products on load
      dispatch(clearProducts());
      dispatch(clearAllFilters());
      setNotFound(false);
      setLoading(true);
      getFamilyDetail(friendlyUrl as string)
        .then((res) =>
          dispatch(setFilterableFamilyDetails({ data: res, lookups })),
        )
        .catch(() => {
          setNotFound(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [dispatch, friendlyUrl, hasWelcomeInfo, lookups.countries, sector]);

  // check path changes
  useEffect(() => {
    if (path !== previousPath) {
      setPreviousPath(path);
      setSearchOpened(false);
    }
  }, [path, previousPath]);

  const {
    categoriesById,
    subCategoriesById,
    applicationsById,
    corrosionProtectionsById,
    metalTypesById,
    nlgisById,
    subSectorsById,
    viscosityIndicesById,
    baseOilTypeGenericsById,
    baseOilTypeSpecificsById,
    thickenerGenericsById,
    thickenerSpecificsById,
  } = useSelector((state: RootStore) => state.lookups);

  const rangeLookupDict: LookupItemDictionary = useMemo(() => {
    return (family?.rangeChildren || []).reduce<LookupItemDictionary>(
      (idMap, item) => {
        idMap[item.id] = {
          id: item.id,
          name: item.name,
        };
        return idMap;
      },
      {},
    );
  }, [family]);

  const oilTypeLookupDicts = useMemo(
    () => [baseOilTypeGenericsById, baseOilTypeSpecificsById],
    [baseOilTypeGenericsById, baseOilTypeSpecificsById],
  );

  const thickenerLookupDicts = useMemo(
    () => [thickenerGenericsById, thickenerSpecificsById],
    [thickenerGenericsById, thickenerSpecificsById],
  );

  const shouldDisplayRangeFilter = useMemo(
    () => filters.range.options.length > 1 || filters.range.value.length > 0,
    [filters.range.options],
  );

  const shouldDisplayCategoryFilter = useMemo(
    () =>
      filters.category.options.length > 1 || filters.category.value.length > 0,
    [filters.category.options],
  );

  const shouldDisplayApplicationFilter = useMemo(
    () =>
      filters.application.options.length > 1 ||
      filters.application.value.length > 0,
    [filters.application.options],
  );

  const shouldDisplaySubCategoryFilter = useMemo(
    () =>
      filters.subCategory.options.length > 1 ||
      filters.subCategory.value.length > 0,
    [filters.subCategory.options],
  );

  const shouldDisplayMetalTypeFilter = useMemo(
    () =>
      filters.metalType.options.length > 1 ||
      filters.metalType.value.length > 0,
    [filters.metalType.options],
  );
  const shouldDisplayNlgiFilter = useMemo(
    () => filters.nlgi.options.length > 1 || filters.nlgi.value.length > 0,
    [filters.nlgi.options],
  );

  const shouldDisplaySubSectorFilter = useMemo(
    () =>
      filters.subSector.options.length > 1 ||
      filters.subSector.value.length > 0,
    [filters.subSector.options],
  );

  const shouldDisplayViscosityFilter = useMemo(
    () =>
      filters.viscosity.options.length > 1 ||
      filters.viscosity.value.length > 0,
    [filters.viscosity.options],
  );

  const shouldDisplayViscosityIndexFilter = useMemo(
    () =>
      filters.viscosityIndex.options.length > 1 ||
      filters.viscosityIndex.value.length > 0,
    [filters.viscosityIndex.options],
  );

  const shouldDisplayCorrosionProtectionFilter = useMemo(
    () =>
      filters.corrosionProtection.options.length > 1 ||
      filters.corrosionProtection.value.length > 0,
    [filters.corrosionProtection.options],
  );

  const shouldDisplayOilTypeFilter = useMemo(
    () => !!filters.oilType.options.find((opts) => opts.length > 0),
    [filters.oilType.options],
  );

  const shouldDisplayThickenerFilter = useMemo(
    () => !!filters.thickener.options.find((opts) => opts.length > 0),
    [filters.thickener.options],
  );

  return (
    <>
      {notFound && <NotFound />}
      {!notFound && (
        <div>
          <NavigationHeader
            onInputFocus={() => setSearchOpened(true)}
            breadcrumbLinks={breadcrumbLinks}
            openFilter={openFilter}
            setOpenFilter={setOpenFilter}
            showFilterIcon={true}
          />

          <ContainerForDrawer
            classes={openFilter ? styles.filterOpen : styles.filterClose}
          >
            <LeftDrawer>
              {loading ? (
                <LoadingSpinner className={styles.loading} />
              ) : (
                <SearchFilter clear={() => dispatch(clearAllFilters())}>
                  {shouldDisplayRangeFilter && (
                    <MultiSelectFilterGroup
                      className={styles.filterGroup}
                      title={intl.formatMessage({ id: 'range-label' })}
                      filterOfKey={filters.range}
                      lookupDict={rangeLookupDict}
                      onFilterChange={(filter) => {
                        dispatch(
                          toggleMultiSelectFilterById({
                            key: 'range',
                            filter: Number(filter.value),
                          }),
                        );
                      }}
                      onFilterReset={() =>
                        dispatch(
                          clearMultiSelectFilter({
                            key: 'range',
                          }),
                        )
                      }
                      open={width < 1024}
                    />
                  )}

                  {shouldDisplayCategoryFilter && (
                    <MultiSelectFilterGroup
                      className={styles.filterGroup}
                      title={intl.formatMessage({
                        id: 'product-category-label',
                      })}
                      filterOfKey={filters.category}
                      lookupDict={categoriesById}
                      onFilterChange={(filter) => {
                        dispatch(
                          toggleMultiSelectFilterById({
                            key: 'category',
                            filter: Number(filter.value),
                          }),
                        );
                      }}
                      onFilterReset={() =>
                        dispatch(
                          clearMultiSelectFilter({
                            key: 'category',
                          }),
                        )
                      }
                    />
                  )}

                  {shouldDisplayApplicationFilter && (
                    <MultiSelectFilterGroup
                      className={styles.filterGroup}
                      title={intl.formatMessage({ id: 'application-label' })}
                      filterOfKey={filters.application}
                      lookupDict={applicationsById}
                      onFilterChange={(filter) => {
                        dispatch(
                          toggleMultiSelectFilterById({
                            key: 'application',
                            filter: Number(filter.value),
                          }),
                        );
                      }}
                      onFilterReset={() =>
                        dispatch(
                          clearMultiSelectFilter({
                            key: 'application',
                          }),
                        )
                      }
                    />
                  )}

                  {shouldDisplaySubCategoryFilter && (
                    <MultiSelectFilterGroup
                      className={styles.filterGroup}
                      title={intl.formatMessage({ id: 'subcategory-label' })}
                      filterOfKey={filters.subCategory}
                      lookupDict={subCategoriesById}
                      onFilterChange={(filter) => {
                        dispatch(
                          toggleMultiSelectFilterById({
                            key: 'subCategory',
                            filter: Number(filter.value),
                          }),
                        );
                      }}
                      onFilterReset={() =>
                        dispatch(
                          clearMultiSelectFilter({
                            key: 'subCategory',
                          }),
                        )
                      }
                    />
                  )}

                  {filters.castrolRecommended.hasDifferentValue && (
                    <FilterCheckbox
                      className={styles.filterGroup}
                      title={intl.formatMessage({
                        id: 'castrol-recommended-label',
                      })}
                      checked={filters.castrolRecommended.value}
                      onClick={() => {
                        dispatch(
                          toggleCheckboxFilter({
                            key: 'castrolRecommended',
                          }),
                        );
                      }}
                    />
                  )}

                  {filters.esterEpAdditives.hasDifferentValue && (
                    <FilterCheckbox
                      className={styles.filterGroup}
                      title={intl.formatMessage({
                        id: 'ester-ep-additives-label',
                      })}
                      checked={filters.esterEpAdditives.value}
                      onClick={() => {
                        dispatch(
                          toggleCheckboxFilter({
                            key: 'esterEpAdditives',
                          }),
                        );
                      }}
                    />
                  )}

                  {filters.foodGrade.hasDifferentValue && (
                    <FilterCheckbox
                      className={styles.filterGroup}
                      title={intl.formatMessage({
                        id: 'food-grade-filter-label',
                      })}
                      checked={filters.foodGrade.value}
                      onClick={() => {
                        dispatch(
                          toggleCheckboxFilter({
                            key: 'foodGrade',
                          }),
                        );
                      }}
                    />
                  )}

                  {shouldDisplayMetalTypeFilter && (
                    <MultiSelectFilterGroup
                      className={styles.filterGroup}
                      title={intl.formatMessage({ id: 'metal-type-label' })}
                      filterOfKey={filters.metalType}
                      lookupDict={metalTypesById}
                      onFilterChange={(filter) => {
                        dispatch(
                          toggleMultiSelectFilterById({
                            key: 'metalType',
                            filter: Number(filter.value),
                          }),
                        );
                      }}
                      onFilterReset={() =>
                        dispatch(
                          clearMultiSelectFilter({
                            key: 'metalType',
                          }),
                        )
                      }
                    />
                  )}

                  {filters.multiMetalsSuitable.hasDifferentValue && (
                    <FilterCheckbox
                      className={styles.filterGroup}
                      title={intl.formatMessage({
                        id: 'multi-metals-suitable-label',
                      })}
                      checked={filters.multiMetalsSuitable.value}
                      onClick={() => {
                        dispatch(
                          toggleCheckboxFilter({
                            key: 'multiMetalsSuitable',
                          }),
                        );
                      }}
                    />
                  )}

                  {shouldDisplayNlgiFilter && (
                    <MultiSelectFilterGroup
                      className={styles.filterGroup}
                      title={intl.formatMessage({ id: 'nlgi-label' })}
                      filterOfKey={filters.nlgi}
                      lookupDict={nlgisById}
                      onFilterChange={(filter) => {
                        dispatch(
                          toggleMultiSelectFilterById({
                            key: 'nlgi',
                            filter: Number(filter.value),
                          }),
                        );
                      }}
                      onFilterReset={() =>
                        dispatch(
                          clearMultiSelectFilter({
                            key: 'nlgi',
                          }),
                        )
                      }
                    />
                  )}

                  {shouldDisplayOilTypeFilter && (
                    <CombinedMultiSelectFilterGroup
                      className={styles.filterGroup}
                      title={intl.formatMessage({ id: 'oil-type-label' })}
                      filterOfKey={filters.oilType}
                      lookupDicts={oilTypeLookupDicts}
                      onFilterChange={(filter) =>
                        dispatch(
                          toggleCombinedMultiSelectFilter({
                            key: 'oilType',
                            idMap: JSON.parse(filter.value),
                          }),
                        )
                      }
                      onFilterReset={() =>
                        dispatch(
                          clearCombinedMultiSelectFilter({
                            key: 'oilType',
                          }),
                        )
                      }
                    />
                  )}

                  {filters.operatingTemperature.min !== null &&
                    filters.operatingTemperature.max !== null &&
                    filters.operatingTemperature.value !== null && (
                      <FilterContainer
                        className={styles.filterGroup}
                        title={intl.formatMessage({
                          id: 'operating-temperature-label',
                        })}
                      >
                        <Slider
                          minValue={filters.operatingTemperature.min}
                          maxValue={filters.operatingTemperature.max}
                          renderMinLabel={(value) => (
                            <FormattedMessage
                              id={'min-temperature-label'}
                              values={{ value: value[0] }}
                            />
                          )}
                          renderMaxLabel={(value) => (
                            <FormattedMessage
                              id={'max-temperature-label'}
                              values={{ value: value[1] }}
                            />
                          )}
                          value={filters.operatingTemperature.value}
                          onChange={(newRange) => {
                            dispatch(
                              changeRangeFilter({
                                key: 'operatingTemperature',
                                value: newRange,
                              }),
                            );
                          }}
                        />
                      </FilterContainer>
                    )}

                  {shouldDisplaySubSectorFilter && (
                    <MultiSelectFilterGroup
                      className={styles.filterGroup}
                      title={intl.formatMessage({ id: 'sub-sector-label' })}
                      filterOfKey={filters.subSector}
                      lookupDict={subSectorsById}
                      onFilterChange={(filter) => {
                        dispatch(
                          toggleMultiSelectFilterById({
                            key: 'subSector',
                            filter: Number(filter.value),
                          }),
                        );
                      }}
                      onFilterReset={() =>
                        dispatch(
                          clearMultiSelectFilter({
                            key: 'subSector',
                          }),
                        )
                      }
                    />
                  )}

                  {shouldDisplayThickenerFilter && (
                    <CombinedMultiSelectFilterGroup
                      className={styles.filterGroup}
                      title={intl.formatMessage({ id: 'thickener-label' })}
                      filterOfKey={filters.thickener}
                      lookupDicts={thickenerLookupDicts}
                      onFilterChange={(filter) =>
                        dispatch(
                          toggleCombinedMultiSelectFilter({
                            key: 'thickener',
                            idMap: JSON.parse(filter.value),
                          }),
                        )
                      }
                      onFilterReset={() =>
                        dispatch(
                          clearCombinedMultiSelectFilter({
                            key: 'thickener',
                          }),
                        )
                      }
                    />
                  )}

                  {shouldDisplayViscosityFilter && (
                    <MultiSelectFilterGroup
                      className={styles.filterGroup}
                      title={intl.formatMessage({ id: 'viscosity-label' })}
                      filterOfKey={filters.viscosity}
                      onFilterChange={(filter) => {
                        dispatch(
                          toggleMultiSelectFilterById({
                            key: 'viscosity',
                            filter: Number(filter.value),
                          }),
                        );
                      }}
                      onFilterReset={() =>
                        dispatch(
                          clearMultiSelectFilter({
                            key: 'viscosity',
                          }),
                        )
                      }
                    />
                  )}

                  {shouldDisplayViscosityIndexFilter && (
                    <MultiSelectFilterGroup
                      className={styles.filterGroup}
                      title={intl.formatMessage({
                        id: 'viscosity-index-label',
                      })}
                      filterOfKey={filters.viscosityIndex}
                      lookupDict={viscosityIndicesById}
                      onFilterChange={(filter) => {
                        dispatch(
                          toggleMultiSelectFilterById({
                            key: 'viscosityIndex',
                            filter: Number(filter.value),
                          }),
                        );
                      }}
                      onFilterReset={() =>
                        dispatch(
                          clearMultiSelectFilter({
                            key: 'viscosityIndex',
                          }),
                        )
                      }
                    />
                  )}

                  {shouldDisplayCorrosionProtectionFilter && (
                    <MultiSelectFilterGroup
                      className={styles.filterGroup}
                      title={intl.formatMessage({
                        id: 'corrosion-protection-label',
                      })}
                      filterOfKey={filters.corrosionProtection}
                      lookupDict={corrosionProtectionsById}
                      onFilterChange={(filter) => {
                        dispatch(
                          toggleMultiSelectFilterById({
                            key: 'corrosionProtection',
                            filter: Number(filter.value),
                          }),
                        );
                      }}
                      onFilterReset={() =>
                        dispatch(
                          clearMultiSelectFilter({
                            key: 'corrosionProtection',
                          }),
                        )
                      }
                    />
                  )}

                  {filters.formulatedWithoutSilicate.hasDifferentValue && (
                    <FilterCheckbox
                      className={styles.filterGroup}
                      title={intl.formatMessage({
                        id: 'formulated-without-silicate-label',
                      })}
                      checked={filters.formulatedWithoutSilicate.value}
                      onClick={() => {
                        dispatch(
                          toggleCheckboxFilter({
                            key: 'formulatedWithoutSilicate',
                          }),
                        );
                      }}
                    />
                  )}

                  {filters.superiorBiodegradation.hasDifferentValue && (
                    <FilterCheckbox
                      className={styles.filterGroup}
                      title={intl.formatMessage({
                        id: 'superior-biodegradation-label',
                      })}
                      checked={filters.superiorBiodegradation.value}
                      onClick={() => {
                        dispatch(
                          toggleCheckboxFilter({
                            key: 'superiorBiodegradation',
                          }),
                        );
                      }}
                    />
                  )}
                </SearchFilter>
              )}
            </LeftDrawer>

            <RightContainer>
              {loading ? (
                <LoadingSpinner className={styles.loading} />
              ) : (
                family && (
                  <div>
                    <DetailHeader
                      title={`${family.name} ${intl.formatMessage({
                        id: 'family-label',
                      })}`}
                      description={family.description}
                      id={family.id}
                      page={'Family'}
                    />

                    <ProductFamilyChildren
                      className={styles.childrenContainer}
                      family={filteredFamily}
                    />
                  </div>
                )
              )}
            </RightContainer>
          </ContainerForDrawer>

          {productSelected && !comparisonOpened && (
            <ComparisonListModal
              onOpenClick={() => openComparison()}
              products={products}
            />
          )}

          {comparisonOpened && (
            <ComparisonModal onClose={() => closeComparison()} />
          )}

          {searchOpened && (
            <SearchModal onClose={() => setSearchOpened(false)} />
          )}
        </div>
      )}
    </>
  );
}

export default withSectorCountry(ProductFamilyPage);
