/* eslint-disable no-debugger */
import { PropsWithoutRef } from 'react';
import { FormattedMessage } from 'react-intl';

import ProductValidationCardMobile from '../ProductValidationCardMobile/ProductValidationCardMobile';
import ProductValidationTable from '../ProductValidationTable/ProductValidationTable';
import Button from '../Button/Button';
import { ProductValidationColumn } from '../../../models/table-column';
import styles from './ValidateData.module.scss';
import { SheetValidationResult } from '../../../models/sheet-validation';

interface ValidateDataProps {
  save: () => void;
  cancel: () => void;
  data: any[];
  setData: (x: any[]) => void;
  columns: ProductValidationColumn[];
  tableRowClassName?: string;
  errorCount: number;
  uniquePair?: boolean;
  backendErrors?: any[];
  validationErrors?: SheetValidationResult;
}

function ValidateData({
  save,
  cancel,
  data = [],
  setData,
  columns,
  tableRowClassName,
  errorCount,
  uniquePair = true,
  backendErrors = [],
  validationErrors,
}: PropsWithoutRef<ValidateDataProps>) {
  const descriptionValues = {
    count: errorCount,
    b: (value: string[]) => <b>{value}</b>,
  };

  const setDataAtIndex = (newDataItem: any, index: number) => {
    if (index >= data.length) return;
    setData(data.map((row, i) => (i === index ? newDataItem : row)));
  };

  return (
    <>
      <div className={styles.container}>
        <h2>
          <FormattedMessage id={'validate-data'} />
        </h2>
        <div className={styles.divider} />
        <div className={styles.validationErrCount}>
          <FormattedMessage
            id={'validation-data-error'}
            values={descriptionValues}
          />
        </div>
        {!uniquePair && (
          <div className={styles.uniquePair}>
            <FormattedMessage id={'manage-localised-unique-error'} />
          </div>
        )}
        {backendErrors.map((error) => (
          <div
            key={`${error.row}_${error.property}`}
            className={styles.backendError}
          >
            Row {error.row} - {error.property} - {error.message}
          </div>
        ))}
        {validationErrors?.errorByRow.map((rowError, rowId) => {
          return Object.keys(rowError).map((key, ind) => (
            <div
              key={`row_${rowId}_prop${ind}`}
              className={styles.backendError}
            >
              Row {rowId + 1} - {key} - {rowError[key]?.errorName}
            </div>
          ));
        })}
        <div className={styles.mobileOnly}>
          {data.map((product, index) => (
            <ProductValidationCardMobile
              key={index}
              rowIndex={index}
              product={product}
              setDataAtIndex={(newData) => setDataAtIndex(newData, index)}
              columns={columns}
              rowError={validationErrors?.errorByRow[index]}
            />
          ))}
        </div>
        <div className={styles.desktopOnly}>
          <ProductValidationTable
            data={data}
            setDataAtIndex={setDataAtIndex}
            columns={columns}
            tableRowClassName={tableRowClassName}
            validationErrors={validationErrors}
          />
        </div>
      </div>
      <div className={styles.buttons}>
        <Button onClick={cancel} color={'gray'} className={styles.button}>
          <FormattedMessage id={'cancel-label'} />
        </Button>
        <Button
          onClick={save}
          color={'green'}
          className={styles.button}
          disabled={data.length === 0 || errorCount !== 0}
        >
          <FormattedMessage id={'save-label'} />
        </Button>
      </div>
    </>
  );
}

export default ValidateData;
