import { useIntl } from 'react-intl';

import ContactDetails from '../ContactDetails/ContactDetails';
import FormWrapper from '../FormWrapper/FormWrapper';
import TextareaRow from '../TextareaRow/TextareaRow';

import { ManageOrderContactProps } from '../../../models/admin-form-types';
import { contactFields } from '../../../configs/admin-constants';

const OrderContactDataForm = ({
  formValue,
  handleValueChange,
}: ManageOrderContactProps) => {
  const intl = useIntl();

  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });
  const title = getTranslatedMessage('update-order-contact-label');
  const placeholder = getTranslatedMessage('input-placeholder');
  return (
    <FormWrapper title={title} message={formValue.country?.name || ''}>
      <TextareaRow
        labelId="address-label"
        value={formValue.address}
        onChange={(value) => handleValueChange('address', value)}
        placeholder={placeholder}
        tooltip={getTranslatedMessage('address-tooltip')}
      />
      <ContactDetails
        fields={contactFields}
        formValue={formValue}
        handleValueChange={handleValueChange}
      />
    </FormWrapper>
  );
};

export default OrderContactDataForm;
