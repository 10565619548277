import styles from './MenuOption.module.scss';
import { PropsWithChildren, useState, ReactElement } from 'react';
import cn from 'classnames';

interface MenuOptionProps {
  className?: string;
  label: string;
  icon: ReactElement;
  iconActive: ReactElement;
  onOptionClick?: () => void;
}

/**
 * dropdown buttons for user profile section in main header
 * @param value value
 * @param className class name
 * @param options options
 * @param onChange change handler
 * @param children children
 */
function MenuOption({
  className,
  label,
  icon,
  iconActive,
  onOptionClick,
}: PropsWithChildren<MenuOptionProps>) {
  const [isHover, setIsHover] = useState(false);

  return (
    <div className={cn(styles.container, className)}>
      <div
        onClick={onOptionClick}
        className={cn(
          styles.innerContainer,
          isHover ? styles.optionActive : '',
        )}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        {isHover ? iconActive : icon}
        <div className={cn(styles.label, isHover ? styles.labelActive : '')}>
          {label}
        </div>
      </div>
    </div>
  );
}

export default MenuOption;
