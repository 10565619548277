import { isNull, isUndefined } from 'lodash';
import { LookupItem } from '../models/lookups';

export function getBoolValue(
  strValue: string,
  reverseBoolItems: Record<
    string,
    {
      name: string;
      value: boolean;
    }
  >,
  defaultValue?: boolean,
): boolean | null {
  if (!strValue) {
    return !isNull(defaultValue) && !isUndefined(defaultValue)
      ? defaultValue
      : null;
  }
  const matchedItem = reverseBoolItems[strValue];
  if (matchedItem) {
    return matchedItem.value;
  } else {
    return !isNull(defaultValue) && !isUndefined(defaultValue)
      ? defaultValue
      : null;
  }
}

export function getSingleSelectValue(
  strValue: string,
  reverseLookup: Record<string, LookupItem>,
  defaultValue?: string,
): number | null {
  if (!strValue) {
    return defaultValue ? reverseLookup[defaultValue].id : null;
  }
  const matchedItem = reverseLookup[strValue];
  if (matchedItem) {
    return matchedItem.id;
  } else {
    return defaultValue ? reverseLookup[defaultValue].id : null;
  }
}

export function getMultiSelectValue(
  strValue: string,
  reverseLookup: Record<string, LookupItem>,
  hasNA = false,
): number[] {
  if (!strValue) {
    return [];
  }
  const value: number[] = [];
  const names = strValue.split(',');
  for (const name of names) {
    const trimmedName = name.trim();
    const matchedItem = reverseLookup[trimmedName];
    if (matchedItem) {
      value.push(matchedItem.id);
    }
  }
  const lookupLength = Object.values(reverseLookup).length;
  const selectAllLength = hasNA ? lookupLength - 1 : lookupLength;
  if (value.length === selectAllLength) {
    return [-1];
  } else {
    return value;
  }
}

export function getMandatoryTextValue(strValue: string): string {
  const trimmed = strValue.trim();
  if (trimmed) {
    return trimmed;
  } else {
    return '';
  }
}

export function getNumberValue(strValue: string): number | null {
  const trimmed = strValue.trim();
  if (!strValue) {
    return null;
  }
  return Number(trimmed);
}

export function getNumberArrayValue(strValue: string): number[] {
  const trimmed = strValue.trim();
  return trimmed.split(',').map(Number);
}
