import { saveAs } from 'file-saver';

import { apiInstance } from '../api/requestInterceptor';
import { CaseStudy, CaseStudyFull } from '../models/case-study';

/**
 * Get case study for a product
 */
async function getCaseStudy(productId: number): Promise<CaseStudyFull[]> {
  const res = await apiInstance.get<CaseStudyFull[]>('/caseStudies', {
    params: {
      productId,
    },
  });

  return res.data;
}

/**
 * delete case study
 */
async function deleteCaseStudy(caseStudyId: number) {
  await apiInstance.delete<void>(`/caseStudies/${caseStudyId}`);
}

/**
 * create case study
 */
async function createCaseStudy(caseStudy: CaseStudy): Promise<any> {
  const res = await apiInstance.post<any>('/caseStudies', caseStudy);
  return res.data;
}

/**
 * Download document associated with case study
 */
async function downloadCaseStudyDocument(id: number): Promise<any> {
  const res = await apiInstance.get<any>(`/caseStudies/${id}/download`, {
    responseType: 'blob',
  });

  if (res.status === 200) {
    const filename =
      res.headers['content-disposition']?.split('filename=')[1] ||
      'case-study-document.pdf';
    saveAs(
      res.data,
      decodeURIComponent(filename.replace('"', '').replace('"', '')),
    );
  }

  return res.data;
}

export {
  getCaseStudy,
  createCaseStudy,
  downloadCaseStudyDocument,
  deleteCaseStudy,
};
