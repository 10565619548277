import { useIntl } from 'react-intl';
import { PropsWithoutRef, useLayoutEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import styles from './ProductTypeSearchPopup.module.scss';
import LoadingSpinner from '../../../../common/LoadingSpinner/LoadingSpinner';
import SearchPopupContainer from '../SearchPopupContainer/SearchPopupContainer';
import { RootStore } from '../../../../../store';
import { LookupItem } from '../../../../../models/lookups';
import { filterLookupItemByIds } from '../../../../../utils/lookup.util';
import { MountService } from '../../../../../services/MountService';
import { getBrowseProductFilters } from '../../../../../services/ProductService';

interface ProductTypeSearchPopupProps {
  onClose: () => void;
  onItemClick: (item: LookupItem) => void;
}

/**
 * Product type search popup
 * @param onClose close handler
 * @param onItemClick item click handler
 */
function ProductTypeSearchPopup({
  onClose,
  onItemClick,
}: PropsWithoutRef<ProductTypeSearchPopupProps>) {
  const intl = useIntl();
  const mountService = useMemo(() => new MountService(), []);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const { culture, sector } = useSelector(
    (state: RootStore) => state.welcomeInfo,
  );
  const { productTypes } = useSelector((state: RootStore) => state.lookups);

  mountService.addDispatch('setLoading', setLoading);
  mountService.addDispatch('setData', setData);

  useLayoutEffect(() => {
    mountService.mount();
    mountService.callDispatch('setLoading', true);

    getBrowseProductFilters({})
      .then((res) =>
        mountService.callDispatch(
          'setData',
          filterLookupItemByIds(productTypes, res.productTypes),
        ),
      )
      .finally(() => mountService.callDispatch('setLoading', false));

    return () => {
      mountService.unmount();
    };
  }, [mountService, culture, sector]);

  return (
    <div>
      <div onClick={() => onClose()} className={styles.cover} />

      <div className={styles.container}>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <SearchPopupContainer
            onItemClick={onItemClick}
            className={styles.innerContainer}
            data={data}
            title={intl.formatMessage({ id: 'product-group-label' })}
          />
        )}
      </div>
    </div>
  );
}

export default ProductTypeSearchPopup;
