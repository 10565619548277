import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import config from '../configs/config';
import { Lookups } from '../models/lookups';
import { mapLookupItemsDictionary } from '../utils/lookup.util';

export interface LookupsSliceState extends Lookups {
  loaded: boolean;
}

export const lookupsSlice = createSlice({
  name: 'lookups',
  initialState: {
    loaded: false,
    announcementTypes: [],
    announcementTypesById: [],
    applications: [],
    applicationsById: {},
    baseOilTypes: [],
    baseOilTypeGenerics: [],
    baseOilTypeGenericsById: {},
    baseOilTypeSpecifics: [],
    baseOilTypeSpecificsById: {},
    categories: [],
    categoriesById: {},
    categorySubCategories: [],
    characteristics: [],
    characteristicsById: {},
    concentrationRanges: [],
    concentrationRangesById: {},
    contactUsRequestSubjects: [],
    corrosionProtections: [],
    corrosionProtectionsById: {},
    countries: [],
    countriesById: {},
    countryLanguages: [],
    customerOffers: [],
    customerOffersById: {},
    helpTypes: [],
    helpTypesById: {},
    languages: [],
    languagesById: {},
    masterBrands: [],
    masterBrandsById: {},
    metalTypes: [],
    metalTypesById: {},
    mineralOils: [],
    mineralOilsById: {},
    nlgis: [],
    nlgisById: {},
    oemApprovalTypes: [],
    productCountryLevelMessages: [],
    productCountryLevelMessagesById: {},
    productCountryLevelStatuses: [],
    productCountryLevelStatusesById: {},
    productTypes: [],
    productTypesById: {},
    regions: [],
    sectors: [],
    sectorsById: {},
    subApplications: [],
    subApplicationsById: {},
    subCategories: [],
    subCategoriesById: {},
    subSectors: [],
    subSectorsById: {},
    thickeners: [],
    thickenerGenerics: [],
    thickenerGenericsById: {},
    thickenerSpecifics: [],
    thickenerSpecificsById: {},
    viscosityIndices: [],
    viscosityIndicesById: {},
    yesNoNones: [],
    yesNoNonesById: {},
    benefits: [],
  } as LookupsSliceState,
  reducers: {
    // set loaded lookups
    setLookups: (state, action: PayloadAction<Lookups>): LookupsSliceState => {
      return {
        ...state,
        loaded: true,
        announcementTypes: action.payload.announcementTypes || [],
        announcementTypesById: mapLookupItemsDictionary(
          action.payload.announcementTypes || [],
        ),
        applications: action.payload.applications || [],
        benefits: action.payload.benefits || [],
        applicationsById: mapLookupItemsDictionary(
          action.payload.applications || [],
        ),
        baseOilTypes: action.payload.baseOilTypes,
        baseOilTypeGenerics:
          action.payload.baseOilTypes?.filter((x) => x.isGeneric) || [],
        baseOilTypeGenericsById: mapLookupItemsDictionary(
          action.payload.baseOilTypes?.filter((x) => x.isGeneric) || [],
        ),
        baseOilTypeSpecifics:
          action.payload.baseOilTypes?.filter((x) => !x.isGeneric) || [],
        baseOilTypeSpecificsById: mapLookupItemsDictionary(
          action.payload.baseOilTypes?.filter((x) => !x.isGeneric) || [],
        ),
        categories: action.payload.categories || [],
        categoriesById: mapLookupItemsDictionary(
          action.payload.categories || [],
        ),
        categorySubCategories: action.payload.categorySubCategories || [],
        characteristics: action.payload.characteristics || [],
        characteristicsById: mapLookupItemsDictionary(
          action.payload.characteristics || [],
        ),
        concentrationRanges: action.payload.concentrationRanges || [],
        concentrationRangesById: mapLookupItemsDictionary(
          action.payload.concentrationRanges || [],
        ),
        contactUsRequestSubjects: action.payload.contactUsRequestSubjects || [],
        corrosionProtections: action.payload.corrosionProtections || [],
        corrosionProtectionsById: mapLookupItemsDictionary(
          action.payload.corrosionProtections || [],
        ),
        countries:
          action.payload.countries.sort((a: any, b: any) =>
            a.name < b.name ? -1 : a.name > b.name ? 1 : 0,
          ) || [],
        countriesById: mapLookupItemsDictionary(action.payload.countries || []),
        countryLanguages: action.payload.countryLanguages || [],
        customerOffers: action.payload.customerOffers || [],
        customerOffersById: mapLookupItemsDictionary(
          action.payload.customerOffers || [],
        ),
        helpTypes: action.payload.helpTypes || [],
        helpTypesById: mapLookupItemsDictionary(action.payload.helpTypes || []),
        languages: config.ENABLE_LANGUAGE
          ? action.payload.languages || []
          : [{ id: 1, name: 'English', englishName: 'English', code: 'en' }],
        languagesById: mapLookupItemsDictionary(
          config.ENABLE_LANGUAGE
            ? action.payload.languages || []
            : [{ id: 1, name: 'English', englishName: 'English', code: 'en' }],
        ),
        masterBrands: action.payload.masterBrands || [],
        masterBrandsById: mapLookupItemsDictionary(
          action.payload.masterBrands || [],
        ),
        metalTypes: action.payload.metalTypes || [],
        metalTypesById: mapLookupItemsDictionary(
          action.payload.metalTypes || [],
        ),
        mineralOils: action.payload.mineralOils || [],
        mineralOilsById: mapLookupItemsDictionary(
          action.payload.mineralOils || [],
        ),
        nlgis: action.payload.nlgis || [],
        nlgisById: mapLookupItemsDictionary(action.payload.nlgis || []),
        oemApprovalTypes: action.payload.oemApprovalTypes || [],
        productCountryLevelMessages:
          action.payload.productCountryLevelMessages || [],
        productCountryLevelMessagesById: mapLookupItemsDictionary(
          action.payload.productCountryLevelMessages || [],
        ),
        productCountryLevelStatuses:
          action.payload.productCountryLevelStatuses || [],
        productCountryLevelStatusesById: mapLookupItemsDictionary(
          action.payload.productCountryLevelStatuses || [],
        ),
        productTypes: action.payload.productTypes || [],
        productTypesById: mapLookupItemsDictionary(
          action.payload.productTypes || [],
        ),
        regions: action.payload.regions || [],
        sectors: action.payload.sectors || [],
        sectorsById: mapLookupItemsDictionary(action.payload.sectors || []),
        subApplications: action.payload.subApplications || [],
        subApplicationsById: mapLookupItemsDictionary(
          action.payload.subApplications || [],
        ),
        subCategories: action.payload.subCategories || [],
        subCategoriesById: mapLookupItemsDictionary(
          action.payload.subCategories || [],
        ),
        subSectors: action.payload.subSectors || [],
        subSectorsById: mapLookupItemsDictionary(
          action.payload.subSectors || [],
        ),
        thickeners: action.payload.thickeners,
        thickenerGenerics:
          action.payload.thickeners?.filter((th) => th.isGeneric) || [],
        thickenerGenericsById: mapLookupItemsDictionary(
          action.payload.thickeners?.filter((th) => th.isGeneric) || [],
        ),
        thickenerSpecifics:
          action.payload.thickeners?.filter((th) => !th.isGeneric) || [],
        thickenerSpecificsById: mapLookupItemsDictionary(
          action.payload.thickeners?.filter((th) => !th.isGeneric) || [],
        ),
        viscosityIndices: action.payload.viscosityIndices || [],
        viscosityIndicesById: mapLookupItemsDictionary(
          action.payload.viscosityIndices || [],
        ),
        yesNoNones: action.payload.yesNoNones || [],
        yesNoNonesById: mapLookupItemsDictionary(
          action.payload.yesNoNones || [],
        ),
      };
    },
  },
});

export const { setLookups } = lookupsSlice.actions;

export default lookupsSlice.reducer;
