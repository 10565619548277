/* istanbul ignore file */
import { isEqual } from 'lodash';
import { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import config from '../configs/config';
import {
  setCulture,
  setSector,
  setWelcomeInfo,
} from '../features/welcomeInfoSlice';
import { WelcomeInfo } from '../models/welcome-info';
import { RootStore } from '../store';
import {
  filterCountryByCulture,
  filterLanguageByCulture,
  filterSectorByFriendlyURL,
} from '../utils/lookup.util';

export default function withSectorCountry(Component: any) {
  const SectorWrappedComponent = (props: any) => {
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { welcomeInfo, sector } = useSelector(
      (state: RootStore) => state.welcomeInfo,
    );

    const lookups = useSelector((state: RootStore) => state.lookups);
    const login = useSelector((state: RootStore) => state.login);

    const [prevWI, setPrevWI] = useState(welcomeInfo);
    const [prevParam, setPrevParam] = useState<any>(undefined);

    /**
     * Check path has updated with provided sector and culture
     * @param path current location path
     * @param sector current sector
     * @param culture current culture
     * @returns true if updated otherwise false.
     */
    const hasPathUpdated = (path: string, sector: string, culture: string) => {
      return path.includes(sector) && path.includes(culture);
    };

    const getCulture = (welcomeInfo: WelcomeInfo) => {
      return `${welcomeInfo?.language?.code}-${welcomeInfo.country?.code}`;
    };

    const getSector = (welcomeInfo: WelcomeInfo) => {
      return `${welcomeInfo?.industrySector?.name}`;
    };

    /**
     * Navigate to the new path that created based on sector & culture
     * @param currentPath current location path
     * @param sector current sector
     * @param culture current culture
     */
    const navigateToUpdatedRoute = (
      currentPath: string,
      sector: string,
      culture: string,
    ) => {
      setPrevWI(welcomeInfo);
      // ignore the process if any of the value is empty. To prevent race conditions.
      if (sector.length === 0 || culture.length == 0) {
        return;
      }
      if (currentPath == '/') {
        navigate(`/${sector}/${culture}`, {
          replace: true,
        });
      } else if (
        currentPath.startsWith('/product') ||
        currentPath.startsWith('/range') ||
        currentPath.startsWith('/family')
      ) {
        const parts = currentPath.split('/').filter((x) => x.match(/[^ ]+/g));
        navigate(
          `/${parts[0]}/${parts[1]}/${sector}/${culture}${location.search}`,
          {
            replace: true,
          },
        );
      } else {
        navigate(`/${sector}/${culture}`, {
          replace: true,
        });
      }
    };

    /**
     * Detect change on welcome info in respect of previous state on mount.
     * If change detected then check location whether they already have the value.
     * If false then update the route accordingly.
     */
    useLayoutEffect(() => {
      if (
        !isEqual(welcomeInfo, prevWI) &&
        location?.pathname?.length > 0 &&
        !hasPathUpdated(
          location.pathname,
          getSector(welcomeInfo),
          getCulture(welcomeInfo),
        )
      ) {
        setPrevWI(welcomeInfo);
        navigateToUpdatedRoute(
          location.pathname,
          sector,
          getCulture(welcomeInfo),
        );
      } else {
        setPrevWI(welcomeInfo);
      }
    }, [welcomeInfo, location?.pathname, prevWI]);

    /**
     * Determine route param change when no change on welcome info
     * If change detected then store it in a local state.
     * If user logged in and current selected sector is all and sector changed in url
     * the do not detect the change.
     */
    useLayoutEffect(() => {
      if (
        !prevParam &&
        params.sector &&
        params.culture &&
        (params.sector !== getSector(welcomeInfo) ||
          params.culture !== getCulture(welcomeInfo)) &&
        lookups?.countries?.length > 0 &&
        !(
          login.hasLogin &&
          params.sector !== sector &&
          sector === config.ALL_SECTORS
        )
      ) {
        if (!(!login.hasLogin && params.sector === config.ALL_SECTORS)) {
          dispatch(setCulture(params.culture));
          dispatch(setSector(params.sector));
          const info: WelcomeInfo = {
            industrySector:
              params.sector !== config.ALL_SECTORS
                ? filterSectorByFriendlyURL(
                    lookups.sectors,
                    params.sector || '',
                  )
                : config.ALL_SECTORS_WELCOME_INFO,
            country: filterCountryByCulture(
              lookups.countries,
              params.culture || '',
            ),
            language: filterLanguageByCulture(
              lookups.languages,
              params.culture || '',
            ),
          };
          setPrevParam(params);
          dispatch(setWelcomeInfo(info));
        }
      }
    }, [params, lookups.countries, login]);

    return <Component {...props} />;
  };

  return SectorWrappedComponent;
}
