import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SearchResultProduct } from '../models/search-result-product';

export interface ProductComparisonSliceState {
  products: SearchResultProduct[];
}

/**
 * manage product comparison state
 */
export const productComparisonSlice = createSlice({
  name: 'comparison',
  initialState: {
    products: [],
  } as ProductComparisonSliceState,
  reducers: {
    // toggle product
    toggleProduct: (state, action: PayloadAction<SearchResultProduct>) => {
      const product = action.payload;
      const filtered = state.products.filter(
        (item) => item.name !== product.name,
      );

      if (
        filtered.length === state.products.length &&
        state.products.length < 4
      ) {
        return {
          ...state,
          products: [...state.products, product],
        };
      } else {
        return {
          ...state,
          products: filtered,
        };
      }
    },
    // remove product
    removeProduct: (state, action: PayloadAction<SearchResultProduct>) => {
      const product = action.payload;

      return {
        ...state,
        products: state.products.filter((item) => item.name !== product.name),
      };
    },
    // clear all products
    clearProducts: (state) => {
      return {
        ...state,
        products: [],
      };
    },
  },
});

export const { toggleProduct, removeProduct, clearProducts } =
  productComparisonSlice.actions;

export default productComparisonSlice.reducer;
