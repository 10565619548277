import styles from './OemListTable.module.scss';
import { Dispatch, PropsWithoutRef, SetStateAction, useState } from 'react';
import Checkbox from '../Checkbox/Checkbox';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { ReactComponent as SortArrow } from '../../../icons/sort-arrow.svg';
import { ReactComponent as Caret } from '../../../icons/caret.svg';
import { TableColumn } from '../../../models/table-column';
import _ from 'lodash';

interface OemListTableProps<T> {
  background?: 'dark' | 'light';
  data: T[];
  sortBy: string;
  sortDirection: 'desc' | 'asc';
  columns: TableColumn<T>[];
  onSortChange: (column: TableColumn<T>) => void;
  showCheckbox?: boolean;
  onChecked?: (data: T) => void;
  checkedData?: T[];
  className?: string;
  columnClassName?: string;
  rowClassName?: string;
  headerClassName?: string;
  headerColumnClassName?: string;
  from?: string;
  actionColumn?: TableColumn<T>;
}

/**
 * sort change handler
 * @param sortBy sort column
 * @param setSortBy set sort by state changer
 * @param sortDirection sort direction
 * @param setSortDirection set sort direction state changer
 * @param column clicked column
 */
export function sortChangeHandler<T>(
  [sortBy, setSortBy]: [string, Dispatch<SetStateAction<string>>],
  [sortDirection, setSortDirection]: [
    'asc' | 'desc',
    Dispatch<SetStateAction<'asc' | 'desc'>>,
  ],
  column: TableColumn<T>,
): { sortBy: string; sortDirection: 'asc' | 'desc' } {
  let changedKey = sortBy;
  let changedDirection: 'asc' | 'desc';

  if (sortBy !== column.key) {
    changedKey = column.key;
    changedDirection = 'desc';
  } else {
    if (sortDirection === 'desc') {
      changedDirection = 'asc';
    } else {
      changedDirection = 'desc';
    }
  }

  setSortBy(changedKey);
  setSortDirection(changedDirection);

  return {
    sortBy: changedKey,
    sortDirection: changedDirection,
  };
}

/**
 * product list table
 * @param background background color
 * @param products product list
 * @param sortBy sort field
 * @param sortDirection sort direction
 * @param columns column definitions
 * @param onSortChange sort change handler
 * @param showCheckbox show checkbox
 * @param onChecked checkbox click handler
 * @param checkedData checked data list
 * @param columnClassName className for column
 * @param headerColumnClassName classname form table hader column
 */
function OemListTable<T>({
  background = 'light',
  data,
  sortBy,
  sortDirection,
  columns,
  onSortChange,
  showCheckbox,
  onChecked,
  checkedData = [],
  className,
  columnClassName,
  headerColumnClassName,
  rowClassName,
  actionColumn,
  headerClassName,
}: PropsWithoutRef<OemListTableProps<T>>) {
  const [expanded, setExpanded] = useState<{ id: number; expanded: boolean }[]>(
    [],
  );

  const getPropertyValue = (data: T, property: string) => {
    const keys = property.split('.');
    let index = 0;
    let tempData = data;
    while (index < keys.length) {
      if (typeof _.get(tempData, keys[index]) === 'object') {
        tempData = _.get(tempData, keys[index]);
        index++;
      } else {
        return _.get(tempData, keys[index]);
      }
    }
  };

  const handleCollapseClick = (
    index: number,
    expanded: { id: number; expanded: boolean }[],
  ) => {
    if (expanded && expanded.length > 0) {
      const items = _.cloneDeep(expanded);
      const item = expanded.find((x: any) => x.id === index);
      if (item) {
        const itemInd = expanded.findIndex((x: any) => x.id === index) || 0;
        items[itemInd].expanded = !item.expanded;
        setExpanded(items);
      } else {
        items.push({ id: index, expanded: false });
        setExpanded(items);
      }
    } else {
      setExpanded([{ id: index, expanded: true }]);
    }
  };

  return (
    <div className={cn(styles.container, styles[background], className)}>
      <div className={cn(styles.tableHeader, headerClassName)}>
        <div className={cn(styles.tableRow, rowClassName)}>
          {showCheckbox && (
            <div
              className={cn(
                styles.tableColumn,
                styles.tableCheckboxColumn,
                columnClassName,
                headerColumnClassName,
              )}
            >
              <FormattedMessage id={'compare-label'} />
            </div>
          )}

          {columns.map((column) => {
            const isSorting = sortBy === column.key;
            const isAsc = sortDirection === 'asc';

            return (
              <div
                onClick={() => onSortChange(column)}
                key={column.key}
                style={{ width: column.width }}
                className={cn(
                  styles.tableColumn,
                  styles.sortableColumn,
                  columnClassName,
                  headerColumnClassName,
                )}
              >
                <div className={styles.columnLabel}>{column.label}</div>

                {isSorting && (
                  <SortArrow
                    className={cn(styles.sortIcon, isAsc && styles.asc)}
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>

      <div className={styles.tableBody}>
        {data.map((item, rowIndex) => {
          const selected = checkedData.some(
            (d) => (d as any).id === (item as any).id,
          );

          return (
            <div
              key={rowIndex}
              className={cn(
                styles.tableRow,
                rowClassName,
                expanded && expanded.find((x) => x.id === rowIndex)?.expanded
                  ? styles.expanded
                  : styles.collapsed,
              )}
              onClick={() =>
                handleCollapseClick(rowIndex, _.cloneDeep(expanded))
              }
            >
              {showCheckbox && (
                <div
                  className={cn(
                    styles.tableColumn,
                    styles.tableCheckboxColumn,
                    columnClassName,
                  )}
                >
                  <Checkbox
                    onClick={() => onChecked && onChecked(item)}
                    checked={selected}
                  />
                </div>
              )}

              {columns.map((column, index) => {
                const columnContent = column.render ? (
                  <div className={styles.content}>
                    {column.render(item, rowIndex)}
                  </div>
                ) : (
                  <div className={styles.content}>
                    {getPropertyValue(item, column.property)}
                  </div>
                );

                if (column.to) {
                  return (
                    <div
                      key={column.key}
                      style={{ width: column.width }}
                      className={cn(
                        styles.tableColumn,
                        columnClassName,
                        index === 0 ? styles.firstColumn : styles.otherColumns,
                        showCheckbox ? styles.showCheckbox : '',
                      )}
                    >
                      {index > 0 && (
                        <span className={styles.label}>{column.label}</span>
                      )}
                      <Link className={styles.link} to={column.to(item)}>
                        {columnContent}
                      </Link>
                      {index === 0 && (
                        <div className={styles.actionsExpandContainer}>
                          {actionColumn &&
                            actionColumn?.render &&
                            actionColumn?.render(item, rowIndex)}
                          <Caret
                            className={styles.dropdownArrow}
                            onClick={() => {
                              handleCollapseClick(
                                rowIndex,
                                _.cloneDeep(expanded),
                              );
                            }}
                          />
                        </div>
                      )}
                    </div>
                  );
                } else {
                  return (
                    <div
                      key={column.key}
                      style={{ width: column.width }}
                      className={cn(
                        styles.tableColumn,
                        columnClassName,
                        index === 0 ? styles.firstColumn : styles.otherColumns,
                        showCheckbox ? styles.showCheckbox : '',
                        column.className || '',
                      )}
                    >
                      {index > 0 && (
                        <span className={styles.label}>{column.label}</span>
                      )}
                      {columnContent}
                      {index === 0 && (
                        <Caret className={styles.dropdownArrow} />
                      )}
                    </div>
                  );
                }
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default OemListTable;
