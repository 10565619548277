import {
  ChangeEvent,
  PropsWithoutRef,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FilterOption } from '../../../models/filter-option';
import { isNil, isNumber, range } from 'lodash';

import styles from './RangeSlider.module.scss';
import classNames from 'classnames';

interface RangeSliderProps {
  minValue?: number;
  filters: FilterOption[];
  selectedOptions: FilterOption | null;
  onChange: (filter: FilterOption) => void;
  onReset: () => void;
  className?: string;
  filerOpenChange?: boolean;
}

/**
 * range slider
 * @param minValue minimum value
 * @param steps steps
 * @param filters filter options
 * @param selectedOptions selected options
 * @param onChange on change handler
 * @param onReset filter reset handler
 * @param className classname
 */
function RangeSlider({
  minValue = 0,
  filters = [],
  selectedOptions,
  onChange,
  onReset,
  className,
  filerOpenChange,
}: PropsWithoutRef<RangeSliderProps>) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState<number | ''>('');
  const [sliderProps, setSliderProps] = useState<any>({});
  const [labels, setLabels] = useState<any[]>([]);
  const [currentPosition, setCurrentPosition] = useState<number>(5);
  const [max, setMax] = useState<number>(0);

  useEffect(() => {
    // filter size may not same as label size
    // so need check label and filter name whether same
    const filterIdx = filters.findIndex(
      (x) => x.value === selectedOptions?.value,
    );

    const labelIdx = selectedOptions
      ? labels.findIndex((x) => x.label === filters[filterIdx]?.name)
      : max;
    const position = labels[labelIdx]?.position;

    setValue(labelIdx);

    if (!isNil(position)) {
      setCurrentPosition(position);
    }
  }, [selectedOptions, labels]);

  useEffect(() => {
    if (sliderProps && labels && labels.length > 0 && !isNumber(value)) {
      const maxVal = labels.length <= 2 ? labels.length - 1 : max;
      setValue(max);
      setCurrentPosition(labels[maxVal].position);
    }
  }, [sliderProps, max, labels]);

  const changeCallback = (e: ChangeEvent<HTMLInputElement>) => {
    const val = parseInt(e.target.value);
    if (val === max) {
      onReset();
    } else {
      // filter size may not same as label size
      // so need find option with name == label
      const found = filters.find((filter) => filter.name === labels[val].label);

      if (found) {
        onChange(found);
      }
    }
    setCurrentPosition(labels[val].position);
  };

  const getThumbPosition = (
    value: number,
    valueMin: number,
    valueMax: number,
    index: number,
    itemCount: number,
  ) => {
    const width = inputRef.current?.offsetWidth || 343;
    const percent = (10 / width) * 100;
    let halfItem = Math.floor(itemCount / 2);
    halfItem = itemCount % 2 === 0 ? halfItem - 0.5 : halfItem;
    const ratio = ((value - valueMin) * 100.0) / (valueMax - valueMin);
    return index > halfItem
      ? ratio - ((index - halfItem) / halfItem) * percent
      : index < halfItem
      ? ratio + ((halfItem - index) / halfItem) * percent
      : ratio;
  };

  useEffect(() => {
    if (
      filters &&
      filters.length > 0 &&
      (labels.length === 0 || filerOpenChange)
    ) {
      const minVal = minValue;
      const maxVal = Math.min(filters.length + 1, 7);
      setMax(maxVal - 1);
      const step = 1;
      const tempLabels: { label: string; position: number }[] = [];
      const ranges = range(minVal, maxVal, step);
      const marks = {};
      tempLabels.push({
        label: filters[0].name,
        position: getThumbPosition(
          minVal,
          minVal,
          maxVal - 1,
          0,
          ranges.length,
        ),
      });

      let index = 1;
      const end = ranges.length - 2;

      for (let i = 1; i < end; i++) {
        tempLabels.push({
          label: filters[index].name,
          position: getThumbPosition(i, minVal, maxVal - 1, i, ranges.length),
        });
        if (filters.length - 1 - index > end - i) {
          index += 2;
        } else {
          index++;
        }
      }

      if (filters.length > 1) {
        tempLabels.push({
          label: filters[filters.length - 1].name,
          position: getThumbPosition(
            ranges.length - 2,
            minVal,
            maxVal - 1,
            ranges.length - 2,
            ranges.length,
          ),
        });
      }

      tempLabels.push({
        label: 'Any',
        position: getThumbPosition(
          ranges.length - 1,
          minVal,
          maxVal - 1,
          ranges.length - 1,
          ranges.length,
        ),
      });

      setLabels(tempLabels);
      setSliderProps({
        min: minVal,
        max: maxVal - 1,
        step: step,
        marks: marks,
      });
    }
  }, [filters, filerOpenChange]);

  return (
    <div className={classNames(styles.sliderContainer, className)}>
      <div className={styles.sliderWrapper}>
        <div className={styles.slider}></div>
        <div
          className={styles.remainingSlider}
          style={{
            width: `${100 - currentPosition}%`,
            left: `${currentPosition}%`,
          }}
        ></div>
        <input
          ref={inputRef}
          type="range"
          value={value}
          {...sliderProps}
          className={styles.sliderInput}
          id="myRange"
          onChange={changeCallback}
        />
      </div>
      <div className={styles.barContainer}>
        {labels.map((val, index) => (
          <span
            className={styles.bar}
            style={{ left: `${val.position}%` }}
            key={index}
          >
            |
          </span>
        ))}
      </div>
      <div className={classNames(styles.barContainer, styles.labelContainer)}>
        {labels.map((val, index) => (
          <span
            className={styles.label}
            key={index}
            style={{ left: `${val.position}%` }}
          >
            {val.label}
          </span>
        ))}
      </div>
    </div>
  );
}

export default RangeSlider;
