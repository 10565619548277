import { useIntl } from 'react-intl';
import FormRow from '../FormRow/FormRow';
import { MonthYearRowType } from '../../../models/admin-form-types';
import styles from './MonthYearField.module.scss';
import classNames from 'classnames';
import Select from '../../common/Select/Select';
import _ from 'lodash';

const monthOptions = _.range(1, 13).map((m) => ({
  name: m.toString(),
  value: m,
}));
const yearOptions = _.range(2021, new Date().getFullYear() + 1).map((y) => ({
  name: y.toString(),
  value: y,
}));

const MonthYearField = ({
  labelId,
  monthValue,
  yearValue,
  onChangeMonth,
  onChangeYear,
  required,
  tooltip,
  disabled,
  className,
}: MonthYearRowType) => {
  const intl = useIntl();
  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });

  return (
    <FormRow
      labelId={labelId}
      required={required}
      tooltip={tooltip}
      className={classNames(className, styles.dateField)}
    >
      <div className={styles.selectWrapper}>
        <Select
          className="selectControl"
          value={monthValue}
          options={monthOptions as any}
          placeholder={getTranslatedMessage('reports-page-month-placeholder')}
          onChange={onChangeMonth}
          disabled={disabled}
          canSearch={true}
        />
        <Select
          className="selectControl"
          value={yearValue}
          options={yearOptions as any}
          placeholder={getTranslatedMessage('reports-page-year-placeholder')}
          onChange={onChangeYear}
          disabled={disabled}
          canSearch={true}
        />
      </div>
    </FormRow>
  );
};

export default MonthYearField;
