import { useIntl } from 'react-intl';

import FormWrapper from '../FormWrapper/FormWrapper';
import SelectFormRow from '../SelectFormRow/SelectFormRow';

type SelectACountryOrDistributorFormProps = {
  titleId: string;
  labelId: string;
  selectOptions: any;
  optionDisplayKey?: string;
  fieldKey: string;
  formValue: any;
  handleValueChange: (fieldKey: string, value: any) => void;
};

const SelectACountryOrDistributorForm = ({
  titleId,
  labelId,
  selectOptions,
  optionDisplayKey = 'name',
  fieldKey,
  formValue,
  handleValueChange,
}: SelectACountryOrDistributorFormProps) => {
  const intl = useIntl();

  const title = intl.formatMessage({ id: titleId });
  return (
    <FormWrapper title={title}>
      <SelectFormRow
        labelId={labelId}
        value={formValue[fieldKey]}
        options={selectOptions.map((option: any) => ({
          name: option[optionDisplayKey],
          value: option,
          //inactiveLabel: !option.active, //bug
        }))}
        onChange={(value: string) => handleValueChange(fieldKey, value)}
      />
    </FormWrapper>
  );
};

export default SelectACountryOrDistributorForm;
