import { memo, PropsWithoutRef } from 'react';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import { ProductValidationColumn } from '../../../models/table-column';
import ProductValidationTableRow from './ProductValidationTableRow';
import styles from './ProductValidationTable.module.scss';
import { SheetValidationResult } from '../../../models/sheet-validation';

interface ProductValidationTableProps {
  data: any[];
  setDataAtIndex: (x: any, i: number) => void;
  columns: ProductValidationColumn[];
  tableRowClassName?: string;
  validationErrors?: SheetValidationResult;
}

function ProductValidationTable({
  data = [],
  setDataAtIndex,
  columns,
  tableRowClassName,
  validationErrors,
}: PropsWithoutRef<ProductValidationTableProps>) {
  return (
    <div className={styles.container}>
      <div className={styles.tableHeader}>
        <div className={cn(styles.tableRow, tableRowClassName)}>
          {columns
            .filter((column) => !column.hidden)
            .map((column) => (
              <div
                className={cn(styles.tableColumn, {
                  [styles.requiredColumn]: column.requiredAsterisk,
                })}
                key={column.formattedMessageId}
              >
                <FormattedMessage id={column.formattedMessageId} />
              </div>
            ))}
          <div className={cn(styles.tableColumn, styles.tableErrorColumn)} />
          <div className={cn(styles.tableColumn, styles.tableDropdownColumn)} />
        </div>
      </div>
      <div className={styles.tableBody}>
        {data.map((product, index) => (
          <ProductValidationTableRow
            key={index}
            columns={columns}
            rowClassName={tableRowClassName}
            row={product}
            rowIndex={index}
            setDataAtIndex={(newData: any) => setDataAtIndex(newData, index)}
            rowError={validationErrors?.errorByRow[index]}
          />
        ))}
      </div>
    </div>
  );
}

export default memo(ProductValidationTable);
