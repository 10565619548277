import styles from './ProductListItem.module.scss';
import { PropsWithoutRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../../../configs/config';
import cn from 'classnames';
import AdditionalButton from './AdditionalButton/AdditionalButton';
import { SearchResultCountry } from '../../../models/search-result-country';
import { SearchResultPort } from '../../../models/search-result-port';
import { SearchResultFamily } from '../../../models/search-result-family';
import { SearchResultRange } from '../../../models/search-result-range';
import { SearchResultProduct } from '../../../models/search-result-product';
import { SearchResultOEM } from '../../../models/search-result-oem';
import ProductChildrenTable from './ProductChildrenTable/ProductChildrenTable';
import PortChildrenTable from './PortChildrenTable/PortChildrenTable';
import RangeChildrenTable from './RangeChildrenTable/RangeChildrenTable';
import Button from '../Button/Button';
import { FormattedMessage } from 'react-intl';

interface ProductListItemProps {
  type: string;
  product:
    | SearchResultCountry
    | SearchResultPort
    | SearchResultFamily
    | SearchResultRange
    | SearchResultProduct
    | SearchResultOEM;
  className?: string;
  showRangeDetailsBtn?: boolean;
  productsForFamily?: boolean;
}

type HasProductChildren =
  | SearchResultCountry
  | SearchResultPort
  | SearchResultFamily
  | SearchResultRange;

/**
 * product list item
 * @param type display type
 * @param product product
 * @param className classname
 * @param onTabChange handle tab change event
 * @param showRangeDetailsBtn range detail button
 */
function ProductListItem({
  type,
  product,
  className,
  showRangeDetailsBtn = false,
  productsForFamily,
}: PropsWithoutRef<ProductListItemProps>) {
  const navigate = useNavigate();
  const [showAdditional, setShowAdditional] = useState<any>(null);
  const [previousType, setPreviousType] = useState<any>(null);

  if (type !== previousType) {
    setShowAdditional(null);
    setPreviousType(type);
  }

  // check current product type
  const isPort = type === config.PORT_TYPE;
  const isRange = type === config.RANGE_TYPE;
  const isOEM = type === config.OEM_TYPE;

  // check children length
  const portsLength =
    (product as SearchResultCountry).portChildren?.length || 0;
  const rangesLength =
    (product as SearchResultFamily).rangeChildren?.length || 0;
  const productsLength =
    (product as HasProductChildren).productChildren?.length || 0;

  // check opened children
  const portsOpened = showAdditional === config.PORT_TYPE;
  const rangesOpened = showAdditional === config.RANGE_TYPE;
  const productsOpened = showAdditional === config.PRODUCT_TYPE;

  // children
  const portChildren = (product as SearchResultCountry).portChildren || [];
  const rangeChildren = (product as SearchResultFamily).rangeChildren || [];
  const productChildren = (product as HasProductChildren).productChildren || [];

  const childrenOpened = portsOpened || rangesOpened || productsOpened;
  const showButtons = portsLength > 0 || rangesLength > 0 || productsLength > 0;

  /**
   * show ports additional
   */
  const showPorts = () => {
    if (portsOpened) {
      setShowAdditional(null);
    } else {
      setShowAdditional(config.PORT_TYPE);
    }
  };

  /**
   * show ranges additional
   */
  const showRanges = () => {
    if (rangesOpened) {
      setShowAdditional(null);
    } else {
      setShowAdditional(config.RANGE_TYPE);
    }
  };

  /**
   * show products additional
   */
  const showProducts = () => {
    if (productsOpened) {
      setShowAdditional(null);
    } else {
      setShowAdditional(config.PRODUCT_TYPE);
    }
  };

  /**
   * event handler for when user clocks on title of item
   */
  const titleClickHandler = () => {
    switch (type) {
      case 'port': {
        navigate(`/port/${(product as SearchResultRange).friendlyUrl}`);
        return;
      }
      case 'family': {
        navigate(`/family/${(product as SearchResultRange).friendlyUrl}`);
        return;
      }
      case 'range': {
        navigate(`/range/${(product as SearchResultRange).friendlyUrl}`);
        return;
      }
      case 'oem': {
        navigate(`/admin/oem/${(product as SearchResultOEM).id}`);
        return;
      }
      default:
        return;
    }
  };

  return (
    <div
      className={cn(
        styles.container,
        className,
        childrenOpened && styles.opened,
      )}
    >
      <div className={styles.summaryContainer}>
        <div className={styles.summaryHeader}>
          <h3
            className={cn({
              [styles.summaryHeaderClickable]: [
                'port',
                'family',
                'range',
                'oem',
              ].includes(type),
            })}
            onClick={titleClickHandler}
          >
            {product.name}
          </h3>

          <div className={styles.summaryType}>
            {productsForFamily ? (
              <FormattedMessage id={`products-label`} />
            ) : (
              <FormattedMessage id={`${type}-label`} />
            )}
          </div>
        </div>

        {isPort && (
          <div className={cn(styles.summarySubTitle, styles.summaryText)}>
            {(product as SearchResultPort).country}
          </div>
        )}

        {isRange && (
          <div className={cn(styles.summarySubTitle, styles.summaryText)}>
            {(product as SearchResultRange).tagLine}
          </div>
        )}

        {!isOEM && (
          <div className={cn(styles.summaryDescription, styles.summaryText)}>
            {product.description}
          </div>
        )}

        {showButtons && (
          <div className={styles.summaryButtonContainer}>
            {portsLength > 0 && (
              <AdditionalButton
                type={'ports'}
                onClick={() => showPorts()}
                counts={portsLength}
                opened={portsOpened}
                className={styles.summaryButton}
              />
            )}

            {!productsForFamily && rangesLength > 0 && (
              <AdditionalButton
                type={'ranges'}
                onClick={() => showRanges()}
                counts={rangesLength}
                opened={rangesOpened}
                className={styles.summaryButton}
              />
            )}

            {productsLength > 0 && (
              <AdditionalButton
                type={'products'}
                onClick={() => showProducts()}
                counts={productsLength}
                opened={productsOpened}
                className={styles.summaryButton}
              />
            )}

            {showRangeDetailsBtn && (
              <Button
                onClick={() =>
                  navigate(
                    `/range/${(product as SearchResultRange).friendlyUrl}`,
                  )
                }
                color={'transparent'}
                className={styles.summaryButton}
              >
                <FormattedMessage id={'see-range-details-label'} />
              </Button>
            )}
          </div>
        )}
      </div>

      {childrenOpened && (
        <div className={styles.listContainer}>
          {portsOpened && <PortChildrenTable ports={portChildren} />}

          {rangesOpened && <RangeChildrenTable ranges={rangeChildren} />}

          {productsOpened && (
            <ProductChildrenTable products={productChildren} />
          )}
        </div>
      )}
    </div>
  );
}

export default ProductListItem;
