import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import GenericManageSkeleton from '../../../components/Admin/GenericManageSkeleton/GenericManageSkeleton';
import Header from '../../../components/Admin/Header/Header';
import ManagePortProductForm from '../../../components/Admin/ManageStockpointContent/ManagePortProductForm/ManagePortProductForm';
import SelectStockpointForm from '../../../components/Admin/ManageStockpointContent/SelectStockpointForm/SelectStockpointForm';

import {
  StepperItemType,
  StockpointFormType,
  StockpointProductType,
  StockpointType,
} from '../../../models/admin-form-types';
import { PreviousLink } from '../../../models/previous-link';
import { CountryItem } from '../../../models/lookups';
import { PortResponse } from '../../../models/port-details';
import { hideLoading, showLoading } from '../../../features/loadingSlice';

import {
  getStockpoint,
  getStockpoints,
} from '../../../services/StockpointService';
import {
  getPortDetails,
  updatePortProductNotesExclusions,
} from '../../../services/PortService';

import withAdmin from '../../../hoc/withAdmin';
import styles from './ManagePortProductPage.module.scss';
import { ALL_ITEM_VALUE } from '../../../components/Admin/MultiSelect/MultiSelect';
import { setUnsavedStatus } from '../../../features/genericModalSlice';

const breadcrumbLinks: PreviousLink[] = [
  {
    name: 'Home',
    to: '/home',
  },
  {
    name: 'Manage Products For Port',
  },
];

const initialState: StockpointFormType = {
  stockpoint: {} as StockpointType,
  country: {} as CountryItem,
  stockpointProducts: [],
  ports: [],
};

const ManagePortProductPage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const [formValue, setFormValue] = useState(initialState);
  const [stockpoints, setStockpoints] = useState([] as StockpointType[]);
  const [portDetail, setPortDetail] = useState<PortResponse>();
  const { portFriendlyUrl } = useParams();

  const [initialProductStatus, setInitialProductStatus] = useState<
    {
      productId: number;
      note: {
        note: string;
        ports: number[];
      };
      portProductExclusion: {
        value?: boolean;
      };
    }[]
  >([]);
  const [productStatus, setProductStatus] = useState(initialProductStatus);

  useEffect(() => {
    dispatch(showLoading());
    Promise.all([getPortDetails(portFriendlyUrl as string), getStockpoints()])
      .then((values) => {
        setPortDetail(values[0]);
        setStockpoints(values[1]);

        const stockPoint = _.find(
          values[1],
          (item) => String(item.id) === String(values[0].port.stockPointId),
        );

        if (stockPoint && step === 0) {
          setFormValue({
            ...formValue,
            stockpoint: stockPoint,
          });
        }
        setStep(1);
      })
      .finally(() => dispatch(hideLoading()));
  }, []);

  useEffect(() => {
    // Make only API call to fetch stockpoint details when we are on the actual page
    if (portDetail && formValue.stockpoint.id && step === 1) {
      dispatch(showLoading());
      const stockpointId = formValue.stockpoint.id;
      if (stockpointId) {
        getStockpoint(stockpointId)
          .then((res) => {
            const stockpointProducts: any[] =
              _.cloneDeep(portDetail?.portProducts) || [];
            for (let i = 0; i < stockpointProducts.length; i++) {
              stockpointProducts[i].name = stockpointProducts[i].product.name;
            }

            setFormValue({
              ...formValue,
              stockpointProducts,
              ports: res.ports || [],
            });

            const tmpProductStatus = stockpointProducts.map((item: any) => ({
              productId: item.productId,
              note: {
                editable: false,
                note: item.portProductNotes || '',
                ports: [portDetail?.port.id],
              },
              portProductExclusion: {
                value: item.portProductExclusion,
              },
            }));
            setProductStatus(tmpProductStatus);
            setInitialProductStatus(tmpProductStatus);
          })
          .finally(() => {
            dispatch(hideLoading());
          });
      }
    }
  }, [step, portDetail, formValue.stockpoint.id]);

  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });

  /* istanbul ignore next */
  const handleValueChange = (
    fieldKey: string,
    value: StockpointProductType[],
  ) => {
    const formVal = _.set(formValue, fieldKey, value);
    setFormValue((state) => ({
      ...state,
      formVal,
    }));
  };

  const secondaryTitle = (
    <div className={styles.portName}>
      <span>{getTranslatedMessage('port-name-label') + ': '}</span>
      <span>{portDetail?.port.name}</span>
    </div>
  );

  /* istanbul ignore next */
  const stepperItems: StepperItemType = [
    {
      label: getTranslatedMessage('select-stockpoint-label'),
      componentToRender: (
        <SelectStockpointForm
          stockpoints={stockpoints}
          formValue={formValue}
          handleValueChange={(value: StockpointType) => {
            setFormValue({
              ...formValue,
              stockpoint: value,
            });
          }}
          disabled={true}
          noMessage={true}
          secondaryTitle={secondaryTitle}
        />
      ),
    },
    {
      label: getTranslatedMessage('modify-products-stockpoint-label'),
      componentToRender: (
        <ManagePortProductForm
          formValue={formValue}
          handleValueChange={handleValueChange}
          portId={portDetail?.port.id || 0}
          portName={portDetail?.port.name || ''}
          productStatus={productStatus}
          setProductStatus={(value) => {
            setProductStatus(value);
            dispatch(setUnsavedStatus(!_.isEqual(value, initialProductStatus)));
          }}
          initialProductStatus={initialProductStatus}
        />
      ),
    },
  ];

  const saveDisabled = () => {
    if (step === 0) {
      return !formValue.stockpoint?.name;
    }
    if (step === 1) {
      return _.isEqual(productStatus, initialProductStatus);
    }

    return false;
  };

  const handleSaveBtnClick = () => {
    if (step < 1) {
      setStep(step + 1);
    }
    if (step === 1) {
      if (!_.isEqual(productStatus, initialProductStatus)) {
        handleApplyNoteChange();
      }
    }
  };

  const handleApplyNoteChange = () => {
    dispatch(showLoading());

    const updatedProductNotes = productStatus.filter((item, index) => {
      const intialStatus = initialProductStatus[index];
      return !_.isEqual(item.note, intialStatus.note);
    });

    const updatedProductNotesRequests: {
      portId: number;
      productId: number;
      portProductNotes: string;
    }[] = [];

    updatedProductNotes
      .filter((x) => x.note.ports.some((y) => y === ALL_ITEM_VALUE))
      .forEach((item) => {
        const portIds = formValue.ports.map((item) => item.id);
        portIds.forEach((id) => {
          updatedProductNotesRequests.push({
            portId: id,
            productId: item.productId,
            portProductNotes: item.note.note,
          });
        });
      });

    updatedProductNotes
      .filter((x) => !x.note.ports.some((y) => y === ALL_ITEM_VALUE))
      .forEach((item) => {
        const portIds = item.note.ports;
        portIds.forEach((id) => {
          updatedProductNotesRequests.push({
            portId: id,
            productId: item.productId,
            portProductNotes: item.note.note,
          });
        });
      });

    const updatedProductExcludeRequests = productStatus.map((x) => ({
      productId: x.productId,
      excluded: x.portProductExclusion.value || false,
    }));

    updatePortProductNotesExclusions(
      updatedProductNotesRequests,
      updatedProductExcludeRequests,
      portDetail?.port.id || 0,
    )
      .then(() => {
        setInitialProductStatus(productStatus);
      })
      .finally(() => {
        dispatch(hideLoading());
        dispatch(setUnsavedStatus(false));
      });
  };

  return (
    <>
      <Header
        titleId={'breadcrumb-manage-products-for-port'}
        breadcrumbLinks={breadcrumbLinks}
        showUnsavedChangesModal={
          !_.isEqual(productStatus, initialProductStatus)
        }
      />
      <GenericManageSkeleton
        step={step}
        stepperItems={stepperItems}
        saveBtnDisabled={saveDisabled()}
        showUnsavedChangesModal={
          !_.isEqual(productStatus, initialProductStatus)
        }
        onBackBtnClick={() => setStep(step - 1)}
        onSaveBtnClick={handleSaveBtnClick}
      >
        {stepperItems[step].componentToRender}
      </GenericManageSkeleton>
    </>
  );
};

export default withAdmin(ManagePortProductPage);
