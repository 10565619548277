import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserInfo } from '../models/user-info';

export interface LoginSliceState {
  hasLogin: boolean;
  isAdmin: boolean;
  user: UserInfo | undefined;
}

const initialState: LoginSliceState = {
  hasLogin: false,
  isAdmin: false,
  user: undefined,
};

/**
 * manage login state
 */
export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setHasLogin: (
      state: LoginSliceState,
      action: PayloadAction<UserInfo | undefined>,
    ): LoginSliceState => ({
      hasLogin: true,
      isAdmin: false,
      user: action.payload,
    }),
    setHasLogout: (): LoginSliceState => ({
      hasLogin: false,
      isAdmin: false,
      user: undefined,
    }),
    setAdminLogin: (
      state: LoginSliceState,
      action: PayloadAction<UserInfo | undefined>,
    ): LoginSliceState => ({
      hasLogin: true,
      isAdmin: true,
      user: action.payload,
    }),
  },
});

export const { setHasLogin, setHasLogout, setAdminLogin } = loginSlice.actions;

export default loginSlice.reducer;
