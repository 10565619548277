import { PropsWithoutRef } from 'react';
import { FormattedMessage } from 'react-intl';

import ValidationTable from '../ValidationTable/ValidationTable';
import Button from '../../../../../../components/common/Button/Button';
import { ProductValidationColumn } from '../../../../../../models/table-column';
import styles from './ValidateData.module.scss';

interface ValidateDataProps {
  save: () => void;
  cancel: () => void;
  data: any[];
  setData: (x: any[]) => void;
  columns: ProductValidationColumn[];
  errorCount: number;
  uniquePair: boolean;
  backendErrors: any[];
}

function ValidateData({
  save,
  cancel,
  data = [],
  setData,
  columns,
  errorCount,
  uniquePair,
  backendErrors,
}: PropsWithoutRef<ValidateDataProps>) {
  const descriptionValues = {
    count: errorCount,
    b: (value: string[]) => <b>{value}</b>,
  };

  const setDataAtIndex = (newDataItem: any, index: number) => {
    if (index >= data.length) return;
    setData(data.map((row, i) => (i === index ? newDataItem : row)));
  };

  return (
    <>
      <div className={styles.container}>
        <h2>
          <FormattedMessage id={'validate-data'} />
        </h2>
        <div className={styles.divider} />
        <div className={styles.validationErrCount}>
          <FormattedMessage
            id={'validation-data-error'}
            values={descriptionValues}
          />
        </div>
        {!uniquePair && (
          <div className={styles.uniquePair}>
            <FormattedMessage id={'add-country-unique-error'} />
          </div>
        )}
        {backendErrors.map((error) => (
          <div
            key={`${error.row}_${error.property}`}
            className={styles.backendError}
          >
            Row {error.row} - {error.property} - {error.message}
          </div>
        ))}
        <ValidationTable
          data={data}
          setDataAtIndex={setDataAtIndex}
          columns={columns}
        />
      </div>
      <div className={styles.buttons}>
        <Button onClick={cancel} color={'gray'} className={styles.button}>
          <FormattedMessage id={'cancel-label'} />
        </Button>
        <Button
          onClick={save}
          color={'green'}
          className={styles.button}
          disabled={data.length === 0 || errorCount !== 0}
        >
          <FormattedMessage id={'save-label'} />
        </Button>
      </div>
    </>
  );
}

export default ValidateData;
