import { MultiSelectFilter } from '../models/filter';

export function containsAnySelectedFilters(
  selectedFilterIdsMap: { [k: number]: boolean },
  idsOnProduct: number[],
): boolean {
  return idsOnProduct.find((id) => selectedFilterIdsMap[id]) !== undefined;
}

export function arrayToIdMap(ids: number[]): { [k: number]: boolean } {
  return ids.reduce<{ [k: number]: boolean }>((idMap, id) => {
    idMap[id] = true;
    return idMap;
  }, {});
}

export function matrixToIdMap(ids: number[][]): {
  [index: number]: { [id: number]: boolean };
} {
  return ids.reduce<{ [index: number]: { [id: number]: boolean } }>(
    (idMap, array, index) => {
      idMap[index] = arrayToIdMap(array);
      return idMap;
    },
    {},
  );
}

export function testMultiSelectFilter(
  selectedIdMap: { [k: number]: boolean },
  idsOnItem: number[],
): boolean {
  return containsAnySelectedFilters(selectedIdMap, idsOnItem);
}

export function testRangeFilter(
  range: number[] | null,
  itemRange: (number | null)[],
): boolean {
  if (range === null) {
    return true;
  } else {
    return (
      (itemRange[0] === null || range[0] <= itemRange[0]) &&
      (itemRange[1] === null || range[1] >= itemRange[1])
    );
  }
}

export function testCheckboxFilter(
  checked: boolean,
  itemValue: number | boolean,
): boolean {
  const yesNoNonesYesId = 2;
  return (
    !checked ||
    (typeof itemValue === 'boolean' && itemValue) ||
    itemValue === yesNoNonesYesId
  );
}

export function testCombinedMultiSelectFilter(
  selectedIdMaps: { [index: number]: { [id: number]: boolean } },
  idsOnItem: number[][],
): boolean {
  return !!idsOnItem.find((ids, index) =>
    ids.find((id) => selectedIdMaps[index][id]),
  );
}

export function getRange<T>(
  items: T[],
  itemRangeMapper: (item: T) => number[],
): number[] | null {
  return items.reduce<number[] | null>((range, item) => {
    const itemRange = itemRangeMapper(item);
    if (range) {
      const newRange = [
        range[0] !== null ? Math.min(range[0], itemRange[0]) : itemRange[0],
        range[1] !== null ? Math.max(range[1], itemRange[1]) : itemRange[1],
      ];
      return newRange[0] === null && newRange[1] === null ? null : newRange;
    } else {
      return itemRange;
    }
  }, null);
}

export function hasDifferentValue(values: (boolean | number)[]): boolean {
  if (values.length === 0) {
    return false;
  }
  const firstValue = values[0];
  return !!values.find((val) => firstValue !== val);
}

export function getUniqueIdArray<T>(
  items: T[],
  idMapper: (item: T) => null | number | number[],
  currentFilter?: number[],
): number[] {
  const dict: { [k: number]: number } = {};
  items.forEach((prod) => {
    const id = idMapper(prod);
    if (typeof id === 'number') {
      dict[id] = id;
    } else if (typeof id === 'object') {
      id?.forEach((idNum) => (dict[idNum] = idNum));
    }
  });
  currentFilter?.forEach((id) => {
    dict[id] = id;
  });
  return Object.values(dict);
}

/**
 * toggle selected filters
 * @param key filters key
 * @param filter filter option to toggle
 * @param filters selected filters
 */
export function toggleMultiSelectFilter(
  multiSelectFilter: MultiSelectFilter,
  filter: number,
) {
  const index = multiSelectFilter.value.indexOf(filter);

  if (index === -1) {
    // add
    return {
      ...multiSelectFilter,
      value: [...multiSelectFilter.value, filter],
    };
  } else {
    const newValues = [...multiSelectFilter.value];
    newValues.splice(index, 1);

    // remove
    return {
      ...multiSelectFilter,
      value: newValues,
    };
  }
}
