import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import cn from 'classnames';

import styles from './MyAccountPage.module.scss';

import { unregister } from '../../services/UserService';
import { RootStore } from '../../store';
import { hideLoading, showLoading } from '../../features/loadingSlice';
import { addToast } from '../../features/toastSlice';
import { showGenericModal } from '../../features/genericModalSlice';
import { UserInfo } from '../../models/user-info';
import { getErrorMessage } from '../../utils/string.util';

import { setUserInfoToLocalStorage } from '../../services/LocalStorageService';
import { updateUserSelf } from '../../services/UserService';
import { getUserInfo } from '../../services/AuthService';
import { PreviousLink } from '../../models/previous-link';
import { CountryItem, LanguageItem } from '../../models/lookups';
import NavigationHeader from '../../components/common/NavigationHeader/NavigationHeader';
import SwitchToggle from '../../components/common/SwitchToggle/SwitchToggle';
import Button from '../../components/common/Button/Button';
import { ReactComponent as UserEditIcon } from '../../icons/user-edit-white.svg';
import { ReactComponent as LockIcon } from '../../icons/lock-outline-white.svg';
import { ReactComponent as UserXIcon } from '../../icons/user-x-white.svg';
import UnregisterModal from '../../components/common/UnregisterModal/UnregisterModal';
import { useMsal } from '@azure/msal-react';
import { setHasLogout } from '../../features/loginSlice';
import {
  setAccessTokenToLocalStorage,
  clearAccessTokenToLocalStorage,
  clearUserInfoToLocalStorage,
} from '../../services/LocalStorageService';
import { loginAsAnonymous } from '../../services/AuthService';

/**
 * My account page component
 */
function MyAccountPage() {
  // init hooks
  const intl = useIntl();
  const { accounts } = useMsal();
  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showUnregisterModal, setShowUnregisterModal] = useState(false);
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const [country, setCountry] = useState<CountryItem>();
  const [language, setLanguage] = useState<LanguageItem>();
  const [receiveEmail, setReceiveEmail] = useState(false);

  const isSSOLogin = useMemo(() => {
    return accounts && accounts.length > 0;
  }, [accounts]);

  const breadcrumbLinks: PreviousLink[] = [
    {
      name: 'Home',
      to: '/home',
    },
    {
      name: getTranslatedMessage('my-account-label'),
    },
  ];

  const { countries, languages } = useSelector(
    (state: RootStore) => state.lookups,
  );

  useEffect(() => {
    dispatch(showLoading());
    getUserInfo()
      .then((res) => {
        if (res) {
          setUserInfo(res);
          setUserInfoToLocalStorage(res);
          if (res.receiveProductPromotionMail) {
            setReceiveEmail(true);
          }
        }
      })
      .catch((err) => {
        dispatch(
          addToast({
            type: 'error',
            title: getTranslatedMessage('get-account-info-failure'),
            message: getErrorMessage(err),
          }),
        );
      })
      .finally(() => dispatch(hideLoading()));
  }, []);

  useEffect(() => {
    if (
      userInfo &&
      countries &&
      countries.length > 0 &&
      languages &&
      languages.length > 0
    ) {
      const countryItem = countries.find(
        (item) => item.id === userInfo.countryId,
      );
      setCountry(countryItem);
      const languageItem = languages.find(
        (item) => item.id === userInfo.languageId,
      );
      setLanguage(languageItem);
    }
  }, [userInfo, countries, languages]);

  const onUnregister = (reason: string) => {
    dispatch(showLoading());
    unregister(reason)
      .then(async () => {
        setShowUnregisterModal(false);
        await onLogoutClick();
        dispatch(
          showGenericModal({
            titleId: 'unregister-account-label',
            messageId: 'unregister-account-success-info',
            to: '/',
          }),
        );
      })
      .catch((err) => {
        dispatch(
          addToast({
            type: 'error',
            title: getTranslatedMessage('update-account-failure'),
            message: getErrorMessage(err),
          }),
        );
      })
      .finally(() => dispatch(hideLoading()));
  };

  const onLogoutClick = async () => {
    clearAccessTokenToLocalStorage();
    clearUserInfoToLocalStorage();
    const login = await loginAsAnonymous();
    setAccessTokenToLocalStorage(login.accessToken);
    dispatch(setHasLogout());
  };

  const receiveEmailClicked = (value: boolean) => {
    setReceiveEmail(value);
    if (userInfo) {
      dispatch(showLoading());
      updateUserSelf({
        ...userInfo,
        receiveProductPromotionMail: value,
      })
        .then(() => {
          dispatch(
            addToast({
              type: 'success',
              title: getTranslatedMessage('update-account-success'),
              message: '',
            }),
          );
        })
        .catch((err) => {
          dispatch(
            addToast({
              type: 'error',
              title: getTranslatedMessage('update-account-failure'),
              message: getErrorMessage(err),
            }),
          );
        })
        .finally(() => dispatch(hideLoading()));
    }
  };

  return (
    <div>
      <NavigationHeader
        breadcrumbLinks={breadcrumbLinks}
        showBottomLine={true}
      />
      <div className={styles.container}>
        <div className={styles.leftPart}>
          <div className={styles.title}>
            <FormattedMessage id={'my-account-label'} />
          </div>

          <div className={styles.divider} />

          <div className={styles.accountContainer}>
            <div className={styles.infoRow}>
              <div className={styles.infoLabel}>
                <FormattedMessage id={'first-name-label'} />
              </div>
              <div className={styles.infoValue}>{userInfo?.firstName}</div>
            </div>
            <div className={styles.infoRow}>
              <div className={styles.infoLabel}>
                <FormattedMessage id={'last-name-label'} />
              </div>
              <div className={styles.infoValue}>{userInfo?.lastName}</div>
            </div>
            <div className={styles.infoRow}>
              <div className={styles.infoLabel}>
                <FormattedMessage id={'email-address-label'} />
              </div>
              <div className={styles.infoValue}>{userInfo?.email}</div>
            </div>
            <div className={styles.infoRow}>
              <div className={styles.infoLabel}>
                <FormattedMessage id={'company-name-label'} />
              </div>
              <div className={styles.infoValue}>{userInfo?.companyName}</div>
            </div>
            <div className={styles.infoRow}>
              <div className={styles.infoLabel}>
                <FormattedMessage id={'phone-number-label'} />
              </div>
              <div className={styles.infoValue}>{userInfo?.phoneNumber}</div>
            </div>
            <div className={styles.infoRow}>
              <div className={styles.infoLabel}>
                <FormattedMessage id={'country-label'} />
              </div>
              <div className={styles.infoValue}>{country?.name}</div>
            </div>
            <div className={styles.infoRow}>
              <div className={styles.infoLabel}>
                <FormattedMessage id={'language-label'} />
              </div>
              <div className={styles.infoValue}>{language?.name}</div>
            </div>
          </div>
        </div>
        <div className={styles.rightPart}>
          <div className={styles.title}>
            <FormattedMessage id={'account-options-label'} />
          </div>
          <div className={styles.buttonContainer}>
            <Button
              onClick={() => navigate('/users/me/edit')}
              color={'green'}
              className={cn(styles.button, styles.editAccountBtn)}
            >
              <UserEditIcon />
              <FormattedMessage id={'edit-account-label'} />
            </Button>

            {!isSSOLogin && (
              <Button
                onClick={() => navigate('/users/me/change-password')}
                color={'green'}
                className={cn(styles.button, styles.changePasswordBtn)}
              >
                <LockIcon />
                <FormattedMessage id={'change-password-label'} />
              </Button>
            )}
            {!isSSOLogin && userInfo && userInfo.role !== 'Super Admin' && (
              <Button
                onClick={() => setShowUnregisterModal(true)}
                color={'ruby'}
                className={cn(styles.button, styles.unregisterBtn)}
              >
                <UserXIcon />
                <FormattedMessage id={'unregister-account-label'} />
              </Button>
            )}
          </div>
          <div className={cn(styles.divider)} />
          <SwitchToggle
            checked={receiveEmail}
            onClick={() => receiveEmailClicked(!receiveEmail)}
            label={getTranslatedMessage('receive-product-email-label')}
          />
        </div>
        {showUnregisterModal && (
          <UnregisterModal
            onClose={() => setShowUnregisterModal(false)}
            onUnregister={onUnregister}
          />
        )}
      </div>
    </div>
  );
}

export default MyAccountPage;
