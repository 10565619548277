import { useEffect, useLayoutEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import styles from './ManageFindADistributorLinkPage.module.scss';
import Header from '../../../../components/Admin/Header/Header';
import { PreviousLink } from '../../../../models/previous-link';
import SectorVisibility from './components/SectorVisibility/SectorVisibility';
import CountryVisibility from './components/CountryVisibility/CountryVisibility';
import { StepperItemType } from '../../../../models/admin-form-types';
import GenericManageSkeleton from '../../../../components/Admin/GenericManageSkeleton/GenericManageSkeleton';
import { DistributorLinks } from '../../../../models/distrubutor-links';
import {
  getDistributorLinks,
  updateDistributorLinks,
} from '../../../../services/DistributorLinkService';
import LoadingSpinner from '../../../../components/common/LoadingSpinner/LoadingSpinner';
import { showGenericModal } from '../../../../features/genericModalSlice';
import { addToast } from '../../../../features/toastSlice';
import { hideLoading, showLoading } from '../../../../features/loadingSlice';
import withAdmin from '../../../../hoc/withAdmin';

function ManageFindADistributorLinkPage() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [stateChanged, setStateChanged] = useState(false);
  const [distributorLinks, setDistributorLinks] = useState<DistributorLinks>();
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedSectors, setSelectedSectors] = useState<number[]>([]);
  const [selectedCountries, setSelectedCountries] = useState<number[]>([]);
  const intl = useIntl();

  useLayoutEffect(() => {
    setLoading(true);

    getDistributorLinks()
      .then((res) => {
        setDistributorLinks(res);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (distributorLinks) {
      setSelectedSectors(
        distributorLinks.sectors.reduce((acc: number[], current) => {
          if (current.supportFindADistributor) {
            acc.push(current.id);
          }
          return acc;
        }, []),
      );
      setSelectedCountries(
        distributorLinks.regions.reduce((acc: number[], current) => {
          current.countries.forEach((country) => {
            if (country.supportFindADistributor) {
              acc.push(country.id);
            }
          });
          return acc;
        }, []),
      );
    }
  }, [distributorLinks]);

  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });

  const breadcrumbLinks: PreviousLink[] = [
    {
      name: 'Home',
      to: '/home',
    },
    {
      name: intl.formatMessage({
        id: 'header-manage-distributor',
      }),
    },
  ];

  /* istanbul ignore next */
  const stepperItems: StepperItemType = [
    {
      label: getTranslatedMessage('sector-visibility'),
      componentToRender: (
        <SectorVisibility
          selectedSectors={selectedSectors}
          setSelectedSectors={(val) => {
            setSelectedSectors(val);
            setStateChanged(true);
          }}
          sectors={distributorLinks?.sectors}
        />
      ),
    },
    {
      label: getTranslatedMessage('country-visibility'),
      componentToRender: (
        <CountryVisibility
          selected={selectedCountries}
          setSelected={(val) => {
            setSelectedCountries(val);
            setStateChanged(true);
          }}
          regions={distributorLinks?.regions}
        />
      ),
    },
  ];

  const saveDisabled = () => {
    if (currentStep === 0) {
      return selectedSectors.length <= 0;
    }

    if (currentStep === 1) {
      return selectedCountries.length <= 0;
    }

    return false;
  };

  const handleSaveBtnClick = () => {
    if (currentStep === 1) {
      const countriesSet = new Set(selectedCountries);
      const payload: any = {
        sectors: distributorLinks?.sectors.map(({ id }) => ({
          id,
          supportFindADistributor: selectedSectors.includes(id),
        })),
        regions: distributorLinks?.regions.map(({ id, name, countries }) => ({
          id,
          name,
          countries: countries.map(({ id, name }) => ({
            id,
            name,
            supportFindADistributor: countriesSet.has(id),
          })),
        })),
      };

      dispatch(showLoading());
      updateDistributorLinks(payload)
        .then(() => {
          dispatch(
            showGenericModal({
              titleId: 'find-distributor-link-update-success',
              messageId: 'find-distributor-link-update-success-message',
              to: '/',
            }),
          );
        })
        .catch(() => {
          setLoading(false);
          dispatch(
            addToast({
              type: 'error',
              title: intl.formatMessage({
                id: 'find-distributor-link-update-failure',
              }),
              message: '',
            }),
          );
        })
        .finally(() => {
          dispatch(hideLoading());
        });
      return;
    }

    setCurrentStep(currentStep + 1);
  };

  return (
    <div className={styles.container}>
      <Header
        breadcrumbLinks={breadcrumbLinks}
        titleId={'header-manage-distributor'}
      />
      {loading ? (
        <LoadingSpinner className={styles.loading} />
      ) : (
        <GenericManageSkeleton
          step={currentStep}
          stepperItems={stepperItems}
          saveBtnDisabled={saveDisabled()}
          onBackBtnClick={() => setCurrentStep(currentStep - 1)}
          onSaveBtnClick={handleSaveBtnClick}
          showUnsavedChangesModal={stateChanged}
        >
          {stepperItems[currentStep].componentToRender}
        </GenericManageSkeleton>
      )}
    </div>
  );
}

export default withAdmin(ManageFindADistributorLinkPage);
