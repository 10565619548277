import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import Paginator, {
  PaginatorType,
} from '../../../../../components/common/Paginator/Paginator';
import { ReactComponent as InfoIcon } from '../../../../../icons/info.svg';
import { ReactComponent as SortArrow } from '../../../../../icons/sort-arrow.svg';
import styles from './HelpTable.module.scss';
import TableMobileView from '../../../TableMobileView/TableMobileView';
import { TableColumn } from '../../../../../models/table-column';
import Button from '../../../../../components/common/Button/Button';
import { ReactComponent as EditIcon } from '../../../../../icons/edit-white.svg';
import { ReactComponent as DustbinIcon } from '../../../../../icons/dustbin.svg';
import { Help } from '../../../../../models/help';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../../../store';
import { downloadHelpDocument } from '../../../../../services/ManageHelpService';
import { useNavigate } from 'react-router';
import { useState } from 'react';
import DescriptionPreviewModal from '../../../../../components/Admin/common/DescriptionPreviewModal/DescriptionPreviewModal';

interface HelpTableProps extends PaginatorType {
  helps: Help[];
  sortBy: string;
  sortDirection: string;
  onSortChange: (column: string) => void;
  deleteHelp: (id: number) => void;
}

export default function HelpTable({
  helps,
  page,
  perPage,
  total,
  onPageChange,
  onPerPageChange,
  sortBy,
  sortDirection,
  onSortChange,
  deleteHelp,
}: HelpTableProps) {
  const intl = useIntl();
  const navigate = useNavigate();

  const { helpTypesById } = useSelector((state: RootStore) => state.lookups);

  const [showDescriptionModal, setShowDescriptionModal] = useState(false);
  const [modalDescription, setModalDescription] = useState('');

  const showDescription = (description: string) => {
    setModalDescription(description);
    setShowDescriptionModal(true);
  };

  const onEdit = (help: Help) => {
    navigate(`${help.id}/edit`);
  };

  const onView = (help: Help) => {
    navigate(`${help.id}`);
  };

  const columns: TableColumn<any>[] = [
    {
      key: 'captionName',
      label: intl.formatMessage({ id: 'caption-name-label' }),
      property: 'captionName',
      render: (data) => (
        <span className={styles.greenText}>{data.captionName}</span>
      ),
    },
    {
      key: 'descriptions',
      label: intl.formatMessage({ id: 'description-label' }),
      property: 'description',
      className: styles.descColumn,
      render: (data) => (
        <span
          onClick={() => showDescription(data.description)}
          className={styles.link}
        >
          <FormattedMessage id={'view-details-label'} />
        </span>
      ),
    },
    {
      key: 'link',
      label: intl.formatMessage({ id: 'link-label' }),
      property: 'link',
      render: (data) => (
        <span
          className={styles.link}
          onClick={() => downloadHelpDocument(data.id)}
        >
          <FormattedMessage id={'download-label'} />
        </span>
      ),
    },
    {
      key: 'type',
      label: intl.formatMessage({ id: 'type-label' }),
      property: 'type',
      render: (data) => helpTypesById[data.helpTypeId]?.name,
    },
  ];

  const mobileRowActions = (help: Help) => (
    <>
      <Button
        onClick={() => onEdit(help)}
        className={styles.mobileActionBtn}
        color={'green'}
      >
        <EditIcon className={styles.actionIcon} />
        <FormattedMessage id={'edit-help'} />
      </Button>
      <Button
        onClick={() => deleteHelp(help.id)}
        className={styles.mobileActionBtn}
        color={'red'}
      >
        <DustbinIcon className={styles.actionIcon} />
        <FormattedMessage id={'delete-help'} />
      </Button>
    </>
  );

  return (
    <>
      <div>
        <div className={styles.tableHeader}>
          <div className={styles.tableRow}>
            <div
              style={{ flex: '1 1 90px' }}
              onClick={() => onSortChange('captionName')}
              className={cn(
                styles.tableLabelColumn,
                styles.tableColumn,
                styles.sortableColumn,
              )}
            >
              <FormattedMessage id={'caption-name-label'} />
              {sortBy === 'captionName' && (
                <SortArrow
                  className={cn(
                    styles.sortIcon,
                    sortDirection === 'asc' && styles.asc,
                  )}
                />
              )}
            </div>
            <div
              style={{ flex: '1 1 200px' }}
              className={cn(styles.tableLabelColumn, styles.tableColumn)}
            >
              <FormattedMessage id={'description-label'} />
            </div>
            <div
              style={{ flex: '1 1 60px' }}
              className={cn(
                styles.tableLabelColumn,
                styles.tableColumn,
                styles.columnWithIcon,
              )}
            >
              <FormattedMessage id={'link-label'} />
              <InfoIcon data-tip data-for={'link-label'} />
              <ReactTooltip id={'link-label'} place={'top'} effect={'solid'}>
                <FormattedMessage id={'link-label'} />
              </ReactTooltip>
            </div>
            <div
              style={{ flex: '1 1 60px' }}
              onClick={() => onSortChange('helpType.name')}
              className={cn(
                styles.tableLabelColumn,
                styles.tableColumn,
                styles.sortableColumn,
              )}
            >
              <FormattedMessage id={'type-label'} />
              {sortBy === 'helpType.name' && (
                <SortArrow
                  className={cn(
                    styles.sortIcon,
                    sortDirection === 'asc' && styles.asc,
                  )}
                />
              )}
            </div>
            <div
              style={{ flex: '1 1 120px' }}
              className={cn(styles.tableLabelColumn, styles.tableColumn)}
            >
              <FormattedMessage id={'actions-label'} />
            </div>
          </div>
        </div>

        <div className={cn(styles.tableBody, styles.desktop)}>
          {helps.map((help, index) => (
            <div className={cn(styles.tableRow)} key={index}>
              <div
                style={{ flex: '1 1 90px' }}
                className={cn(
                  styles.tableColumn,
                  styles.linkText,
                  styles.captionName,
                )}
              >
                {help.captionName}
              </div>
              <div
                style={{ flex: '1 1 200px' }}
                className={cn(styles.tableColumn)}
              >
                <span
                  onClick={() => showDescription(help.description)}
                  className={styles.link}
                >
                  <FormattedMessage id={'view-details-label'} />
                </span>
              </div>
              <div
                style={{ flex: '1 1 60px' }}
                className={cn(styles.tableColumn)}
              >
                <span
                  className={styles.link}
                  onClick={() => downloadHelpDocument(help.id)}
                >
                  <FormattedMessage id={'download-label'} />
                </span>
              </div>
              <div
                style={{ flex: '1 1 60px' }}
                className={cn(styles.tableColumn)}
              >
                {helpTypesById[help.helpTypeId]?.name}
              </div>
              <div
                style={{ flex: '1 1 120px' }}
                className={cn(styles.tableColumn, styles.action)}
              >
                <span
                  onClick={() => onView(help)}
                  className={cn(styles.actionBtn, styles.link)}
                >
                  View
                </span>
                <span
                  onClick={() => onEdit(help)}
                  className={cn(styles.actionBtn, styles.link)}
                >
                  Edit
                </span>
                <span
                  onClick={() => deleteHelp(help.id)}
                  className={cn(styles.actionBtn, styles.link)}
                >
                  Delete
                </span>
              </div>
            </div>
          ))}
        </div>

        <div className={cn(styles.tableBody, styles.mobile)}>
          <TableMobileView
            rows={helps}
            columns={columns}
            actions={mobileRowActions}
            className={styles.tableMobileView}
          />
        </div>

        <Paginator
          page={page}
          perPage={perPage}
          total={total}
          onPageChange={onPageChange}
          onPerPageChange={onPerPageChange}
        />
      </div>
      {showDescriptionModal && (
        <DescriptionPreviewModal
          modalDescription={modalDescription}
          onHideModal={() => setShowDescriptionModal(false)}
        />
      )}
    </>
  );
}
