import { PropsWithoutRef, useState, useEffect } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import Select from '../Select/Select';
import styles from './Paginator.module.scss';

export type PaginatorType = {
  page: number;
  perPage: number;
  total: number;
  className?: string;
  onPerPageChange: (val: number) => void;
  onPageChange: (val: number) => void;
};

const Paginator = ({
  page,
  perPage,
  total,
  className,
  onPerPageChange,
  onPageChange,
}: PropsWithoutRef<PaginatorType>) => {
  const perPageOptions = [5, 10, 20];
  const [pageVal, setPageVal] = useState(page);
  const [midPages, setMidPages] = useState([page - 1, page, page + 1]);
  const [perPageVal, setPerPageVal] = useState(perPageOptions[0]);

  const clickPage = (value: number) => {
    setPageVal(value);
    setMidPages([value - 1, value, value + 1]);
    onPageChange(value);
  };

  const first = total > 0 ? perPage * (page - 1) + 1 : 0;
  let end = page * perPage;
  end = total > end ? end : total;
  const totalPages = Math.ceil(total / perPage);

  useEffect(() => {
    setPerPageVal(perPage);
  }, [perPage]);

  useEffect(() => {
    setPageVal(page);
    setMidPages([page - 1, page, page + 1]);
  }, [page]);

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.perPagePart}>
        <Select
          className={styles.perPageSelector}
          inputClassName={styles.perPageInput}
          value={perPageVal}
          options={perPageOptions.map((item) => ({
            name: String(item),
            value: item,
          }))}
          placeholder={''}
          onChange={onPerPageChange}
        />
        <span className={styles.desktop}>
          <FormattedMessage
            id={'per-page-label'}
            values={{ first, end, total }}
          />
        </span>
        <span className={styles.mobile}>
          <FormattedMessage
            id={'per-page-label-mobile'}
            values={{
              first,
              end,
              total,
            }}
          />
        </span>
      </div>
      <div className={styles.pagesPart}>
        <div
          className={cn(styles.pageItem, pageVal === 1 && styles.active)}
          onClick={() => clickPage(1)}
        >
          1
        </div>
        {midPages[0] > 2 && <div className={styles.pageItem}>{'...'}</div>}
        {midPages
          .filter((item) => item > 1 && item < totalPages)
          .map((item) => (
            <div
              key={item}
              className={cn(styles.pageItem, pageVal === item && styles.active)}
              onClick={() => clickPage(item)}
            >
              {item}
            </div>
          ))}
        {midPages[2] < totalPages - 1 && (
          <div className={styles.pageItem}>{'...'}</div>
        )}
        {totalPages > 1 && (
          <div
            className={cn(
              styles.pageItem,
              pageVal === totalPages && styles.active,
            )}
            onClick={() => clickPage(totalPages)}
          >
            {totalPages}
          </div>
        )}
      </div>
    </div>
  );
};

export default Paginator;
