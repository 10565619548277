import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
  GenericFormProps,
  AddProductFormValues,
  RangeFamily,
} from '../../../../../../models/admin-form-types';
import '../../../../../../components/Admin/shared.scss';
import { RootStore } from '../../../../../../store';
import FormWrapper from '../../../../../../components/Admin/FormWrapper/FormWrapper';
import InputRow from '../../../../../../components/Admin/InputRow/InputRow';
import StepContentContainer from '../../../../../../components/Admin/StepContentContainer/StepContentContainer';
import CheckboxRow from '../../../../../../components/Admin/CheckboxRow/CheckboxRow';
import FormRow from '../../../../../../components/Admin/FormRow/FormRow';
import { useEffect, useMemo } from 'react';
import SelectFormRow from '../../../../../../components/Admin/SelectFormRow/SelectFormRow';
// import ProductCountryLevelsList from '../../../components/ProductCountryLevelsList/ProductCountryLevelsList';
import MultipleSelect from '../../../../../../components/Admin/MultiSelect/MultiSelect';
import styles from './Categorisation.module.scss';

type CategorisationProps = GenericFormProps<AddProductFormValues> & {
  families: RangeFamily[];
  formValue: AddProductFormValues;
  isEdit?: boolean;
};

const Categorisation = ({
  families,
  formValue,
  handleValueChange,
}: CategorisationProps) => {
  const intl = useIntl();

  const {
    masterBrands,
    categories,
    categoriesById,
    subCategories,
    categorySubCategories,
    productTypes,
    applications,
    subApplications,
    sectors,
  } = useSelector((state: RootStore) => state.lookups);

  const masterBrandOptions = useMemo(
    () => masterBrands.map((c) => ({ name: c.name, value: c.id })),
    [masterBrands],
  );

  const familyOptions = useMemo(
    () => families.map((c) => ({ name: c.name, value: c.id })),
    [families],
  );

  const rangeOptions = useMemo(
    () =>
      families
        .find((family) => formValue.productFamily === family.id)
        ?.ranges?.map((range) => ({
          name: range.name,
          value: range.id,
        })) || [],
    [families, formValue.productFamily],
  );

  const categoryOptions = useMemo(
    () => categories.map((c) => ({ name: c.name, value: c.id })),
    [categories],
  );

  const industrial = useMemo(() => {
    return sectors.find((x) => x.friendlyUrl === 'industrial')?.id || 4;
  }, sectors);

  const subCategoryOptions = useMemo(() => {
    if (formValue.productCategory !== null) {
      const subCategoryIds = new Map<number, number>();
      categorySubCategories.forEach((subCategory) => {
        if (formValue.productCategory.includes(subCategory.categoryId)) {
          subCategoryIds.set(subCategory.subCategoryId, subCategory.categoryId);
        }
      });
      const empty = [
        {
          name: intl.formatMessage({
            id: 'add-product-select-placeholder',
          }),
          value: null as any,
        },
      ];

      const items = subCategories
        .filter((subCategory) => (subCategoryIds.get(subCategory.id) || 0) > 0)
        .map((item) => {
          const categoryId = subCategoryIds.get(item.id) || 0;
          const category = categoriesById[categoryId];
          return {
            name: item.name + ' (' + category.name + ')',
            value: item.id,
          };
        });

      return items.length > 0 ? empty.concat(items) : items;
    } else {
      return [];
    }
  }, [subCategories, categorySubCategories, formValue.productCategory]);

  const productTypeItems = useMemo(
    () =>
      productTypes.map((item) => ({
        name: item.name,
        value: item.id,
      })),
    [productTypes],
  );

  const applicationsItems = useMemo(
    () =>
      applications.map((item) => ({
        name: item.name,
        value: item.id,
      })),
    [applications],
  );

  const subApplicationsItems = useMemo(() => {
    if (
      !formValue?.applications ||
      formValue?.applications.length === 0 ||
      !subApplications.some((item) => {
        return formValue?.applications?.some(
          (app) => app === item.applicationId,
        );
      })
    ) {
      return {
        items: subApplications.map((item) => ({
          name: item.name,
          value: item.id,
        })),
        disabled: true,
      };
    }
    return {
      items: subApplications
        .filter((item) => {
          return formValue?.applications?.some(
            (app) => app === item.applicationId,
          );
        })
        .map((item) => ({
          name: item.name,
          value: item.id,
        })),
      disabled: false,
    };
  }, [subApplications, formValue?.applications]);

  useEffect(() => {
    handleValueChange('subApplications', []);
  }, [subApplications, formValue?.applications]);

  return (
    <StepContentContainer
      title={intl.formatMessage({ id: 'add-product-step-product-overview' })}
    >
      <FormWrapper
        title={intl.formatMessage({ id: 'add-product-step-categorisation' })}
      >
        <div className={'columnRow'}>
          <SelectFormRow
            labelId={'product-master-brand-label'}
            options={masterBrandOptions}
            value={formValue.masterBrand}
            onChange={(value: number) =>
              handleValueChange('masterBrand', value)
            }
            required
            tooltip={intl.formatMessage({ id: 'product-master-brand-tooltip' })}
          />
        </div>
        <div className={'columnRow'}>
          <SelectFormRow
            labelId={'product-family-label'}
            options={familyOptions}
            value={formValue.productFamily}
            onChange={(value: number) => {
              handleValueChange('productFamily', value);
              handleValueChange('productRange', null);
            }}
            required
            tooltip={intl.formatMessage({ id: 'product-family-tooltip' })}
          />
          <SelectFormRow
            labelId={'product-range-label'}
            options={rangeOptions}
            value={formValue.productRange}
            onChange={(value: number) =>
              handleValueChange('productRange', value)
            }
            required={false}
            disabled={!rangeOptions || rangeOptions.length === 0}
            tooltip={intl.formatMessage({ id: 'product-range-tooltip' })}
          />
        </div>
        <div className={'columnRow'}>
          <FormRow
            labelId={'product-category-label'}
            required={!formValue.displayOnlyPartialInfo}
            tooltip={intl.formatMessage({ id: 'product-category-tooltip' })}
          >
            <MultipleSelect
              className={styles.multiSelect}
              values={formValue.productCategory}
              options={categoryOptions}
              onChange={(value: number[]) => {
                handleValueChange('productCategory', value);
                handleValueChange('productSubCategory', null);
              }}
              placeholder={intl.formatMessage({ id: 'select-placeholder' })}
              canSearch
            />
          </FormRow>
        </div>
        <div className={'columnRow'}>
          <SelectFormRow
            labelId={'product-sub-category-label'}
            options={subCategoryOptions}
            value={formValue.productSubCategory}
            onChange={(value: number) =>
              handleValueChange('productSubCategory', value)
            }
            required={false}
            tooltip={intl.formatMessage({ id: 'product-sub-category-tooltip' })}
            disabled={!subCategoryOptions || subCategoryOptions.length === 0}
          />
        </div>
        <div className={'columnRow'}>
          {formValue.sectorId.some((x) => x === industrial) && (
            <FormRow
              labelId={'product-type-label'}
              required={
                !formValue.displayOnlyPartialInfo &&
                formValue.sectorId.some((x) => x === industrial)
              }
              tooltip={intl.formatMessage({ id: 'product-type-tooltip' })}
            >
              <MultipleSelect
                className={styles.multiSelect}
                values={formValue.productTypes}
                options={productTypeItems}
                onChange={(value: number[]) =>
                  handleValueChange('productTypes', value)
                }
                placeholder={intl.formatMessage({ id: 'select-placeholder' })}
                canSearch
              />
            </FormRow>
          )}
          <FormRow
            labelId={'product-application-label'}
            required={!formValue.displayOnlyPartialInfo}
            tooltip={intl.formatMessage({ id: 'product-application-tooltip' })}
          >
            <MultipleSelect
              className={styles.multiSelect}
              values={formValue.applications}
              options={applicationsItems}
              onChange={(value: number[]) =>
                handleValueChange('applications', value)
              }
              placeholder={intl.formatMessage({ id: 'select-placeholder' })}
              canSearch
              showTooltip={true}
            />
          </FormRow>
          <FormRow
            labelId={'product-sub-application-label'}
            required={false}
            tooltip={intl.formatMessage({
              id: 'product-sub-application-tooltip',
            })}
          >
            <MultipleSelect
              disabled={subApplicationsItems.disabled}
              className={styles.multiSelect}
              values={formValue.subApplications}
              options={subApplicationsItems.items}
              onChange={(value: number[]) =>
                handleValueChange('subApplications', value)
              }
              placeholder={intl.formatMessage({ id: 'select-placeholder' })}
              canSearch
            />
          </FormRow>
        </div>
        <div className={'columnRow'}>
          <InputRow
            labelId={'repository-business-label'}
            value={formValue.repositoryBusiness}
            onChange={(value: string) =>
              handleValueChange('repositoryBusiness', value)
            }
            tooltip={intl.formatMessage({ id: 'repository-business-tooltip' })}
            required={!formValue.displayOnlyPartialInfo}
          />
        </div>
        <div className={'columnRow'}>
          <FormRow>
            <CheckboxRow
              label={<FormattedMessage id={'catrol-recommended-label'} />}
              checked={formValue.recommendedByCastrol}
              onClick={() =>
                handleValueChange(
                  'recommendedByCastrol',
                  !formValue.recommendedByCastrol,
                )
              }
              tooltip={intl.formatMessage({
                id: 'catrol-recommended-tooltip',
              })}
            />
          </FormRow>
        </div>
        {/* {isEdit && (
          <div className={'columnRow'}>
            <FormRow labelId={'product-status-label'} required={false}>
              <ProductCountryLevelsList
                className={styles.productCountrylevels}
                countryLevels={formValue.productCountryLevels}
              />
            </FormRow>
          </div>
        )} */}
      </FormWrapper>
    </StepContentContainer>
  );
};

export default Categorisation;
