import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
  GenericFormProps,
  RegionAvailabilityType,
} from '../../../../../../models/admin-form-types';
import '../../../../../../components/Admin/shared.scss';
import { RootStore } from '../../../../../../store';
import FormWrapper from '../../../../../../components/Admin/FormWrapper/FormWrapper';
import StepContentContainer from '../../../../../../components/Admin/StepContentContainer/StepContentContainer';
import FormRow from '../../../../../../components/Admin/FormRow/FormRow';
import { useMemo } from 'react';
import styles from './RegionAvailability.module.scss';
import CheckboxTree from '../../../../../../components/common/CheckboxTree/CheckboxTree';
import { CountryItem } from '../../../../../../models/lookups';

type RegionAvailabilityProps = GenericFormProps<RegionAvailabilityType> & {
  formValue: RegionAvailabilityType;
};

const RegionAvailability = ({
  formValue,
  handleValueChange,
}: RegionAvailabilityProps) => {
  const intl = useIntl();
  const { countries, regions } = useSelector(
    (state: RootStore) => state.lookups,
  );

  const countryTree = useMemo(() => {
    const countriesByRegion = new Map<number, CountryItem[]>();
    countries.forEach((country) => {
      if (!countriesByRegion.has(country.regionId)) {
        countriesByRegion.set(country.regionId, []);
      }
      countriesByRegion.get(country.regionId)?.push(country);
    });
    return regions.map((region) => ({
      value: region.id,
      label: region.name,
      children: (countriesByRegion.get(region.id) || []).map((country) => ({
        value: country.id,
        label: country.name,
      })),
    }));
  }, [countries, regions]);

  return (
    <StepContentContainer
      title={intl.formatMessage({ id: 'product-availability-title' })}
    >
      <FormWrapper
        title={intl.formatMessage({
          id: 'add-product-step-region-availability',
        })}
      >
        <div className="columnRow">
          <FormRow
            labelId="product-avaiable-region-available-regions-label"
            tooltip={intl.formatMessage({
              id: 'product-avaiable-region-available-regions-tooltip',
            })}
          >
            <CheckboxTree
              className={styles.countriesTree}
              tree={countryTree}
              selected={formValue.avaiableCountries}
              setSelected={(value) =>
                handleValueChange('avaiableCountries', value)
              }
            />
          </FormRow>
        </div>
      </FormWrapper>
    </StepContentContainer>
  );
};

export default RegionAvailability;
