import styles from './WelcomeForm.module.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '../../Button/Button';
import Select from '../../Select/Select';
import { PropsWithoutRef, ReactElement, useEffect, useState } from 'react';
import Checkbox from '../../Checkbox/Checkbox';
import { ReactComponent as ArrowRight } from '../../../../icons/arrow-right.svg';
import { OptionWithImage } from '../../../../models/option-with-image';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import { WelcomeInfo } from '../../../../models/welcome-info';
import {
  setCultureToLocalStorage,
  setSectorToLocalStorage,
  setWelcomeInfoToStorage,
} from '../../../../services/LocalStorageService';
import { useDispatch } from 'react-redux';
import {
  setCulture,
  setSector,
  setWelcomeInfo,
} from '../../../../features/welcomeInfoSlice';
import {
  CountryItem,
  LanguageItem,
  Lookups,
  SectorItem,
} from '../../../../models/lookups';
import { getSectorAndCulture } from '../../../../utils/welcome-info.util';
import {
  filterCountryByCulture,
  filterLanguageByCulture,
} from '../../../../utils/lookup.util';
import { getCurrentCountry } from '../../../../services/HomeService';
import { cloneDeep } from 'lodash';
import config from '../../../../configs/config';
import { setLookups } from '../../../../features/lookupsSlice';
import { useNavigate } from 'react-router';
import { getAccessToken } from '../../../../utils/api.util';

interface WelcomeFormProps {
  onFormSubmit: () => void;
  lookups: Lookups;
}

/**
 * welcome form component
 * @param onFormSubmit handle after submit
 * @param lookups lookups
 */
function WelcomeForm({
  onFormSubmit,
  lookups,
}: PropsWithoutRef<WelcomeFormProps>) {
  // declare the hooks.
  const navigate = useNavigate();
  const intl = useIntl();
  const dispatch = useDispatch();

  // init local data from incoming props.
  const { sectors, countries, languages } = lookups;

  // setting up states.
  const [submitting, setSubmitting] = useState(false);
  const [industrySector, setIndustrySector] = useState<SectorItem>();
  const [country, setCountry] = useState<CountryItem | null>();
  const [language, setLanguage] = useState<LanguageItem | null>();
  const [industrySectorId, setIndustrySectorId] = useState<number>();
  const [countryId, setCountryId] = useState<number>();
  const [languageId, setLanguageId] = useState<number>();
  const [remember, setRemember] = useState(false);
  const [countryLoading, setCountryLoading] = useState(false);

  const isMarine =
    industrySector &&
    industrySector.friendlyUrl === 'directory-marine-services';

  const requiredError = intl.formatMessage({ id: 'field-required-error' });

  const industrySectorOptions = sectors.map((item) => ({
    name: item.name,
    value: item.id,
  }));

  const countryOptions = countries.map((item) => ({
    name: item.name,
    value: item.id,
  }));

  const languageOptions = (
    !config.ENABLE_LANGUAGE
      ? [{ id: 1, name: 'English', englishName: 'English', code: 'en' }]
      : languages
  ).map((item) => ({
    name: item.name,
    value: item.id,
  }));

  const canSubmit = !!country && !!language && !!industrySector;

  const onUseCurrentLocation = () => {
    const currentCulture = navigator.language || 'en-GB';
    const defaultCountry = filterCountryByCulture(countries, 'en-GB');
    const defaultLanguage = filterLanguageByCulture(languages, 'en-GB');
    setCountryLoading(true);

    navigator.geolocation?.getCurrentPosition((res) => {
      const { latitude, longitude } = res.coords;

      getCurrentCountry(latitude, longitude)
        .then((res) => {
          const result = res.results.find(
            (item) => item.types.indexOf('country') !== -1,
          );
          const geoCountry = (result?.address_components || []).find(
            (item) => item.types.indexOf('country') !== -1,
          );
          const geoCulture = `${currentCulture.split('-')[0]}-${
            geoCountry?.short_name || 'GB'
          }`;

          const countryTemp = filterCountryByCulture(countries, geoCulture);
          setCountry(countryTemp || defaultCountry);
          setLanguage(
            filterLanguageByCulture(languages, geoCulture) || defaultLanguage,
          );

          setCountryId(countryTemp?.id || defaultCountry?.id);
          setLanguageId(
            filterLanguageByCulture(languages, geoCulture)?.id ||
              defaultLanguage?.id,
          );
        })
        .finally(() => {
          setCountryLoading(false);
        });
    });
  };

  const countryAndLanguageRenderer = (item: any): ReactElement => {
    item = item as OptionWithImage<string>;

    return <div className={styles.optionContainer}>{item?.name}</div>;
  };

  const submitForm = async () => {
    const info: WelcomeInfo = {
      industrySector: cloneDeep(industrySector || null),
      country: cloneDeep(country || null),
      language: cloneDeep(language || null),
    };
    const { culture, sector } = getSectorAndCulture(info);

    // store the lookups if culture state same as default
    if (culture === config.DEFAULT_CULTURE) {
      dispatch(setLookups(lookups));
    }

    await getAccessToken(culture, sector);
    if (remember) {
      setWelcomeInfoToStorage(info);
      setCultureToLocalStorage(culture);
      setSectorToLocalStorage(sector);
    }

    setSubmitting(true);
    dispatch(setWelcomeInfo(info));
    dispatch(setCulture(culture));
    dispatch(setSector(sector));
    setSubmitting(false);
    onFormSubmit();

    if (location.pathname === '/') {
      navigate(`/${sector}/${culture}`);
    } else if (
      location.pathname.startsWith('/product') ||
      location.pathname.startsWith('/family') ||
      location.pathname.startsWith('/range')
    ) {
      navigate(`${location.pathname}/${sector}/${culture}${location.search}`);
    }
  };

  const onSectorChange = (id: number) => {
    setIndustrySectorId(id);
    setIndustrySector(sectors.find((item) => item.id === id));
  };

  const onCountyChange = (id: number) => {
    setCountryId(id);
    setCountry(countries.find((item) => item.id === id));
  };

  const onLanguageChange = (id: number) => {
    setLanguageId(id);
    setLanguage(languages.find((item) => item.id === id));
  };

  // effects.

  /**
   * Effect to change language on language collection change.
   */
  useEffect(() => {
    if (languages && languages.length > 0) {
      onLanguageChange(languages[0]?.id);
    }
  }, [languages]);

  return (
    <div className={styles.container}>
      <div className={styles.fieldContainer}>
        <h3>
          <FormattedMessage id={'industry-sector-label'} />
        </h3>

        {/* Safe to use dangerouslySetInnerHTML here since we are reading from translation file */}
        <p
          className={styles.topDescriptions}
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({
              id: 'welcome-industry-sector-description',
            }),
          }}
        />

        <Select
          required={true}
          className={styles.formControl}
          value={industrySectorId as number}
          options={industrySectorOptions}
          onChange={onSectorChange}
          placeholder={'Select'}
          errorMessage={requiredError}
        />
      </div>

      <div className={styles.fieldContainer}>
        <div className={styles.labelContainer}>
          <h3>
            <FormattedMessage id={'country-label'} />
          </h3>

          {countryLoading ? (
            <LoadingSpinner className={styles.loading} />
          ) : (
            <Button
              onClick={() => onUseCurrentLocation()}
              color={'transparent'}
              className={styles.locationButton}
            >
              <FormattedMessage id={'welcome-use-current-location-label'} />
            </Button>
          )}
        </div>

        <Select
          required={true}
          className={styles.formControl}
          value={countryId as number}
          options={countryOptions}
          onChange={onCountyChange}
          placeholder={'Select'}
          errorMessage={requiredError}
          canSearch
        />
      </div>

      <div className={styles.fieldContainer}>
        <h3>
          <FormattedMessage id={'language-label'} />
        </h3>

        <Select
          required={true}
          className={styles.formControl}
          value={languageId as number}
          options={languageOptions}
          onChange={onLanguageChange}
          placeholder={'Select'}
          errorMessage={requiredError}
          optionRenderer={countryAndLanguageRenderer}
        />
      </div>

      <div className={styles.checkboxContainer}>
        <div onClick={() => setRemember(!remember)} className={styles.checkbox}>
          <Checkbox checked={remember} />

          <p>
            <FormattedMessage id={'welcome-remember-label'} />
          </p>
        </div>
      </div>

      <div className={styles.formFooter}>
        {/* Safe to use dangerouslySetInnerHTML here since we are reading from translation file */}
        {isMarine ? (
          <p
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: 'welcome-terms-and-conditions-description',
              }),
            }}
          />
        ) : (
          <p />
        )}

        {submitting ? (
          <Button
            disabled={true}
            color={'green'}
            className={styles.continueButton}
          >
            <LoadingSpinner className={styles.buttonLoading} />
          </Button>
        ) : (
          <Button
            onClick={() => submitForm()}
            disabled={!canSubmit}
            color={'green'}
            className={styles.continueButton}
          >
            <FormattedMessage id={'continue-label'} />

            <ArrowRight className={styles.arrow} />
          </Button>
        )}
      </div>
    </div>
  );
}

export default WelcomeForm;
