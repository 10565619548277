import styles from './ContactUsForm.module.scss';
import { PropsWithoutRef, useMemo, useState } from 'react';
import cn from 'classnames';
import FormField from '../../FormField/FormField';
import { FormattedMessage, useIntl } from 'react-intl';
import Input from '../../Input/Input';
import Select from '../../Select/Select';
import Textarea from '../../Textarea/Textarea';
import Button from '../../Button/Button';
import { submitContactUs } from '../../../../services/HomeService';
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from '../../../../features/toastSlice';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import { RootStore } from '../../../../store';
import { emailValidator } from '../../../../utils/string.util';
import { PageType } from '../../../../features/contactUsSlice';

interface ContactUsFormProps {
  className?: string;
  onCancel: () => void;
  page?: PageType;
  id?: number;
}

/**
 * contact us form
 * @param className classname
 * @param onCancel handle cancel click
 */
function ContactUsForm({
  className,
  onCancel,
  id,
  page,
}: PropsWithoutRef<ContactUsFormProps>) {
  const intl = useIntl();
  const selectPlaceholder = intl.formatMessage({ id: 'select-placeholder' });

  const { contactUsRequestSubjects, countries, sectors } = useSelector(
    (rootState: RootStore) => rootState.lookups,
  );
  const { welcomeInfo } = useSelector(
    (rootState: RootStore) => rootState.welcomeInfo,
  );

  const [fullName, setFullName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [lineOfBusiness, setLineOfBusiness] = useState(
    `${welcomeInfo?.industrySector?.id}` || '',
  );
  const [countryOfBusiness, setCountryOfBusiness] = useState(
    `${welcomeInfo?.country?.id}` || '',
  );
  const [subject, setSubject] = useState('');
  const [commentOrQuestion, setCommentOrQuestion] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();

  const fieldRequiredMessage = intl.formatMessage({
    id: 'field-required-error',
  });
  const submittable =
    fullName &&
    emailAddress &&
    !emailValidator(emailAddress) &&
    companyName &&
    lineOfBusiness &&
    countryOfBusiness &&
    subject &&
    commentOrQuestion;

  const countryOfBusinessOptions = useMemo(
    () =>
      countries.map((country) => ({
        name: country.name,
        value: String(country.id),
      })),
    [countries],
  );
  const subjectOptions = useMemo(
    () =>
      contactUsRequestSubjects.map((subject) => ({
        name: subject.name,
        value: String(subject.id),
      })),
    [contactUsRequestSubjects],
  );

  const buildPageData = () => {
    let pageData = {};
    if (page === 'Range') {
      pageData = {
        ...pageData,
        rangeId: id,
      };
    }
    if (page === 'Product') {
      pageData = {
        ...pageData,
        productId: id,
      };
    }
    if (page === 'Family') {
      pageData = {
        ...pageData,
        familyId: id,
      };
    }

    return pageData;
  };

  const onSubmit = () => {
    setSubmitting(true);

    submitContactUs({
      name: fullName,
      email: emailAddress,
      companyName,
      sectorId: Number(lineOfBusiness),
      countryId: Number(countryOfBusiness),
      contactUsRequestSubjectId: Number(subject),
      comment: commentOrQuestion,
      ...buildPageData(),
    })
      .then(() => {
        dispatch(
          addToast({
            type: 'success',
            title: intl.formatMessage({ id: 'contact-us-success-title' }),
            message: intl.formatMessage({ id: 'contact-us-success-message' }),
          }),
        );

        onCancel();
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className={cn(styles.container, className)}>
      <FormField
        className={styles.formField}
        label={intl.formatMessage({ id: 'full-name-label' })}
      >
        <Input
          value={fullName}
          required={true}
          onChange={(value) => setFullName(value)}
          errorMessage={fieldRequiredMessage}
        />
      </FormField>

      <FormField
        className={styles.formField}
        label={intl.formatMessage({ id: 'email-address-label' })}
      >
        <Input
          value={emailAddress}
          required={true}
          onChange={(value) => setEmailAddress(value)}
          errorMessage={intl.formatMessage({ id: 'email-invalid-error' })}
          customValidator={emailValidator}
        />
      </FormField>

      <FormField
        className={styles.formField}
        label={intl.formatMessage({ id: 'company-name-label' })}
      >
        <Input
          value={companyName}
          required={true}
          onChange={(value) => setCompanyName(value)}
          errorMessage={fieldRequiredMessage}
        />
      </FormField>

      <FormField
        className={styles.formField}
        label={intl.formatMessage({ id: 'line-of-business-label' })}
      >
        <Select
          errorMessage={fieldRequiredMessage}
          required={true}
          className={styles.formControl}
          value={lineOfBusiness}
          options={sectors.map((s) => ({ name: s.name, value: `${s.id}` }))}
          onChange={(value) => setLineOfBusiness(value)}
          placeholder={selectPlaceholder}
        />
      </FormField>

      <FormField
        className={styles.formField}
        label={intl.formatMessage({ id: 'country-of-business-label' })}
      >
        <Select
          errorMessage={fieldRequiredMessage}
          required={true}
          className={styles.formControl}
          value={countryOfBusiness}
          options={countryOfBusinessOptions}
          onChange={(value) => setCountryOfBusiness(value)}
          placeholder={selectPlaceholder}
        />
      </FormField>

      <FormField
        className={styles.formField}
        label={intl.formatMessage({ id: 'subject-label' })}
      >
        <Select
          errorMessage={fieldRequiredMessage}
          required={true}
          className={styles.formControl}
          value={subject}
          options={subjectOptions}
          onChange={(value) => setSubject(value)}
          placeholder={selectPlaceholder}
        />
      </FormField>

      <FormField
        className={styles.formField}
        label={intl.formatMessage({ id: 'comment-or-question-label' })}
      >
        <Textarea
          errorMessage={fieldRequiredMessage}
          required={true}
          className={cn(styles.formControl, styles.textarea)}
          value={commentOrQuestion}
          onChange={(value) => setCommentOrQuestion(value)}
        />
      </FormField>

      <div className={styles.buttonContainer}>
        <Button
          onClick={() => onSubmit()}
          color={'green'}
          disabled={!submittable || submitting}
          className={cn(styles.button, styles.submitButton)}
        >
          {submitting ? (
            <LoadingSpinner className={styles.loading} />
          ) : (
            <FormattedMessage id={'submit-label'} />
          )}
        </Button>

        <Button
          onClick={() => onCancel()}
          color={'transparent'}
          className={cn(styles.button, styles.cancelButton)}
        >
          <FormattedMessage id={'cancel-label'} />
        </Button>
      </div>
    </div>
  );
}

export default ContactUsForm;
