import classNames from 'classnames';
import { PropsWithChildren, useLayoutEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ReactComponent as CheckCircleGreen } from '../../../icons/check-circle-green.svg';
import styles from './ValidationDataCellText.module.scss';

export interface ValidationDataCellTextProps {
  isEdit?: boolean;
  value: string;
  isValid?: boolean;
  onValueChange: (newVal: string) => void;
  onDone?: () => void;
  smallPadding?: boolean;
}

function ValidationDataCellText({
  isEdit = false,
  value,
  isValid,
  onValueChange,
  onDone,
  smallPadding = false,
}: PropsWithChildren<ValidationDataCellTextProps>) {
  const intl = useIntl();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const isEmpty = ['$NULL_STRING$', ''].includes(value);

  useLayoutEffect(() => {
    if (isEdit) {
      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.selectionStart = inputRef.current.selectionEnd =
          inputRef.current.value.length;
      }
    }
  }, [isEdit]);

  return (
    <div className={styles.container}>
      {isEdit ? (
        <div
          className={classNames(
            styles.valueInputWrapper,
            smallPadding && styles.valueInputSmallPadding,
          )}
        >
          <input
            ref={inputRef}
            type="text"
            defaultValue={isEmpty ? '' : value}
            onChange={(el) => {
              onValueChange(el.target.value);
            }}
            onBlur={() => {
              if (onDone) {
                onDone();
              }
            }}
            placeholder={intl.formatMessage({
              id: 'validation-data-input-placeholder',
            })}
          />
          {!isEmpty && !smallPadding && isValid && (
            <CheckCircleGreen className={styles.checkCircle} />
          )}
        </div>
      ) : isEmpty ? (
        <FormattedMessage id="validation-data-not-available" />
      ) : (
        value
      )}
    </div>
  );
}

export default ValidationDataCellText;
