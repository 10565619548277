import { Dispatch, SetStateAction } from 'react';

/**
 * service to manage dispatches by mounted state of component
 */
export class MountService {
  // mounted state
  private _mounted = true;
  // dispatches
  private _dispatches: { [k: string]: Dispatch<SetStateAction<any>> } = {};

  /**
   * add dispatch
   * @param name dispatch name
   * @param dispatch dispatch
   */
  addDispatch<T>(name: string, dispatch: Dispatch<SetStateAction<T>>): void {
    this._dispatches[name] = dispatch;
  }

  /**
   * call dispatch
   * @param name name
   * @param value value
   */
  callDispatch<T>(name: string, value: T): void {
    if (this._mounted) {
      if (this._dispatches[name]) {
        this._dispatches[name](value);
      } else {
        console.error('No dispatch with name', name);
      }
    }
  }

  /**
   * unmount dispatches
   */
  unmount(): void {
    this._mounted = false;
  }

  /**
   * mount dispatches
   */
  mount(): void {
    this._mounted = true;
  }
}
