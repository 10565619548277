import { CancelToken } from 'axios';
import { Lookups } from '../models/lookups';
import { apiInstance } from '../api/requestInterceptor';

/**
 * get all lookups
 * @param cancelToken cancel token
 */
export async function getLookups(cancelToken: CancelToken): Promise<Lookups> {
  // Fetch
  const res = await apiInstance.get('/lookups', {
    cancelToken,
    cache: {
      ignoreCache: false,
    },
  });

  return res.data;
}
