import { apiInstance } from '../api/requestInterceptor';

import { ManageOrderContactType } from '../models/admin-form-types';
import { OrderContact } from '../models/order-contacts';

/**
 * get order contacts.
 */
export async function getOrderContacts(
  countryId?: number,
): Promise<OrderContact[]> {
  const res = await apiInstance.get(
    `/orderContacts${countryId ? `?countryId=${countryId}` : ''}`,
  );

  return res.data;
}

/**
 * Update order contact
 */
export async function updateOrderContacts(
  orderContact: ManageOrderContactType,
): Promise<void> {
  try {
    await apiInstance.put('/orderContacts', orderContact);
  } catch (e: any) {
    throw e?.response?.data?.message;
  }
}
