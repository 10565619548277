import styles from './SearchPopupContainer.module.scss';
import React, { PropsWithoutRef } from 'react';
import cn from 'classnames';
import { LookupItem } from '../../../../../models/lookups';

interface SearchPopupContainerProps {
  title: string;
  data: LookupItem[];
  className?: string;
  onItemClick: (item: LookupItem) => void;
}

/**
 * search popup container to browse product and application
 * @param title title
 * @param data data
 * @param className classname
 * @param onItemClick item click handler
 */
function SearchPopupContainer({
  title,
  data,
  className,
  onItemClick,
}: PropsWithoutRef<SearchPopupContainerProps>) {
  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.header}>
        <h3>{title}</h3>
      </div>

      <div>
        {data.map((item, index) => {
          return (
            <div
              onClick={() => onItemClick(item)}
              //key={item.id} TODO: This is currently pushing id always 1. Will be updated when integration done.
              key={index}
              className={styles.listItem}
            >
              {item.name}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default SearchPopupContainer;
