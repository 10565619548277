/* eslint-disable react/jsx-key */
/* istanbul ignore file */
import { useEffect, useState, useMemo, Fragment, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import XLSX from 'xlsx';
import ReactTooltip from 'react-tooltip';
import cn from 'classnames';

import Card from '../../../../components/common/Card/Card';
import Header from '../../../../components/Admin/Header/Header';
import InfoCard from '../../../../components/Admin/Products/InfoCard/InfoCard';
import HorizontalStepper from '../../../../components/common/HorizontalStepper/HorizontalStepper';
import MobileStepper from '../../../../components/common/MobileStepper/MobileStepper';
import UploadData from '../../../../components/Admin/Products/UploadData/UploadData';
import { PreviousLink } from '../../../../models/previous-link';
import styles from './ManageLocalisedProductsPage.module.scss';
import ValidateData from '../../../../components/common/ValidateData/ValidateData';
import SaveData from '../../../../components/Admin/Products/SaveData/SaveData';
import { ProductValidationColumn } from '../../../../models/table-column';
import {
  downloadExistingLocalizedProducts,
  downloadLocalizedProductsTemplate,
  saveLocalizedProducts,
  SaveRequestBody,
} from '../../../../services/LocalizedProductService';
import { getAllProducts } from '../../../../services/ProductService';
import {
  AllProductItem,
  AllProductMap,
} from '../../../../models/admin-product-details';
import LoadingSpinner from '../../../../components/common/LoadingSpinner/LoadingSpinner';
import { RootStore } from '../../../../store';
import Select from '../../../../components/common/Select/Select';
import withAdmin from '../../../../hoc/withAdmin';
import { responsiveRender } from '../../../../utils/common.util';
import { addToast } from '../../../../features/toastSlice';
import { hideLoading, showLoading } from '../../../../features/loadingSlice';

/**
 * manage localised products page component
 */
function ManageLocalisedProductsPage() {
  // init hook
  const navigate = useNavigate();

  const [, ...languages] = useSelector(
    (rootState: RootStore) => rootState.lookups,
  ).languages;

  const columns: ProductValidationColumn[] = [
    {
      formattedMessageId: 'product-id-label',
      key: (item: any) => item['Product ID'],
      objectKey: 'Product ID',
      hidden: false,
      requiredAsterisk: true,
      renderer: (column, product) =>
        ['$NULL_STRING$', ''].includes(String(column.key(product)))
          ? 'N/A'
          : column.key(product),
    },
    {
      formattedMessageId: 'product-name-label',
      key: (item: any) => item['Product name'],
      objectKey: 'Product name',
      hidden: false,
      requiredAsterisk: true,
      renderer: (column, product, setDataAtIndex) => (
        <Select
          required={true}
          fixedWidth={false}
          className={cn(styles.selectDropdown, {
            [styles.errorDropdown]: ['$NULL_STRING$', ''].includes(
              String(column.key(product)),
            ),
          })}
          value={product['Product ID'] as number}
          options={Object.keys(allProducts).map((productId) => ({
            name: allProducts[productId as unknown as number].name,
            value: allProducts[productId as unknown as number].id,
          }))}
          onChange={(value) => {
            setDataAtIndex({
              ...product,
              'Product ID': allProducts[value].id,
              'Product name': allProducts[value].name,
            });
          }}
          placeholder={'Select Product'}
          canSearch
        />
      ),
    },
    {
      formattedMessageId: 'language-label',
      key: (item: any) => item['Language'],
      objectKey: 'Language',
      hidden: false,
      requiredAsterisk: true,
      renderer: (column, product, setDataAtIndex) => (
        <Select
          required={true}
          className={cn(styles.selectDropdown, {
            [styles.errorDropdown]: ['$NULL_STRING$', ''].includes(
              String(column.key(product)),
            ),
          })}
          inputClassName={styles.selectInput}
          value={product[column.objectKey] as number}
          options={languages.map((language) => ({
            name: language.name,
            value: language.id,
          }))}
          onChange={(value) => {
            setDataAtIndex({
              ...product,
              [column.objectKey]: value,
            });
          }}
          placeholder={'Select Language'}
        />
      ),
    },
    {
      formattedMessageId: 'tagline-label',
      key: (item: any) => item['Tagline'],
      objectKey: 'Tagline',
      hidden: false,
      showNA: true,
      renderer: (column, product, setDataAtIndex, rowIndex) => {
        const [showInput, setShowInput] = useState(false);

        if (
          showInput ||
          ['$NULL_STRING$', ''].includes(String(column.key(product)))
        ) {
          return (
            <input
              className={styles.input}
              placeholder={'Fill Tagline'}
              defaultValue={
                ['$NULL_STRING$', ''].includes(String(column.key(product)))
                  ? ''
                  : column.key(product)
              }
              onBlur={(e) => {
                if (e.target.value) {
                  setDataAtIndex({
                    ...product,
                    [column.objectKey]: e.target.value,
                  });
                }
                setShowInput(false);
              }}
            />
          );
        }

        return (
          <>
            <span
              className={styles.ellipsis}
              onClick={() => setShowInput(true)}
              data-tip
              data-for={`${column.formattedMessageId}_${rowIndex}`}
            >
              {column.key(product)}
            </span>
            <ReactTooltip
              id={`${column.formattedMessageId}_${rowIndex}`}
              place={'top'}
              effect={'solid'}
              multiline
            >
              {column.key(product)}
            </ReactTooltip>
          </>
        );
      },
    },
    {
      formattedMessageId: 'product-description-label',
      key: (item: any) => item['Description'],
      objectKey: 'Description',
      requiredAsterisk: true,
      hidden: true,
    },
  ];

  const dispatch = useDispatch();
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [allProducts, setAllProducts] = useState<AllProductMap>({});
  const [currentStep, setCurrentStep] = useState(0);
  const [file, setFile] = useState();
  const [data, handleDataChange] = useState<unknown[]>([]);
  const [errorCount, setErrorCount] = useState<number>(0);
  const [uniquePair, setUniquePair] = useState<boolean>(true);
  const [backendErrors, setBackendErrors] = useState<any[]>([]);

  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });

  const setData = (newData: any[]) => {
    let count = 0;
    let areAllPairsUnique = true;
    if (newData.length === 0) return;

    const uniquePairMap: { [key: string]: number[] } = {};

    newData = newData.map((row: any, index: number) => {
      if (
        !(
          ['$NULL_STRING$', '', undefined, null].includes(row['Product ID']) ||
          ['$NULL_STRING$', '', undefined, null].includes(row['Language'])
        )
      ) {
        if (`${row['Product ID']}_${row['Language']}` in uniquePairMap) {
          uniquePairMap[`${row['Product ID']}_${row['Language']}`] = [
            ...uniquePairMap[`${row['Product ID']}_${row['Language']}`],
            index,
          ];
        } else {
          uniquePairMap[`${row['Product ID']}_${row['Language']}`] = [index];
        }
      }
      if (
        columns.some(
          (column) =>
            !column.showNA &&
            ['$NULL_STRING$', ''].includes(String(column.key(row))),
        )
      ) {
        count++;
        return {
          ...row,
          hasError: true,
        };
      }
      return {
        ...row,
        hasError: false,
      };
    });
    Object.keys(uniquePairMap).forEach((key) => {
      if (uniquePairMap[key].length > 1) {
        uniquePairMap[key].forEach((indx) => {
          newData[indx].hasError = true;
        });
        count++;
        areAllPairsUnique = false;
      }
    });
    setUniquePair(areAllPairsUnique);
    setErrorCount(count);
    handleDataChange(newData);
  };

  useEffect(() => {
    setLoading(true);

    getAllProducts()
      .then((res: AllProductItem[]) => {
        setAllProducts(
          res.reduce((acc: AllProductMap, current: AllProductItem) => {
            acc[current.id] = current;
            return acc;
          }, {}),
        );
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (file) {
      dispatch(showLoading());
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;
      reader.onload = (e) => {
        /* Parse data */
        const bstr = e?.target?.result;
        const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const newData = XLSX.utils
          .sheet_to_json(ws, {
            header: [
              'Product ID',
              'Product name',
              'Language',
              'Tagline',
              'Description',
              'Range short description',
            ],
            defval: '$NULL_STRING$',
            raw: false,
          })
          .slice(1)
          .map((row: any) => {
            let language = NaN;
            if (!isNaN(row['Language'])) language = row['Language'];
            if (row['Language'] === 'German') language = 2;
            else if (row['Language'] === 'Japanese') language = 3;
            const productExists =
              !isNaN(row['Product ID']) &&
              Number(row['Product ID']) in allProducts;
            return {
              ...row,
              'Product ID': productExists
                ? allProducts[Number(row['Product ID'])].id
                : '$NULL_STRING$',
              'Product name': productExists
                ? allProducts[Number(row['Product ID'])].name
                : '$NULL_STRING$',
              Language: isNaN(language) ? '$NULL_STRING$' : language,
            };
          });
        setData(newData);
        dispatch(hideLoading());
      };
      if (rABS) reader.readAsBinaryString(file);
      else reader.readAsArrayBuffer(file);
    }
  }, [file]);

  const steps = useMemo(
    () => [
      intl.formatMessage({ id: 'upload-data' }),
      intl.formatMessage({ id: 'validate-data' }),
      intl.formatMessage({ id: 'save-data' }),
    ],
    [],
  );

  const breadcrumbLinks: PreviousLink[] = [
    {
      name: 'Home',
      to: '/home',
    },
    {
      name: intl.formatMessage({
        id: 'header-manage-localised-products',
      }),
    },
  ];

  const descriptionValues = {
    a: (value: string[]) => (
      <a
        onClick={() => {
          if (value.includes('LINK_1')) {
            downloadExistingLocalizedProducts();
          } else if (value.includes('LINK_2')) {
            downloadLocalizedProductsTemplate();
          }
        }}
      >
        {value.includes('LINK_1') && (
          <FormattedMessage id={'localised-products-info-card-link-1'} />
        )}
        {value.includes('LINK_2') && (
          <FormattedMessage id={'localised-products-info-card-link-2'} />
        )}
      </a>
    ),
    b: (value: string[]) => <b>{value}</b>,
    p: (value: string[]) => <p>{value}</p>,
  };

  const timerHandle = useRef<number | null>(null);

  useEffect(() => {
    return () => {
      if (timerHandle.current) {
        clearTimeout(timerHandle.current);
      }
    };
  }, []);

  const completeProcess = () => {
    setIsSaving(true);
    const payload: SaveRequestBody = {
      items: data.map((row: any) => ({
        productId: row['Product ID'],
        languageId: row['Language'],
        tagLine: row['Tagline'] || undefined,
        description: row['Description'],
      })),
    };
    setBackendErrors([]);
    saveLocalizedProducts(payload)
      .then(() => {
        setIsSaving(false);
        timerHandle.current = window.setTimeout(() => navigate('/'), 3000);
      })
      .catch((err) => {
        setBackendErrors(err?.response?.data?.errors);
        setCurrentStep(1);
        dispatch(
          addToast({
            type: 'error',
            title: getTranslatedMessage('bulk-upload-localised-error'),
            message: '',
          }),
        );
      });
  };

  const stepsComponents = [
    <UploadData submit={() => setCurrentStep(1)} setFile={setFile} />,
    <ValidateData
      save={() => {
        setCurrentStep(2);
        completeProcess();
      }}
      cancel={() => setCurrentStep(0)}
      data={data}
      setData={setData}
      columns={columns}
      errorCount={errorCount}
      uniquePair={uniquePair}
      backendErrors={backendErrors}
    />,
    <SaveData isLoading={isSaving} />,
  ];

  return (
    <div className={styles.container}>
      <Header
        breadcrumbLinks={breadcrumbLinks}
        titleId={'header-manage-localised-products'}
      />
      {loading ? (
        <LoadingSpinner className={styles.loading} />
      ) : (
        <Card>
          <>
            <p className={styles.para}>
              <FormattedMessage id={'manage-localised-products-intro-para'} />
            </p>
            <div className={styles.divider} />
            <div className={styles.twoColumnGrid}>
              <InfoCard
                heading={
                  <FormattedMessage
                    id="manage-localised-products-info-card-heading-1"
                    values={descriptionValues}
                  />
                }
                body={
                  <FormattedMessage
                    id="manage-localised-products-info-card-body-1"
                    values={descriptionValues}
                  />
                }
              />
              <InfoCard
                heading={
                  <FormattedMessage
                    id="manage-localised-products-info-card-heading-2"
                    values={descriptionValues}
                  />
                }
                body={
                  <FormattedMessage
                    id="manage-localised-products-info-card-body-2"
                    values={descriptionValues}
                  />
                }
              />
            </div>
            {responsiveRender(
              'desktop',
              <HorizontalStepper steps={steps} currentStep={currentStep} />,
            )}
            {responsiveRender(
              'mobile',
              <MobileStepper
                steps={steps}
                currentStep={currentStep}
                stepsComponents={stepsComponents}
              />,
            )}
            {responsiveRender(
              'desktop',
              <div>
                {stepsComponents.map((stepComponent, index) =>
                  index === currentStep ? (
                    <Fragment key={index}>{stepComponent}</Fragment>
                  ) : null,
                )}
              </div>,
            )}
          </>
        </Card>
      )}
    </div>
  );
}

export default withAdmin(ManageLocalisedProductsPage);
