import { PropsWithChildren } from 'react';
import styles from './PortProductTable.module.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import OemListTable from '../../../../../components/common/OemListTable/OemListTable';
import { TableColumn } from '../../../../../models/table-column';
import Paginator from '../../../../../components/common/Paginator/Paginator';
import { ReactComponent as XlsxFileIcon } from '../../../../../icons/xlsx-file.svg';
import cn from 'classnames';
import ReactTooltip from 'react-tooltip';

type PortProductTableType = {
  reportData: any[];
  page: number;
  perPage: number;
  total: number;
  sortBy: string;
  sortDirection: 'asc' | 'desc';
  onClickPage: (page: number) => void;
  onClickPerPage: (page: number) => void;
  onSortChange: (column: any) => void;
  exportToExcel: () => void;
};

const PortProductTable = ({
  reportData,
  page,
  perPage,
  total,
  sortBy,
  sortDirection,
  onClickPage,
  onClickPerPage,
  onSortChange,
  exportToExcel,
}: PropsWithChildren<PortProductTableType>) => {
  const intl = useIntl();
  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });

  const columns: TableColumn<any>[] = [
    {
      key: 'countryName',
      property: 'countryName',
      label: getTranslatedMessage('port-product-page-country'),
      width: '10%',
      render: (data) => {
        return <div className={styles.green}>{data?.countryName}</div>;
      },
    },
    {
      key: 'stockPointName',
      property: 'stockPointName',
      label: getTranslatedMessage('port-product-page-stock-name'),
      width: '10%',
    },
    {
      key: 'portName',
      property: 'portName',
      label: getTranslatedMessage('port-product-page-port-name'),
      width: '10%',
    },
    {
      key: 'productName',
      property: 'productName',
      label: getTranslatedMessage('port-product-page-product'),
      width: '10%',
    },
    {
      key: 'offerBulkTruck',
      property: 'offerBulkTruck',
      label: getTranslatedMessage('port-product-page-bulk-by-truck'),
      width: '10%',
      render: (data) => <span>{data.offerBulkTruck ? 'Y' : 'N'}</span>,
    },
    {
      key: 'offerBulkBadge',
      property: 'offerBulkBadge',
      label: getTranslatedMessage('port-product-page-bulk-by-barge'),
      width: '10%',
      render: (data) => <span>{data.offerBulkBadge ? 'Y' : 'N'}</span>,
    },
    {
      key: 'offerBulkIbc',
      property: 'offerBulkIbc',
      label: getTranslatedMessage('port-product-page-bulk-by-LBC'),
      width: '10%',
      render: (data) => <span>{data.offerBulkIbc ? 'Y' : 'N'}</span>,
    },
    {
      key: 'offerPackDrum',
      property: 'offerPackDrum',
      label: getTranslatedMessage('port-product-page-bulk-by-drums'),
      width: '10%',
      render: (data) => <span>{data.offerPackDrum ? 'Y' : 'N'}</span>,
    },
    {
      key: 'offerPackPail',
      property: 'offerPackPail',
      label: getTranslatedMessage('port-product-page-bulk-by-pails'),
      width: '10%',
      render: (data) => <span>{data.offerPackPail ? 'Y' : 'N'}</span>,
    },
    {
      key: 'portProductNotes',
      property: 'portProductNotes',
      label: getTranslatedMessage('port-product-page-note'),
      width: '10%',
      render: (data, index) => {
        return (
          <>
            <div
              data-tip
              data-for={`notes-${index}`}
              className={styles.notesColumn}
            >
              {data.notes}
            </div>
            <ReactTooltip
              id={`notes-${index}`}
              aria-haspopup="true"
              place="top"
              effect="solid"
            >
              {data.notes}
            </ReactTooltip>
          </>
        );
      },
    },
  ];

  return (
    <div className={styles.tableContainer}>
      <div className={styles.table}>
        <div className={styles.tableTitle}>
          {getTranslatedMessage('port-product-page-result')}
          <div
            className={cn(styles.exportToExcel, styles.desktop)}
            onClick={() => exportToExcel()}
          >
            <XlsxFileIcon />
            <FormattedMessage id={'oem-table-field-export-to-excel'} />
          </div>
        </div>
        <OemListTable
          data={reportData}
          sortBy={sortBy}
          sortDirection={sortDirection}
          columns={columns}
          showCheckbox={false}
          onSortChange={onSortChange}
        />
        <Paginator
          page={page}
          perPage={perPage}
          total={total}
          onPageChange={onClickPage}
          onPerPageChange={onClickPerPage}
        />
      </div>
    </div>
  );
};

export default PortProductTable;
