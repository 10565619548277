import styles from './SearchModalInput.module.scss';
import { ReactComponent as Search } from '../../../../icons/search-gray.svg';
import { PropsWithoutRef } from 'react';
import { useIntl } from 'react-intl';

interface SearchModalInputProps {
  onChange: (value: string) => void;
}

/**
 * search modal input
 * @param onChange change handler
 */
function SearchModalInput({
  onChange,
}: PropsWithoutRef<SearchModalInputProps>) {
  const intl = useIntl();

  return (
    <div className={styles.container}>
      <input
        autoFocus={true}
        type={'text'}
        onChange={(event) => onChange(event.target.value)}
        className={styles.input}
        placeholder={intl.formatMessage({ id: 'start-typing-placeholder' })}
      />

      <Search className={styles.search} />

      <button hidden />
    </div>
  );
}

export default SearchModalInput;
