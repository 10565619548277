import RichTextarea from '../RichTextarea/RichTextarea';
import FormRow from '../FormRow/FormRow';

interface RichTextareaRowProps {
  labelId: string;
  required: boolean;
  tooltip?: string;
  value?: string;
  onChange: (value: string) => void;
  placeholder?: string;
  className?: string;
}

const RichTextareaRow = ({
  labelId,
  value,
  onChange,
  placeholder,
  required,
  tooltip,
  className,
}: RichTextareaRowProps) => {
  return (
    <FormRow
      labelId={labelId}
      required={required}
      tooltip={tooltip}
      className={className}
    >
      <RichTextarea
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </FormRow>
  );
};

export default RichTextareaRow;
