import styles from './SearchInput.module.scss';
import { ReactComponent as Search } from '../../../../icons/search.svg';
import { PropsWithoutRef } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import getSearchPlaceholderId from '../../../../utils/common.util';
import { RootStore } from '../../../../store';
import { useSelector } from 'react-redux';

interface SearchInputProps {
  placeholder?: string;
  onInputFocus: () => void;
  classes?: string;
}

/**
 * search input for navigation header
 * @param placeholder input placeholder
 * @param onInputFocus input focus handler
 */
function SearchInput({
  placeholder,
  onInputFocus,
  classes,
}: PropsWithoutRef<SearchInputProps>) {
  const intl = useIntl();
  const { sector } = useSelector((state: RootStore) => state.welcomeInfo);
  const { hasLogin } = useSelector((state: RootStore) => state.login);

  const placeholderId = getSearchPlaceholderId(sector, '-short', hasLogin);

  placeholder =
    placeholder || `${intl.formatMessage({ id: placeholderId })}...`;

  return (
    <div
      className={classNames(styles.container, classes)}
      onClick={() => onInputFocus()}
    >
      <input
        tabIndex={-1}
        onClick={() => onInputFocus()}
        placeholder={placeholder}
        className={styles.input}
      />

      <Search className={styles.icon} />
    </div>
  );
}

export default SearchInput;
