import { isDate, isFloat } from './validation.util';
import { getObjectValue } from './object.util';

/**
 * sort data as ascending
 * @param a data 1
 * @param b data 2
 */
export function sortMethodAsc<T>(a: T, b: T): number {
  if (typeof a === 'number' && typeof b === 'string') {
    return 1;
  } else if (typeof a === 'string' && typeof b === 'number') {
    return -1;
  } else {
    return a === b ? 0 : a > b ? 1 : -1;
  }
}

/**
 * sort data with order
 * @param order sort order
 */
export function sortMethodWithOrder<T>(order: 'asc' | 'desc'): any {
  if (order === undefined || order === 'asc') {
    return sortMethodAsc;
  } else {
    return (a: T, b: T) => {
      return -sortMethodAsc(a, b);
    };
  }
}

/**
 * sort data with ordered column
 * @param property property string
 * @param order sort order
 */
export function sortMethodWithOrderByColumn<T>(
  property: string,
  order: 'asc' | 'desc',
): any {
  const sortMethod = sortMethodWithOrder(order);

  return (a: T, b: T) => {
    let v1: string | number = getObjectValue(a, property);
    let v2: string | number = getObjectValue(b, property);

    if (v1 === null || v1 === undefined) {
      v1 = '';
    }
    if (v2 === null || v2 === undefined) {
      v1 = '';
    }

    v1 = isFloat(v1 as string) ? parseFloat(v1 as string) : v1;
    v2 = isFloat(v2 as string) ? parseFloat(v2 as string) : v2;

    v1 = isDate(v1 as string) ? new Date(v1).getTime() : v1;
    v2 = isDate(v2 as string) ? new Date(v2).getTime() : v2;

    return sortMethod(v1, v2);
  };
}

/**
 * sort data with ordered multiple columns
 * @param sortedColumns sorted column list
 */
export function sortMethodWithOrderMultiColumn<T>(
  sortedColumns: { property: string; order: 'asc' | 'desc' }[],
): any {
  const sortMethodsForColumn = (sortedColumns || []).map((item) =>
    sortMethodWithOrderByColumn(item.property, item.order),
  );

  return (a: T, b: T) => {
    let sorted = 0;
    let index = 0;

    while (sorted === 0 && index < sortMethodsForColumn.length) {
      sorted = sortMethodsForColumn[index++](a, b);
    }

    return sorted;
  };
}
