import styles from './MultiSelect.module.scss';
import { ReactComponent as ArrowDown } from '../../../icons/arrow-down.svg';
import {
  Fragment,
  CSSProperties,
  PropsWithoutRef,
  ReactElement,
  useRef,
  useState,
} from 'react';
import { SelectOption } from '../../../models/select-option';
import cn from 'classnames';
import Option from './Option/Option';
import { cloneDeep } from 'lodash';

interface MultiSelectProps<T> {
  values: T[];
  options: SelectOption<T>[];
  onChange?: (value: T[]) => void;
  placeholder: string;
  required?: boolean;
  className?: string;
  inputClassName?: string;
  optionClassName?: string;
  canSearch?: boolean;
  optionRenderer?: (option: SelectOption<T>) => ReactElement;
  disabled?: boolean;
  popperPosition?: 'right' | '';
  suffixLabel?: string;
  defaultSelected?: T[];
  onOpenChange?: (open: boolean) => void;
  onSelect?: (value: T) => void;
  onDeselect?: (value: T) => void;
  showTooltip?: boolean;
}

export const ALL_ITEM_VALUE = -1;

/**
 * select component
 * @param value value
 * @param options options
 * @param onChange change handler
 * @param placeholder placeholder
 * @param required required
 * @param className classname
 * @param canSearch can filter options or not
 * @param errorMessage error message
 * @param optionRenderer option content renderer
 */
function MultiSelect<T>({
  values = [],
  options,
  onChange,
  placeholder,
  required,
  className,
  inputClassName,
  optionClassName,
  canSearch = false,
  disabled = false,
  popperPosition,
  suffixLabel,
  defaultSelected = [],
  onOpenChange,
  onSelect,
  onDeselect,
  showTooltip,
}: PropsWithoutRef<MultiSelectProps<T>>) {
  const [opened, setOpened] = useState(false);
  const [changed, setChanged] = useState(false);
  const [filter, setFilter] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([...options]);
  const [focused, setFocused] = useState(false);
  const selectContainerRef = useRef<HTMLDivElement>(null);
  const optionStyles: CSSProperties = {};
  let optionHeight = 0;

  // The page may scroll, then the selector position will change
  // So we need generate option container position when opening
  const generatePosition = (): boolean => {
    if (selectContainerRef.current) {
      const domRect = selectContainerRef.current.getBoundingClientRect();
      const maxHeight = domRect.height * 5;
      const bottomRemains = window.innerHeight > domRect.bottom + maxHeight;

      optionHeight = domRect.height;
      optionStyles.width = domRect.width + 'px';
      optionStyles.maxHeight = maxHeight + 'px';

      if (bottomRemains) {
        if (popperPosition === 'right') {
          if (domRect.right + 2 + domRect.width > window.innerWidth) {
            optionStyles.left = window.innerWidth - domRect.width + 'px';
          } else {
            optionStyles.left = domRect.right + 2 + 'px';
          }
          optionStyles.top = domRect.top + 'px';
        } else {
          optionStyles.left = (domRect.left > 0 ? domRect.left : 0) + 'px';
          optionStyles.top = domRect.bottom + 'px';
        }
      } else {
        if (popperPosition === 'right') {
          if (domRect.right + 2 + domRect.width > window.innerWidth) {
            optionStyles.left = window.innerWidth - domRect.width + 'px';
          } else {
            optionStyles.left = domRect.right + 2 + 'px';
          }
          optionStyles.bottom = window.innerHeight - domRect.bottom + 'px';
        } else {
          optionStyles.left = (domRect.left > 0 ? domRect.left : 0) + 'px';
          optionStyles.bottom = window.innerHeight - domRect.top + 'px';
        }
      }
    }

    return true;
  };

  const selectedAllFlag = (): boolean => {
    const allIdx = values.findIndex((elem: any) => elem === ALL_ITEM_VALUE);
    return allIdx >= 0;
  };

  /**
   * option click handler
   * @param changed changed value
   */
  const onOptionClick = (changed: T) => {
    // user selected `All`
    if ((changed as any) === ALL_ITEM_VALUE) {
      if (selectedAllFlag()) {
        if (onDeselect) {
          onDeselect(changed);
        }
        if (onChange) {
          onChange([...defaultSelected]);
        }
      } else {
        if (onSelect) {
          onSelect(changed);
        }
        if (onChange) {
          onChange([ALL_ITEM_VALUE, ...defaultSelected] as any);
        }
      }
      return;
    }
    const valueData = cloneDeep(values).filter(
      (item) => (item as any) !== ALL_ITEM_VALUE,
    );
    const idx = valueData.indexOf(changed);
    if (idx > -1) {
      if (onDeselect) {
        onDeselect(changed);
      }
      valueData.splice(idx, 1);
    } else {
      if (onSelect) {
        onSelect(changed);
      }
      valueData.push(changed);
    }
    if (onChange) {
      onChange(valueData);
    }
  };

  /**
   * open options
   */
  const openOptions = () => {
    setFilter('');
    setFocused(true);
    setOpened(true);
    if (onOpenChange) {
      onOpenChange(true);
    }
    onSearchChange('');
  };

  /**
   * close options
   */
  const closeOptions = () => {
    setFilter('');
    setFocused(false);
    setOpened(false);
    if (onOpenChange) {
      onOpenChange(false);
    }
    setChanged(true);
  };

  /**
   * handle search change event
   * @param value value
   */
  const onSearchChange = (value: string) => {
    setFilter(value);

    const filtered = options.filter(
      (item) => item.name.toLowerCase().indexOf(value.toLowerCase()) !== -1,
    );

    setFilteredOptions(filtered);
  };

  const getLabelText = () => {
    if (values.length === 0) {
      return '';
    } else if (selectedAllFlag()) {
      return 'All';
    } else if (suffixLabel) {
      return `${values.length} ${suffixLabel}`;
    }
    return `${values.length} item(s)`;
  };

  const requiredInvalid = changed && required && values.length == 0;

  return (
    <Fragment>
      <div
        ref={selectContainerRef}
        onClick={() => {
          if (!disabled) {
            openOptions();
          }
        }}
        className={cn(
          styles.selectContainer,
          opened && styles.opened,
          requiredInvalid && styles.invalid,
          disabled && styles.disabled,
          className,
        )}
      >
        {!canSearch || disabled ? (
          <div className={cn(styles.input, styles.inputVal, inputClassName)}>
            {getLabelText() || placeholder}
          </div>
        ) : (
          <input
            onInput={(event) =>
              onSearchChange((event.target as HTMLInputElement).value)
            }
            value={canSearch && focused ? filter : getLabelText()}
            placeholder={placeholder}
            disabled={!canSearch || disabled}
            type={'text'}
            className={cn(styles.input, inputClassName)}
          />
        )}

        <ArrowDown className={styles.icon} />
      </div>

      {opened && (
        <div onClick={() => closeOptions()} className={styles.clickDetector} />
      )}

      {opened && generatePosition() && (
        <div style={optionStyles} className={styles.optionsContainer}>
          {!filter && filteredOptions.length > 0 && (
            <Option
              selected={selectedAllFlag()}
              data={{
                name: 'All',
                value: ALL_ITEM_VALUE as any,
              }}
              onClick={onOptionClick}
              height={optionHeight}
              scrollIntoView={false}
              className={optionClassName}
            />
          )}
          {filteredOptions.map((item, index) => {
            return (
              <Option
                key={index}
                selected={values.includes(item.value)}
                data={item}
                onClick={onOptionClick}
                height={optionHeight}
                scrollIntoView={false}
                className={optionClassName}
                showTooltip={showTooltip}
              />
            );
          })}
        </div>
      )}
    </Fragment>
  );
}

export default MultiSelect;
