import { apiInstance } from '../api/requestInterceptor';
import { Announcement } from '../models/announcement';

/**
 * Get announcements
 */
export async function getAnnouncements() {
  const res = await apiInstance.get<Announcement[]>('/announcements');

  return res.data;
}
