import styles from './SearchFilter.module.scss';
import { FormattedMessage } from 'react-intl';
import { PropsWithChildren } from 'react';
import Button from '../../common/Button/Button';

interface SearchFilterProps {
  clear?: () => void;
}

/**
 * search filter for search result page
 * @param children children
 */
function SearchFilter({
  children,
  clear,
}: PropsWithChildren<SearchFilterProps>) {
  return (
    <div>
      <div className={styles.title}>
        <FormattedMessage id={'filters-label'} />
        {clear && (
          <Button
            color="transparent"
            className={styles.btnClear}
            onClick={clear}
          >
            Reset
          </Button>
        )}
      </div>

      {children}
    </div>
  );
}

export default SearchFilter;
