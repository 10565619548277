import styles from './PortLeftDetail.module.scss';
import { PropsWithoutRef } from 'react';
import { PortDetails } from '../../../models/port-details';
import PortSectionNavigation from '../PortSectionNavigation/PortSectionNavigation';
import PortStat from '../PortStat/PortStat';

interface PortLeftDetailProps {
  port: PortDetails;
  onLinkCliked: (id: number) => void;
}

/**
 * product left drawer contents
 * @param port port
 */
function PortLeftDetail({
  port,
  onLinkCliked,
}: PropsWithoutRef<PortLeftDetailProps>) {
  return (
    <div>
      <PortStat port={port} />
      <div className={styles.divider} />
      <PortSectionNavigation onLinkCliked={onLinkCliked} />
    </div>
  );
}

export default PortLeftDetail;
