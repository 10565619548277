import ReactTooltip from 'react-tooltip';
import Checkbox from '../../common/Checkbox/Checkbox';
import { ReactComponent as Help } from '../../../icons/help-green.svg';
import styles from './CheckboxRow.module.scss';
import { useMemo } from 'react';

interface CheckboxRowProps {
  label: React.ReactNode;
  tooltip?: string;
  checked: boolean;
  onClick: () => void;
}

let id = 0;

function CheckboxRow({ label, tooltip, checked, onClick }: CheckboxRowProps) {
  const helpId = useMemo(() => `checkbox-row-${++id}`, []);

  return (
    <div className={styles.container}>
      <Checkbox checked={checked} onClick={onClick} />
      <div className={styles.checkboxLabel}>{label}</div>
      {tooltip && <Help data-tip data-for={helpId} />}
      {tooltip && (
        <ReactTooltip id={helpId} place="top" effect="solid">
          {tooltip}
        </ReactTooltip>
      )}
    </div>
  );
}

export default CheckboxRow;
