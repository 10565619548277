import { PropsWithoutRef, useMemo } from 'react';
import { Link } from 'react-router-dom';
import styles from './CardLink.module.scss';
import MoreButton from '../MoreButton/MoreButton';
import cn from 'classnames';

interface CardLinkProps {
  title: string;
  subTitle?: string;
  description: string;
  trimDescription?: boolean;
  to: string;
  useHref?: boolean;
}

/**
 * card link
 */
function CardLink({
  title,
  subTitle,
  description,
  trimDescription = false,
  to,
  useHref = false,
}: PropsWithoutRef<CardLinkProps>) {
  const memoizedDescription = useMemo(() => {
    // description needs to be trimmed to show only the first sentence
    if (trimDescription && description) {
      const match: any = /[.?!]/.exec(description);
      if (match) {
        return description.slice(0, match.index + 1);
      }

      return description;
    }

    return description;
  }, [description]);

  if (useHref) {
    return (
      <a
        href={to}
        target={'_blank'}
        rel={'noreferrer'}
        className={styles.container}
      >
        <MoreButton className={styles.title}>{title}</MoreButton>

        {subTitle && (
          <div className={cn(styles.text, styles.subTitle)}>{subTitle}</div>
        )}

        <div className={cn(styles.text, styles.description)}>
          {memoizedDescription}
        </div>
      </a>
    );
  } else {
    return (
      <Link to={to} className={styles.container}>
        <MoreButton className={styles.title}>{title}</MoreButton>

        {subTitle && (
          <div className={cn(styles.text, styles.subTitle)}>{subTitle}</div>
        )}

        <div className={cn(styles.text, styles.description)}>
          {memoizedDescription}
        </div>
      </Link>
    );
  }
}

export default CardLink;
