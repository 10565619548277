import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SearchResultProduct } from '../models/search-result-product';
import { SearchResult } from '../models/search-result';
import { cloneDeep } from 'lodash';
import {
  groupSearchResult,
  resolveProductLookups,
} from '../utils/product.util';
import { ProductSearchResult } from '../models/product-search-result';
import { Lookups } from '../models/lookups';
import {
  CheckboxFilter,
  CombinedMultiSelectFilter,
  MultiSelectFilter,
  RangeFilter,
} from '../models/filter';
import {
  arrayToIdMap,
  getRange,
  getUniqueIdArray,
  hasDifferentValue,
  matrixToIdMap,
  testCheckboxFilter,
  testCombinedMultiSelectFilter,
  testMultiSelectFilter,
  testRangeFilter,
  toggleMultiSelectFilter,
} from '../utils/filter.utils';

type Filters = {
  family: MultiSelectFilter;
  range: MultiSelectFilter;
  category: MultiSelectFilter;
  subCategory: MultiSelectFilter;
  application: MultiSelectFilter;
  productType: MultiSelectFilter;
  viscosity: MultiSelectFilter;
  corrosionProtection: MultiSelectFilter;
  castrolRecommended: CheckboxFilter;
  esterEpAdditives: CheckboxFilter;
  foodGrade: CheckboxFilter;
  formulatedWithoutSilicate: CheckboxFilter;
  formulatedWithoutChlorinatedParaffin: CheckboxFilter;
  formulatedWithoutBoron: CheckboxFilter;
  formulatedWithoutHeavyMetal: CheckboxFilter;
  metalType: MultiSelectFilter;
  multiMetalsSuitable: CheckboxFilter;
  nlgi: MultiSelectFilter;
  oilType: CombinedMultiSelectFilter;
  operatingTemperature: RangeFilter;
  subSector: MultiSelectFilter;
  superiorBiodegradation: CheckboxFilter;
  thickener: CombinedMultiSelectFilter;
  viscosityIndex: MultiSelectFilter;
};

export interface ProductFilterSliceState {
  filters: Filters;
  searchResult: ProductSearchResult;
  filteredSearchResult: SearchResult;
}

/**
 * filter products
 * @param products products
 * @param filters filters
 */
function filterProducts(
  products: SearchResultProduct[],
  filters: Filters,
): SearchResultProduct[] {
  if (filters) {
    const familyIdMap = arrayToIdMap(filters.family.value);
    const hasNoFamilyFilter = filters.family.value.length === 0;
    const rangeIdMap = arrayToIdMap(filters.range.value);
    const hasNoRangeFilter = filters.range.value.length === 0;
    const categoryIdMap = arrayToIdMap(filters.category.value);
    const hasNoCategoryFilter = filters.category.value.length === 0;
    const subCategoryIdMap = arrayToIdMap(filters.subCategory.value);
    const hasNoSubCategoryFilter = filters.subCategory.value.length === 0;
    const applicationIdMap = arrayToIdMap(filters.application.value);
    const hasNoApplicationFilter = filters.application.value.length === 0;
    const productTypeIdMap = arrayToIdMap(filters.productType.value);
    const hasNoProductTypeFilter = filters.productType.value.length === 0;
    const viscosityMap = arrayToIdMap(filters.viscosity.value);
    const hasNoViscosityFilter = filters.viscosity.value.length === 0;
    const corrosionProtectedMap = arrayToIdMap(
      filters.corrosionProtection.value,
    );
    const hasNoCorrosionProtectionFilter =
      filters.corrosionProtection.value.length === 0;
    const metalTypeIdMap = arrayToIdMap(filters.metalType.value);
    const hasNoMetalTypeFilter = filters.metalType.value.length === 0;
    const nlgiMap = arrayToIdMap(filters.nlgi.value);
    const hasNoNlgiFilter = filters.nlgi.value.length === 0;
    const subSectorIdMap = arrayToIdMap(filters.subSector.value);
    const hasNoSubSectorFilter = filters.subSector.value.length === 0;
    const viscosityIndexMap = arrayToIdMap(filters.viscosityIndex.value);
    const hasNoViscosityIndexFilter = filters.viscosityIndex.value.length === 0;
    const oilTypeIdMap = matrixToIdMap(filters.oilType.value);
    const hasNoOilTypeFilter = !filters.oilType.value.find(
      (array) => array.length > 0,
    );
    const thickenerMap = matrixToIdMap(filters.thickener.value);
    const hasNoThickenerFilter = !filters.thickener.value.find(
      (array) => array.length > 0,
    );

    return products.filter((product) => {
      return (
        (hasNoFamilyFilter ||
          testMultiSelectFilter(familyIdMap, [product.familyId])) &&
        (hasNoRangeFilter ||
          testMultiSelectFilter(
            rangeIdMap,
            product.rangeId !== null ? [product.rangeId] : [],
          )) &&
        (hasNoCategoryFilter ||
          testMultiSelectFilter(
            categoryIdMap,
            product.productCategories.map((category) => category.categoryId),
          )) &&
        (hasNoSubCategoryFilter ||
          testMultiSelectFilter(
            subCategoryIdMap,
            product.subCategoryId !== null ? [product.subCategoryId] : [],
          )) &&
        (hasNoApplicationFilter ||
          testMultiSelectFilter(
            applicationIdMap,
            product.productApplications.map(
              (application) => application.applicationId,
            ),
          )) &&
        (hasNoProductTypeFilter ||
          testMultiSelectFilter(
            productTypeIdMap,
            product.productProductTypes.map(
              (application) => application.productTypeId,
            ),
          )) &&
        (hasNoViscosityFilter ||
          testMultiSelectFilter(
            viscosityMap,
            product.productCharacteristic.viscosity,
          )) &&
        (hasNoCorrosionProtectionFilter ||
          testMultiSelectFilter(corrosionProtectedMap, [
            product.productCharacteristic.corrosionProtectionId,
          ])) &&
        testRangeFilter(filters.operatingTemperature.value, [
          product.productCharacteristic.minTemperatureC,
          product.productCharacteristic.maxTemperatureC,
        ]) &&
        (hasNoMetalTypeFilter ||
          testMultiSelectFilter(
            metalTypeIdMap,
            product.productCharacteristic.productCharacteristicMetalTypes.map(
              (metalType) => metalType.metalTypeId,
            ),
          )) &&
        (hasNoNlgiFilter ||
          testMultiSelectFilter(
            nlgiMap,
            product.productCharacteristic.productCharacteristicNlgis.map(
              (nlgi) => nlgi.nlgiId,
            ),
          )) &&
        (hasNoSubSectorFilter ||
          testMultiSelectFilter(
            subSectorIdMap,
            product.productSubSectors.map((subSector) => subSector.subSectorId),
          )) &&
        (hasNoViscosityIndexFilter ||
          testMultiSelectFilter(viscosityIndexMap, [
            product.productCharacteristic.viscosityIndexId,
          ])) &&
        testCheckboxFilter(
          filters.castrolRecommended.value,
          product.castrolRecommended,
        ) &&
        testCheckboxFilter(
          filters.esterEpAdditives.value,
          product.productCharacteristic.esterEpAdditives,
        ) &&
        testCheckboxFilter(
          filters.foodGrade.value,
          product.productCharacteristic.foodGradeId,
        ) &&
        testCheckboxFilter(
          filters.formulatedWithoutSilicate.value,
          product.productCharacteristic.formulatedWithoutSilicateId,
        ) &&
        testCheckboxFilter(
          filters.formulatedWithoutChlorinatedParaffin.value,
          product.productCharacteristic.formulatedWithoutChlorinatedParaffinId,
        ) &&
        testCheckboxFilter(
          filters.formulatedWithoutBoron.value,
          product.productCharacteristic.formulatedWithoutBoronId,
        ) &&
        testCheckboxFilter(
          filters.formulatedWithoutHeavyMetal.value,
          product.productCharacteristic.formulatedWithoutHeavyMetalId,
        ) &&
        testCheckboxFilter(
          filters.multiMetalsSuitable.value,
          product.productCharacteristic.multiMetalsSuitableId,
        ) &&
        (hasNoOilTypeFilter ||
          testCombinedMultiSelectFilter(oilTypeIdMap, [
            [product.productCharacteristic.baseOilTypeGenericId],
            [product.productCharacteristic.baseOilTypeSpecificId],
          ])) &&
        (hasNoThickenerFilter ||
          testCombinedMultiSelectFilter(thickenerMap, [
            [product.productCharacteristic.thickenerGenericId],
            [product.productCharacteristic.thickenerSpecificId],
          ])) &&
        testCheckboxFilter(
          filters.superiorBiodegradation.value,
          product.productCharacteristic.superiorBiodegradationId,
        )
      );
    });
  } else {
    return products;
  }
}

/**
 * get filters from products
 */
function getFiltersWithProducts(
  products: SearchResultProduct[],
  currentFilters?: Filters,
): Filters {
  return {
    family: {
      options: getUniqueIdArray(
        products,
        (prod) => prod.familyId,
        currentFilters?.family.value,
      ),
      value: currentFilters?.family.value || [],
    },
    range: {
      options: getUniqueIdArray(
        products,
        (prod) => prod.rangeId,
        currentFilters?.range.value,
      ),
      value: currentFilters?.range.value || [],
    },
    category: {
      options: getUniqueIdArray(
        products,
        (prod) => prod.productCategories.map((cat) => cat.categoryId),
        currentFilters?.category.value,
      ),
      value: currentFilters?.category.value || [],
    },
    subCategory: {
      options: getUniqueIdArray(
        products,
        (prod) => prod.subCategoryId,
        currentFilters?.subCategory.value,
      ),
      value: currentFilters?.subCategory.value || [],
    },
    application: {
      options: getUniqueIdArray(
        products,
        (prod) => prod.productApplications.map((app) => app.applicationId),
        currentFilters?.application.value,
      ),
      value: currentFilters?.application.value || [],
    },
    productType: {
      options: getUniqueIdArray(
        products,
        (prod) => prod.productProductTypes.map((app) => app.productTypeId),
        currentFilters?.productType.value,
      ),
      value: currentFilters?.productType.value || [],
    },
    viscosity: {
      options: getUniqueIdArray(
        products,
        (prod) => prod.productCharacteristic.viscosity,
        currentFilters?.viscosity.value,
      ),
      value: currentFilters?.viscosity.value || [],
    },
    corrosionProtection: {
      options: getUniqueIdArray(
        products,
        (prod) => prod.productCharacteristic.corrosionProtectionId,
        currentFilters?.corrosionProtection.value,
      ),
      value: currentFilters?.corrosionProtection.value || [],
    },
    castrolRecommended: {
      hasDifferentValue:
        currentFilters?.castrolRecommended.value ||
        hasDifferentValue(products.map((prod) => prod.castrolRecommended)),
      value: currentFilters?.castrolRecommended.value || false,
    },
    esterEpAdditives: {
      hasDifferentValue:
        currentFilters?.esterEpAdditives.value ||
        hasDifferentValue(
          products.map((prod) => prod.productCharacteristic.esterEpAdditives),
        ),
      value: currentFilters?.esterEpAdditives.value || false,
    },
    foodGrade: {
      hasDifferentValue:
        currentFilters?.foodGrade.value ||
        hasDifferentValue(
          products.map((prod) => prod.productCharacteristic.foodGradeId),
        ),
      value: currentFilters?.foodGrade.value || false,
    },
    formulatedWithoutSilicate: {
      hasDifferentValue:
        currentFilters?.formulatedWithoutSilicate.value ||
        hasDifferentValue(
          products.map(
            (prod) => prod.productCharacteristic.formulatedWithoutSilicateId,
          ),
        ),
      value: currentFilters?.formulatedWithoutSilicate.value || false,
    },
    formulatedWithoutChlorinatedParaffin: {
      hasDifferentValue:
        currentFilters?.formulatedWithoutChlorinatedParaffin.value ||
        hasDifferentValue(
          products.map(
            (prod) =>
              prod.productCharacteristic.formulatedWithoutChlorinatedParaffinId,
          ),
        ),
      value:
        currentFilters?.formulatedWithoutChlorinatedParaffin.value || false,
    },
    formulatedWithoutBoron: {
      hasDifferentValue:
        currentFilters?.formulatedWithoutBoron.value ||
        hasDifferentValue(
          products.map(
            (prod) => prod.productCharacteristic.formulatedWithoutBoronId,
          ),
        ),
      value: currentFilters?.formulatedWithoutBoron.value || false,
    },
    formulatedWithoutHeavyMetal: {
      hasDifferentValue:
        currentFilters?.formulatedWithoutHeavyMetal.value ||
        hasDifferentValue(
          products.map(
            (prod) => prod.productCharacteristic.formulatedWithoutHeavyMetalId,
          ),
        ),
      value: currentFilters?.formulatedWithoutHeavyMetal.value || false,
    },
    metalType: {
      options: getUniqueIdArray(
        products,
        (prod) =>
          prod.productCharacteristic.productCharacteristicMetalTypes.map(
            (metalType) => metalType.metalTypeId,
          ),
        currentFilters?.metalType.value,
      ),
      value: currentFilters?.metalType.value || [],
    },
    multiMetalsSuitable: {
      hasDifferentValue:
        currentFilters?.multiMetalsSuitable.value ||
        hasDifferentValue(
          products.map(
            (prod) => prod.productCharacteristic.multiMetalsSuitableId,
          ),
        ),
      value: currentFilters?.multiMetalsSuitable.value || false,
    },
    nlgi: {
      options: getUniqueIdArray(
        products,
        (prod) =>
          prod.productCharacteristic.productCharacteristicNlgis.map(
            (nlgi) => nlgi.nlgiId,
          ),
        currentFilters?.nlgi.value,
      ),
      value: currentFilters?.nlgi.value || [],
    },
    oilType: {
      options: [
        getUniqueIdArray(
          products,
          (prod) => prod.productCharacteristic.baseOilTypeGenericId,
          currentFilters?.oilType.value[0],
        ),
        getUniqueIdArray(
          products,
          (prod) => prod.productCharacteristic.baseOilTypeSpecificId,
          currentFilters?.oilType.value[1],
        ),
      ],
      value: [
        currentFilters?.oilType.value[0] || [],
        currentFilters?.oilType.value[1] || [],
      ],
    },
    operatingTemperature: (() => {
      const range = getRange(products, (prod) => [
        prod.productCharacteristic.minTemperatureC,
        prod.productCharacteristic.maxTemperatureC,
      ]);
      if (currentFilters?.operatingTemperature.value) {
        return {
          ...currentFilters?.operatingTemperature,
        };
      } else {
        return {
          min: range ? range[0] : null,
          max: range ? range[1] : null,
          value: range,
        };
      }
    })(),
    subSector: {
      options: getUniqueIdArray(
        products,
        (prod) =>
          prod.productSubSectors.map((subSector) => subSector.subSectorId),
        currentFilters?.subSector.value,
      ),
      value: currentFilters?.subSector.value || [],
    },
    superiorBiodegradation: {
      hasDifferentValue:
        currentFilters?.superiorBiodegradation.value ||
        hasDifferentValue(
          products.map(
            (prod) => prod.productCharacteristic.superiorBiodegradationId,
          ),
        ),
      value: currentFilters?.superiorBiodegradation.value || false,
    },
    thickener: {
      options: [
        getUniqueIdArray(
          products,
          (prod) => prod.productCharacteristic.thickenerGenericId,
          currentFilters?.thickener.value[0],
        ),
        getUniqueIdArray(
          products,
          (prod) => prod.productCharacteristic.thickenerSpecificId,
          currentFilters?.thickener.value[1],
        ),
      ],
      value: [
        currentFilters?.thickener.value[0] || [],
        currentFilters?.thickener.value[1] || [],
      ],
    },
    viscosityIndex: {
      options: getUniqueIdArray(
        products,
        (prod) => prod.productCharacteristic.viscosityIndexId,
        currentFilters?.viscosityIndex.value,
      ),
      value: currentFilters?.viscosityIndex.value || [],
    },
  };
}

/**
 * get filtered search result
 * @param searchResult search result
 * @param filters filters
 */
function getFilteredSearchResult(
  searchResult: ProductSearchResult,
  filters?: Filters,
): SearchResult {
  const clone = cloneDeep(searchResult);

  if (filters) {
    clone.products = filterProducts(clone.products, filters);
  }

  return groupSearchResult(clone);
}

/**
 * manage product filter state
 */
export const productFilterSlice = createSlice({
  name: 'product-filter',
  initialState: {
    filters: getFiltersWithProducts([]),
    searchResult: {
      countries: [],
      ports: [],
      families: [],
      ranges: [],
      products: [],
      oems: [],
    },
    filteredSearchResult: {
      countries: [],
      ports: [],
      families: [],
      ranges: [],
      products: [],
      oems: [],
    },
  } as ProductFilterSliceState,
  reducers: {
    // set search result and create filters
    setFilterableSearchResult: (
      state,
      action: PayloadAction<{ res: ProductSearchResult; lookups: Lookups }>,
    ) => {
      const searchResult = action.payload.res;
      searchResult.products = resolveProductLookups(
        searchResult.products,
        action.payload.lookups,
      );

      return {
        ...state,
        searchResult,
        filteredSearchResult: getFilteredSearchResult(searchResult),
        // extract filterable values from products
        filters: getFiltersWithProducts(searchResult.products),
      };
    },
    // toggle filter
    toggleMultiSelectFilterById: (
      state,
      action: PayloadAction<{
        key: keyof Filters;
        filter: number;
      }>,
    ) => {
      const { key, filter } = action.payload;
      const filters = {
        ...state.filters,
        [key]: toggleMultiSelectFilter(
          state.filters[key] as MultiSelectFilter,
          filter,
        ),
      };

      const filteredSearchResult = getFilteredSearchResult(
        state.searchResult,
        filters,
      );

      return {
        ...state,
        filters: getFiltersWithProducts(filteredSearchResult.products, filters),
        filteredSearchResult,
      };
    },
    // clear filter for type
    clearMultiSelectFilter: (
      state,
      action: PayloadAction<{ key: keyof Filters }>,
    ) => {
      const { key } = action.payload;
      const filters = {
        ...state.filters,
        [key]: {
          ...state.filters[key],
          value: [],
        },
      };

      const filteredSearchResult = getFilteredSearchResult(
        state.searchResult,
        filters,
      );

      return {
        ...state,
        filters: getFiltersWithProducts(filteredSearchResult.products, filters),
        filteredSearchResult,
      };
    },
    changeRangeFilter: (
      state,
      action: PayloadAction<{ key: keyof Filters; value: number[] }>,
    ) => {
      const { key } = action.payload;
      const filters = {
        ...state.filters,
        [key]: {
          ...state.filters[key],
          value: action.payload.value,
        },
      };

      const filteredSearchResult = getFilteredSearchResult(
        state.searchResult,
        filters,
      );

      return {
        ...state,
        filters: getFiltersWithProducts(filteredSearchResult.products, filters),
        filteredSearchResult,
      };
    },
    toggleCheckboxFilter: (
      state,
      action: PayloadAction<{ key: keyof Filters }>,
    ) => {
      const { key } = action.payload;
      const filters = {
        ...state.filters,
        [key]: {
          ...state.filters[key],
          value: !state.filters[key].value,
        },
      };

      const filteredSearchResult = getFilteredSearchResult(
        state.searchResult,
        filters,
      );

      return {
        ...state,
        filters: getFiltersWithProducts(filteredSearchResult.products, filters),
        filteredSearchResult,
      };
    },
    toggleCombinedMultiSelectFilter: (
      state,
      action: PayloadAction<{
        key: keyof Filters;
        idMap: { [index: number]: number };
      }>,
    ) => {
      const { key } = action.payload;
      const filtersOfKey = cloneDeep(state.filters[key]);

      Object.entries(action.payload.idMap).forEach(([indexStr, id]) => {
        const index = Number(indexStr);
        const selectedIds = (filtersOfKey.value as number[][])[index];
        const selectedIndex = selectedIds.indexOf(id);
        if (selectedIndex >= 0) {
          // remove
          selectedIds.splice(selectedIndex, 1);
        } else {
          // add
          selectedIds.push(id);
        }
      });

      const newFilters = {
        ...state.filters,
        [key]: filtersOfKey,
      };

      const filteredSearchResult = getFilteredSearchResult(
        state.searchResult,
        newFilters,
      );

      return {
        ...state,
        filters: getFiltersWithProducts(
          filteredSearchResult.products,
          newFilters,
        ),
        filteredSearchResult,
      };
    },
    clearCombinedMultiSelectFilter: (
      state,
      action: PayloadAction<{ key: keyof Filters }>,
    ) => {
      const { key } = action.payload;
      const filtersOfKey = cloneDeep(state.filters[key]);

      (filtersOfKey.value as number[][]).forEach((array) =>
        array.splice(0, array.length),
      );

      const newFilters = {
        ...state.filters,
        [key]: filtersOfKey,
      };

      const filteredSearchResult = getFilteredSearchResult(
        state.searchResult,
        newFilters,
      );

      return {
        ...state,
        filters: getFiltersWithProducts(
          filteredSearchResult.products,
          newFilters,
        ),
        filteredSearchResult,
      };
    },
    // clear all filters
    clearAllFilters: (state) => {
      const filters = getFiltersWithProducts(state.searchResult.products);

      return {
        ...state,
        filters,
        filteredSearchResult: getFilteredSearchResult(
          state.searchResult,
          filters,
        ),
      };
    },
  },
});

export const {
  setFilterableSearchResult,
  toggleMultiSelectFilterById,
  clearMultiSelectFilter,
  changeRangeFilter,
  toggleCheckboxFilter,
  toggleCombinedMultiSelectFilter,
  clearCombinedMultiSelectFilter,
  clearAllFilters,
} = productFilterSlice.actions;

export default productFilterSlice.reducer;
