import { useState, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import cn from 'classnames';

import RowActions from '../../RowActions/RowActions';
import MultipleSelect from '../../../../components/Admin/MultiSelect/MultiSelect';

import { ReactComponent as EditIcon } from '../../../../icons/edit-green.svg';
import { ReactComponent as EditWhiteIcon } from '../../../../icons/edit-white.svg';
import { ReactComponent as CheckIcon } from '../../../../icons/check.svg';
import { ReactComponent as CloseIcon } from '../../../../icons/close-thick-white.svg';
import { ReactComponent as Barge } from '../../../../icons/barge.svg';
import { ReactComponent as Truck } from '../../../../icons/truck.svg';
import { ReactComponent as IBC } from '../../../../icons/ibc-gray.svg';
import { ReactComponent as Drum } from '../../../../icons/drum-gray.svg';
import { ReactComponent as Pail } from '../../../../icons/pail.svg';

import {
  Actions,
  StockpointProductType,
} from '../../../../models/admin-form-types';

import styles from './ProductListWithNote.module.scss';
import { cloneDeep } from 'lodash';

import Textarea from '../../../common/Textarea/Textarea';
import CheckBox from '../../../common/Checkbox/Checkbox';

type ProductListWithNoteProps = {
  products: StockpointProductType[];
  ports: any[];
  defaultPortId: number;
  productStatus: any[];
  setProductStatus: (
    item: {
      productId: number;
      note: {
        note: string;
        ports: number[];
      };
      portProductExclusion: {
        value?: boolean;
      };
    }[],
  ) => void;
};

const ProductListWithNote = ({
  products,
  ports,
  defaultPortId,
  productStatus,
  setProductStatus,
}: ProductListWithNoteProps) => {
  const intl = useIntl();
  const [showAction, setShowAction] = useState(-1);
  const [productNoteStatus, setProductNoteStatus] = useState<
    {
      productId: number;
      editable: boolean;
      applyMulti: boolean;
      note: string;
      noteChanging: string;
      ports: number[];
    }[]
  >([]);

  const defaultPorts = [defaultPortId];
  const portOptions = useMemo(() => {
    return (
      ports?.map((item) => ({
        name: item.name,
        value: item.id,
        disabled: defaultPortId === item.id,
      })) || []
    );
  }, [ports]);

  useEffect(() => {
    if (products && products.length > 0) {
      const tmpProductNoteStatus = products.map((item: any) => ({
        productId: item.productId,
        editable: false,
        applyMulti: false,
        note: item.portProductNotes || '',
        noteChanging: item.portProductNotes || '',
        ports: [defaultPortId],
      }));
      setProductNoteStatus(tmpProductNoteStatus);
    }
  }, [products]);

  const productCategoriesIds = [
    {
      key: 'offerBulkBadge',
      labelId: 'barge-label',
    },
    {
      key: 'offerBulkTruck',
      labelId: 'truck-label',
    },
    {
      key: 'offerBulkIbc',
      labelId: 'port-availability-ibc-label',
    },
    {
      key: 'offerPackDrum',
      labelId: 'port-availability-drum-label',
    },
    {
      key: 'offerPackPail',
      labelId: 'port-availability-pail-label',
    },
  ];
  const productCategories = productCategoriesIds.map((productCategory) => ({
    key: productCategory.key,
    label: intl.formatMessage({ id: productCategory.labelId }),
  }));

  const rowActions: Actions[] = [
    {
      fieldKey: 'edit-note',
      nameId: 'edit-note-label',
      icon: <EditIcon />,
      iconActive: <EditWhiteIcon />,
    },
    {
      fieldKey: 'exclude',
      nameId: 'exclude-label',
      icon: <EditIcon />,
      iconActive: <EditWhiteIcon />,
      isVisible: (item: any) =>
        item && (!item.portProductExclusion.value || false),
    },
    {
      fieldKey: 'include',
      nameId: 'include-label',
      icon: <EditIcon />,
      iconActive: <EditWhiteIcon />,
      isVisible: (item: any) =>
        item && (item.portProductExclusion.value || false),
    },
  ];

  const handleEditableChange = (productId: number, value: boolean) => {
    const tempStatus = cloneDeep(productNoteStatus);
    const index = tempStatus.findIndex((x) => x.productId === productId);
    tempStatus[index].editable = value;
    setProductNoteStatus(tempStatus);
  };

  const toggleExclusion = (productId: number, value: boolean) => {
    const tempStatus = cloneDeep(productStatus);
    const index = tempStatus.findIndex((x) => x.productId === productId);
    tempStatus[index].portProductExclusion.value = value;
    setProductStatus(tempStatus);
  };

  const handleAction = (fieldKey: string, productId: number) => {
    switch (fieldKey) {
      case 'edit-note': {
        handleEditableChange(productId, true);
        break;
      }
      case 'include': {
        toggleExclusion(productId, false);
        break;
      }
      case 'exclude': {
        toggleExclusion(productId, true);
        break;
      }
    }
  };

  const handleApplyMultiToggle = (index: number) => {
    const tempStatus = cloneDeep(productNoteStatus);
    tempStatus[index].applyMulti = !tempStatus[index].applyMulti;
    if (!tempStatus[index].applyMulti) {
      tempStatus[index].ports = [];
    }
    setProductNoteStatus(tempStatus);
  };

  const handleNoteChange = (index: number, value: string) => {
    const tempStatus = cloneDeep(productNoteStatus);
    tempStatus[index].noteChanging = value;
    setProductNoteStatus(tempStatus);
  };

  const handleMultiNotesChange = (index: number, ids: any) => {
    const tempStatus = cloneDeep(productNoteStatus);
    tempStatus[index].ports = ids;

    setProductNoteStatus(tempStatus);
  };

  const handleCancelClick = (index: number) => {
    const tempStatus = cloneDeep(productStatus);
    const tempNoteStatus = cloneDeep(productNoteStatus);
    const productId = tempNoteStatus[index].productId;

    const currentTempStatus = tempStatus.find((x) => x.productId === productId);

    if (currentTempStatus) {
      tempNoteStatus[index].applyMulti = currentTempStatus?.note.applyMulti;
      tempNoteStatus[index].editable = false;
      tempNoteStatus[index].note = currentTempStatus?.note.note;
      tempNoteStatus[index].noteChanging = currentTempStatus?.note.note;
      tempNoteStatus[index].ports = currentTempStatus?.note.ports;
    }

    setProductNoteStatus(tempNoteStatus);
  };

  const productNotePart = (index: number) => {
    return productNoteStatus[index] && productNoteStatus[index].editable ? (
      <div>
        <Textarea
          value={productNoteStatus[index].noteChanging}
          onChange={(value) => handleNoteChange(index, value)}
          placeholder={''}
          maxLength={200}
        />
        <div
          onClick={() => handleApplyMultiToggle(index)}
          className={styles.applyMulti}
        >
          <CheckBox
            checked={productNoteStatus[index].applyMulti}
            className={styles.checkbox}
          />
          <span>
            <FormattedMessage id={'apply-to-multi-ports'} />
          </span>
        </div>
        {productNoteStatus[index].applyMulti && (
          <MultipleSelect
            className={cn(styles.selectDropdown)}
            inputClassName={styles.selectInput}
            optionClassName={styles.selectOption}
            values={productNoteStatus[index].ports}
            options={portOptions}
            onChange={(value) => handleMultiNotesChange(index, value)}
            placeholder={'Select Ports'}
            defaultSelected={defaultPorts}
          />
        )}
        <div className={styles.actionButtons}>
          <div
            className={cn(
              styles.button,
              styles.applyChange,
              // productNoteStatus[index].noteChanging.trim().length === 0 &&
              //   styles.disabled,
            )}
            onClick={() => {
              const tempNoteStatus = cloneDeep(productNoteStatus);
              const tempStatus = cloneDeep(productStatus);
              const currentTempNoteStatus = tempNoteStatus[index];

              currentTempNoteStatus.note = currentTempNoteStatus.noteChanging;
              const currentTempStatus = tempStatus.find(
                (item) => item.productId === currentTempNoteStatus.productId,
              );
              currentTempStatus.note.applyMulti =
                currentTempNoteStatus?.applyMulti;
              currentTempStatus.note.note = currentTempNoteStatus.noteChanging;
              currentTempStatus.note.ports = currentTempNoteStatus.ports;

              tempNoteStatus[index].editable = false;
              setProductNoteStatus(tempNoteStatus);
              setProductStatus(tempStatus);
            }}
          >
            <CheckIcon />
          </div>
          <div
            className={cn(styles.button, styles.cancelChange)}
            onClick={() => handleCancelClick(index)}
          >
            <CloseIcon />
          </div>
        </div>
      </div>
    ) : (
      <span> {productNoteStatus[index] && productNoteStatus[index].note} </span>
    );
  };

  const showCategoryIcon = (key: string, value: boolean) => {
    if (!value) {
      return <div />;
    }

    switch (key) {
      case 'offerBulkBadge':
        return <Barge />;
      case 'offerBulkTruck':
        return <Truck />;
      case 'offerBulkIbc':
        return <IBC />;
      case 'offerPackDrum':
        return <Drum />;
      case 'offerPackPail':
        return <Pail />;
      default:
        return <div />;
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.desktopContainer}>
        <div className={styles.header}>
          <span>
            <FormattedMessage id={'product-label'} />
          </span>
          <span className={styles.productNotesLabel}>
            <FormattedMessage id={'notes-label'} />
          </span>
          <div className={styles.productCategoriesWrapper}>
            <div className={styles.productCategoriesLabel}>
              <FormattedMessage id={'physical-offer-label'} />
            </div>
            <div className={styles.productCategories}>
              {productCategories.map((category) => (
                <span key={category.label}>{category.label}</span>
              ))}
            </div>
          </div>
          <span>
            <FormattedMessage id={'actions-label'} />
          </span>
        </div>
        <div className={styles.productsWrapper}>
          {products.map((product, index) => (
            <div
              className={cn(
                styles.productContainer,
                productStatus.find((p) => p.productId === product.productId)
                  ?.portProductExclusion.value
                  ? styles.inactive
                  : '',
              )}
              key={`${product.name}-${index}`}
            >
              <div className={styles.productName}>
                <span>{product.name}</span>
                {productStatus.find((p) => p.productId === product.productId)
                  ?.portProductExclusion.value && (
                  <div className={styles.statusText}>Excluded</div>
                )}
              </div>
              <div className={styles.productNotes}>
                {productNotePart(index)}
              </div>
              <div className={styles.productCategoryIconContainer}>
                {productCategories.map((category) => (
                  <div key={category.key} className={styles.iconWrapper}>
                    {showCategoryIcon(
                      category.key,
                      !!product[category.key as keyof StockpointProductType],
                    )}
                  </div>
                ))}
              </div>
              <div className={styles.settingsIconWrapper}>
                <RowActions
                  showAction={showAction === index}
                  handleShowAction={(state: boolean) => {
                    if (state) {
                      setShowAction(index);
                    } else {
                      setShowAction(-1);
                    }
                  }}
                  actions={rowActions}
                  handleActionClick={(fieldKey) =>
                    handleAction(fieldKey, product.productId)
                  }
                  item={productStatus.find(
                    (p) => p.productId === product.productId,
                  )}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.phoneContainer}>
        {products.map((product, index) => (
          <div
            key={product.name}
            className={cn(
              styles.itemWrapper,
              productStatus.find((p) => p.productId === product.productId)
                ?.portProductExclusion.value
                ? styles.inactive
                : '',
            )}
          >
            <div className={styles.sectionHeader}>
              <div className={styles.firstRow}>
                <span>
                  <FormattedMessage id={'product-label'} />
                </span>
                <RowActions
                  showAction={showAction === index}
                  handleShowAction={(state: boolean) => {
                    if (state) {
                      setShowAction(index);
                    } else {
                      setShowAction(-1);
                    }
                  }}
                  actions={rowActions}
                  handleActionClick={(fieldKey) =>
                    handleAction(fieldKey, product.productId)
                  }
                  item={productStatus.find(
                    (p) => p.productId === product.productId,
                  )}
                />
              </div>
              <div className={styles.productNameWrapper}>
                <span className={styles.productName}>{product.name}</span>

                {productStatus.find((p) => p.productId === product.productId)
                  ?.portProductExclusion.value && (
                  <span className={styles.excluded}>Excluded</span>
                )}
              </div>
            </div>
            <span className={styles.productNotesLabel}>
              <FormattedMessage id={'notes-label'} />
            </span>
            <div className={styles.productNotes}>{productNotePart(index)}</div>
            <div className={styles.contentWrapper}>
              <span className={styles.physicalOfferLabel}>
                <FormattedMessage id={'physical-offer-label'} />
              </span>
              {productCategories.map((category) => (
                <div key={category.key} className={styles.categoryWrapper}>
                  <span>{category.label}</span>
                  <div className={styles.iconWrapper}>
                    {showCategoryIcon(
                      category.key,
                      !!product[category.key as keyof StockpointProductType],
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductListWithNote;
