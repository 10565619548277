import { PropsWithoutRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import cn from 'classnames';
import { cloneDeep } from 'lodash';

import { ValidationColumn } from '../../../../../models/table-column';
import styles from './ValidationTable.module.scss';

import { ErrorMessage } from '../../AddMultiUsersPage';
import Input from '../../../../../components/common/Input/Input';
import Select from '../../../../../components/common/Select/Select';
import MultipleSelect from '../../../../../components/Admin/MultiSelect/MultiSelect';
import { ReactComponent as Caret } from '../../../../../icons/caret.svg';

interface ValidationTableProps<T> {
  data: T[];
  setDataAtIndex: (x: T, i: number) => void;
  columns: ValidationColumn<T>[];
  errorMessages: ErrorMessage[];
  setErrorMessages: (x: ErrorMessage[]) => void;
}

function ValidationTable<T>({
  data = [],
  setDataAtIndex,
  columns,
  errorMessages,
  setErrorMessages,
}: PropsWithoutRef<ValidationTableProps<T>>) {
  const intl = useIntl();
  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });

  const [expanded, setExpanded] = useState<{ id: number; expanded: boolean }[]>(
    [],
  );

  const handleCollapseClick = (
    index: number,
    expanded: { id: number; expanded: boolean }[],
  ) => {
    if (expanded && expanded.length > 0) {
      const items = cloneDeep(expanded);
      const item = expanded.find((x: any) => x.id === index);
      if (item) {
        const itemInd = expanded.findIndex((x: any) => x.id === index) || 0;
        items[itemInd].expanded = !item.expanded;
        setExpanded(items);
      } else {
        items.push({ id: index, expanded: false });
        setExpanded(items);
      }
    } else {
      setExpanded([{ id: index, expanded: true }]);
    }
  };

  const getHeaderVal = (
    data: T,
    headerIds: (keyof T)[] | undefined,
  ): string => {
    if (!headerIds) {
      return '';
    }
    const headerStrings = headerIds.map((item) => data[item]);
    return headerStrings.join(' ');
  };

  const invalidColumn = (
    column: ValidationColumn<T>,
    inputVal: any,
    index: number,
  ): boolean => {
    if (column.required && (!inputVal || inputVal.length === 0)) {
      return true;
    }
    if (column.customValidator && column.customValidator(inputVal)) {
      return true;
    }

    const errorFound = errorMessages.find(
      (item) => item.row === index + 1 && item.property === column.objectKey,
    );

    if (errorFound) {
      return true;
    }

    return false;
  };

  const handleErrorsChange = (rowIndex: number, objectKey: keyof T) => {
    const errorIndex = errorMessages.findIndex(
      (item) => item.row === rowIndex && item.property === objectKey,
    );
    if (errorIndex > -1) {
      const tmpArr = cloneDeep(errorMessages);
      tmpArr.splice(errorIndex, 1);
      setErrorMessages(tmpArr);
    }
  };

  const getColumnComponent = (
    column: ValidationColumn<T>,
    dataItem: T,
    index: number,
  ) => {
    if (column.renderer) {
      return column.renderer(column, dataItem, (value) =>
        setDataAtIndex(value, index),
      );
    } else if (column.type === 'input') {
      const inputVal = String(dataItem[column.objectKey] || '');
      return (
        <Input
          containerClassName={styles.inputContainer}
          className={cn(
            styles.input,
            invalidColumn(column, inputVal, index) && styles.error,
          )}
          value={inputVal}
          onChange={(value) => {
            handleErrorsChange(index, column.objectKey);
            setDataAtIndex(
              {
                ...dataItem,
                [column.objectKey]: value,
              },
              index,
            );
          }}
          placeholder={
            column.placeholder || getTranslatedMessage('input-placeholder')
          }
          customValidator={column.customValidator}
        />
      );
    } else if (column.type === 'select') {
      const inputVal = dataItem[column.objectKey];
      return (
        <Select
          className={cn(
            styles.selectDropdown,
            invalidColumn(column, inputVal, index) && styles.error,
          )}
          inputClassName={styles.selectInput}
          optionClassName={styles.selectOption}
          value={dataItem[column.objectKey]}
          options={column.selectOptions || []}
          onChange={(value) => {
            handleErrorsChange(index, column.objectKey);
            setDataAtIndex(
              {
                ...dataItem,
                [column.objectKey]: value,
              },
              index,
            );
          }}
          placeholder={
            column.placeholder || getTranslatedMessage('select-placeholder')
          }
          canSearch={column.canSearch}
        />
      );
    } else if (column.type === 'multi-select') {
      const inputVal = dataItem[column.objectKey];
      return (
        <MultipleSelect
          className={cn(
            styles.selectDropdown,
            styles.multiSelect,
            invalidColumn(column, inputVal, index) && styles.error,
          )}
          inputClassName={styles.selectInput}
          optionClassName={styles.selectOption}
          values={dataItem[column.objectKey] as any}
          options={column.selectOptions || []}
          onChange={(value) => {
            handleErrorsChange(index, column.objectKey);
            setDataAtIndex(
              {
                ...dataItem,
                [column.objectKey]: value,
              },
              index,
            );
          }}
          placeholder={
            column.placeholder || getTranslatedMessage('select-placeholder')
          }
          suffixLabel={column.selectSuffixLabel}
          canSearch
        />
      );
    }
    return dataItem[column.objectKey];
  };

  return (
    <div className={styles.container}>
      <div className={styles.tableHeader}>
        <div className={styles.tableRow}>
          {columns.map((column) => (
            <div className={styles.tableColumn} key={column.tableHeaderId}>
              <FormattedMessage id={column.tableHeaderId} />
              {column.required && (
                <span className={styles.requiredIndicator}>*</span>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.tableBody}>
        {data.map((item, index) => (
          <div
            key={index}
            className={cn(
              styles.tableRow,
              expanded && expanded.find((x) => x.id === index)?.expanded
                ? styles.expanded
                : styles.collapsed,
            )}
          >
            <div className={styles.headerColumn}>
              <span className={styles.columnLabel}>
                {getHeaderVal(item, columns[0].headerKey)}
              </span>
              <Caret
                className={styles.dropdownArrow}
                onClick={() => {
                  handleCollapseClick(index, expanded);
                }}
              />
              <div className={styles.divider} />
            </div>
            {columns.map((column, i) => (
              <div className={styles.tableColumn} key={i}>
                <span className={styles.columnLabel}>
                  <FormattedMessage id={column.tableHeaderId} />
                  {column.required && (
                    <span className={styles.requiredIndicator}>*</span>
                  )}
                </span>
                {getColumnComponent(column, item, index)}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default ValidationTable;
