import { useEffect, useState } from 'react';
import styles from './ProductReportPage.module.scss';
import ContainerForDrawer from '../../../../components/common/ContainerForDrawer/ContainerForDrawer';
import NavigationHeader from '../../../../components/common/NavigationHeader/NavigationHeader';
import { PreviousLink } from '../../../../models/previous-link';
import { FormattedMessage, useIntl } from 'react-intl';
import QuickStatsForm from '../components/QuickStatsForm/QuickStatsForm';
import DateField from '../../../../components/Admin/DateField/DateField';
import { RootStore } from '../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { CountryItem } from '../../../../models/lookups';
import classNames from 'classnames';
import { ReactComponent as CancelIcon } from '../../../../icons/cross-circle.svg';
import { ReactComponent as XlsxFileIcon } from '../../../../icons/xlsx-file.svg';
import InputRow from '../../../../components/Admin/InputRow/InputRow';
import { getProductActivities } from '../../../../services/StatsService';
import { getRangeFamilies } from '../../../../services/FamilyService';
import { RangeFamily } from '../../../../models/admin-form-types';
import LoadingSpinner from '../../../../components/common/LoadingSpinner/LoadingSpinner';
import { userRoles } from '../../../../configs/admin-constants';
import FormRow from '../../../../components/Admin/FormRow/FormRow';
import MultiProductSelect from '../../Products/AddCountryLevelProductPage/components/MultiProductSelect/MultiProductSelect';
import { addToast } from '../../../../features/toastSlice';

/**
 * Product Report page component
 */
function ProductReportPage() {
  const intl = useIntl();
  const { countries, sectors, categories } = useSelector(
    (state: RootStore) => state.lookups,
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [rangeFamilies, setRangeFamilies] = useState<RangeFamily[]>([]);

  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });
  const initialState: any = {
    productName: '',
    familyIds: [],
    categoryIds: [],
    sectorIds: [],
    userRole: [],
    startDate: null,
    endDate: null,
    country: [],
    language: [],
  };

  const [formValue, setFormValue] = useState(initialState);

  const breadcrumbLinks: PreviousLink[] = [
    {
      name: 'Report',
      to: '/admin/reports',
    },
    {
      name: 'Product Report',
    },
  ];

  const rangeFamiliesItems = [
    {
      name: getTranslatedMessage('reports-page-select-all'),
      value: -1,
    },
    ...rangeFamilies.map((item) => ({
      name: item.name,
      value: item.id,
    })),
  ];

  const sectorsItems = [
    {
      name: getTranslatedMessage('reports-page-select-all'),
      value: -1,
    },
    ...sectors.map((item) => ({
      name: item.name,
      value: item.id,
    })),
  ];

  const categoriesItems = [
    {
      name: getTranslatedMessage('reports-page-select-all'),
      value: -1,
    },
    ...categories.map((item) => ({
      name: item.name,
      value: item.id,
    })),
  ];

  const handleValueChange = (
    fieldKey: string,
    value: string | boolean | number[],
  ) => {
    setFormValue({
      ...formValue,
      [fieldKey]: value,
    });
  };

  const isInitialState =
    !formValue.productName &&
    !formValue.familyIds.length &&
    !formValue.categoryIds.length &&
    !formValue.sectorIds.length &&
    !formValue.userRole &&
    !formValue.startDate &&
    !formValue.endDate &&
    !formValue.country &&
    !formValue.language;

  const clearFilter = () => {
    setFormValue(initialState);
  };

  useEffect(() => {
    setLoading(true);
    getRangeFamilies()
      .then(async (res: RangeFamily[]) => {
        setRangeFamilies(res);
      })
      .finally(() => setLoading(false));
  }, []);

  const onExport = () => {
    setLoading(true);
    getProductActivities(
      formValue.productName,
      formValue.familyIds,
      formValue.categoryIds,
      formValue.sectorIds,
      formValue.userRole,
      formValue.startDate,
      formValue.endDate,
      formValue.country,
    )
      .catch(async (err) => {
        const respData = err?.response?.data;
        const respJson = respData ? JSON.parse(await respData.text()) : {};
        dispatch(
          addToast({
            type: 'error',
            title: intl.formatMessage({ id: 'report-product-report-failure' }),
            message: respJson.errors || respJson.message || err?.message || '',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const countryItems = [
    {
      name: getTranslatedMessage('reports-page-select-all'),
      value: -1,
    },
    ...countries.map((country: CountryItem) => ({
      name: country.name,
      value: country.id,
    })),
  ] as any;

  const userRoleItems = [
    {
      name: getTranslatedMessage('reports-page-select-all'),
      value: -1,
    },
    ...userRoles.map((role: string) => ({
      name: role,
      value: role,
    })),
  ] as any;

  return (
    <>
      <div className={classNames(loading ? styles.loadingOverlayDim : null)}>
        <NavigationHeader breadcrumbLinks={breadcrumbLinks} />
        <ContainerForDrawer>
          <div className={styles.container}>
            <div className={styles.title}>
              <FormattedMessage id={'product-report-page-title'} />
            </div>
            <div className={styles.contentContainer}>
              <div className={styles.reportDetailContainer}>
                <div>
                  <QuickStatsForm
                    title={getTranslatedMessage('product-report-page-filter')}
                    secondaryTitle={getTranslatedMessage(
                      'product-report-page-description',
                    )}
                  >
                    <div className={styles.formFieldsContainer}>
                      <div className={'columnRow'}>
                        <InputRow
                          labelId={'product-report-page-product-name'}
                          value={formValue.productName}
                          required={false}
                          placeholder={getTranslatedMessage(
                            'oem-page-input-placeholder',
                          )}
                          onChange={(value) =>
                            handleValueChange('productName', value)
                          }
                          errorMessage={getTranslatedMessage(
                            'field-required-error',
                          )}
                        />
                      </div>

                      <div className={styles.row}>
                        <FormRow
                          labelId="product-report-page-product-family"
                          required={false}
                          className={styles.multipleSelect}
                        >
                          <MultiProductSelect
                            value={formValue.familyIds}
                            onChange={(value: number[]) => {
                              if (value.includes(-1)) {
                                handleValueChange('familyIds', []);
                              } else {
                                handleValueChange('familyIds', value);
                              }
                            }}
                            options={rangeFamiliesItems}
                            className={styles.multipleSelect}
                            inputClassName={styles.multipleInput}
                            selectClassName={styles.selectOptions}
                            selectedItemName={getTranslatedMessage(
                              'reports-field-product-select',
                            )}
                            selectedItemNameSingular={getTranslatedMessage(
                              'reports-field-product-select-singular',
                            )}
                            placeholder={getTranslatedMessage(
                              'reports-page-select-all',
                            )}
                          />
                        </FormRow>
                        <FormRow
                          labelId="product-report-page-product-category"
                          required={false}
                          className={styles.multipleSelect}
                        >
                          <MultiProductSelect
                            value={formValue.categoryIds}
                            options={categoriesItems}
                            onChange={(value: number[]) => {
                              if (value.includes(-1)) {
                                handleValueChange('categoryIds', []);
                              } else {
                                handleValueChange('categoryIds', value);
                              }
                            }}
                            className={styles.multipleSelect}
                            inputClassName={styles.multipleInput}
                            selectClassName={styles.selectOptions}
                            selectedItemName={getTranslatedMessage(
                              'reports-field-category-type-select',
                            )}
                            selectedItemNameSingular={getTranslatedMessage(
                              'reports-field-category-type-select-singular',
                            )}
                            placeholder={getTranslatedMessage(
                              'reports-page-select-all',
                            )}
                          />
                        </FormRow>
                        <FormRow
                          labelId="product-report-page-sector"
                          required={false}
                          className={styles.multipleSelect}
                        >
                          <MultiProductSelect
                            options={sectorsItems}
                            value={formValue.sectorIds}
                            onChange={(value: number[]) => {
                              if (value.includes(-1)) {
                                handleValueChange('sectorIds', []);
                              } else {
                                handleValueChange('sectorIds', value);
                              }
                            }}
                            className={styles.multipleSelect}
                            inputClassName={styles.multipleInput}
                            selectClassName={styles.selectOptions}
                            selectedItemName={getTranslatedMessage(
                              'reports-field-sector-select',
                            )}
                            selectedItemNameSingular={getTranslatedMessage(
                              'reports-field-sector-select-singular',
                            )}
                            placeholder={getTranslatedMessage(
                              'reports-page-select-all',
                            )}
                          />
                        </FormRow>
                      </div>

                      <div className={classNames(styles.row, styles.flexStart)}>
                        <FormRow
                          labelId="product-report-page-user-country"
                          required={false}
                          className={classNames(
                            styles.formRow,
                            styles.selectRow,
                          )}
                        >
                          <MultiProductSelect
                            value={formValue.country}
                            onChange={(value: number[]) => {
                              if (value.includes(-1)) {
                                handleValueChange('country', []);
                              } else {
                                handleValueChange('country', value);
                              }
                            }}
                            placeholder={getTranslatedMessage(
                              'reports-page-select-all',
                            )}
                            options={countryItems}
                            className={styles.multipleSelect}
                            inputClassName={styles.multipleInput}
                            selectClassName={styles.selectOptions}
                            selectedItemName={getTranslatedMessage(
                              'reports-field-country-select',
                            )}
                            selectedItemNameSingular={getTranslatedMessage(
                              'reports-field-country-select-singular',
                            )}
                          />
                        </FormRow>
                        <FormRow
                          labelId="product-report-page-user-role"
                          required={false}
                          className={classNames(
                            styles.formRow,
                            styles.selectRow,
                          )}
                        >
                          <MultiProductSelect
                            value={formValue.userRole}
                            onChange={(value: number[]) => {
                              if (value.includes(-1)) {
                                handleValueChange('userRole', []);
                              } else {
                                handleValueChange('userRole', value);
                              }
                            }}
                            placeholder={getTranslatedMessage(
                              'reports-page-select-all',
                            )}
                            options={userRoleItems}
                            className={styles.multipleSelect}
                            inputClassName={styles.multipleInput}
                            selectClassName={styles.selectOptions}
                            selectedItemName={getTranslatedMessage(
                              'reports-field-role-select',
                            )}
                            selectedItemNameSingular={getTranslatedMessage(
                              'reports-field-role-select-singular',
                            )}
                          />
                        </FormRow>
                      </div>

                      <div className={classNames(styles.row, styles.flexStart)}>
                        <DateField
                          labelId="product-report-page-start-date"
                          value={formValue.startDate}
                          onChange={(value: string) =>
                            handleValueChange('startDate', value)
                          }
                          required={false}
                          className={classNames(styles.calendarRow)}
                          placeholder={getTranslatedMessage(
                            'reports-page-select-date',
                          )}
                        />
                        <DateField
                          labelId="product-report-page-end-date"
                          value={formValue.endDate}
                          onChange={(value: string) =>
                            handleValueChange('endDate', value)
                          }
                          required={false}
                          className={classNames(styles.calendarRow)}
                          placeholder={getTranslatedMessage(
                            'reports-page-select-date',
                          )}
                          tooltip={getTranslatedMessage(
                            'product-report-page-tooltip',
                          )}
                        />
                      </div>
                    </div>

                    <div className={styles.filterContainer}>
                      <div
                        className={classNames(styles.export)}
                        onClick={onExport}
                      >
                        <XlsxFileIcon />
                        <a className={styles.filterButton}>
                          {getTranslatedMessage(
                            'oem-table-field-export-to-excel',
                          )}
                        </a>
                      </div>
                      {!isInitialState && (
                        <div
                          className={styles.clearFilter}
                          onClick={clearFilter}
                        >
                          <CancelIcon className={styles.cancelIcon} />
                          <div>
                            {getTranslatedMessage('reports-page-clear-filter')}
                          </div>
                        </div>
                      )}
                    </div>
                  </QuickStatsForm>
                </div>
              </div>
            </div>
          </div>
        </ContainerForDrawer>
      </div>
      {loading && (
        <div className={styles.loadingOverlay}>
          <LoadingSpinner />
        </div>
      )}
    </>
  );
}

export default ProductReportPage;
