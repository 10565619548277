import { FormattedMessage, useIntl } from 'react-intl';
import StepContentContainer from '../../../../../components/Admin/StepContentContainer/StepContentContainer';
import '../../../../../components/Admin/shared.scss';

import styles from './ProductSummary.module.scss';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../../../store';
// import classNames from 'classnames';
// import ReplacementProductsList from '../../components/ReplacementProductsList/ReplacementProductsList';
import React, { useMemo } from 'react';
// import { UploadedDocument } from '../../../../../models/uploaded-document';
import {
  AddProductFormValues,
  RangeFamily,
} from '../../../../../models/admin-form-types';
// import { CountryLevelAlternativeProduct } from '../../../../../models/country-level-alternative-product';

interface ProductSummaryProps {
  families: RangeFamily[];
  formValue: AddProductFormValues;
}

function ProductSummary({ families, formValue }: ProductSummaryProps) {
  const intl = useIntl();

  const familyName = useMemo(() => {
    return (
      families.find((family) => family.id === formValue.productFamily)?.name ||
      ''
    );
  }, [families, formValue.productFamily]);

  // const getAddedDatasheetType = (
  //   document: File | string | UploadedDocument | null,
  // ) => {
  //   if (typeof document === 'string' || document === null) {
  //     return 'url';
  //   } else if (document instanceof File) {
  //     return 'file';
  //   } else {
  //     return document.url ? 'url' : 'file';
  //   }
  // };

  // const getFilenameOrUrl = (doc: File | string | UploadedDocument | null) => {
  //   if (!doc) {
  //     return {
  //       name: '',
  //     };
  //   } else if (getAddedDatasheetType(doc) === 'url') {
  //     const url =
  //       typeof doc === 'string' ? doc : (doc as UploadedDocument).url || '';
  //     return {
  //       name: url,
  //       link: url,
  //     };
  //   } else {
  //     const fileName =
  //       doc instanceof File ? doc.name : (doc as UploadedDocument).name || '';
  //     return {
  //       name: fileName,
  //     };
  //   }
  // };

  const {
    sectorsById,
    masterBrandsById,
    productTypesById,
    categoriesById,
    applicationsById,
    countriesById,
    yesNoNonesById,
    // nlgisById,
  } = useSelector((rootState: RootStore) => rootState.lookups);

  // const filteredSupportingDocCountries = useMemo(() => {
  //   return Object.entries(formValue.supportingDocByCountry)
  //     .filter(
  //       ([, countrySupportingDocument]) =>
  //         countrySupportingDocument.addedDocuments.length > 0,
  //     )
  //     .map(([countryId]) => Number(countryId));
  // }, [formValue.supportingDocByCountry]);

  const renderTextRow = (label: string, value: string) => (
    <>
      <div className={styles.rowLabel}>{label}</div>
      <div className={styles.rowValue}>{value}</div>
    </>
  );

  // const renderLinkRow = (
  //   label: string,
  //   value: { name: string; link?: string },
  // ) => {
  //   return (
  //     <>
  //       <div className={styles.rowLabel}>{label}</div>
  //       {value.link ? (
  //         <a
  //           className={classNames(styles.rowValue, styles.linkValue)}
  //           href={value.link}
  //         >
  //           {value.name}
  //         </a>
  //       ) : (
  //         <div className={classNames(styles.rowValue, styles.linkValue)}>
  //           {value.name}
  //         </div>
  //       )}
  //     </>
  //   );
  // };

  // const alternativeProducts = useMemo(() => {
  //   return formValue.productCountryLevels.reduce<
  //     CountryLevelAlternativeProduct[]
  //   >((list, countryLevel) => {
  //     list.push(...countryLevel.productCountryLevelAlternateProducts);
  //     return list;
  //   }, []);
  // }, [formValue.productCountryLevels]);

  return (
    <StepContentContainer
      title={intl.formatMessage({ id: 'product-summary-title' })}
    >
      <div className={styles.panelsContainer}>
        {/* step 1 */}
        <div className={styles.panel}>
          <div className={styles.title}>
            <FormattedMessage id="product-summary-step1-title" />
          </div>
          <div className={styles.content}>
            {/* section */}
            <div className={styles.section}>
              <div className={styles.row}>
                {renderTextRow(
                  intl.formatMessage({
                    id: 'product-summary-product-name',
                  }),
                  formValue.productName,
                )}
              </div>
              <div className={styles.row}>
                {renderTextRow(
                  intl.formatMessage({
                    id: 'product-summary-sector',
                  }),
                  formValue.sectorId
                    .map((sectorId) => sectorsById[sectorId].name)
                    .join(', '),
                )}
              </div>
            </div>
            {/* section */}
            <div className={styles.section}>
              <div className={styles.row}>
                {renderTextRow(
                  intl.formatMessage({
                    id: 'product-summary-product-tagline',
                  }),
                  formValue.localisedContentByLang[1].tagLine,
                )}
              </div>
              <div className={styles.row}>
                {renderTextRow(
                  intl.formatMessage({
                    id: 'product-summary-product-description',
                  }),
                  formValue.localisedContentByLang[1].description,
                )}
              </div>
            </div>
            {/* section */}
            <div className={styles.section}>
              <div className={styles.row}>
                {renderTextRow(
                  intl.formatMessage({
                    id: 'product-summary-master-brand',
                  }),
                  formValue.masterBrand === null
                    ? ''
                    : masterBrandsById[formValue.masterBrand]?.name,
                )}
              </div>
              <div className={styles.row}>
                {renderTextRow(
                  intl.formatMessage({
                    id: 'product-summary-product-family',
                  }),
                  familyName,
                )}
              </div>
              <div className={styles.row}>
                {renderTextRow(
                  intl.formatMessage({
                    id: 'product-summary-product-category',
                  }),
                  formValue.productCategory
                    .map((cat) => categoriesById[cat]?.name)
                    .join(', '),
                )}
              </div>
              <div className={styles.row}>
                {renderTextRow(
                  intl.formatMessage({
                    id: 'product-summary-product-type',
                  }),
                  formValue.productTypes
                    .map((productType) => productTypesById[productType]?.name)
                    .join(', '),
                )}
              </div>
              <div className={styles.row}>
                {renderTextRow(
                  intl.formatMessage({
                    id: 'product-summary-application',
                  }),
                  formValue.applications
                    .map((application) => applicationsById[application]?.name)
                    .join(', '),
                )}
              </div>
              <div className={styles.row}>
                {renderTextRow(
                  intl.formatMessage({
                    id: 'product-summary-repository-business',
                  }),
                  formValue.repositoryBusiness,
                )}
              </div>
              <div className={styles.row}>
                {renderTextRow(
                  intl.formatMessage({
                    id: 'product-summary-castrol-recommended',
                  }),
                  formValue.recommendedByCastrol
                    ? intl.formatMessage({
                        id: 'product-summary-yes',
                      })
                    : intl.formatMessage({
                        id: 'product-summary-no',
                      }),
                )}
              </div>
            </div>
          </div>
        </div>
        {/* step 2 */}
        <div className={styles.panel}>
          <div className={styles.title}>
            <FormattedMessage id="product-summary-step2-title" />
          </div>
          <div className={styles.content}>
            {/* section */}
            <div className={styles.section}>
              <div className={styles.row}>
                {renderTextRow(
                  intl.formatMessage({
                    id: 'product-summary-region-availability',
                  }),
                  formValue.avaiableCountries
                    .map((country) => countriesById[country].name)
                    .join(', '),
                )}
              </div>
            </div>
          </div>
        </div>
        {/* step 3 */}
        <div className={styles.panel}>
          <div className={styles.title}>
            <FormattedMessage id="product-summary-step3-title" />
          </div>
          <div className={styles.content}>
            {/* section */}
            <div className={styles.section}>
              <div className={styles.row}>
                {renderTextRow(
                  intl.formatMessage({
                    id: 'product-summary-ep-additive',
                  }),
                  formValue.esterEpAdditives
                    ? intl.formatMessage({
                        id: 'product-summary-yes',
                      })
                    : intl.formatMessage({
                        id: 'product-summary-no',
                      }),
                )}
              </div>
              <div className={styles.row}>
                {renderTextRow(
                  intl.formatMessage({
                    id: 'product-summary-food-grade',
                  }),
                  formValue.foodGrade === null
                    ? ''
                    : yesNoNonesById[formValue.foodGrade].name,
                )}
              </div>
              <div className={styles.row}>
                {renderTextRow(
                  intl.formatMessage({
                    id: 'product-summary-superior-biodegradation',
                  }),
                  formValue.superiorBiodegradation === null
                    ? ''
                    : yesNoNonesById[formValue.superiorBiodegradation].name,
                )}
              </div>
              <div className={styles.row}>
                {renderTextRow(
                  intl.formatMessage({
                    id: 'product-summary-multi-material-suitable',
                  }),
                  formValue.multiMetalsSuitable === null
                    ? ''
                    : yesNoNonesById[formValue.multiMetalsSuitable].name,
                )}
              </div>
              <div className={styles.row}>
                {renderTextRow(
                  intl.formatMessage({
                    id: 'product-summary-formulated-without-silicate',
                  }),
                  formValue.formulatedWithoutSilicate === null
                    ? ''
                    : yesNoNonesById[formValue.formulatedWithoutSilicate].name,
                )}
              </div>
              <div className={styles.row}>
                {renderTextRow(
                  intl.formatMessage({
                    id: 'product-summary-formulated-without-chlorinated-paraffin',
                  }),
                  formValue.formulatedWithoutChlorinatedParaffin === null
                    ? ''
                    : yesNoNonesById[
                        formValue.formulatedWithoutChlorinatedParaffin
                      ].name,
                )}
              </div>
              <div className={styles.row}>
                {renderTextRow(
                  intl.formatMessage({
                    id: 'product-summary-formulated-without-boron',
                  }),
                  formValue.formulatedWithoutBoron === null
                    ? ''
                    : yesNoNonesById[formValue.formulatedWithoutBoron].name,
                )}
              </div>
              <div className={styles.row}>
                {renderTextRow(
                  intl.formatMessage({
                    id: 'product-summary-formulated-without-heavy-metal',
                  }),
                  formValue.formulatedWithoutHeavyMetal === null
                    ? ''
                    : yesNoNonesById[formValue.formulatedWithoutHeavyMetal]
                        .name,
                )}
              </div>
            </div>
          </div>
        </div>
        {/* step 4 */}
        {/* <div className={styles.panel}>
          <div className={styles.title}>
            <FormattedMessage id="product-summary-step4-title" />
          </div>
          <div className={styles.content}>
            {filteredSupportingDocCountries.length > 0 ? (
              filteredSupportingDocCountries.map((country, index) => (
                <React.Fragment key={index}>
                  {// section //}
                  <div className={styles.section}>
                    <div className={styles.row}>
                      {renderTextRow(
                        intl.formatMessage({
                          id: 'product-summary-country',
                        }),
                        countriesById[country]?.name,
                      )}
                    </div>
                    {formValue.supportingDocByCountry[
                      country
                    ].addedDocuments.map((addedDoc, addedDocIndex) => (
                      <React.Fragment key={addedDocIndex}>
                        {addedDoc.nlgiId !== null && (
                          <div className={styles.row}>
                            {renderTextRow(
                              intl.formatMessage({
                                id: 'product-summary-nlgi',
                              }),
                              nlgisById[addedDoc.nlgiId].name,
                            )}
                          </div>
                        )}
                        {addedDoc.viscosity !== null && (
                          <div className={styles.row}>
                            {renderTextRow(
                              intl.formatMessage({
                                id: 'product-summary-viscosity',
                              }),
                              String(addedDoc.viscosity),
                            )}
                          </div>
                        )}
                        <div className={styles.row}>
                          {renderLinkRow(
                            intl.formatMessage({
                              id: 'product-summary-product-datasheet',
                            }),
                            getFilenameOrUrl(
                              addedDoc.addedProductDataSheetDocument,
                            ),
                          )}
                        </div>
                        <div className={styles.row}>
                          {renderLinkRow(
                            intl.formatMessage({
                              id: 'product-summary-material-safety-datasheet',
                            }),
                            getFilenameOrUrl(
                              addedDoc.addedSafetyDataSheetDocument,
                            ),
                          )}
                        </div>
                      </React.Fragment>
                    ))}
                  </div>
                </React.Fragment>
              ))
            ) : (
              <div className={styles.section}>
                <div className={styles.row}>
                  {renderTextRow(
                    intl.formatMessage({ id: 'product-summary-documents' }),
                    intl.formatMessage({
                      id: 'product-summary-no-document-added',
                    }),
                  )}
                </div>
              </div>
            )}
          </div>
        </div> */}
        {/* step 5 */}
        {/* <div className={styles.panel}>
          <div className={styles.title}>
            <FormattedMessage id="product-summary-step5-title" />
          </div>
          {alternativeProducts.length > 0 ? (
            <ReplacementProductsList replacements={alternativeProducts} />
          ) : (
            <div className={styles.content}>
              <>
                {// section //}
                <div className={styles.section}>
                  <div className={styles.row}>
                    {renderTextRow(
                      intl.formatMessage({
                        id: 'product-summary-replacement-product',
                      }),
                      intl.formatMessage({
                        id: 'product-summary-no-replacement-added',
                      }),
                    )}
                  </div>
                </div>
              </>
            </div>
          )}
        </div> */}
      </div>
    </StepContentContainer>
  );
}

export default ProductSummary;
