import styles from './FormError.module.scss';
import { PropsWithChildren } from 'react';
import cn from 'classnames';

interface FormErrorProps {
  className?: string;
}

/**
 * form error
 * @param className classname
 * @param children children
 */
function FormError({ className, children }: PropsWithChildren<FormErrorProps>) {
  return <div className={cn(styles.container, className)}>{children}</div>;
}

export default FormError;
