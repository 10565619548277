import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import cn from 'classnames';
import { cloneDeep } from 'lodash';

import styles from './EditAccountPage.module.scss';
import Button from '../../components/common/Button/Button';
import InputRow from '../../components/Admin/InputRow/InputRow';
import { RootStore } from '../../store';
import { getUserInfoFromLocalStorage } from '../../services/LocalStorageService';
import { hideLoading, showLoading } from '../../features/loadingSlice';
import { addToast } from '../../features/toastSlice';
import { UserInfo } from '../../models/user-info';
import { updateUserSelf } from '../../services/UserService';
import { emailValidator, getErrorMessage } from '../../utils/string.util';

import { PreviousLink } from '../../models/previous-link';
import NavigationHeader from '../../components/common/NavigationHeader/NavigationHeader';

import SelectFormRow from '../../components/Admin/SelectFormRow/SelectFormRow';
import { ReactComponent as CheckCircleIcon } from '../../icons/check-circle.svg';
import { useMsal } from '@azure/msal-react';

/**
 * Edit account page component
 */
function EditAccountPage() {
  // init hooks
  const intl = useIntl();
  const { accounts } = useMsal();
  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isSSOLogin = useMemo(() => {
    return accounts && accounts.length > 0;
  }, [accounts]);

  const [userInfo, setUserInfo] = useState<UserInfo>();
  const [step, setStep] = useState(0);

  const breadcrumbLinks: PreviousLink[] = [
    {
      name: 'Home',
      to: '/home',
    },
    {
      name: getTranslatedMessage('my-account-label'),
      to: '/users/me',
    },
    {
      name: getTranslatedMessage('edit-account-label'),
    },
  ];

  const { countries, languages } = useSelector(
    (state: RootStore) => state.lookups,
  );

  const countryOptions = useMemo(() => {
    return (
      countries?.map((item) => ({
        name: item.name,
        value: item.id,
      })) || []
    );
  }, [countries]);

  const languageOptions = useMemo(() => {
    return (
      languages?.map((item) => ({
        name: item.name,
        value: item.id,
      })) || []
    );
  }, [languages]);

  useEffect(() => {
    const userTmp = getUserInfoFromLocalStorage();
    setUserInfo(userTmp);
  }, []);

  const allowUpdate = () => {
    return (
      userInfo?.firstName &&
      userInfo.firstName.trim().length > 0 &&
      userInfo?.lastName &&
      userInfo.lastName.trim().length > 0 &&
      userInfo?.companyName &&
      userInfo.companyName.trim().length > 0 &&
      userInfo?.countryId &&
      userInfo?.languageId
    );
  };

  const updateClicked = () => {
    if (userInfo) {
      dispatch(showLoading());
      updateUserSelf(userInfo)
        .then(() => {
          setStep(1);
        })
        .catch((err) => {
          dispatch(
            addToast({
              type: 'error',
              title: getTranslatedMessage('update-account-failure'),
              message: getErrorMessage(err),
            }),
          );
        })
        .finally(() => dispatch(hideLoading()));
    }
  };

  const handleChange = (key: keyof UserInfo, value: any) => {
    const tmp: any = cloneDeep(userInfo) || {};
    tmp[key] = value;
    setUserInfo(tmp);
  };

  return (
    <div>
      <NavigationHeader
        breadcrumbLinks={breadcrumbLinks}
        showBottomLine={true}
      />
      <div className={styles.container}>
        {step === 0 && (
          <div className={styles.fieldsContainer}>
            <div className={styles.title}>
              <FormattedMessage id={'edit-account-details-label'} />
            </div>

            <div className={cn(styles.divider)} />

            <div className={'columnRow'}>
              <InputRow
                labelId={'email-address-label'}
                value={userInfo?.email}
                required={false}
                disabled={true}
                onChange={(value: string) => handleChange('email', value)}
                customValidator={(value: string) => emailValidator(value)}
              />
            </div>
            <div className={'columnRow'}>
              <InputRow
                labelId={'first-name-label'}
                value={userInfo?.firstName}
                required={true}
                disabled={isSSOLogin}
                onChange={(value: string) => handleChange('firstName', value)}
                tooltip={getTranslatedMessage('first-name-tooltip')}
              />
            </div>
            <div className={'columnRow'}>
              <InputRow
                labelId={'last-name-label'}
                value={userInfo?.lastName}
                required={true}
                disabled={isSSOLogin}
                onChange={(value: string) => handleChange('lastName', value)}
                tooltip={getTranslatedMessage('last-name-tooltip')}
              />
            </div>
            <div className={'columnRow'}>
              <InputRow
                labelId={'company-name-label'}
                value={userInfo?.companyName}
                required={true}
                disabled={isSSOLogin}
                onChange={(value: string) => handleChange('companyName', value)}
                tooltip={getTranslatedMessage('company-name-tooltip')}
              />
            </div>
            <div className={'columnRow'}>
              <InputRow
                labelId={'phone-number-label'}
                value={userInfo?.phoneNumber}
                required={false}
                onChange={(value: string) => handleChange('phoneNumber', value)}
                tooltip={getTranslatedMessage('phone-number-2-tooltip')}
              />
            </div>

            <div className={'columnRow'}>
              <SelectFormRow
                labelId={'country-label'}
                tooltip={getTranslatedMessage('country-tooltip')}
                required={true}
                options={countryOptions}
                value={userInfo?.countryId}
                onChange={(value: number) => handleChange('countryId', value)}
              />
            </div>

            <div className={'columnRow'}>
              <SelectFormRow
                labelId={'language-label'}
                tooltip={getTranslatedMessage('language-tooltip')}
                required={true}
                options={languageOptions}
                value={userInfo?.languageId}
                onChange={(value: number) => handleChange('languageId', value)}
              />
            </div>

            <div className={styles.actionRow}>
              <Button
                onClick={updateClicked}
                color={'green'}
                disabled={!allowUpdate()}
                className={cn(styles.button, styles.updateButton)}
              >
                <FormattedMessage id={'update-label'} />
              </Button>
              <Button
                onClick={() => navigate('/users/me')}
                color={'green-outline'}
                className={cn(styles.button, styles.cancelButton)}
              >
                <FormattedMessage id={'cancel-label'} />
              </Button>
            </div>
          </div>
        )}
        {step === 1 && (
          <div className={styles.resContainer}>
            <div className={styles.title}>
              <CheckCircleIcon className={styles.checkCircleIcon} />
              <FormattedMessage id={'account-updated-label'} />
            </div>
            <div className={styles.description}>
              <FormattedMessage id={'account-updated-success'} />
            </div>
            <div className={cn(styles.divider)} />

            <div className={styles.actionRow}>
              <Button
                onClick={() => navigate('/users/me')}
                color={'green'}
                className={cn(styles.button, styles.doneBtn)}
              >
                <FormattedMessage id={'done-label'} />
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default EditAccountPage;
