import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
  GenericFormProps,
  AddProductFormValues,
} from '../../../../../../models/admin-form-types';
import { RootStore } from '../../../../../../store';
import FormWrapper from '../../../../../../components/Admin/FormWrapper/FormWrapper';
import InputRow from '../../../../../../components/Admin/InputRow/InputRow';
import StepContentContainer from '../../../../../../components/Admin/StepContentContainer/StepContentContainer';
import FormRow from '../../../../../../components/Admin/FormRow/FormRow';
import Textarea from '../../../../../../components/common/Textarea/Textarea';
import { useMemo } from 'react';
import '../../../../../../components/Admin/shared.scss';
import styles from './LocalisedProductDetails.module.scss';
import FormDividerLine from '../../../../../../components/common/FormDividerLine/FormDividerLine';
import TabbedMultipleSelect from '../../../../../../components/common/TabbedMultipleSelect/TabbedMultipleSelect';

type LocalisedProductDetailsProps = GenericFormProps<AddProductFormValues> & {
  formValue: AddProductFormValues;
};

const LocalisedProductDetails = ({
  formValue,
  handleValueChange,
}: LocalisedProductDetailsProps) => {
  const intl = useIntl();
  const { languages } = useSelector((state: RootStore) => state.lookups);

  const getLocalisedContent = (langId: number) =>
    formValue.localisedContentByLang[langId] || {
      tagLine: '',
      description: '',
    };

  const activeLanguage = formValue.activeLanguage as number;

  const languageOptions = useMemo(
    () =>
      languages.map((c) => ({
        name: c.name,
        value: c.id,
        disabled: activeLanguage === 0 && c.id !== 1,
      })),
    [languages, activeLanguage],
  );

  return (
    <StepContentContainer
      title={intl.formatMessage({ id: 'add-product-step-product-overview' })}
    >
      <FormWrapper
        title={intl.formatMessage({ id: 'add-product-step-localised-details' })}
        infoMessage={intl.formatMessage({
          id: 'localised-product-details-note',
        })}
      >
        <div className={'columnRow'}>
          <FormRow
            labelId={'add-additional-langs-label'}
            tooltip={intl.formatMessage({ id: 'add-additional-langs-tooltip' })}
            required={false}
          >
            <TabbedMultipleSelect
              placeholder={intl.formatMessage({
                id: 'add-product-select-placeholder',
              })}
              mandatoryValue={[1]}
              value={formValue.languageIds}
              options={languageOptions}
              onChange={(value: number[]) => {
                handleValueChange('languageIds', value);
                // remove some languageId
                if (value.length < formValue.languageIds.length) {
                  const leftContent: any = {};
                  value.forEach((item) => {
                    leftContent[item] = formValue.localisedContentByLang[item];
                  });
                  handleValueChange('localisedContentByLang', leftContent);
                }
              }}
              activeValue={activeLanguage}
              onChangeActiveValue={(value) =>
                handleValueChange('activeLanguage', value)
              }
              wasActiveMap={formValue.wasLanguageActiveMap}
              onChangeWasActiveMap={(wasActiveMap) =>
                handleValueChange('wasLanguageActiveMap', wasActiveMap)
              }
            />
          </FormRow>
        </div>
        <FormDividerLine className={styles.dividerLine} />
        {activeLanguage > 0 && (
          <div className={'columnRow'}>
            <InputRow
              labelId={'product-tag-line-label'}
              value={getLocalisedContent(activeLanguage).tagLine || ''}
              onChange={(value: string) =>
                handleValueChange('localisedContentByLang', {
                  ...formValue.localisedContentByLang,
                  [activeLanguage]: {
                    ...getLocalisedContent(activeLanguage),
                    tagLine: value,
                  },
                })
              }
              tooltip={intl.formatMessage({ id: 'product-tag-line-tooltip' })}
            />
          </div>
        )}
        {activeLanguage > 0 && (
          <div className={'columnRow'}>
            <FormRow
              labelId={'product-description-label'}
              tooltip={intl.formatMessage({
                id: 'product-description-tooltip',
              })}
              required={!formValue.displayOnlyPartialInfo}
            >
              <Textarea
                value={getLocalisedContent(activeLanguage).description || ''}
                onChange={(value: string) =>
                  handleValueChange('localisedContentByLang', {
                    ...formValue.localisedContentByLang,
                    [activeLanguage]: {
                      ...getLocalisedContent(activeLanguage),
                      description: value,
                    },
                  })
                }
                placeholder={intl.formatMessage({
                  id: 'add-product-textarea-placeholder',
                })}
              ></Textarea>
            </FormRow>
          </div>
        )}
      </FormWrapper>
    </StepContentContainer>
  );
};

export default LocalisedProductDetails;
