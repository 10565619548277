import cn from 'classnames';
import { Fragment, PropsWithoutRef, ReactElement } from 'react';

import { ReactComponent as CheckCircleGreen } from '../../../icons/check-circle-green.svg';
import styles from './MobileStepper.module.scss';

interface MobileStepperProps {
  steps: string[];
  currentStep: number;
  stepsComponents: ReactElement<any, any>[];
}

function MobileStepper({
  steps,
  currentStep,
  stepsComponents,
}: PropsWithoutRef<MobileStepperProps>) {
  return (
    <div className={styles.container}>
      {steps.map((step: string, index: number) => (
        <Fragment key={step}>
          <div className={styles.step}>
            {index < currentStep ? (
              <CheckCircleGreen className={styles.completedStepCircle} />
            ) : (
              <div
                className={cn(styles.numberCircle, {
                  [styles.activeStepCircle]: index === currentStep,
                })}
              >
                {index + 1}
              </div>
            )}
            <div
              className={cn(styles.label, {
                [styles.completedLabel]: index < currentStep,
                [styles.currentLabel]: index === currentStep,
              })}
            >
              {step}
            </div>
          </div>
          {currentStep === index ? stepsComponents[currentStep] : null}
          <div className={styles.divider} />
        </Fragment>
      ))}
    </div>
  );
}

export default MobileStepper;
