import React from 'react';
import styles from './ValidationDataColumn.module.scss';

export interface ValidationDataColumnProps {
  children?: React.ReactNode;
}

function ValidationDataColumn({ children }: ValidationDataColumnProps) {
  return <div className={styles.container}>{children}</div>;
}

export default ValidationDataColumn;
