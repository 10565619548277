import { PropsWithChildren, useEffect, useRef } from 'react';

type OutsideClickDetectorProps = {
  className?: string;
  handleOutsideClick: () => void;
};

/**
 * Hook that detects clicks outside of the passed ref
 */
function useOutsideAlerter(ref: any, onOutsideClick: any) {
  useEffect(() => {
    /**
     * Calls the callback
     */
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        onOutsideClick();
      }
    }
    // Bind the event listener
    document.addEventListener('click', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('click', handleClickOutside);
    };
  }, [onOutsideClick, ref]);
}

/**
 * Component that detects if you click outside of it
 */
const OutsideClickDetector = ({
  className,
  handleOutsideClick,
  children,
}: PropsWithChildren<OutsideClickDetectorProps>) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, handleOutsideClick);

  return (
    <div ref={wrapperRef} className={className}>
      {children}
    </div>
  );
};

export default OutsideClickDetector;
