import { PropsWithChildren } from 'react';
import cn from 'classnames';
import styles from './ModalLeftBannerTitle.module.scss';

interface ModalLeftBannerTitleProps {
  className?: string;
}

/**
 * modal left banner title
 * @param className classname
 * @param children children
 */
function ModalLeftBannerTitle({
  className,
  children,
}: PropsWithChildren<ModalLeftBannerTitleProps>) {
  return <h1 className={cn(styles.title, className)}>{children}</h1>;
}

export default ModalLeftBannerTitle;
