import { useEffect, useState } from 'react';
import styles from './PortProductReportPage.module.scss';
import ContainerForDrawer from '../../../../components/common/ContainerForDrawer/ContainerForDrawer';
import LoadingSpinner from '../../../../components/common/LoadingSpinner/LoadingSpinner';
import NavigationHeader from '../../../../components/common/NavigationHeader/NavigationHeader';
import { PreviousLink } from '../../../../models/previous-link';
import { FormattedMessage, useIntl } from 'react-intl';
import QuickStatsForm from '../components/QuickStatsForm/QuickStatsForm';
import { RootStore } from '../../../../store';
import { useSelector } from 'react-redux';
import { CountryItem } from '../../../../models/lookups';
import classNames from 'classnames';
import Button from '../../../../components/common/Button/Button';
import { getPortProductReport } from '../../../../services/StatsService';
import PortProductTable from '../components/PortProductTable/PortProductTable';
import FormRow from '../../../../components/Admin/FormRow/FormRow';
import MultiProductSelect from '../../Products/AddCountryLevelProductPage/components/MultiProductSelect/MultiProductSelect';

/**
 * Port Product Report page component
 */
function PortProductReportPage() {
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState<any>(null);
  // table sorts
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [sortBy, setSortBy] = useState('countryName');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [filterClicked, setFilterClicked] = useState(false);

  const intl = useIntl();
  const { countries } = useSelector((state: RootStore) => state.lookups);

  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });
  const initialState = {
    country: [],
  };

  const [formValue, setFormValue] = useState(initialState);

  const breadcrumbLinks: PreviousLink[] = [
    {
      name: 'Reports',
      to: '/admin/reports',
    },
    {
      name: 'PORT PRODUCT REPORT',
    },
  ];

  const handleValueChange = (
    fieldKey: string,
    value: string | boolean | number[],
  ) => {
    setFormValue({
      ...formValue,
      [fieldKey]: value,
    });
  };

  const onFilter = () => {
    setFilterClicked(true);
    setLoading(true);
    getPortProductReport(
      page,
      perPage,
      sortBy,
      sortDirection,
      formValue.country,
    )
      .then((res: any) => {
        setReportData(res.items);
        setTotal(res.total);
        setPerPage(res.perPage);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onExport = () => {
    setLoading(true);
    getPortProductReport(
      page,
      perPage,
      sortBy,
      sortDirection,
      formValue.country,
      true,
    ).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    if (filterClicked) {
      onFilter();
    }
  }, [page, perPage, sortBy, sortDirection]);

  const countryItems = [
    {
      name: getTranslatedMessage('reports-page-select-all'),
      value: -1,
    },
    ...countries.map((country: CountryItem) => ({
      name: country.name,
      value: country.id,
    })),
  ] as any;

  const onSortChange = (column: any) => {
    let changedKey = sortBy;
    let changedDirection: 'asc' | 'desc';

    if (sortBy !== column.key) {
      changedKey = column.key;
      changedDirection = 'desc';
    } else {
      if (sortDirection === 'desc') {
        changedDirection = 'asc';
      } else {
        changedDirection = 'desc';
      }
    }
    setSortBy(changedKey);
    setSortDirection(changedDirection);
  };

  return (
    <>
      <div className={classNames(loading ? styles.loadingOverlayDim : null)}>
        <NavigationHeader breadcrumbLinks={breadcrumbLinks} />
        <ContainerForDrawer>
          <div className={styles.container}>
            <div className={styles.title}>
              <FormattedMessage id={'port-product-page-title'} />
            </div>
            <div className={styles.contentContainer}>
              <div className={styles.reportDetailContainer}>
                <div>
                  <QuickStatsForm
                    title={getTranslatedMessage('product-report-page-filter')}
                  >
                    <div className={styles.formFieldsContainer}>
                      <FormRow
                        labelId="port-product-select-country"
                        required={false}
                        className={styles.multipleSelect}
                        tooltip={getTranslatedMessage(
                          'port-product-page-tooltip',
                        )}
                      >
                        <MultiProductSelect
                          value={formValue.country}
                          onChange={(value: number[]) => {
                            if (value.includes(-1)) {
                              handleValueChange('country', []);
                            } else {
                              handleValueChange('country', value);
                            }
                          }}
                          placeholder={getTranslatedMessage(
                            'reports-page-select-all',
                          )}
                          options={countryItems}
                          className={styles.formRow}
                          inputClassName={styles.multipleInput}
                          selectClassName={styles.selectOptions}
                          selectedItemName={getTranslatedMessage(
                            'reports-field-country-select',
                          )}
                          selectedItemNameSingular={getTranslatedMessage(
                            'reports-field-country-select-singular',
                          )}
                        />
                      </FormRow>
                    </div>

                    <div className={styles.filterContainer}>
                      <Button
                        color="green"
                        className={classNames('rowButton', styles.filterButton)}
                        onClick={onFilter}
                      >
                        {getTranslatedMessage('reports-page-filter-button')}
                      </Button>
                    </div>
                  </QuickStatsForm>
                </div>
                {reportData && (
                  <PortProductTable
                    reportData={reportData}
                    page={page}
                    perPage={perPage}
                    total={total}
                    sortBy={sortBy}
                    sortDirection={sortDirection}
                    onClickPage={(page) => setPage(page)}
                    onClickPerPage={(perPage) => {
                      setPerPage(perPage);
                      setPage(1);
                    }}
                    exportToExcel={onExport}
                    onSortChange={onSortChange}
                  />
                )}
              </div>
            </div>
          </div>
        </ContainerForDrawer>
      </div>
      {loading && (
        <div className={styles.loadingOverlay}>
          <LoadingSpinner />
        </div>
      )}
    </>
  );
}

export default PortProductReportPage;
