import styles from './ContactUsBanner.module.scss';
import ModalLeftBanner from '../../ModalLeftBanner/ModalLeftBanner';
import ModalLeftBannerTitle from '../../ModalLeftBanner/ModalLeftBannerTitle/ModalLeftBannerTitle';
import { FormattedMessage, useIntl } from 'react-intl';

/**
 * banner for contact us modal
 */
function ContactUsBanner() {
  const intl = useIntl();

  return (
    <ModalLeftBanner className={styles.container}>
      <img src={'/images/logo2.png'} alt={'logo'} />

      <ModalLeftBannerTitle className={styles.title}>
        <FormattedMessage id={'contact-label'} />
      </ModalLeftBannerTitle>

      {/* Safe to use dangerouslySetInnerHTML here since we are reading from translation file */}
      <h2
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage({ id: 'contact-description' }),
        }}
      />
    </ModalLeftBanner>
  );
}

export default ContactUsBanner;
