import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  GenericFormProps,
  PartialProductInformationType,
} from '../../../../../../models/admin-form-types';
import '../../../../../../components/Admin/shared.scss';
import { RootStore } from '../../../../../../store';
import FormWrapper from '../../../../../../components/Admin/FormWrapper/FormWrapper';
import InputRow from '../../../../../../components/Admin/InputRow/InputRow';
import StepContentContainer from '../../../../../../components/Admin/StepContentContainer/StepContentContainer';
import CheckboxRow from '../../../../../../components/Admin/CheckboxRow/CheckboxRow';
import FormRow from '../../../../../../components/Admin/FormRow/FormRow';
import MultipleSelect from '../../../../../../components/common/MultipleSelect/MultipleSelect';
import { useEffect, useMemo, useState } from 'react';

import styles from './PartialProductInformation.module.scss';
import Button from '../../../../../../components/common/Button/Button';
import classNames from 'classnames';
import { MountService } from '../../../../../../services/MountService';
import { checkAvailability } from '../../../../../../services/ProductService';
import { addToast } from '../../../../../../features/toastSlice';
import { generateFriendlyUrl } from '../../../../../../utils/common.util';
import { getErrorMessage } from '../../../../../../utils/string.util';
import CheckboxList from '../../../../../../components/Admin/CheckboxList/CheckboxList';
import { SubSectorItem } from '../../../../../../models/lookups';

type PartialProductInformationProps =
  GenericFormProps<PartialProductInformationType> & {
    formValue: PartialProductInformationType;
    setNextDisable: (val: boolean) => void;
    productId?: number;
  };

const PartialProductInformation = ({
  formValue,
  productId,
  handleValueChange,
  setNextDisable,
}: PartialProductInformationProps) => {
  const mountService: MountService = useMemo(() => new MountService(), []);
  const dispatch = useDispatch();

  useEffect(() => {
    // setNextDisable(true);
    mountService.mount();
    return () => {
      mountService.unmount();
    };
  }, []);

  const intl = useIntl();
  const { sectors, subSectors, subSectorsById } = useSelector(
    (state: RootStore) => state.lookups,
  );

  const [disableAvailableBtn, setDisable] = useState<boolean>(false);

  const sectorOptions = useMemo(
    () => sectors.map((c) => ({ label: c.name, id: c.id })),
    [sectors],
  );

  const subSectorOptions = useMemo(() => {
    if (formValue.sectorId.length === 0) {
      return [];
    }
    const selectedSectorIds: Record<number, boolean> = {};
    formValue.sectorId.forEach(
      (sectorId) => (selectedSectorIds[sectorId] = true),
    );
    return subSectors
      .filter((subSec) => selectedSectorIds[subSec.sectorId])
      .map((subSec) => ({
        name: subSec.name,
        value: subSec.id,
      }));
  }, [formValue.sectorId, subSectors]);

  const clickCheckAvailability = async () => {
    setDisable(true);
    await checkAvailability(
      formValue.productName,
      formValue.friendlyUrl,
      formValue.sectorId.map((sectorId) => ({
        sectorId,
      })),
      productId,
    )
      .then(() => {
        setNextDisable(false);
        dispatch(
          addToast({
            type: 'success',
            title: intl.formatMessage({
              id: 'check-availability-success-label',
            }),
            message: intl.formatMessage({
              id: 'product-availability-get-success',
            }),
          }),
        );
      })
      .catch((err) => {
        setNextDisable(true);
        dispatch(
          addToast({
            type: 'error',
            title: intl.formatMessage({
              id: 'check-availability-error-label',
            }),
            message:
              getErrorMessage(err) ||
              intl.formatMessage({
                id: 'product-availability-get-failure',
              }),
          }),
        );
      })
      .finally(() => {
        setDisable(false);
      });
  };

  return (
    <StepContentContainer
      title={intl.formatMessage({ id: 'add-product-step-product-overview' })}
    >
      <FormWrapper
        title={intl.formatMessage({ id: 'add-product-step-partial-info' })}
        secondaryTitle={
          <CheckboxRow
            label={
              <span className={styles.checkboxLabel}>
                <FormattedMessage id="display-only-partial-info-for-product" />
              </span>
            }
            checked={formValue.displayOnlyPartialInfo}
            onClick={() =>
              handleValueChange(
                'displayOnlyPartialInfo',
                !formValue.displayOnlyPartialInfo,
              )
            }
            tooltip={intl.formatMessage({
              id: 'display-only-partial-info-for-product-tooltip',
            })}
          />
        }
      >
        <div className="sectionHeader">
          <FormattedMessage id="product-name-and-ids-header" />
        </div>
        <div className="columnRow">
          <InputRow
            required={!formValue.displayOnlyPartialInfo}
            labelId="fusion-code-label"
            value={formValue.productExternalId}
            onChange={(value: string) =>
              handleValueChange('productExternalId', value)
            }
            tooltip={intl.formatMessage({ id: 'product-external-id-tooltip' })}
          />
        </div>
        <div className="columnRow">
          <InputRow
            labelId="product-name-label"
            value={formValue.productName}
            onChange={(value: string) => {
              setDisable(false);
              handleValueChange('productName', value);
              handleValueChange('friendlyUrl', generateFriendlyUrl(value));
            }}
            tooltip={intl.formatMessage({ id: 'product-name-tooltip' })}
          />
          <InputRow
            labelId="product-friendly-url-label"
            value={formValue.friendlyUrl}
            onChange={(value: string) => {
              setDisable(false);
              handleValueChange('friendlyUrl', value);
            }}
            tooltip={intl.formatMessage({ id: 'product-friendly-url-tooltip' })}
          />
        </div>
        <div className="columnRow">
          <CheckboxList
            labelId="product-sector-label"
            items={sectorOptions}
            selectedIds={formValue.sectorId}
            onClick={(id: number) => {
              setDisable(false);
              const idx = formValue.sectorId.indexOf(id);
              const newValues = [...formValue.sectorId];
              if (idx >= 0) {
                newValues.splice(idx, 1);
              } else {
                newValues.push(id);
              }
              handleValueChange('sectorId', newValues);
              if (idx >= 0) {
                handleValueChange(
                  'subSectorIds',
                  formValue.subSectorIds.filter(
                    (subSectorId) =>
                      (subSectorsById[subSectorId] as SubSectorItem)
                        .sectorId !== id,
                  ),
                );
              }
            }}
            tooltip={intl.formatMessage({ id: 'product-sector-tooltip' })}
          />
        </div>
        {subSectorOptions.length > 0 && (
          <div className="columnRow">
            <FormRow
              labelId="product-sub-sector-label"
              tooltip={intl.formatMessage({ id: 'product-sub-sector-tooltip' })}
              required={false}
            >
              <MultipleSelect
                placeholder={intl.formatMessage({
                  id: 'add-product-select-placeholder',
                })}
                value={formValue.subSectorIds}
                options={subSectorOptions}
                onChange={(value: number[]) =>
                  handleValueChange('subSectorIds', value)
                }
              />
            </FormRow>
          </div>
        )}
        {formValue.sectorId !== null && (
          <div className="columnRow">
            <Button
              className={classNames(
                'rowButton',
                styles.checkAvailabilityButton,
              )}
              color="green-outline"
              disabled={
                !formValue.productName ||
                !formValue.friendlyUrl ||
                formValue.sectorId === null ||
                disableAvailableBtn
              }
              onClick={clickCheckAvailability}
            >
              <FormattedMessage id="product-check-availability-button" />
            </Button>
          </div>
        )}
      </FormWrapper>
    </StepContentContainer>
  );
};

export default PartialProductInformation;
