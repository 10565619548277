import cn from 'classnames';
import styles from './Option.module.scss';
import { PropsWithoutRef, useEffect, useRef } from 'react';
import { SelectOption } from '../../../../models/select-option';
import CheckBox from '../../../common/Checkbox/Checkbox';

interface OptionProps<T> {
  selected: boolean;
  data: SelectOption<T>;
  onClick: (value: T) => void;
  height: number;
  scrollIntoView: boolean;
  className?: string;
  showTooltip?: boolean;
}

/**
 * option for select
 * @param selected selected state
 * @param data data
 * @param onClick click handler
 * @param height option height
 * @param optionRenderer option content renderer
 * @param scrollIntoView scrollIntoView when loaded
 */
function Option<T>({
  selected,
  data,
  onClick,
  height,
  scrollIntoView,
  className,
  showTooltip = false,
}: PropsWithoutRef<OptionProps<T>>) {
  const optionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selected && scrollIntoView) {
      if (optionRef.current) {
        optionRef.current.scrollIntoView({ block: 'center' });
      }
    }
  }, [scrollIntoView, selected]);

  return (
    <div
      ref={optionRef}
      style={{ height: height + 'px' }}
      onClick={() => onClick(data.value)}
      className={cn(
        styles.option,
        className,
        selected && styles.selected,
        data.disabled && styles.disabled,
      )}
      title={showTooltip ? data.name : undefined}
    >
      <CheckBox checked={selected} className={styles.checkbox} />
      {data.name}
    </div>
  );
}

export default Option;
