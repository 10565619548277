import config from '../configs/config';

/**
 * replace replace non alpha and digits with hyphens
 * @param value the string
 */
export function getAlphaDigitName(value: string | undefined): string {
  if (!value) {
    return '';
  }

  return value.replace(/[^0-9A-Za-z]/g, '-');
}

/**
 * Check whether string value is null or undefined or whitespace.
 * @param value the result
 */
export function isNotNullOrWhiteSpace(value: string | undefined): boolean {
  return !!value && value.trim().length > 0;
}

/**
 * email validator
 * @param value value
 */
export function emailValidator(value: string): boolean {
  return !config.EMAIL_REGEX.test(value.toLowerCase());
}

/**
 * castrol sponsor email validator
 * @param value value
 */
export function castrolEmailValidator(value: string): boolean {
  if (emailValidator(value)) {
    return true;
  }

  const lowerVal = value.toLowerCase();
  if (
    lowerVal.endsWith('@bp.com') ||
    lowerVal.endsWith('.bp.com') ||
    lowerVal.endsWith('@castrol.com')
  ) {
    return false;
  }

  return true;
}

/**
 * get error message
 * @param error the error
 */
export function getErrorMessage(error: any): string | string[] {
  if (!error) {
    return '';
  }

  if (error.response?.data) {
    return error.response.data.message || error.response.data.errors;
  }

  if (error.message) {
    return error.message;
  }

  return '';
}
