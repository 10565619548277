import config from '../../../configs/config';
import { ProductComparisonDetail } from '../../../models/product-comparison-detail';

export const productComparisonFields = [
  {
    formattedMessageId: 'oem-label',
    isVisibleFunction: () => true,
    value: (item: ProductComparisonDetail) => {
      const res = (item.oemProductApprovalCount || 0) + ' OEM approval(s)';
      return res;
    },
  },
  {
    formattedMessageId: 'viscosity-label',
    isVisibleFunction: (item: ProductComparisonDetail) =>
      item.characteristics.viscosity &&
      item.characteristics.viscosity.length > 0,
    value: (item: ProductComparisonDetail) =>
      item.characteristics.viscosity.join(', '),
  },
  {
    formattedMessageId: 'product-comparision-viscosity-index-label',
    isVisibleFunction: (item: ProductComparisonDetail) =>
      item.characteristics.viscosityIndexName &&
      item.characteristics.viscosityIndexName !== 'null' &&
      item.characteristics.viscosityIndexName.length > 0,
    value: (item: ProductComparisonDetail) =>
      !item.characteristics.viscosityIndexName ||
      item.characteristics.viscosityIndexName === 'null'
        ? 'N/A'
        : item.characteristics.viscosityIndexName,
  },
  {
    formattedMessageId: 'minTemperatureC-label',
    isVisibleFunction: (item: ProductComparisonDetail) =>
      item.characteristics.minTemperatureC !== null,
    value: (item: ProductComparisonDetail) =>
      item.characteristics.minTemperatureC || 'N/A',
  },
  {
    formattedMessageId: 'maxTemperatureC-label',
    isVisibleFunction: (item: ProductComparisonDetail) =>
      item.characteristics.maxTemperatureC !== null,
    value: (item: ProductComparisonDetail) =>
      item.characteristics.maxTemperatureC || 'N/A',
  },
  {
    formattedMessageId: 'grade-label',
    isVisibleFunction: (item: ProductComparisonDetail) =>
      item.characteristics.grade && item.characteristics.grade.length > 0,
    value: (item: ProductComparisonDetail) =>
      item.characteristics.grade || 'N/A',
  },
  {
    formattedMessageId: 'sub-grade-label',
    isVisibleFunction: (item: ProductComparisonDetail) =>
      item.characteristics.subGrade && item.characteristics.subGrade.length > 0,
    value: (item: ProductComparisonDetail) =>
      item.characteristics.subGrade || 'N/A',
  },
  {
    formattedMessageId: 'mineralOilName-label',
    isVisibleFunction: (item: ProductComparisonDetail) =>
      item.characteristics.mineralOilName &&
      item.characteristics.mineralOilName.length > 0,
    value: (item: ProductComparisonDetail) =>
      item.characteristics.mineralOilName || 'N/A',
  },
  {
    formattedMessageId: 'corrosionProtectionName-label',
    isVisibleFunction: (item: ProductComparisonDetail) =>
      item.characteristics.corrosionProtectionName &&
      item.characteristics.corrosionProtectionName.length > 0,
    value: (item: ProductComparisonDetail) =>
      item.characteristics.corrosionProtectionName || 'N/A',
  },
  {
    formattedMessageId: 'concentrationRangeName-label',
    isVisibleFunction: (item: ProductComparisonDetail) =>
      item.characteristics.concentrationRangeName &&
      item.characteristics.concentrationRangeName.length > 0,
    value: (item: ProductComparisonDetail) =>
      item.characteristics.concentrationRangeName || 'N/A',
  },
  {
    formattedMessageId: 'characteristicName-label',
    isVisibleFunction: (item: ProductComparisonDetail) =>
      item.characteristics.characteristicName &&
      item.characteristics.characteristicName.length > 0,
    value: (item: ProductComparisonDetail) =>
      item.characteristics.characteristicName || 'N/A',
  },
  {
    formattedMessageId: 'customerOfferName-label',
    isVisibleFunction: (item: ProductComparisonDetail) =>
      item.characteristics.customerOfferName &&
      item.characteristics.customerOfferName.length > 0,
    value: (item: ProductComparisonDetail) =>
      item.characteristics.customerOfferName || 'N/A',
  },
  {
    formattedMessageId: 'thickenerGenericName-label',
    isVisibleFunction: (item: ProductComparisonDetail) =>
      item.characteristics.thickenerGenericName &&
      item.characteristics.thickenerGenericName.length > 0,
    value: (item: ProductComparisonDetail) =>
      item.characteristics.thickenerGenericName || 'N/A',
  },
  {
    formattedMessageId: 'thickenerSpecificName-label',
    isVisibleFunction: (item: ProductComparisonDetail) =>
      item.characteristics.thickenerSpecificName &&
      item.characteristics.thickenerSpecificName.length > 0,
    value: (item: ProductComparisonDetail) =>
      item.characteristics.thickenerSpecificName || 'N/A',
  },
  {
    formattedMessageId: 'baseOilTypeGenericName-label',
    isVisibleFunction: (item: ProductComparisonDetail) =>
      item.characteristics.baseOilTypeGenericName &&
      item.characteristics.baseOilTypeGenericName.length > 0,
    value: (item: ProductComparisonDetail) =>
      item.characteristics.baseOilTypeGenericName || 'N/A',
  },
  {
    formattedMessageId: 'baseOilTypeSpecificName-label',
    isVisibleFunction: (item: ProductComparisonDetail) =>
      item.characteristics.baseOilTypeSpecificName &&
      item.characteristics.baseOilTypeSpecificName.length > 0,
    value: (item: ProductComparisonDetail) =>
      item.characteristics.baseOilTypeSpecificName || 'N/A',
  },
  {
    formattedMessageId: 'nlgi-label',
    isVisibleFunction: (item: ProductComparisonDetail) =>
      item.characteristics.productCharacteristicNlgis &&
      item.characteristics.productCharacteristicNlgis.length > 0,
    value: (item: ProductComparisonDetail) =>
      item.characteristics.productCharacteristicNlgis
        .map((x) => x.nlgisValue)
        .join(', '),
  },
  {
    formattedMessageId: 'productCharacteristicMetalTypes-label',
    isVisibleFunction: (item: ProductComparisonDetail) =>
      item.characteristics.productCharacteristicMetalTypes &&
      item.characteristics.productCharacteristicMetalTypes.length > 0,
    value: (item: ProductComparisonDetail) =>
      item.characteristics.productCharacteristicMetalTypes
        .map((x) => x.metalTypeValue)
        .join(', '),
  },
  {
    formattedMessageId: 'multiMetalsSuitable-label',
    isVisibleFunction: (item: ProductComparisonDetail) =>
      item.characteristics.multiMetalsSuitableId &&
      item.characteristics.multiMetalsSuitableId !== 3,
    value: (item: ProductComparisonDetail) =>
      item.characteristics.multiMetalsSuitableValue || 'N/A',
  },
  {
    formattedMessageId: 'formulatedWithoutSilicate-label',
    isVisibleFunction: (item: ProductComparisonDetail) =>
      item.characteristics.formulatedWithoutSilicateId &&
      item.characteristics.formulatedWithoutSilicateId !== 3,
    value: (item: ProductComparisonDetail) =>
      item.characteristics.formulatedWithoutSilicateValue || 'N/A',
  },
  {
    formattedMessageId: 'formulatedWithoutChlorinatedParaffin-label',
    isVisibleFunction: (item: ProductComparisonDetail) =>
      item.characteristics.formulatedWithoutChlorinatedParaffinId &&
      item.characteristics.formulatedWithoutChlorinatedParaffinId !== 3,
    value: (item: ProductComparisonDetail) =>
      item.characteristics.formulatedWithoutChlorinatedParaffinValue || 'N/A',
  },
  {
    formattedMessageId: 'formulatedWithoutBoron-label',
    isVisibleFunction: (item: ProductComparisonDetail) =>
      item.characteristics.formulatedWithoutBoronId &&
      item.characteristics.formulatedWithoutBoronId !== 3,
    value: (item: ProductComparisonDetail) =>
      item.characteristics.formulatedWithoutBoronValue || 'N/A',
  },
  {
    formattedMessageId: 'formulatedWithoutHeavyMetal-label',
    isVisibleFunction: (item: ProductComparisonDetail) =>
      item.characteristics.formulatedWithoutHeavyMetalId &&
      item.characteristics.formulatedWithoutHeavyMetalId !== 3,
    value: (item: ProductComparisonDetail) =>
      item.characteristics.formulatedWithoutHeavyMetalValue || 'N/A',
  },
  {
    formattedMessageId: 'esterEpAdditives-label',
    isVisibleFunction: (item: ProductComparisonDetail) =>
      !!item.characteristics.esterEpAdditivesName,
    value: (item: ProductComparisonDetail) =>
      item.characteristics.esterEpAdditivesName || 'N/A',
  },
  {
    formattedMessageId: 'product-comparision-food-grade-label',
    isVisibleFunction: (item: ProductComparisonDetail) =>
      !!item.characteristics.foodGradeName &&
      item.characteristics.foodGradeName !== 'N/A',
    value: (item: ProductComparisonDetail) =>
      item.characteristics.foodGradeName || 'N/A',
  },
  {
    formattedMessageId: 'high-flash-point-label',
    isVisibleFunction: (item: ProductComparisonDetail) =>
      !!item.characteristics.highFlashPointName &&
      item.characteristics.highFlashPointName !== 'N/A',
    value: (item: ProductComparisonDetail) =>
      item.characteristics.highFlashPointName || 'N/A',
  },
  {
    formattedMessageId: 'product-comparision-superior-biodegradation-label',
    isVisibleFunction: (item: ProductComparisonDetail) =>
      !!item.characteristics.superiorBiodegradationName &&
      item.characteristics.superiorBiodegradationName !== 'N/A',
    value: (item: ProductComparisonDetail) =>
      item.characteristics.superiorBiodegradationName || 'N/A',
  },
  {
    formattedMessageId: 'product-type-label2',
    isVisibleFunction: (item: ProductComparisonDetail, sector?: string) =>
      sector === config.INDUSTRIAL || sector === config.ALL_SECTORS,
    value: (item: ProductComparisonDetail) => item.productType,
  },
  {
    formattedMessageId: 'applicable-these-sectors-label',
    isVisibleFunction: () => true,
    value: (item: ProductComparisonDetail) => item.subSector || item.sector,
  },
];
