import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import Modal from '../Modal/Modal';
import Button from '../Button/Button';

import { ReactComponent as SuccessIcon } from '../../../icons/success-alt.svg';

import styles from './GenericModal.module.scss';

type GenericModalProps = {
  type?: string;
  titleId: string;
  messageId: string;
  onClose: () => void;
  onYesClick?: () => void;
};

const GenericModal = ({
  type = 'success',
  titleId,
  messageId,
  onClose,
  onYesClick,
}: GenericModalProps) => (
  <Modal onClose={onClose} className={styles.genericModalContainer}>
    <div className={styles.container}>
      <h1>
        <FormattedMessage id={titleId} />
      </h1>
      <div className={styles.content}>
        {type === 'success' && <SuccessIcon className={styles.successIcon} />}
        <span>
          <FormattedMessage id={messageId} />
        </span>
      </div>
      <div
        className={cn(
          styles.actionBtnContainer,
          type === 'confirmation' && styles.confirmButtons,
        )}
      >
        <Button
          color={type === 'confirmation' ? 'gray' : 'green'}
          onClick={onClose}
        >
          <FormattedMessage
            id={type === 'confirmation' ? 'no-label' : 'done-label'}
          />
        </Button>
        {type === 'confirmation' && (
          <Button color={'green'} onClick={onYesClick}>
            <FormattedMessage id={'yes-label'} />
          </Button>
        )}
      </div>
    </div>
  </Modal>
);

export default GenericModal;
