import { PropsWithoutRef } from 'react';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as CheckCircle } from '../../../../../icons/check-circle.svg';
import { ReactComponent as ErrorIcon } from '../../../../../icons/error.svg';
import LoadingSpinner from '../../../../../components/common/LoadingSpinner/LoadingSpinner';
import styles from './SaveUserData.module.scss';

interface SaveUserDataProps {
  completed: boolean;
  success: boolean;
  errorMessage?: string;
}

function SaveUserData({
  completed,
  success,
  errorMessage,
}: PropsWithoutRef<SaveUserDataProps>) {
  return (
    <div className={styles.container}>
      <h2>
        <FormattedMessage id={'save-data'} />
      </h2>
      <div className={styles.divider} />
      <div className={styles.resultContainer}>
        {!completed ? (
          <LoadingSpinner />
        ) : success ? (
          <>
            <CheckCircle />
            <div className={styles.successText}>
              <FormattedMessage id={'user-save-success-label'} />
            </div>
          </>
        ) : (
          <>
            <ErrorIcon className={styles.errorIcon} />
            <div className={styles.successText}>
              <FormattedMessage id={'user-save-fail-label'} />
            </div>
            <div className={styles.errorText}>{errorMessage}</div>
          </>
        )}
      </div>
    </div>
  );
}

export default SaveUserData;
