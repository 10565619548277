import { useEffect, useState } from 'react';
import styles from './OEMActivitiesPage.module.scss';
import ContainerForDrawer from '../../../../components/common/ContainerForDrawer/ContainerForDrawer';
import NavigationHeader from '../../../../components/common/NavigationHeader/NavigationHeader';
import { PreviousLink } from '../../../../models/previous-link';
import { FormattedMessage, useIntl } from 'react-intl';
import QuickStatsForm from '../components/QuickStatsForm/QuickStatsForm';
import DateField from '../../../../components/Admin/DateField/DateField';
import SelectFormRow from '../../../../components/Admin/SelectFormRow/SelectFormRow';
import { RootStore } from '../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { ReactComponent as CancelIcon } from '../../../../icons/cross-circle.svg';
import InputRow from '../../../../components/Admin/InputRow/InputRow';
import { getOemActivities } from '../../../../services/StatsService';
import { getRangeFamilies } from '../../../../services/FamilyService';
import { RangeFamily } from '../../../../models/admin-form-types';
import LoadingSpinner from '../../../../components/common/LoadingSpinner/LoadingSpinner';
import { userRoles } from '../../../../configs/admin-constants';
import Button from '../../../../components/common/Button/Button';
import OEMActivitiesTable from '../components/OEMActivitiesTable/OEMActivitiesTable';
import FormRow from '../../../../components/Admin/FormRow/FormRow';
import MultiProductSelect from '../../Products/AddCountryLevelProductPage/components/MultiProductSelect/MultiProductSelect';
import { addToast } from '../../../../features/toastSlice';
import { getActiveProducts } from '../../../../services/OemService';

/**
 * OEM Activities page component
 */
function OEMActivitiesPage() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { sectors } = useSelector((state: RootStore) => state.lookups);
  const [loading, setLoading] = useState(false);
  const [rangeFamilies, setRangeFamilies] = useState<RangeFamily[]>([]);
  const [reportData, setReportData] = useState<any>(null);
  // table sorts
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [sortBy, setSortBy] = useState('oem.name');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [filterClicked, setFilterClicked] = useState(false);
  const [products, setProducts] = useState([]);

  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });
  const initialState: any = {
    oemName: '',
    familyIds: [],
    productIds: [],
    sectorIds: [],
    userRole: '',
    startDate: null,
    endDate: null,
  };

  const [formValue, setFormValue] = useState(initialState);

  const breadcrumbLinks: PreviousLink[] = [
    {
      name: 'Report',
      to: '/admin/reports',
    },
    {
      name: 'OEMS Report',
    },
  ];

  const rangeFamiliesItems = [
    {
      name: getTranslatedMessage('reports-page-select-all'),
      value: -1,
    },
    ...rangeFamilies.map((item) => ({
      name: item.name,
      value: item.id,
    })),
  ];

  const sectorsItems = [
    {
      name: getTranslatedMessage('reports-page-select-all'),
      value: -1,
    },
    ...sectors.map((item) => ({
      name: item.name,
      value: item.id,
    })),
  ];

  const userRolesItems = [
    {
      name: getTranslatedMessage('reports-page-select-all'),
      value: null,
    },
    ...userRoles.map((role) => ({
      name: role,
      value: role,
    })),
  ];

  const handleValueChange = (
    fieldKey: string,
    value: string | boolean | number[],
  ) => {
    setFormValue({
      ...formValue,
      [fieldKey]: value,
    });
  };

  const clearFilter = () => {
    setFormValue(initialState);
    setReportData(null);
    setPage(1);
    setPerPage(10);
  };

  useEffect(() => {
    getActiveProducts().then((values: any) => {
      const activeProducts = values.filter((item: any) => item.active);
      const productItems = [
        {
          name: getTranslatedMessage('reports-page-select-all'),
          value: -1,
        },
        ...activeProducts.map((item: any) => ({
          name: item.name,
          value: item.id,
        })),
      ] as any;
      setProducts(productItems);
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    getRangeFamilies()
      .then(async (res: RangeFamily[]) => {
        setRangeFamilies(res);
      })
      .finally(() => setLoading(false));
  }, []);

  const onFilter = (resetPage = false) => {
    setLoading(true);
    setFilterClicked(true);
    getOemActivities(
      resetPage ? 1 : page,
      resetPage ? 10 : perPage,
      sortBy,
      sortDirection,
      formValue.oemName,
      formValue.familyIds,
      formValue.productIds,
      formValue.sectorIds,
      formValue.userRole,
      formValue.startDate,
      formValue.endDate,
    )
      .then((res: any) => {
        setReportData(res.items);
        setPage(res.page);
        setPerPage(res.perPage);
        setTotal(res.total);
      })
      .catch((err) => {
        dispatch(
          addToast({
            type: 'error',
            title: intl.formatMessage({ id: 'report-oem-report-failure' }),
            message: err?.message || '',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onExport = () => {
    setLoading(true);
    getOemActivities(
      page,
      perPage,
      sortBy,
      sortDirection,
      formValue.oemName,
      formValue.familyIds,
      formValue.productIds,
      formValue.sectorIds,
      formValue.userRole,
      formValue.startDate,
      formValue.endDate,
      true,
    )
      .catch((err) => {
        dispatch(
          addToast({
            type: 'error',
            title: intl.formatMessage({ id: 'report-oem-report-failure' }),
            message: err?.message || '',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (filterClicked) {
      onFilter();
    }
  }, [page, perPage, sortBy, sortDirection]);

  const onSortChange = (column: any) => {
    let changedKey = sortBy;
    let changedDirection: 'asc' | 'desc';

    if (sortBy !== column.key) {
      changedKey = column.key;
      changedDirection = 'desc';
    } else {
      if (sortDirection === 'desc') {
        changedDirection = 'asc';
      } else {
        changedDirection = 'desc';
      }
    }
    setSortBy(changedKey);
    setSortDirection(changedDirection);
  };

  return (
    <>
      <div className={classNames(loading ? styles.loadingOverlayDim : null)}>
        <NavigationHeader breadcrumbLinks={breadcrumbLinks} />
        <ContainerForDrawer>
          <div className={styles.container}>
            <div className={styles.title}>
              <FormattedMessage id={'reports-oems-report'} />
            </div>
            <div className={styles.contentContainer}>
              <div className={styles.reportDetailContainer}>
                <div>
                  <QuickStatsForm
                    title={getTranslatedMessage('reports-oems-report')}
                    secondaryTitle={getTranslatedMessage(
                      'reports-oems-report-description',
                    )}
                  >
                    <div className={styles.formFieldsContainer}>
                      <div className={styles.inputRow}>
                        <InputRow
                          labelId={'oem-page-oem-name'}
                          value={formValue.oemName}
                          required={false}
                          placeholder={getTranslatedMessage(
                            'oem-page-input-placeholder',
                          )}
                          onChange={(value) =>
                            handleValueChange('oemName', value)
                          }
                          errorMessage={getTranslatedMessage(
                            'field-required-error',
                          )}
                        />
                      </div>

                      <div className={styles.row}>
                        <FormRow
                          labelId={'product-report-page-products'}
                          required={false}
                          className={styles.multipleSelect}
                        >
                          <MultiProductSelect
                            value={formValue.productIds}
                            options={products}
                            onChange={(value: number[]) => {
                              if (value.includes(-1)) {
                                handleValueChange('productIds', []);
                              } else {
                                handleValueChange('productIds', value);
                              }
                            }}
                            className={styles.multipleSelect}
                            inputClassName={styles.multipleInput}
                            selectClassName={styles.selectOptions}
                            selectedItemName={getTranslatedMessage(
                              'reports-field-product-select',
                            )}
                            selectedItemNameSingular={getTranslatedMessage(
                              'reports-field-product-select-singular',
                            )}
                            placeholder={getTranslatedMessage(
                              'reports-page-select-all',
                            )}
                          />
                        </FormRow>
                        <FormRow
                          labelId={'product-report-page-product-family'}
                          required={false}
                          className={styles.multipleSelect}
                        >
                          <MultiProductSelect
                            value={formValue.familyIds}
                            onChange={(value: number[]) => {
                              if (value.includes(-1)) {
                                handleValueChange('familyIds', []);
                              } else {
                                handleValueChange('familyIds', value);
                              }
                            }}
                            options={rangeFamiliesItems}
                            className={styles.multipleSelect}
                            inputClassName={styles.multipleInput}
                            selectClassName={styles.selectOptions}
                            selectedItemName={getTranslatedMessage(
                              'reports-field-product-select',
                            )}
                            selectedItemNameSingular={getTranslatedMessage(
                              'reports-field-product-select-singular',
                            )}
                            placeholder={getTranslatedMessage(
                              'reports-page-select-all',
                            )}
                          />
                        </FormRow>
                        <FormRow
                          labelId={'product-report-page-sector'}
                          required={false}
                          className={styles.multipleSelect}
                        >
                          <MultiProductSelect
                            options={sectorsItems}
                            value={formValue.sectorIds}
                            onChange={(value: number[]) => {
                              if (value.includes(-1)) {
                                handleValueChange('sectorIds', []);
                              } else {
                                handleValueChange('sectorIds', value);
                              }
                            }}
                            className={styles.multipleSelect}
                            inputClassName={styles.multipleInput}
                            selectClassName={styles.selectOptions}
                            selectedItemName={getTranslatedMessage(
                              'reports-field-sector-select',
                            )}
                            selectedItemNameSingular={getTranslatedMessage(
                              'reports-field-sector-select-singular',
                            )}
                            placeholder={getTranslatedMessage(
                              'reports-page-select-all',
                            )}
                          />
                        </FormRow>
                        <SelectFormRow
                          labelId={'product-report-page-user-role'}
                          value={formValue.userRole}
                          onChange={(value: string) =>
                            handleValueChange('userRole', value)
                          }
                          placeholder={getTranslatedMessage(
                            'reports-page-select-all',
                          )}
                          required={false}
                          options={userRolesItems}
                          className={styles.formRow}
                        />
                        <DateField
                          labelId={'product-report-page-start-date'}
                          value={formValue.startDate}
                          onChange={(value: string) =>
                            handleValueChange('startDate', value)
                          }
                          required={false}
                          className={styles.calendarRow}
                          rowClassName={styles.formRow}
                          placeholder={getTranslatedMessage(
                            'reports-page-select-date',
                          )}
                        />
                        <DateField
                          labelId={'product-report-page-end-date'}
                          value={formValue.endDate}
                          onChange={(value: string) =>
                            handleValueChange('endDate', value)
                          }
                          required={false}
                          className={styles.calendarRow}
                          rowClassName={styles.formRow}
                          placeholder={getTranslatedMessage(
                            'reports-page-select-date',
                          )}
                          tooltip={getTranslatedMessage(
                            'reports-page-oem-page-tooltip',
                          )}
                        />
                      </div>
                    </div>

                    <div className={styles.filterContainer}>
                      <Button
                        color={'green'}
                        className={classNames('rowButton', styles.filterButton)}
                        onClick={() => onFilter(true)}
                      >
                        {getTranslatedMessage('reports-page-filter-button')}
                      </Button>
                      <div className={styles.clearFilter} onClick={clearFilter}>
                        <CancelIcon className={styles.cancelIcon} />
                        <div className={styles.desktop}>
                          {getTranslatedMessage('reports-page-clear-filter')}
                        </div>
                        <div className={styles.mobile}>
                          {getTranslatedMessage('clear-label')}
                        </div>
                      </div>
                    </div>
                  </QuickStatsForm>
                </div>
                {reportData && (
                  <OEMActivitiesTable
                    reportData={reportData}
                    page={page}
                    perPage={perPage}
                    sortBy={sortBy}
                    sortDirection={sortDirection}
                    total={total}
                    onClickPage={(page) => setPage(page)}
                    onClickPerPage={(perPage) => {
                      setPerPage(perPage);
                      setPage(1);
                    }}
                    exportToExcel={onExport}
                    onSortChange={onSortChange}
                  />
                )}
              </div>
            </div>
          </div>
        </ContainerForDrawer>
      </div>
      {loading && (
        <div className={styles.loadingOverlay}>
          <LoadingSpinner />
        </div>
      )}
    </>
  );
}

export default OEMActivitiesPage;
