/* istanbul ignore file */
import styles from './ManageAnnouncements.module.scss';
import { PreviousLink } from '../../../models/previous-link';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEffect, useMemo, useState } from 'react';
import NavigationHeader from '../../../components/common/NavigationHeader/NavigationHeader';
import ContainerForDrawer from '../../../components/common/ContainerForDrawer/ContainerForDrawer';
import LeftDrawer from '../../../components/common/LeftDrawer/LeftDrawer';
import RightContainer from '../../../components/common/RightContainer/RightContainer';
import LoadingSpinner from '../../../components/common/LoadingSpinner/LoadingSpinner';
import SearchFilter from '../../../components/common/SearchFilter/SearchFilter';
import Input from '../../../components/common/Input/Input';
import FilterContainer from '../../../components/common/FilterContainer/FilterContainer';
import Select from '../../../components/common/Select/Select';
import FilterGroup from '../../../components/common/FilterGroup/FilterGroup';
import { ReactComponent as AnnouncementAddIcon } from '../../../icons/announcement-add.svg';
import { Link } from 'react-router-dom';
import AnnouncementTable from './components/AnnouncementTable/AnnouncementTable';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import { FilterOption } from '../../../models/filter-option';
import {
  activateAnnouncement,
  deactivateAnnouncement,
  deleteAnnouncement,
  getAnnouncementCountries,
  getAnnouncements,
} from '../../../services/ManageAnnouncementService';
import { addToast } from '../../../features/toastSlice';
import { Announcement } from '../../../models/announcement';
import _ from 'lodash';
import NoResult from '../../../components/Admin/NoResult/NoResult';
import { useNavigate } from 'react-router-dom';

const visibility = [
  { name: 'Active', value: 'active' },
  { name: 'Inactive', value: 'inactive' },
];

export default function ManageAnnouncements() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // pagination
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [processing, setProcessing] = useState<boolean>(false);
  const [reload, setReload] = useState(0);

  // filters
  const [titleFilter, setTitleFilter] = useState('');
  const [typeFilter, setTypeFilter] = useState(0);
  const [countryFilter, setCountryFilter] = useState<FilterOption[]>([]);
  const [visibilityFilter, setVisibilityFilter] = useState<FilterOption[]>([]);

  // sorting
  const [sortBy, setSortBy] = useState('title');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  const [loading, setLoading] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);
  const [announcementCountries, setAnnouncementCountries] = useState<number[]>(
    [],
  );
  const [countriesOption, setCountriesOption] = useState<FilterOption[]>([]);
  const { user } = useSelector((state: RootStore) => state.login);

  const onSortChange = (column: string) => {
    if (column !== sortBy) {
      setSortBy(column);
      setSortDirection('asc');
      return;
    }
    setSortDirection((currDirection) =>
      currDirection === 'desc' ? 'asc' : 'desc',
    );
  };

  const toggleFilter = (prevFilters: FilterOption[], filter: FilterOption) => {
    const newVals = [...prevFilters];
    const index = _.findIndex(newVals, (val) => val.value === filter.value);
    if (index >= 0) {
      newVals.splice(index, 1);
    } else {
      newVals.push(filter);
    }
    return newVals;
  };

  const onCountryChange = (filter: FilterOption) => {
    setCountryFilter((prev) => toggleFilter(prev, filter));
  };

  const onVisibilityChange = (filter: FilterOption) => {
    setVisibilityFilter((prev) => toggleFilter(prev, filter));
  };

  const { announcementTypes, countriesById } = useSelector(
    (state: RootStore) => state.lookups,
  );

  const allOption = {
    name: intl.formatMessage({ id: 'all-label' }),
    value: 0,
  };

  const announcementTypesOptions = useMemo(() => {
    const announcementTypesTemp = announcementTypes.map((item) => ({
      name: item.name,
      value: item.id,
    }));
    announcementTypesTemp.unshift(allOption);
    return announcementTypesTemp;
  }, [announcementTypes, allOption]);

  const breadcrumbLinks: PreviousLink[] = useMemo(
    () => [
      {
        name: intl.formatMessage({ id: 'breadcrumb-home' }),
        to: '/home',
      },
      {
        name: intl.formatMessage({
          id: 'header-menu-admin-manage-annoucements',
        }),
      },
    ],
    [intl],
  );

  useEffect(() => {
    if (_.isEmpty(countriesById)) return;
    setCountriesOption(
      announcementCountries.map((id) => ({
        name: countriesById[id].name,
        value: countriesById[id].id.toString(),
      })),
    );
  }, [announcementCountries, countriesById]);

  useEffect(() => {
    if (!user?.role || user?.role === 'Approver') {
      return;
    }
    setFilterLoading(true);
    getAnnouncementCountries()
      .then((res) => setAnnouncementCountries(res))
      .catch(() => {
        dispatch(
          addToast({
            type: 'error',
            title: intl.formatMessage({
              id: 'announcement-countries-load-failure',
            }),
            message: '',
          }),
        );
      })
      .finally(() => setFilterLoading(false));
  }, [user]);

  useEffect(() => {
    setLoading(true);

    getAnnouncements({
      page,
      perPage,
      title: titleFilter,
      countryIds: countryFilter.map((item) => +item.value),
      active:
        visibilityFilter.length == 1
          ? visibilityFilter[0].value == 'active'
          : null,
      announcementTypeIds: typeFilter || null,
      sortBy,
      sortDirection,
    })
      .then((res) => {
        setAnnouncements(res.items);
        setTotal(res.total);
      })
      .catch(() => {
        dispatch(
          addToast({
            type: 'error',
            title: intl.formatMessage({ id: 'announcement-load-failure' }),
            message: '',
          }),
        );
      })
      .finally(() => setLoading(false));
  }, [
    page,
    perPage,
    titleFilter,
    countryFilter,
    visibilityFilter,
    typeFilter,
    sortBy,
    sortDirection,
    reload,
  ]);

  const onUpdateSuccess = (messageId: string) => {
    dispatch(
      addToast({
        type: 'success',
        title: intl.formatMessage({ id: messageId }),
        message: '',
      }),
    );
  };

  const onUpdateFailure = (messageId: string) => {
    dispatch(
      addToast({
        type: 'error',
        title: intl.formatMessage({ id: messageId }),
        message: '',
      }),
    );
  };

  const setAnnouncementActiveStatus = (
    announcementId: number,
    active: boolean,
  ) => {
    const newAnnouncements = _.cloneDeep(announcements);
    const updatedAnnouncement = _.find(
      newAnnouncements,
      (item) => item.id === announcementId,
    );
    if (updatedAnnouncement) {
      updatedAnnouncement.active = active;
    }
    setAnnouncements(newAnnouncements);
  };

  const tableMenuClick = (actionType: string, announcement: Announcement) => {
    switch (actionType) {
      case 'edit-announcement':
        navigate(`${announcement.id}/edit`);
        break;
      case 'activate-announcement':
        setProcessing(true);
        activateAnnouncement(announcement.id)
          .then(() => {
            setAnnouncementActiveStatus(announcement.id, true);
            onUpdateSuccess('announcement-update-success');
          })
          .catch(() => onUpdateFailure('announcement-update-failure'))
          .finally(() => setProcessing(false));
        break;
      case 'deactivate-announcement':
        setProcessing(true);
        deactivateAnnouncement(announcement.id)
          .then(() => {
            setAnnouncementActiveStatus(announcement.id, false);
            onUpdateSuccess('announcement-update-success');
          })
          .catch(() => onUpdateFailure('announcement-update-failure'))
          .finally(() => setProcessing(false));
        break;
      case 'delete-announcement':
        setProcessing(true);
        deleteAnnouncement(announcement.id)
          .then(() => {
            setReload((prev) => prev + 1);
            onUpdateSuccess('announcement-delete-success');
          })
          .catch(() => onUpdateFailure('announcement-delete-failure'))
          .finally(() => setProcessing(false));
        break;
    }
  };

  return (
    <>
      <NavigationHeader
        breadcrumbLinks={breadcrumbLinks}
        showBottomLine={true}
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        showFilterIcon={true}
      />
      <ContainerForDrawer
        classes={openFilter ? styles.filterOpen : styles.filterClose}
      >
        <LeftDrawer className={styles.filterContainer}>
          {filterLoading ? (
            <LoadingSpinner />
          ) : (
            <SearchFilter>
              <FilterContainer
                title={intl.formatMessage({ id: 'title-label' })}
                className={styles.filterGroup}
              >
                <Input
                  value={titleFilter}
                  onChange={_.debounce(setTitleFilter, 400)}
                  placeholder={intl.formatMessage({
                    id: 'search-announcement-title',
                  })}
                />
              </FilterContainer>

              <FilterContainer
                title={intl.formatMessage({ id: 'type-label' })}
                className={styles.filterGroup}
              >
                <Select
                  required={false}
                  className={styles.formSelectField}
                  value={typeFilter}
                  options={announcementTypesOptions}
                  onChange={setTypeFilter}
                  placeholder={''}
                />
              </FilterContainer>
              {user?.role !== 'Approver' && (
                <FilterGroup
                  filters={countriesOption}
                  onChange={onCountryChange}
                  onReset={() => setCountryFilter([])}
                  selectedOptions={countryFilter}
                  title={intl.formatMessage({ id: 'country-label' })}
                  open={true}
                  className={styles.filterGroup}
                />
              )}
              <FilterGroup
                filters={visibility}
                onChange={onVisibilityChange}
                onReset={() => setVisibilityFilter([])}
                selectedOptions={visibilityFilter}
                title={intl.formatMessage({ id: 'visibility-label' })}
                open={true}
                className={classNames(styles.filterGroup, styles.lastFilter)}
              />
            </SearchFilter>
          )}
        </LeftDrawer>
        <RightContainer>
          <>
            <div className={styles.pageHeader}>
              <h1 className={styles.title}>
                <FormattedMessage
                  id={'header-menu-admin-manage-annoucements'}
                />
              </h1>
              <Link
                className={classNames(styles.link, styles.addButton)}
                to={`/admin/annoucements/add`}
              >
                <AnnouncementAddIcon />
                <FormattedMessage id={'add-new-announcements'} />
              </Link>
            </div>
            {loading ? (
              <LoadingSpinner />
            ) : announcements.length > 0 ? (
              <>
                <AnnouncementTable
                  page={page}
                  perPage={perPage}
                  onPageChange={setPage}
                  onPerPageChange={(val: number) => {
                    setPerPage(val);
                    setPage(1);
                  }}
                  total={total}
                  announcements={announcements}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                  onSortChange={onSortChange}
                  menuClick={tableMenuClick}
                />
              </>
            ) : (
              <NoResult
                titleId={'no-announcement-available'}
                messageId={'no-search-result-message'}
              />
            )}
          </>
        </RightContainer>
      </ContainerForDrawer>
      {processing && <LoadingSpinner className={'lookupsLoading'} />}
    </>
  );
}
